import React from 'react';
import InstitutionalLayout from '../layout/institutional';
import useDeleteSystemAccount from 'services/queries/delete-system-account/use-delete-system-account';
import DeleteSystemAccountForm from '../components/form/login-form';
import Content from './content';

const DeleteSystemAccountPage = () => {
    const { mutate: deleteSystemAccount, isSuccess, isLoading } = useDeleteSystemAccount();

    return (
        <InstitutionalLayout title="Exclusão de Conta">
            <section className="text-gray-500 text-xl">
                <Content />
                {!isSuccess && (
                    <DeleteSystemAccountForm contentClassNames="my-10 sm:py-0 px-0" isLoginPage={false} isLoading={isLoading} onSubmit={deleteSystemAccount} submitButtonTitle="Solicitar exclusão" />
                )}
            </section>
        </InstitutionalLayout>
    );
};

export default DeleteSystemAccountPage;
