// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import queries, { getDetailsKey } from './graphql';
import { CRUDDetails } from 'types/graphql';

type GenericResponse<T = any> = {
    item: T;
};

const useGetDetails = <T>(queryConfig: CRUDDetails, id?: string | null) => {
    const getAll = async () => {
        const params = {
            id
        };

        const query = queryConfig?.query || queries.getDetails(queryConfig);

        return apiGraphQl<GenericResponse<T>>(query, params).then((response) => {
            if (queryConfig.formatResponse) {
                return queryConfig.formatResponse(response);
            }

            return response?.item;
        });
    };

    return useQuery(getDetailsKey(queryConfig?.table, id), getAll, { enabled: !!id });
};

export default useGetDetails;
