module.exports = {
    fontFamily: {
        sans: ['Poppins', 'sans-serif']
    },
    metadata: {
        name: 'Milidata',
        shortName: 'Mili',
        slug: 'milidata'
    },
    extend: {
        animation: {
            spin: 'spin 0.7s linear infinite'
        },
        backgroundImage: {
            notFound: "url('assets/images/bg-404.png')",
            public: "url('assets/images/bg-public.png')"
        },
        borderRadius: {
            '2lg': '10px'
        },
        colors: {
            base: {
                100: '#FFF',
                200: '#F8F8F8',
                300: '#E8E9EB',
                400: '#C6C9CC',
                500: '#6d7178',
                700: '#595F69'
            },
            primary: {
                100: '#98B7DF',
                200: '#7EA4D7',
                300: '#6492CF',
                400: '#4A80C7',
                500: '#306ebf',
                600: '#2A60A7',
                700: '#24538F',
                800: '#1E4577',
                900: '#183760'
            },
            secondary: {
                100: '#81D3D3',
                200: '#62C7C7',
                300: '#42BCBC',
                400: '#23B1B1',
                500: '#03a6a6',
                600: '#039191',
                700: '#027D7D',
                800: '#026868',
                900: '#025353'
            },
            system: {
                danger: {
                    100: '#FEBCB9',
                    200: '#FEABA7',
                    300: '#FE9B95',
                    400: '#FD8A84',
                    500: '#FD7972',
                    600: '#DD6A64',
                    700: '#BE5B56',
                    800: '#9E4C47',
                    900: '#7F3D39'
                },
                success: {
                    100: '#A4E4B6',
                    200: '#8DDDA4',
                    300: '#77D792',
                    400: '#60D07F',
                    500: '#49C96D',
                    600: '#40B05F',
                    700: '#379752',
                    800: '#2E7E44',
                    900: '#256537'
                },
                warning: {
                    100: '#FFE9A0',
                    200: '#FFE388',
                    300: '#FFDD70',
                    400: '#FFD858',
                    500: '#FFD240',
                    600: '#DFB838',
                    700: '#BF9E30',
                    800: '#9F8328',
                    900: '#806920'
                },
                info: {
                    50: '#e1f5fe',
                    100: '#b3e5fc',
                    200: '#81d4fa',
                    300: '#4fc3f7',
                    400: '#29b6f6',
                    500: '#03a9f4',
                    600: '#039be5',
                    700: '#0288d1',
                    800: '#0277bd',
                    900: '#01579b'
                }
            },
            heading: '#023e73',
            white: '#FFF'
        },
        fontSize: {
            '2xs': ['15px', '22px'],
            '3xl': ['28px', '42px'],
            '7xl': ['56px', '84px']
        },
        screens: {
            sm: '767px',
            md: '1023px',
            lg: '1279px',
            xl: '1280px',
            '2xl': '1536px'
        },
        toast: {
            inset: '16px'
        },
        transitionProperty: {
            width: 'width'
        }
    }
};
