import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { TimelineType } from 'types/models/timeline';
import project, { getTaskStatusesKey } from './graphql';

type Response = {
    task_status: Option[];
};

const useGetTaskStatuses = (type: TimelineType) => {
    const fetcher = () => apiGraphQl<Response>(project.getTaskStatuses, { type }).then((data) => data.task_status);

    return useQuery(getTaskStatusesKey(type), fetcher);
};

export default useGetTaskStatuses;
