import { jsonToGraphQLQuery } from 'json-to-graphql-query';

export const getProviderQueryFields = jsonToGraphQLQuery({
    id: true,
    juridic_document: true,
    juridic_fantasy_name: true,
    juridic_name: true,
    title: true,
    account: { id: true, name: true },
    contacts: {
        id: true,
        person: {
            birthday: true,
            document: true,
            gender: true,
            phones: true,
            name: true
        }
    },
    type_business: { id: true, title: true },
    address: {
        address: {
            compliment: true,
            neighbourhood: true,
            number: true,
            street: true,
            zip: true,
            city: { id: true, name: true },
            state: { id: true, initials: true }
        }
    },
    file: {
        id: true,
        filename: true,
        path: true,
        original_name: true
    },
    partner: true,
    type: true,
    personal_document: true
});
