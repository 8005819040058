import api from 'services/api';
import { ReduxStore } from 'store/ducks';

export default (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    const state: ReduxStore = store.getState();

    if (action.type === 'persist/REHYDRATE' && Boolean(state?.auth?.token)) {
        api.defaults.headers.common.Authorization = `Bearer ${state.auth.token}`;
    }

    return result;
};
