import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

const useResponsive = () => {
    const { breakpoints } = useTheme();

    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTabletPortrait = useMediaQuery(breakpoints.between('sm', 'md'));
    const isTabletLandscape = useMediaQuery(breakpoints.up('md'));
    const isDesktop = useMediaQuery(breakpoints.up('lg'));

    const isSmallerThenTabletLandscape = useMediaQuery(breakpoints.down('md'));
    const isSmallerThenLarge = useMediaQuery(breakpoints.down('lg'));

    return {
        isMobile,
        isTabletPortrait,
        isTabletLandscape,
        isDesktop,
        isSmallerThenLarge,
        isSmallerThenTabletLandscape
    };
};

export default useResponsive;
