import api from 'services/api';
import { useQueries } from 'react-query';
import { getReportChartKey } from './graphql';
import { Activities, AttentionPoints, MainActivity, PhysicalFinancial, PhysicalProgress, ReportImage, WeeklyEffective } from 'types/models/report';

export const DEFAULT_WEEKS = [{ value: undefined, label: 'Geral' }];

type Response = {
    'main-activities': MainActivity[];
    'physical-progress': PhysicalProgress;
    'physical-financial': PhysicalFinancial;
    'weekly-effective': WeeklyEffective;
    images: ReportImage[];
    activities: Activities;
    'attention-points': AttentionPoints;
};

const useGetReportCharts = (projectProposalTypeId?: number, week?: number) => {
    const fetcher =
        <T extends keyof Response>(path: keyof Response) =>
        async () => {
            const endpoint = Boolean(week) ? `/project-types/${projectProposalTypeId}/reports/${path}/${week}` : `/project-types/${projectProposalTypeId}/reports/${path}`;

            return api.get<Response[T]>(endpoint).then(({ data }) => data);
        };

    return useQueries([
        {
            queryKey: getReportChartKey('main-activities', projectProposalTypeId, week),
            queryFn: fetcher<'main-activities'>('main-activities'),
            enabled: Boolean(projectProposalTypeId)
        },
        {
            queryKey: getReportChartKey('physical-progress', projectProposalTypeId, week),
            queryFn: fetcher<'physical-progress'>('physical-progress'),
            enabled: Boolean(projectProposalTypeId)
        },
        {
            queryKey: getReportChartKey('physical-financial', projectProposalTypeId, week),
            queryFn: fetcher<'physical-financial'>('physical-financial'),
            enabled: Boolean(projectProposalTypeId)
        },
        {
            queryKey: getReportChartKey('weekly-effective', projectProposalTypeId, week),
            queryFn: fetcher<'weekly-effective'>('weekly-effective'),
            enabled: Boolean(projectProposalTypeId)
        },
        {
            queryKey: getReportChartKey('images', projectProposalTypeId, week),
            queryFn: fetcher<'images'>('images'),
            enabled: Boolean(projectProposalTypeId)
        },
        {
            queryKey: getReportChartKey('activities', projectProposalTypeId, week),
            queryFn: fetcher<'activities'>('activities'),
            enabled: Boolean(projectProposalTypeId)
        },
        {
            queryKey: getReportChartKey('attention-points', projectProposalTypeId, week),
            queryFn: fetcher<'attention-points'>('attention-points'),
            enabled: Boolean(projectProposalTypeId)
        }
    ]);
};

export default useGetReportCharts;
