// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { GraphqlPagination } from 'types/graphql';
import { ProjectTypeEnum } from 'types/models/project';
import project, { getProjectServicesByTypeKey } from './graphql';

const useGetProjectServiceByType = (type: ProjectTypeEnum) => {
    const getProjectMeasuresOptions = () => apiGraphQl<GraphqlPagination<Option>>(project.getProjectServicesByType, { type }).then((data) => data.items);

    return useQuery(getProjectServicesByTypeKey(type), getProjectMeasuresOptions);
};

export default useGetProjectServiceByType;
