import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getTimelineKey } from '../projects/graphql';

type Payload = Array<{
    task: number;
    order: number;
}>;

const useReorderTasks = (timelineId?: string | null) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => api.put('/tasks', payload).then(({ data }) => data);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getTimelineKey(timelineId));

            showToast('Tarefas reordenadas com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao reordenar as tarefas', 'danger');
        }
    });
};

export default useReorderTasks;
