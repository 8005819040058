import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

type SignPostLightboxProps = {
    items: string[];
    onClose: () => void;
};

const SignPostLightbox = ({ items, onClose }: SignPostLightboxProps) => {
    const [photoIndex, setPhotoIndex] = useState(0);

    return (
        <Lightbox
            mainSrc={items[photoIndex]}
            nextSrc={items[(photoIndex + 1) % items.length]}
            prevSrc={items[(photoIndex + items.length - 1) % items.length]}
            onCloseRequest={onClose}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + items.length - 1) % items.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % items.length)}
            clickOutsideToClose={true}
        />
    );
};

export default SignPostLightbox;
