import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';
import { CreateTypeBusinessPayload } from 'types/models/configurations-classifications';
import queries, { getTypeBusinessKey } from './graphql';

type Response = {
    items: CreateTypeBusinessPayload & { id: string };
};

const useGetTypeBusinessById = (typeBusinessId?: string) => {
    const fetcher = async () => apiGraphQl<Response>(queries.getTypeBusinessById, { typeBusinessId }).then(({ items }) => items);

    return useQuery(getTypeBusinessKey(typeBusinessId), fetcher, { enabled: !!typeBusinessId });
};

export default useGetTypeBusinessById;
