import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useUploadFile from '../files/use-upload-file';
import { useNavigate } from 'react-router-dom';
import useToast from 'hooks/toast/use-toast';
import { getAllKey } from '../crud/graphql';
import { getPersonDocumentKey } from './graphql';
import { getPersonContractKey } from '../person-contract/keys';

export const usePersonDocumentMutation = (props: { personId?: string; documentId?: string; contractId?: string }) => {
    const navigate = useNavigate();

    const { personId, documentId, contractId } = props || {};

    const isEdit = !!documentId;

    const { showToast } = useToast();

    const queryClient = useQueryClient();

    const { uploadFile } = useUploadFile();

    const request = async (payload: any) => {
        if (payload?.file?.id) {
            payload.file = payload.file.id;
        } else {
            await uploadFile(
                payload.file,
                ({ id }) => {
                    payload.file = id;
                },
                true
            );
        }

        if (!payload?.file) {
            throw new Error();
        }

        if (!!contractId) {
            payload.contract = Number(contractId);
        }

        const apiService = isEdit ? api.patch : api.post;
        const endpoint = isEdit ? `/people/${personId}/documents/${documentId}` : `/people/${personId}/documents`;

        return apiService<any>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(request, {
        onSuccess: () => {
            navigate(-1);

            if (!!documentId) {
                queryClient.invalidateQueries(getPersonDocumentKey(documentId));
            }

            if (!!contractId) {
                queryClient.invalidateQueries(getPersonContractKey(contractId, true));
            }

            queryClient.invalidateQueries(
                getAllKey('person_document', {
                    limit: 10,
                    offset: 0,
                    where: {
                        person_id: {
                            _eq: Number(personId)
                        },
                        document: {
                            status: {
                                _neq: -1
                            }
                        }
                    }
                } as any)
            );

            showToast(`Documento ${isEdit ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isEdit ? 'atualizar' : 'criar'} o documento`, 'danger');
        }
    });
};
