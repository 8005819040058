import TimelineItem from '@mui/lab/TimelineItem';
import { Separator } from './item';
import { generateProjectTypeStatusBadge } from 'pages/private/operationals/list/utils';
import Text from 'components/core/text';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/core/icon';
import { useTheme } from '@mui/material';
import { formatDate } from 'utils/date';
import { infoButton } from 'pages/private/operationals/outcomes/list';
import { ProjectMovementsResponse } from 'services/queries/operationals/use-get-project-movements';
import { ProjectStatusEnum, ProjectTypeStatus } from 'types/models/project';
import classNames from 'classnames';
import { generateProjectStatusBadge } from '../../utils';

type ProjectMovementItemProps = {
    item: ProjectMovementsResponse;
    onSetObservationContent: (value: string) => void;
};

const ProjectMovementItem = ({ item, onSetObservationContent }: ProjectMovementItemProps) => {
    const { palette } = useTheme();

    const isDoneProjectTypeStatus = item.projectTypeStatus === ProjectTypeStatus.Done;
    const isDoneProjectStatus = item.projectStatus?.slug === ProjectStatusEnum.Done;

    const isDone = isDoneProjectTypeStatus || isDoneProjectStatus;
    const isProject = Boolean(item.projectStatus?.slug);

    return (
        <TimelineItem classes={{ root: classNames(isDone ? 'min-h-[40px]' : undefined) }} sx={{ '&:before': { display: 'none' } }}>
            <Separator withConnector={true} done={isDone} />
            <div className="ml-2 mt-8 flex items-start flex-col gap-2">
                {isProject ? generateProjectStatusBadge(item.projectStatus?.slug) : generateProjectTypeStatusBadge(item.projectTypeStatus)}
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon name="ico-calendar" width={16} height={16} color={palette.info.main} />
                        <Text className="!text-xs text-base-500">{formatDate(item.informedDate)}</Text>
                    </div>
                    <Tooltip placement="top" title="Observações" disableFocusListener={true}>
                        <IconButton
                            onClick={onSetObservationContent.bind(this, isProject ? item.observations! : item.reason)}
                            TouchRippleProps={infoButton}
                            className="hover:bg-primary-100 hover:bg-opacity-20">
                            <Icon name="ico-info" width={13} height={13} color={palette.info.main} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="flex items-center gap-2">
                    <Icon name="ico-user" width={16} height={16} color={palette.info.main} />
                    <Text className="!text-xs text-base-500">{item.person?.name || '-'}</Text>
                </div>
            </div>
        </TimelineItem>
    );
};

export default ProjectMovementItem;
