// Dependencies
import { RouteObject } from 'react-router-dom';

// Helpers
import pages from './entities/pages';
import users from './entities/users';
import profile from './entities/profile';
import contracts from './entities/projects';
import customers from './entities/customers';
import providers from './entities/providers';
import operational from './entities/operational';
import humanResources from './entities/humans-resources';
import financialResources from './entities/financial';
import administrativeResources from './entities/administrative';
import home from './entities/home';
import rules from './entities/rules';
import menu from './entities/menu';
import tasks from './entities/tasks';
import configurationClassifications from './entities/configuration-classifications';
import peopleMap from './entities/people-map';
import occupationMap from './entities/occupation-map';
import commercial from './entities/commercial';
import reports from './entities/reports';

const PRIVATE_ROUTES: RouteObject[] = [
    ...home,
    ...pages,
    ...profile,
    ...users,
    ...humanResources,
    ...customers,
    ...contracts,
    ...providers,
    ...financialResources,
    ...administrativeResources,
    ...rules,
    ...menu,
    ...tasks,
    ...operational,
    ...configurationClassifications,
    ...peopleMap,
    ...occupationMap,
    ...commercial,
    ...reports
];

export default PRIVATE_ROUTES;
