import { useNavigate, useParams } from 'react-router-dom';
import { projectFPColumns } from '../../utils';
import Datatable from 'components/core/table/datatable';
import SimpleTable from 'components/core/table/simple';
import { generateVersionsTableItems, versionsTableColumns } from 'pages/private/commercial/budgets/utils';
import useTheme from '@mui/material/styles/useTheme';
import { Project } from 'types/models/project';
import TableRow from '@mui/material/TableRow/TableRow';
import { TableCell } from '@mui/material';
import { CommercialBudgetVersionsResponse } from 'services/queries/commercial/use-get-budget-versions';
import useGetCommercialBudget from 'services/queries/commercial/use-get-commercial-budget';
import { CommercialBudgetByProjectIdResponse } from 'types/models/commercial';

type ProjectFPsProps = {
    project: Partial<Project>;
};

const ProjectFPs = ({ project }: ProjectFPsProps) => {
    const { projectId } = useParams();
    const { palette } = useTheme();

    const { data: budgets } = useGetCommercialBudget<CommercialBudgetByProjectIdResponse>(undefined, projectId);

    const navigate = useNavigate();

    return (
        <Datatable
            columns={projectFPColumns}
            data={budgets?.items || []}
            options={{
                sort: false,
                elevation: 0,
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                selectableRows: 'none',
                enableNestedDataAccess: '.',
                expandableRowsHeader: false,
                responsive: 'standard',
                pagination: false,
                expandableRows: true,
                expandableRowsOnClick: true,
                renderExpandableRow: (rowData) => {
                    const versionByRowBudgetId = (budgets?.items || []).find((item) => item.id === Number(rowData?.[0]));
                    const budgetRowId = rowData?.[0];

                    const handleGoTo = (item: Partial<CommercialBudgetVersionsResponse['items'][0]>) => {
                        navigate(`fp/${budgetRowId}/versoes/${item?.id}/${item.project_type?.id}/visualizar`);
                    };

                    return (
                        <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                            <TableCell colSpan={5} className="px-0">
                                <SimpleTable
                                    className="my-6"
                                    items={generateVersionsTableItems(versionByRowBudgetId?.budget_versions, palette, handleGoTo, undefined, project.budgets?.[0].budgetStatus, true)}
                                    columns={versionsTableColumns(true)}
                                />
                            </TableCell>
                        </TableRow>
                    );
                }
            }}
            withItemsPerPage={false}
            //@ts-ignore
            className="overflow-hidden proposals"
        />
    );
};

export default ProjectFPs;
