import RemoveButton from 'components/buttons/remove';
import Select from 'components/core/form/select';
import Switch from 'components/core/switch';
import Text from 'components/core/text';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Option } from 'types/general';
import { ProjectTypePersonResource } from 'types/models/project';
import { NonManagementResourcePayload } from 'types/models/resource';

type NonManagementResourceProps = {
    people: Option[];
    providers: Option[];
    services: Option[];
    index: number;
    isLoadingProviders: boolean;
    isLoadingServices: boolean;
    positions: Option[];
    onDeleteResource: (index: number) => void;
    findedResource?: Partial<ProjectTypePersonResource>;
};

const NonManagementResource = ({ index, people, providers, services, isLoadingProviders, isLoadingServices, positions, findedResource, onDeleteResource }: NonManagementResourceProps) => {
    const { resourceId } = useParams();

    const { control, formState, setValue, watch } = useFormContext<NonManagementResourcePayload>();

    const isUpdate = !!resourceId;
    const isExternal = watch(`resources.${index}.isExternal`);

    const handleDeleteResource = () => onDeleteResource(index);

    const handleToggleSwitch = (value: boolean) => {
        setValue(`resources.${index}.isExternal`, value);

        if (value) {
            setValue(`resources.${index}.typeManagementInformation`, undefined);
            setValue(`resources.${index}.person`, undefined);
        } else {
            setValue(`resources.${index}.services`, undefined);
            setValue(`resources.${index}.provider`, undefined);
        }
    };

    useEffect(() => {
        if (!!findedResource?.provider && !isExternal) {
            setValue(`resources.${index}.isExternal`, true);
        }
    }, [findedResource?.provider, isExternal, index, setValue]);

    return (
        <div className="border border-base-300 rounded-[14px] p-4 group relative mb-3">
            {!isUpdate && (
                <div className="flex justify-start gap-3 items-center mb-4">
                    <Text variant="body.medium.sm" as="span" className="text-primary-700">
                        É um recurso externo?
                    </Text>
                    <Switch value={isExternal} onChange={handleToggleSwitch} />
                </div>
            )}
            {Boolean(index) && <RemoveButton onClick={handleDeleteResource} />}
            {isExternal && (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                    <Controller
                        name={`resources.${index}.provider`}
                        control={control}
                        render={({ field }) => {
                            const value = providers.find((item) => item?.value === field?.value);

                            return (
                                <Select
                                    {...field}
                                    isLoading={isLoadingProviders}
                                    value={value}
                                    options={providers}
                                    label="Fornecedor"
                                    placeholder="Selecione uma opção"
                                    error={formState.errors.resources?.[index]?.provider?.message}
                                    className="col-1"
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name={`resources.${index}.services`}
                        render={({ field }) => {
                            const value = services?.filter((item) => field?.value?.find?.((value) => value === item?.value));

                            return (
                                <Select
                                    {...field}
                                    isLoading={isLoadingServices}
                                    isMulti={true}
                                    value={value}
                                    options={services}
                                    label="Serviços"
                                    placeholder="Selecione uma opção"
                                    onChange={(option: any) => {
                                        const ids = option.map((item) => item.value);

                                        field.onChange(ids);
                                    }}
                                />
                            );
                        }}
                    />
                </div>
            )}
            {!isExternal && ( // DO NOT REMOVE THIS CONDITION: for some reason the fields inside this condition is not resetted when isExternal is false
                <div className="grid gap-3 grid-cols-2">
                    <Controller
                        name={`resources.${index}.person`}
                        control={control}
                        render={({ field }) => {
                            const value = people.find((item) => item.value === field.value);

                            return (
                                <Select
                                    {...field}
                                    options={people}
                                    value={value}
                                    label="Colaborador"
                                    placeholder="Selecione uma opção"
                                    error={formState.errors.resources?.[index]?.person?.message}
                                    className="col-1"
                                    onChange={(option: any) => field.onChange(option.value)}
                                />
                            );
                        }}
                    />
                    <Controller
                        name={`resources.${index}.typeManagementInformation`}
                        control={control}
                        render={({ field }) => {
                            const value = positions.find((item) => item?.value === field?.value);
                            return (
                                <Select
                                    {...field}
                                    value={value}
                                    options={positions}
                                    label="Atribuição"
                                    isClearable={true}
                                    placeholder="Selecione uma opção"
                                    error={formState.errors.resources?.[index]?.typeManagementInformation?.message}
                                    onChange={(option: any) => field.onChange(option?.value)}
                                />
                            );
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default NonManagementResource;
