import React, { useMemo } from 'react';

// Dependencies
import { useOutletContext, useParams } from 'react-router-dom';

// Components
import SetupSignpost from './signpost';

// Helpers
import { ProjectSignpostPayload } from 'types/models/project';
import useGetSignpostClass from 'services/queries/signposts/use-get-signpost-class';
import useCreateProjectSignposts from 'services/queries/projects/use-create-project-signposts';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';

const SetupSignpostPage = () => {
    const { signPostClassId } = useParams();
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const { mutateAsync: setupSignposts, isLoading: isSubmitting } = useCreateProjectSignposts(ctx?.project?.id);

    const { data: signpostClass, isLoading: isLoadingSignpostClass } = useGetSignpostClass(signPostClassId);

    const isBuilding = useMemo(() => isLoadingSignpostClass, [isLoadingSignpostClass]);

    const handleSubmit = async (data: ProjectSignpostPayload) => {
        try {
            const payload = data.projectSignposts.map((item) => ({
                ...item,
                quantity: Number(item.quantity)
            }));

            await setupSignposts(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    return <SetupSignpost {...signpostClass} isSubmitting={isSubmitting} isBuilding={isBuilding} projectId={ctx?.project?.id} onSubmit={handleSubmit} />;
};

export default SetupSignpostPage;
