import Spinner from 'components/core/spinner';
import { Suspense, lazy, useMemo } from 'react';
import useAuth from 'store/auth/use-auth';
import retry from 'utils/retry';
import Error from 'pages/shared/error';
import ErrorBoundary from 'components/error';

const Home = () => {
    const { auth } = useAuth();

    const directorType = useMemo(() => auth.credentials.user.profile?.dashboardTemplate?.key.replace('./dashboards/', ''), [auth.credentials.user.profile?.dashboardTemplate?.key]);

    const Dashboard = lazy(() => retry(() => import(`${auth.credentials.user.profile?.dashboardTemplate?.key || './dashboards/user'}/index.tsx`)));

    return (
        <ErrorBoundary component={<Error title="Ops!" subtitle="Tivemos um problema" />}>
            <Suspense
                fallback={
                    <div className="relative w-full h-full">
                        <Spinner />
                    </div>
                }>
                <Dashboard directorType={directorType} />
            </Suspense>
        </ErrorBoundary>
    );
};

export default Home;
