import { useState } from 'react';

// Dependencies
import IconButton from '@mui/material/IconButton/IconButton';

// Components
import Icon from 'components/core/icon';
import HeaderUser from './components/user';
import Breadcrumb from 'components/core/breadcrumb';
import Notifications from './components/notifications';

// Helpers
import theme from 'settings/theme';
import useAuth from 'store/auth/use-auth';
import ResetUserPassword from 'components/reset-password';
import useResponsive from 'hooks/responsive/use-responsive';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';

type HeaderProps = {
    onToggleSidebar: () => void;
};

const Header = ({ onToggleSidebar }: HeaderProps) => {
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

    const { auth, logout } = useAuth();

    const handleLogout = () => logout();
    const { isTabletLandscape } = useResponsive();
    const { isVisible } = useBreadcrumb();

    const handleResetPasswordModal = () => setIsResetPasswordModalOpen((state) => !state);
    const handleToggleSidebar = () => onToggleSidebar();

    return (
        <>
            <header className={styles.container}>
                <div className={styles.centerY}>
                    <IconButton color="primary" className="p-[10px] mr-4" onClick={handleToggleSidebar}>
                        <Icon name="ico-menu" color={theme.extend.colors.heading} width={16} height={16} />
                    </IconButton>
                    {isTabletLandscape && isVisible && <Breadcrumb />}
                </div>
                <div className={styles.centerY}>
                    <Notifications />
                    <span className={styles.divider} />
                    <HeaderUser name={auth.credentials.user.name} onLogout={handleLogout} onOpenResetPasswordModal={handleResetPasswordModal} />
                </div>
            </header>
            {isResetPasswordModalOpen && <ResetUserPassword onResetPasswordModal={handleResetPasswordModal} />}
        </>
    );
};

const styles = {
    centerY: 'flex items-center',
    container: 'bg-base-100 border-b border-b-base-300 p-4 flex items-center justify-between h-[70px] sticky top-0 z-50',
    divider: 'w-px h-[30px] bg-base-300 mx-[12px]',
    name: 'text-heading ml-3 mr-1.5'
};

export default Header;
