import hexToRgba from 'utils/hex-to-rgba';
import { MONTH_HEIGHT, WEEK_WIDTH } from '../../utils';
import classNames from 'classnames';

type Props = {
    percentage: number;
    color: string;
    className?: string;
};

const CalendarProgress = ({ percentage, color, className }: Props) => {
    const style = { width: WEEK_WIDTH, height: MONTH_HEIGHT };

    const isWhiteBg = color?.toLowerCase() === '#ffffff';

    const classes = classNames(className, 'rounded-md flex items-center justify-center text-heading border border-gray-300 text-[12px] w-full h-full', isWhiteBg && 'text-base');

    return (
        <div style={style} className="py-1 px-[3px]">
            {!percentage ? (
                <div className={classes}></div>
            ) : (
                <div style={{ background: hexToRgba(color, 0.4) }} className={classes}>
                    {percentage}%
                </div>
            )}
        </div>
    );
};

export default CalendarProgress;
