import Icon from 'components/core/icon';
import { useCalendarContext } from '../context';
import { MONTH_HEIGHT } from '../utils';
import { Task, TaskStatus } from 'types/models/task';
import useTheme from '@mui/material/styles/useTheme';
import { getTaskVariant } from 'pages/private/operationals/view/timeline/task-meta';
import _get from 'lodash/get';
import hexToRgba from 'utils/hex-to-rgba';

type Props = {
    tasks: Task[];
    tasksThatShouldShowSubtasks: number[];
    onToggleActiveTask(id: number): void;
};

const CalendarSidebar = ({ tasks = [], tasksThatShouldShowSubtasks = [], onToggleActiveTask }: Props) => {
    const { timelineVariant } = useCalendarContext();

    const getIsOpen = (taskId: number) => tasksThatShouldShowSubtasks.includes(taskId);

    const { palette } = useTheme();

    const getStatusLabel = (taskStatus: Partial<TaskStatus>) => {
        const variant = getTaskVariant(taskStatus?.slug);

        const color = _get(palette, variant);

        return (
            <span className="text-[10px] font-medium uppercase py-[2px] px-1 rounded-md mb-1" style={{ background: hexToRgba(color[100], 0.5), color: color[700] }}>
                {taskStatus?.title}
            </span>
        );
    };

    return (
        <div className="sm:w-[300px] w-[150px] flex flex-col">
            <div className="border-b border-t border-slate-200" style={{ height: timelineVariant === 'WEEKS' ? MONTH_HEIGHT + 20 : MONTH_HEIGHT }}></div>

            <div className="flex flex-col flex-1 border-r border-slate-200">
                {tasks.map(({ id, title, color, taskStatus, ...item }) => {
                    const isOpen = getIsOpen(id);
                    const hasSubtasks = !!item?.tasks?.length;

                    return (
                        <div key={`sidebar_${id}`} className="flex flex-col border-b border-slate-200 ">
                            <div
                                className="hover:bg-base-200 cursor-pointer relative flex flex-col sm:flex-row items-center sm:justify-start justify-center px-4"
                                style={{ height: MONTH_HEIGHT }}
                                onClick={() => onToggleActiveTask(id)}>
                                <span className="w-1 h-full absolute left-0 top-0" style={{ backgroundColor: color?.[400] }}></span>

                                {hasSubtasks ? (
                                    <Icon name={isOpen ? 'ico-chevron-down' : 'ico-chevron-right'} width={15} height={15} className="!text-base-500 mr-3" />
                                ) : (
                                    <span className="!text-base-500 mr-3 w-[15px] h-[15px]" />
                                )}

                                <span className="text-heading sm:text-left text-center text-sm truncate mr-2 w-full text-ellipsis sm:flex-1">{title}</span>

                                {getStatusLabel(taskStatus)}
                            </div>

                            {isOpen && (
                                <div className="flex flex-col bg-base-200">
                                    {item?.tasks?.map((subTask) => {
                                        return (
                                            <div
                                                key={`subTask_${subTask.id}_task_${id}`}
                                                className="flex flex-col sm:flex-row items-center sm:justify-start justify-center pl-11 pr-4"
                                                style={{ height: MONTH_HEIGHT }}>
                                                <span className="text-base-700 sm:text-left text-center text-sm truncate sm:mr-2 text-ellipsis w-full sm:flex-1">{subTask.title}</span>
                                                {getStatusLabel(subTask?.taskStatus)}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}

                {/*  */}
            </div>
        </div>
    );
};

export default CalendarSidebar;
