import classNames from 'classnames';

const HoverElements = () => {
    const barsClasses = 'top-0 flex absolute gap-1 z-10 h-full w-[12px] bg-[#009EB3] items-center justify-center';

    return (
        <div className="hidden group-hover:flex rounded-md w-full h-full border-2 border-[#009EB3] z-20">
            <div className={classNames(barsClasses, '-left-[1px] rounded-l-md')}>
                <span className="w-[1px] h-[13px] bg-white"></span>
                <span className="w-[1px] h-[13px] bg-white"></span>
            </div>

            <div className={classNames(barsClasses, '-right-[1px] rounded-r-md')}>
                <span className="w-[1px] h-[13px] bg-white"></span>
                <span className="w-[1px] h-[13px] bg-white"></span>
            </div>
        </div>
    );
};

export default HoverElements;
