import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import DatePicker from 'components/core/datepicker';
import Editor from 'components/core/form/editor';
import FormButtons from 'components/core/form/buttons';
import { ProjectStatusEnum } from 'types/models/project';
import { yupResolver } from '@hookform/resolvers/yup';
import useUpdateProjectStatus, { UpdateProjectStatusPayload } from 'services/queries/projects/use-update-project-status';
import { updateProjectLabelsByProjectStatus } from '../../utils';
import { schemaUpdateProjectStatus } from 'pages/private/operationals/view/utils';
import dayjs from 'dayjs';

type UpdateProjectStatusProps = {
    updateProjectStatus: ProjectStatusEnum;
    onSetProjectStatus: React.Dispatch<React.SetStateAction<ProjectStatusEnum | null>>;
};

const UpdateProjectStatus = ({ updateProjectStatus, onSetProjectStatus }: UpdateProjectStatusProps) => {
    const { projectId } = useParams();

    const { mutateAsync: updateProjectTypeStatusMutate, isLoading: isSubmitting } = useUpdateProjectStatus(projectId);

    const { control, formState, handleSubmit } = useForm<UpdateProjectStatusPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaUpdateProjectStatus),
        defaultValues: {
            informedDate: dayjs().toISOString()
        }
    });

    const submit = async (data: UpdateProjectStatusPayload) => {
        try {
            await updateProjectTypeStatusMutate({
                ...data,
                status: updateProjectStatus
            });

            onSetProjectStatus(null);
        } catch (error) {
            console.error('submit - updateProjectStatus: ', error);
        }
    };

    return (
        <Modal onClose={onSetProjectStatus.bind(this, null)} closeOnClickOutside={false} contentClassnames="w-[800px]">
            <form className="py-5 px-7" onSubmit={handleSubmit(submit)}>
                <Text as="h3" variant="h4" className="text-heading mb-5">
                    {updateProjectLabelsByProjectStatus[updateProjectStatus].title}
                </Text>
                <div className="grid gap-4 mb-4">
                    <Controller
                        name="informedDate"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                label={updateProjectLabelsByProjectStatus[updateProjectStatus].label}
                                placeholderText="Selecione uma data"
                                error={formState.errors.informedDate?.message}
                                {...field}
                            />
                        )}
                    />
                </div>
                <div className="mb-4">
                    <Controller
                        name="reason"
                        control={control}
                        render={({ field }) => (
                            <Editor {...field} error={formState.errors.reason?.message} label={updateProjectLabelsByProjectStatus[updateProjectStatus].reasonLabel} parentClasses="mb-6" />
                        )}
                    />
                </div>
                <FormButtons onCancel={onSetProjectStatus.bind(this, null)} isLoading={isSubmitting} />
            </form>
        </Modal>
    );
};

export default UpdateProjectStatus;
