import SimpleCard from 'components/simple-card';
import { generateAssetDetails } from '../create-or-update/utils';
import { useParams } from 'react-router-dom';
import { AssetResponse } from 'types/models/assets';
import Text from 'components/core/text';

export type AssetDetailsProps = {
    asset: AssetResponse['items'];
};

const AssetDetails = ({ asset }: AssetDetailsProps) => {
    const { classificationType } = useParams();

    const details = generateAssetDetails(asset, classificationType!);

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                {details.map((item, index) => (
                    <SimpleCard variant="details" {...item} key={`asset_card_${index}`} />
                ))}
            </div>
            <div className="w-full break-words my-4 px-6">
                <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                    Observação
                </Text>
                <div className="text-base sm:text-lg" dangerouslySetInnerHTML={{ __html: asset.observations || '-' }} />
            </div>
        </>
    );
};

export default AssetDetails;
