import Modal from 'components/core/modal';
import React, { useEffect } from 'react';
import Text from 'components/core/text';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ContactsFields from 'components/contacts/fields';
import Button from 'components/core/button';
import useContactMutation from 'services/queries/contact/use-contact-mutation';
import { object, string, number, array } from 'yup';
import dictionary from 'utils/dictionary';
import { Gender } from 'types/models/person';
import _get from 'lodash/get';
import useGetContact from 'services/queries/contact/use-get-contact';
import Spinner from 'components/core/spinner';
import _pick from 'lodash/pick';
import { getAllKey } from 'services/queries/crud/graphql';

const schema = object({
    name: string().required(dictionary.validation.required).trim(),
    title: string().required(dictionary.validation.required).trim(),
    gender: number().oneOf([Gender.Female, Gender.Male, Gender.Other]).required(dictionary.validation.required),
    email: string().required(dictionary.validation.required).trim(),

    phones: array(
        object({
            ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)).trim(),
            number: string()
        }).required(dictionary.validation.required)
    )
        .default(null)
        .nullable()
        .notRequired(),

    user: object({
        username: string().required(dictionary.validation.required).trim()
        // profile: number().required(dictionary.validation.required)
    })
        .default(undefined)
        .notRequired()
});

const CustomerDetailsContactsForm = () => {
    const { contactId, customerId = '' } = useParams();
    const navigate = useNavigate();
    const methods = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schema)
    });

    const { handleSubmit, reset } = methods;

    const isEdit = !!contactId;

    const { mutate, isLoading: isSubmitting } = useContactMutation({
        endpoint: `/customers/${customerId}/contacts`,
        contactId,
        queryKey: getAllKey('customer_contact', {
            limit: 10,
            page: 1,
            offset: 0,
            where: {
                customer_id: {
                    _eq: Number(customerId)
                },
                person: { status: { _neq: -1 } }
            }
        })
    });

    const { data: contactDetails, isLoading: isLoadingDetails } = useGetContact({ endpoint: `/customers/${customerId}/contacts`, contactId });

    const handleClose = () => (isLoadingDetails ? null : navigate(-1));

    useEffect(() => {
        if (!!contactDetails?.person) {
            const formatData = _pick(contactDetails?.person, ['name', 'title', 'gender', 'email', 'phones', 'user.username']);

            if (!!formatData?.phones?.length) {
                formatData.phones = [{ phones: formatData?.phones }];
            }

            reset(formatData);
        }
    }, [contactDetails, reset]);

    const onSubmit = (data: any) => {
        try {
            data.phones = _get(data, 'phones[0].phones', []);

            const payload = {
                person: {
                    ...data,
                    ...(!!data.user?.username && {
                        user: { username: data.user?.username }
                    })
                }
            };

            mutate(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <Modal contentClassnames="w-[1200px]" onClose={handleClose}>
            {isLoadingDetails ? (
                <div className="h-[150px]">
                    <Spinner size={30} fixed={true} />
                </div>
            ) : (
                <form className="py-5 px-3 sm:px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {isEdit ? 'Editar' : 'Novo'} contato
                    </Text>
                    <div className="mb-7 group relative">
                        <ContactsFields methods={methods} />
                    </div>

                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CustomerDetailsContactsForm;
