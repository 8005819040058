import { Fragment } from 'react';
import { Control, Controller, FormState, useFieldArray } from 'react-hook-form';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import RemoveButton from 'components/buttons/remove';
import { ProjectProposalPayload, ProjectTypeEnum } from 'types/models/project';
import CurrencyInput from 'components/core/form/currency';
import { NumberFormatValues } from 'react-number-format';

type ScopeProps = {
    control: Control<ProjectProposalPayload, any>;
    formState: FormState<ProjectProposalPayload>;
    hasBudgetId: boolean;
    projectType?: ProjectTypeEnum;
};

export const DEFAULT_SCOPE = {
    duration: 0,
    title: ''
};

const Scope = ({ control, formState, hasBudgetId, projectType }: ScopeProps) => {
    const isManagement = projectType === ProjectTypeEnum.Management;

    const { fields, append, remove } = useFieldArray({ control, name: 'phases' });

    const handleCreateScope = () => append(DEFAULT_SCOPE);

    const handleDeleteScope = (index: number) => () => remove(index);

    const getFieldDaysValues = (fieldIndex: number) => {
        return {
            error: hasBudgetId ? formState.errors?.phases?.[fieldIndex]?.financialPrevision?.message : formState.errors?.phases?.[fieldIndex]?.duration?.message,
            name: hasBudgetId ? (`phases.${fieldIndex}.financialPrevision` as const) : (`phases.${fieldIndex}.duration` as const)
        };
    };

    const handleChangeValue = (onChange: (...event: any[]) => void, type: 'input' | 'currency') => (value: React.ChangeEvent<HTMLInputElement> | NumberFormatValues) => {
        if ('target' in value && type === 'input') {
            return onChange(Number(value.target.value));
        }

        if ('floatValue' in value && type === 'currency') {
            return onChange(value.floatValue);
        }
    };

    return (
        <Fragment>
            {fields.map((item, fieldIndex) => {
                const isLastItem = fields.length - 1 === fieldIndex;

                return (
                    <Fragment key={item.id}>
                        <div className="group mb-5 border border-base-300 p-4 rounded-[14px] relative">
                            <div className="bg-base-300 flex items-center justify-center rounded-full absolute -right-2 -top-4 h-[32px] w-[32px]">
                                <Text as="span" variant="body.medium.2xs" className="text-base-700">
                                    {fieldIndex + 1}
                                </Text>
                            </div>
                            {Boolean(fieldIndex) && <RemoveButton onClick={handleDeleteScope(fieldIndex)} />}
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <Controller
                                    name={`phases.${fieldIndex}.title`}
                                    control={control}
                                    render={({ field }) => <Input {...field} error={formState.errors.phases?.[fieldIndex]?.title?.message} label="Título" />}
                                />
                                <Controller
                                    name={getFieldDaysValues(fieldIndex)?.name}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            value={field.value?.toString()}
                                            placeholder=""
                                            error={getFieldDaysValues(fieldIndex).error}
                                            mask="9999999999999999"
                                            alwaysShowMask={false}
                                            maskPlaceholder={null}
                                            label={hasBudgetId ? 'Previsão em Dias' : 'Duração'}
                                            right={
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    /dias
                                                </Text>
                                            }
                                            rightClasses="bg-base-200 px-4"
                                            onChange={handleChangeValue(field.onChange, 'input')}
                                        />
                                    )}
                                />
                                {isManagement && hasBudgetId ? (
                                    <Controller
                                        name={`phases.${fieldIndex}.value`}
                                        control={control}
                                        render={({ field }) => (
                                            <CurrencyInput
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value}
                                                error={formState.errors.phases?.[fieldIndex]?.value?.message}
                                                label="Valor"
                                                placeholder=""
                                                decimalScale={2}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                fixedDecimalScale={true}
                                                right={
                                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                                        R$
                                                    </Text>
                                                }
                                                rightClasses="bg-base-200 px-4"
                                                onValueChange={handleChangeValue(field.onChange, 'currency')}
                                            />
                                        )}
                                    />
                                ) : (
                                    <Controller
                                        name={`phases.${fieldIndex}.paymentPercentage`}
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                error={formState.errors.phases?.[fieldIndex]?.paymentPercentage?.message}
                                                value={field.value?.toString()}
                                                mask="9999999999999999"
                                                alwaysShowMask={false}
                                                maskPlaceholder={null}
                                                label="Percentual de liberação de pagamento"
                                                right={
                                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                                        %
                                                    </Text>
                                                }
                                                rightClasses="bg-base-200 px-4"
                                                onChange={handleChangeValue(field.onChange, 'input')}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                        {isLastItem && <AddButton onClick={handleCreateScope}>Adicionar escopo</AddButton>}
                    </Fragment>
                );
            })}
            {Boolean((formState as any).errors.phases?.message) && (
                <Text as="span" variant="body.regular.xs" className="text-system-danger-500 italic block w-full text-center">
                    {(formState as any).errors.phases?.message}
                </Text>
            )}
        </Fragment>
    );
};

export default Scope;
