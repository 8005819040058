import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';

import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';

import theme from 'settings/theme';
import personBankAccountSchema from './utils/schema';

import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useGetAllBanksOptions from 'services/queries/people/use-get-banks-options';
import { Switch } from '@mui/material';
import { PersonBankAccountDetails } from 'types/models/person';

type PeopleProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    title: string;
    onSubmit: (data: any) => void;
    defaultValues?: PersonBankAccountDetails;
    error?: any;
};

const INITIAL_FORM_STATE = {
    bank: '',
    account: '',
    account_digit: '',
    agency: '',
    agency_digit: '',
    pix: '',
    main: false,
    type: 1
};

const CreateOrUpdatePersonBankAccount = ({ isBuilding, isSubmitting, title, onSubmit, defaultValues, error }: PeopleProps) => {
    const navigate = useNavigate();

    const { data: allBanksOptions } = useGetAllBanksOptions();

    const methods = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(personBankAccountSchema),
        defaultValues: INITIAL_FORM_STATE
    });

    const { formState, control, handleSubmit, reset, setError, watch, setValue } = methods;

    const isMainBankAccount = watch('main');

    const { showFormErrors } = useShowApiErrors();

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleClose = () => navigate(-1);

    const handleIsMainBankAccount = () => {
        return setValue('main', !isMainBankAccount);
    };

    return (
        <Modal onClose={handleClose} contentClassnames="w-[800px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {title}
                        </Text>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                            <Controller
                                name="bank"
                                control={control}
                                render={({ field }) => {
                                    const value = allBanksOptions?.find((item) => item.value === field.value.id);

                                    return <Select placeholder="Selecione um banco" label="Banco" options={allBanksOptions} {...field} value={value} error={formState.errors.bank?.message} />;
                                }}
                            />
                            <div className="flex items-baseline justify-content-end">
                                <Text as="span" className="text-sm pl-0 mb-1.5 text-base-500">
                                    Esta é a conta bancária principal?
                                </Text>
                                <Switch title="É uma conta principal?" name="main" checked={isMainBankAccount} value={!!isMainBankAccount} onChange={handleIsMainBankAccount} />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <Controller
                                    name="agency"
                                    control={control}
                                    render={({ field }) => (
                                        <Input {...field} label="Agência" autoComplete="nope" mask="999999999999999999999" maskPlaceholder={null} error={formState.errors.agency?.message} />
                                    )}
                                />
                                <Controller
                                    name="agency_digit"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            label="Dígito Agência"
                                            autoComplete="nope"
                                            mask="999999999999999999999"
                                            maskPlaceholder={null}
                                            error={formState.errors['agency_digit']?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <Controller
                                    name="account"
                                    control={control}
                                    render={({ field }) => (
                                        <Input {...field} label="Conta" autoComplete="nope" mask="999999999999999999999" maskPlaceholder={null} error={formState.errors.account?.message} />
                                    )}
                                />
                                <Controller
                                    name="account_digit"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            label="Dígito Conta"
                                            autoComplete="nope"
                                            mask="999999999999999999999"
                                            maskPlaceholder={null}
                                            error={formState.errors['account_digit']?.message}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 mb-4">
                            <Controller name="pix" control={control} render={({ field }) => <Input {...field} label="Pix" error={formState.errors.pix?.message} />} />
                        </div>

                        <div className="flex items-center">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdatePersonBankAccount;
