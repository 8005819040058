import { Fragment, PropsWithChildren } from 'react';
import { MONTH_HEIGHT, WEEK_WIDTH } from '../../utils';
import classNames from 'classnames';
import { HeaderText } from '../..';
import { useCalendarContext } from '../../context';
import CalendarProgress from '../progress';
import { useIsActiveMonth } from '../../hooks/use-is-active-month';

type Props = {
    headerText: HeaderText;
    width: number;
};

/**
 * This component is responsible for displaying the columns of the calendar
 */
const BodyMonth = ({ headerText, width }: Props) => {
    const { totalWeeks, year, title: month } = headerText;

    const isActive = useIsActiveMonth({ year, month });

    const { items, activePeople } = useCalendarContext();

    const isPersonActive = (id: number) => activePeople.includes(id);

    return (
        <div
            className={classNames('flex shrink h-full border-r hover:bg-slate-100 flex-col', isActive && 'bg-slate-100/30')}
            style={{ width, maxWidth: WEEK_WIDTH * 6, minWidth: WEEK_WIDTH * 4, flex: '1 0 auto' }}>
            {items?.map(({ person, totals, clients, availability }, index) => {
                const currentTotals = totals?.find(({ month, year }) => month === headerText.monthNumber && year === headerText.year)?.weeks || [];
                const currentAvailability = availability?.find(({ month, year }) => month === headerText.monthNumber && year === headerText.year)?.weeks || [];

                const isActive = isPersonActive(person.id);

                const key = ['person', person.id, index];

                return (
                    <Fragment key={key.join('-')}>
                        <Line totalWeeks={totalWeeks}>
                            {currentTotals?.map(({ percentage, color }, index) => (
                                <CalendarProgress key={[...key, 'total', 'line', index].join('-')} color={color} percentage={percentage} />
                            ))}
                        </Line>

                        {isActive && (
                            <>
                                {clients?.map((client, index) => {
                                    const currentOcupation = client?.occupation?.find(({ month, year }) => month === headerText.monthNumber && year === headerText.year)?.weeks || [];

                                    return (
                                        <Line totalWeeks={totalWeeks} key={[...key, index, 'client', client].join('-')}>
                                            {currentOcupation?.map(({ percentage, color }, ocupationIndex) => (
                                                <CalendarProgress key={[...key, index, 'client', client, 'occupation', ocupationIndex].join('-')} color={color} percentage={percentage} />
                                            ))}
                                        </Line>
                                    );
                                })}

                                <Line totalWeeks={totalWeeks}>
                                    {currentAvailability?.map(({ percentage, color }, index) => (
                                        <CalendarProgress key={[...key, 'availability', index].join('-')} color={color} percentage={percentage} />
                                    ))}
                                </Line>
                            </>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};

const Line = ({
    className,
    children,
    totalWeeks
}: PropsWithChildren<{
    className?: string;
    totalWeeks: number;
}>) => (
    <div className={classNames(`flex grid-cols-${totalWeeks}`, className)} style={{ height: MONTH_HEIGHT }}>
        {children}
    </div>

    // px-1 pt-[5px]
);

export default BodyMonth;
