import React, { memo } from 'react';

// Dependencies
import get from 'lodash/get';
import { Controller, UseFormReturn } from 'react-hook-form';

// Components
import Select from 'components/core/form/select';

// Helpers
import { Option } from 'types/general';

type Content = 'frequency' | 'seniority' | 'shift' | 'jobPosition';

export type Contents = {
    [key in Content]: {
        field: string;
        items: Option[];
    };
};

type FormProps = {
    contents: Contents;
} & UseFormReturn<any, any>;

const Form = ({ control, contents, formState }: FormProps) => {
    return (
        <>
            <Controller
                name={contents.jobPosition.field}
                control={control}
                render={({ field }) => {
                    const value = contents.jobPosition.items.find((item) => (item as any)?.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={contents.jobPosition.items}
                            label="Cargo"
                            placeholder="Selecione uma opção"
                            error={get(formState.errors, contents.jobPosition.field)?.message}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={contents.seniority.field}
                control={control}
                render={({ field }) => {
                    const value = contents.seniority.items.find((item) => (item as any)?.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={contents.seniority.items}
                            label="Senioridade"
                            placeholder="Selecione uma opção"
                            error={get(formState.errors, contents.seniority.field)?.message}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={contents.shift.field}
                control={control}
                render={({ field }) => {
                    const value = contents.shift.items.find((item) => (item as any)?.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={contents.shift.items}
                            label="Turno"
                            placeholder="Selecione uma opção"
                            error={get(formState.errors, contents.shift.field)?.message}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
            <Controller
                name={contents.frequency.field}
                control={control}
                render={({ field }) => {
                    const value = contents.frequency.items.find((item) => (item as any)?.value === field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={contents.frequency.items}
                            label="Periodicidade"
                            placeholder="Selecione uma opção"
                            error={get(formState.errors, contents.frequency.field)?.message}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                    );
                }}
            />
        </>
    );
};

export default memo(Form);
