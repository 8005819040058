import React from 'react';

const Content = () => {
    return (
        <div className="rich-content">
            <p className="mb-4 rich">
                Se você deseja excluir sua conta, entendemos que você tenha motivos pessoais para fazer isso. Nós valorizamos sua privacidade e estamos comprometidos em fornecer a você um processo
                transparente para remover sua conta de nosso sistema. Para prosseguir com a exclusão da sua conta, por favor, preencha o campo abaixo com o endereço de e-mail associado à sua conta.
                Após o envio, nossa equipe processará sua solicitação e a exclusão será concluída em até 5 dias. Lembre-se de que, ao excluir sua conta, todos os dados associados serão permanentemente
                removidos de nosso sistema. Isso inclui suas informações pessoais, histórico de atividades e qualquer conteúdo relacionado à sua conta. Tenha em mente que essa ação não pode ser
                desfeita, e você precisará criar uma nova conta caso decida retornar ao nosso serviço no futuro. Se você está tendo problemas com sua conta ou deseja obter mais informações sobre nosso
                processo de exclusão, por favor, entre em contato com nossa equipe de suporte. Agradecemos pelo tempo que você passou conosco e esperamos tê-lo atendido de maneira satisfatória. Sua
                opinião é importante para nós, então, se você tiver algum feedback sobre sua experiência ou sugestões para melhorias, não hesite em nos informar. Atenciosamente,
            </p>
        </div>
    );
};

export default Content;
