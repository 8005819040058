import React, { useState } from 'react';
import { Option } from 'types/general';
import Files, { HookValue } from './files';

type WorkDiaryItemProps = {
    className: string;
    index: number;
    provider?: Option;
};

const WorkDiaryItem = ({ className, index, provider }: WorkDiaryItemProps) => {
    const filesHook = useState<HookValue>();

    return <Files className={className} hook={filesHook} provider={provider} index={index} />;
};

export default WorkDiaryItem;
