import React from 'react';

// Dependencies
import classNames from 'classnames';

// Components
import Text from 'components/core/text';

// Helpers
import { formatDate } from 'utils/date';

type DetailsProps = {
    date: string;
    editors: string[];
    title: string;
    className?: string;
    withTitle?: boolean;
};

const Details = ({ className, title, date, editors, withTitle }: DetailsProps) => {
    const containerClasses = classNames('border border-base-300 p-4 rounded-2xl mb-2', className);

    return (
        <div className={containerClasses}>
            {withTitle && (
                <Text as="h6" variant="h6" className="text-heading mb-2">
                    Detalhes
                </Text>
            )}
            <div className="flex items-baseline mb-2">
                <Text as="span" variant="body.medium.2xs" className="text-secondary-500 mr-2">
                    Título
                </Text>
                <Text as="span" variant="body.regular.sm" className="text-base-500 flex-1">
                    {title || '-'}
                </Text>
            </div>
            <div className="flex items-baseline mb-2">
                <Text as="span" variant="body.medium.2xs" className="text-secondary-500 mr-2">
                    Data
                </Text>
                <Text as="span" variant="body.regular.sm" className="text-base-500 flex-1">
                    {formatDate(date, 'DD/MM/YYYY')}
                </Text>
            </div>
            <div className="flex items-baseline">
                <Text as="span" variant="body.medium.2xs" className="text-secondary-500 mr-2">
                    Responsáveis
                </Text>
                <Text as="span" variant="body.regular.sm" className="text-base-500 flex-1">
                    {editors.join(', ') || '-'}
                </Text>
            </div>
        </div>
    );
};

export default Details;
