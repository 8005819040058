import { CrudPageProps } from 'types/graphql';
import { RouteObject } from 'react-router-dom';
import ListPage from 'pages/private/crud/list';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import CreateOrUpdate, { CreateOrUpdateProps } from 'pages/private/crud/create-or-update';
import { createOrUpdateRuleMutation, ruleSchema } from 'pages/private/rules/create-or-update/utils';
import CrudDetails from 'pages/private/crud/details';

const form: CreateOrUpdateProps = {
    formClassName: 'grid !grid-cols-1 sm:!grid-cols-2 gap-4',
    fields: [
        {
            type: 'text',
            name: 'name'
        },
        {
            type: 'text',
            name: 'key_rule'
        }
    ],
    schema: ruleSchema
};

const [list, create, update, details]: CrudPageProps[] = [
    {
        graphql: {
            table: 'rule',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'key_rule',
                    label: dictionary.content.graphql['key_rule']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ]
        },
        title: 'Permissões'
    },
    {
        title: 'Nova permissão',
        graphql: {
            table: 'rule',
            query: () => createOrUpdateRuleMutation()
        },
        form
    },
    {
        title: 'Editar permissão',
        graphql: {
            table: 'rule',
            query: () => createOrUpdateRuleMutation(true)
        },
        form
    }
];

const rules: RouteObject[] = [
    {
        path: 'permissoes',
        element: <Page Component={ListPage} code="permissoes" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: ':id',
                element: <Page Component={CrudDetails} withModal={true} code="permissoes" permissions={[Rule.Create]} {...details} />
            },
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdate} withModal={true} code="permissoes" permissions={[Rule.Create]} {...create} />
            },
            {
                path: 'editar/:id',
                element: <Page Component={CreateOrUpdate} withModal={true} code="permissoes" permissions={[Rule.Update]} {...update} />
            }
        ]
    }
];

export default rules;
