import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import { RouteObject } from 'react-router-dom';
import OccupationMap from 'pages/private/occupation-map';

const occupationMap: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Mapa de ocupação',
        path: 'mapa-de-ocupacao',
        element: <Page code="mapa-de-ocupacao" title="Mapa de ocupação" permissions={[Rule.Show]} Component={OccupationMap} withModal={true} />
    }
];

export default occupationMap;
