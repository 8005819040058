import React, { forwardRef, InputHTMLAttributes } from 'react';

// Dependencies
import classNames from 'classnames';

// Components
import Text from 'components/core/text';
import ErrorMessage from 'components/error/message';

type CheckboxProps = {
    containerClasseName?: string;
    error?: string;
    inputClassName?: string;
    labelClassName?: string;
    label?: string;
};

const Checkbox = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & CheckboxProps>(({ containerClasseName, error, inputClassName, labelClassName, ...props }, ref) => {
    const labelClasses = classNames(label, error ? 'text-system-danger-500' : 'text-base-500');
    const container = classNames(`min-w-[48px] sm:w-auto ${props.disabled ? 'cursor-no-drop' : 'cursor-auto'}`, containerClasseName);
    const fakeCheckboxClasses = classNames(fakeCheckbox, error && 'border-system-danger-500', inputClassName);
    const labelWrapperClasses = classNames(props.disabled ? 'cursor-no-drop' : 'cursor-pointer', labelWrapper, labelClassName);

    const checkboxClasses = classNames(
        checkbox,
        error ? 'border-system-danger-500 group-hover:border-system-danger-600' : 'border-base-300 group-hover:border-base-400',
        props.checked ? `bg-secondary-500 border-secondary-500 checked group-hover:border-secondary-500` : 'bg-base-200'
    );

    return (
        <div className={container}>
            <label className={labelWrapperClasses}>
                <div className="flex flex-row-reverse relative">
                    <input ref={ref} type="checkbox" {...props} className={fakeCheckboxClasses} />
                    <span className={checkboxClasses} />
                </div>
                {props.label && (
                    <Text as="span" variant="body.regular.sm" className={labelClasses}>
                        {props.label}
                    </Text>
                )}
            </label>
            <ErrorMessage visible={Boolean(error)}>{error}</ErrorMessage>
        </div>
    );
});

const { checkbox, fakeCheckbox, labelWrapper, label } = {
    fakeCheckbox: 'h-4 left-0 opacity-0 absolute top-0 w-4 z-[1] cursor-pointer',
    labelWrapper: 'group items-center flex justify-center',
    checkbox: 'border rounded items-center flex h-4 justify-center w-4 transition-all',
    label: 'whitespace-nowrap ml-2'
};

export default Checkbox;
