import { yupResolver } from '@hookform/resolvers/yup';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useGetAttentionPoint from 'services/queries/operationals/use-get-attention-point';
import useUpdateAttentionPoint from 'services/queries/operationals/use-update-attention-point';
import useGetPeopleByProjectOptions from 'services/queries/people/use-get-people-options-by-project';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { number, object, string } from 'yup';
import AttentionPointFields from '../../create-or-update/attention-point-fields';
import { RequestAttentionPointsPayload } from '../../create-or-update/attention-points';

type Payload = RequestAttentionPointsPayload['attentionPoints'][0];

const schema = object({
    type: number().required(dictionary.validation.required),
    task: object({
        title: string().required(dictionary.validation.required),
        description: string().required(dictionary.validation.required),
        person: number().required(dictionary.validation.required),
        deadline: string().required(dictionary.validation.required)
    })
});

const UpdateAttentionPoint = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const projectId = useGetQueryParam('projectId');
    const { attentionPointId, projectTypeSolicitationId, projectProposalTypeId } = useParams();

    const { data: people = [], isLoading: isLoadingPeople } = useGetPeopleByProjectOptions(Number(projectId || 0));
    const { data: attentionPoint, isLoading: isLoadingAttentionPoint } = useGetAttentionPoint(projectProposalTypeId, projectTypeSolicitationId, attentionPointId);

    const { mutateAsync: updateAttentionPoint, isLoading: isSubmitting } = useUpdateAttentionPoint(projectProposalTypeId, projectTypeSolicitationId, attentionPointId);

    const isBuilding = useMemo(() => isLoadingPeople || isLoadingAttentionPoint, [isLoadingPeople, isLoadingAttentionPoint]);

    const { control, formState, reset, handleSubmit } = useForm<Payload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: {
            task: {
                deadline: '',
                description: '',
                title: ''
            }
        },
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (attentionPoint) {
            reset({
                type: attentionPoint.type,
                task: {
                    deadline: attentionPoint.task.deadline,
                    description: attentionPoint.task.description,
                    title: attentionPoint.task.title,
                    person: attentionPoint.task.person.id
                }
            });
        }
    }, [attentionPoint, reset]);

    const handleBack = () => navigate(-1);

    const submit = async (data: Payload) => {
        try {
            const payload = {
                ...data,
                task: {
                    ...data.task,
                    deadline: formatDate(data.task.deadline, 'YYYY-MM-DD')
                }
            };

            await updateAttentionPoint(payload);
        } catch (error) {
            console.log('UpdateAttentionPoint', error);
        }
    };

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Atualizar ponto de atenção
                </Text>
            }
            contentClassnames="w-[600px]"
            closeOnClickOutside={false}
            onClose={handleBack}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="p-4 pt-0" onSubmit={handleSubmit(submit)}>
                    <AttentionPointFields control={control} people={people} errorPrefix={formState.errors} />
                    <div className="flex items-center mt-6">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default UpdateAttentionPoint;
