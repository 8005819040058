import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { ProjectTypeDocumentPayload } from 'types/models/project';
import { createOrUpdateProjectTypeDocumentsKey, getProjectTypeDocumentsKey } from './graphql';

const useCreateOrUpdateDocument = (projectProposalTypeId?: string, projectTypeDocumentId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: ProjectTypeDocumentPayload) => {
        const method = projectTypeDocumentId ? api.patch : api.post;
        const endpoint = projectTypeDocumentId ? `/project-types/${projectProposalTypeId}/documents/${projectTypeDocumentId}` : `/project-types/${projectProposalTypeId}/documents`;

        return method<{ id: number }>(endpoint, payload);
    };

    return useMutation(createOrUpdateProjectTypeDocumentsKey(projectProposalTypeId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectTypeDocumentsKey(Number(projectProposalTypeId)));

            navigate(-1);

            showToast(`Documento ${projectTypeDocumentId ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${projectTypeDocumentId ? 'atualizar' : 'criar'} o documento`, 'danger');
        }
    });
};

export default useCreateOrUpdateDocument;
