import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import api from 'services/api';

const useDownloadReport = (endpoint: string) => {
    const { showToast } = useToast();
    const request = async () => {
        const { data } = await api.get<{ url: string }>(`${endpoint}?type=export`);

        return data?.url;
    };
    return useMutation(request, {
        onSuccess: (url) => {
            window.open(url, '_blank');
        },
        onError: () => {
            showToast('Ocorreu um erro ao tentar baixar o arquivo Excel, por favor, tente novamente,', 'danger');
        }
    });
};

export default useDownloadReport;
