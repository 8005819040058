import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';
import project, { getActiveCisoKey } from './graphql';
import { ProjectManagementInformation } from 'types/models/project';

type Response = {
    project_management_information: Partial<ProjectManagementInformation>[];
};

const useGetActiveCiso = (hasCiso: boolean, projectId?: number) => {
    const request = () => apiGraphQl<Response>(project.getAtiveCiso(projectId!)).then((response) => response.project_management_information?.[0]);

    return useQuery(getActiveCisoKey(projectId), request, { enabled: Boolean(projectId) && hasCiso });
};

export default useGetActiveCiso;
