// Dependencies
import findIndex from 'lodash/findIndex';
import React, { createContext, useReducer } from 'react';

// Helpers
import * as Types from './types';

const INITIAL_STATE: Types.MeetingRecordState = {
    completeds: [],
    content: {
        files: [],
        meeting: {
            dateFormatted: '',
            editors: [],
            project: 0,
            title: ''
        },
        participants: {
            external: [],
            internal: []
        },
        subjects: []
    },
    currentStep: {
        slug: Types.MeetingSteps.Meeting,
        label: 'Detalhes'
    },
    isLoading: false,
    steps: [
        {
            slug: Types.MeetingSteps.Meeting,
            label: 'Detalhes'
        },
        {
            slug: Types.MeetingSteps.Subjects,
            label: 'Tópicos'
        },
        {
            slug: Types.MeetingSteps.Files,
            label: 'Arquivos'
        },
        {
            slug: Types.MeetingSteps.Participants,
            label: 'Participantes'
        },
        {
            slug: Types.MeetingSteps.Publish,
            label: 'Publicar'
        }
    ]
};

const MeetingRecordContext = createContext({} as Types.MeetingRecordContextType);

const actions = new Map<Types.MeetingRecordActions, keyof Types.MeetingRecordState>([
    ['SET_CONTENT', 'content'],
    ['SET_CURRENT_STEP', 'currentStep'],
    ['SET_STEP_COMPLETED', 'completeds'],
    ['TOGGLE_LOADER', 'isLoading']
]);

const MeetingRecordProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer((state: Types.MeetingRecordState, stateAction: Types.ReducerAction) => {
        try {
            const action = actions.get(stateAction.type);

            if (!action) {
                throw new Error();
            }

            return { ...state, [action]: stateAction.payload };
        } catch (error) {
            console.log('error', error);
            return state;
        }
    }, INITIAL_STATE);

    const getStep = (slug: Types.MeetingSteps) => state.steps.find((item) => item.slug === slug);

    const getNextStep = () => {
        const ix = findIndex(state.steps, { slug: state.currentStep.slug });

        return state.steps[ix + 1];
    };

    const completeStep = (step: Types.Step) => {
        const stepExistsOnCompletedArray = state.completeds.some((item) => item.slug === step.slug);
        const completedPayload = stepExistsOnCompletedArray ? state.completeds : [...state.completeds, state.currentStep];

        dispatch({ type: 'SET_STEP_COMPLETED', payload: completedPayload });
    };

    const setCurrentStep = (slug: Types.MeetingSteps) => {
        const step = getStep(slug);

        dispatch({ type: 'SET_CURRENT_STEP', payload: step });
    };

    const changeStep = (currentStepSlug: Types.MeetingSteps, nextStepSlug?: Types.MeetingSteps, previousStepSlug?: Types.MeetingSteps) => {
        const step = getStep(currentStepSlug);

        if (!step) {
            throw new Error();
        }

        completeStep(step);

        if (previousStepSlug) {
            return setCurrentStep(previousStepSlug);
        }

        if (nextStepSlug) {
            setCurrentStep(nextStepSlug);
        }
    };

    const setContent = (payload: any, jumpToNextStep = true) => {
        dispatch({ type: 'SET_CONTENT', payload: { ...state.content, ...payload } });

        if (jumpToNextStep) {
            const nextStep = getNextStep();

            changeStep(state.currentStep.slug, nextStep.slug);
        }
    };

    const values = {
        state,
        setCurrentStep,
        changeStep,
        completeStep,
        getStep,
        setContent
    };

    return <MeetingRecordContext.Provider value={values}>{children}</MeetingRecordContext.Provider>;
};

export { MeetingRecordContext, MeetingRecordProvider };
