import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { CommercialBudgetPayload, CommercialBudgetResponse } from 'types/models/commercial';

const getRequestMethodOrEndpoint = (budgetId?: string, projectId?: string) => ({
    method: Boolean(budgetId) ? api.patch : api.post,
    endpoint: Boolean(budgetId || projectId) ? `/budgets/${budgetId || projectId}` : '/budgets'
});

const useCreateOrUpdateCommercialFP = (budgetId?: string, params?: any, projectId?: string) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const request = async (payload: CommercialBudgetPayload) => {
        const { endpoint, method } = getRequestMethodOrEndpoint(budgetId, projectId);

        return method<CommercialBudgetResponse>(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('budget', params));

            showToast(`FP ${!!budgetId ? 'atualizado' : 'cadastrado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${!!budgetId ? 'atualizar' : 'cadastrar'} o FP`, 'danger');
        }
    });
};

export default useCreateOrUpdateCommercialFP;
