// Dependencies
import { useQuery } from 'react-query';
import api from 'services/api';

// Helpers
import { ProjectTypeDocument } from 'types/models/project';
import { getProjectTypeDocumentKey } from './graphql';

const useGetDocument = (projectProposalTypeId?: string, projectTypeDocumentId?: string, isTechnical?: boolean) => {
    const endpoint = isTechnical ? `projects/${projectProposalTypeId}/documents/${projectTypeDocumentId}` : `/project-types/${projectProposalTypeId}/documents/${projectTypeDocumentId}`;

    const fetcher = async () => api.get<ProjectTypeDocument>(endpoint).then(({ data }) => data);

    return useQuery(getProjectTypeDocumentKey(projectProposalTypeId, projectTypeDocumentId), fetcher);
};

export default useGetDocument;
