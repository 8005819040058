import ButtonBase from '@mui/material/ButtonBase';
import { generatePath, NavLink } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import Icon from 'components/core/icon';
import useConfig from 'store/config/use-config';
import classNames from 'classnames';
import { Page } from 'types/models/page';
import useCreateNav from 'hooks/nav/use-create-nav';
import { useMemo } from 'react';

type OperationalDetailsPageNaverProps = {
    subpages: Page[];
    taskPlanningId?: number;
};

const OperationalDetailsPageNavbar = ({ subpages, taskPlanningId }: OperationalDetailsPageNaverProps) => {
    const { palette } = useTheme();
    const { config, setConfig } = useConfig();

    const nav = useCreateNav<Pick<Page, 'title' | 'link'>>(subpages, (subpage) => ({
        link: subpage.link.includes('planejamento-de-tarefas') ? generatePath(subpage.link, { taskPlanningId }) : subpage.link,
        title: subpage.title
    }));

    const buttonClassNames = useMemo(() => {
        return classNames(
            'z-[111] w-9 h-9 bg-base-200 rounded-br flex items-center justify-center hover:bg-base-300 transition-colors',
            !config?.operational?.isNavbarOpen ? 'absolute left-0 !md:right-[36px] w-[80px] top-[70px] md:top-0' : 'absolute right-[-35px]  lg:!top-0'
        );
    }, [config?.operational?.isNavbarOpen]);

    return (
        <div className={`transition-all ${config?.operational?.isNavbarOpen ? 'z-[200] fixed md:relative top-[70px] md:top-0 left-0 md:h-auto h-[100%] w-[242px] bg-base-200' : 'h-0 w-[0px]'}`}>
            <button type="button" className={buttonClassNames} onClick={() => setConfig('operational', { ...config.operational, isNavbarOpen: !config?.operational?.isNavbarOpen })}>
                {!config?.operational?.isNavbarOpen && <span className={`mr-1 text-heading`}>MENU</span>}{' '}
                <Icon name={config.operational.isNavbarOpen ? 'ico-chevron-left' : 'ico-chevron-right'} color={palette.heading} width={16} height={16} />
            </button>
            <div className="bg-base-200 w-full h-[calc(100%-70px)] md:h-full overflow-y-auto">
                <div className="overflow-hidden min-h-[48px] flex flex-col">
                    <div className="relative inline-block flex-1 whitespace-nowrap overflow-x-hidden overflow-y-auto">
                        <div className="flex flex-col">
                            {nav.map((item, index) => {
                                return (
                                    <ButtonBase
                                        key={index}
                                        component={NavLink}
                                        end={!Boolean(item.link.length)}
                                        to={item.link}
                                        className="bg-base-200 border-b border-solid border-b-base-300 items-start w-full p-6 max-w-none text-sm font-medium leading-[1] justify-start"
                                        // @ts-ignore
                                        style={({ isActive }) => (isActive ? { color: palette.primary[500], backgroundColor: palette.grey[100] } : { color: palette.grey[600] }) as any}>
                                        {item.title}
                                    </ButtonBase>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OperationalDetailsPageNavbar;
