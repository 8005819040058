// Helpers
import { Person } from './person';
import { BaseModel } from './base';
import { Project, ProjectMeetingRecordSubject, ProjectProposalType } from './project';
import { Timeline, TimelineBudget, TimelineType } from './timeline';
import { File } from './file';
import { CamelCase } from 'types/utils';
import { Provider } from './provider';
import { PaletteColor } from '@mui/material';

export enum TaskType {
    Progress = 1,
    Hour
}

export type Task = {
    budget?: Partial<TimelineBudget>;
    project_proposal_type_id: number;
    person_id: number;
    task_father_id: number;
    timeline_id: number;
    project_meeting_record_subject_id: number;
    code: string;
    title: string;
    description: string;
    deadline: string;
    person: Partial<Person>;
    project_meeting_record_subject: Partial<ProjectMeetingRecordSubject>;
    project_proposal_type: Partial<ProjectProposalType>;
    timeline: Partial<Timeline>;
    timelineByTimelineId: number;
    order: number;
    tasks?: Task[];
    task_status_id: number;
    task_status: Partial<TaskStatus>;
    taskStatus: Partial<TaskStatus>;
    startDate?: string;
    endDate?: string;
    progressPercentage: number;
    progress_percentage: number;
    mandatory: boolean;
    files: Array<{
        id: number;
        file: CamelCase<File>;
        featuredImage: boolean;
    }>;
    featuredImages: Task['files'];
    taskFather?: Task;
    progress: TaskProgress[];
    comments: TaskComment[];
    accomplishedTime: number | null;
    predictedTime: string | null;
    predictedPercentage: number;
    type: TaskType;
    canReportProgress: boolean;
    duration: number;
    project: Pick<Project, 'id' | 'code' | 'title'>;
    provider: Partial<CamelCase<Provider>>;
    color?: PaletteColor;
    isChildren?: boolean;
    late?: boolean;
} & BaseModel;

export type TaskComment = {
    person: Person;
    text: string;
    createdAt: string;
} & BaseModel;

export type TaskProgress = {
    id: number;
    date: string;
    observations: string;
    percentage: number;
    hour: number;
    person: Person;
    files: Task['files'];
    createdAt?: string;
};

export type TaskStatus = {
    id: number;
    type: TimelineType;
    title: string;
    slug: 'pending' | 'stopped' | 'processing' | 'done' | 'partial';
    tasks: Partial<Task>[];
};
