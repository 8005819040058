import { useMemo } from 'react';

import { useOutletContext, useParams } from 'react-router-dom';

import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';

import { CreateClassificationPayload } from 'types/models/configurations-classifications';
import useGetClassificationId from 'services/queries/configs-classifications/use-get-classification-by-id';
import useCreateOrUpdateClassification from 'services/queries/configs-classifications/use-create-update-classification-asset';

import { ClassificationType } from 'types/models/classification';
import { createOrUpdateClassificationMutation } from 'pages/private/config-classifications/utils/utils';
import CreateUpdateClassificationProviderServices from './create-or-update';

const CreateConfigClassificationProviderServicesPage = ({ title, type }: CrudPageProps & { type: ClassificationType }) => {
    const { providerServiceId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<CreateClassificationPayload>>();

    const {
        mutateAsync: mutateCreateOrUpdateClassification,
        isLoading: isSubmitting,
        error: errorCreateUpdate
    } = useCreateOrUpdateClassification(providerServiceId, params, createOrUpdateClassificationMutation(providerServiceId));

    const { data: classificationDetails, isLoading: isLoadingDetails, error: errorGetClassificationId } = useGetClassificationId(providerServiceId);

    const error = useMemo(() => errorCreateUpdate || errorGetClassificationId, [errorCreateUpdate, errorGetClassificationId]);

    const isBuilding = useMemo(() => isSubmitting || isLoadingDetails, [isSubmitting, isLoadingDetails]);

    const handleSubmit = async (data: CreateClassificationPayload) => {
        try {
            await mutateCreateOrUpdateClassification({ ...data, type });
        } catch (error) {
            console.log('error', error);
        }
    };

    const newDefaultValues = useMemo<Partial<CreateClassificationPayload>>(
        () => ({
            name: classificationDetails?.name,
            classification_id: classificationDetails?.classification?.id
        }),
        [classificationDetails?.name, classificationDetails?.classification?.id]
    );

    return (
        <CreateUpdateClassificationProviderServices isBuilding={isBuilding} isSubmitting={isSubmitting} onSubmit={handleSubmit} defaultValues={newDefaultValues} error={error} title={title || ''} />
    );
};

export default CreateConfigClassificationProviderServicesPage;
