// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { Pagination } from 'types/pagination';
import businessTypes, { getAllBusinessTypesOptionsKey } from './graphql';

const useGetBusinessTypesOptions = () => {
    const getAllBusinessTypes = () => apiGraphQl<Pagination<Option>>(businessTypes.getAll).then((data) => data.items);

    return useQuery(getAllBusinessTypesOptionsKey, getAllBusinessTypes);
};

export default useGetBusinessTypesOptions;
