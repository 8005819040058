// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import useAuth from 'store/auth/use-auth';
import { Pagination } from 'types/pagination';
import customers, { getAllCustomersOptionsKey } from './graphql';

const useGetCustomersOptions = () => {
    const { auth } = useAuth();
    const account_id = auth.credentials.account.id;

    const getAllCustomersOptions = () => apiGraphQl<Pagination<Option>>(customers.getAll, { account_id }).then((data) => data.items);

    return useQuery(getAllCustomersOptionsKey, getAllCustomersOptions);
};

export default useGetCustomersOptions;
