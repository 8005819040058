// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { createPersonContractKey } from './keys';
import { getPeopleKey } from '../people/graphql';

const usePersonContractMutation = (props: { contractId?: string; personId?: string }) => {
    const { contractId, personId } = props || {};

    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const isEdit = !!contractId;

    const request = async (payload: any) => {
        const apiService = isEdit ? api.patch : api.post;
        const endpoint = isEdit ? `/people/${personId}/contracts/${contractId}` : `/people/${personId}/contracts`;

        return apiService<any>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(createPersonContractKey, request, {
        onSuccess: () => {
            navigate(-1);

            // Invalidate person details
            queryClient.invalidateQueries(getPeopleKey(String(personId!)));

            // Invalidate the list of contracts
            // TODO: How can we pass the correct params here?
            queryClient.invalidateQueries(
                getAllKey('person_contract', {
                    limit: 10,
                    page: 1,
                    offset: 0,
                    where: {
                        person_id: {
                            _eq: ~~(personId || 0)
                        },
                        status: {
                            _neq: -1
                        }
                    }
                })
            );

            showToast(`Contrato ${isEdit ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isEdit ? 'atualizar' : 'criar'} o contrato`, 'danger');
        }
    });
};

export default usePersonContractMutation;
