import Input from 'components/core/form/input';
import Icon from 'components/core/icon';
import Select from 'components/core/form/select';
import { useEffect, useState } from 'react';
import useDebounce from 'hooks/keyboard/use-debounce';
import { OperationalOrderBy, useGetOperationalOrderBy } from 'services/queries/operationals/use-get-operational-order-by';
import { Option } from 'types/general';
import { ProjectTypeRouteSlug } from 'services/queries/operationals/use-get-operational';
import isEqual from 'lodash/isEqual';

type QuickFilterProps = {
    onChangeSearch(value: string): void;
    onChangeOrderBy(value: OperationalOrderByValue): void;
    orderBy?: OperationalOrderByValue;
    projectTypeSlug?: ProjectTypeRouteSlug;
};

const QuickFilter = ({ onChangeSearch, onChangeOrderBy, projectTypeSlug, orderBy }: QuickFilterProps) => {
    const { data: orderByItems = [] } = useGetOperationalOrderBy({ projectTypeSlug });

    const [text, setText] = useState<string>('');

    const debouncedText = useDebounce(text, 1000);

    useEffect(() => {
        onChangeSearch(debouncedText);
    }, [debouncedText, onChangeSearch]);

    const options: Option<OperationalOrderByValue>[] = orderByItems.map(({ title: label, ...value }) => ({
        label,
        value
    }));

    const selectedOption = options.find(({ value }) => isEqual(value, orderBy));

    return (
        <div className="flex flex-col lg:flex-row items-center gap-4 mb-5">
            <Input
                parentClassName="bg-white rounded-[14px]"
                left={<Icon className="ml-4 text-base-400" name="ico-search" width={16} height={16} />}
                autoComplete="nope"
                placeholder="Pesquisar por nome..."
                value={text}
                onChange={(e) => setText(e?.target?.value)}
            />

            <Select
                isMulti={false}
                parentClassName="w-full lg:w-[300px] lg:min-w-[300px] rounded-[14px]"
                value={selectedOption}
                options={options}
                onChange={(option: any) => onChangeOrderBy(option?.value as OperationalOrderByValue)}
                placeholder="Ordernar por"
            />
        </div>
    );
};

export default QuickFilter;

export type OperationalOrderByValue = Omit<OperationalOrderBy, 'title'> & { title?: string };
