import React, { useCallback, useMemo } from 'react';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import DatatableFooter from './components/footer';
import DatatableActions from './components/actions';
import Checkbox from 'components/core/form/checkbox';
import { Modify } from 'types/general';
import dictionary from 'utils/dictionary';
import { DatatableColumn, ItemsPerPage } from 'types/graphql';
import DataTableSearchInput from './components/search-input';
import classNames from 'classnames';
import { Rule } from 'types/permissions';
import { MenuItem } from 'components/menu';
import TableCell from '@mui/material/TableCell/TableCell';
import Text from 'components/core/text';
import { useLocation, useNavigate } from 'react-router-dom';

const NOT_FOUND_INDEX = -1;

export type ModifiedDataTableOptions = Modify<MUIDataTableOptions, { rowsPerPage?: ItemsPerPage }>;

type ModifiedDataTableProps = Modify<
    MUIDataTableProps,
    {
        title?: string;
        columns: DatatableColumn[];
        options?: ModifiedDataTableOptions;
    }
>;

type DatatableProps = {
    footerLeft?: React.ReactNode;
    loading?: boolean;
    withItemsPerPage?: boolean;
    onDelete?: (id?: number, slug?: string) => void;
    withBorder?: boolean;
    hideFooter?: boolean;
    data?: any;
    actions?: Rule[];
    advancedSearchComponent?: React.ReactNode;
    customActions?: MenuItem[] | any;
    canShowDeleteButton?: (item: any) => boolean;
} & ModifiedDataTableProps;

const Datatable = ({
    actions,
    columns,
    data,
    footerLeft,
    loading = false,
    options,
    withItemsPerPage = true,
    onDelete,
    withBorder = false,
    hideFooter = false,
    advancedSearchComponent,
    customActions,
    canShowDeleteButton,
    ...props
}: DatatableProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const customRoutePath = useMemo(() => columns.find((item) => item.name === 'actions')?.customRoutePath, [columns]);

    const canShow = actions?.includes(Rule.Show);

    const items = useMemo(() => {
        return data.map((item) => ({
            ...item,
            ...(!!actions?.length && {
                actions: (
                    <DatatableActions
                        customActions={customActions}
                        canShowDeleteButton={canShowDeleteButton}
                        customRoutePath={customRoutePath}
                        item={item}
                        key={(item as any).id}
                        actions={actions}
                        onDelete={onDelete}
                    />
                )
            })
        }));
    }, [actions, data, customRoutePath, customActions, canShowDeleteButton, onDelete]);

    const { searchPlaceholder } = options || {};

    const className = classNames('rounded-[20px]', withBorder && 'border-base-300 border');

    const getCustomHeadRender = (item: DatatableColumn) => {
        const hasCustomSortOrHeadRender = [true, undefined].includes(item.options?.sort) || item.options?.customHeadRender;

        if (hasCustomSortOrHeadRender) {
            return item.options?.customHeadRender;
        }

        return (columnMeta: any) => {
            return (
                <TableCell>
                    <Text variant="body.medium.2xs" className="text-base-500 uppercase">
                        {columnMeta.label}
                    </Text>
                </TableCell>
            );
        };
    };

    const formattedColumns = columns.map((item) => ({
        ...item,
        options: {
            ...item.options,
            customHeadRender: getCustomHeadRender(item)
        }
    }));
    const handleCellClick = useCallback(
        (...args) => {
            const [, cellMeta] = args;
            const actionsColumnIndex = columns.findIndex((item) => item.name === 'actions');
            const hasColumnIndex = actionsColumnIndex !== NOT_FOUND_INDEX;
            const dataItem = data[cellMeta.rowIndex];

            const hasSelectedRows = !!options?.selectableRowsOnClick;
            const hasExpandableRows = !!options?.expandableRows || !!options?.expandableRowsOnClick;

            const isColumnLink = () => {
                const target = cellMeta.event.target as HTMLAnchorElement;
                const parentTarget = target?.parentElement as HTMLAnchorElement;

                return !!target?.href || !!parentTarget?.href;
            };

            const isActionsColumn = cellMeta.colIndex === actionsColumnIndex;
            const canCellClick = !isActionsColumn && !isColumnLink() && !hasExpandableRows && !hasSelectedRows;

            const routePath = customRoutePath?.(dataItem);

            const handleGoTo = (event: React.MouseEvent<Element, MouseEvent>, url: string) => {
                if (event.ctrlKey || event.metaKey) {
                    return window.open(url, '_blank');
                }

                return navigate(url);
            };

            if (!!customRoutePath && canCellClick) {
                return handleGoTo(cellMeta.event, routePath!);
            }

            if (canShow && hasColumnIndex && canCellClick) {
                const { id } = dataItem;

                const url = routePath || `${pathname}/${id}`;
                return handleGoTo(cellMeta.event, url);
            }
        },
        [canShow, columns, customRoutePath, data, navigate, options?.expandableRows, options?.expandableRowsOnClick, options?.selectableRowsOnClick, pathname]
    );

    const handleChangeRowProps = useCallback(() => {
        const actionsColumnIndex = columns.findIndex((item) => item.name === 'actions');
        const hasColumnIndex = actionsColumnIndex !== NOT_FOUND_INDEX;

        return {
            style: {
                cursor: canShow && hasColumnIndex ? 'pointer' : 'auto'
            }
        };
    }, [canShow, columns]);

    return (
        <div className={className}>
            <MUIDataTable
                {...props}
                columns={formattedColumns}
                data={items}
                options={{
                    textLabels: dictionary.content.datatable,
                    responsive: 'standard',
                    onCellClick: handleCellClick,
                    setRowProps: handleChangeRowProps,
                    ...options,
                    customSearchRender: (searchText, handleSearch) => (
                        <DataTableSearchInput
                            advancedSearchComponent={advancedSearchComponent}
                            searchPlaceholder={searchPlaceholder || 'Buscar'}
                            searchText={Boolean(advancedSearchComponent) ? searchText || '' : options?.searchText || ''}
                            handleSearch={handleSearch}
                        />
                    ),
                    searchProps: {
                        // @ts-ignore
                        autoFocus: !options?.searchAlwaysOpen
                    },
                    fixedHeader: true
                }}
                components={{
                    Checkbox,
                    TableFooter: (footerProps) => (hideFooter ? null : <DatatableFooter loading={loading} footerLeft={footerLeft} withItemsPerPage={withItemsPerPage} {...footerProps} />)
                }}
            />
        </div>
    );
};

export default Datatable;
