// Helpers
import { Status } from 'types/general';

export const getAllPersonContractStatusOptionsKey = ['person_contract_status', 'all', 'options'];

export const createPersonContractStatusOptionsKey = ['person_contract_status', 'create'];

const queries = {
    getAllOptions: `query getPersonContractStatusOptions {
        items: person_contract_status(order_by: {title: asc}, where: {status: {_eq: ${Status.Active}}, is_visible: {_eq: true}}) {
          value: id
          label: title
        }
      }
      `
};

export default queries;
