import { useQuery } from 'react-query';
import project, { getChecklistsKey } from './graphql';
import apiGraphQl from 'services/graphql';
import { Timeline, TimelineType } from 'types/models/timeline';

export type TimelineResponse = {
    timeline: Partial<Timeline>[];
};

const useGetTimelines = (projectProposalTypeId?: string) => {
    const fetcher = () => apiGraphQl<TimelineResponse>(project.getTimelines, { projectProposalTypeId, type: TimelineType.Timeline }).then((data) => data.timeline);

    return useQuery(getChecklistsKey(projectProposalTypeId), fetcher);
};

export default useGetTimelines;
