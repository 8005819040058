import React from 'react';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { UseQueryResult } from 'react-query';
import { MainActivity } from 'types/models/report';
import HorizontalBarChart from './horizontal-bar-chart';
import theme from 'settings/theme';

const colors = ['#b3d3d3', theme.extend.colors.secondary[500]];

const MainActivities = ({ isLoading, isError, data: mainActivities = [] }: UseQueryResult<MainActivity[], unknown>) => {
    return (
        <div className="border border-base-300 p-4 rounded-[14px] mb-4">
            <Text as="h6" variant="h6" className="text-heading mb-4">
                Cronograma por atividade
            </Text>
            {isLoading ? (
                <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} className="min-h-[100px]" />
            ) : isError ? (
                <Empty title="Dados não encontrados" />
            ) : (
                mainActivities?.map((item, index) => {
                    const isLastItem = mainActivities.length - 1 === index;

                    return (
                        <div key={index} className={`border border-base-300 p-4 rounded-[14px] ${isLastItem ? 'mb-0' : 'mb-4'}`}>
                            <HorizontalBarChart
                                title={item.provider.title}
                                colors={colors}
                                labels={item.graphic.labels}
                                datasets={item.graphic.datasets}
                                className={`${item.graphic.labels.length > 4 ? 'min-h-[650px]' : ''}`}
                            />
                        </div>
                    );
                })
            )}
        </div>
    );
};

export default MainActivities;
