import { useQuery } from 'react-query';
import api from 'services/api';
import { formatDate } from 'utils/date';
import { getReportWeeksKey } from './graphql';

type ApiResponse = {
    order: number;
    id: number;
    startDate: string;
    endDate: string;
    title: string;
};

export const DEFAULT_WEEKS = [{ value: undefined, label: 'Geral' }];

const useGetReportWeeks = (projectProposalTypeId?: number) => {
    const fetcher = async () => {
        return api.get<ApiResponse[]>(`/project-types/${projectProposalTypeId}/reports`).then(({ data }) => {
            const arr = data.map((item) => ({
                value: item.id,
                label: `${item.title} (${formatDate(item.startDate)} - ${formatDate(item.endDate)})`
            }));

            return [...DEFAULT_WEEKS, ...arr];
        });
    };

    return useQuery(getReportWeeksKey(projectProposalTypeId), fetcher, { enabled: Boolean(projectProposalTypeId) });
};

export default useGetReportWeeks;
