import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import useGetDetails from 'services/queries/crud/use-get-details';
import { CRUDDetails } from 'types/graphql';

type Props = {
    details: CRUDDetails;
} & WithPagePermissionsProps;

const CrudDetails = ({ details, ...rest }: Props) => {
    const { paramName = 'id' } = details || {};

    const params = useParams();

    const id = params[paramName];

    const detailsData = useGetDetails(details, id);

    const { component: Component = Fragment } = details;

    return <Component {...detailsData} id={id!} {...rest} />;
};

export default CrudDetails;
