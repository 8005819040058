import { useQuery } from 'react-query';
import { getTermTypeKey } from './keys';
import apiGraphQl from 'services/graphql';
import { TermType } from 'types/models/project';

type Response = {
    term_type_by_pk: Partial<TermType>;
};

const useGetTermType = (query: string, termTypeId?: number) => {
    const request = async () => apiGraphQl<Response>(query).then(({ term_type_by_pk }) => term_type_by_pk);

    return useQuery(getTermTypeKey(termTypeId), request, { enabled: Boolean(termTypeId) });
};

export default useGetTermType;
