import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// Dependencies
import { useOutletContext, useParams } from 'react-router-dom';
import Table from '@mui/material/Table/Table';
import useTheme from '@mui/material/styles/useTheme';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';

// Components
import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Dropzone from 'components/dropzone';
import Button from 'components/core/button';

// Helpers
import { FileApiModel } from 'types/models/file';
import { MeetingSteps } from 'contexts/meeting-record/types';
import { MeetingRecordContext } from 'contexts/meeting-record';
import useCreateOrUpdateMeetingRecord from 'services/queries/projects/use-create-or-update-meeting-record';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import FileRow from 'components/files-row';

const Files = () => {
    const { palette } = useTheme();
    const { meetingRecordId, projectId: projectIdParam } = useParams();

    const ctx = useOutletContext<OperationalDetailsPageContext | undefined>();

    const projectId = useMemo(() => Number(projectIdParam) || ctx?.project?.id, [projectIdParam, ctx?.project?.id]);

    const [files, setFiles] = useState<Array<Partial<File & FileApiModel>>>([]);

    const { state, changeStep, setContent } = useContext(MeetingRecordContext);
    const { mutateAsync: createOrUpdateMeetingRecord, isLoading: isSubmitting } = useCreateOrUpdateMeetingRecord(meetingRecordId, projectId);

    useEffect(() => {
        setFiles(state.content.files);
    }, [state.content.files]);

    const handleUploadFiles = (filesList: File[]) => setFiles([...files, ...filesList]);

    const handleRemoveFromList = useCallback(
        (originalName: string) => {
            const filtered = files.filter((item) => (item.originalName || item.path) !== originalName);

            return setFiles(filtered);
        },
        [files]
    );

    const handlePersistFile = (file: FileApiModel) => {
        setFiles((prev) => {
            return prev.map((item) => ({
                ...item,
                ...(item.path === file.originalName && { ...file, id: file.id })
            }));
        });
    };

    const handleSubmit = async () => {
        try {
            const meetingRecord = await createOrUpdateMeetingRecord({ files: files.map((item) => item.id!) });

            setContent({ files: meetingRecord.files });
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleBack = () => changeStep(state.currentStep.slug, undefined, MeetingSteps.Subjects);

    return (
        <>
            <div className="p-[30px]">
                <Text as="h3" variant="h4" className="text-heading mb-5">
                    Arquivos
                </Text>
                <Dropzone multiple={true} containerClasses="mb-9" onUploadFiles={handleUploadFiles} />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Tipo
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Arquivo
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Status
                                    </Text>
                                </TableCell>
                                <TableCell width={90}>
                                    <Text as="span" className="text-secondary-500">
                                        Ações
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Boolean(files.length) ? (
                                files.map((file, index) => {
                                    return <FileRow key={index} file={file} onPersistFile={handlePersistFile} onRemoveFromList={handleRemoveFromList} />;
                                })
                            ) : (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={4}>
                                        <Empty title="Nenhum arquivo adicionado até o momento." />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="border-t p-2 flex justify-between">
                <Button startIcon={<Icon name="ico-arrow-left" width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                    Voltar
                </Button>
                <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    endIcon={<Icon name="ico-arrow-right" width={18} color={isSubmitting ? palette.grey[400] : palette.secondary[500]} />}
                    type="submit"
                    variant="text"
                    color={isSubmitting ? 'inherit' : 'secondary'}
                    className="min-w-[100px]"
                    onClick={handleSubmit}>
                    Avançar
                </Button>
            </div>
        </>
    );
};

export default Files;
