import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';

const useDeleteProfilePageRule = (profileId?: string) => {
    const request = async () => {
        return apiGraphQl(
            `mutation DeleteProfilePageRules($profileId: Int!) {
            item: delete_profile_page_rule(where: {profile_id: {_eq: $profileId}}) {
                affected_rows
            }
        }`,
            { profileId }
        );
    };

    return useMutation(request);
};

export default useDeleteProfilePageRule;
