import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Text from 'components/core/text';
import Button from 'components/core/button';
import Input from 'components/core/form/input';

// Helpers
import theme from 'settings/theme';

import { ForgotPasswordWithTokenPayload } from 'types/models/user';

// Assets

import { Link } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import Icon from 'components/core/icon';
import { object, ref, string } from 'yup';
import dictionary from 'utils/dictionary';

type ResetPasswordFormProps = {
    onSubmit: (payload: ForgotPasswordWithTokenPayload) => void;
    isSubmitting: boolean;
};

const ResetPasswordForm = ({ onSubmit, isSubmitting }: ResetPasswordFormProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState({
        password: false,
        confirmPassword: false
    });

    const handlePasswordVisibility = useCallback((stateToChange: string) => {
        setIsPasswordVisible((state) => {
            return { ...state, [stateToChange]: !state[stateToChange] };
        });
    }, []);

    const Toggle = useMemo(
        () => (stateKey: string) =>
            (
                <IconButton color="primary" className="p-1.5 m-1.5" onClick={() => handlePasswordVisibility(stateKey)}>
                    <Icon color={theme.extend.colors.primary[500]} width={16} height={16} name={isPasswordVisible[stateKey] ? 'ico-show' : 'ico-hide'} />
                </IconButton>
            ),
        [isPasswordVisible, handlePasswordVisibility]
    );

    const schema = object({
        password: string().required(dictionary.validation.required),
        confirmPassword: string()
            .required(dictionary.validation.required)
            .oneOf([ref('password'), null], dictionary.validation.equal),
        code: string().required(dictionary.validation.required)
    });

    const { control, handleSubmit, formState } = useForm<ForgotPasswordWithTokenPayload>({
        mode: 'onSubmit',
        defaultValues: {
            password: '',
            confirmPassword: '',
            code: ''
        },
        resolver: yupResolver(schema)
    });

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Text as="h1" variant="h1" className={styles.title}>
                Recupere sua senha
            </Text>
            <div className="w-full mb-6">
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <Input {...field} label="Senha" type={isPasswordVisible['password'] ? 'text' : 'password'} error={formState.errors.password?.message} right={Toggle('password')} />
                    )}
                />
            </div>
            <div className="w-full mb-6">
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => {
                        return (
                            <Input
                                {...field}
                                label="Confirmação senha"
                                type={isPasswordVisible['confirmPassword'] ? 'text' : 'password'}
                                error={(formState.errors.confirmPassword?.message as any)?.password}
                                right={Toggle('confirmPassword')}
                            />
                        );
                    }}
                />
            </div>
            <div className="w-full mb-6">
                <Controller name="code" control={control} render={({ field }) => <Input {...field} label="Código" type="text" error={(formState.errors.code?.message as any)?.password} />} />
            </div>
            <Button type="submit" size="medium" variant="contained" color="primary" fullWidth={true} disabled={isSubmitting} loading={isSubmitting}>
                Recuperar senha
            </Button>
            <div className="flex align-center justify-center mt-[5rem] mb-[1.5rem]">
                <Text>
                    <span className="text-base-500">Voltar para o </span>
                    <Link className="text-primary-400" to={'/'}>
                        Login
                    </Link>
                </Text>
            </div>
        </form>
    );
};

const styles = {
    form: 'bg-white w-full rounded-t-2xl p-7 sm:rounded-b-2xl sm:px-20 sm:pt-20 xl:max-w-2xl mb-5 overflow-auto',
    logo: 'flex items-center justify-center w-full flex-1 sm:flex-none sm:mb-10',
    title: 'mb-4 sm:text-center text-heading sm:mb-10'
};

export default ResetPasswordForm;
