// Dependencies
import { useQuery } from 'react-query';

// Helpers
import api from 'services/api';
import { getTimelineKey } from './graphql';
import { Timeline } from 'types/models/timeline';

const useGetTimeline = (projectProposalTypeId: number, timelineId?: string) => {
    const request = async () => {
        const { data } = await api.get<Timeline>(`/project-types/${projectProposalTypeId}/timelines/${timelineId}`);

        return data;
    };

    return useQuery(getTimelineKey(timelineId), request, { enabled: Boolean(projectProposalTypeId) && Boolean(timelineId) });
};

export default useGetTimeline;
