import React from 'react';

// Dependencies
import classNames from 'classnames';

// Components
import Text, { TextVariant } from '../text';

type AvatarProps = {
    firstLetter: string;
    className?: string;
    isLoading?: boolean;
    rounded?: boolean;
    size?: 'micro' | 'small' | 'medium' | 'large';
    src?: string | null;
};

const avatarSizes = new Map<AvatarProps['size'], string>([
    ['micro', 'w-[30px] h-[30px]'],
    ['small', 'w-[40px] h-[40px]'],
    ['medium', 'w-[64px] h-[64px]'],
    ['large', 'w-[150px] h-[150px]']
]);

const textSizes = new Map<AvatarProps['size'], TextVariant>([
    ['micro', 'body.medium.xs'],
    ['small', 'body.medium.2xs'],
    ['medium', 'h4'],
    ['large', 'h4']
]);

const Avatar = ({ className, firstLetter, rounded = true, size = 'small', src }: AvatarProps) => {
    const radius = rounded ? 'rounded-full' : 'rounded-[44px]';
    const figureClasses = classNames(commonAvatar, radius, avatarSizes.get(size), className);
    const textClasses = classNames(commonText);

    return (
        <figure className={figureClasses} style={{ backgroundImage: `url(${src})` }}>
            {!Boolean(src) && (
                <Text variant={textSizes.get(size)} as="figcaption" className={textClasses}>
                    {firstLetter}
                </Text>
            )}
        </figure>
    );
};

const { commonAvatar, commonText } = {
    commonAvatar: 'bg-secondary-100 bg-opacity-50 bg-cover bg-center bg-no-repeat flex items-center',
    commonText: 'block text-center w-full text-secondary-800'
};

export default Avatar;
