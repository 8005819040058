import React, { useState } from 'react';
import theme from 'settings/theme';
import Empty from 'components/empty';
import Text from 'components/core/text';
import { UseQueryResult } from 'react-query';
import Spinner from 'components/core/spinner';
import type { Activities as ActivitiesType } from 'types/models/report';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import ActivitiesTable from './table';

type TabValue = 'DONE' | 'PARTIAL' | 'PENDING';

const Activities = ({ isLoading, isError, data: activities }: UseQueryResult<ActivitiesType, unknown>) => {
    const [tab, setTab] = useState<TabValue>('DONE');

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    return (
        <div className="border border-base-300 rounded-[14px] mb-4">
            <Text as="h6" variant="h6" className="p-4 text-heading">
                Atividades
            </Text>
            {isLoading ? (
                <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} className="min-h-[100px]" />
            ) : isError ? (
                <Empty title="Dados não encontrados" className="px-4" />
            ) : (
                <>
                    <Tabs variant="scrollable" classes={{ root: 'bg-base-100 z-[10]' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                        <Tab value="DONE" label="Executadas" />
                        <Tab value="PARTIAL" label="Em andamento" />
                        <Tab value="PENDING" label="Aguardando Início" />
                    </Tabs>
                    {tab === 'DONE' && <ActivitiesTable data={activities?.done || []} />}
                    {tab === 'PARTIAL' && <ActivitiesTable data={activities?.partial || []} />}
                    {tab === 'PENDING' && <ActivitiesTable data={activities?.pending || []} />}
                </>
            )}
        </div>
    );
};

export default Activities;
