// Dependencies
import { useDispatch } from 'react-redux';

// Helpers
import { useAppSelector } from 'store';
import { Creators as CredentialsActions } from 'store/credentials';
import { Credentials } from 'types/models/credentials';

const useCredentials = () => {
    const dispatch = useDispatch();
    const credentials = useAppSelector(({ credentials }) => credentials);

    return {
        credentials,
        setCredentials: (payload: Credentials) => dispatch(CredentialsActions.setCredentials(payload)),
        clearCredentials: () => dispatch(CredentialsActions.clearCredentials())
    };
};

export default useCredentials;
