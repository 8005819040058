import { useTheme } from '@mui/material';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';

import { useNavigate, useParams } from 'react-router-dom';
import useGetProjectOutcomeById from 'services/queries/operationals/use-get-project-transaction-outcome-by-id';

import { formatDate } from 'utils/date';
import { currencyToFloat, formatMoney } from 'utils/money';
import Badge from 'components/core/badge';
import ExternalButton from 'components/buttons/external';
import Documents from 'pages/private/projects/view/components/type/documents';
import Text from 'components/core/text';
import classNames from 'classnames';
import useResponsive from 'hooks/responsive/use-responsive';

const OutcomePage = () => {
    const navigate = useNavigate();

    const { outcomeId, projectProposalTypeId } = useParams();

    const { palette } = useTheme();

    const { data, isLoading } = useGetProjectOutcomeById(outcomeId, projectProposalTypeId);

    const handleClose = () => navigate(-1);
    const { isMobile } = useResponsive();

    const valueClassNames = {
        mobile: classNames('px-3 sm:px-7 mt-3', Boolean(data?.payday) ? 'text-system-success-600' : 'text-system-warning-600'),
        desktop: classNames(
            'sm:grow w-full sm:w-[35%] relative flex items-center px-7',
            Boolean(data?.payday) ? 'text-system-success-600 bg-system-success-100 bg-opacity-30' : 'text-system-warning-600 bg-system-warning-100'
        )
    };

    return (
        <Modal onClose={handleClose} closeOnClickOutside={false} contentClassnames="w-[900px]">
            {isLoading ? (
                <div className="p-10">
                    <Spinner color={palette.secondary[100]} fixed={false} size={30} />
                </div>
            ) : (
                <div className="py-4">
                    <p className={`px-3 sm:px-7 mb-4 xl:text-3xl font-medium text-heading`}>
                        Despesa <span className={`xl:text-2xl font-normal text-[${palette.primary[200]}]/[0.2]`}>#{data?.code}</span>
                    </p>
                    <hr className="mt-3" />
                    <div id="details-outcome">
                        <div className="mb-5 flex flex-col sm:flex-row sm:h-[130px]">
                            {isMobile ? (
                                <div className={valueClassNames.mobile}>
                                    <p className="text-sm text-heading font-medium">Valor da despesa</p>
                                    <p className="py-2 text-base sm:text-2xl">{formatMoney(currencyToFloat(String(data?.value)))}</p>
                                </div>
                            ) : (
                                <div className={valueClassNames.desktop}>
                                    <p className="text-white text-2xl text-current font-medium">{formatMoney(currencyToFloat(String(data?.value)))}</p>
                                </div>
                            )}

                            <div className="sm:grow px-3 sm:px-7 mt-3 w-[65%]">
                                <p className="text-sm mb-2 text-heading font-medium">Vencimento</p>
                                <p className="text-base mb-2 sm:text-2xl">{formatDate(data?.dueDate)}</p>
                                <Badge className="mt-2" variant={Boolean(data?.payday) ? 'success' : 'warning'}>
                                    {data?.payday ? 'Pago' : 'A vencer'}
                                </Badge>
                            </div>
                        </div>
                        <div id="payday-detail">
                            <p className={`px-3 sm:px-7 text-sm font-medium text-heading mb-2`}>Data de pagamento</p>
                            <p className="mb-2 text-base sm:text-2xl px-3 sm:px-7">{formatDate(data?.payday) || '-'}</p>
                        </div>
                    </div>
                    {data?.provider ? (
                        <>
                            <hr className="my-3" />
                            <div className="mt-5">
                                <p className={`px-3 sm:px-7 mt-3 mb-1 text-sm font-medium text-heading`}>Fornecedor</p>
                                <div id="fornecedores-area" className="grid grid-cols-1 sm:grid-cols-3 mt-4">
                                    <div className="mb-3">
                                        <p className="px-3 sm:px-7 mb-2 text-sm">Nome:</p>
                                        <ExternalButton href={`/app/fornecedores/${data.provider}`}>
                                            <p className="pl-3 sm:pl-7 pr-1 text-secondary-500 text-base sm:text-2xl">{data?.data_provider.title}</p>
                                        </ExternalButton>
                                    </div>
                                    <div className="mb-3">
                                        <p className="px-3 sm:px-7 mb-2 text-sm">Razão Social:</p>

                                        <p className="pl-3 sm:pl-7 pr-1 text-secondary-500 text-base sm:text-2xl mb-2">{data?.data_provider.juridic_name}</p>
                                    </div>
                                    <div className="mb-3">
                                        <p className="px-3 sm:px-7 mb-2 text-sm">CNPJ:</p>

                                        <p className="pl-3 sm:pl-7 pr-1 text-secondary-500 text-base sm:text-2xl">{data?.data_provider.juridic_document}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {data?.classification ? (
                        <>
                            <hr className="my-3" />
                            <div id="classificacao-area">
                                <p className="px-3 sm:px-7 mt-3 mb-1 text-sm font-medium text-heading">Classificação</p>

                                <p className="pl-3 sm:pl-7 pr-1 text-base sm:text-2xl">{data.data_classification?.name}</p>
                            </div>
                        </>
                    ) : null}
                    {data?.service ? (
                        <>
                            <hr className="mt-3" />
                            <div id="classificacao-area">
                                <p className={`px-3 sm:px-7 mb-2 mt-3 text-sm font-medium text-heading`}>Serviço</p>

                                <p className="pl-3 sm:pl-7 pr-1 text-base sm:text-2xl mb-2">{data?.data_service_classification.name}</p>
                            </div>
                            <hr className="mt-3" />
                        </>
                    ) : null}
                    {data?.description ? (
                        <>
                            <div className="mt-3 border-b">
                                <p className="px-3 sm:px-7 mb-2 mt-3 text-sm font-medium text-heading">Descrição</p>
                                <p className="pl-3 sm:pl-7 pr-1 text-base sm:text-2xl mb-2 break-words">{data?.description}</p>
                            </div>
                        </>
                    ) : null}
                    {data?.observation ? (
                        <>
                            <div className="mt-3 border-b">
                                <p className="px-3 sm:px-7 mb-2 mt-3 text-sm font-medium text-heading">Observação</p>
                                <p className="pl-3 sm:pl-7 pr-1 text-base sm:text-2xl mb-2 break-words" dangerouslySetInnerHTML={{ __html: data?.observation }}></p>
                            </div>
                        </>
                    ) : null}
                    <div className="mt-4 px-3 sm:px-7">
                        <Text as="p" className="mt-3 mb-2 text-sm font-medium text-heading">
                            Documentos
                        </Text>
                        <Documents withActions={true} isTransactionOutcome={true} outcomeId={+outcomeId!} projectProposalTypeIdProp={+projectProposalTypeId!} />
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default OutcomePage;
