import { array, boolean, date, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';

const { required, invalid, type } = dictionary.validation;

export const personContractSchema = object({
    branch: number(),
    position: number().required(required),
    shift: number().required(required),
    frequency: number().required(required),
    seniority: number().required(required),
    salary: string().required(required),
    hourValue: number().optional(),
    startDate: date().typeError(type('data')).required(required),
    finalDate: date().nullable(true).notRequired().typeError(type('data')),
    type: number(),
    juridicName: string(),
    juridicFantasyName: string(),
    juridicDocument: string()
});

const personSchema = (isEdit = false, hasContract = false) =>
    object({
        name: string().typeError(required).required(required),
        title: string().typeError(required).required(required),
        document: string().typeError(required).required(required),
        birthday: string().typeError(required).required(required),
        gender: number().typeError(required).required(required),
        motherName: string().nullable(true),
        fatherName: string().typeError(required).nullable(true),
        maritalStatus: number().typeError(required).required(required),
        isUser: boolean().default(false),
        email: string().typeError(required).email(invalid.email).required(required).default(''),
        user: object({
            username: string().typeError(required).required(required).default(''),
            profile: number().positive().typeError(required).required(required)
        })
            .when('isUser', {
                is: true,
                then: (schema) => schema.required(required),
                otherwise: (schema) => schema.nullable().notRequired()
            })
            .default(undefined),
        account: number().typeError(required).required(required).default(1),

        address: object({
            city_id: number().notRequired(),
            state_id: number().notRequired(),
            compliment: string().nullable(true),
            neighbourhood: string().notRequired(),
            number: string().notRequired(),
            street: string().notRequired(),
            zip: string().notRequired()
        })
            .notRequired()
            .nullable(true),
        phones: array(
            object({
                ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)).typeError(required).trim(),
                number: string().typeError(required)
            })
        ),
        ...(!isEdit &&
            !!hasContract && {
                contracts: personContractSchema
            })
    });

export default personSchema;
