// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import states, { getAllStatesOptionsKey } from './graphql';

const useGetStateOptions = () => {
    const getStates = () => apiGraphQl<Pagination<Option>>(states.getAll).then((data) => data.items);

    return useQuery(getAllStatesOptionsKey, getStates);
};

export default useGetStateOptions;
