import { useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useGetPeopleOptions from 'services/queries/people/use-get-people-options';
import useGetProjectProposalType from 'services/queries/projects/use-get-project-proposal-type-by-pk';
import useProjectTypeSlug from 'pages/private/operationals/hooks/use-project-type-slug';
import { PersonType } from 'types/models/person';
import { PersonContractStatusTitle } from 'pages/private/people/details/tabs/contracts';
import CreateOrUpdateManagementResource from './management';
import CreateOrUpdateNonManagementResource from './non-management';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';

export const getPeopleCondition = {
    where: {
        type: { _eq: PersonType.Internal },
        person_contract: {
            contract_status: {
                id: { _eq: PersonContractStatusTitle.Active }
            }
        }
    }
};

const CreateOrUpdateProjectTypeResourcesPage = () => {
    const navigate = useNavigate();
    const { isManagement } = useProjectTypeSlug();
    const { projectProposalTypeId } = useParams();
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const handleClose = () => navigate(-1);

    const { data: people = [], isLoading: isLoadingPeople } = useGetPeopleOptions(getPeopleCondition as any);
    const { data: projectProposalType, isLoading: isLoadingProjectType } = useGetProjectProposalType(projectProposalTypeId);

    const isBuilding = useMemo(() => isLoadingPeople || isLoadingProjectType, [isLoadingPeople, isLoadingProjectType]);

    const resources = useMemo(() => ctx?.projectProposalType?.project_type_person_resources, [ctx]);

    const Component = isManagement ? CreateOrUpdateManagementResource : CreateOrUpdateNonManagementResource;

    return <Component hasManager={Boolean(projectProposalType?.managers?.aggregate?.count)} isBuilding={isBuilding} people={people} resources={resources || []} onClose={handleClose} />;
};

export default CreateOrUpdateProjectTypeResourcesPage;

// TODO: marcar opção de visualização ativa da listagem
