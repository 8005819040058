// Helpers
import { BaseModel } from './base';
import { Provider, ProviderFinancialSetup } from './provider';
import { Project, ProjectProposalType } from './project';
import { Task } from './task';
import { CamelCase } from 'types/utils';

type ReportHourValue = {
    value: number;
    hour: number;
};

export type ReportResume = {
    contract: ReportHourValue;
    realized: ReportHourValue;
    planned: ReportHourValue;
    budget: ReportHourValue;
    forecast: ReportHourValue;
    variation: ReportHourValue;
    eac: ReportHourValue;
    etc: ReportHourValue;
};

type ProjectProposalTypeReport = {
    report?: {
        financialProgress: number;
        hourProgress: number;
        progress: number;
        resume: {
            execution: ReportResume;
            management: ReportResume;
        };
    };
};

export type Timeline = {
    withBudget: boolean;
    project_proposal_type_id: number;
    provider_id: number;
    type: TimelineType;
    tasksByTimelineId: Partial<Timeline>[];
    project_proposal_type: Partial<ProjectProposalType>;
    proposalType: Partial<CamelCase<ProjectProposalType>> & ProjectProposalTypeReport;
    provider: Partial<Provider> | null;
    tasks: Partial<CamelCase<Task>>[];
    startDate: string;
    start_date: string;
    endDate: string;
    end_date: string;
    progress_percentage: number;
    progressPercentage: number;
    budget: Partial<TimelineBudget>;
    project?: Partial<Project>;
    predictedTime?: number;
    statusCreation: TimelineStatusCreation;
    status_creation: TimelineStatusCreation;
    canEditTask: boolean;
} & BaseModel;

export type TimelineBudget = {
    timeline_id: number;
    provider_id: number;
    value: number;
    provider_financial_setups: Partial<ProviderFinancialSetup>[];
    task_budgets: any[];
    provider: Partial<Provider>;
    timeline: Partial<Timeline>;
} & BaseModel;

export enum TimelineType {
    Checklist = 1,
    Timeline,
    MeetingRecord,
    Scope
}

export enum TimelineStatusCreation {
    Started = 1,
    Finalized
}

export type TimelineTaskPayload = {
    title: string;
    timeline: number;
    proposalType: number;
    task?: number;
};
