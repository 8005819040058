import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { Pagination } from 'types/pagination';
import { getRulesKey } from './graphql';

const useGetRules = (query: string) => {
    const fetcher = async () => apiGraphQl<Pagination<Option>>(query).then((data) => data.items);

    return useQuery(getRulesKey, fetcher);
};

export default useGetRules;
