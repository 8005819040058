import React, { useCallback, useEffect, useState } from 'react';

// Dependencies
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';

// Components
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Switch from 'components/core/switch';
import AddressForm from 'components/address';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import DatePicker from 'components/core/datepicker';
import PersonContract from 'components/person-contract';

// Helpers
import theme from 'settings/theme';
import personShema from './utils/schema';
import hexToRgba from 'utils/hex-to-rgba';
import { Modify, Option } from 'types/general';
import { genders, maritalStatuses } from 'utils/statics';
import { CreatePersonContractPayload, CreatePersonPayload } from 'types/models/person';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import Phones from 'pages/private/projects/meeting-records/components/participants/phones';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import Emails from 'components/emails';

export type CustomCreatePersonPayload = Modify<CreatePersonPayload, { phones: string; contracts: CreatePersonContractPayload }> & {
    isUser?: boolean;
    documentIdentity: string;
    documentIssuingAgency: string;
    expeditionDate: string;
    voterRegistration: string;
    voterRegistrationPollingStation: string;
    socialIntegrationProgram: string;
    workCard: string;
    workCardSeries: string;
    emails: {
        type: number;
        email: string;
    }[];
    voterRegistrationConstituency: string;
};

type PeopleProps = {
    branches: Option[];
    isBuilding: boolean;
    isSubmitting: boolean;
    profiles: Option[];
    title: string;
    onChangeUser: (value: boolean) => void;
    onSubmit: (data: CustomCreatePersonPayload) => void;
    defaultValues?: any;
    error?: any;
};

const INITIAL_FORM_STATE = {
    document: '',
    name: '',
    phones: '',
    title: '',
    isUser: false,
    address: {
        compliment: '',
        neighbourhood: '',
        number: '',
        street: '',
        zip: ''
    },
    birthday: undefined,
    contracts: {
        juridicDocument: '',
        juridicFantasyName: '',
        juridicName: '',
        salary: '',
        startDate: ''
    }
};

const CreateOrUpdatePerson = ({ branches, isBuilding, isSubmitting, profiles, title, onChangeUser, onSubmit, defaultValues, error }: PeopleProps) => {
    const navigate = useNavigate();

    const [hasContract, setHasContract] = useState(false);

    const methods = useForm<CustomCreatePersonPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(personShema(!!defaultValues?.id, !!hasContract)),
        defaultValues: INITIAL_FORM_STATE
    });

    const { formState, control, handleSubmit, setValue, watch, reset, setError, resetField } = methods;

    const { showFormErrors } = useShowApiErrors();

    useEffect(() => {
        if (!!error) {
            const blackList = ['contracts.branch'];

            showFormErrors({ error, setError, blackList });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!!defaultValues) {
            let formatDefaultValues: any = {
                ..._pick(defaultValues, [
                    'name',
                    'title',
                    'email',
                    'motherName',
                    'fatherName',
                    'gender',
                    'document',
                    'maritalStatus',
                    'user',
                    'contracts',
                    'birthday',
                    'address',
                    'documentIdentity',
                    'expeditionDate',
                    'documentIssuingAgency',
                    'voterRegistration',
                    'voterRegistrationPollingStation',
                    'voterRegistrationConstituency',
                    'socialIntegrationProgram',
                    'workCard',
                    'workCardSeries'
                ]),
                isUser: !!defaultValues?.user,
                address: {
                    ...defaultValues?.address,
                    state_id: defaultValues?.address?.state?.id,
                    city_id: defaultValues?.address?.city?.id
                }
            };

            if (!!defaultValues?.user) {
                onChangeUser(true);
            }

            formatDefaultValues.phones = [{ phones: defaultValues?.phones || [] }];
            formatDefaultValues.emails = [{ emails: defaultValues?.emails || [] }];

            formatDefaultValues = _omit(formatDefaultValues, ['address.state', 'address.city']);

            reset(formatDefaultValues);
        }
    }, [defaultValues, reset, onChangeUser]);

    useEffect(() => {
        resetField('contracts');
    }, [hasContract, setValue, resetField]);

    const handleClose = () => navigate(-1);

    const isUser = watch('isUser');

    const handleAbleUser = useCallback(
        (value: boolean) => {
            setValue('isUser', value);

            if (!value) {
                setValue('user', undefined);
            }

            if (value && !Boolean(profiles.length)) {
                onChangeUser(value);
            }
        },
        [profiles, onChangeUser, setValue]
    );

    const isEdit = !!defaultValues?.id;

    return (
        <Modal onClose={handleClose} contentClassnames="w-[800px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {title}
                        </Text>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                            <Controller name="name" control={control} render={({ field }) => <Input {...field} label="Nome" error={formState.errors.name?.message} />} />
                            <Controller name="title" control={control} render={({ field }) => <Input {...field} label="Nome para exibição" error={formState.errors.title?.message} />} />
                            <Controller
                                name="document"
                                control={control}
                                render={({ field }) => <Input {...field} label="CPF" autoComplete="nope" mask="999.999.999-99" error={formState.errors.document?.message} />}
                            />
                            <Controller
                                name="birthday"
                                control={control}
                                render={({ field }) => <DatePicker label="Data de nascimento" placeholderText="Selecione uma data" error={formState.errors?.birthday?.message} {...field} />}
                            />
                            <Controller
                                name="maritalStatus"
                                control={control}
                                render={({ field }) => {
                                    const value = maritalStatuses.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={maritalStatuses}
                                            label="Estado civil"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.maritalStatus?.message}
                                            onChange={(option: any) => field.onChange(option.value)}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => {
                                    const value = genders.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={genders}
                                            label="Gênero"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.gender?.message}
                                            onChange={(option: any) => field.onChange(option.value)}
                                        />
                                    );
                                }}
                            />
                            <Controller name="motherName" control={control} render={({ field }) => <Input {...field} label="Nome da mãe" error={formState.errors?.motherName?.message} />} />

                            <Controller name="fatherName" control={control} render={({ field }) => <Input {...field} label="Nome da pai" error={formState.errors?.fatherName?.message} />} />

                            <Controller name="email" control={control} render={({ field }) => <Input {...field} type="email" label="E-mail" error={formState.errors.email?.message} />} />

                            <Controller
                                name="documentIdentity"
                                control={control}
                                render={({ field }) => <Input {...field} label="RG" autoComplete="nope" error={formState.errors.documentIdentity?.message} />}
                            />

                            <Controller
                                name="expeditionDate"
                                control={control}
                                render={({ field }) => <DatePicker label="Data de expedição" placeholderText="Selecione uma data" error={formState.errors?.expeditionDate?.message} {...field} />}
                            />

                            <Controller
                                name="documentIssuingAgency"
                                control={control}
                                render={({ field }) => <Input {...field} type="text" label="Órgão expedidor" error={formState.errors.documentIssuingAgency?.message} />}
                            />
                            <Controller
                                name="voterRegistration"
                                control={control}
                                render={({ field }) => <Input {...field} type="text" label="Título de Eleitor" error={formState.errors.voterRegistration?.message} />}
                            />
                            <Controller
                                name="voterRegistrationPollingStation"
                                control={control}
                                render={({ field }) => <Input {...field} type="text" label="Zona Eleitoral" error={formState.errors.voterRegistrationPollingStation?.message} />}
                            />
                            <Controller
                                name="voterRegistrationConstituency"
                                control={control}
                                render={({ field }) => <Input {...field} type="text" label="Seção Eleitoral" error={formState.errors.voterRegistrationConstituency?.message} />}
                            />
                            <Controller
                                name="socialIntegrationProgram"
                                control={control}
                                render={({ field }) => <Input {...field} type="text" label="PIS" error={formState.errors.socialIntegrationProgram?.message} />}
                            />
                            <Controller name="workCard" control={control} render={({ field }) => <Input {...field} type="text" label="Número da CTPS" error={formState.errors.workCard?.message} />} />
                            <Controller
                                name="workCardSeries"
                                control={control}
                                render={({ field }) => <Input {...field} type="text" label="Série da CTPS" error={formState.errors.workCardSeries?.message} />}
                            />
                        </div>

                        <Emails className="mb-5" accessor="emails" index={0} {...methods} />

                        <Phones className="mb-8" accessor="phones" index={0} {...methods} />

                        <Text as="h4" variant="h5" className="text-heading mb-5">
                            Endereço
                        </Text>
                        <AddressForm {...methods} />
                        {!isEdit && (
                            <div className="-mx-7 px-7 py-5 mb-5">
                                <div className="flex justify-between items-center">
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-700 mb-5">
                                        Esta pessoa terá um contrato?
                                    </Text>
                                    <Switch value={hasContract as boolean} onChange={() => setHasContract((hasContract) => !hasContract)} />
                                </div>
                                {!!hasContract && (
                                    <div>
                                        <Text as="h4" variant="h5" className="text-heading mb-5">
                                            Contrato
                                        </Text>
                                        <PersonContract {...methods} branches={branches} baseName="contracts" className="mb-8" hideFinalDate={true} />
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="-mx-7 px-7 py-5 mb-5" style={{ backgroundColor: hexToRgba(theme.extend.colors.secondary[100], 0.2) }}>
                            <div className="flex justify-between items-center">
                                <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                                    Esta pessoa será usuária do sistema?
                                </Text>
                                <Switch value={isUser as boolean} onChange={handleAbleUser} />
                            </div>
                            {isUser && (
                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
                                    <Controller
                                        name="user.username"
                                        control={control}
                                        render={({ field }) => <Input {...field} label="Nome de usuário" error={formState.errors.user?.username?.message} />}
                                    />
                                    <Controller
                                        name="user.profile"
                                        control={control}
                                        render={({ field }) => {
                                            const value = profiles.find((item) => item.value === field.value);

                                            return (
                                                <Select
                                                    {...field}
                                                    value={value}
                                                    options={profiles}
                                                    label="Perfil de usuário"
                                                    placeholder="Selecione uma opção"
                                                    error={formState.errors.user?.profile?.message}
                                                    onChange={(option: any) => field.onChange(option.value)}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex items-center">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdatePerson;
