import Spinner from 'components/core/spinner';
import { useOutletContext } from 'react-router-dom';
import { CrudPageProps } from 'types/graphql';
import { BaseModel } from 'types/models/base';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import ListPage from 'pages/private/crud/list';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';

const ProviderTabProjects = ({ page }: WithPagePermissionsProps) => {
    const { id } = useOutletContext<Partial<BaseModel>>();

    if (!id) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    const listConfig: CrudPageProps = {
        graphql: {
            table: 'project',
            searchableField: 'title',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'title',
                    label: dictionary.content.graphql.title
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    },
                    customRoutePath: (item) => `/app/contratos/${item?.id}`
                }
            ],
            where: {
                project_proposals: { proposal_types: { timelines: { provider_id: { _eq: id } } } }
            }
        },

        title: 'Projetos',
        hideCreateButton: true,
        withBorder: true
    };

    return <ListPage page={page} {...listConfig} />;
};

export default ProviderTabProjects;
