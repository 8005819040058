// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import jobPositions, { getAllJobPositionsOptionsKey } from './graphql';

const useGetJobPositionsOptions = (enabled = false) => {
    const getJobPositions = () => apiGraphQl<Pagination<Option>>(jobPositions.getAllOptions).then((data) => data.items);

    return useQuery(getAllJobPositionsOptionsKey, getJobPositions, { enabled });
};

export default useGetJobPositionsOptions;
