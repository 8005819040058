import { Dayjs } from 'dayjs';

export type CreateAssetPayload = {
    name: string;
    account_id: number;
    serial_number: number;
    code: string;
    classification_id: number;
    quantity: number;
    modality: AssetsModalities;
    model: string;
    value: number;
    insurance_value: number;
    branch_id: number;
    brand: number;
    person_id?: number;
    classification_department_id?: number;
    observations: string;
    asset_status: string;
    phone: string;
    label: string;
    type: number;
};

export type AssetResponse = {
    items: CreateAssetPayload & {
        id: string;
        classification: { name: string };
        branch: { name: string };
    };
};

export type CreateAssetLeasingPayload = {
    start_date: string | Dayjs;
    asset_id: number;
    person_id: number;
};

export enum AssetsStatus {
    Available = 1,
    InUse,
    Breakdown
}

export enum AssetsModalities {
    Leased = 1,
    Patrimony,
    Own
}

export enum AssetsTechnologyClassifications {
    Machinery = 1025,
    Peripherals,
    Telephony,
    Equipment
}

export enum AssetsFurnitureClassifications {
    HomeAppliances = 1029,
    Furnitures
}
