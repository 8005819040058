// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { GraphqlByPk } from 'types/graphql';
import signpost, { getSignpostClassKey } from './graphql';
import { SignpostClass } from 'types/models/signpost';

const useGetSignpostClass = (signpostClassId?: string) => {
    const getSignpostClass = () => apiGraphQl<GraphqlByPk<Partial<SignpostClass>>>(signpost.getSignpostClass, { id: signpostClassId }).then((data) => data.signpost_class_by_pk);

    return useQuery(getSignpostClassKey(signpostClassId), getSignpostClass, { enabled: Boolean(signpostClassId) });
};

export default useGetSignpostClass;
