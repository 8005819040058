import Avatar from 'components/core/avatar';
import Badge from 'components/core/badge';
import Text from 'components/core/text';
import { Person } from 'types/models/person';
import useTheme from '@mui/material/styles/useTheme';
import PersonDetailsSidebarActions from './actions';
import { PersonContractStatusTitle } from '../tabs/contracts';
import { ColorVariant } from 'types/colors';

type Props = {
    person: Person;
};

const statusColors = new Map<PersonContractStatusTitle, ColorVariant>([
    [PersonContractStatusTitle.Active, 'success'],
    [PersonContractStatusTitle.MaternityLeave, 'grey'],
    [PersonContractStatusTitle.SicknessAllowance, 'grey'],
    [PersonContractStatusTitle.Resignation, 'warning'],
    [PersonContractStatusTitle.Finished, 'error'],
    [PersonContractStatusTitle.Transferred, 'info'],
    [PersonContractStatusTitle.Vacation, 'primary'],
    [PersonContractStatusTitle.MedicalCertificate, 'grey']
]);

const PersonDetailsSidebar = ({ person }: Props) => {
    const { palette } = useTheme();

    return (
        <div className="bg-base-100 border-r border-r-base-300 md:w-[300px] h-full md:overflow-y-auto flex flex-col">
            <div className="items-center flex flex-col border-b border-b-base-300 mb-4 pb-6">
                <Avatar firstLetter={person?.name?.charAt(0)} size="large" className="my-4" src={person?.profileImage?.url} />
                <Text variant="h5" as="h3" className="mb-2 text-center mx-2" style={{ color: palette.heading }}>
                    {person?.title}
                </Text>
                {!!person.activeContract && (
                    <>
                        <Text variant="body.medium.xs" as="span" className="mb-2" style={{ color: palette.heading }}>
                            {person.activeContract?.position?.title}
                        </Text>

                        {!!person.activeContract?.contractStatus?.title && (
                            <Badge variant={statusColors.get(person.activeContract?.contractStatus?.id!) || 'grey'} className="ml-2 w-[137px] text-center">
                                {person.activeContract?.contractStatus?.title}
                            </Badge>
                        )}
                    </>
                )}
            </div>

            <div className="flex flex-col px-4 items-center mb-4 pt-2">
                <PersonDetailsSidebarActions hasUser={!!person.user} personId={person?.id} mainContractId={person.activeContract?.id} />
            </div>
        </div>
    );
};

export default PersonDetailsSidebar;
