import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import { RouteObject } from 'react-router-dom';
import ProjectsReportPage from 'pages/private/reports/projects-report';
import ExpensesReportPage from 'pages/private/reports/expenses-report';
import ResourcesReportPage from 'pages/private/reports/resources-report';

const reports: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Relatório de projetos',
        path: 'relatorio/projetos',
        element: <Page code="relatorios-projetos" permissions={[Rule.List]} Component={ProjectsReportPage} />
    },
    {
        // @ts-ignore
        breadcrumb: 'Relatório de despesas',
        path: 'relatorio/despesas',
        element: <Page code="relatorios-despesas" permissions={[Rule.List]} Component={ExpensesReportPage} />
    },
    {
        // @ts-ignore
        breadcrumb: 'Relatório de recursos',
        path: 'relatorio/recursos',
        element: <Page code="relatorios-recursos" permissions={[Rule.List]} Component={ResourcesReportPage} />
    }
];

export default reports;
