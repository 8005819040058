import useTheme from '@mui/material/styles/useTheme';
import AddButton from 'components/buttons/add';
import FormButtons from 'components/core/form/buttons';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import ErrorMessage from 'components/error/message';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import { useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import { ProjectTypeManagementInformation, ProjectTypePersonResource } from 'types/models/project';
import { NonManagementResourcePayload } from 'types/models/resource';
import { typeManagementInformations } from 'utils/statics';
import useGetProviderOptions from 'services/queries/operationals/use-get-provider-options';
import useGetClassificationOptions from 'services/queries/operationals/use-get-classifications-options';
import { ClassificationType } from 'types/models/classification';
import { Option } from 'types/general';
import { yupResolver } from '@hookform/resolvers/yup';
import { nonManagementResourceSchema } from './utils/schema';
import NonManagementResource from './non-management-resource';
import useCreateOrUpdateProjectTypeResourcesProjects from 'services/queries/projects/use-create-or-update-project-resources';
import omit from 'lodash/omit';

export type CreateOrUpdateResourceProps = {
    people: Option[];
    resources: Partial<ProjectTypePersonResource>[];
    isBuilding: boolean;
    hasManager: boolean;
    onClose: () => void;
};

const CreateOrUpdateNonManagementResource = ({ isBuilding, hasManager, people = [], resources = [], onClose }: CreateOrUpdateResourceProps) => {
    const { palette } = useTheme();
    const { resourceId, projectProposalTypeId } = useParams();
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const isUpdate = !!resourceId;

    const { data: providers = [], isLoading: isLoadingProviders } = useGetProviderOptions(true);
    const { data: services = [], isLoading: isLoadingServices } = useGetClassificationOptions(ClassificationType.ProviderServices, undefined, true);

    const { mutateAsync: createOrUpdateResources, isLoading: isSubmitting } = useCreateOrUpdateProjectTypeResourcesProjects(ctx?.project?.id, Number(projectProposalTypeId), Number(resourceId));

    const methods = useForm<NonManagementResourcePayload>({
        mode: 'onSubmit',
        resolver: yupResolver(nonManagementResourceSchema),
        defaultValues: {
            resources: [{ isExternal: false }]
        }
    });

    const { handleSubmit, formState, control, reset } = methods;

    const { fields, append, remove } = useFieldArray({ control, name: 'resources' });

    const findedResource = useMemo(() => resources?.find((resource) => resource?.id === (+resourceId! as number)), [resourceId, resources]);

    const positions = useMemo(() => {
        return typeManagementInformations.filter((item) => {
            const isNotManager = hasManager ? item.value !== ProjectTypeManagementInformation.Manager : true;
            const isNotDirector = item.value !== ProjectTypeManagementInformation.Director;
            const isNotResident = item.value !== ProjectTypeManagementInformation.Resident;

            const manager = resources?.find((item) => item?.type_management_information === ProjectTypeManagementInformation.Manager);

            if (Boolean(resourceId) && manager?.person?.id === findedResource?.person?.id) {
                return isNotDirector && isNotResident;
            }

            return isNotDirector && isNotManager && isNotResident;
        });
    }, [hasManager, resourceId, findedResource?.person?.id, resources]);

    const handleCreateResource = () => append({});

    const submit = async (data: NonManagementResourcePayload) => {
        try {
            const createPayload = {
                resources: data.resources.map((item) => omit(item, ['isExternal']))
            };

            const [resource] = data.resources;

            const updatePayload = {
                services: resource?.services,
                provider: resource?.provider,
                person: resource?.person,
                typeManagementInformation: resource?.typeManagementInformation
            };

            await createOrUpdateResources(isUpdate ? updatePayload : createPayload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    useEffect(() => {
        if (findedResource) {
            reset({
                resources: [
                    {
                        isExternal: !!findedResource.services?.length,
                        person: findedResource.person?.id,
                        provider: findedResource.provider?.id,
                        services: findedResource.services?.map((item) => item.classification?.id),
                        typeManagementInformation: findedResource.type_management_information
                    }
                ]
            });
        }
    }, [findedResource, reset]);

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {resourceId ? 'Editar' : 'Adicionar'} recurso
                </Text>
            }
            closeOnClickOutside={false}
            contentClassnames="w-[1000px]"
            onClose={onClose}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="pb-5 px-6" onSubmit={handleSubmit(submit)}>
                        <div className="flex items-start">
                            <div className="group flex-1 relative">
                                {fields.map((item, index) => {
                                    return (
                                        <NonManagementResource
                                            key={item.id}
                                            index={index}
                                            isLoadingProviders={isLoadingProviders}
                                            isLoadingServices={isLoadingServices}
                                            onDeleteResource={remove}
                                            people={people}
                                            positions={positions}
                                            providers={providers}
                                            services={services}
                                            findedResource={findedResource}
                                        />
                                    );
                                })}
                                <ErrorMessage visible={Boolean((formState as any).errors?.resources?.message)} className="block mb-4">
                                    {(formState as any).errors?.resources?.message}
                                </ErrorMessage>
                                {!isUpdate && <AddButton onClick={handleCreateResource}>Novo recurso</AddButton>}
                            </div>
                        </div>
                        <FormButtons className="mt-5" isLoading={isSubmitting} onCancel={onClose} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdateNonManagementResource;
