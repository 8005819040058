import { matchRoutes, useLocation } from 'react-router-dom';
import routes from 'settings/routes';

const useMatchRoutes = (basename?: string) => {
    const location = useLocation();

    return matchRoutes(routes, location, basename);
};

export default useMatchRoutes;
