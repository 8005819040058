import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { object, string } from 'yup';
import dictionary from 'utils/dictionary';

export const getRuleQuery = (ruleId?: string) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetRule',
            rule_by_pk: {
                __args: { id: Number(ruleId || 0) },
                name: true,
                key_rule: true
            }
        }
    });
};

export const createOrUpdateRuleMutation = (isEdit = false) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateRule',
                __variables: {
                    ...(isEdit
                        ? {
                              payload: 'rule_set_input',
                              id: 'Int!'
                          }
                        : {
                              payload: 'rule_insert_input!'
                          })
                },
                item: {
                    __aliasFor: isEdit ? 'update_rule_by_pk' : 'insert_rule_one',
                    __args: {
                        ...(isEdit
                            ? {
                                  pk_columns: { id: new VariableType('id') },
                                  _set: new VariableType('payload')
                              }
                            : {
                                  object: new VariableType('payload')
                              })
                    },
                    id: true
                }
            }
        },
        { pretty: true }
    );
};

export const ruleSchema = object({
    name: string().required(dictionary.validation.required),
    key_rule: string().required(dictionary.validation.required)
});
