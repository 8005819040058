import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import { minutesToHours } from 'utils/date';
import Icon from 'components/core/icon';
import { formatMoney } from 'utils/money';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ConfirmModal from 'components/core/modal/confirm';
import { useCallback, useMemo, useState } from 'react';
import useDelete from 'services/queries/crud/use-delete';
import { useQueryClient } from 'react-query';
import { getProjectKey, getProjectProposalTypeKey } from 'services/queries/projects/graphql';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import { ProjectStatusEnum, ProjectTypePersonResource } from 'types/models/project';

type ResourceProjectTableProps = {
    withActions?: boolean;
    resources?: Partial<ProjectTypePersonResource>[];
};

const ResourceProjectTable = ({ resources = [], withActions = true }: ResourceProjectTableProps) => {
    const { palette } = useTheme();
    const queryClient = useQueryClient();
    const { projectProposalTypeId } = useParams();

    const navigate = useNavigate();

    const ctx = useOutletContext<OperationalDetailsPageContext>();

    const [resourceProjectIdToDelete, setResourceProjectIdToDelete] = useState<number | null>(null);

    const { mutateAsync: deleteResource, isLoading: isSubmitting } = useDelete({
        apiPayload: { endpoint: `/project-types/${projectProposalTypeId}/resources`, useApiTodelete: true }
    });

    const tapReleased = useMemo(() => ctx?.project?.project_status?.slug === ProjectStatusEnum.PreTap, [ctx?.project?.project_status?.slug]);

    const handleAddResource = useCallback(() => {
        if (tapReleased) {
            return alert('O TAP deste projeto ainda não foi liberado.');
        }

        navigate(`novo`);
    }, [tapReleased, navigate]);

    const handleDelete = async () => {
        try {
            if (resourceProjectIdToDelete) {
                await deleteResource({ id: resourceProjectIdToDelete });

                setResourceProjectIdToDelete(null);

                queryClient.invalidateQueries(getProjectKey(ctx?.project?.id));
                queryClient.invalidateQueries(getProjectProposalTypeKey(+projectProposalTypeId!));
            }
        } catch (error) {
            console.log('handleDeleteDocument', error);
        }
    };

    const handleGoTo = (path: string) => navigate(path);
    const handleResourceProjectIdToDelete = (value: number | null) => setResourceProjectIdToDelete(value);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text as="span" className="text-secondary-500 ">
                                Colaborador
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-secondary-500">
                                Fornecedor
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text as="span" className="text-secondary-500">
                                Valor Horas
                            </Text>
                        </TableCell>
                        <TableCell className="min-w-[180px]">
                            <Text as="span" className="text-secondary-500">
                                Horas Previstas
                            </Text>
                        </TableCell>
                        <TableCell className="min-w-[180px]">
                            <Text as="span" className="text-secondary-500">
                                Horas Alocadas
                            </Text>
                        </TableCell>
                        <TableCell className="w-[100px]">
                            <Text as="span" className="text-secondary-500">
                                Ações
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resources.map((resource) => {
                        const classifications = resource.services?.map((item) => item.classification.name).join(', ');
                        const hourValue = !!resource.hour_value ? +resource.hour_value : 0;

                        return (
                            <TableRow key={resource.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell className="min-w-[200px] sm:min-w-fit">
                                    <Text as="p" className="text-base-500">
                                        {resource.person?.name || '-'}
                                    </Text>
                                    {Boolean(resource.person?.contracts?.length) && (
                                        <Text as="span" variant="body.regular.xs" className="text-base-500 italic">
                                            {resource.person?.contracts?.map((item) => item.job_position?.title).join(', ')}
                                        </Text>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500 block">
                                        {resource.provider?.title || '--'}
                                    </Text>
                                    {resource.services?.length ? (
                                        <Tooltip
                                            enterTouchDelay={700}
                                            title={
                                                <div>
                                                    <Text as="strong" variant="body.medium.2xs">
                                                        Serviços disponíveis:{' '}
                                                    </Text>
                                                    <div className="max-h-[100px] overflow-auto">
                                                        {resource.services?.map((item, index) => (
                                                            <Text as="p" variant="body.regular.sm" key={index}>
                                                                - {item.classification.name}
                                                            </Text>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                            placement="top">
                                            <span>
                                                <Text as="p" className="text-ellipsis text-[0.65rem] max-w-[150px] overflow-hidden inline-block text-system-info-500">
                                                    {classifications}
                                                </Text>
                                            </span>
                                        </Tooltip>
                                    ) : null}
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {formatMoney(hourValue)}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {!resource.provider?.id
                                            ? `${minutesToHours(resource.predicted_time || 0)}h / ${formatMoney(hourValue * +minutesToHours(resource?.predicted_time!, 'number') || 0)}`
                                            : '--'}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {!resource.provider?.id
                                            ? `${minutesToHours(resource.accomplished_time || 0)}h / ${formatMoney(hourValue * +minutesToHours(resource.accomplished_time!, 'number') || 0)}`
                                            : '--'}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <div className="flex">
                                        <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                            <IconButton className="hover:bg-system-warning-100 hover:bg-opacity-30" onClick={handleGoTo.bind(this, `editar/${resource.id}`)}>
                                                <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                            <IconButton className="hover:bg-system-danger-100 hover:bg-opacity-30" onClick={handleResourceProjectIdToDelete.bind(this, resource.id || null)}>
                                                <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                {withActions && (
                    <TableFooter>
                        <TableRow classes={{ root: 'border-t' }}>
                            <TableCell colSpan={6} classes={{ root: 'border-none px-0' }}>
                                <AddButton onClick={handleAddResource}>Novo recurso</AddButton>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
            {Boolean(resourceProjectIdToDelete) && (
                <ConfirmModal
                    title="Apagar recurso"
                    description="Você tem certeza que deseja apagar este recurso?"
                    onClose={() => setResourceProjectIdToDelete(null)}
                    isLoading={isSubmitting}
                    onConfirmAction={handleDelete}
                />
            )}
        </TableContainer>
    );
};

export default ResourceProjectTable;
