import { User } from './user';
import { Branch } from './branch';
import { BaseModel } from './base';
import { Account } from './account';
import { Address } from './address';
import { Project } from './project';
import { JobPosition } from './job-position';
import { Frequency, Seniority, Shift } from 'types/general';
import { File } from './file';

export enum MaritalStatus {
    Single = 1,
    Married,
    Divorced,
    Widowed
}

export enum Gender {
    Female = 1,
    Male,
    Other
}

export type Credentials = {
    account: Account;
    user: User;
};

export type Phone = {
    ddd: string;
    number: string;
};

export type Person = {
    document: string;
    birthday: string;
    gender: Gender;
    marital_status: MaritalStatus;
    maritalStatus?: MaritalStatus;
    name: string;
    title: string;
    phones: Phone[];
    account?: Partial<Account>;
    address?: Address;
    personContract?: PersonContract;
    person_contract?: PersonContract;
    activeContract?: Partial<PersonContract>;
    contracts?: Partial<PersonContract>[];
    projects?: Project[];
    user: User;
    profileImage: File | null;
    file?: File;
    email?: string;
    manager?: boolean;
    documentIdentity?: string;
    documentIssuingAgency?: string;
    expeditionDate?: string;
    voterRegistration?: string;
    voterRegistrationPollingStation?: string;
    socialIntegrationProgram?: string;
    workCard?: string;
    workCardSeries?: string;
    emails?: {
        type?: number;
        email?: string;
    }[];
    voterRegistrationConstituency?: string;
} & BaseModel;

export enum PersonContractType {
    Personal = 1,
    Juridic,
    Intern,
    Associate
}

export enum PersonType {
    Internal = 1,
    External
}

export type PersonContract = {
    contract_status_id: number;
    person_id: number;
    branch_id: number;
    position_id: number;
    start_date: string;
    final_date: string;
    type: PersonContractType;
    salary: number;
    job_position: Partial<JobPosition>;
    position: Partial<JobPosition>;
    juridic_name: string;
    juridic_fantasy_name: string;
    juridic_document: string;
    movimentations: Partial<PersonContractMovimentation>[];
    branch: Partial<Branch>;
    contract_status: Partial<PersonContractStatus>;
    contractStatus: Partial<PersonContractStatus>;
} & BaseModel;

export type PersonContractMovimentation = {
    person_contract_id: number;
    contract_status_id: number;
    start_date: string;
    final_date: string;
    observations: string;
    person_contract: Partial<PersonContract>;
    person_contract_status: Partial<PersonContractStatus>;
} & BaseModel;

export type PersonContractStatus = {
    title: string;
    is_visible: boolean;
    person_contract_movimentations: Partial<PersonContractMovimentation>[];
    person_contracts: Partial<PersonContract>[];
    job_position: Partial<JobPosition>;
    person: Partial<Person>;
} & BaseModel;

export type CreatePersonContractPayload = {
    branch: number;
    position: number;
    shift: Shift;
    frequency: Frequency;
    seniority: Seniority;
    salary: string;
    startDate: string;
    juridicName: string;
    juridicFantasyName: string;
    juridicDocument: string;
    finalDate: string;
    type: PersonContractType;
    hourValue?: string;
};

export type CreatePersonPayload = {
    user?: {
        username?: string;
        profile?: number;
    };
    email?: string;
    account: number;
    name: string;
    title: string;
    gender: Gender;
    motherName: string;
    fatherName: string;
    maritalStatus: MaritalStatus;
    phones: Phone[];
    document: string;
    birthday: string;
    address: Omit<Address, 'city' | 'state' | 'status'>;
    contracts?: CreatePersonContractPayload[];
};

export type PersonBankAccountDetails = {
    id: string;
    pix: string;
    bank: {
        id: string;
        name: string;
    };
    account: string;
    account_digit: string;
    agency: string;
    agency_digit: string;
    main: boolean;
};

export type PersonBankAccountPayload = {
    type: number;
    agency: string;
    agencyDigit: string;
    account: string;
    accountDigit: string;
    pix: string;
    main: boolean;
    bank: number;
};
