import { ProjectTypeStatus } from 'types/models/project';
import dictionary from 'utils/dictionary';
import { date, object, string } from 'yup';

export const updateProjectLabelsByProjectTypeStatus = {
    [ProjectTypeStatus.Done]: {
        title: 'Finalizar projeto',
        label: 'Data da finalização',
        reasonLabel: 'Motivo'
    },
    [ProjectTypeStatus.Stopped]: {
        title: 'Paralisar projeto',
        label: 'Data da paralisação',
        reasonLabel: 'Motivo de paralisação'
    },
    [ProjectTypeStatus.Started]: {
        title: 'Reiniciar projeto',
        label: 'Data do reinício',
        resonLabel: 'Motivo'
    },
    [ProjectTypeStatus.Canceled]: {
        title: 'Cancelar projeto',
        label: 'Data do cancelamento',
        reasonLabel: 'Motivo'
    }
};

export const schemaUpdateProjectStatus = object({
    reason: string().required(dictionary.validation.required),
    informedDate: date().required(dictionary.validation.required)
});
