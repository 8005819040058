import SimpleCard from 'components/simple-card';
import { formatMoney } from 'utils/money';
import { getNumberValueOrZero } from 'utils/number';
import { UpdateBudgetTableProps } from './budget-table';
import Text from 'components/core/text';

type UpdateBudgetHeaderProps = Omit<UpdateBudgetTableProps, 'onSetNewContractValue'> & {
    marginByMonth: number;
    footageSale: number;
};

const UpdateBudgetHeader = ({ contractValue, directCostsValue, indirectCostsValue, margin, marginByMonth, footageSale }: UpdateBudgetHeaderProps) => {
    return (
        <div
            className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-6 mb-10 md:sticky top-[74px] z-50
        shadow-sm border-t">
            <SimpleCard className="bg-white pb-[25px]" valueClassName="!text-sm" value={formatMoney(contractValue)} label="Valor Contrato" />
            <SimpleCard className="bg-white pb-[25px]" valueClassName="!text-sm" value={formatMoney(indirectCostsValue)} label="Custos Indiretos" />
            <SimpleCard className="bg-white pb-[25px]" valueClassName="!text-sm" value={formatMoney(directCostsValue)} label="Custos Diretos" />
            <SimpleCard className="bg-white pb-[25px] relative" valueClassName="whitespace-pre-line !text-sm">
                <div className="relative">
                    <Text as="p" className="text-base-500 font-normal text-sm mb-2">
                        Margem
                    </Text>
                    <div>
                        <Text as="strong" className="font-medium text-sm text-heading flex-1 mb-3">
                            {formatMoney(margin.value)}
                        </Text>
                        <Text as="span" className="absolute -bottom-4 left-0 font-medium !text-xs text-heading">
                            {`${getNumberValueOrZero(margin.percentage.toFixed(2))}%`}
                        </Text>
                    </div>
                </div>
            </SimpleCard>
            <SimpleCard className="bg-white pb-[25px]" valueClassName="!text-sm" value={formatMoney(isFinite(marginByMonth) ? marginByMonth : 0)} label="Margem / Mês" />
            <SimpleCard className="bg-white pb-[25px]" valueClassName="!text-sm" value={`${formatMoney(isFinite(footageSale) ? footageSale : 0)}/m²`} label="Venda / M ²" />
        </div>
    );
};

export default UpdateBudgetHeader;
