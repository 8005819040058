export type PermissionsProps = {
    rules: Array<{
        name: string;
        key: Rule;
        id: number;
    }>;
};

export enum Rule {
    List = 'list',
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Show = 'show'
}
