import React, { useMemo } from 'react';
import { Chart as ChartJS, Tooltip, Legend, ChartData, ArcElement, LinearScale } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { pieChartOptions } from './utils';
import { WeeklyEffective } from 'types/models/report';

type PieChartProps = {
    title?: string;
    colors: string[];
    datasets: WeeklyEffective['graphic']['datasets'];
    labels: string[];
    className?: string;
};

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const PieChart = ({ colors, datasets, labels, title, className }: PieChartProps) => {
    const dataFormatted = useMemo<ChartData<'pie', number[], string>>(() => {
        return {
            labels,
            datasets: [
                {
                    ...datasets,
                    borderColor: 'white',
                    backgroundColor: colors
                }
            ]
        };
    }, [datasets, labels, colors]);

    return (
        <div className={className}>
            <Pie options={pieChartOptions(title)} data={dataFormatted} className="!max-w-full" updateMode="resize" />
        </div>
    );
};

export default PieChart;
