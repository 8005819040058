// Dependencies
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import notification, { getAllNotificationsKey, readNotificationKey } from './graphql';

type Payload = { is_read: boolean };

type Response = {
    update_notification_by_pk: {
        id: number;
    };
};

const useReadNotification = (id: number) => {
    const queryClient = useQueryClient();

    const fetcher = (data: Payload) => {
        const payload = {
            ...data,
            updated_at: new Date().toISOString()
        };

        return apiGraphQl<Response>(notification.readNotification, { id, payload });
    };

    return useMutation(readNotificationKey, fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllNotificationsKey);
        }
    });
};

export default useReadNotification;
