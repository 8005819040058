import React, { SVGAttributes, lazy, Suspense, memo } from 'react';

// Components
import Spinner from '../spinner';

// Helpers
import retry from 'utils/retry';
import theme from 'settings/theme';

type Size = Partial<{ width: number; height: number | string }>;
type IconProps = Size & SVGAttributes<SVGSVGElement>;

const LoadingIcon = ({ width, height }: Size) => (
    <div style={{ width: `${width}px`, height: `${height}px` }}>
        <div className={container}>
            <Spinner color={theme.extend.colors.base[500]} size={12} fixed={false} className="block" />
        </div>
    </div>
);

const Icon = ({ name, width = 20, height = 20, ...rest }: IconProps) => {
    const Element = lazy(() => retry(() => import(`./files/${name}`)));

    return (
        <Suspense fallback={<LoadingIcon width={width} height={height} />}>
            <div className={container}>
                <Element {...rest} width={width} height={height} />
            </div>
        </Suspense>
    );
};

const { container } = {
    container: 'pointer-events-none transition-all ease-in-out duration-200'
};

export default memo(Icon);
