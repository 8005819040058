import { Dispatch, SetStateAction } from 'react';

import ExternalButton from 'components/buttons/external';

import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { useNavigate } from 'react-router-dom';
import { ProjectTypeDocument } from 'types/models/project';

const generateCategoryText = (document: Partial<ProjectTypeDocument>) => {
    return document.classification?.father?.name ? `${document.classification?.father?.name} > ${document.classification?.name}` : document.classification?.name;
};

type DetailsDocumentProps = {
    document?: ProjectTypeDocument;
    isTechnical?: boolean;
    setViewTechnicalDocumentId?: Dispatch<SetStateAction<number | null>>;
};

const DetailsDocument = ({ document, isTechnical, setViewTechnicalDocumentId }: DetailsDocumentProps) => {
    const navigate = useNavigate();

    const handleShowFile = () => window.open(document?.file?.url, '_blank');
    return (
        <Modal
            headerLeft={
                <Text as="h4" variant="h4" className="text-heading">
                    {document?.name || 'Documento'}
                </Text>
            }
            contentClassnames="w-[800px]"
            closeOnClickOutside={true}
            onClose={() => (isTechnical ? setViewTechnicalDocumentId?.(null) : navigate(-1))}>
            <div className="px-3 sm:p-6 pt-0">
                {Boolean(document) ? (
                    <>
                        <div className="border-b border-b-base-300 pb-4 mb-4 gap-4 grid grid-cols-1 divide-y sm:divide-y-0 sm:grid-cols-2">
                            <div>
                                <Text as="label" variant="h6" className="text-heading block mb-3">
                                    Categoria
                                </Text>
                                <Text as="p" className="text-base-500 mb-2">
                                    {generateCategoryText(document!)}
                                </Text>
                            </div>
                            <div className="pt-2 sm:pt-0">
                                <Text as="label" variant="h6" className="text-heading block mb-3">
                                    Arquivo
                                </Text>
                                <ExternalButton className="break-words w-full" onClick={handleShowFile}>
                                    {document!.file.filename}
                                </ExternalButton>
                            </div>
                        </div>
                        <div className="border-b border-b-base-300 pb-4 mb-4">
                            <Text as="label" variant="h6" className="text-heading block mb-3">
                                Descrição
                            </Text>
                            <div className="rich-content" dangerouslySetInnerHTML={{ __html: document!.description }} />
                        </div>
                        <div>
                            <Text as="label" variant="h6" className="text-heading block mb-3">
                                Observações
                            </Text>
                            <div className="rich-content" dangerouslySetInnerHTML={{ __html: document!.observation }} />
                        </div>
                    </>
                ) : (
                    <Empty title="Documento não encontrado" />
                )}
            </div>
        </Modal>
    );
};

export default DetailsDocument;
