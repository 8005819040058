import { Pagination as PaginationType } from 'types/pagination';
import OperationalCard from '../card';
import { Operational } from 'types/models/operationals';
import Pagination from '@mui/material/Pagination/Pagination';
import Card from 'components/core/card';
import { OperationalOrderByValue } from '../quick-filter';

type OperationalsListCardsProps = {
    data?: PaginationType<Operational>;
    page: number;
    orderBy?: OperationalOrderByValue;
    totalPerPage?: number;
    onChangePage(page: number): void;
    onChangeSort?(data: OperationalOrderByValue): void;
    onChangeRowsPerPage?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const OperationalsListCards = ({ data, page, onChangePage }: OperationalsListCardsProps) => {
    const itemsPerPage = 12;
    const { total = 0, items = [] } = data || {};

    const pagesCount = Math.ceil(total / itemsPerPage) ?? 1;

    return (
        <div className="mb-5">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8">
                {items?.map((item) => {
                    return <OperationalCard key={item.id} {...item} />;
                })}
            </div>

            <Card className="mx-auto w-fit py-4 px-6 max-w-full">
                <Pagination
                    count={pagesCount}
                    color="secondary"
                    defaultPage={1}
                    page={page || 0}
                    // boundaryCount={isMobile && props?.rowCount! > MAX_COUNT_ITEMS ? 0 : undefined}
                    shape="rounded"
                    showFirstButton={true}
                    showLastButton={true}
                    onChange={(_, page) => onChangePage(page)}
                    disabled={itemsPerPage >= total}
                />
            </Card>
        </div>
    );
};

export default OperationalsListCards;
