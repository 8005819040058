const checklistTemplate = [
    { order: 1, title: 'Visita técnica', tasks: [], mandatory: false },
    {
        order: 2,
        title: 'Levantamento do shell',
        mandatory: false,
        tasks: [{ order: 1, title: 'Liberação do shell', mandatory: false }]
    },
    {
        order: 3,
        title: 'Aferir pontos de entrega',
        mandatory: false,
        tasks: [
            { order: 1, title: 'Hidrossanitários', mandatory: false },
            { order: 2, title: 'Elétrico', mandatory: false },
            { order: 3, title: 'Combate a incêndio', mandatory: false },
            { order: 4, title: 'Telefonia', mandatory: false },
            { order: 5, title: 'Laje técnica', mandatory: false }
        ]
    },
    { order: 4, title: 'Receber todos os projetos', tasks: [], mandatory: false },
    { order: 5, title: 'Receber folha mestra de projetos', tasks: [], mandatory: false },
    { order: 6, title: 'Receber planilha de contratação direta', tasks: [], mandatory: false },
    { order: 7, title: 'Receber contato dos fornecedores', tasks: [], mandatory: false },
    { order: 8, title: 'Receber contrato construtora', tasks: [], mandatory: false },
    { order: 9, title: 'Receber protocolo de corpo de bombeiros', tasks: [], mandatory: false },
    { order: 10, title: 'Receber projetos aprovados no CBM', tasks: [], mandatory: false },
    {
        order: 11,
        title: 'Receber protocolo do GEM',
        mandatory: false,
        tasks: [
            { order: 1, title: 'Escada rolante', mandatory: false },
            { order: 2, title: 'Elevador de carga', mandatory: false },
            { order: 3, title: 'Elevador social', mandatory: false },
            { order: 4, title: 'Elevador PNE', mandatory: false }
        ]
    },
    {
        order: 12,
        title: 'Receber protocolo do GEM',
        mandatory: false,
        tasks: [{ order: 1, title: 'Ar condicionado', mandatory: false }]
    },
    {
        order: 13,
        title: 'Receber projetos aprovados',
        mandatory: false,
        tasks: [
            { order: 1, title: 'Prefeitura', mandatory: false },
            { order: 2, title: 'Shopping', mandatory: false }
        ]
    },
    {
        order: 14,
        title: 'Receber documentação para aprovar',
        mandatory: false,
        tasks: [{ order: 1, title: 'Subestação', mandatory: false }]
    },
    { order: 15, title: 'Atualizar os projetos a construtora', tasks: [], mandatory: false },
    { order: 16, title: 'Fiscalizar a versão dos projetos nas obras', tasks: [], mandatory: false },
    { order: 17, title: 'Produzir cronograma de obra', tasks: [], mandatory: false },
    { order: 18, title: 'Produzir relatório de start de obra', tasks: [], mandatory: false }
];

export default checklistTemplate;
