import { createActions, createReducer } from 'reduxsauce';
import { ReduxAction } from 'types/general';
import { Credentials } from 'types/models/credentials';
import { CredentialsActionsType, CredentialsActions } from './types';

export const { Creators, Types } = createActions<Record<CredentialsActionsType, CredentialsActionsType>, CredentialsActions>({
    setCredentials: ['payload'],
    clearCredentials: []
});

const INITIAL_STATE: Credentials = {
    menu: [],
    pages: []
};

const setCredentials = (state = INITIAL_STATE, action: ReduxAction<Credentials>): Credentials => ({
    ...state,
    ...action.payload
});

const clearCredentials = (state = INITIAL_STATE): Credentials => ({ ...state, ...INITIAL_STATE });

export default createReducer<Credentials>(INITIAL_STATE, {
    [Types.SET_CREDENTIALS]: setCredentials,
    [Types.CLEAR_CREDENTIALS]: clearCredentials
});
