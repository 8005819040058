import React, { useState } from 'react';
import Icon from 'components/core/icon';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';
import { CommercialBudgetResponse } from 'types/models/commercial';

type CommercialBudgetDetailsActionsProps = {
    hasProjects: boolean;
    budget: CommercialBudgetResponse;
    onSetCommercialBudgetId: React.Dispatch<React.SetStateAction<number | null>>;
    onSetConfigAdditiveContract: React.Dispatch<React.SetStateAction<boolean>>;
};

const CommercialBudgetDetailsActions = ({ budget, hasProjects, onSetCommercialBudgetId, onSetConfigAdditiveContract }: CommercialBudgetDetailsActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { palette } = useTheme();

    const navigate = useNavigate();

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const content: MenuItem[] = [
        {
            label: 'Gerar contrato',
            icon: {
                color: palette.success[500],
                name: 'ico-plus'
            },
            onClick: () => {
                handleClose();

                if (budget.additive) {
                    if (hasProjects) {
                        return navigate('gerar-contrato-aditivo');
                    }

                    return onSetConfigAdditiveContract(true);
                }

                navigate(`gerar-contrato`);
            }
        },
        {
            label: 'Cancelar FP',
            icon: {
                color: palette.error[500],
                name: 'ico-close-circle'
            },
            onClick: () => {
                onSetCommercialBudgetId(budget.id);
                handleClose();
            }
        }
    ];

    return (
        <>
            <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<Icon name="ico-chevron-down" width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
        </>
    );
};

export default CommercialBudgetDetailsActions;
