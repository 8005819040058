import React from 'react';
import Text from 'components/core/text';
import { ProviderFinancialMeasurement } from 'types/models/provider';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Empty from 'components/empty';
import { formatDate } from 'utils/date';
import { CamelCase } from 'types/utils';
import { formatMoney } from 'utils/money';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import { useNavigate } from 'react-router-dom';

type FinancialMeasurementsProps = {
    measurements: Partial<CamelCase<ProviderFinancialMeasurement>>[];
};

const infoButton = { classes: { child: 'text-system-info-100' } };
const secondaryButton = { classes: { child: 'text-secondary-100' } };

const FinancialMeasurements = ({ measurements }: FinancialMeasurementsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const handleGoTo = (path: string) => () => navigate(path);

    return (
        <div>
            {Boolean(measurements.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width={85} />
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Data (P/R)
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Percentual (P/R)
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Valor (P/R)
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Saldo contrato
                                    </Text>
                                </TableCell>
                                <TableCell width={96}>
                                    <Text as="span" className="text-secondary-500">
                                        Ações
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {measurements.map((item) => {
                                return (
                                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <span className="border border-base-300 flex items-center justify-center bg-base-200 text-heading rounded-[10px] min-w-[30px] px-2 py-[2px]">
                                                {item.order?.toString().padStart(3, '0') || '-'}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {formatDate(item.expectedDate) || ''}
                                            </Text>
                                            <Text as="span" className="text-base-500 mx-2">
                                                /
                                            </Text>
                                            <Text as="span" className="text-system-info-500">
                                                {formatDate(item.measurementDate) || ''}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {item.predictedPercentage ? `${item.predictedPercentage}%` : '-'}
                                            </Text>
                                            <Text as="span" className="text-base-500 mx-2">
                                                /
                                            </Text>
                                            <Text as="span" className="text-system-info-500">
                                                {item.measurementPercentage ? `${item.measurementPercentage}%` : '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {item.predictedValue ? formatMoney(item.predictedValue) : '-'}
                                            </Text>
                                            <Text as="span" className="text-base-500 mx-2">
                                                /
                                            </Text>
                                            <Text as="span" className="text-system-info-500">
                                                {item.measurementValue ? formatMoney(item.measurementValue) : '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500 mx-2">
                                                {formatMoney(item.contractBalance) || '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip placement="top" title="Visualizar">
                                                <IconButton TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20" onClick={handleGoTo(`medicoes/${item.id}`)}>
                                                    <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title="Gerar medição">
                                                <IconButton TouchRippleProps={secondaryButton} className="hover:bg-secondary-100 hover:bg-opacity-20" onClick={handleGoTo(`medicoes/${item.id}/novo`)}>
                                                    <Icon name="ico-plus" width={12} height={12} color={palette.secondary[500]} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Empty title="Nenhuma medição encontrada" />
            )}
        </div>
    );
};

export default FinancialMeasurements;
