import classNames from 'classnames';
import { TimelineVariant } from '..';

type Props = {
    timelineVariant: TimelineVariant;
    setTimelineVariant: React.Dispatch<React.SetStateAction<TimelineVariant>>;
};

const SwitchTimelineVariant = ({ timelineVariant, setTimelineVariant }: Props) => {
    const activeClasses = 'text-white bg-secondary-500 font-medium';

    const buttonClasses = 'px-3 py-2 rounded-lg text-heading hover:text-white hover:bg-secondary-500';

    return (
        <div className="bg-base-200 absolute right-5 bottom-5 rounded-l-lg rounded-r-lg p-1 border flex gap-1">
            <button onClick={() => setTimelineVariant('WEEKS')} type="button" className={classNames(buttonClasses, timelineVariant === 'WEEKS' && activeClasses)}>
                Semanas
            </button>

            <button onClick={() => setTimelineVariant('MONTHS')} type="button" className={classNames(buttonClasses, timelineVariant === 'MONTHS' && activeClasses)}>
                Meses
            </button>
        </div>
    );
};

export default SwitchTimelineVariant;
