export const createUserKey = () => ['user', 'create'];

const queries = {
    createProfile: `
        mutation ($payload: profile_insert_input!) {
            item: insert_profile_one(object: $payload) {
                id
            }
        }
    `,
    createProfilePageRules: `
        mutation ($payload: [profile_page_rule_insert_input!]!) {
            item: insert_profile_page_rule(objects: $payload) {
                affected_rows
            }
        }
    `
};

export default queries;
