import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';

export const getRulesQuery = jsonToGraphQLQuery({
    query: {
        __name: 'GetRulesOptions',
        items: {
            __aliasFor: 'rule',
            __args: {
                where: {
                    status: { _eq: Status.Active }
                },
                order_by: { name: new EnumType('asc') }
            },
            value: { __aliasFor: 'id' },
            label: { __aliasFor: 'name' }
        }
    }
});
