import ListPage from 'pages/private/crud/list';
import React from 'react';
import { CrudPageProps } from 'types/graphql';
import TableCell from '@mui/material/TableCell/TableCell';
import { Person } from 'types/models/person';
import { useOutletContext } from 'react-router-dom';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import { useNavigate } from 'react-router-dom';
import dictionary from 'utils/dictionary';
import { generateFileUrl } from 'utils/file';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import ExternalButton from 'components/buttons/external';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

const PersonDetailsDocuments = ({ page }: WithPagePermissionsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const { id } = useOutletContext<Partial<Person>>();

    const listPageProps: CrudPageProps = {
        title: 'Documentos',
        graphql: {
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },

                {
                    name: 'document.name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'document.classification.name',
                    label: dictionary.content.graphql['classification']
                },
                {
                    name: 'document.file.path',
                    options: { display: 'excluded' }
                },
                {
                    name: 'document.file.filename',
                    options: { display: 'excluded' }
                },

                {
                    name: 'document.file.original_name',
                    label: 'Arquivo',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            if (!value) return '-';

                            const path = tableMeta.rowData[3];
                            const filename = tableMeta.rowData[4];

                            const url = generateFileUrl(filename, path);

                            const handleShowFile = () => window.open(url, '_blank');

                            return <ExternalButton onClick={handleShowFile}>{value}</ExternalButton>;
                        }
                    }
                },

                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ],
            table: 'person_document',
            searchableField: 'document.name',
            where: {
                person_id: {
                    _eq: id
                }
            },
            statusPath: 'document.status'
        },
        hideHeader: true,
        api: {
            useApiTodelete: true,
            endpoint: `/people/${id}/documents`
        }
    };

    return (
        <div>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <div className="flex mb-5">
                <div className="flex-1" />
                <IconButton
                    className="ml-auto"
                    color="secondary"
                    size="large"
                    sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}
                    onClick={() => navigate('novo')}>
                    <Icon name="ico-plus" width={16} height={16} color={palette.grey[100]} />
                </IconButton>
            </div>
            <ListPage page={page} {...listPageProps} />
        </div>
    );
};

export default PersonDetailsDocuments;
