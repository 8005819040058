import CreateOrUpdateConfigJobPositionPage from 'pages/private/config-classifications/job-positions/create-or-update';
import CreateOrUpdateConfigClassificationPage from 'pages/private/config-classifications/classifications/create-or-update';
import CreateConfigClassificationDocumentTypePage from 'pages/private/config-classifications/classifications/documents-types/create-or-update';
import CreateConfigClassificationProviderServicesPage from 'pages/private/config-classifications/classifications/provider-services/create-or-update';
import CreateConfigClassificationScopePage from 'pages/private/config-classifications/classifications/scopes/create-or-update';
import ListPage from 'pages/private/crud/list';
import { RouteObject } from 'react-router-dom';
import { ClassificationType } from 'types/models/classification';
import { classificationByType } from './utils/classifications-object';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import CreateOrUpdateConfigTypeBusinessPage from 'pages/private/config-classifications/business-type';

const [jobPositionList, jobPositionCreate] = classificationByType('job_position', 'jobPosition');
const [typeBusinessList, typeBusinessCreate] = classificationByType('type_business', 'typeBusiness');
const [assetList, assetCreate] = classificationByType('classification', ClassificationType.Asset);
const [scopeList, scopeCreate] = classificationByType('classification', ClassificationType.Scope);
const [effectiveList, effectiveCreate] = classificationByType('classification', ClassificationType.Effective);
const [documentTypeList, documentTypeCreate] = classificationByType('classification', ClassificationType.Document);
const [solicitationList, solicitationCreate] = classificationByType('classification', ClassificationType.Solicitation);
const [personDocumentList, personDocumentCreate] = classificationByType('classification', ClassificationType.PersonDocument);
const [weatherConditionList, weatherConditionCreate] = classificationByType('classification', ClassificationType.WeatherCondition);
const [providerServicesList, providerServicesCreate] = classificationByType('classification', ClassificationType.ProviderServices);
const [documentTypeProjectList, documentTypeProjectCreate] = classificationByType('classification', ClassificationType.ProjectDocument);
const [financialClassificationsList, financialClassificationsCreate] = classificationByType('classification', ClassificationType.FinancialClassification);
const [transactionDocumentList, transactionDocumentCreate] = classificationByType('classification', ClassificationType.TransactionDocument);
const [departmentList, departmentCreate] = classificationByType('classification', ClassificationType.Departament);

const configurationClassifications: RouteObject[] = [
    {
        path: 'configuracoes/ativos',
        element: <Page Component={ListPage} code="configuracoes-ativos" permissions={[Rule.List]} {...assetList} title="Ativos" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-ativos"
                        permissions={[Rule.Create]}
                        type={ClassificationType.Asset}
                        title="Criar ativo"
                        withModal={true}
                        {...assetCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-ativos"
                        permissions={[Rule.Update]}
                        type={ClassificationType.Asset}
                        title="Editar ativo"
                        withModal={true}
                        {...assetCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/cargos',
        element: <Page Component={ListPage} code="configuracoes-cargos" permissions={[Rule.List]} {...jobPositionList} title="Cargos" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigJobPositionPage}
                        code="configuracoes-cargos"
                        permissions={[Rule.Create]}
                        type={ClassificationType.Asset}
                        title="Criar cargos"
                        withModal={true}
                        {...jobPositionCreate}
                    />
                )
            },
            {
                path: 'editar/:configJobPositionId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigJobPositionPage}
                        code="configuracoes-cargos"
                        permissions={[Rule.Update]}
                        type={ClassificationType.Asset}
                        title="Editar cargo"
                        withModal={true}
                        {...jobPositionCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/condicoes-climaticas',
        element: <Page Component={ListPage} code="configuracoes-condicoes-climaticas" permissions={[Rule.List]} {...weatherConditionList} title="Condições Climáticas" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-condicoes-climaticas"
                        permissions={[Rule.Create]}
                        type={ClassificationType.WeatherCondition}
                        title="Criar condição climática"
                        withModal={true}
                        {...weatherConditionCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-condicoes-climaticas"
                        permissions={[Rule.Update]}
                        type={ClassificationType.WeatherCondition}
                        title="Editar condição climática"
                        withModal={true}
                        {...weatherConditionCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/efetivos',
        element: <Page Component={ListPage} code="configuracoes-efetivos" permissions={[Rule.List]} {...effectiveList} title="Efetivos" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-efetivos"
                        permissions={[Rule.Create]}
                        type={ClassificationType.Effective}
                        title="Criar efetivo"
                        withModal={true}
                        {...effectiveCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-efetivos"
                        permissions={[Rule.Update]}
                        type={ClassificationType.Effective}
                        title="Editar efetivo"
                        withModal={true}
                        {...effectiveCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/solicitacoes',
        element: <Page Component={ListPage} code="configuracoes-solicitacoes" permissions={[Rule.List]} {...solicitationList} title="Solicitações" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-solicitacoes"
                        permissions={[Rule.Create]}
                        type={ClassificationType.Solicitation}
                        title="Criar solicitações"
                        withModal={true}
                        {...solicitationCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-solicitacoes"
                        permissions={[Rule.Update]}
                        type={ClassificationType.Solicitation}
                        title="Editar solicitações"
                        withModal={true}
                        {...solicitationCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/tipos-documentos',
        element: <Page Component={ListPage} code="configuracoes-tipos-de-documentos" permissions={[Rule.List]} {...documentTypeList} title="Tipos de documentos" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateConfigClassificationDocumentTypePage}
                        code="configuracoes-tipos-de-documentos"
                        permissions={[Rule.Create]}
                        type={ClassificationType.Document}
                        title="Criar tipo de documento"
                        withModal={true}
                        {...documentTypeCreate}
                    />
                )
            },
            {
                path: 'editar/:documentTypeId',
                element: (
                    <Page
                        Component={CreateConfigClassificationDocumentTypePage}
                        code="configuracoes-tipos-de-documentos"
                        permissions={[Rule.Update]}
                        type={ClassificationType.Document}
                        title="Editar tipo de documento"
                        withModal={true}
                        {...documentTypeCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/tipos-documentos-pessoais',
        element: <Page Component={ListPage} code="configuracoes-tipos-de-documentos-pessoais" permissions={[Rule.List]} {...personDocumentList} title="Tipos de documentos pessoais" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-tipos-de-documentos-pessoais"
                        permissions={[Rule.Create]}
                        type={ClassificationType.PersonDocument}
                        title="Criar tipo"
                        withModal={true}
                        {...personDocumentCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-tipos-de-documentos-pessoais"
                        permissions={[Rule.Update]}
                        type={ClassificationType.PersonDocument}
                        title="Editar tipo"
                        withModal={true}
                        {...personDocumentCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/tipos-documentos-projetos',
        element: <Page Component={ListPage} code="configuracoes-tipos-de-documentos-projetos" permissions={[Rule.List]} {...documentTypeProjectList} title="Tipos de documentos de projetos" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-tipos-de-documentos-projetos"
                        permissions={[Rule.Create]}
                        type={ClassificationType.ProjectDocument}
                        title="Criar tipo"
                        withModal={true}
                        {...documentTypeProjectCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-tipos-de-documentos-projetos"
                        permissions={[Rule.Update]}
                        type={ClassificationType.ProjectDocument}
                        title="Editar tipo"
                        withModal={true}
                        {...documentTypeProjectCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/servicos-terceiros',
        element: <Page Component={ListPage} code="configuracoes-tipos-de-servico-de-terceiros" permissions={[Rule.List]} {...providerServicesList} title="Tipos de serviço de terceiro" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateConfigClassificationProviderServicesPage}
                        code="configuracoes-tipos-de-servico-de-terceiros"
                        permissions={[Rule.Create]}
                        type={ClassificationType.ProviderServices}
                        title="Criar tipo de serviço"
                        withModal={true}
                        {...providerServicesCreate}
                    />
                )
            },
            {
                path: 'editar/:providerServiceId',
                element: (
                    <Page
                        Component={CreateConfigClassificationProviderServicesPage}
                        code="configuracoes-tipos-de-servico-de-terceiros"
                        permissions={[Rule.Update]}
                        type={ClassificationType.ProviderServices}
                        title="Editar tipo de serviço"
                        withModal={true}
                        {...providerServicesCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/escopo',
        element: <Page Component={ListPage} code="configuracoes-tipos-de-escopos" permissions={[Rule.List]} {...scopeList} title="Tipos de escopos" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateConfigClassificationScopePage}
                        code="configuracoes-tipos-de-escopos"
                        permissions={[Rule.Create]}
                        type={ClassificationType.Scope}
                        title="Criar tipo de escopo"
                        withModal={true}
                        {...scopeCreate}
                    />
                )
            },
            {
                path: 'editar/:scopeId',
                element: (
                    <Page
                        Component={CreateConfigClassificationScopePage}
                        code="configuracoes-tipos-de-escopos"
                        permissions={[Rule.Update]}
                        type={ClassificationType.Scope}
                        title="Editar tipo de escopo"
                        withModal={true}
                        {...scopeCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/classificacoes-financeiras',
        element: <Page Component={ListPage} code="configuracoes-classificacoes-financeiras" permissions={[Rule.List]} {...financialClassificationsList} title="Tipos de classificação" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-classificacoes-financeiras"
                        permissions={[Rule.Create]}
                        type={ClassificationType.FinancialClassification}
                        title="Criar tipo de classificação"
                        withModal={true}
                        {...financialClassificationsCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-classificacoes-financeiras"
                        permissions={[Rule.Update]}
                        type={ClassificationType.FinancialClassification}
                        title="Editar tipo de classificação"
                        withModal={true}
                        {...financialClassificationsCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/tipos-de-negocios',
        element: <Page Component={ListPage} code="configuracoes-tipos-de-negocios" permissions={[Rule.List]} {...typeBusinessList} title="Tipos de negócio" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigTypeBusinessPage}
                        code="configuracoes-tipos-de-negocios"
                        permissions={[Rule.Create]}
                        title="Criar tipo de negócio"
                        withModal={true}
                        {...typeBusinessCreate}
                    />
                )
            },
            {
                path: 'editar/:configTypeBusinessId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigTypeBusinessPage}
                        code="configuracoes-tipos-de-negocios"
                        permissions={[Rule.Update]}
                        title="Editar tipo de negócio"
                        withModal={true}
                        {...typeBusinessCreate}
                    />
                )
            }
        ]
    },
    {
        path: 'configuracoes/departamentos',
        element: <Page Component={ListPage} code="configuracoes-departamentos" permissions={[Rule.List]} {...departmentList} title="Departamentos" />,
        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-departamentos"
                        type={ClassificationType.Departament}
                        permissions={[Rule.Create]}
                        title="Criar departamento"
                        withModal={true}
                        {...departmentCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-departamentos"
                        type={ClassificationType.Departament}
                        permissions={[Rule.Update]}
                        title="Editar departamento"
                        withModal={true}
                        {...departmentCreate}
                    />
                )
            }
        ]
    },

    {
        path: 'configuracoes/tipos-de-documentos-da-despesa',
        element: <Page Component={ListPage} code="configuracoes-tipos-de-documentos-despesas" permissions={[Rule.List]} {...transactionDocumentList} title="Tipos de documentos das despesas" />,

        children: [
            {
                path: 'novo',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-tipos-de-documentos-despesas"
                        permissions={[Rule.Create]}
                        title="Criar tipo de doc. da despesa"
                        withModal={true}
                        type={ClassificationType.TransactionDocument}
                        {...transactionDocumentCreate}
                    />
                )
            },
            {
                path: 'editar/:configClassificationId',
                element: (
                    <Page
                        Component={CreateOrUpdateConfigClassificationPage}
                        code="configuracoes-tipos-de-documentos-despesas"
                        permissions={[Rule.Update]}
                        title="Editar tipo de doc. da despesa"
                        withModal={true}
                        type={ClassificationType.TransactionDocument}
                        {...transactionDocumentCreate}
                    />
                )
            }
        ]
    }
];

export default configurationClassifications;
