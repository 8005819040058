import React, { useState } from 'react';
import Text from 'components/core/text';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Empty from 'components/empty';
import { Link, useNavigate } from 'react-router-dom';
import { ProviderFinancialPayment, ProviderFinancialPaymentType } from 'types/models/provider';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import AddButton from 'components/buttons/add';
import { CamelCase } from 'types/utils';
import { formatMoney } from 'utils/money';
import Modal from 'components/core/modal';
import { formatDate } from 'utils/date';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Icon from 'components/core/icon';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';

type FinancialPaymentsProps = {
    payments: Partial<CamelCase<ProviderFinancialPayment>>[];
};

const infoButton = { classes: { child: 'text-system-info-100' } };

const FinancialPayments = ({ payments }: FinancialPaymentsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [observations, setObservations] = useState<string>();

    return (
        <>
            {Boolean(payments.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Tipo
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Valor
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Entrada
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        NF
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Fornecedor
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Ações
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payments.map((item) => {
                                return (
                                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {item.type === ProviderFinancialPaymentType.Start ? 'Entrada' : 'Direto'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {formatMoney(item.value) || '-'} {item.percentage ? `(${item.percentage}%)` : ''}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {formatDate(item.date) || '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {item.invoiceNumber?.padStart(3, '0') || '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {item.providerName ? `${item.providerName} (${item.providerDocument})` : '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip placement="top" title="Visualizar">
                                                <span>
                                                    <IconButton
                                                        component={Link}
                                                        disabled={item.type === ProviderFinancialPaymentType.Start}
                                                        to={`pagamentos/${item.id}`}
                                                        TouchRippleProps={infoButton}
                                                        className="hover:bg-primary-100 hover:bg-opacity-20 disabled:bg-base-100">
                                                        <Icon name="ico-show" width={16} height={16} color={item.type === ProviderFinancialPaymentType.Start ? palette.grey[400] : palette.info.main} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell classes={{ root: 'py-2 border-none' }} colSpan={5}>
                                    <AddButton linkComponent={Link} to="pagamentos/novo">
                                        Novo faturamento direto
                                    </AddButton>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            ) : (
                <Empty title="Nenhum faturamento direto cadastrado até o momento." className="p-4" buttonLabel="Criar faturamento direto" onClick={() => navigate('pagamentos/novo')} />
            )}
            {Boolean(observations) && (
                <Modal contentClassnames="py-5 px-7 min-w-[500px]" onClose={() => setObservations(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: observations! }} />
                </Modal>
            )}
        </>
    );
};

export default FinancialPayments;
