import { EnumType, jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { array, boolean, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';

export const getPageQuery = (pageId?: string) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetPage',
            page_by_pk: {
                __args: { id: Number(pageId || 0) },
                key_page: true,
                name: true,
                slug: true,
                permalink: true,
                system: true,
                title: true,
                controller: true,
                subpages: {
                    __args: {
                        order_by: {
                            order_menu: new EnumType('asc')
                        }
                    },
                    id: true,
                    page_id: true,
                    order_menu: true,
                    page: {
                        name: true,
                        title: true
                    }
                },
                page_rules: {
                    id: { __aliasFor: 'rule_id' }
                }
            }
        }
    });
};

export const createOrUpdatePageMutation = (pageId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdatePage',
                __variables: {
                    ...(pageId
                        ? {
                              payload: 'page_set_input',
                              id: 'Int!'
                          }
                        : {
                              payload: 'page_insert_input!'
                          })
                },
                item: {
                    __aliasFor: pageId ? 'update_page_by_pk' : 'insert_page_one',
                    __args: {
                        ...(pageId
                            ? {
                                  pk_columns: { id: new VariableType('id') },
                                  _set: new VariableType('payload')
                              }
                            : {
                                  object: new VariableType('payload')
                              })
                    },
                    id: true
                }
            }
        },
        { pretty: true }
    );
};

export const pageSchema = object({
    key_page: string().required(dictionary.validation.required),
    name: string().required(dictionary.validation.required),
    slug: string().required(dictionary.validation.required),
    permalink: string(),
    system: boolean().required(dictionary.validation.required).default(false),
    title: string().required(dictionary.validation.required),
    controller: string().required(dictionary.validation.required),
    has_subpages: boolean(),
    subpages: array(
        object({
            label: string(),
            value: number(),
            order_menu: number()
        })
    ),
    page_rules: array(number())
});
