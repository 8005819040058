import Icon from 'components/core/icon';
import Text from 'components/core/text';

type TimelineOptionsProps = {
    onSelectOption: (mode: 'normal' | 'file') => void;
};

const TimelineOptions = ({ onSelectOption }: TimelineOptionsProps) => {
    const handleClick = (mode: 'normal' | 'file') => () => onSelectOption(mode);

    return (
        <div className="px-3 sm:px-6 pb-6 sm:py-6 flex gap-6 items-center">
            <button
                className="bg-base-200 bg-opacity-50 border border-solid border-base-300 rounded-[14px] p-6 flex-1 flex flex-col items-center justify-center hover:bg-base-200"
                onClick={handleClick('file')}>
                <Icon name="ico-excel" className="text-heading" width={24} height={28} />
                <Text as="span" className="mt-2 text-heading">
                    Importação de arquivo
                </Text>
            </button>
            <strong className="text-secondary-500">ou</strong>
            <button
                className="bg-base-200 bg-opacity-50 border border-solid border-base-300 rounded-[14px] p-6 flex-1 flex flex-col items-center justify-center hover:bg-base-200"
                onClick={handleClick('normal')}>
                <Icon name="ico-file-text" className="text-heading" width={28} height={28} />
                <Text as="span" className="mt-2 text-heading">
                    Preenchimento manual
                </Text>
            </button>
        </div>
    );
};

export default TimelineOptions;
