import useTheme from '@mui/material/styles/useTheme';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGetContact from 'services/queries/contact/use-get-contact';
import { genders, maritalStatuses } from 'utils/statics';

const ContactDetails = () => {
    const { contactId, customerId, providerId } = useParams();
    const navigate = useNavigate();
    const { palette } = useTheme();

    const endpoint = useMemo(() => (Boolean(customerId) ? `/customers/${customerId}/contacts` : `/providers/${providerId}/contacts`), [customerId, providerId]);

    const handleClose = useCallback(() => navigate(-1), [navigate]);

    const { data: contactDetails, isLoading } = useGetContact({ endpoint, contactId });

    return (
        <Modal onClose={handleClose} closeOnClickOutside={false} contentClassnames="w-[800px]">
            {isLoading ? (
                <div className="p-10">
                    <Spinner color={palette.secondary[100]} fixed={false} size={30} />
                </div>
            ) : (
                <>
                    <div className="py-4">
                        <Text className={`px-5 mb-4 xl:text-2xl font-medium text-heading`}>Detalhes do contato</Text>
                        <hr className="mt-3" />
                    </div>
                    <div className="px-5 mt-2 mb-5">
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <Text as="p" className="text-heading font-medium">
                                Nome:{' '}
                                <Text as="span" className="text-base-500 block break-words">
                                    {contactDetails?.person?.name || '--'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-heading font-medium">
                                Título:{' '}
                                <Text as="span" className="text-base-500 block break-words">
                                    {contactDetails?.person?.title || '--'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-heading font-medium">
                                Email:{' '}
                                <Text as="span" className="text-base-500 block break-words">
                                    {contactDetails?.person?.email || '--'}
                                </Text>
                            </Text>
                            {Boolean(contactDetails?.person?.user?.username) && (
                                <Text as="p" className="text-heading font-medium">
                                    Usuário:{' '}
                                    <Text as="span" className="text-base-500 block break-words">
                                        {contactDetails?.person?.user?.username}
                                    </Text>
                                </Text>
                            )}
                            <Text as="p" className="text-heading font-medium">
                                Estado civil:{' '}
                                <Text as="span" className="text-base-500 block break-words">
                                    {maritalStatuses.find((marital) => marital.value === contactDetails?.person?.maritalStatus)?.label || '--'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-heading font-medium">
                                Gênero:{' '}
                                <Text as="span" className="text-base-500 block break-words">
                                    {genders.find((gender) => gender?.value === contactDetails?.person?.gender)?.label || '--'}
                                </Text>
                            </Text>
                            <Text as="p" className="text-heading font-medium">
                                Telefone(s):{' '}
                                <Text className="text-base-500 block break-words">
                                    {Boolean(contactDetails?.person?.phones?.length) ? contactDetails?.person?.phones?.map((item) => `(${item.ddd}) ${item.number}`).join(', ') : '--'}
                                </Text>
                            </Text>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default ContactDetails;
