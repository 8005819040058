import { useParams } from 'react-router-dom';
import Databook from './databook';
import useGetDatabooks from 'services/queries/operationals/use-get-databooks';
import { useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'components/core/breadcrumb';

import { uniqBy } from 'lodash';
import { formattedOrderNumber } from '../components/utils';

const baseBredcrumb = [
    {
        pathname: `.`,
        breadcrumb: 'Databook',
        match: {
            pathname: '.'
        }
    }
];

const DatabookPage = () => {
    const [breadCrumbsDatabook, setBreadCrumbsDatabook] = useState(baseBredcrumb);
    const [breadcrumbsTitle, setBreadcrumbsTitle] = useState<any>([]);
    const [dataBookData, setDataBookData] = useState<any>({
        folders: [],
        files: []
    });

    const { projectProposalTypeId, '*': params, projectTypeSlug } = useParams();

    const databookId = params?.split('/').pop();
    const databooksIds = useMemo(() => params?.split('/').filter(Boolean) || [], [params]);

    const { data: dataBooks, isLoading: isLoadingDatabooks } = useGetDatabooks(projectProposalTypeId!, databookId);

    useEffect(() => {
        if (!!databooksIds.length && !!dataBooks) {
            const breadcrumbsWithTitle = databooksIds.reduce((acc: any[], curr: string) => {
                const slugTitle = formattedOrderNumber(+curr !== dataBooks?.id ? dataBooks?.father?.number || '0' : dataBooks?.number || '1');

                return [
                    ...acc,
                    {
                        id: +curr,
                        title: `${slugTitle} - ${+curr !== dataBooks.id ? dataBooks?.father?.name : dataBooks?.title}`,
                        slug: dataBooks?.slug
                    }
                ];
            }, []);

            setBreadcrumbsTitle((prev) => uniqBy([...prev, ...breadcrumbsWithTitle], 'id'));

            return;
        }

        if (!databooksIds.length) {
            setBreadcrumbsTitle([]);
        }
    }, [dataBooks, databooksIds]);

    useEffect(() => {
        if (!!databooksIds?.length) {
            const result = databooksIds.reduce((acc: any[], curr: string, index: number) => {
                const titleBreadcrumb = breadcrumbsTitle.find((item) => item.id === +curr!)?.title;
                const pathname = index === 0 ? `${curr}` : `${acc[index - 1].pathname}/${curr}`;
                acc.push({
                    pathname,
                    breadcrumb: titleBreadcrumb,
                    match: {
                        pathname: `/app/operacional/${projectTypeSlug}/${projectProposalTypeId}/databook/${pathname}`
                    }
                });
                return acc;
            }, []);

            setBreadCrumbsDatabook([...baseBredcrumb, ...result]);
        } else {
            setBreadCrumbsDatabook(baseBredcrumb);
        }
    }, [databooksIds, params, projectProposalTypeId, projectTypeSlug, dataBooks?.id, breadcrumbsTitle]);

    useEffect(() => {
        if (!!databookId) {
            setDataBookData({ folders: dataBooks?.children, files: dataBooks?.files || [] });

            return;
        }

        setDataBookData({ folders: dataBooks });
    }, [databookId, dataBooks]);

    return (
        <>
            <div className="flex items-center">
                <Breadcrumb externalBreadcrumbs={breadCrumbsDatabook} />
            </div>
            <Databook hasFiles={!!dataBooks?.files?.length} data={dataBookData} isLoading={!!isLoadingDatabooks} />
        </>
    );
};

export default DatabookPage;
