import useGetBranchesOptions from 'services/queries/branches/use-get-branches-options';
import Select from 'components/core/form/select';
import { Option } from 'types/general';
import useGetClassificationsOptions from 'services/queries/configs-classifications/use-get-classifications-options';
import { ClassificationType } from 'types/models/classification';
import { PeopleMapFilter } from 'services/queries/people-map/types';
import { useCallback } from 'react';
import Button from '@mui/material/Button';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import { monthsOptions } from 'pages/private/people-map/utils';
import { yearsOptions } from 'pages/private/occupation-map/utils';

type State = [number | undefined, React.Dispatch<React.SetStateAction<number | undefined>>];

type FiltersProps = {
    branchState: State;
    yearState: State;
    monthState: State;
    departmentState: State;
    onSetFilters: React.Dispatch<PeopleMapFilter[]>;
};

const Filters = ({ branchState, yearState, monthState, departmentState, onSetFilters }: FiltersProps) => {
    const [branch, setBranch] = branchState;
    const [year, setYear] = yearState;
    const [month, setMonth] = monthState;
    const [department, setDepartment] = departmentState;

    const { palette } = useTheme();

    const { data: branches = [], isLoading: isLoadingBranches } = useGetBranchesOptions();

    const { data: departments = [], isLoading: isLoadingDepartments } = useGetClassificationsOptions(ClassificationType.Departament);

    const getValue = (options: Option[], value?: number) => options?.find((item) => item?.value === value);

    const handleFilter = useCallback(() => {
        onSetFilters([
            {
                key: 'branch',
                value: branch
            },
            {
                key: 'month',
                value: month?.toString()
            },
            {
                key: 'year',
                value: year
            },
            {
                key: 'classification',
                value: department
            }
        ]);
    }, [onSetFilters, branch, department, month, year]);

    return (
        <div className="flex flex-wrap items-end gap-2">
            <Select
                isLoading={isLoadingBranches}
                value={getValue(branches, branch)}
                options={branches}
                label="Filial"
                placeholder="Selecione uma opção"
                onChange={(option: any) => setBranch(option.value)}
            />

            <Select
                isLoading={isLoadingDepartments}
                value={getValue(departments, department)}
                options={departments}
                label="Departamento"
                placeholder="Selecione uma opção"
                onChange={(option: any) => setDepartment(option.value)}
            />

            <div className="w-[100px]">
                <Select value={getValue(yearsOptions, year)} options={yearsOptions} label="Ano" placeholder="Selecione" onChange={(option: any) => setYear(option.value)} />
            </div>

            <Select value={getValue(monthsOptions, month)} options={monthsOptions} label="Mês" placeholder="Selecione" onChange={(option: any) => setMonth(option.value)} />
            <Button
                title="Carregar"
                variant="outlined"
                size="medium"
                className="border border-gray-200 rounded-full min-w-[33px] px-0 text-white gap-2 hover:border-gray-400 hover:opacity-[0.8] mb-[8px] bg-white"
                onClick={handleFilter}>
                <Icon name="ico-refresh" color={palette.grey[500]} width={15} height={15} />
            </Button>
        </div>
    );
};

export default Filters;
