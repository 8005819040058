import { Modify } from 'types/general';
import { FileApiModel } from 'types/models/file';
import { ProjectTypeSolicitationPayload } from 'types/models/project';

export type ReducerAction = {
    type: RequestActions;
    payload: any;
};

export type RequestActions = 'SET_STEP_COMPLETED' | 'SET_CURRENT_STEP' | 'SET_CONTENT' | 'TOGGLE_LOADER';

export type Step = {
    slug: RequestSteps;
    label: string;
};

export type RequestRecordState = {
    completeds: Step[];
    content: Modify<ProjectTypeSolicitationPayload, { files: Array<Partial<File & FileApiModel>> }>;
    currentStep: Step;
    isLoading: boolean;
    steps: Step[];
};

export enum RequestSteps {
    Details = 'DETAILS',
    Files = 'FILES',
    AttentionPoints = 'ATTENTION_POINTS'
}

export type Steps = RequestSteps.Details | RequestSteps.Files | RequestSteps.AttentionPoints;

export type RequestRecordContextType = {
    state: RequestRecordState;
    setCurrentStep: (slug: RequestSteps) => void;
    changeStep: (currentStepSlug: RequestSteps, nextStepSlug?: RequestSteps, previousStepSlug?: RequestSteps) => void;
    completeStep: (step: Step) => void;
    completeMultipleSteps: (steps: Step[]) => void;
    getStep: (slug: RequestSteps) => void;
    setContent: (payload: any, jumpToNextStep?: boolean) => void;
};
