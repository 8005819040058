export const MONTH_WIDTH = 180;
export const MONTH_HEIGHT = 64;
export const BAR_HEIGHT = 50;
export const MONTH_DAY_WIDTH = MONTH_WIDTH / 30;
export const WEEK_DAY_WIDTH = MONTH_WIDTH / 7;
export const BAR_MARGIN_TOP = 7;

export const getTotalDaysInYear = (year: number) => {
    return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
};
