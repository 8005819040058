import React, { useEffect, useMemo, useState } from 'react';

// Components
import Text from 'components/core/text';
import Switch from 'components/core/switch';

type PageProps = {
    title: string;
    rules?: {
        rule: {
            id: number;
            name: string;
        };
    }[];
    rulesSelecteds?: number[];
    onSelectItem: (values: number[]) => void;
};

const Page = ({ rules, rulesSelecteds, title, onSelectItem }: PageProps) => {
    const [selecteds, setSelecteds] = useState<number[]>(rulesSelecteds || []);
    const isEmpty = useMemo(() => !Boolean(rules?.length), [rules]);

    useEffect(() => {
        if (rulesSelecteds?.length) {
            setSelecteds(rulesSelecteds);
        }
    }, [rulesSelecteds]);

    const handleSelectItem = (value?: number) => () => {
        if (value) {
            const isSelected = selecteds.some((item) => item === value);

            const values = isSelected ? selecteds.filter((item) => item !== value) : [...selecteds, value];

            setSelecteds(values);

            return onSelectItem(values);
        }

        const ids = Boolean(selecteds.length) ? [] : rules?.map((item) => item.rule.id) || [];

        setSelecteds(ids);

        return onSelectItem(ids);
    };

    return (
        <div>
            <div className="flex items-center justify-between border-b border-b-base-200 py-3 mb-3 sticky left-0 top-0 bg-base-100 z-10">
                <Text as="label" className="text-base-500 font-bold">
                    {title}
                </Text>
                {!isEmpty && <Switch value={selecteds.length === (rules?.length || 0)} onChange={handleSelectItem()} />}
            </div>
            {isEmpty ? (
                <Text as="p" className="text-base-400 italic mb-7">
                    Nenhum resultado encontrado.
                </Text>
            ) : (
                rules?.map((item) => {
                    const isActive = selecteds.some((option) => option === item.rule.id);

                    return (
                        <div key={item.rule.id} className="flex items-center justify-between py-1">
                            <Text as="label" className="text-base-500 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis">
                                {item.rule.name}
                            </Text>
                            <Switch value={isActive} onChange={handleSelectItem(item.rule.id)} />
                        </div>
                    );
                })
            )}
        </div>
    );
};

export default Page;
