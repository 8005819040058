import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getProjectTypeSolicitationKey } from '../projects/graphql';

const useDeleteAttentionPoint = (solicitationId?: string, projectProposalTypeId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (attentionPointId?: number) => api.delete(`/project-types/${projectProposalTypeId}/solicitations/${solicitationId}/attention-points/${attentionPointId}`);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Ponto de atenção apagado com sucesso', 'success');

            queryClient.invalidateQueries(getProjectTypeSolicitationKey(solicitationId));
        },
        onError: () => {
            showToast('Ocorreu um erro ao apagar o ponto de atenção', 'danger');
        }
    });
};

export default useDeleteAttentionPoint;
