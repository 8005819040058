import logojp from 'assets/images/logo-jp-privacy-policy.png';
import Text from 'components/core/text';
import PublicLayout from 'pages/public/layout';
import { PropsWithChildren } from 'react';

type TermsPrivacyProps = {
    title: string;
};

const InstitutionalLayout = ({ title, children }: PropsWithChildren<TermsPrivacyProps>) => {
    return (
        <PublicLayout withScroll={true}>
            <main className="bg-white rounded rounded-t-2xl p-7 sm:rounded-b-2xl sm:p-20 w-[80vw] flex flex-col items-center mx-auto">
                <div className="w-full max-w-[200px]">
                    <img src={logojp} alt="logo JP Projetos" />
                </div>
                <Text className="my-10" variant="h1">
                    {title}
                </Text>
                {children}
            </main>
        </PublicLayout>
    );
};

export default InstitutionalLayout;
