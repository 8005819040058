import { Status } from 'types/general';
import { ProjectTypeRouteSlug } from './use-get-operational';
import { VariableType, jsonToGraphQLQuery } from 'json-to-graphql-query';

export const createOutcomeTransactionProject = ['operational', 'project', 'outcomes', 'create'];

export const getProviderContactKey = ['provider_contact', 'get', 'all'];

export const getProviderOptionsKey = ['provider', 'all', 'options'];

export const getAllDreKey = ['dre', 'get', 'all'];

export const getOutcomeTransactionProjectById = (projectId?: string, outcomeId?: string) => ['operational', 'project', 'transaction_project', projectId, outcomeId];

export const getClassificationOptionsKey = (typeId) => ['classification', 'all', 'options', typeId];

export const getOutcomeTransactionsProject = (projectId?: string, params?: any) => ['operational', 'project', 'transaction_project', projectId, params];

export const getOperationalKey = (projectTypeSlug?: ProjectTypeRouteSlug, filters: any = '') => ['operational', 'all', projectTypeSlug, filters];

export const startConstructionKey = (projectProposalTypeId?: number) => ['operational', 'start', projectProposalTypeId];

export const createDailyReportKey = (projectProposalTypeId?: number) => ['operational', 'daily-report', 'create', projectProposalTypeId];

export const getFollowupsKey = (projectProposalTypeId?: string | number) => ['followup', 'all', projectProposalTypeId];

export const getTimelineProvidersKey = (projectProposalTypeId?: string | number) => ['timelines', 'providers', projectProposalTypeId];

export const getDailyReportsKey = (projectProposalTypeId?: string | number) => ['operational', 'daily-report', 'all', projectProposalTypeId];

export const getFollowupKey = (projectProposalTypeId?: number, dailyReportId?: string | number) => ['followup', projectProposalTypeId, dailyReportId];

export const createMeasurementPeriodsKey = (providerId?: number, period?: number) => ['operational', 'measurement', 'periods', providerId, period];

export const createFinancialSetupKey = (projectProposalTypeId?: number) => ['operational', 'provider', 'financial', 'setup', 'create', projectProposalTypeId];

export const getFinancialSetupKey = (providerFinancialSetupId?: number) => ['operational', 'financial', 'setup', providerFinancialSetupId];

export const getFinancialPaymentKey = (paymentId?: number) => ['operational', 'financial', 'payment', paymentId];

export const getMeasurementPaymentsKey = (date?: string) => ['operational', 'financial', 'measurement', 'payments', date];

export const updateMeasurementKey = (measurementId?: number) => ['operational', 'financial', 'measurement', 'update', measurementId];

export const getReportWeeksKey = (projectProposalTypeId?: number) => ['reports', 'weeks', projectProposalTypeId];

export const getReportChartKey = (path: string, projectProposalTypeId?: number, week?: number) => ['reports', 'chart', projectProposalTypeId, path, week];

export const getScopeKey = (projectProposalTypeId?: number) => ['operational', projectProposalTypeId, 'scope'];

export const getAttentionPointKey = (attentionPointId?: string) => ['attention-point', attentionPointId];

export const getFilesByGroupDatabookIdKey = (projectProposalTypeId?: string, groupDatabookId?: string) => ['project-type', projectProposalTypeId, 'files', 'databook', 'groups', groupDatabookId];

export const getDatabooksByProjectProposalTypeIdKey = (projectProposalTypeId?: string, databookId?: string) => ['project-type', projectProposalTypeId, 'databook', 'all', databookId];

export const getProjectTransactionsReportKey = (projectProposalTypeId?: string) => ['project-type', projectProposalTypeId, 'transactions', 'report', 'all'];

export const getProjectMovementsKey = (projectProposalTypeId?: number) => ['project-type', projectProposalTypeId, 'movements'];

const operational = {
    getProjectsByProjectTypeSlug: `query GetProjectsByProjectTypeSlug ($projectTypeSlug: Int!) {
        project(where: {types: {project_type: {id: {_eq: $projectTypeId}}}}) {
            id
            title
            customer { title }
            project_management_informations { management_end_date }
            project_proposals(where: {proposal_types: {project_type: {id: {_eq: $projectTypeId}}}}) {
                proposal_types {
                    project_type_person_resources {
                        person {
                            user {
                                name
                                file {
                                    path
                                    filename
                                }
                            }
                        }
                    }
                }
            }
        }
    }`,
    getProjectsOutcomes: `query GetProjectOutcomes ($orderBy: [transaction_project_order_by!], $where: transaction_project_bool_exp, $limit: Int, $offset: Int) {
        items: transaction_project(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
            project_id
            transaction {
                id,
                payday,
                due_date,
                description,
                value,
                code
                classification {
                    id
                    name
                    type
                }
            }
        }
        pagination: transaction_project_aggregate (where: $where) {
            aggregate { count }
        }
    }`,
    getClassificationsByType: (classficationType: number, where: any = '') =>
        `
        query GetClassificationByType {
            classification(where: {type: {_eq: ${classficationType}}, status: {_eq: ${Status.Active}}, ${where}},order_by: {name: asc}) {
                value: id,
                label: name
                children_classifications: classifications {
                    value: id,
                    label: name
                }
            }
        }
    `,
    getProviderOptions: (isPartner?: boolean) => `
    query GetProviderOptions {
        provider(where: {status: {_eq: ${Status.Active}}, ${isPartner ? 'partner: { _eq: true }' : ''}}) {
          value: id,
          label: title
        }
      }
    `,
    getProjectTransactionOutcomeById: jsonToGraphQLQuery({
        query: {
            __name: 'GetProjectTransactionOutcomeById',
            __variables: {
                id: 'Int!'
            },
            transactionOutcome: {
                __aliasFor: 'transaction_project_by_pk',
                __args: {
                    id: new VariableType('id')
                },
                service: {
                    __aliasFor: 'classification_service_id'
                },
                provider: {
                    __aliasFor: 'provider_id'
                },
                person: {
                    __aliasFor: 'person_id'
                },
                transaction: {
                    id: true,
                    classification: {
                        __aliasFor: 'classification_id'
                    },
                    code: true,
                    description: true,
                    dueDate: {
                        __aliasFor: 'due_date'
                    },
                    payday: true,
                    value: true,
                    data_classification: {
                        __aliasFor: 'classification',
                        id: true,
                        name: true
                    },
                    documents: {
                        __aliasFor: 'transaction_documents',
                        document: {
                            classification_id: true,
                            name: true,
                            id: true,
                            file: {
                                id: true,
                                original_name: true,
                                filename: true,
                                path: true
                            }
                        }
                    },
                    observation: true
                },
                data_provider: {
                    __aliasFor: 'provider',
                    id: true,
                    juridic_document: true,
                    juridic_name: true,
                    title: true
                },
                data_service_classification: {
                    __aliasFor: 'classification',
                    id: true,
                    name: true
                }
            }
        }
    }),
    getProjectProviderContacts: `
    query GetProjectProviderContacts {
        provider_contact(where: {person: {status: {_eq: ${Status.Active}}}}) {
          provider_id
          person {
            value: id
            label: name
          }
        }
      }
      
    `,
    getCustomerByProject: {
        tableName: 'customer',
        labelColumn: 'title',
        where: { projects: { status: { _eq: Status.Active } }, status: { _eq: Status.Active } }
    },
    getProjectTypePersonResource: {
        tableName: 'project_type_person_resource',
        query: `query GetProjectTypePersonResource {
            items: project_type_person_resource(where: {manager: {_eq: true}, status: {_eq: ${Status.Active}}}, distinct_on: person_id) {
              person {
                id
                name
              }
            }
          }`,
        serialize: ({ person }: any) => ({ value: person.id, label: person.name })
    },
    getFilesByGroupId: (id?: string) => `query GetFilesByGroupId {
        items: project_data_book_group_file(where: {status: {_eq: ${Status.Active}}, project_data_book_group_id: {_eq: ${id}}}) {
            file {
                title
                id
                path
                original_name
            }
            project_data_book_group_id
        }
    }`
};

export default operational;
