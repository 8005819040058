import { useState } from 'react';
import Datatable from 'components/core/table/datatable';
import { DatatableColumn } from 'types/graphql';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import { useParams } from 'react-router-dom';
import useDeletePersonDocument from 'services/queries/people/use-delete-person-document';
import Spinner from 'components/core/spinner';
import ConfirmModal from 'components/core/modal/confirm';
import ExternalButton from 'components/buttons/external';
import { Rule } from 'types/permissions';

type PersonContractDocument = {
    id: number;
    file: { mimeType: string; url: string; id: number; path: string; pathTmp?: any; title?: any; originalName: string; filename: string };
    classification: {
        id: number;
        name: string;
        type: number;
        father?: any;
    };
    name: string;
    description: string;
    observation: string;
    type: number;
}[];

type Props = {
    actions: Rule[];
    items: PersonContractDocument[];
};

const PersonContractDetailsDocuments = ({ actions, items = [] }: Props) => {
    const { personId, contractId } = useParams();

    const [idToDelete, setIdToDelete] = useState<number>();

    const columns: DatatableColumn[] = [
        {
            name: 'id',
            options: { display: 'excluded' }
        },
        {
            name: 'name',
            label: dictionary.content.graphql['name']
        },

        {
            name: 'file.originalName',
            options: { display: 'excluded' }
        },

        {
            name: 'file.url',
            label: 'Arquivo',
            options: {
                customBodyRender: (value, tableMeta) => {
                    if (!value) return '-';

                    const filename = tableMeta.rowData[2];
                    const handleShowFile = () => window.open(value, '_blank');

                    return <ExternalButton onClick={handleShowFile}>{filename}</ExternalButton>;
                }
            }
        },
        {
            name: 'actions',
            label: 'Ações',
            options: {
                customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
            },
            customRoutePath: () => `/app/pessoas/${personId}/contratos/${contractId}/documentos`
        }
    ];

    const { mutate, isLoading } = useDeletePersonDocument({ contractId, personId: personId });

    const handleDelete = async () => {
        mutate(idToDelete!);

        setIdToDelete(undefined);
    };

    return (
        <>
            {isLoading && <Spinner />}
            {!!idToDelete && (
                <ConfirmModal
                    title="Apagar documento"
                    description="Você tem certeza que deseja apagar este documento?"
                    isLoading={isLoading}
                    onClose={() => setIdToDelete(undefined)}
                    onConfirmAction={handleDelete}
                />
            )}
            <Datatable
                actions={actions}
                columns={columns}
                data={items}
                loading={false}
                options={{
                    elevation: 0,
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: false
                }}
                withItemsPerPage={false}
                hideFooter={true}
                onDelete={(id?: number) => id && setIdToDelete(id)}
            />
        </>
    );
};

export default PersonContractDetailsDocuments;
