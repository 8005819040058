export const getAllBranchesOptionsKey = (account_id?: number) => ['branches', 'all', 'options', account_id];

const queries = {
    getAll: `query GetBranchesOptions ($account_id: Int!) {
        items: branch(order_by: {name: asc}, where: {status: {_neq: "-1"}, account_id: {_eq: $account_id}}) {
            value: id
            label: name
        }
    }`
};

export default queries;
