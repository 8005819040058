import React from 'react';

import theme from 'settings/theme';
import Empty from 'components/empty';
import Text from 'components/core/text';
import { UseQueryResult } from 'react-query';
import Spinner from 'components/core/spinner';
import type { WeeklyEffective as WeeklyEffectiveType } from 'types/models/report';
import PieChart from '../pie-chart';
import WeeklyEffectiveTable from './table';

const colors = ['#f9c000', '#a6a6a6', '#4f81bd', '#0a2160'];

const WeeklyEffective = ({ isLoading, isError, data: weeklyEffective }: UseQueryResult<WeeklyEffectiveType, unknown>) => {
    return (
        <div className="border border-base-300 rounded-[14px] mb-4">
            <Text as="h6" variant="h6" className="p-4 text-heading">
                Efetivo semanal e clima
            </Text>
            {isLoading ? (
                <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} className="min-h-[100px]" />
            ) : isError ? (
                <Empty title="Dados não encontrados" className="px-4" />
            ) : (
                <div className="flex flex-col sm:flex-row items-center sm:items-start">
                    <div className="sm:flex-1 pl-4 pr-6 max-w-full">
                        <WeeklyEffectiveTable effective={weeklyEffective?.effective} totals={weeklyEffective?.totals} week={weeklyEffective?.week} />
                    </div>
                    <PieChart
                        colors={colors}
                        labels={weeklyEffective?.graphic.labels || []}
                        datasets={weeklyEffective?.graphic.datasets || { data: [] }}
                        className="w-[200px] h-[250px] sm:w-[350px] p-4 sm:h-[400px]"
                    />
                </div>
            )}
        </div>
    );
};

export default WeeklyEffective;
