import { useMemo } from 'react';
import dayjs from 'dayjs';

export const useIsActiveMonth = ({ year, month }: { year: number; month: string }) => {
    const isActive = useMemo<boolean>(() => {
        const today = dayjs();

        const isSameYear = Number(year) === today.get('year');

        const isSameMonth = month === today.format('MMMM').toUpperCase();

        return isSameYear && isSameMonth;
    }, [year, month]);

    return isActive;
};
