import { Modify, Option, Seniority } from 'types/general';
import { Project, ProjectProposalType, ProjectService, ProjectType, ProjectTypeEnum } from './project';
import { Person } from './person';
import { BaseModel } from './base';
import { Classification } from './classification';
import { CommercialBudgetVersionsResponse } from 'services/queries/commercial/use-get-budget-versions';
import { CamelCase, SnakeCase } from 'types/utils';
import { Pagination } from 'types/pagination';

export enum BudgetStatus {
    NotConverted = -1,
    Started = 1,
    GeneratedContract
}

export type CommercialBudgetPayload = {
    types: ProjectTypeEnum[];
    customer: number;
    branch: number;
    code: string;
    title: string;
};

export type Service = {
    totalValue: number;
    hourMonth?: number;
    hourValue?: number;
    licenseValue?: number;
    seniority?: Seniority;
    service?: Option;
    discount?: number;
    classification?: Option;
    addition?: number;
    actualValue?: number;
    value?: number;
    quantity?: number;
};

export type Employee = {
    licenseValue: number;
    hourMonth: number;
    hourValue: number;
};

export type CommercialBudgetResponse = {
    budgetStatus: BudgetStatus;
    person: Person;
    project: Pick<Project, 'id' | 'title' | 'code'>;
    additive?: boolean;
} & Pick<Project, 'code' | 'customer' | 'id' | 'title' | 'branch'>;

export enum CommercialBudgetCostEnum {
    ExpensesThirdParties = 1,
    ExpensesOthers,
    ProductionTeam,
    ManagementTeam,
    PostDeliveryCost,
    Contingency
}

export enum CommercialBudgetManagementTeamEnum {
    General = 1,
    Manager,
    Coordinator
}

export enum CommercialBudgetGroupedCostType {
    Service = 1
}

export type CommercialBudgetVersionDetailsCostItems = Modify<
    Pick<CommercialBudgetVersionDetailsCosts, 'id' | 'projectService' | 'quantity' | 'value' | 'percentage'>,
    {
        seniority: Seniority;
        licenseFee: number;
        numberOfHours: number;
        typeManagement: CommercialBudgetManagementTeamEnum | null;
        manager: boolean | null;
    } & Pick<Service, 'hourValue'>
>;

export type CommercialBudgetVersionDetailsCosts = Modify<
    Pick<Service, 'value' | 'quantity'>,
    {
        projectService: Modify<
            Pick<ProjectService, 'id' | 'title' | 'type'>,
            {
                numberOfProjects: number;
                name: string;
            }
        >;
        classification: Pick<Classification, 'id' | 'type' | 'name' | 'father'>;
        type: CommercialBudgetCostEnum;
        percentage: number;
        items: CommercialBudgetVersionDetailsCostItems[];
    } & Pick<BaseModel, 'id'>
>;

type CommercialBudgetVersioDetailsGroupedCosts = Modify<
    Pick<Service, 'addition' | 'discount'>,
    {
        grossValue: number;
        netValue: number;
        projectService: CommercialBudgetVersionDetailsCosts['projectService'];
    } & Pick<UpdateBudgetPayload, 'footage'>
>;

export type CommercialBudgetVersionDetails = Modify<
    Pick<UpdateBudgetPayload, 'factor' | 'date' | 'deadline' | 'footage'>,
    {
        projectType: Pick<ProjectType, 'id' | 'slug' | 'title'>;
        version: number;
        active: boolean;
        contractValue: number;
        indirectCosts: number;
        directCosts: number;
        margin: number;
        percentageMargin: number;
        percentageCostAdministrative: number;
        percentageCostTax: number;
        percentageCommercialExpenses: number;
        percentageFinancialExpenses: number;
        costs: CommercialBudgetVersionDetailsCosts[];
        groupedCosts: CommercialBudgetVersioDetailsGroupedCosts[];
    } & Pick<BaseModel, 'id'>
>;

export type CostItem = Modify<
    Partial<Omit<CommercialBudgetVersionDetailsCosts, 'id' | 'items'>>,
    {
        items?: CostItem[];
        projectService?: number;
        classification?: number;
        seniority?: Seniority;
        licenseFee?: number;
        numberOfHours?: number;
        hourValue?: number;
        typeManagement?: CommercialBudgetManagementTeamEnum | null;
        manager?: boolean | null;
    }
>;

export type CommercialBudgetOutletContext = {
    commercialBudget: CommercialBudgetResponse;
    budgetVersions: CommercialBudgetVersionsResponse['items'];
    setProjectTypeId: (value: number) => void;
};

export type UpdateBudgetPayload = {
    thirdPartyExpenses: Service[];
    teamProductionExpenses: Service[];
    otherExpenses: Service[];
    manager: Employee;
    coordinator: Employee;
    selectedServices: Service[];
    contingency: number;
    postDeliveryCost: number;
    backoffice: number;
    tax: number;
    commercialExpense: number;
    financialExpense: number;
    GEOPArchitecture?: number;
    GEOPInstallations?: number;
    GEOPBid?: number;
    GEOPManagement?: number;
    date: string;
    factor: number;
    footage: number;
    deadline: number;
};

type CommercialBudgetByProjectIdBudgetVersions = {
    budget_versions: CommercialBudgetVersionsResponse['items'];
};

export type CommercialBudgetByProjectIdResponse = Pagination<
    SnakeCase<Pick<CommercialBudgetResponse, 'id' | 'title' | 'code' | 'customer' | 'budgetStatus'> & CommercialBudgetVersionsResponse & CommercialBudgetByProjectIdBudgetVersions>
>;

export type BudgetAdditiveContractPayload = {
    versions: Modify<
        CamelCase<Pick<CommercialBudgetVersionsResponse['items'][0], 'budget_version'>>,
        {
            withPhase: boolean;
            phases?: Modify<
                CamelCase<Partial<Pick<ProjectProposalType['phases'][0], 'payment_percentage'>>>,
                {
                    order: number;
                    classification: number;
                    classifications: number[];
                }
            >[];
        }
    >[];
};
