import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import ExternalButton from 'components/buttons/external';
import Badge from 'components/core/badge';
import Text from 'components/core/text';
import { ProjectTypeTerm, TermStatus } from 'types/models/project';
import { ColorVariant } from 'types/colors';
import { CamelCase } from 'types/utils';
import Empty from 'components/empty';

type Colors = {
    [key: string | number]: ColorVariant;
};

type TermsOriginProjectProps = {
    data: CamelCase<ProjectTypeTerm>[];
    className?: string;
};

const statusesLabel = {
    [TermStatus.Waiting]: 'Aguardando impressão',
    [TermStatus.Created]: 'Termo gerado',
    [TermStatus.Finalized]: 'Termo finalizado'
};

const statusesColors: Colors = {
    [TermStatus.Waiting]: 'grey',
    [TermStatus.Created]: 'info',
    [TermStatus.Finalized]: 'success'
};

const TermsOriginProject = ({ data = [], className }: TermsOriginProjectProps) => {
    if (!data.length) {
        return <Empty title="Nenhum termo cadastrado." className={className} />;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                Código
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                Tipo
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                Criador
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                Status
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item) => {
                        return (
                            <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    <Text as="span" variant="body.regular.sm" className="text-base-500">
                                        {item.number}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" variant="body.regular.sm" className="text-base-500">
                                        {item.type.title || '-'}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <ExternalButton onClick={() => null}>{item.person?.name || ''}</ExternalButton>
                                </TableCell>
                                <TableCell>
                                    {Boolean(statusesLabel[item.termStatus]) ? <Badge variant={statusesColors[item.termStatus] || 'grey'}>{statusesLabel[item.termStatus]}</Badge> : '-'}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TermsOriginProject;
