import { ForgotPasswordWithTokenPayload } from 'types/models/user';
import { useParams } from 'react-router-dom';
import useResetPassword from 'services/queries/forgot-password/use-reset-password';
import PublicLayout from 'pages/public/layout';
import ResetPasswordForm from 'pages/public/components/form/reset-password-form';

const ResetPasswordPage = () => {
    const { token } = useParams();

    const { mutateAsync: mutateResetPassword, isLoading: isSubmitting } = useResetPassword(token);
    const handleSubmit = async (data: ForgotPasswordWithTokenPayload) => {
        try {
            await mutateResetPassword(data);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <PublicLayout>
            <ResetPasswordForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />
        </PublicLayout>
    );
};

export default ResetPasswordPage;
