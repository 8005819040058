import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Timeline } from 'types/models/timeline';
import { getTimelineKey } from './graphql';

type Response = {
    timeline: Partial<Timeline>[];
};

const useGetTimeline = (query: string, providerId?: number) => {
    const fetcher = async () => apiGraphQl<Response>(query).then(({ timeline }) => timeline[0]);

    return useQuery(getTimelineKey(query), fetcher, { enabled: Boolean(providerId) });
};

export default useGetTimeline;
