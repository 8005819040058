import React from 'react';
import Text from 'components/core/text';
import CircularProgress from '@material-ui/core/CircularProgress';

type TimelineProgressProps = {
    progress: number;
};

const TimelineProgress = ({ progress }: TimelineProgressProps) => {
    return (
        <div className="w-[100px] h-[100px] sm:w-[150px] sm:h-[150px]">
            <div className="relative text-primary-100 text-opacity-50 w-[100%] h-[100%] flex items-center justify-center">
                <Text as="span" className="text-center text-primary-500">
                    <strong>{progress}</strong>%
                </Text>
                <CircularProgress size="100%" value={progress} thickness={2} variant="determinate" color="primary" className="absolute left-0 top-0 z-10" />
                <CircularProgress size="100%" variant="determinate" value={100} color="inherit" thickness={2} className="absolute left-0 top-0 z-0" />
            </div>
            <Text as="p" variant="body.medium.sm" className="text-base-700 mt-2 text-center">
                Cronograma geral
            </Text>
        </div>
    );
};

export default TimelineProgress;
