import { useMemo, useState } from 'react';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import Empty from 'components/empty';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import AddButton from 'components/buttons/add';
import ExternalButton from 'components/buttons/external';
import { pluralize } from 'utils/string';
import dictionary from 'utils/dictionary';
import { ProjectProposalType, ProjectStatus, ProjectStatusEnum } from 'types/models/project';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import { Link } from 'react-router-dom';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import classNames from 'classnames';

type ResourcesProps = {
    projectStatus?: Partial<ProjectStatus>;
    projectProposalType: Partial<ProjectProposalType> & { projectProposalId?: number };
    emptyClassName?: string;
    onAddResource: () => void;
};

const ProposalResources = ({ emptyClassName, projectProposalType, projectStatus, onAddResource }: ResourcesProps) => {
    const [observation, setObservation] = useState<string>();
    const { palette } = useTheme();

    const canAddResource = useMemo(() => projectStatus?.slug === ProjectStatusEnum.PreTap, [projectStatus]);

    const handleToggleModal = (value?: string) => () => setObservation(value);

    return (
        <>
            <div>
                {Boolean(projectProposalType.resources?.length) ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Cargo
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Senioridade
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Quantidade
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Turno
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Periodicidade
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Observações
                                        </Text>
                                    </TableCell>
                                    {projectStatus?.slug === ProjectStatusEnum.PreTap && (
                                        <TableCell>
                                            <Text as="span" className="text-secondary-500">
                                                Ações
                                            </Text>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projectProposalType.resources?.map((resource) => {
                                    return (
                                        <TableRow key={resource.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {resource.job_position?.title || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {Object.values(dictionary.content.jobSeniorities)[(resource.seniority || 0) - 1] || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {pluralize(resource.quantity || 0, 'profissionais', 'profissional')}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {Object.values(dictionary.content.shifts)[(resource.shift || 0) - 1] || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {Object.values(dictionary.content.frequencies)[(resource.frequency || 0) - 1] || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                {resource.observations ? (
                                                    <ExternalButton onClick={handleToggleModal(resource.observations)}>Visualizar</ExternalButton>
                                                ) : (
                                                    <Text as="span" className="text-base-500">
                                                        -
                                                    </Text>
                                                )}
                                            </TableCell>
                                            {projectStatus?.slug === ProjectStatusEnum.PreTap && (
                                                <TableCell>
                                                    <>
                                                        <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                            <IconButton
                                                                component={Link}
                                                                to={`proposta/${projectProposalType.projectProposalId}/tipo/${projectProposalType.id}/recursos/${resource?.id}/editar`}
                                                                className="hover:bg-system-warning-100 hover:bg-opacity-30">
                                                                <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            {canAddResource && (
                                <TableFooter>
                                    <TableRow classes={{ root: 'border-t' }}>
                                        <TableCell colSpan={6} classes={{ root: 'border-none' }}>
                                            <AddButton onClick={onAddResource}>Novo recurso</AddButton>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </TableContainer>
                ) : (
                    <Empty
                        title="Nenhum recurso cadastrado."
                        buttonLabel={canAddResource ? 'Cadastrar recurso' : undefined}
                        className={classNames('p-4', emptyClassName)}
                        onClick={canAddResource ? onAddResource : undefined}
                    />
                )}
            </div>
            {Boolean(observation) && (
                <Modal contentClassnames="py-5 px-7" onClose={handleToggleModal(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: observation! }} />
                </Modal>
            )}
        </>
    );
};

export default ProposalResources;
