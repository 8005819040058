import { generateArray } from 'pages/private/operationals/schedule/utils';
import { WEEK_WIDTH } from '../../utils';

type Props = {
    totalWeeks: number;
    uniqKey: string;
};

const WeekDays = ({ totalWeeks, uniqKey }: Props) => {
    if (!totalWeeks) {
        return null;
    }

    // gap-1 px-1

    return (
        <div className="flex w-full mt-1 items-center">
            {generateArray(totalWeeks).map((week) => {
                return (
                    <div
                        key={[week, uniqKey].join('-')}
                        style={{
                            width: WEEK_WIDTH
                        }}
                        className="px-[3px]">
                        <span className="bg-slate-200 text-heading text-xs flex items-center justify-center rounded-md flex-1">{week + 1}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default WeekDays;
