// Helpers
import { ProjectManagementInformation } from 'types/models/project';

export type ProjectTypeProps = {
    activeAccordion: ProjectTypeAccordion;
    projectProposalId: number;
    projectTypeId: number;
    onToggleAccordion: (type: ProjectTypeProps['activeAccordion'], expanded: boolean) => void;
    projectProposalTypeId?: number;
    onAddResource?: (proposalTypeId?: number) => void;
    managementInformations?: Partial<ProjectManagementInformation>[];
};

export enum ProjectTypeAccordion {
    ClosedAccordion = -1,
    Management,
    Project,
    BID,
    ComplementaryProject
}
