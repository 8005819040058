import { object, string, boolean, number } from 'yup';

import dictionary from 'utils/dictionary';

const { required } = dictionary.validation;

const onlyLetters = 'Apenas letras';

const schema = {
    classification: object({
        name: string().typeError(onlyLetters).required(required)
    }),
    classificationDocumentType: object({
        classification_id: number().nullable(true),
        name: string().typeError(onlyLetters).required(required)
    }),
    classificationProviderServices: object({
        classification_id: number().nullable(true),
        name: string().typeError(onlyLetters).required(required)
    }),
    classificationScope: object({
        name: string().typeError(onlyLetters).required(required),
        classification_id: number().nullable(true),
        project_service_id: number().nullable(true)
    }),
    jobPosition: object({
        title: string().typeError(onlyLetters).required(required),
        classification_department_id: number().required(required),
        manager: boolean().default(false)
    }),
    typeBusiness: object({
        title: string().typeError(onlyLetters).required(required)
    })
};

export default schema;
