import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { ProjectTypePersonResourcePeriods } from 'types/models/project';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { formatDate } from 'utils/date';

type ModalResourcePeriodsProps = {
    periods: Partial<ProjectTypePersonResourcePeriods>[];
    onClose: () => void;
};

const ModalResourcePeriods = ({ periods, onClose }: ModalResourcePeriodsProps) => {
    return (
        <Modal contentClassnames="py-5 px-7 w-[500px]" onClose={onClose}>
            <Text variant="h4" as="h4" className="text-heading">
                Períodos
            </Text>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="pl-0">
                                <Text as="span" className="text-secondary-500">
                                    Início do contrato
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Final do contrato
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Percentual
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {periods.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell className="pl-0">
                                        <Text as="span" className="text-base-500">
                                            {formatDate(item.contract_start_date)}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {formatDate(item.contract_end_date)}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-base-500">
                                            {item.percentage || 0}%
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Modal>
    );
};

export default ModalResourcePeriods;
