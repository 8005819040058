import React, { useState } from 'react';
import { AttentionPoint, AttentionPointType } from 'types/models/project';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import ExternalButton from 'components/buttons/external';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Badge from 'components/core/badge';
import { formatDate } from 'utils/date';
import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import ConfirmModal from 'components/core/modal/confirm';
import useDeleteAttentionPoint from 'services/queries/operationals/use-delete-attention-point';
import TableContainer from '@mui/material/TableContainer/TableContainer';

type RequestAttentionPointsProps = {
    projectId?: number;
    solicitationId?: number;
    projectProposalTypeId?: number;
    attentionPoints: AttentionPoint[];
};

const warningButton = { classes: { child: 'text-system-warning-100' } };
const dangerButton = { classes: { child: 'text-system-danger-100' } };

const RequestAttentionPoints = ({ attentionPoints, projectProposalTypeId, solicitationId, projectId }: RequestAttentionPointsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const [description, setDescription] = useState<string>();
    const [attentionPointToDeleteId, setAttentionPointToDeleteId] = useState<number>();

    const { mutateAsync: deleteAttentionPoint, isLoading: isSubmitting } = useDeleteAttentionPoint(solicitationId?.toString(), projectProposalTypeId?.toString());

    const handleDelete = async () => {
        try {
            await deleteAttentionPoint(attentionPointToDeleteId);
            setAttentionPointToDeleteId(undefined);
        } catch (error) {
            console.log('handleDelete', error);
        }
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Tarefa</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Atribuída a</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {attentionPoints.map((item, index) => (
                        <TableRow sx={{ '&:last-child td': { border: 0 } }} key={index}>
                            <TableCell>
                                <ExternalButton onClick={() => navigate(`tarefas/${item.task.id}`)}>{item.task.title}</ExternalButton>
                            </TableCell>
                            <TableCell>{formatDate(item.task.deadline)}</TableCell>
                            <TableCell>
                                <ExternalButton onClick={() => navigate(`/app/pessoas/${item.task.person.id}/contratos`)}>{item.task.person?.title}</ExternalButton>
                            </TableCell>
                            <TableCell>
                                <Badge key={item.id} variant="secondary" className="mr-2">
                                    {item.type === AttentionPointType.critical ? 'Crítico' : 'Relevante'}
                                </Badge>
                            </TableCell>
                            <TableCell>
                                <ExternalButton onClick={() => setDescription(item.solicitation?.description)}>Visualizar</ExternalButton>
                            </TableCell>
                            <TableCell>
                                <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                    <IconButton
                                        component={Link}
                                        to={`pontos-de-atencao/${item.id}/editar?projectId=${projectId}`}
                                        TouchRippleProps={warningButton}
                                        className="hover:bg-system-warning-100 hover:bg-opacity-30">
                                        <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                    <IconButton TouchRippleProps={dangerButton} className="hover:bg-system-danger-100 hover:bg-opacity-30" onClick={() => setAttentionPointToDeleteId(item.id)}>
                                        <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {Boolean(description) && (
                <Modal contentClassnames="py-5 px-7 w-[500px]" onClose={() => setDescription(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Descrição
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: description! }} />
                </Modal>
            )}
            {Boolean(attentionPointToDeleteId) && (
                <ConfirmModal
                    title="Apagar ponto de atenção"
                    description="Você tem certeza que deseja apagar este ponto de atenção?"
                    onClose={() => setAttentionPointToDeleteId(undefined)}
                    isLoading={isSubmitting}
                    onConfirmAction={handleDelete}
                />
            )}
        </TableContainer>
    );
};

export default RequestAttentionPoints;
