import React, { useMemo } from 'react';
import Text from 'components/core/text';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Timeline } from 'types/models/timeline';

type ChecklistProgressProps = {
    className?: string;
    checklist?: Timeline;
};

const ChecklistProgress = ({ className, checklist }: ChecklistProgressProps) => {
    const doneTasks = useMemo(() => {
        const tasks = checklist?.tasks || [];

        const arr = tasks.filter((item) => item.taskStatus?.slug === 'done');

        return arr.length || 0;
    }, [checklist]);

    if (!checklist) {
        return null;
    }

    return (
        <div className={className}>
            <div className="relative text-primary-100 text-opacity-50 w-[100px] h-[100px] sm:w-[150px] sm:h-[150px] flex items-center justify-center">
                <Text as="span" className="text-center text-primary-500">
                    <strong>{doneTasks}</strong> de <strong>{checklist.tasks.length || 0}</strong> <br />
                    concluídas
                </Text>
                <CircularProgress size="100%" value={checklist.progressPercentage} thickness={2} variant="determinate" color="primary" className="absolute left-0 top-0 z-10" />
                <CircularProgress size="100%" variant="determinate" value={100} color="inherit" thickness={2} className="absolute left-0 top-0 z-0" />
            </div>
            <Text as="p" variant="body.medium.sm" className="text-base-700 mt-2 text-center">
                Checklist
            </Text>
        </div>
    );
};

export default ChecklistProgress;
