import React from 'react';

// Dependencies
import { useOutletContext } from 'react-router-dom';

// Components
import Checklist from './checklist';

// Helpers
import { Timeline, TimelineType } from 'types/models/timeline';
import useGetTaskStatuses from 'services/queries/projects/use-get-task-statuses';

const ChecklistPage = () => {
    const timeline = useOutletContext<Timeline | undefined>();
    const { data: statuses } = useGetTaskStatuses(TimelineType.Checklist);

    return <Checklist statuses={statuses || []} timeline={timeline} />;
};

export default ChecklistPage;
