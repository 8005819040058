import React, { useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useGetFinancialSetup from 'services/queries/operationals/use-get-financial-setup';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import Avatar from '@mui/material/Avatar/Avatar';
import hexToRgba from 'utils/hex-to-rgba';
import { formatMoney } from 'utils/money';
import FinancialMeasurements from './measurements';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import FinancialPayments from './payments';

type TabValue = 'MEASUREMENTS' | 'PAYMENTS';

const FinancialViewPage = () => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { providerFinancialSetupId } = useParams();

    const [tab, setTab] = useState<TabValue>('PAYMENTS');

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    const { data: setup, isLoading, error } = useGetFinancialSetup(Number(providerFinancialSetupId));

    const totalAvaliable = useMemo(() => Number(setup?.budget?.value) - Number(setup?.measuredValue), [setup?.budget?.value, setup?.measuredValue]);

    if (isLoading) {
        return (
            <div className="absolute left-2/4 top-2/4 -ml-4 -mt-4">
                <Spinner fixed={false} />
            </div>
        );
    }

    if (error || !setup) {
        return <Empty className="p-4" title="Configuração financeira não encontrada." />;
    }

    return (
        <>
            <div className="flex items-center justify-between mb-6">
                <Text as="h4" variant="h4" className="text-heading">
                    {setup.budget.provider.title || ''}
                </Text>
                <IconButton className="bg-base-200" size="large" color="default" onClick={() => navigate(-1)}>
                    <Icon name="ico-close" color={palette.heading} width={10} height={10} />
                </IconButton>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                {setup.budget && (
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.success[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-dollar-sign" color={palette.success[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title="Orçamento"
                        subheader={formatMoney(parseFloat(setup.budget?.value?.toString() || '') || 0)}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                )}
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: hexToRgba(palette.error[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                            <Icon name="ico-dollar-sign" color={palette.error[500]} />
                        </Avatar>
                    }
                    classes={{ root: 'p-0' }}
                    title="Saldo utilizado"
                    subheader={formatMoney(parseFloat(setup.measuredValue?.toString() || '') || 0)}
                    titleTypographyProps={{ color: palette.grey[500] }}
                    subheaderTypographyProps={{ color: palette.heading }}
                />
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: hexToRgba(palette.success[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                            <Icon name="ico-dollar-sign" color={palette.success[500]} />
                        </Avatar>
                    }
                    classes={{ root: 'p-0' }}
                    title="Saldo disponível"
                    subheader={formatMoney(totalAvaliable || 0)}
                    titleTypographyProps={{ color: palette.grey[500] }}
                    subheaderTypographyProps={{ color: palette.heading }}
                />
                {Boolean(setup.valueEntryInstallment) && (
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.error[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-dollar-sign" color={palette.error[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title="Entrada"
                        subheader={`${formatMoney(parseFloat(setup.valueEntryInstallment?.toString() || '') || 0)} (${parseFloat(setup.percentageEntryInstallment?.toString() || '0')}%)`}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                )}
                {Boolean(setup.measuredValueEntryInstallment) && (
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.success[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-dollar-sign" color={palette.success[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title="Valor de entrada medido"
                        subheader={formatMoney(parseFloat(setup.measuredValueEntryInstallment?.toString() || '') || 0)}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                )}
                {setup.period && (
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-calendar" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title="Medições a cada"
                        subheader={`${setup.period} dias`}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                )}
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: hexToRgba(palette.warning[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                            <Icon name="ico-dollar-sign" color={palette.warning[500]} />
                        </Avatar>
                    }
                    classes={{ root: 'p-0' }}
                    title="Com retenção técnica"
                    subheader={setup.withTechnicalRetention ? 'Sim' : 'Não'}
                    titleTypographyProps={{ color: palette.grey[500] }}
                    subheaderTypographyProps={{ color: palette.heading }}
                />
            </div>
            <Tabs classes={{ root: 'bg-base-100 z-[10]' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                <Tab value="PAYMENTS" label="FATURAMENTOS DIRETOS" />
                <Tab value="MEASUREMENTS" label="MEDIÇÕES" />
            </Tabs>
            {tab === 'PAYMENTS' && <FinancialPayments payments={setup.payments} />}
            {tab === 'MEASUREMENTS' && <FinancialMeasurements measurements={setup.measurements || []} />}
            <Outlet context={{ setup }} />
        </>
    );
};

export default FinancialViewPage;
