import React from 'react';
import PeopleMap from 'pages/private/people-map';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import { Navigate, RouteObject } from 'react-router-dom';
import PeopleMapHHMonth from 'pages/private/people-map-hh/month';
import PeopleMapHHYear from 'pages/private/people-map-hh/year';

const peopleMap: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Mapa de pessoa por alocação',
        path: 'mapa-de-pessoas',
        element: <Page code="mapa-de-pessoas" permissions={[Rule.Show]} Component={PeopleMap} withModal={true} />
    },
    {
        // @ts-ignore
        breadcrumb: 'Mapa de HH',
        path: 'mapa-de-hh',
        element: <Navigate to="/app" replace={true} />
        // <Page code="mapa-de-pessoas" permissions={[Rule.Show]} Component={PeopleMapHH} withModal={true} />
    },
    {
        // @ts-ignore
        breadcrumb: 'Mapa de HH',
        path: 'mapa-de-hh/mes',
        element: <PeopleMapHHMonth />
        // <Page code="mapa-de-pessoas" permissions={[Rule.Show]} Component={PeopleMapHH} withModal={true} />
    },
    {
        // @ts-ignore
        breadcrumb: 'Mapa de HH',
        path: 'mapa-de-hh/ano',
        element: <PeopleMapHHYear />
        // <Page code="mapa-de-pessoas" permissions={[Rule.Show]} Component={PeopleMapHH} withModal={true} />
    }
];

export default peopleMap;
