import useTheme from '@mui/material/styles/useTheme';
import Text from 'components/core/text';

type ProviderDetailProps = {
    label?: string;
    value: string;
};

const ProviderDetail = ({ label, value }: ProviderDetailProps) => {
    const { palette } = useTheme();

    return (
        <div className="flex flex-col">
            {!!label && (
                <Text className="mb-1 !text-base" style={{ color: palette.grey[500] }}>
                    {label}
                </Text>
            )}
            <Text className="text-sm" style={{ color: palette.heading }}>
                {value}
            </Text>
        </div>
    );
};

export default ProviderDetail;
