// Dependencies
import { useQuery } from 'react-query';

// Helpers
import api from 'services/api';
import { getChecklistKey } from './graphql';
import { Timeline } from 'types/models/timeline';

const useGetChecklist = (isManagement: boolean, projectProposalTypeId?: string) => {
    const fetcher = () => api.get<Timeline>(`/project-types/${projectProposalTypeId}/timelines/checklist`).then(({ data }) => data);

    return useQuery(getChecklistKey(projectProposalTypeId), fetcher, { enabled: isManagement && Boolean(projectProposalTypeId) });
};

export default useGetChecklist;
