// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import provider, { getAllProvidersOptionsKey } from './graphql';

const useGetProvidersOptions = () => {
    const fetcher = () => apiGraphQl<Pagination<Option>>(provider.getAll).then((data) => data.items);

    return useQuery(getAllProvidersOptionsKey, fetcher);
};

export default useGetProvidersOptions;
