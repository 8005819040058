import classNames from 'classnames';
import useTheme from '@mui/material/styles/useTheme';

type Props = {
    activePeriod: number;
    onChange(period: number): void;
};

const MyTasksSwitch = ({ activePeriod, onChange }: Props) => {
    const { palette } = useTheme();

    return (
        <div className="grid grid-cols-3 place-items-center md:flex md:items-center border border-base-300 rounded-2xl p-[2px]">
            {['Dia', 'Semana', 'Mês'].map((label, index) => {
                const isActive = index === activePeriod;

                const className = classNames('px-2 md:px-5 py-2 text-center w-[100%] text-xs md:text-base', isActive && `text-base-100 rounded-[14px]`);

                return (
                    <button onClick={() => onChange(index)} key={index} type="button" className={className} style={{ background: isActive ? palette.secondary[400] : 'transparent' }}>
                        {label}
                    </button>
                );
            })}
        </div>
    );
};

export default MyTasksSwitch;
