import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Input from 'components/core/form/input';
import ActionButton from 'components/task-tree/action';
import AddButton from 'components/buttons/add';
import { Controller } from 'react-hook-form';
import DatePicker from 'components/core/datepicker';
import get from 'lodash/get';
import { useTimelineFieldFormProject } from '../../hooks/use-timeline-form-project';
import { Option } from 'types/general';
import Select from 'components/core/form/select';
import Text from 'components/core/text';
import { dateReduceFn } from '../management/recursive-management';
import { useLocation } from 'react-router-dom';

export type RecursiveProps = {
    levels: number;
    prefix?: string;
    className?: string;
    style?: any;
    people: Option[];
};

const hasChildren = (children: any[]) => Boolean(children?.length);

export const predictedTimeReduceFn = (acc, curr) => acc + (curr.predictedTime || 0);

const RecursiveProject = ({ className, levels, prefix = '', style, people }: RecursiveProps) => {
    const { pathname } = useLocation();

    const [hasChanged, setHasChanged] = useState(false);
    const [hasEndDateChanged, setHasEndDateChanged] = useState(false);
    const [hasStartDateChanged, setHasStartDateChanged] = useState(false);

    const isBid = pathname.includes('bid');

    const containerClasses = classNames('border rounded-xl relative p-5 border-base-300 mb-2', className);

    const currentLevels = useMemo(() => {
        const result = prefix.match(/\d+/g);

        return result?.length || 0;
    }, [prefix]);

    const isPrimaryTask = !prefix && !isBid;

    const canAddNewService = useMemo(() => currentLevels < (levels || 0) && !isPrimaryTask, [currentLevels, levels, isPrimaryTask]);

    const {
        control,
        fields,
        formState,
        titleInputPath,
        predictedTimePath,
        personInputPath,
        endDateInputPath,
        itemsArrayInputPath,
        getValues,
        handleAdd,
        handleDelete,
        watch,
        setValue,
        startDateInputPath
    } = useTimelineFieldFormProject(prefix);

    const items = watch(itemsArrayInputPath);

    const parentItems = useMemo(() => {
        const grandParentItems = itemsArrayInputPath.split('.').filter(Boolean);

        grandParentItems.splice(grandParentItems.length - (levels - 1), levels);

        const path = [...grandParentItems, 'items'].join('.');
        const endDatePath = [...grandParentItems, 'endDate'].join('.');
        const startDatePath = [...grandParentItems, 'startDate'].join('.');
        const predictedTimePath = [...grandParentItems, 'predictedTime'].join('.');
        const personPath = [...grandParentItems, 'person'].join('.');

        return {
            items: get(getValues(), path),
            endDatePath,
            startDatePath,
            predictedTimePath,
            personPath
        };
    }, [itemsArrayInputPath, levels, getValues]);

    const calculateTotalPredictedTime = useCallback(() => {
        if (!Boolean(items.length)) {
            return;
        }

        if (canAddNewService) {
            const arr = itemsArrayInputPath.split('.').filter(Boolean);
            // Remove last item
            arr.pop();

            // Create predicted time parent path
            const predictedTimePath = [...arr, 'predictedTime'].join('.');
            const personPath = [...arr, 'person'].join('.');

            // Sum all of predicted times inside parent items array
            const predictedTimeTotal = items?.reduce(predictedTimeReduceFn, 0);

            // Sum all of predicted times inside grandparent items array
            const predictedTimeGrandParentTotal = parentItems.items?.reduce(predictedTimeReduceFn, 0);

            if (predictedTimePath.includes('.')) {
                // Set new total value to predicted time parent
                setValue(predictedTimePath as any, predictedTimeTotal);
                setValue(personPath as any, undefined);
            }

            if (parentItems.predictedTimePath.includes('.')) {
                // Set new total value to grandparent
                setValue(parentItems.predictedTimePath as any, predictedTimeGrandParentTotal);
            }

            const firstPath = arr.length > 2 ? `${arr[0]}.${arr[1]}` : '';

            if (Boolean(firstPath.length)) {
                setValue(`${firstPath}.predictedTime` as any, predictedTimeGrandParentTotal);
            }

            setHasChanged(false);
        }
    }, [canAddNewService, items, itemsArrayInputPath, parentItems, setValue]);

    const getBiggerDate = useCallback(
        (field: string) => {
            if (!Boolean(items.length)) {
                return;
            }

            if (canAddNewService) {
                const arr = itemsArrayInputPath.split('.').filter(Boolean);

                // Remove last item
                arr.pop();

                // Create field parent path
                const path = [...arr, field].join('.');

                // Sum all of endDates inside items array
                const greatestDate = items?.reduce(dateReduceFn(field), '');

                const greatestDateParent = parentItems.items?.reduce(dateReduceFn(field), '');

                // Set new total value to field parent
                setValue(path as any, greatestDate);
                // Set new total value to grandparent
                setValue(parentItems[`${field}Path`] as any, greatestDateParent);

                const firstPath = arr.length > 2 ? `${arr[0]}.${arr[1]}` : '';

                if (firstPath) {
                    setValue(`${firstPath}.${field}` as any, greatestDateParent);
                }

                if (field === 'endDate') {
                    setHasEndDateChanged(false);
                } else {
                    setHasStartDateChanged(false);
                }
            }
        },
        [itemsArrayInputPath, setValue, items, canAddNewService, parentItems]
    );

    useEffect(() => {
        calculateTotalPredictedTime();
    }, [calculateTotalPredictedTime, hasChanged]);

    useEffect(() => {
        getBiggerDate('endDate');
    }, [getBiggerDate, hasEndDateChanged]);

    useEffect(() => {
        getBiggerDate('startDate');
    }, [getBiggerDate, hasStartDateChanged]);

    return (
        <Fragment>
            {fields.map((item, index) => {
                const path = `${prefix}items.${index}`;
                const finded = getValues(path as any);

                const replaced = prefix.replace(/\D/g, '');
                const parentIdentifier = replaced
                    .split('')
                    .map((item) => Number(item) + 1)
                    .join('.');

                const identifier = prefix ? `${parentIdentifier}.${index + 1}` : (index + 1).toString();

                const errorTitle = get(formState.errors, titleInputPath(index));
                const errorPerson = get(formState.errors, personInputPath(index));
                const errorStartDate = get(formState.errors, startDateInputPath(index));
                const errorEndDate = get(formState.errors, endDateInputPath(index));
                const errorPredictedTime = get(formState.errors, predictedTimePath(index));

                return (
                    <div key={item.id} className={containerClasses} style={style}>
                        <div className="flex items-start mb-2 w-full overflow-x-auto pb-2">
                            <span className="flex items-center justify-center bg-base-200 text-heading rounded-[10px] min-w-[30px] px-2 py-[2px] mr-4 mt-[10px]">{identifier}</span>
                            <Controller
                                name={titleInputPath(index)}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Input
                                            {...field}
                                            disabled={isPrimaryTask}
                                            parentClassName="flex-1 mr-2 min-w-[220px] w-[unset]"
                                            autoComplete="nope"
                                            type="text"
                                            placeholder="Título do serviço"
                                            error={errorTitle?.message}
                                        />
                                    );
                                }}
                            />
                            {!hasChildren(finded.items) && (
                                <Controller
                                    name={personInputPath(index)}
                                    control={control}
                                    render={({ field }) => {
                                        const value = people?.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={people}
                                                placeholder="Selecione o responsável"
                                                error={errorPerson}
                                                onChange={(option: any) => field.onChange(option.value)}
                                                parentClassName="mr-2 min-w-[230px]"
                                            />
                                        );
                                    }}
                                />
                            )}
                            <Controller
                                name={startDateInputPath(index)}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            {...field}
                                            disabled={hasChildren(finded.items)}
                                            placeholderText="Data inicial"
                                            error={errorStartDate?.message}
                                            parentClasses="min-w-[160px] mr-2"
                                            onChange={(date: Date) => {
                                                field.onChange(date.toISOString());
                                                setHasStartDateChanged(true);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name={endDateInputPath(index)}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            {...field}
                                            disabled={hasChildren(finded.items)}
                                            placeholderText="Data final"
                                            error={errorEndDate?.message}
                                            parentClasses="min-w-[160px]  mr-2"
                                            onChange={(date: Date) => {
                                                field.onChange(date.toISOString());
                                                setHasEndDateChanged(true);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name={predictedTimePath(index)}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Input
                                            {...field}
                                            disabled={hasChildren(finded.items)}
                                            type="number"
                                            autoComplete="nope"
                                            placeholder="Tempo previsto"
                                            error={errorPredictedTime?.message}
                                            parentClassName="min-w-[220px] sm:min-w-unset w-auto w-[150px]"
                                            rightClasses={`${hasChildren(finded.items) ? 'bg-base-300' : 'bg-base-200'} px-4`}
                                            right={
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    /horas
                                                </Text>
                                            }
                                            onChange={(e) => {
                                                setValue(predictedTimePath(index), +e.currentTarget.value);
                                                setHasChanged(true);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <ActionButton disabled={isPrimaryTask} variant="remove" className="mt-[10px] ml-4" onClick={handleDelete(index)} />
                        </div>
                        <RecursiveProject people={people} key={item.id} prefix={`${prefix}items.${index}.`} className="border-0 p-0" levels={levels} style={{ marginLeft: 46 }} />
                    </div>
                );
            })}
            {canAddNewService && (
                <AddButton className="mb-2" onClick={handleAdd}>
                    Novo serviço
                </AddButton>
            )}
        </Fragment>
    );
};

export default memo(RecursiveProject);
