export const getSignpostClassKey = (signpostClassId?: string) => ['signpost', signpostClassId];

const queries = {
    getSignpostClass: `query GetSignPostClassByPk ($id: Int!) {
        signpost_class_by_pk(id: $id) {
            id
            title
            signposts(order_by: {order_signpost: asc}) {
                id
                order_signpost
                title
                url
                with_type
                with_dimensions
            }
        }
    }`
};

export default queries;
