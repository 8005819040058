import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Input from 'components/core/form/input';
import ActionButton from 'components/task-tree/action';
import AddButton from 'components/buttons/add';
import { Controller } from 'react-hook-form';
import DatePicker from 'components/core/datepicker';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { useTimelineFieldFormManagement } from '../../hooks/use-timeline-form-management';
import { isValidDate } from 'utils/date';

export type RecursiveProps = {
    levels: number;
    prefix?: string;
    className?: string;
    style?: any;
};

const hasChildren = (children: any[]) => Boolean(children?.length);

export const dateReduceFn = (field: string) => (acc, curr) => {
    if (!acc) {
        return curr[field];
    }

    if (!isValidDate(curr[field])) {
        return acc;
    }

    const isStartDate = field === 'startDate';

    if (isStartDate) {
        const isBefore = dayjs(acc).isBefore(dayjs(curr[field]));

        return isBefore ? acc : curr[field];
    }

    const isAfter = dayjs(acc).isAfter(dayjs(curr[field]));

    return isAfter ? acc : curr[field];
};

const RecursiveManagement = ({ className, levels, prefix = '', style }: RecursiveProps) => {
    const [hasEndDateChanged, setHasEndDateChanged] = useState(false);
    const [hasStartDateChanged, setHasStartDateChanged] = useState(false);

    const containerClasses = classNames('border rounded-xl relative p-5 border-base-300 mb-2', className);

    const currentLevels = useMemo(() => {
        const result = prefix.match(/\d+/g);

        return result?.length || 0;
    }, [prefix]);

    const canAddNewService = useMemo(() => currentLevels < (levels || 0), [currentLevels, levels]);

    const { control, fields, formState, titleInputPath, endDateInputPath, itemsArrayInputPath, getValues, handleAdd, handleDelete, watch, setValue, startDateInputPath } =
        useTimelineFieldFormManagement(prefix);

    const items = watch(itemsArrayInputPath);

    const parentItems = useMemo(() => {
        const grandParentItems = itemsArrayInputPath.split('.').filter(Boolean);

        grandParentItems.splice(grandParentItems.length - (levels - 1), levels);

        const path = [...grandParentItems, 'items'].join('.');
        const endDatePath = [...grandParentItems, 'endDate'].join('.');
        const startDatePath = [...grandParentItems, 'startDate'].join('.');

        return {
            items: get(getValues(), path),
            endDatePath,
            startDatePath
        };
    }, [itemsArrayInputPath, levels, getValues]);

    const getBiggerDate = useCallback(
        (field: string) => {
            if (!Boolean(items.length)) {
                return;
            }

            if (canAddNewService) {
                const arr = itemsArrayInputPath.split('.').filter(Boolean);

                // Remove last item
                arr.pop();

                // Create field parent path
                const path = [...arr, field].join('.');

                // Sum all of endDates inside items array
                const greatestDate = items?.reduce(dateReduceFn(field), '');

                const greatestDateParent = parentItems.items?.reduce(dateReduceFn(field), '');

                // Set new total value to field parent
                setValue(path as any, greatestDate);
                // Set new total value to grandparent
                setValue(parentItems[`${field}Path`] as any, greatestDateParent);

                const firstPath = arr.length > 2 ? `${arr[0]}.${arr[1]}` : '';

                if (firstPath) {
                    setValue(`${firstPath}.${field}` as any, greatestDateParent);
                }

                if (field === 'endDate') {
                    setHasEndDateChanged(false);
                } else {
                    setHasStartDateChanged(false);
                }
            }
        },
        [itemsArrayInputPath, setValue, items, canAddNewService, parentItems]
    );

    useEffect(() => {
        getBiggerDate('endDate');
    }, [getBiggerDate, hasEndDateChanged]);

    useEffect(() => {
        getBiggerDate('startDate');
    }, [getBiggerDate, hasStartDateChanged]);

    return (
        <Fragment>
            {fields.map((item, index) => {
                const path = `${prefix}items.${index}`;
                const finded = getValues(path as any);

                const replaced = prefix.replace(/\D/g, '');
                const parentIdentifier = replaced
                    .split('')
                    .map((item) => Number(item) + 1)
                    .join('.');

                const identifier = prefix ? `${parentIdentifier}.${index + 1}` : index + 1;

                const errorTitle = get(formState.errors, titleInputPath(index));
                const errorStartDate = get(formState.errors, startDateInputPath(index));
                const errorEndDate = get(formState.errors, endDateInputPath(index));

                return (
                    <div key={item.id} className={containerClasses} style={style}>
                        <div className="flex items-start w-full overflow-x-auto mb-2">
                            <span className="flex items-center justify-center bg-base-200 text-heading rounded-[10px] min-w-[30px] px-2 py-[2px] mr-4">{identifier}</span>
                            <Controller
                                name={titleInputPath(index)}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Input
                                            {...field}
                                            parentClassName="flex-1 mr-2 min-w-[240px] sm:min-w-unset sm:w-[unset]"
                                            autoComplete="nope"
                                            type="text"
                                            placeholder="Título do serviço"
                                            error={errorTitle?.message}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name={startDateInputPath(index)}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            {...field}
                                            disabled={hasChildren(finded.items)}
                                            placeholderText="Data inicial"
                                            error={errorStartDate?.message}
                                            parentClasses="min-w-[160px] min-w-unset sm:w-[160px] mr-2"
                                            onChange={(date: Date) => {
                                                field.onChange(date.toISOString());
                                                setHasStartDateChanged(true);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name={endDateInputPath(index)}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            {...field}
                                            disabled={hasChildren(finded.items)}
                                            placeholderText="Data final"
                                            error={errorEndDate?.message}
                                            parentClasses="min-w-[160px] sm:min-w-unset sm:w-[160px]"
                                            onChange={(date: Date) => {
                                                field.onChange(date.toISOString());
                                                setHasEndDateChanged(true);
                                            }}
                                        />
                                    );
                                }}
                            />
                            <ActionButton variant="remove" className="ml-4" onClick={handleDelete(index)} />
                        </div>
                        <RecursiveManagement key={item.id} prefix={`${prefix}items.${index}.`} className="border-0 p-0" levels={levels} style={{ marginLeft: 46 }} />
                    </div>
                );
            })}
            {canAddNewService && (
                <AddButton className="mt-2" onClick={handleAdd}>
                    Novo serviço
                </AddButton>
            )}
        </Fragment>
    );
};

export default memo(RecursiveManagement);
