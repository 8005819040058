import { useState } from 'react';

// Components
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import ExternalButton from 'components/buttons/external';
import TimelineProgress from './management/timeline-progress';
import ChecklistProgress from './management/checklist-progress';

// Helpers
import { formatDate } from 'utils/date';
import { ProjectProposalType, ProjectTypeEnum } from 'types/models/project';
import classNames from 'classnames';
import { Timeline } from 'types/models/timeline';

type ProjectTypeDetailsProps = {
    type?: 'management';
    withCharts?: boolean;
    projectProposalType: Partial<ProjectProposalType>;
    containerClassNames?: string;
    checklist?: Timeline;
};

const ProjectTypeDetails = ({ projectProposalType, type, containerClassNames, checklist, withCharts = true }: ProjectTypeDetailsProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const containerClasses = classNames('p-5 flex items-center flex-wrap', containerClassNames);

    const handleToggleModal = (value: boolean) => () => setIsModalOpen(value);

    return (
        <>
            <div className={containerClasses}>
                <div className="flex-1 w-full">
                    {projectProposalType.start_date && (
                        <div className="flex items-center mb-3">
                            <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                Data inicial:
                            </Text>
                            <Text variant="body.regular.sm" as="span" className="text-base-700">
                                {formatDate(projectProposalType.start_date, 'DD/MM/YYYY')}
                            </Text>
                        </div>
                    )}
                    {projectProposalType.end_date && (
                        <div className="flex items-center mb-3">
                            <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                Data final:
                            </Text>
                            <Text variant="body.regular.sm" as="span" className="text-base-700">
                                {formatDate(projectProposalType.end_date, 'DD/MM/YYYY')}
                            </Text>
                        </div>
                    )}
                    {projectProposalType.duration && (
                        <div className="flex items-center mb-3">
                            <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                Duração:
                            </Text>
                            <Text variant="body.regular.sm" as="span" className="text-base-700">
                                {projectProposalType.duration} dias
                            </Text>
                        </div>
                    )}
                    {projectProposalType.project_methodology && (
                        <div className="flex items-center mb-3">
                            <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                Metodologia:
                            </Text>
                            <Text variant="body.regular.sm" as="span" className="text-base-700">
                                {projectProposalType.project_methodology.title}
                            </Text>
                        </div>
                    )}
                    {Boolean(projectProposalType.project_proposal_type_has_services?.length) && (
                        <>
                            {projectProposalType.project_proposal_type_has_services?.map((item, index) => {
                                return (
                                    <div key={index} className="flex items-center mb-3">
                                        <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                            {item.project_service.type === ProjectTypeEnum.Management ? 'Tipologia' : 'Disciplina'}:
                                        </Text>
                                        <Text variant="body.regular.sm" as="span" className="text-base-700">
                                            {item.project_service.title}
                                        </Text>
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {Boolean(projectProposalType.has_entry_installment) && (
                        <div className="flex items-center mb-3">
                            <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                Faturamento:
                            </Text>
                            <Text variant="body.regular.sm" as="span" className="text-base-700">
                                {projectProposalType.value_of_entry_installment}%
                            </Text>
                        </div>
                    )}
                    {projectProposalType.director && (
                        <div className="flex items-center mb-3">
                            <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                Responsável:
                            </Text>
                            <ExternalButton className="w-100 text-ellipsis overflow-hidden" onClick={() => null}>
                                {projectProposalType.director.name}
                            </ExternalButton>
                        </div>
                    )}
                    {projectProposalType.manager && (
                        <div className="flex items-center mb-3">
                            <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                Gerente:
                            </Text>
                            <ExternalButton onClick={() => null}>{projectProposalType.manager.name}</ExternalButton>
                        </div>
                    )}
                    {projectProposalType.observations && (
                        <div className="flex items-center">
                            <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                                Observações:
                            </Text>
                            <ExternalButton onClick={handleToggleModal(true)}>Visualizar</ExternalButton>
                        </div>
                    )}
                </div>
                {type === 'management' && withCharts && (
                    <div className="flex gap-5 justify-start mt-5 lg:mt-5 sm:justify-end sm:ml-6 mb-8">
                        <ChecklistProgress checklist={checklist} className="sm:mr-6" />
                        <TimelineProgress progress={projectProposalType.progress || 0} />
                    </div>
                )}
            </div>
            {isModalOpen && projectProposalType.observations && (
                <Modal contentClassnames="py-5 px-7" onClose={handleToggleModal(false)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: projectProposalType.observations }} />
                </Modal>
            )}
        </>
    );
};

export default ProjectTypeDetails;
