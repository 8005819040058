import { Option } from 'types/general';
import dictionary from 'utils/dictionary';
import { array, number, object, string } from 'yup';

export const schema = (customerContacts: Option[]) =>
    object({
        customer: number().required(dictionary.validation.required),
        title: string().required(dictionary.validation.required),
        code: string().required(),
        notes: string(),
        footage: object({
            formattedValue: string(),
            value: string(),
            floatValue: number()
        })
            .nullable(true)
            .notRequired(),
        ...(Boolean(customerContacts.length) && { customerContact: number().required(dictionary.validation.required) }),
        types: array(number()).min(1, dictionary.validation.required),
        address: object({
            city_id: number().notRequired(),
            state_id: number().notRequired(),
            compliment: string().notRequired(),
            neighbourhood: string().notRequired(),
            number: string().notRequired(),
            street: string().notRequired(),
            zip: string().notRequired()
        })
            .notRequired()
            .nullable()
    });
