import { useQuery } from 'react-query';
import { getPeopleByProjectOptionsKey } from './graphql';
import api from 'services/api';
import { Person } from 'types/models/person';

type ResponsePeopleByProjectType = Person & { hourValue: number };

const useGetPeopleByProjectTypeOptions = (projectTypeId?: number) => {
    const request = async () => {
        return api.get<ResponsePeopleByProjectType[]>(`/project-types/${projectTypeId}/people`).then(({ data }) => {
            return data.map((item) => ({
                value: item.id,
                label: item.title || item.name,
                manager: item.manager,
                hourValue: item?.hourValue
            }));
        });
    };

    return useQuery(getPeopleByProjectOptionsKey(projectTypeId), request, { enabled: Boolean(projectTypeId) });
};

export default useGetPeopleByProjectTypeOptions;
