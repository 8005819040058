import { memo, useCallback, useMemo, useState } from 'react';
import TimelineCreateOrUpdate from './timeline';
import useCreateOrUpdateTimeline from 'services/queries/projects/use-create-or-update-timeline';
import useGetProvidersOptions from 'services/queries/providers/use-get-providers-options';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useGetTimeline from 'services/queries/projects/use-get-timeline';
import { Task } from 'types/models/task';
import ConfirmModal from 'components/core/modal/confirm';
import { TimelineType } from 'types/models/timeline';
import { useQueryClient } from 'react-query';
import { getChecklistsKey, getTimelineKey } from 'services/queries/projects/graphql';
import useProjectTypeSlug from 'pages/private/operationals/hooks/use-project-type-slug';
import { CamelCase } from 'types/utils';
import Modal from 'components/core/modal';
import Empty from 'components/empty';

const TimelineCreateOrUpdatePage = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();

    const { isManagement } = useProjectTypeSlug();

    const isManagementTimeline = useMemo(() => pathname.includes('cronogramas') && isManagement, [pathname, isManagement]);
    const { projectProposalTypeId, projectTypeTimelineId, taskPlanningId } = useParams();

    const [payload, setPayload] = useState();

    const { data: providers = [], isLoading: isLoadingProviders } = useGetProvidersOptions();
    const { data: timeline } = useGetTimeline(Number(projectProposalTypeId), taskPlanningId || projectTypeTimelineId);
    console.log('timeline', timeline);

    const { isLoading: isSubmitting, mutateAsync: createOrUpdateTimeline } = useCreateOrUpdateTimeline(projectProposalTypeId, taskPlanningId, projectTypeTimelineId);

    const isBuilding = useMemo(() => isLoadingProviders, [isLoadingProviders]);

    const handleToggleModal = (data: any) => () => setPayload(data);

    const isTimelineType = isManagementTimeline || timeline?.type === TimelineType.Timeline;

    const handleConfirmAction = (data?: any, callSuccessAction?: boolean) => async () => {
        const initialData = data || payload;

        delete initialData['hasProblem'];

        try {
            const formatItems = (item, index) => ({
                ...item,
                id: item.currentId || null,
                order: index + 1,
                items: item.items.map(formatItems)
            });

            if (isManagementTimeline) {
                delete initialData['predictedTime'];
            }

            const payload = {
                ...initialData,
                items: initialData.items.map(formatItems)
            };

            await createOrUpdateTimeline(payload);

            if (callSuccessAction) {
                queryClient.invalidateQueries(getChecklistsKey(projectProposalTypeId));
                queryClient.invalidateQueries(getTimelineKey(taskPlanningId || projectTypeTimelineId));

                navigate(-1);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleSubmit = (data: any, callSuccessAction?: boolean) => {
        if (data.hasProblem) {
            return handleToggleModal(data)();
        }

        return handleConfirmAction(data, callSuccessAction)();
    };

    const formatItems = useCallback(
        (task: CamelCase<Task>) => {
            const [hours] = (task.predictedTime || '').split(':');

            return {
                currentId: task.id,
                title: task.title || '',
                endDate: task.endDate || undefined,
                startDate: task.startDate || undefined,
                items: task.tasks?.map(formatItems) || [],
                ...(!isManagementTimeline && {
                    predictedTime: Number(hours || 0) || 0,
                    person: task.person?.id
                })
            };
        },
        [isManagementTimeline]
    );

    const defaultValues = useMemo(() => {
        return {
            provider: timeline?.provider?.id,
            endDate: timeline?.endDate,
            startDate: timeline?.startDate || undefined,
            ...(!isManagementTimeline && { predictedTime: timeline?.predictedTime }),
            items: timeline?.tasks?.map(formatItems) || []
        };
    }, [timeline, formatItems, isManagementTimeline]);

    const handleBack = () => navigate(-1);

    if (typeof timeline?.canEditTask === 'boolean' && !timeline?.canEditTask) {
        return (
            <Modal closeOnClickOutside={false} contentClassnames="w-[400px]" onClose={handleBack}>
                <Empty title={`${isManagementTimeline ? 'Cronograma' : 'Planejamento de tarefas'} finalizado para edição`} className="p-6" />
            </Modal>
        );
    }

    return (
        <>
            <TimelineCreateOrUpdate
                statusCreation={timeline?.statusCreation}
                proposalType={timeline?.proposalType}
                projectId={timeline?.project?.id}
                defaultValues={defaultValues}
                isBuilding={isBuilding}
                isSubmitting={isSubmitting}
                providers={providers}
                onSubmit={handleSubmit}
                isTimelineType={isTimelineType}
            />
            {Boolean(payload) && (
                <ConfirmModal
                    title="Atenção!"
                    description="A quantidade de horas planejadas para este planejamento de tarefas não está de acordo com as horas previstas. Deseja continuar?"
                    isLoading={isSubmitting}
                    onClose={handleToggleModal(false)}
                    onConfirmAction={handleConfirmAction(undefined, true)}
                />
            )}
        </>
    );
};

export default memo(TimelineCreateOrUpdatePage);
