// Helpers
import theme from 'settings/theme';

// Assets
import logoFull from 'assets/images/logo-full.svg';
import classNames from 'classnames';

type PublicLayoutProps = {
    children: React.ReactChild;
    withScroll?: boolean;
};

const PublicLayout = ({ children, withScroll = false }: PublicLayoutProps) => {
    const containerClasses = classNames(styles.container, withScroll ? 'h-auto block overflow-auto py-[80px] h-[100vh]' : 'h-full flex sm:px-40 md:px-60');
    return (
        <section className={containerClasses}>
            <div className={styles.logo}>
                <img alt={theme.metadata.name} src={logoFull} className="w-52 sm:w-64" />
            </div>

            {children}
        </section>
    );
};

const styles = {
    container: 'w-full bg-secondary-100 bg-public bg-opacity-40 flex-col items-end sm:justify-center xl:items-center',
    logo: 'flex items-center justify-center w-full flex-1 sm:flex-none sm:mb-10'
};

export default PublicLayout;
