import { PeopleMapFilter } from './types';
import usePeopleMapRequest from './use-people-map-request';

const useGetMapOfHoursPerPersonAndYear = <T>(filters: PeopleMapFilter[]) => {
    return usePeopleMapRequest<T>({
        endpoint: '/map-of-hours-per-person-and-year',
        filters
    });
};

export default useGetMapOfHoursPerPersonAndYear;
