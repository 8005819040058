import { WhereClause } from 'types/graphql';
import { PersonContract } from 'types/models/person';

export const getAllPeopleOptionsKey = (customer_id?: number) => ['people', 'all', 'options', customer_id];

export const getAllPersonContractsKey = (where?: WhereClause<Partial<PersonContract>>) => ['people', 'all', 'options', where];

export const createPersonBankAccountKey = (personId?: string, bankAccountId?: string) => ['person', personId, 'create', 'bank_account', bankAccountId];
export const getAllBanksOptionsKey = ['get', 'all', 'banks'];

export const getPeopleKey = (id?: string) => ['people', id];
export const getPeopleDetailsKey = (id?: string) => ['people_details', id];
export const getPersonDetailsTabKey = (id?: string) => [...getPeopleDetailsKey(id), 'tab'];

export const getPersonDocumentKey = (documentId?: string) => ['person_document', documentId];

export const getPeopleByProjectOptionsKey = (projectId?: number) => ['person', 'all', 'options', 'project', projectId];

export const getPersonBankAccountKey = (bankAccountId?: string) => ['get', 'all', 'person', 'bankAccount', bankAccountId];

const queries = {
    getAll: `query GetPersons ($where: person_bool_exp) {
        items: person(order_by: {title: asc}, where: $where) {
            value: id
            label: title
        }
    }`,
    getAllContracts: `query ($where: person_contract_bool_exp) {
        items: person_contract(order_by: {person: { title: asc }}, where: $where) {
            value: person { id }
            label: person { title }
        }
    }`,
    getPersonBankAccountById: `query getPersonBankAccountById ($bankAccountId: Int!) {
        data: bank_account_by_pk(id: $bankAccountId) {
            id
            pix
            bank {
                id
                name
            }
            account
            account_digit
            agency
            agency_digit
            main
        }
    }`,
    getAllBanks: `query GetAllBanksOptions {
        items: bank(order_by: {name: asc}) {
            value: id
            label: name
        }
    }`
};

export default queries;
