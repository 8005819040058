import { useNavigate, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Select from 'components/core/form/select';
import CreateProposalBaseForm from '../components/form';

import theme from 'settings/theme';
import projectBaseSchema from '../utils/schema';
import { CreateProposalPageProps } from '../utils/types';
import { Project, ProjectProposalPayload, ProjectStatusEnum, ProjectTypeEnum } from 'types/models/project';
import useGetProjectServiceByType from 'services/queries/projects/use-get-project-service-by-type';
import { weekDays } from 'utils/date';
import { useEffect, useState } from 'react';

const CreateManagementProposal = ({ projectProposalId, projectTypeId, isBuilding, isSubmitting, onSubmit, defaultValues, projectStatusSlug }: CreateProposalPageProps) => {
    const navigate = useNavigate();

    const { data: services } = useGetProjectServiceByType(ProjectTypeEnum.Management);

    const ctx = useOutletContext<{ projectDetails?: Partial<Project> }>();
    const hasBudgetId = Boolean(ctx?.projectDetails?.budgets?.length);

    const percentageAverage = (value: any, context: any) => {
        if (!Array.isArray(value)) {
            return true;
        }

        const average = (value || []).reduce((acc, curr) => acc + Number(curr.paymentPercentage), 0);

        if (average !== 100) {
            return false;
        }

        methods.clearErrors(context.path);

        return true;
    };

    const [duration, setDuration] = useState('');

    const methods = useForm<ProjectProposalPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(projectBaseSchema(duration, projectProposalId, Number(projectTypeId), !hasBudgetId ? percentageAverage : undefined, projectStatusSlug, hasBudgetId)),
        defaultValues: {
            budgetExpense: {
                formattedValue: '',
                value: ''
            },
            budgetProvider: {
                formattedValue: '',
                value: ''
            },
            investmentType: '',
            duration: undefined,
            investment: {
                formattedValue: '',
                value: ''
            },
            observations: ''
        }
    });

    const durationWatcher = methods.watch('duration');

    useEffect(() => {
        setDuration(durationWatcher);
    }, [durationWatcher]);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-full sm:w-[980px] mx-5 sm:min-w-[500px] max-w-[1124px]" onClose={navigate.bind(this, -1)}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={methods.handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            Proposta {'>'} Gerenciamento
                        </Text>
                        <CreateProposalBaseForm projectStatusSlug={projectStatusSlug} projectType={+projectTypeId} defaultValues={defaultValues} {...methods}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                {projectStatusSlug === ProjectStatusEnum.PreTap && (
                                    <>
                                        <Controller
                                            name="services"
                                            control={methods.control}
                                            render={({ field }) => {
                                                const value = (services || []).filter((item) => field.value?.find((value) => value === item.value));

                                                return (
                                                    <Select
                                                        {...field}
                                                        value={value}
                                                        options={services || []}
                                                        label="Tipologia"
                                                        placeholder="Selecione uma opção"
                                                        error={(methods as any).formState.errors?.services?.message}
                                                        onChange={(option: any) => field.onChange([option.value])}
                                                    />
                                                );
                                            }}
                                        />
                                        <Controller
                                            name="weekDays"
                                            control={methods.control}
                                            render={({ field }) => {
                                                const value = (weekDays || []).filter((item) => field.value?.find((value) => +value === item.value));

                                                return (
                                                    <Select
                                                        {...field}
                                                        isMulti={true}
                                                        value={value}
                                                        options={weekDays}
                                                        label="Período de trabalho"
                                                        placeholder="Selecione uma ou mais opções"
                                                        error={(methods as any).formState.errors?.weekDays?.message}
                                                        onChange={(option: any) => {
                                                            const ids = option.map((item) => item.value);

                                                            field.onChange(ids);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </CreateProposalBaseForm>
                        <div className="flex items-center mt-4">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={navigate.bind(this, -1)}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateManagementProposal;
