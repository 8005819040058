import useToast from 'hooks/toast/use-toast';
import { PagePayload } from 'pages/private/pages/create-or-update';
import { useMutation, useQueryClient } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Modify } from 'types/general';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Page } from 'types/models/page';
import { getAllKey } from '../crud/graphql';

const useCreateOrUpdatePage = (params: GraphqlPaginationVariables<Page>, query: string, pageId?: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: Modify<PagePayload, { system: boolean }>) => {
        const data = {
            ...(pageId && { id: pageId }),
            payload
        };

        return apiGraphQl(query, data);
    };

    return useMutation(fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('page', params));
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${!!pageId ? 'atualizar' : 'criar'} a página`, 'danger');
        }
    });
};

export default useCreateOrUpdatePage;
