import React, { Fragment, useEffect } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'components/core/form/select';
import Button from 'components/core/button';
import { Option } from 'types/general';
import { formatMoney } from 'utils/money';
import Input from 'components/core/form/input';
import Checkbox from 'components/core/form/checkbox';
import DatePicker from 'components/core/datepicker';
import { ProviderFinancialPaymentType, ProviderFinancialSetupPayload } from 'types/models/provider';
import IconButton from '@mui/material/IconButton/IconButton';
import Measurements from './measurements';
import { financialSetupSchema } from '../utils';
import useCreateMeasurementPeriods from 'services/queries/operationals/use-create-measurement-periods';
import ErrorMessage from 'components/error/message';
import Empty from 'components/empty';
import CurrencyInput from 'components/core/form/currency';
import { NumberFormatValues } from 'react-number-format';

type CreateOrUpdateFollowupProps = {
    budgetId: number;
    budget: number;
    isBuilding: boolean;
    isSubmitting: boolean;
    providers: Option[];
    onSubmit: (data: ProviderFinancialSetupPayload) => void;
    providerHook: [number | undefined, React.Dispatch<React.SetStateAction<number | undefined>>];
    projectProposalTypeId?: number;
};

const INITIAL_VALUES = {
    budget: 0,
    measurements: [],
    payments: [],
    period: 0,
    withTechnicalRetention: false
};

const INITIAL_PAYMENT = { date: '', type: ProviderFinancialPaymentType.Start };

const CreateOrUpdateFinancial = ({ budget, budgetId, isBuilding, providers, isSubmitting, providerHook, onSubmit, projectProposalTypeId }: CreateOrUpdateFollowupProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const [providerId, setProviderId] = providerHook;

    const percentageAverage = (value: ProviderFinancialSetupPayload['measurements'], context: any) => {
        if (!Array.isArray(value)) {
            return true;
        }

        const average = Math.round((value || []).reduce((acc, curr) => acc + Number(curr.predictedPercentage), 0));

        if (average < 100 || average > 100) {
            return false;
        }

        clearErrors(context.path);

        return true;
    };

    const budgetAverage = (value: ProviderFinancialSetupPayload['measurements'], context: any) => {
        if (!Array.isArray(value)) {
            return true;
        }

        const average = (value || []).reduce((acc, curr) => (acc + (curr.predictedValue?.floatValue || 0)) as any, 0);

        if (average !== budget) {
            return false;
        }

        clearErrors(context.path);

        return true;
    };

    const handlePercentage = (value: ProviderFinancialSetupPayload['payments'], context: any) => {
        const [payment] = value;

        if (Number(payment?.value) > Number(measurements[0]?.predictedValue)) {
            setError('payments', { message: 'O valor de entrada não pode ser maior que o valor da primeira medição' });
            return false;
        }

        clearErrors(context.path);

        return true;
    };

    const methods = useForm<ProviderFinancialSetupPayload & { withPaymentStart: 0 | 1 }>({
        mode: 'onSubmit',
        defaultValues: INITIAL_VALUES,
        resolver: yupResolver(financialSetupSchema(budget, percentageAverage, handlePercentage, budgetAverage))
    });

    const { control, formState, setValue, watch, handleSubmit, clearErrors, setError } = methods;

    const budgetForm = watch('budget');
    const period = watch('period');
    const measurements = watch('measurements');

    useEffect(() => {
        if (budgetId && !Boolean(budgetForm)) {
            setValue('budget', budgetId);
        }
    }, [budgetId, budgetForm, setValue]);

    const { fields: payments, append, remove } = useFieldArray({ name: 'payments', control });

    const { mutateAsync: createMeasurementPeriod, isLoading: isSubmittingMeasurementPeriods } = useCreateMeasurementPeriods(projectProposalTypeId, providerId, period);

    const handleBack = () => navigate(-1);

    const handlePaymentStart = (event: any) => {
        const isChecked = event.currentTarget.checked;
        setValue('withPaymentStart', isChecked ? 1 : 0);

        if (isChecked) {
            append(INITIAL_PAYMENT);
        } else {
            remove(0);
        }
    };

    const resetValues = () => {
        setValue(`payments.0.value`, undefined);
        setValue(`payments.0.percentage`, 0);
    };

    const handlePaymentPercent = (event) => {
        const value = Number(event.currentTarget?.value) || 0;

        if (Boolean(value)) {
            setValue(`payments.0.percentage`, Math.round(value * 100) / 100);

            const otherValue = (budget * value) / 100;

            setValue(`payments.0.value`, {
                floatValue: otherValue,
                formattedValue: otherValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim(),
                value: otherValue.toString()
            });
        } else {
            resetValues();
        }
    };

    const handlePaymentValue = (values: NumberFormatValues) => {
        if (Boolean(values)) {
            setValue(`payments.0.value`, values);

            const percentage = ((values.floatValue || 0) * 100) / budget;

            setValue(`payments.0.percentage`, percentage);
        } else {
            resetValues();
        }
    };

    const handleCreateMeasurementPeriod = (period: number) => async () => {
        setValue('period', period);
        await methods.trigger('period');

        setValue('measurements', []);

        try {
            const { data } = await createMeasurementPeriod({ period, provider: providerId || 0 });

            const arr = data.map((item) => ({
                order: item.order,
                expectedDate: item.expectedDate,
                predictedPercentage: item.predictedPercentage,
                predictedValue: {
                    floatValue: item.predictedValue,
                    formattedValue: item.predictedValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim(),
                    value: item.predictedValue?.toString()
                }
            }));

            setValue('measurements', arr);
        } catch (error) {
            console.log('handleCreateMeasurementPeriod', error);
        }
    };

    const totalValue = measurements.reduce((acc, curr) => acc + (curr.predictedValue?.floatValue || 0), 0);

    const totalPercentage = measurements.reduce((acc, curr) => acc + Number(curr.predictedPercentage), 0);

    return (
        <Modal
            contentClassnames={`w-[1024px] ${providerId ? 'h-full' : ''}`}
            closeOnClickOutside={false}
            headerLeft={
                <div className="flex items-baseline justify-between mr-4">
                    <Text as="h3" variant="h4" className="text-heading">
                        Nova configuração financeira
                    </Text>
                    {Boolean(providers.length) && (
                        <Select
                            options={providers}
                            placeholder="Selecione um fornecedor"
                            selectStyles={{
                                control: (base) => ({ ...base, borderWidth: 0 }),
                                placeholder: (base) => ({ ...base, fontSize: 18 }),
                                singleValue: (base) => ({ ...base, fontSize: 18 })
                            }}
                            onChange={(option: any) => setProviderId(option.value)}
                        />
                    )}
                </div>
            }
            onClose={handleBack}>
            {Boolean(providers.length) ? (
                isBuilding ? (
                    <div className="p-4">
                        <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                    </div>
                ) : (
                    <FormProvider {...methods}>
                        <form className="h-[calc(100%-74px)]" onSubmit={handleSubmit(onSubmit)}>
                            {Boolean(budget) && (
                                <div className="bg-base-200 px-6 py-4 sticky left-0 -top-[1px] z-10 border-y border-y-base-300">
                                    <div className="flex items-center justify-between">
                                        <Text as="span" variant="body.medium.2xs" className="text-base-700 block">
                                            Orçamento disponível
                                        </Text>
                                        <Text as="span" variant="h6" className="text-secondary-500 !text-[24px]">
                                            {formatMoney(budget)}
                                        </Text>
                                    </div>
                                </div>
                            )}
                            {Boolean(providerId) ? (
                                <div className="flex h-[calc(100%-152px)] border-b border-b-base-300 overflow-hidden">
                                    <div className="border-r border-r-base-300 p-6 overflow-y-auto w-[320px]">
                                        <div className="mb-4">
                                            <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                                                Período (dias)
                                            </Text>
                                            <ErrorMessage className="block mt-1 mb-3" visible={Boolean((formState.errors as any)?.period?.message)}>
                                                {(formState.errors as any)?.period?.message}
                                            </ErrorMessage>
                                            <div className="flex items-center">
                                                <IconButton
                                                    classes={{ root: `h-9 w-9 mr-2 ${period === 15 ? 'bg-primary-500' : 'bg-base-200'}` }}
                                                    sx={{ '&:hover': { backgroundColor: period === 15 ? palette.primary[700] : palette.grey[300] } }}
                                                    onClick={handleCreateMeasurementPeriod(15)}>
                                                    <Text as="strong" variant="body.medium.2xs" className={`${period === 15 ? 'text-base-100' : 'text-base-700'}`}>
                                                        15
                                                    </Text>
                                                </IconButton>
                                                <IconButton
                                                    classes={{ root: `h-9 w-9 ${period === 30 ? 'bg-primary-500' : 'bg-base-200'}` }}
                                                    sx={{ '&:hover': { backgroundColor: period === 30 ? palette.primary[700] : palette.grey[300] } }}
                                                    onClick={handleCreateMeasurementPeriod(30)}>
                                                    <Text as="strong" variant="body.medium.2xs" className={`${period === 30 ? 'text-base-100' : 'text-base-700'}`}>
                                                        30
                                                    </Text>
                                                </IconButton>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                                                Retensão técnica
                                            </Text>
                                            <div className="h-12 border border-base-300 p-[13px] rounded-xl">
                                                <Controller
                                                    name="withTechnicalRetention"
                                                    control={control}
                                                    render={({ field }) => <Checkbox {...field} value={field.value as any} label="Possui retensão técnica?" checked={field.value} />}
                                                />
                                            </div>
                                        </div>
                                        <div className={`mb-4 ${Boolean(measurements?.length) ? 'opacity-100' : 'opacity-50'}`}>
                                            <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                                                Entrada
                                            </Text>
                                            <div className="h-12 border border-base-300 p-[13px] rounded-xl">
                                                <Controller
                                                    name="withPaymentStart"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            disabled={!Boolean(measurements?.length)}
                                                            value={field.value}
                                                            label="Possui entrada?"
                                                            checked={Boolean(field.value)}
                                                            onChange={handlePaymentStart}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="border border-base-300 p-4 rounded-[14px] w-[270px]">
                                            <Text as="span" className="text-base-500 block">
                                                Total percentual:
                                            </Text>
                                            <div className="mb-4">
                                                <Text as="span" variant="h6" className="text-heading">
                                                    {totalPercentage}%{' '}
                                                </Text>
                                                {totalPercentage < 100 && (
                                                    <Text as="span" variant="body.regular.xs" className="text-system-danger-500">{`-${(100 - totalPercentage).toFixed(2)}%`}</Text>
                                                )}
                                            </div>
                                            <Text as="span" className="text-base-500 block">
                                                Total valor:
                                            </Text>
                                            <div>
                                                <Text as="span" variant="h6" className="text-heading">
                                                    {formatMoney(totalValue)}{' '}
                                                </Text>
                                                {totalValue < budget && <Text as="span" variant="body.regular.xs" className="text-system-danger-500">{`-${formatMoney(budget - totalValue)}`}</Text>}
                                            </div>
                                        </div>
                                    </div>
                                    {Boolean(measurements?.length) ? (
                                        <div className="overflow-y-auto w-[calc(100%-320px)]">
                                            {Boolean(payments.length) && (
                                                <div className="bg-base-200 p-6 border-b border-b-base-300">
                                                    <Text as="span" variant="h6" className="block mb-2 text-heading">
                                                        Entrada
                                                    </Text>
                                                    <div className="flex gap-4">
                                                        {payments.map((field, index) => {
                                                            return (
                                                                <Fragment key={field.id}>
                                                                    <Controller
                                                                        name={`payments.${index}.date`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <DatePicker
                                                                                {...field}
                                                                                error={formState.errors?.payments?.[0]?.date?.message}
                                                                                label="Data"
                                                                                placeholderText="Selecione uma data"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <div className="flex items-start flex-1">
                                                                        <Controller
                                                                            name={`payments.${index}.percentage`}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <Input
                                                                                    {...field}
                                                                                    min={0}
                                                                                    max={100}
                                                                                    step="any"
                                                                                    label="Percentual de entrada"
                                                                                    placeholder="Ex: 10"
                                                                                    right={
                                                                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                                            %
                                                                                        </Text>
                                                                                    }
                                                                                    rightClasses="bg-base-200 px-4"
                                                                                    error={formState.errors?.payments?.[0]?.percentage?.message}
                                                                                    onChange={handlePaymentPercent}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Text as="span" variant="body.medium.sm" className="text-secondary-500 pt-[40px] mx-4">
                                                                            OU
                                                                        </Text>
                                                                        <Controller
                                                                            name={`payments.${index}.value`}
                                                                            control={control}
                                                                            render={() => {
                                                                                return (
                                                                                    <CurrencyInput
                                                                                        left={
                                                                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                                                                R$
                                                                                            </Text>
                                                                                        }
                                                                                        label="Valor alocado"
                                                                                        placeholder="Ex: 1000"
                                                                                        onValueChange={handlePaymentValue}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                    <ErrorMessage className="block mt-4" visible={Boolean((formState.errors as any)?.payments?.message)}>
                                                        {(formState.errors as any)?.payments?.message}
                                                    </ErrorMessage>
                                                </div>
                                            )}
                                            <div className="p-6">
                                                <Text as="span" variant="h6" className="block text-heading mb-4">
                                                    Medições
                                                </Text>
                                                <Measurements budget={budget} />
                                            </div>
                                        </div>
                                    ) : isSubmittingMeasurementPeriods ? (
                                        <Spinner fixed={false} parentClasses="w-[calc(100%-320px)]" />
                                    ) : (
                                        <div className="p-6 flex items-center justify-center flex-1">
                                            <Text as="p" variant="body.regular.sm" className="italic text-center text-base-500">
                                                Selecione um período para montar as medições.
                                            </Text>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <Text as="p" variant="body.regular.sm" className="italic text-base-500 p-6">
                                    Selecione um fornecedor para montar sua configuração financeira.
                                </Text>
                            )}
                            <div className="flex items-center p-6">
                                {Boolean(providerId) && (
                                    <Button disabled={isSubmitting || totalPercentage < 100} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                        Enviar
                                    </Button>
                                )}
                                <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                                    Cancelar
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                )
            ) : (
                <Empty title="Todos os cronogramas deste projeto já possuem configuração financeira criada" className="px-6 pb-6" />
            )}
        </Modal>
    );
};

export default CreateOrUpdateFinancial;
