import React, { useEffect } from 'react';
import DocumentsFields from './fields';
import Modal from 'components/core/modal';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Button from 'components/core/button';
import Text from 'components/core/text';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import dictionary from 'utils/dictionary';
import { object, string, number, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { classificationsQuery } from './utils/graphql';

type Props = {
    title: string;
    onSubmit(data: any): void;
    isSubmitting?: boolean;
    isBuilding?: boolean;
    onClose?(): void;
    defaultValues?: any;
    isTransactionOutcomeDocument?: boolean;
};

const schema = object({
    file: mixed()
        .required(dictionary.validation.required)
        .test('fileName', 'File not found', (value) => !!value?.name || !!value?.original_name), // TODO: is this the best way to validate a file?
    classification: number().typeError(dictionary.validation.required).required(dictionary.validation.required),
    name: string().typeError(dictionary.validation.required).required(dictionary.validation.required).trim(),
    description: string().default('').nullable().notRequired(),
    observation: string().default('').nullable().notRequired()
});

const DocumentsForm = ({ title, onSubmit, onClose, isSubmitting = false, isBuilding = false, defaultValues, isTransactionOutcomeDocument }: Props) => {
    const navigate = useNavigate();
    const { palette } = useTheme();

    const { data: classifications = [], isLoading: isLoadingClassifications } = useGetClassifications(classificationsQuery(isTransactionOutcomeDocument));

    const methods = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schema)
    });

    const { handleSubmit, reset } = methods;

    const isSpinnerVisible = isLoadingClassifications || isBuilding;

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleClose = () => {
        if (onClose) {
            onClose();
        } else {
            navigate(-1);
        }
    };

    return (
        <Modal contentClassnames="w-[1100px]" onClose={handleClose} hideCloseButton={isSpinnerVisible}>
            {isSpinnerVisible ? (
                <div className="p-10">
                    <Spinner color={palette.secondary[100]} fixed={false} size={30} />
                </div>
            ) : (
                <form className="py-5 px-3 sm:px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {title}
                    </Text>

                    <DocumentsFields methods={methods} classifications={classifications} />

                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default DocumentsForm;
