const hexToRgba = (hex: string, alpha?: number) => {
    const regx = new RegExp(`.{1,${hex.length > 4 ? 2 : 1}}`, 'g');
    const pairs = hex
        .replace('#', '')
        .match(regx)!
        .map((pair) => parseInt(pair.length === 1 ? pair + pair : pair, 16));

    const rgba = `rgba(${pairs.join(', ')}, ${alpha || 1})`;

    return rgba;
};

export default hexToRgba;
