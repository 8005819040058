import Header from './header';
import ColumnCard from './card';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { TaskDashboardItem, TaskDashboardStatus } from 'types/models/task-dashboard';
import useGetTasksByStatus from 'services/queries/task-dashboard/use-get-tasks-by-status';
import { TaskFilters } from '..';

const topColors = new Map<TaskDashboardStatus['slug'], string>([
    ['pending', '#FFD240'],
    ['stopped', '#FD7972'],
    ['processing', '#22CCE2'],
    ['done', '#49C96D'],
    ['partial', '#03A6A6']
]);

type TasksColumnProps = {
    filters?: TaskFilters;
} & TaskDashboardStatus;

const TasksColumn = ({ title, id, slug, filters }: TasksColumnProps) => {
    const { data: items, isLoading } = useGetTasksByStatus({ statusId: id, filters });

    const showEmptyMessage = items?.length === 0 && !isLoading;

    return (
        <Droppable droppableId={id.toString()}>
            {(provided, snapshot) => {
                return (
                    <div className="w-[350px] max-w-[80%] sm:max-w-none flex-none mb-3" {...provided.droppableProps} ref={provided.innerRef}>
                        <div className="bg-base-100 rounded-[20px] ml-2 sm:ml-0 p-4 pb-3 flex flex-col relative max-h-full shadow border-t lg:border-t-0">
                            <span className="absolute left-1/2 -ml-[32px] top-0 w-[64px] h-[5px] rounded-b-md bg-base-300" style={{ backgroundColor: topColors.get(slug) }}></span>
                            <Header total={items?.length || 0} title={title} />
                            <div className="flex flex-col overflow-y-auto h-auto column-custom-scroll min-h-[144px] pr-2" ref={provided.innerRef}>
                                {showEmptyMessage ? (
                                    snapshot?.isDraggingOver ? null : (
                                        <p className="text-center text-base-400 text-sm flex items-center justify-center h-[144px]">Nenhuma tarefa foi adicionada ainda</p>
                                    )
                                ) : (
                                    items?.map((task: TaskDashboardItem, i) => (
                                        <Draggable key={`status_${id}_${task.id}`} draggableId={task.id?.toString()} index={i}>
                                            {(providedDrag, snapshotDrag) => (
                                                <div ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps}>
                                                    <ColumnCard key={i} isDragging={snapshotDrag.isDragging} task={task} statusId={id} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                )}
                                {provided.placeholder}
                            </div>
                            {/* <Footer /> */}
                        </div>
                    </div>
                );
            }}
        </Droppable>
    );
};

export default TasksColumn;
