import { Fragment, useMemo } from 'react';

import { Outlet } from 'react-router-dom';
import { UseQueryResult } from 'react-query';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';

import Icon from 'components/core/icon';
import PageHeader from 'components/page/header';
import Datatable, { ModifiedDataTableOptions } from 'components/core/table/datatable';

import dictionary from 'utils/dictionary';
import { Profile } from 'types/models/profile';
import { GraphqlPagination, GraphqlPaginationVariables } from 'types/graphql';
import { Page } from 'types/models/page';
import { MenuItem } from 'components/menu';
import get from 'lodash/get';

export type CustomProfile = Pick<Profile, 'id' | 'name' | 'slug' | 'status'>;

type ListProps = {
    columns: MUIDataTableColumn[];
    params: GraphqlPaginationVariables<any>;
    searchableField: string;
    title: string;
    onAction: () => void;
    onChangePage: (currentPage: number) => void;
    onChangeRowsPerPage: (numberOfRows: number) => void;
    onColumnSortChange: (changedColumn: string, direction: 'asc' | 'desc') => void;
    onDelete: (id?: number, slug?: string) => void;
    onSearchChange: (searchText: string | null) => void;
    hideHeader?: boolean;
    hideCreateButton?: boolean;
    withBorder?: boolean;
    page?: Page;
    advancedSearchComponent?: React.ReactNode;
    customActions?: MenuItem[];
    canShowDeleteButton?: (item: any) => boolean;
    columnsToSearch?: string[];
    tableOptions?: MUIDataTableOptions;
} & UseQueryResult<GraphqlPagination<CustomProfile>, unknown>;

const List = ({
    columns,
    columnsToSearch,
    customActions,
    canShowDeleteButton,
    page,
    data,
    isLoading,
    params,
    searchableField,
    title,
    onAction,
    onChangePage,
    onChangeRowsPerPage,
    onColumnSortChange,
    onDelete,
    onSearchChange,
    hideHeader = false,
    hideCreateButton = false,
    withBorder = false,
    advancedSearchComponent,
    tableOptions
}: ListProps) => {
    const { palette } = useTheme();

    data?.items.forEach((item) => {
        if (!Object.keys(item).includes('id')) {
            const key = Object.keys(item)[0];

            item.id = item[key]?.id;
        }
    });

    const options = useMemo<ModifiedDataTableOptions>(() => {
        const searchableValue = get(params?.where, `${searchableField}._ilike`);
        const searchableValueAllColumns = get(params?.where, `${columnsToSearch?.[0]}._ilike`);

        return {
            ...tableOptions,
            ...(Boolean(searchableValue) && { searchText: searchableValue || '' }),
            ...(Boolean(searchableValueAllColumns) && { searchText: searchableValueAllColumns || '' }),
            elevation: 21,
            count: data?.pagination?.aggregate.count || 0,
            page: params.page,
            rowsPerPage: params.limit,
            enableNestedDataAccess: '.',
            serverSide: true,
            filter: false,
            searchAlwaysOpen: true,
            searchPlaceholder: `Pesquisar${dictionary.content.graphql[searchableField] ? ` por ${dictionary.content.graphql[searchableField].toLowerCase()}` : '...'}`,
            onChangePage,
            onChangeRowsPerPage,
            onColumnSortChange,
            onSearchChange
        };
    }, [onChangePage, onChangeRowsPerPage, onColumnSortChange, onSearchChange, data, params, searchableField, columnsToSearch, tableOptions]);

    const actions = useMemo(() => page?.rules.map((item) => item.key) || [], [page?.rules]);

    return (
        <>
            <div>
                {!hideHeader && (
                    <PageHeader
                        className="mb-8"
                        title={title}
                        right={
                            hideCreateButton ? (
                                <Fragment />
                            ) : (
                                <IconButton color="secondary" size="large" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }} onClick={onAction}>
                                    <Icon name="ico-plus" width={16} height={16} color={palette.grey[100]} />
                                </IconButton>
                            )
                        }
                    />
                )}
                <Datatable
                    advancedSearchComponent={advancedSearchComponent}
                    customActions={customActions}
                    canShowDeleteButton={canShowDeleteButton}
                    actions={actions}
                    withBorder={withBorder}
                    data={data?.items || []}
                    options={options}
                    columns={columns}
                    loading={isLoading}
                    withItemsPerPage={true}
                    onDelete={onDelete}
                />
            </div>
            <Outlet context={params} />
        </>
    );
};

export default List;
