import DocumentsForm from 'components/documents';
import { omit } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useCreateOrUpdateTransactionOutcomeDocument from 'services/queries/projects/use-create-or-update-transaction-outcome-document';
import useGetProjectTypeDocuments from 'services/queries/projects/use-get-project-type-documents';

const TransactionOutcomeDocumentForm = () => {
    const { projectProposalTypeId, outcomeId, transactionOutcomeDocumentId } = useParams();

    const title = !!transactionOutcomeDocumentId ? 'Editar documento' : 'Novo documento';

    const { isLoading: isSubmitting, mutate: mutationOutcomeDocument } = useCreateOrUpdateTransactionOutcomeDocument(projectProposalTypeId, transactionOutcomeDocumentId, +outcomeId!);

    const { isLoading: isBuilding, data: documents } = useGetProjectTypeDocuments('', +projectProposalTypeId!, true, +outcomeId!);

    const finderDocumentById = useMemo(() => {
        return documents?.find((item) => item.id === +transactionOutcomeDocumentId!);
    }, [documents, transactionOutcomeDocumentId]);

    const newDefaultValues = useMemo(
        () => ({
            ...omit(finderDocumentById, ['type', 'id']),
            ...(finderDocumentById?.file && {
                file: {
                    filename: finderDocumentById?.file?.filename,
                    id: finderDocumentById?.file?.id,
                    original_name: (finderDocumentById?.file as any)?.originalName,
                    path: finderDocumentById?.file?.path
                }
            }),

            classification: finderDocumentById?.classification?.id
        }),
        [finderDocumentById]
    );

    return (
        <DocumentsForm
            defaultValues={newDefaultValues || {}}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting}
            title={title}
            isTransactionOutcomeDocument={true}
            onSubmit={(data) => mutationOutcomeDocument(data)}
        />
    );
};

export default TransactionOutcomeDocumentForm;
