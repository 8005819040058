export const WEEK_WIDTH = 64;
export const MONTH_WIDTH = 256;
export const MONTH_HEIGHT = 60;
export const BAR_HEIGHT = 50;
export const MONTH_DAY_WIDTH = MONTH_WIDTH / 30;
export const WEEK_DAY_WIDTH = MONTH_WIDTH / 7;
export const BAR_MARGIN_TOP = 7;

export const getTotalDaysInYear = (year: number) => {
    return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
};

export const getTotalWeeksInYear = ({ year, month }: { year: number; month: number }): number => {
    (Date.prototype as any).getWeekOfMonth = function () {
        const firstDay = new Date(this.setDate(1)).getDay();
        const totalDays = new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate();
        return Math.ceil((firstDay + totalDays) / 7);
    };

    let monthNumber: any = month;

    if (monthNumber < 10) {
        monthNumber = `0${monthNumber}`;
    }

    return ~~(new Date(`${year}-${monthNumber}-01`) as any).getWeekOfMonth();
};

export const getMonthWidth = ({ year, month }: { year: number; month: number }) => {
    return getTotalWeeksInYear({ year, month }) * WEEK_WIDTH;
};
