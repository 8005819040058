import classNames from 'classnames';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';

type FormButtonsProps = {
    isLoading: boolean;
    onCancel?: () => void;
    className?: string;
    submitClassName?: string;
    cancelClassName?: string;
};

const FormButtons = ({ isLoading, cancelClassName, className, submitClassName, onCancel }: FormButtonsProps) => {
    const navigate = useNavigate();

    const containerClasses = classNames('flex items-center', className);
    const submitClasses = classNames('min-w-[100px] mr-4', submitClassName);
    const cancelClasses = classNames('min-w-[100px]', cancelClassName);

    const handleCancel = () => {
        if (!onCancel) {
            return navigate(-1);
        }

        onCancel();
    };

    return (
        <div className={containerClasses}>
            <Button disabled={isLoading} loading={isLoading} type="submit" variant="contained" color="secondary" className={submitClasses}>
                Enviar
            </Button>
            <Button color="inherit" className={cancelClasses} variant="outlined" onClick={handleCancel}>
                Cancelar
            </Button>
        </div>
    );
};

export default FormButtons;
