// Dependencies
import { useDispatch } from 'react-redux';

// Helpers
import { ConfigState } from './types';
import { useAppSelector } from 'store';
import { Creators as ConfigActions } from 'store/config';

const useConfig = () => {
    const dispatch = useDispatch();
    const config = useAppSelector(({ config }) => config);

    return {
        config,
        setConfig: (key: keyof ConfigState, payload: ConfigState[keyof ConfigState]) => dispatch(ConfigActions.setConfig(key, payload))
    };
};

export default useConfig;
