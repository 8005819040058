import { memo, useMemo } from 'react';
import { BAR_HEIGHT, BAR_MARGIN_TOP, MONTH_DAY_WIDTH, MONTH_HEIGHT, MONTH_WIDTH, WEEK_DAY_WIDTH } from '../../utils';
import HoverElements from './hover-elements';
import { Task } from 'types/models/task';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import dayjs from 'dayjs';
import { useCalendarContext } from '../../context';

type Props = {
    task: Task;
    index: number;
    timelineId?: number;
};

const Bar = ({ task, index, timelineId }: Props) => {
    const { timelineVariant, headerTexts } = useCalendarContext();

    const { duration = 0, startDate, color, isChildren = false } = task || {};

    const width = useMemo(() => {
        const width = timelineVariant === 'WEEKS' ? WEEK_DAY_WIDTH : MONTH_DAY_WIDTH;

        return width * ~~duration! + width;
    }, [duration, timelineVariant]);

    const top = useMemo(() => {
        if (isChildren) {
            return index * MONTH_HEIGHT + BAR_MARGIN_TOP * 2;
        }

        return index * MONTH_HEIGHT + BAR_MARGIN_TOP;
    }, [index, isChildren]);

    const taskMonth = new Date(startDate!).getMonth();
    const startDay = new Date(startDate!).getDate();

    const left = useMemo(() => {
        const currentYear = new Date().getFullYear();

        const years = [currentYear - 1, currentYear, currentYear + 1];

        const yearIndex = years.findIndex((year) => year === new Date(startDate!).getFullYear());

        if (timelineVariant === 'MONTHS') {
            return taskMonth * MONTH_WIDTH + startDay * MONTH_DAY_WIDTH + 12 * yearIndex * MONTH_WIDTH;
        }

        const weeksFromStart = dayjs(startDate).diff(headerTexts?.[0]?.legendDays?.[0]!, 'week');

        const weekDay = new Date(startDate!).getDay() - 1;

        const dayWidth = WEEK_DAY_WIDTH;

        return weeksFromStart * MONTH_WIDTH + weekDay * dayWidth;
    }, [taskMonth, startDay, timelineVariant, startDate, headerTexts]);

    const navigate = useNavigate();

    const handleOpenTask = () => {
        navigate(`tarefa/${task.id}?timelineId=${timelineId}`);
    };

    const title = `${task.code} - ${task.title}`;

    const progress = task?.progressPercentage || 0;
    // const progress = 50;

    if (!task) {
        return null;
    }

    const showProgress = timelineVariant === 'MONTHS' ? duration >= 20 : duration >= 4;

    const height = isChildren ? BAR_HEIGHT - 10 : BAR_HEIGHT;

    return (
        <Tooltip placement="top" title={title} disableFocusListener={true}>
            <div onClick={handleOpenTask} className="group absolute bg-white flex items-center rounded-md z-10 border-none hover:cursor-pointer" style={{ width, maxWidth: width, height, top, left }}>
                <HoverElements />
                <div className="w-[6px] h-full rounded-l-md z-10 absolute left-0" style={{ backgroundColor: color?.[400] }}></div>

                <div className="rounded-md w-full h-full  absolute top-0 left-0 opacity-50" style={{ backgroundColor: color?.[100] }}></div>

                <div className=" flex items-center rounded-md h-full absolute progress z-[1] opacity-60" style={{ backgroundColor: color?.[200], width: `${progress}%` }}></div>

                <div className="flex absolute top-0 left-0 items-center h-full justify-between w-full pr-2 pl-3 z-[2]">
                    <span className="text-heading text-xs font-medium text-ellipsis overflow-hidden whitespace-nowrap" style={{ maxWidth: width <= 8 ? 0 : width - 8 }}>
                        {task.title}
                    </span>
                    {showProgress && <span className="text-heading text-xs font-normal">{progress}%</span>}
                </div>
            </div>
        </Tooltip>
    );
};

export default memo(Bar);
