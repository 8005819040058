import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { formatMoney } from 'utils/money';
import ProposalResources from './resources';
import { ProjectProposalType, ProjectStatus, ProjectTypeSlug } from 'types/models/project';
import classNames from 'classnames';

type ProposalDetailsProps = {
    colSpan: number;
    days: string;
    item: Partial<ProjectProposalType>;
    projectProposalId?: number;
    projectStatus?: Partial<ProjectStatus>;
    onAddResource: () => void;
};

const ProposalDetails = ({ colSpan, days, item, projectProposalId, projectStatus, onAddResource }: ProposalDetailsProps) => {
    return (
        <TableRow>
            <TableCell classes={{ root: 'p-0' }} colSpan={colSpan}>
                <div className={classNames('grid bg-base-200', Boolean(item.financial_setup?.length) ? 'grid-cols-2' : 'grid-cols-1')}>
                    <div className="border-r border-r-base-300 p-4">
                        {Boolean(item.budget_expense) && (
                            <div className="mb-1 flex items-center justify-between">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    Previsão de despesas:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {formatMoney(item.budget_expense)}
                                </Text>
                            </div>
                        )}
                        {Boolean(item.budget_provider) && (
                            <div className="mb-1 flex items-center justify-between">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    Previsão de custos de terceiros:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {formatMoney(item.budget_provider)}
                                </Text>
                            </div>
                        )}
                        {Boolean(item.percentage_cost_administrative) && (
                            <div className="mb-1 flex items-center justify-between">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    Percentual de custo administrativo:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {item.percentage_cost_administrative}%
                                </Text>
                            </div>
                        )}
                        {Boolean(item.percentage_cost_tax) && (
                            <div className="mb-1 flex items-center justify-between">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    Percentual de imposto:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {item.percentage_cost_tax}%
                                </Text>
                            </div>
                        )}
                        {Boolean(item.percentage_profit) && (
                            <div className="flex items-center justify-between mb-4">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    Percentual de lucro:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {item.percentage_profit}%
                                </Text>
                            </div>
                        )}
                        {Boolean(item.number_of_management_hours) && (
                            <div className="mb-1 flex items-center justify-between">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    HH Gestão:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {item.number_of_management_hours}
                                </Text>
                            </div>
                        )}
                        {Boolean(item.value_of_management_hours) && (
                            <div className="flex items-center justify-between mb-4">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    Valor HH Gestão:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {formatMoney(item.value_of_management_hours || 0)}
                                </Text>
                            </div>
                        )}
                        {Boolean(item.number_of_execution_hours) && (
                            <div className="mb-1 flex items-center justify-between">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    HH Execução:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {item.number_of_execution_hours}
                                </Text>
                            </div>
                        )}
                        {Boolean(item.value_of_execution_hours) && (
                            <div className="mb-1 flex items-center justify-between">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    Valor HH Execução:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {formatMoney(item.value_of_execution_hours || 0)}
                                </Text>
                            </div>
                        )}
                        {Boolean(days) && (
                            <div className="mb-1 flex items-center justify-between">
                                <Text
                                    variant="body.regular.xs"
                                    className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                    Período de trabalho:{' '}
                                </Text>
                                <Text variant="body.regular.xs" className="text-base-500">
                                    {days}
                                </Text>
                            </div>
                        )}
                        {item.project_type?.slug === ProjectTypeSlug.Management && (
                            <div className="mt-4">
                                <Text as="p" variant="h6" className="text-heading">
                                    Recursos
                                </Text>
                                <ProposalResources
                                    projectProposalType={{ resources: item.resources, id: item.id, projectProposalId }}
                                    projectStatus={projectStatus}
                                    emptyClassName="p-0"
                                    onAddResource={onAddResource}
                                />
                            </div>
                        )}
                    </div>
                    <div className="p-4">
                        {item.financial_setup?.map((financial_setup, index) => {
                            return (
                                <div className="border border-base-300 rounded-[14px] p-4" key={index}>
                                    <Text variant="body.medium.sm" className="text-heading mb-2 block">
                                        {financial_setup?.service?.label}
                                    </Text>
                                    <div className="mb-1 flex items-center justify-between">
                                        <Text
                                            variant="body.regular.xs"
                                            className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                            HH Execução:{' '}
                                        </Text>
                                        <Text variant="body.regular.xs" className="text-base-500">
                                            {financial_setup.number_of_execution_hours || 0}
                                        </Text>
                                    </div>
                                    <div className="mb-1 flex items-center justify-between">
                                        <Text
                                            variant="body.regular.xs"
                                            className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                            Valor Execução:{' '}
                                        </Text>
                                        <Text variant="body.regular.xs" className="text-base-500">
                                            {formatMoney(financial_setup.value_of_execution_hours || 0)}
                                        </Text>
                                    </div>
                                    <div className="mb-1 flex items-center justify-between">
                                        <Text
                                            variant="body.regular.xs"
                                            className="text-secondary-500 flex-1 flex items-center after:border-b-base-400 after:content-[''] after:flex-1 after:mx-4 after:h-[1px] after:border-b after:border-dashed after:block">
                                            Custo de Terceiros:{' '}
                                        </Text>
                                        <Text variant="body.regular.xs" className="text-base-500">
                                            {formatMoney(financial_setup.budget_provider || 0)}
                                        </Text>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default ProposalDetails;
