import { memo, useMemo } from 'react';

// Dependencies
import { UseFormReturn } from 'react-hook-form';

// Components
import Form, { Contents } from './contract';

// Helpers
import { frequencies, jobSeniorities, shifts } from 'utils/statics';
import useGetJobPositionsOptions from 'services/queries/job-positions/use-get-job-positions-options';

type Fields = {
    frequency: string;
    seniority: string;
    shift: string;
    jobPosition: string;
};

type ContractFormProps = {
    fieldNames: Fields;
} & UseFormReturn<any, any>;

const ContractForm = ({ fieldNames, ...props }: ContractFormProps) => {
    const { data: jobPositions } = useGetJobPositionsOptions(true);

    const contents = useMemo<Contents>(
        () => ({
            frequency: {
                field: fieldNames.frequency,
                items: frequencies
            },
            seniority: {
                field: fieldNames.seniority,
                items: jobSeniorities
            },
            shift: {
                field: fieldNames.shift,
                items: shifts
            },
            jobPosition: {
                field: fieldNames.jobPosition,
                items: jobPositions || []
            }
        }),
        [fieldNames, jobPositions]
    );

    return <Form {...props} contents={contents} />;
};

export default memo(ContractForm);
