import React, { useEffect, useState } from 'react';

// Dependencies
import { number, object, string } from 'yup';
import Table from '@mui/material/Table/Table';
import useTheme from '@mui/material/styles/useTheme';
import { yupResolver } from '@hookform/resolvers/yup';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';

// Components
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Dropzone from 'components/dropzone';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';

// Helpers
import { Option } from 'types/general';
import dictionary from 'utils/dictionary';
import { FileApiModel } from 'types/models/file';
import { ProjectTypeDocumentPayload } from 'types/models/project';
import Editor from 'components/core/form/editor';
import FileRow from 'components/files-row';

type DocumentsCreateOrUpdateProps = {
    classifications: Option[];
    defaultValues: Partial<ProjectTypeDocumentPayload>;
    subClassifications: Option[];
    isBuilding: boolean;
    isSubmitting: boolean;
    onGetSubClassifications: (parentId: number) => void;
    onSubmit: (data: ProjectTypeDocumentPayload) => void;
    file?: Partial<File & FileApiModel>;
};

const DocumentsCreateOrUpdate = ({ classifications, defaultValues, file, isBuilding, isSubmitting, subClassifications, onGetSubClassifications, onSubmit }: DocumentsCreateOrUpdateProps) => {
    const navigate = useNavigate();

    const { palette } = useTheme();
    const { projectTypeDocumentId } = useParams();

    const [files, setFiles] = useState<Array<Partial<File & FileApiModel>>>([]);

    useEffect(() => {
        if (file) {
            setFiles([file]);
        }
    }, [file]);

    const schema = object({
        name: string().required(dictionary.validation.required),
        file: number().required(dictionary.validation.required),
        classification_holder: number().required(dictionary.validation.required),
        classification: number(),
        description: string(),
        observation: string()
    });

    const { control, formState, handleSubmit, setValue } = useForm<ProjectTypeDocumentPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(schema),
        defaultValues
    });

    const handleBack = () => navigate(-1);

    const handleUploadFiles = (filesList: File[]) => setFiles([...files, ...filesList]);

    const handleSelectClassification = (field: ControllerRenderProps<ProjectTypeDocumentPayload, 'classification_holder'>) => (option: any) => {
        field.onChange(option.value);

        onGetSubClassifications(option.value);
    };

    const handleRemoveFromList = () => {
        setFiles([]);
        setValue('file', undefined as any);
    };

    const handlePersistFile = (file: FileApiModel) => {
        setValue('file', file.id);
        setFiles((prev) => {
            return prev.map((item) => ({
                ...item,
                ...(item.path === file.originalName && { ...file, id: file.id })
            }));
        });
    };

    return (
        <Modal
            contentClassnames="w-[620px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {projectTypeDocumentId ? 'Atualizar' : 'Novo'} documento
                </Text>
            }
            onClose={handleBack}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="px-3 sm:px-6 pb-6" onSubmit={handleSubmit(onSubmit)}>
                    <Controller name="name" control={control} render={({ field }) => <Input {...field} error={formState.errors.name?.message} label="Título" parentClassName="mb-4" />} />
                    <div className={Boolean(subClassifications.length) ? 'grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4' : 'mb-4'}>
                        <Controller
                            name="classification_holder"
                            control={control}
                            render={({ field }) => {
                                const value = classifications.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={classifications}
                                        label="Categoria"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.classification_holder?.message}
                                        onChange={handleSelectClassification(field)}
                                    />
                                );
                            }}
                        />
                        {Boolean(subClassifications.length) && (
                            <Controller
                                name="classification"
                                control={control}
                                render={({ field }) => {
                                    const value = subClassifications.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={subClassifications}
                                            label="Sub-categoria"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.classification_holder?.message}
                                            onChange={(option: any) => field.onChange(option.value)}
                                        />
                                    );
                                }}
                            />
                        )}
                    </div>
                    <Controller
                        control={control}
                        name="description"
                        render={({ field }) => <Editor {...field} error={formState.errors?.description?.message} label="Descrição" parentClasses="mb-4" />}
                    />
                    <Controller
                        control={control}
                        name="observation"
                        render={({ field }) => <Editor {...field} error={formState.errors?.observation?.message} label="Observações" parentClasses="mb-4" />}
                    />
                    <div className="mb-9">
                        <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                            Anexo
                        </Text>
                        <Dropzone disabled={Boolean(files.length)} error={formState.errors.file?.message} onUploadFiles={handleUploadFiles} />
                        {Boolean(files.length) && (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Text as="span" className="text-secondary-500">
                                                    Tipo
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-secondary-500">
                                                    Arquivo
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-secondary-500">
                                                    Status
                                                </Text>
                                            </TableCell>
                                            <TableCell width={90}>
                                                <Text as="span" className="text-secondary-500">
                                                    Ações
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <FileRow file={files[0]} onPersistFile={handlePersistFile} onRemoveFromList={handleRemoveFromList} />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default DocumentsCreateOrUpdate;
