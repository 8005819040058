import { useEffect, useState } from 'react';

export const useLocalStorageState = <T = any>({ initialValue, key }: Props<T>) => {
    const [state, setState] = useState<T>(getLocalStorageValue(key) || initialValue);

    useEffect(() => {
        if (getLocalStorageValue(key)) {
            setState(getLocalStorageValue(key));
        }
    }, [key]);

    const handleSetState = (value: T) => {
        setState(value);

        localStorage.setItem(key, JSON.stringify(value));
    };

    return [state, handleSetState] as [T, (value: T) => void];
};

type Props<T> = {
    initialValue: T;
    key: string;
};

const getLocalStorageValue = (key: string) => JSON.parse(localStorage.getItem(key) as any);
