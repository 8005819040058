// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import useAuth from 'store/auth/use-auth';
import { Pagination } from 'types/pagination';
import branches, { getAllBranchesOptionsKey } from './graphql';

const useGetBranchesOptions = () => {
    const { auth } = useAuth();
    const account_id = auth.credentials.account.id;

    const getAllAccountsOptions = () => apiGraphQl<Pagination<Option>>(branches.getAll, { account_id }).then((data) => data.items);

    return useQuery(getAllBranchesOptionsKey(account_id), getAllAccountsOptions);
};

export default useGetBranchesOptions;
