import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import useGetProjectTypeTerms from 'services/queries/projects/use-get-project-type-term';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import TermsOriginProject from './origin-project';
import TermsOriginOperational from './origin-operational';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';

type DocumentsProps = {
    projectProposalTypeIdProp?: number;
    withActions?: boolean;
    withTitle?: boolean;
    className?: string;
    origin: 'project' | 'operational';
} & WithPagePermissionsProps;

const Terms = ({ origin, projectProposalTypeIdProp, className, page }: DocumentsProps) => {
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const projectProposalTypeId = useMemo(() => projectProposalTypeIdProp || ctx?.projectProposalType?.id, [projectProposalTypeIdProp, ctx?.projectProposalType]);

    const { data, isLoading } = useGetProjectTypeTerms(projectProposalTypeId, origin);

    if (isLoading) {
        return <Spinner fixed={false} parentClasses="h-[calc(100%-49px)] !p-4" />;
    }

    if (origin === 'project') {
        return <TermsOriginProject data={data || []} className={className} />;
    }

    return <TermsOriginOperational page={page} />;
};

export default Terms;
