import Editor from 'components/core/form/editor';
import Input from 'components/core/form/input';
import Text from 'components/core/text';

import { Controller, useFormContext } from 'react-hook-form';
import { CreateAssetPayload } from 'types/models/assets';
import CurrencyInput from 'components/core/form/currency';
import CommonFields from '../commonFields';

const EquipmentFields = () => {
    const { control, formState } = useFormContext<CreateAssetPayload>();

    return (
        <>
            <CommonFields />
            <Controller
                name="value"
                control={control}
                render={({ field }) => (
                    <div>
                        <CurrencyInput
                            name={field.name}
                            ref={field.ref}
                            value={field.value}
                            left={
                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                    R$
                                </Text>
                            }
                            fixedDecimalScale={true}
                            decimalScale={2}
                            allowLeadingZeros={true}
                            onValueChange={({ floatValue }) => field.onChange(floatValue)}
                            error={formState.errors?.value?.message}
                            label="Valor"
                            placeholder="Ex: 99,99"
                        />
                    </div>
                )}
            />
            <Controller name="code" control={control} render={({ field }) => <Input {...field} error={formState.errors?.code?.message} label="Código" placeholder="Código" />} />

            <Controller
                name="observations"
                control={control}
                render={({ field }) => <Editor parentClasses="col-span-3" {...field} label="Observação" error={formState.errors?.observations?.message} placeholder="Observação" />}
            />
        </>
    );
};

export default EquipmentFields;
