import React, { useState } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MeasurementPaymentPayload, ProviderFinancialPaymentType } from 'types/models/provider';
import { array, date, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import Input from 'components/core/form/input';
import DatePicker from 'components/core/datepicker';
import { currencyToFloat, maskToCurrency } from 'utils/money';
import Button from 'components/core/button';
import Editor from 'components/core/form/editor';
import Files from '../../progress/files';
import { FileApiModel } from 'types/models/file';
import useCreateMeasurementPayment from 'services/queries/measurement/use-create-measurement-payment';
import { formatDate } from 'utils/date';

const schema = object({
    type: number().required(dictionary.validation.required).default(ProviderFinancialPaymentType.DirectBilling),
    value: string().required(dictionary.validation.required),
    providerName: string().required(dictionary.validation.required),
    providerDocument: string().required(dictionary.validation.required),
    invoiceNumber: string().required(dictionary.validation.required),
    dueDate: date().required(dictionary.validation.required),
    issueDate: date().required(dictionary.validation.required),
    receiptDate: date().required(dictionary.validation.required),
    sentDate: date().required(dictionary.validation.required),
    forecastDate: date().required(dictionary.validation.required),
    observations: string(),
    files: array(number())
});

const CreateOrUpdateMeasurementPaymentPage = () => {
    const navigate = useNavigate();
    const { providerFinancialSetupId } = useParams();

    const filesHook = useState<Array<Partial<File & FileApiModel>>>([]);

    const { mutateAsync: createMeasurementPayment, isLoading: isSubmitting } = useCreateMeasurementPayment(Number(providerFinancialSetupId));

    const { control, formState, handleSubmit } = useForm<MeasurementPaymentPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(schema),
        defaultValues: {
            providerDocument: '',
            providerName: '',
            dueDate: '',
            files: [],
            forecastDate: '',
            invoiceNumber: '',
            issueDate: '',
            observations: '',
            receiptDate: '',
            sentDate: '',
            type: ProviderFinancialPaymentType.DirectBilling
        }
    });

    const handleBack = () => navigate(-1);

    const submit = async (data: MeasurementPaymentPayload) => {
        try {
            const [files] = filesHook;
            const filesMapped: Array<{ file: number }> = files.map((item) => ({ file: item.id || 0 })).filter((item) => Boolean(item.file));

            const payload = {
                ...data,
                dueDate: formatDate(data.dueDate, 'DD/MM/YYYY'),
                forecastDate: formatDate(data.forecastDate, 'DD/MM/YYYY'),
                issueDate: formatDate(data.issueDate, 'DD/MM/YYYY'),
                receiptDate: formatDate(data.receiptDate, 'DD/MM/YYYY'),
                sentDate: formatDate(data.sentDate, 'DD/MM/YYYY'),
                value: currencyToFloat(data.value),
                files: filesMapped
            };

            await createMeasurementPayment(payload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[800px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Novo faturamento direto
                </Text>
            }
            onClose={handleBack}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                <div className="flex flex-col sm:flex-row gap-4 mb-4">
                    <div className="border border-base-300 p-4 rounded-[14px] flex-1 sm:mr-4">
                        <Text as="label" variant="h6" className="text-heading mb-2 block">
                            Fornecedor
                        </Text>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <Controller name="providerName" control={control} render={({ field }) => <Input {...field} label="Nome" error={formState.errors?.providerName?.message} />} />
                            <Controller
                                name="providerDocument"
                                control={control}
                                render={({ field }) => <Input {...field} autoComplete="nope" mask="99.999.999/9999-99" label="CNPJ" error={formState.errors.providerDocument?.message} />}
                            />
                        </div>
                    </div>
                    <div className="border border-base-300 p-4 rounded-[14px]">
                        <Text as="label" variant="h6" className="text-heading mb-2 block">
                            Escopo
                        </Text>
                        <Controller
                            name="invoiceNumber"
                            control={control}
                            render={({ field }) => <Input {...field} label="Nota discal" placeholder="Ex: 1" error={formState.errors?.providerName?.message} />}
                        />
                    </div>
                </div>
                <div className="border border-base-300 p-4 rounded-[14px] mb-4">
                    <Text as="label" variant="h6" className="text-heading mb-2 block">
                        Valor contratual
                    </Text>
                    <div className="grid grid-col-1 sm:grid-cols-3 gap-4">
                        <Controller
                            name="issueDate"
                            control={control}
                            render={({ field }) => <DatePicker {...field} error={formState.errors?.issueDate?.message} label="Data de emissão" placeholderText="Selecione uma data" />}
                        />
                        <Controller
                            name="value"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    label="Valor nota fiscal"
                                    alwaysShowMask={false}
                                    beforeMaskedStateChange={maskToCurrency}
                                    mask="999999999999999999999"
                                    placeholder="Ex: 1000"
                                    maskPlaceholder={null}
                                    error={formState.errors?.value?.message}
                                    left={
                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                            R$
                                        </Text>
                                    }
                                    leftClasses="bg-base-200 px-4"
                                />
                            )}
                        />
                        <Controller
                            name="dueDate"
                            control={control}
                            render={({ field }) => <DatePicker {...field} error={formState.errors?.dueDate?.message} label="Data de vencimento" placeholderText="Selecione uma data" />}
                        />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row mb-4 gap-4">
                    <div className="border border-base-300 p-4 rounded-[14px] flex-1 sm:mr-4">
                        <Text as="label" variant="h6" className="text-heading mb-2 block">
                            Total medido
                        </Text>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <Controller
                                name="receiptDate"
                                control={control}
                                render={({ field }) => <DatePicker {...field} error={formState.errors?.receiptDate?.message} label="Recebido em obra" placeholderText="Selecione uma data" />}
                            />
                            <Controller
                                name="sentDate"
                                control={control}
                                render={({ field }) => <DatePicker {...field} error={formState.errors?.sentDate?.message} label="Data de envio" placeholderText="Selecione uma data" />}
                            />
                        </div>
                    </div>
                    <div className="border border-base-300 p-4 rounded-[14px]">
                        <Text as="label" variant="h6" className="text-heading mb-2 block">
                            Previsão
                        </Text>
                        <Controller
                            name="forecastDate"
                            control={control}
                            render={({ field }) => (
                                <DatePicker {...field} error={formState.errors?.forecastDate?.message} label="Previsão de faturamento direto" placeholderText="Selecione uma data" />
                            )}
                        />
                    </div>
                </div>
                <Files hook={filesHook} label="Arquivos" className="!mb-4" />
                <Controller name="observations" control={control} render={({ field }) => <Editor {...field} label="Observações" parentClasses="mb-4" />} />
                <div className="flex items-center">
                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                        Enviar
                    </Button>
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                        Cancelar
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default CreateOrUpdateMeasurementPaymentPage;
