import { Navigate, RouteObject } from 'react-router-dom';
import CreateOrUpdatePersonPage from 'pages/private/people/create-or-update';
import People from 'pages/private/people';
import PersonDetailsContracts from 'pages/private/people/details/tabs/contracts';
import CreateOrUpdatePersonDetailsContractsPage from 'pages/private/people/details/tabs/contracts/create-or-update';
import PersonContractStatus from 'components/core/modal/person-contract-status';
import PersonDetailsTasks from 'pages/private/people/details/tabs/tasks';
import PersonDetailsProjects from 'pages/private/people/details/tabs/projects';
import PersonDetailsDocuments from 'pages/private/people/details/tabs/documents';
import ChangePersonImage from 'pages/private/people/change-image';
import PersonDetailsDocumentsForm from 'pages/private/people/details/tabs/documents/form';
import PersonContractDetails from 'pages/private/people/details/tabs/contracts/details';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import PersonDetailsTab from 'pages/private/people/details/tabs/details';
import PersonBankAccountsTab from 'pages/private/people/details/tabs/bank-accounts';
import CreateOrUpdatePersonBankAccountPage from 'pages/private/people/details/tabs/bank-accounts/create-or-update';
import ListPeople from 'pages/private/people/list';
import TaskPage from 'pages/private/tasks/details';

export const peopleDetailsChildren: RouteObject[] = [
    {
        path: 'detalhes',
        element: <Page Component={PersonDetailsTab} code="pessoas" permissions={[Rule.Show]} title="Detalhes da pessoa" />,
        // @ts-ignore
        label: 'Detalhes'
    },
    {
        path: 'contratos',
        element: <Page Component={PersonDetailsContracts} code="pessoas-contratos" permissions={[Rule.Show, Rule.List]} />,
        // @ts-ignore
        label: 'Contratos',
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdatePersonDetailsContractsPage} withModal={true} key="create-contract" code="pessoas-contratos" permissions={[Rule.Create]} />
            },
            {
                path: 'editar/:contractId',
                element: <Page Component={CreateOrUpdatePersonDetailsContractsPage} withModal={true} key="edit-contract" code="pessoas-contratos" permissions={[Rule.Update]} />
            },
            {
                path: 'reativar/:contractId',
                element: <Page Component={PersonContractStatus} withModal={true} type="reactivate" code="pessoas-contratos" permissions={[Rule.Update]} />
            },
            {
                path: ':contractId',
                element: <Page Component={PersonContractDetails} withModal={true} code="pessoas-contratos" permissions={[Rule.Show]} />
            },
            {
                path: ':contractId/documentos',
                element: <Navigate to=".." />
            },
            {
                path: ':contractId/documentos/novo',
                element: <Page Component={PersonDetailsDocumentsForm} withModal={true} code="pessoas-contratos-documentos" permissions={[Rule.Create]} />
            },
            {
                path: ':contractId/documentos/editar/:documentId',
                element: <Page Component={PersonDetailsDocumentsForm} withModal={true} code="pessoas-contratos-documentos" permissions={[Rule.Update]} />
            }
        ]
    },
    {
        path: 'projetos',
        element: <Page Component={PersonDetailsProjects} code="pessoas-projetos" permissions={[Rule.Show, Rule.List]} />,
        // @ts-ignore
        label: 'Projetos'
    },
    {
        path: 'tarefas',
        element: <Page Component={PersonDetailsTasks} code="pessoas-tarefas" permissions={[Rule.Show, Rule.List]} />,
        // @ts-ignore
        label: 'Tarefas',
        children: [
            {
                path: ':taskId',
                element: <Page Component={TaskPage} withModal={true} code="tarefas" permissions={[Rule.Show]} />
            }
        ]
    },
    {
        path: 'documentos',
        element: <Page Component={PersonDetailsDocuments} code="pessoas-documentos" permissions={[Rule.Show, Rule.List]} />,
        // @ts-ignore
        label: 'Documentos',
        children: [
            {
                path: 'novo',
                element: <Page Component={PersonDetailsDocumentsForm} withModal={true} code="pessoas-documentos" permissions={[Rule.Create]} />
            },
            {
                path: 'editar/:documentId',
                element: <Page Component={PersonDetailsDocumentsForm} withModal={true} code="pessoas-documentos" permissions={[Rule.Update]} />
            }
        ]
    },
    {
        path: 'contas-bancarias',
        element: <Page Component={PersonBankAccountsTab} code="pessoas-contas-bancarias" permissions={[Rule.List]} title="Contas Bancárias" />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdatePersonBankAccountPage} permissions={[Rule.Create]} code="pessoas-contas-bancarias" withModal={true} title="Cadastrar conta bancária" />
            },
            {
                path: 'editar/:bankAccountId',
                element: <Page Component={CreateOrUpdatePersonBankAccountPage} permissions={[Rule.Update]} code="pessoas-contas-bancarias" withModal={true} title="Editar conta bancária" />
            }
        ]
    }
];

const humanResources: RouteObject[] = [
    {
        path: 'pessoas',
        element: <Page Component={ListPeople} code="pessoas" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdatePersonPage} withModal={true} code="pessoas" permissions={[Rule.Create]} title="Nova pessoa" />
            },
            {
                path: 'editar/:personId',
                element: <Page Component={CreateOrUpdatePersonPage} withModal={true} code="pessoas" permissions={[Rule.Update]} title="Atualizar pessoa" />
            }
        ]
    },
    {
        path: 'pessoas/:personId',
        element: <Page Component={People} code="pessoas" permissions={[Rule.Show]} />,
        children: [
            {
                index: true,
                element: <Navigate to="detalhes" />
            },
            {
                path: 'editar',
                element: <Page Component={CreateOrUpdatePersonPage} withModal={true} code="pessoas" permissions={[Rule.Update]} title="Atualizar pessoa" />
            },
            {
                path: 'alterar-foto',
                element: <Page Component={ChangePersonImage} withModal={true} code="pessoas" permissions={[Rule.Update]} />
            },
            ...peopleDetailsChildren
        ]
    }
];

export default humanResources;
