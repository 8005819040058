import { useMutation } from 'react-query';
import api from 'services/api';

type Payload = {
    projectProposalTypeId?: string;
    projectTypeTermId?: string;
};

const useGetTermPrintUrl = () => {
    const request = async ({ projectProposalTypeId, projectTypeTermId }: Payload) => {
        const { data } = await api.get<{ url: string }>(`/project-types/${projectProposalTypeId}/terms/${projectTypeTermId}/print`);

        return data.url;
    };

    return useMutation(request);
};

export default useGetTermPrintUrl;
