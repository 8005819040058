// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import { addProjectTypeResourcesKey, getProjectKey, getProjectProposalTypeKey } from './graphql';

import api from 'services/api';

const useCreateOrUpdateProjectTypeResourcesProjects = (projectId?: number, proposalTypeId?: number, resourceId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = (payload: any) => {
        const method = resourceId ? api.patch : api.post;
        const endpoint = resourceId ? `/project-types/${proposalTypeId}/resources/${resourceId}` : `/project-types/${proposalTypeId}/resources`;

        return method(endpoint, payload);
    };

    return useMutation(addProjectTypeResourcesKey(proposalTypeId, projectId), request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectKey(projectId));
            queryClient.invalidateQueries(getProjectProposalTypeKey(proposalTypeId));

            navigate(-1);

            showToast(`Recurso ${resourceId ? 'editado' : 'alocado'} com sucesso`, 'success');
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title || 'Ocorreu um erro ao alocar os recursos', 'danger');
        }
    });
};

export default useCreateOrUpdateProjectTypeResourcesProjects;
