// Dependencies
import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';

// Helpers
import auth from './auth';
import config from './config';
import breadcrumb from './breadcrumb';
import credentials from './credentials';
import { Types as AuthTypes } from './auth';
import { ReduxAction } from 'types/general';

const appReducer = combineReducers({
    auth,
    breadcrumb,
    config,
    credentials
});

export type ReduxStore = ReturnType<typeof appReducer> & PersistPartial;

const rootReducer = (state: ReduxStore, action: ReduxAction<any>) => {
    if (action.type === AuthTypes.LOGOUT) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default rootReducer;
