import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';

import theme from 'settings/theme';

import { useShowApiErrors } from 'hooks/error/show-api-errors';

import { CreateClassificationPayload } from 'types/models/configurations-classifications';
import useGetClassificationsOptions from 'services/queries/configs-classifications/use-get-classifications-options';
import Select from 'components/core/form/select';
import schema from 'pages/private/config-classifications/utils/schema';
import { ClassificationType } from 'types/models/classification';

type ClassificationsProps = {
    title: string;
    isBuilding: boolean;
    isSubmitting: boolean;
    onSubmit: (data: CreateClassificationPayload) => void;
    defaultValues?: Partial<CreateClassificationPayload>;
    error?: any;
};

const INITIAL_FORM_STATE = {
    name: ''
};

const CreateUpdateClassificationProviderServices = ({ isBuilding, isSubmitting, onSubmit, defaultValues, error, title }: ClassificationsProps) => {
    const navigate = useNavigate();

    const { data } = useGetClassificationsOptions(ClassificationType.ProviderServices);

    const { formState, control, handleSubmit, reset, setError } = useForm<CreateClassificationPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema.classificationProviderServices),
        defaultValues: INITIAL_FORM_STATE
    });

    const { showFormErrors } = useShowApiErrors();

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleClose = () => navigate(-1);

    return (
        <Modal onClose={handleClose} contentClassnames="w-[500px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {title}
                    </Text>
                    <div className="grid gap-4 mb-4">
                        <Controller name="name" control={control} render={({ field }) => <Input {...field} label="Tipo" error={formState.errors.name?.message} />} />
                    </div>
                    <div className="mb-4">
                        <Controller
                            name="classification_id"
                            control={control}
                            render={({ field }) => {
                                const value = data?.find((item) => field.value === item?.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={data}
                                        label="Classificação"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.classification_id?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateUpdateClassificationProviderServices;
