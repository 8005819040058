import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { ProviderFinancialSetup } from 'types/models/provider';
import { getProviderFinancialSetupsKeys } from './graphql';

type Response = {
    provider_financial_setup: Partial<ProviderFinancialSetup>[];
};

const useGetProviderFinancialSetups = (query: string, projectProposalTypeId?: number) => {
    const fetcher = async () => apiGraphQl<Response>(query).then(({ provider_financial_setup }) => provider_financial_setup);

    return useQuery(getProviderFinancialSetupsKeys(projectProposalTypeId), fetcher, { enabled: Boolean(projectProposalTypeId) });
};

export default useGetProviderFinancialSetups;
