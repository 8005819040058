import { useOutletContext } from 'react-router-dom';
import { ProjectTypeDocument } from 'types/models/project';
import DetailsDocument from '../components/details';

const DocumentViewPage = () => {
    const document = useOutletContext<ProjectTypeDocument | undefined>();

    return <DetailsDocument document={document} />;
};

export default DocumentViewPage;
