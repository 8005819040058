import React from 'react';

// Dependencies
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';

// Components
import Icon from 'components/core/icon';

// Helpers
import hexToRgba from 'utils/hex-to-rgba';
import classNames from 'classnames';

type RemoveButtonProps = {
    onClick: () => void;
    className?: string;
};

const RemoveButton: React.FC<RemoveButtonProps> = ({ children, onClick, className = '' }) => {
    const { palette } = useTheme();

    const containerClassName = classNames('absolute -right-[18px] top-[50%] -mt-[18px] opacity-0 group-hover:opacity-100 transition z-[10]', className);

    return (
        <div className={containerClassName}>
            <IconButton
                size="large"
                type="button"
                color="error"
                sx={{ backgroundColor: palette.grey[100], border: `1px solid ${palette.error.main}`, '&:hover': { backgroundColor: hexToRgba(palette.error[100], 0.5) } }}
                onClick={onClick}
            >
                {children || <Icon name="ico-trash" width={16} height={16} color={palette.error[500]} />}
            </IconButton>
        </div>
    );
};

export default RemoveButton;
