import { convertEnumToOptions } from './enum';
import { AttentionPointType, ProjectDetachment, ProjectTypeEnum, ProjectTypeManagementInformation } from 'types/models/project';
import { SignpostDimension, SignpostType } from 'types/models/signpost';
import { Frequency, PaymentForm, Seniority, Shift } from 'types/general';
import { Gender, MaritalStatus, PersonContractType } from 'types/models/person';
import { AssetsModalities, AssetsStatus } from 'types/models/assets';

export const maritalStatuses = convertEnumToOptions(MaritalStatus, 'maritalStatus');

export const genders = convertEnumToOptions(Gender, 'genders');

export const jobSeniorities = convertEnumToOptions(Seniority, 'jobSeniorities');

export const detachmentTypes = convertEnumToOptions(ProjectDetachment, 'detachments');

export const signpostTypes = convertEnumToOptions(SignpostType, 'signpostTypes');

export const signpostDimensions = convertEnumToOptions(SignpostDimension, 'signpostDimensions');

export const shifts = convertEnumToOptions(Shift, 'shifts');

export const frequencies = convertEnumToOptions(Frequency, 'frequencies');

export const payments = convertEnumToOptions(PaymentForm, 'payments');

export const contractTypes = convertEnumToOptions(PersonContractType, 'contractTypes');

export const typeManagementInformations = convertEnumToOptions(ProjectTypeManagementInformation, 'typeManagementInformations');

export const attentionPointTypes = convertEnumToOptions(AttentionPointType, 'attentionPointTypes');

export const assetsStatuses = convertEnumToOptions(AssetsStatus, 'assetsStatus');

export const assetsModalities = convertEnumToOptions(AssetsModalities, 'assetsModalities');

export const projectTypes = convertEnumToOptions(ProjectTypeEnum, 'projectTypesOptions');
