// Dependencies
import { useOutletContext, useParams } from 'react-router-dom';

// Components
import CreateOrUpdateMeetingRecord from './create-or-update';

// Helpers
import { MeetingRecordProvider } from 'contexts/meeting-record';
import useGetMeetingRecord from 'services/queries/projects/use-get-meeting-record';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';

const CreateOrUpdateMeetingRecordPage = () => {
    const { meetingRecordId } = useParams();
    const meetingRecord = useGetMeetingRecord(meetingRecordId);

    const ctx = useOutletContext<OperationalDetailsPageContext | undefined>();

    return (
        <MeetingRecordProvider>
            <CreateOrUpdateMeetingRecord projectProposalTypeId={ctx?.projectProposalType?.id} {...meetingRecord} />
        </MeetingRecordProvider>
    );
};

export default CreateOrUpdateMeetingRecordPage;
