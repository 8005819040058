import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import classNames from 'classnames';
import Text from 'components/core/text';
import Empty from 'components/empty';

export type SimpleTableContent = {
    id: number | string;
    contents: Array<{
        label: React.ReactNode;
        className?: string;
        textClassName?: string;
        size?: number;
    }>;
};

type SimpleTableProps = {
    columns: Array<{ label: string; className?: string }>;
    items: SimpleTableContent[];
    footer?: SimpleTableContent;
    className?: string;
};

const SimpleTable = ({ columns, items, className, footer }: SimpleTableProps) => {
    return (
        <TableContainer>
            <Table className={className}>
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) => {
                            const headColumnClasses = classNames('py-2', item.className);
                            return (
                                <TableCell className={headColumnClasses} key={`head_${index}`}>
                                    <Text className="text-secondary-500">{item.label}</Text>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Boolean(items.length) ? (
                        items.map((item) => {
                            return (
                                <TableRow classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50' }} key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    {item.contents.map((content, index) => {
                                        return (
                                            <TableCell key={`${item.id}_${index}`} colSpan={content.size} className={content.className}>
                                                <Text as="span" variant="body.regular.xs" className={classNames('text-base-500', content.textClassName)}>
                                                    {content.label}
                                                </Text>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Empty title="Não há dados a serem listados." />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                {Boolean(footer) && (
                    <TableFooter>
                        <TableRow classes={{ root: 'hover:bg-base-200 hover:bg-opacity-50' }} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {footer!.contents.map((content, index) => {
                                return (
                                    <TableCell colSpan={content.size} key={`footer_${index}`} className={content.className}>
                                        <Text as="span" variant="body.regular.xs" className={classNames('text-base-500', content.textClassName)}>
                                            {content.label}
                                        </Text>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
};

export default SimpleTable;
