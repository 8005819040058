import React, { useCallback, useMemo, useState } from 'react';

// Dependencies
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Components
import Empty from 'components/empty';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Files from '../components/publish/files';
import Details from '../components/publish/details';
import Subjects from '../components/publish/subjects';
import Participants from '../components/publish/participants';

// Helpers
import { ProjectMeetingRecord } from 'types/models/project';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';

const MeetingRecord = (props: UseQueryResult<ProjectMeetingRecord, unknown>) => {
    const navigate = useNavigate();
    const [tab, setTab] = useState('DETAILS');

    const { palette } = useTheme();

    const participants = useMemo(
        () => ({
            internal:
                props.data?.participants
                    .filter((item) => item.person)
                    .map((item) => ({
                        validationRequired: item.validationRequired!,
                        name: item.person?.name!
                    })) || [],
            external:
                props.data?.participants
                    .filter((item) => item.email)
                    .map((item) => ({
                        name: item.name!,
                        email: item.email!,
                        phones: item.phones!,
                        jobPosition: item?.jobPosition!,
                        company: item?.company!,
                        validationRequired: item.validationRequired!
                    })) || []
        }),
        [props.data]
    );

    const handleClose = () => navigate(-1);

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    const handlePrint = useCallback(() => {
        window.open(props?.data?.document?.file?.url, '_blank');
    }, [props.data]);

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[1200px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading mb-3">
                    {props.data?.title}
                </Text>
            }
            headerRight={
                Boolean(props?.data?.document) ? (
                    <Tooltip placement="top" title="Imprimir termo">
                        <IconButton disabled={props.isLoading} className="hover:bg-secondary-100 hover:bg-opacity-20 ml-2" onClick={handlePrint}>
                            {props.isLoading ? <Spinner fixed={false} size={16} /> : <Icon name="ico-printer" color={palette.secondary[500]} width={16} height={16} />}
                        </IconButton>
                    </Tooltip>
                ) : (
                    <></>
                )
            }
            onClose={handleClose}>
            {props.isLoading ? (
                <Spinner />
            ) : Boolean(props.error) || !Boolean(props.data) ? (
                <Empty title="Ocorreu um erro ao carregar a ata de reunião" className="p-6" />
            ) : (
                <div className="p-7 pt-0 px0">
                    <Tabs variant="scrollable" classes={{ root: 'px-0' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                        <Tab value="DETAILS" label="Detalhes" />
                        <Tab value="SUBJECTS" label="Tópicos" />
                        <Tab value="FILES" label="Arquivos" />
                        <Tab value="PARTICIPANTS" label="Participantes" />
                    </Tabs>
                    {tab === 'DETAILS' && (
                        <Details date={props.data?.date!} title={props.data?.title!} editors={props.data?.editors.map((item) => (item as any).name!) || []} className="border-0 mb-0 px-0" />
                    )}
                    {tab === 'SUBJECTS' && <Subjects subjects={props.data?.subjects} className="border-0 mb-0 px-0" />}
                    {tab === 'FILES' && <Files withTableHead={false} files={props.data?.files.map((item) => ({ filename: item.filename, url: item.url })) || []} className="border-0 mb-0 px-0" />}
                    {tab === 'PARTICIPANTS' && <Participants {...participants} className="border-0 mb-0 px-0" />}
                </div>
            )}
        </Modal>
    );
};

export default MeetingRecord;
