import { useFieldArray } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';

import Empty from 'components/empty';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import RemoveButton from 'components/buttons/remove';

import ClassificationFields from './fields';

const defaultClassifications = {
    classification_id: '',
    subcategory_id: ''
};

type ClassificationsProps = UseFormReturn<any, any>;

const Classifications = ({ ...props }: ClassificationsProps) => {
    const { control } = props;

    const handleAdd = () => append(defaultClassifications);

    const handleDelete = (index: number) => () => remove(index);

    const { fields, append, remove } = useFieldArray({ name: 'classifications', control });

    return (
        <div className="border border-base-300 rounded-[14px] p-4 mb-4">
            <Text as="h5" variant="h6" className="text-heading mb-3">
                Tipos de serviços
            </Text>
            {Boolean(fields.length) ? (
                fields.map((item, index) => {
                    return (
                        <div key={item.id} className="border border-base-300 rounded-[14px] p-4 mb-4 group relative">
                            <RemoveButton onClick={handleDelete(index)} />
                            <ClassificationFields baseName="classifications" index={index} methods={props} />
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhum tipo de serviço adicionado ainda" className="mb-2" />
            )}
            <AddButton onClick={handleAdd}>Novo tipo de serviço</AddButton>
        </div>
    );
};

export default Classifications;
