import React, { useMemo, useState } from 'react';

// Dependencies
import Menu from '@mui/material/Menu/Menu';
import Badge from '@mui/material/Badge/Badge';
import Divider from '@mui/material/Divider/Divider';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';

// Components
import NotificationItem from './item';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Button from 'components/core/button';

// Helpers
import useGetNotifications from 'services/queries/notifications/use-get-notifications';

const Notifications = () => {
    const { palette } = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { data: notifications } = useGetNotifications();

    const unreadCount = useMemo(() => notifications?.filter((item) => !item.is_read).length, [notifications]);

    const handleClose = () => setAnchorEl(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleGoToNotificationsPage = () => {
        handleClose();
    };

    return (
        <div className="relative">
            <IconButton color="primary" aria-label="cart" className="p-[10px]" onClick={handleOpen}>
                <Badge badgeContent={unreadCount} color="error">
                    <Icon name="ico-bell" color={palette.heading} width={16} height={16} />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                open={Boolean(anchorEl)}
                PaperProps={{
                    classes: {
                        elevation8: 'shadow-none sm:w-96 sm:max-w-sm rounded-[14px] custom-shadow'
                    }
                }}
                onClose={handleClose}>
                <div className="flex items-center px-4 py-2 pb-3">
                    <Text as="span" variant="h6" className="text-heading flex-1">
                        Notificações
                    </Text>
                    <Button color="secondary" variant="text" size="medium" onClick={handleGoToNotificationsPage}>
                        Ver todas
                    </Button>
                </div>
                <Divider />
                <div className="p-3 notifications">
                    {Boolean(notifications?.length) ? (
                        notifications?.map((item) => <NotificationItem key={item.id} {...item} onCloseMenu={handleClose} />)
                    ) : (
                        <div className="px-4 py-2">
                            <Text as="p" className="text-base-500 italic">
                                Nenhuma notificação encontrada
                            </Text>
                        </div>
                    )}
                </div>
            </Menu>
        </div>
    );
};

export default Notifications;
