import useMatchRoutes from 'hooks/router/use-match-routes';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteObject } from 'react-router-dom';
import { useAppSelector } from 'store';
import { Creators as BreadcrumbActions } from 'store/breadcrumb';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { excludePaths } from './utils';

const useBreadcrumb = (label?: string) => {
    const dispatch = useDispatch();
    const matchedRoutes = useMatchRoutes();

    const { routes, isVisible } = useAppSelector(({ breadcrumb }) => breadcrumb);

    const breadcrumbs = useReactRouterBreadcrumbs(routes, { excludePaths });

    const setRoutes = useCallback((payload: RouteObject[]) => dispatch(BreadcrumbActions.setRoutes(payload)), [dispatch]);

    const setBreadcrumbLabel = (item: RouteObject) => {
        const match = matchedRoutes?.find((matched) => matched.route.path === item.path && item.path?.includes(':'));

        if (match) {
            return { ...item, breadcrumb: label || '' };
        }

        return { ...item, children: item.children?.map(setBreadcrumbLabel) };
    };

    const formatBreadcrumb = useCallback(() => {
        if (!label) {
            return;
        }

        if (matchedRoutes) {
            const newRoutes = routes.map(setBreadcrumbLabel);

            setRoutes(newRoutes);
        }
        // eslint-disable-next-line
    }, [label]);

    useEffect(() => {
        formatBreadcrumb();
    }, [formatBreadcrumb]);

    return {
        breadcrumbs,
        setRoutes,
        toggleVisibility: (payload: boolean) => dispatch(BreadcrumbActions.toggleVisibility(payload)),
        isVisible
    };
};

export default useBreadcrumb;
