import { TaskFilters } from 'pages/private/tasks';
import { TaskDashboardItem } from 'types/models/task-dashboard';

export const getTaskStatusesKey = ['task_dashboard', 'statuses'];

export const getTasksByStatusKey = (id: number, filters?: TaskFilters) => [...getTaskStatusesKey, id, filters];

export const getTaskCardKey = (taskId: number, statusId: number) => [...getTasksByStatusKey(statusId), 'task', taskId];

export const taskDashboardQueries = {
    getTasksByStatus: `query getTasksByStatus($personId: Int!, $statusId: Int!, $taskWhere: person_dashboard_manager_task_bool_exp) {
        items: person_dashboard_manager(where: {person_id: {_eq: $personId}, task_status_id: {_eq: $statusId}}, limit: 1) {
          id
          tasks: person_dashboard_manager_tasks(where: $taskWhere, order_by: {order_task: asc}) {
            task {
              id
              title
              description
              created_at
              task_father {
                id
                title
              }
              project_proposal_type {
                project_proposal {
                    project {
                        id
                        title
                        code
                    }
                }
              }
              person {
                name
                file {
                  path
                  filename
                }
              }
              comments: task_comments_aggregate {
                aggregate {
                  count
                }
              }
              files: task_files_aggregate {
                aggregate {
                  count
                }
              }
            }
          }
        }
      }`,
    updateTasks: (tasks: TaskDashboardItem[]) => `mutation updatePersonTasks($personId: Int!) {
        ${tasks
            .map(
                (
                    { id },
                    index
                ) => `task_${id}: update_person_dashboard_manager_task(where: {task_id: {_eq: ${id}}, person_dashboard_manager: {person_id: {_eq: $personId}}}, _set: {order_task: ${index}}) {
            affected_rows
        }`
            )
            .join('\n')}
    }`
};
