import api from 'services/api';
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { GraphqlPaginationVariables } from 'types/graphql';
import { MenuGroupPayload } from 'pages/private/menu/create-or-update';

const useCreateOrUpdateMenuGroup = (params: GraphqlPaginationVariables<any>, menuGroupId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const isPatch = !!menuGroupId;

    const request = async (payload: MenuGroupPayload) => {
        const method = isPatch ? api.patch : api.post;
        const endpoint = isPatch ? `/menus/${menuGroupId}` : '/menus';

        return method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('menu_group', params));

            navigate(-1);
            showToast(`Grupo de menu ${isPatch ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isPatch ? 'atualizar' : 'criar'} o grupo de menu`, 'danger');
        }
    });
};

export default useCreateOrUpdateMenuGroup;
