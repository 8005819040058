import { useMutation } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import api from 'services/api';

const useResetPasswordPeople = (peopleId?: number) => {
    const { showToast } = useToast();

    const fetcher = async (payload: { id: number }) => {
        return api.post(`people/${peopleId}/reset-password`, payload);
    };

    return useMutation(fetcher, {
        onSuccess: () => {
            showToast(`Senha renovada com sucesso`, 'success', { duration: 5000 });
        },
        onError: (error) => {
            console.log(error);
            showToast(`Ocorreu um erro ao renovar a senha do usuário em nosso sistema`, 'danger');
        }
    });
};

export default useResetPasswordPeople;
