import Divider from '@mui/material/Divider/Divider';
import Badge from 'components/core/badge';
import Text from 'components/core/text';
import { budgetStatusMap } from '../utils';
import { BudgetStatus } from 'types/models/commercial';
import ExternalButton from 'components/buttons/external';

type BudgetDetails = {
    title?: string;
    code?: string;
    customer?: string;
    budgetStatus?: BudgetStatus;
    contractId?: number;
};

const BudgetDetails = ({ title = '-', code, customer = '-', budgetStatus, contractId }: BudgetDetails) => {
    return (
        <div className="px-6 py-5 relative">
            <div className="flex justify-between items-center">
                <div className="flex-1 pr-2">
                    <div className="flex justify-between items-center flex-wrap gap-1">
                        <Text variant="h5" as="h5" className="block !text-[22px] text-heading">
                            {title}
                        </Text>
                        <Badge variant={(budgetStatusMap[budgetStatus!]?.variant as any) || 'grey'}>{budgetStatusMap[budgetStatus!]?.label || '-'}</Badge>
                    </div>
                    {Boolean(code) && (
                        <Text variant="body.regular.xs" as="span" className="block text-base-400 italic mt-1">
                            #{code}
                        </Text>
                    )}
                </div>
            </div>
            <>
                <Divider className="my-6" />
                <div className="flex items-center flex-wrap justify-between mb-3">
                    <Text variant="body.regular.sm" as="span" className="text-secondary-500">
                        Cliente
                    </Text>
                    <Text variant="body.regular.sm" as="span" className="text-base-500">
                        {customer}
                    </Text>
                </div>
                {Boolean(contractId) && (
                    <ExternalButton to={`/app/contratos/${contractId}`} textClassNames="text-secondary-500">
                        Ir para o contrato
                    </ExternalButton>
                )}
            </>
        </div>
    );
};

export default BudgetDetails;
