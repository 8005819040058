import api from 'services/api';
import { useQuery } from 'react-query';
import { getReportKey } from './keys';

const useGetExpensesReport = (page: number) => {
    const request = async () => {
        const { data } = await api.get<any>(`reports/project-types-expenses?page=${page}`);

        return data;
    };

    return useQuery(getReportKey('expenses', page), request);
};

export default useGetExpensesReport;

//TODO: type response - HIGOR
