import React, { useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Option } from 'types/general';
import { Gender, MaritalStatus, Person } from 'types/models/person';
import useTheme from '@mui/material/styles/useTheme';
import dictionary from 'utils/dictionary';
import _get from 'lodash/get';
import classNames from 'classnames';
import { minutesToHours, formatDate } from 'utils/date';
import Icon from 'components/core/icon';
import Avatar from '@mui/material/Avatar/Avatar';
import hexToRgba from 'utils/hex-to-rgba';
import { CardHeader } from '@mui/material';
import ProjectChart from 'pages/private/home/components/project-chart';
import useGetPersonDetailsTab from 'services/queries/people/use-get-person-details-tab';
import MUICard from 'components/core/card';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

const Card = ({ children, className }: { children: React.ReactNode; className?: string }) => <MUICard className={classNames('p-6', className)}>{children}</MUICard>;

const PersonDetailsTab = () => {
    const { palette } = useTheme();
    const { personId } = useParams();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const {
        name,
        email,
        gender,
        address,
        document,
        documentIdentity,
        documentIssuingAgency,
        expeditionDate,
        voterRegistration,
        voterRegistrationPollingStation,
        socialIntegrationProgram,
        workCard,
        workCardSeries,
        emails,
        voterRegistrationConstituency,
        maritalStatus,
        phones
    } = useOutletContext<Partial<Person>>();

    const { data, isLoading } = useGetPersonDetailsTab(personId);

    const {
        totalContracts,
        totalProjects,
        totalTasks,
        tasks = [],
        tasksByStatusData = {
            items: [],
            legends: []
        } as any
    } = data || {};

    const genderLabel = _get(dictionary.content.genders, Gender[gender!]?.toLowerCase(), '');
    const maritalStatusLabel = _get(dictionary.content.maritalStatus, MaritalStatus[maritalStatus!]?.toLowerCase(), '');

    const details: Array<{ title: string; items: Array<Option<any> & { span?: number }> }> = [
        {
            title: 'Informações pessoais',
            items: [
                {
                    value: name,
                    label: 'Nome'
                },
                {
                    value: genderLabel,
                    label: 'Gênero'
                },
                {
                    value: maritalStatusLabel,
                    label: 'Estado civil'
                }
            ]
        },
        {
            title: 'Documentos',
            items: [
                {
                    value: document,
                    label: 'CPF'
                },
                {
                    value: documentIdentity,
                    label: 'RG'
                },
                {
                    value: formatDate(expeditionDate),
                    label: 'Data de expedição'
                },

                {
                    value: documentIssuingAgency,
                    label: 'Órgão expedidor'
                },
                {
                    value: voterRegistration,
                    label: 'Título de Eleitor'
                },
                {
                    value: voterRegistrationPollingStation,
                    label: 'Zona Eleitoral'
                },
                {
                    value: voterRegistrationConstituency,
                    label: 'Seção Eleitoral'
                },

                {
                    value: socialIntegrationProgram,
                    label: 'PIS'
                },
                {
                    value: workCard,
                    label: 'Número da CTPS'
                },
                {
                    value: workCardSeries,
                    label: 'Série da CTPS'
                }
            ]
        },
        {
            title: 'Informações de contato',
            items: [
                {
                    value: email,
                    label: 'E-mail principal'
                },
                {
                    value: emails?.map(({ email }) => email)?.join(', '),
                    label: 'E-mails secundários'
                },

                {
                    value: phones?.map(({ ddd, number }) => `(${ddd}) ${number}`).join(', '),
                    label: 'Telefone(s)'
                },
                {
                    value: address?.full,
                    label: 'Endereço',
                    span: 3
                }
            ]
        }
    ];

    const isTasksEmpty = !tasksByStatusData?.total?.value && !isLoading;

    const totalTasksHours = useMemo(() => tasks.reduce((acc, curr) => (acc += curr.accomplishedTime), 0), [tasks]);

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-project" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0', content: 'basis-[100px]' }}
                        className="flex-wrap gap-2"
                        title="PROJETOS"
                        subheader={totalProjects}
                        titleTypographyProps={{ color: palette.grey[500], fontSize: 14 }}
                        subheaderTypographyProps={{ color: palette.heading, fontSize: 25 }}
                    />
                </Card>

                <Card>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-travel" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0', content: 'basis-[100px]' }}
                        className="flex-wrap gap-2"
                        title="CONTRATOS"
                        subheader={totalContracts}
                        titleTypographyProps={{ color: palette.grey[500], fontSize: 14 }}
                        subheaderTypographyProps={{ color: palette.heading, fontSize: 25 }}
                    />
                </Card>

                <Card>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-tasks-outline" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0', content: 'basis-[100px]' }}
                        className="flex-wrap gap-2"
                        title="TAREFAS"
                        subheader={totalTasks}
                        titleTypographyProps={{ color: palette.grey[500], fontSize: 14 }}
                        subheaderTypographyProps={{ color: palette.heading, fontSize: 25 }}
                    />
                </Card>

                <Card>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-clock" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0', content: 'basis-[100px]' }}
                        className="flex-wrap gap-2"
                        title="TOTAL HORAS (TAREFAS)"
                        subheader={minutesToHours(totalTasksHours)}
                        titleTypographyProps={{ color: palette.grey[500], fontSize: 14 }}
                        subheaderTypographyProps={{ color: palette.heading, fontSize: 25 }}
                    />
                </Card>
            </div>

            {details.map(({ title, items }, index) => (
                <Card className="mb-4" key={`detail_${index}`}>
                    <p className="text-heading text-2xl mb-4">{title}</p>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                        {items.map(({ label, value, span }, index) => (
                            <div className={classNames('flex flex-col', span && `col-span-1 sm:col-span-${span}`)} key={`detail-${index}`}>
                                <p className="mb-1 uppercase" style={{ color: palette.grey[500] }}>
                                    {label}
                                </p>
                                <p className="text-sm break-words" style={{ color: palette.heading }}>
                                    {value ?? '-'}
                                </p>
                            </div>
                        ))}
                    </div>
                </Card>
            ))}

            {isTasksEmpty ? (
                <Card className="flex-1 flex flex-col py-6 px-4 pt-6">
                    <p className="text-heading text-xl font-medium mb-4">Tarefas</p>

                    <div className="flex flex-col items-center min-h-[300px] justify-center">
                        <p className="text-base-500 text-lg mb-2">Nenhuma tarefa foi encontrada.</p>
                        <Icon name="ico-emoji-shocked" height={40} width={40} />
                    </div>
                </Card>
            ) : (
                <ProjectChart legendsClassName="grid grid-cols-2 gap-4" horizontal={true} isLoading={isLoading} className="mb-5" title="Tarefas" {...tasksByStatusData} />
            )}
        </>
    );
};

export default PersonDetailsTab;
