import Datatable from 'components/core/table/datatable';
import React from 'react';
import { DatatableColumn } from 'types/graphql';
import dictionary from 'utils/dictionary';
import _get from 'lodash/get';
import { PersonContractStatusTitle } from '../..';
import { formatDate } from 'utils/date';

export type PersonContractMovement = {
    id: number;
    contractStatus: {
        id: number;
        title: string;
    };
    startDate: Date;
    finalDate?: Date;
    observations?: string;
}[];

type Props = {
    items: PersonContractMovement[];
};

const PersonContractDetailsMovements = ({ items = [] }: Props) => {
    const columns: DatatableColumn[] = [
        {
            name: 'id',
            options: { display: 'excluded' }
        },
        {
            name: 'contractStatus.id',
            label: dictionary.content.graphql['status'],
            options: {
                customBodyRender: (value) => _get(dictionary.content.personContractStatusTitle, PersonContractStatusTitle[value], '-') || '-'
            }
        },
        {
            name: 'startDate',
            label: dictionary.content.graphql['start_date'],
            options: {
                customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY')
            }
        },
        {
            name: 'finalDate',
            label: dictionary.content.graphql['final_date'],
            options: {
                customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY')
            }
        },
        {
            name: 'observations',
            label: dictionary.content.graphql['observations']
        }
    ];

    return (
        <Datatable
            columns={columns}
            data={items}
            loading={false}
            options={{
                elevation: 0,
                sort: true,
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                serverSide: true,
                selectableRows: 'none',
                enableNestedDataAccess: '.',
                pagination: false
            }}
            withItemsPerPage={false}
            hideFooter={true}
        />
    );
};

export default PersonContractDetailsMovements;
