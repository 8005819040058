import { Company } from 'types/models/company';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { Outlet } from 'react-router-dom';
import { useTabRoute, UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import useCreateNav from 'hooks/nav/use-create-nav';
import { Page } from 'types/models/page';

type Props = {
    customer: Partial<Company>;
    subpages: Page[];
};

const Customer = ({ customer, subpages }: Props) => {
    const nav = useCreateNav<UseTabRouteChildren>(subpages, (subpage) => ({
        label: subpage.title,
        ...(!!subpage.link && { path: subpage.link })
    }));

    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(nav);

    return (
        <div className="bg-base-100 flex flex-col sm:flex-row -ml-6 -mr-4 -my-6 sm:h-[calc(100vh-70px)] sm:max-h-[calc(100vh-70px)] overflow-hidden">
            <div className="bg-base-200 sm:w-[242px] h-full overflow-y-auto">
                <Tabs classes={{ indicator: 'hidden' }} visibleScrollbar={true} orientation="vertical" variant="scrollable" value={activeTabIndex} onChange={handleChangeTab}>
                    {tabLabels.map((label, index) => {
                        const isLastChild = tabLabels.length - 1 === index;

                        return (
                            <Tab
                                classes={{ root: 'bg-base-200', selected: 'bg-base-100' }}
                                className={`${!isLastChild && 'border-b border-solid border-b-base-300'} items-start w-full p-6 max-w-none`}
                                label={label}
                                key={`${label}_${index}`}
                            />
                        );
                    })}
                </Tabs>
            </div>
            <section className="flex-1 border-x border-x-base-300 overflow-y-auto relative w-full h-full flex pl-6 pr-4 py-6 flex-col">
                <Outlet context={customer} />
            </section>
        </div>
    );
};

export default Customer;
