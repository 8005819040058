import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';

import { CreateAssetPayload } from 'types/models/assets';
import useGetAssetById from 'services/queries/assets/use-get-asset-by-id';

import useCreateOrUpdateAsset from 'services/queries/assets/use-create-update-asset';
import { createOrUpdateClassificationMutation } from './utils';
import CreateOrUpdateAsset from './create-or-update';
import useCreateAssetLeasing from 'services/queries/assets/use-create-asset-leasing';
import dayjs from 'dayjs';
import { Status } from 'types/general';
import omit from 'lodash/omit';

const CreateOrUpdateAssetPage = ({ title }: CrudPageProps) => {
    const { assetId, classificationTypeId } = useParams();
    const navigate = useNavigate();
    const params = useOutletContext<GraphqlPaginationVariables<CreateAssetPayload>>();

    const { mutateAsync: createOrUpdateAsset, isLoading: isSubmittingAsset, error: errorCreateUpdate } = useCreateOrUpdateAsset(assetId, params, createOrUpdateClassificationMutation(assetId));

    const { mutateAsync: createAssetLeasing, isLoading: isCreatingAssetLeasing } = useCreateAssetLeasing();

    const { data: assetDetails, isLoading: isLoadingDetails, error: errorGetAsset } = useGetAssetById(assetId);

    const error = errorCreateUpdate || errorGetAsset;
    const isSubmitting = isSubmittingAsset || isCreatingAssetLeasing;

    const handleSubmit = async (data: CreateAssetPayload) => {
        try {
            const payload = {
                ...omit(data, 'person_id'),
                account_id: 1,
                status: Status.Active,
                type: +classificationTypeId!
            };

            const assetId = await createOrUpdateAsset(payload);

            if (!!data?.person_id) {
                const assetLeasingPayload = {
                    start_date: dayjs().utc(),
                    asset_id: assetId,
                    person_id: data.person_id
                };
                await createAssetLeasing(assetLeasingPayload);
            }

            navigate(-1);
        } catch (error) {
            console.log('error', error);
        }
    };

    return <CreateOrUpdateAsset isBuilding={isLoadingDetails} isSubmitting={isSubmitting} onSubmit={handleSubmit} defaultValues={assetDetails} error={error} title={title || ''} />;
};

export default CreateOrUpdateAssetPage;
