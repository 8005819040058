import Select from 'components/core/form/select';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Option } from 'types/general';
import { PersonContractType } from '.';
import _get from 'lodash/get';
import Text from 'components/core/text';
import Button from 'components/core/button';
import dictionary from 'utils/dictionary';
import DatePicker from 'components/core/datepicker';
import Textarea from 'components/core/form/textarea';
import { date, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/core/form/currency';

type Props = {
    type: PersonContractType;
    jobPositions: Option<number>[];
    onSubmit(data: any): void;
    isSubmitting: boolean;
    onClose(): void;
    contractStatus: Option<number>[];
};

export const schemas = {
    promotion: {
        position: number().typeError(dictionary.validation.required).required(dictionary.validation.required),
        startDate: date().typeError(dictionary.validation.required).required(dictionary.validation.required),
        salary: object({
            formattedValue: string(),
            value: string(),
            floatValue: number()
        })
    },
    resignation: {
        finalDate: date().typeError(dictionary.validation.required).required(dictionary.validation.required),
        earlyWarningStartDate: date().typeError(dictionary.validation.required).required(dictionary.validation.required),
        earlyWarningFinalDate: date().typeError(dictionary.validation.required).required(dictionary.validation.required)
    },
    situation: {
        status: number().typeError(dictionary.validation.required).required(dictionary.validation.required),
        startDate: date().typeError(dictionary.validation.required).required(dictionary.validation.required)
    },
    reactivate: {
        startDate: date().typeError(dictionary.validation.required).required(dictionary.validation.required)
    }
};

const PersonContractStatusForm = ({ type, jobPositions = [], onSubmit, isSubmitting, onClose, contractStatus }: Props) => {
    const methods = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(object({ ...schemas[type], observations: string().default('').notRequired() }))
    });

    const { formState, control, handleSubmit, setValue } = methods;

    const getError = useCallback((name: string) => _get(formState.errors, `${name}.message`), [formState]);

    const fields = useMemo(() => {
        switch (type) {
            case 'promotion':
                return (
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                        <Controller
                            name="position"
                            control={control}
                            render={({ field }) => {
                                const value = jobPositions.find((item) => (item as any)?.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={jobPositions}
                                        label="Cargo"
                                        placeholder="Selecione uma opção"
                                        error={getError('position')}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="startDate"
                            control={control}
                            render={({ field }) => <DatePicker label="Data" placeholderText="Selecione uma data" error={getError('startDate')} {...field} />}
                        />
                        <Controller
                            name="salary"
                            control={control}
                            render={() => {
                                return (
                                    <CurrencyInput
                                        left={
                                            <Text as="span" variant="body.medium.sm" className="text-heading">
                                                R$
                                            </Text>
                                        }
                                        label="Salário"
                                        placeholder="Ex: 1000"
                                        error={getError('salary')}
                                        onValueChange={(values) => setValue('salary', values)}
                                    />
                                );
                            }}
                        />
                    </div>
                );

            case 'resignation':
                return (
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                        <Controller
                            name="finalDate"
                            control={control}
                            render={({ field }) => <DatePicker label="Data de demissão" placeholderText="Selecione uma data" error={getError('startDate')} {...field} />}
                        />

                        <Controller
                            name="earlyWarningStartDate"
                            control={control}
                            render={({ field }) => <DatePicker label="Início de aviso prévio" placeholderText="Selecione uma data" error={getError('earlyWarningStartDate')} {...field} />}
                        />

                        <Controller
                            name="earlyWarningFinalDate"
                            control={control}
                            render={({ field }) => <DatePicker label="Fim de aviso prévio" placeholderText="Selecione uma data" error={getError('earlyWarningStartDate')} {...field} />}
                        />
                    </div>
                );

            case 'situation':
                return (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <Controller
                            name="status"
                            control={control}
                            render={({ field }) => {
                                const value = contractStatus.find((item) => (item as any)?.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={contractStatus}
                                        label="Situação"
                                        placeholder="Selecione uma opção"
                                        error={getError('status')}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />

                        <Controller
                            name="startDate"
                            control={control}
                            render={({ field }) => <DatePicker label="Data" placeholderText="Selecione uma data" error={getError('startDate')} {...field} />}
                        />
                    </div>
                );

            case 'reactivate':
                return (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <Controller
                            name="startDate"
                            control={control}
                            render={({ field }) => <DatePicker label="Data" placeholderText="Selecione uma data" error={getError('startDate')} {...field} />}
                        />
                    </div>
                );

            default:
                onClose();
                return null;
        }
    }, [type, control, getError, jobPositions, contractStatus, setValue, onClose]);

    const title = dictionary.content.personContractType[type];

    return (
        <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
            <Text as="h3" variant="h4" className="text-heading mb-5">
                {title}
            </Text>
            <div className="flex flex-col mb-8">
                {fields}

                <Controller
                    name="observations"
                    control={control}
                    render={({ field }) => <Textarea {...field} label="Observações" placeholder="Adicione alguma observação" error={getError('observations')} />}
                />
            </div>
            <div className="flex items-center">
                <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                    Enviar
                </Button>
                <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={onClose}>
                    Cancelar
                </Button>
            </div>
        </form>
    );
};

export default PersonContractStatusForm;
