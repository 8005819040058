import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { deleteProjectDocumentKey, getProjectDocumentsKey } from './graphql';

export type ProjectDocumentPayload = {
    classification: number;
    file: number;
    name: string;
    description?: string;
    observation?: string;
};

const useDeleteProjectDocument = (projectId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (documentId?: number) => api.delete(`/projects/${projectId}/documents/${documentId}`);

    return useMutation(deleteProjectDocumentKey(projectId), request, {
        onSuccess: () => {
            showToast('Documento apagado com sucesso', 'success');

            queryClient.invalidateQueries(getProjectDocumentsKey(projectId));
        },
        onError: () => {
            showToast('Ocorreu um erro ao apagar o documento', 'danger');
        }
    });
};

export default useDeleteProjectDocument;
