// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { GraphqlPagination } from 'types/graphql';
import project, { projectTypesOptionsKey } from './graphql';

const useGetProjectTypesOptions = () => {
    const getProjectTypesOptions = () => apiGraphQl<GraphqlPagination<Option>>(project.getProjectTypesOptions).then((data) => data.items);

    return useQuery(projectTypesOptionsKey, getProjectTypesOptions);
};

export default useGetProjectTypesOptions;
