import classNames from 'classnames';
import React from 'react';
import { ColorVariant } from 'types/colors';
import useTheme from '@mui/material/styles/useTheme';
import _get from 'lodash/get';

export type HomeMyTasksItemProps = {
    id: number;
    personName: string;
    title: string;
    status: 'done' | 'pending' | 'partial' | 'stopped' | 'processing';
};

const HomeMyTasksItem = ({ status, personName, title }: HomeMyTasksItemProps) => {
    const statusColors = new Map<HomeMyTasksItemProps['status'], ColorVariant>([
        ['done', 'success'],
        ['pending', 'info'],
        ['partial', 'secondary'],
        ['stopped', 'warning'],
        ['processing', 'primary']
    ]);

    const { palette } = useTheme();

    const variantColors = _get(palette, statusColors.get(status) as string);

    // const backgroundColor = hexToRgba(variantColors[100], 0.3);
    const iconColor = variantColors[800];

    const lineClassName = classNames('w-[4px] h-[45px] rounded-md mr-3');

    return (
        <div className="flex items-center mb-4 last:mb-0 cursor-pointer">
            <span className={lineClassName} style={{ backgroundColor: iconColor }}></span>
            <div className="flex flex-col flex-1">
                <span className="text-base-500 text-sm">{personName}</span>
                <p className="text-heading  text-sm">{title}</p>
            </div>
        </div>
    );
};

export default HomeMyTasksItem;
