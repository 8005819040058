import Text from 'components/core/text';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Option } from 'types/general';
import { DailyReportPayload } from 'types/models/organization';
import WorkDiaryItem from './work-diary-item';

type WorkDiariesProps = {
    providers: Option[];
};

const WorkDiaries = ({ providers }: WorkDiariesProps) => {
    const { control } = useFormContext<DailyReportPayload>();
    const { fields: workDiaries } = useFieldArray({ name: 'workDiaries', control });

    return (
        <div className="border border-base-300 p-4 rounded-[14px]">
            <Text as="h6" variant="h6" className="text-heading mb-2">
                Diários de obra
            </Text>
            {workDiaries.map((item, index) => {
                const isLast = workDiaries.length - 1 === index;
                const provider = providers.find((option) => option.value === item.provider);

                return <WorkDiaryItem className={isLast ? '' : 'mb-9'} index={index} key={item.id} provider={provider} />;
            })}
        </div>
    );
};

export default WorkDiaries;
