import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import { createOutcomeTransactionProject } from './graphql';

import { useShowApiErrors } from 'hooks/error/show-api-errors';

import { OutcomeProjectTransaction } from 'types/models/organization';
import api from 'services/api';

const useCreateOrUpdateOutcometransactionProject = (outcomeId: string | undefined, projectTypeId: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();

    const request = async (payload) => {
        const method = outcomeId ? api.patch : api.post;
        const endpoint = outcomeId ? `/project-types/${projectTypeId}/transactions/${outcomeId}` : `/project-types/${projectTypeId}/transactions`;

        return method<OutcomeProjectTransaction>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(createOutcomeTransactionProject, request, {
        onSuccess: () => {
            queryClient.invalidateQueries('operational');

            navigate(-1);
            const statusEditUpdate = outcomeId ? 'atualizada' : 'criada';
            showToast(`Despesa ${statusEditUpdate} com sucesso`, 'success');
        },
        onError: (err) => {
            showToastErrors(err);
        }
    });
};

export default useCreateOrUpdateOutcometransactionProject;
