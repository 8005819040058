import React, { useMemo } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useGetFinancialMeasurementByPk from 'services/queries/measurement/use-get-financial-measurement-by-pk';
import { getFinancialMeasurementByPk } from './utils';
import useTheme from '@mui/material/styles/useTheme';
import { formatDate } from 'utils/date';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import { formatMoney } from 'utils/money';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import NewMeasurementPayments from './payments';

const secondaryButton = { classes: { child: 'text-secondary-100' } };

const MeasurementPage = () => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { measurementId } = useParams();

    const { data: measurement, isLoading, isError } = useGetFinancialMeasurementByPk(getFinancialMeasurementByPk(Number(measurementId)), Number(measurementId));

    const handleBack = () => navigate(-1);

    const payments = useMemo(
        () =>
            measurement?.provider_financial_measurement_has_payments?.map(({ provider_financial_payment }) => ({
                ...provider_financial_payment,
                providerName: provider_financial_payment.provider_name,
                providerDocument: provider_financial_payment.provider_document,
                invoiceNumber: provider_financial_payment.invoice_number,
                dueDate: provider_financial_payment.due_date
            })),
        [measurement]
    );

    return (
        <Modal
            closeOnClickOutside={true}
            contentClassnames="w-[800px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    #{measurement?.order_measurement?.toString().padStart(3, '0')}
                </Text>
            }
            headerRight={
                <>
                    <Tooltip placement="top" title="Gerar medição">
                        <IconButton component={Link} TouchRippleProps={secondaryButton} className="hover:bg-secondary-100 hover:bg-opacity-20 ml-2" to="novo">
                            <Icon name="ico-plus" color={palette.secondary[500]} width={12} height={12} />
                        </IconButton>
                    </Tooltip>
                </>
            }
            onClose={handleBack}>
            {isLoading ? (
                <Spinner color={palette.secondary[100]} fixed={false} size={20} />
            ) : isError || !measurement ? (
                <Empty title="Medição não encontrada" />
            ) : (
                <div className="px-6 pb-6">
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                        <div className="border border-base-300 p-4 rounded-[14px]">
                            <Text as="strong" className="text-secondary-500 block mb-2">
                                Data
                            </Text>
                            <div>
                                <Text as="span" className="text-base-500 block mb-1">
                                    Previsto: {formatDate(measurement.expected_date)}
                                </Text>
                                <Text as="span" className="text-base-500 block">
                                    Realizado: {formatDate(measurement.measurement_date) || '-'}
                                </Text>
                            </div>
                        </div>
                        <div className="border border-base-300 p-4 rounded-[14px]">
                            <Text as="strong" className="text-secondary-500 block mb-2">
                                Valor
                            </Text>
                            <div>
                                <Text as="span" className="text-base-500 block mb-1">
                                    Previsto: {formatMoney(measurement.predicted_value)}
                                </Text>
                                <Text as="span" className="text-base-500 block">
                                    Realizado: {formatMoney(measurement.measurement_value) || '-'}
                                </Text>
                            </div>
                        </div>
                        <div className="border border-base-300 p-4 rounded-[14px]">
                            <Text as="strong" className="text-secondary-500 block mb-2">
                                Andamento
                            </Text>
                            <div>
                                <Text as="span" className="text-base-500 block mb-1">
                                    Previsto: {measurement.predicted_percentage || 0}%
                                </Text>
                                <Text as="span" className="text-base-500 block">
                                    Realizado: {measurement.measurement_percentage ? `${measurement.measurement_percentage}%` : '-'}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6">
                        <Text as="h6" variant="h6" className="text-heading mb-2">
                            Pagamentos
                        </Text>
                        {Boolean(payments?.length) ? <NewMeasurementPayments payments={(payments as any) || []} /> : <Empty title="Nenhum pagamento encontrado para esta medição." />}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default MeasurementPage;
