import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';

export const getBudgetKeys = (budgetId?: string) => ['get', 'budget', budgetId, 'details'];
export const getBudgetVersionsKeys = (budgetId?: string, projectTypeId?: string) => ['get', 'budget', budgetId, 'versions', 'projectTypes', projectTypeId];
export const getBudgetVersionKeys = (budgetId?: string, projectTypeId?: string, versionId?: string) => ['get', 'budget', budgetId, 'versions', versionId, projectTypeId];

export const generateCommercialBudgetVersionsQuery = (budgetId?: string, projectTypeId?: string, isWithFalsyVersions?: boolean) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetCommercialBudgetVersions',
            items: {
                __aliasFor: 'budget_version',
                __args: {
                    where: {
                        budget_id: { _eq: budgetId },
                        ...(!isWithFalsyVersions && { status: { _eq: Status.Active } }),
                        ...(!projectTypeId && { active: { _eq: true } }),
                        ...(Boolean(projectTypeId) && { project_type_id: { _eq: projectTypeId } })
                    },
                    order_by: {
                        version: new EnumType('desc'),
                        ...(Boolean(isWithFalsyVersions) && {
                            status: new EnumType('desc')
                        })
                    }
                },
                id: true,
                budget_id: true,
                version: true,
                contract_value: true,
                percentage_margin: true,
                indirect_costs: true,
                direct_costs: true,
                percentage_commercial_expenses: true,
                percentage_cost_administrative: true,
                percentage_cost_tax: true,
                percentage_financial_expenses: true,
                footage: true,
                deadline: true,
                margin: true,
                active: true,
                status: true,
                project_type: {
                    title: true,
                    slug: true,
                    id: true
                }
            }
        }
    });
};

export const generateCommercialBudgetsByProjectIdQuery = (projectId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetCommercialBudgetsByProjectId',
                items: {
                    __aliasFor: 'budget',
                    __args: {
                        where: {
                            project_id: { _eq: projectId },
                            status: { _eq: Status.Active }
                        }
                    },
                    id: true,
                    code: true,
                    title: true,
                    customer: {
                        title: true
                    },
                    budget_has_types: {
                        project_type: {
                            title: true
                        }
                    },
                    budget_status: true,
                    budget_versions: {
                        __args: {
                            where: {
                                active: { _eq: true }
                            }
                        },
                        id: true,
                        version: true,
                        contract_value: true,
                        direct_costs: true,
                        indirect_costs: true,
                        active: true,
                        percentage_margin: true,
                        margin: true,
                        footage: true,
                        deadline: true,
                        project_type: {
                            id: true,
                            title: true
                        },
                        budget_id: true
                    }
                }
            }
        },
        { pretty: true }
    );
};
