import { useCalendarContext } from '../../context';
import { MONTH_HEIGHT, MONTH_WIDTH } from '../../utils';
import WeekDays from './week-days';

const CalendarHeaderMonths = () => {
    const { headerTexts, timelineVariant } = useCalendarContext();

    return (
        <>
            {headerTexts.map(({ legend: year, title: month, legendDays = [] }, index) => {
                const width = MONTH_WIDTH;

                return (
                    <div
                        className="flex flex-col items-center justify-center shrink border-b border-t border-slate-200"
                        key={[month, year, index, timelineVariant].join('-')}
                        style={{ width, maxWidth: width, flex: '1 0 auto', height: timelineVariant === 'WEEKS' ? MONTH_HEIGHT + 20 : MONTH_HEIGHT }}>
                        <p className="text-base-500 text-xs">{year}</p>
                        <p className="text-heading font-medium">{month}</p>
                        {timelineVariant === 'WEEKS' && <WeekDays items={legendDays} />}
                    </div>
                );
            })}
        </>
    );
};

export default CalendarHeaderMonths;
