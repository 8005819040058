import React from 'react';

// Dependecies
import { RouteObject } from 'react-router-dom';
import TableCell from '@mui/material/TableCell/TableCell';

// Components
import ListPage from 'pages/private/crud/list';
import CreateOrUpdateProfilePage from 'pages/private/profiles/create-or-update';

// Helpers
import dictionary from 'utils/dictionary';
import { CrudPageProps } from 'types/graphql';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';

const list: CrudPageProps = {
    graphql: {
        table: 'profile',
        searchableField: 'name',
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' }
            },
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'slug',
                label: dictionary.content.graphql['slug']
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                }
            }
        ],
        where: {
            system: { _eq: false }
        }
    },
    title: 'Perfis'
};

const profile: RouteObject[] = [
    {
        path: 'perfis',
        element: <Page code="perfis" permissions={[Rule.List]} Component={ListPage} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page code="perfis" permissions={[Rule.Create]} Component={CreateOrUpdateProfilePage} withModal={true} />
            },
            {
                path: 'editar/:profileId',
                element: <Page code="perfis" permissions={[Rule.Update]} Component={CreateOrUpdateProfilePage} withModal={true} />
            }
        ]
    }
];

export default profile;
