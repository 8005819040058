import React from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useForm } from 'react-hook-form';
import Button from 'components/core/button';
import { useNavigate, useParams } from 'react-router-dom';
import FormFactory from 'components/core/form/factory';
import { FormFactoryField } from 'types/form-factory';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import useCreateOrUpdate from 'services/queries/crud/use-create-or-update';
import { ApiPayload, CRUDDetails, GraphqlQueryPayload } from 'types/graphql';
import useGetDetails from 'services/queries/crud/use-get-details';

export type CreateOrUpdateProps = {
    fields: FormFactoryField[];
    modalClassName?: string;
    formClassName?: string;
    schema?: AnyObjectSchema;
    beforeSend?(data?: any): any;
    beforeSetInitialData?(data: any): any;
};

type Props = {
    title: string;
    form: CreateOrUpdateProps;
    graphql?: GraphqlQueryPayload;
    api?: ApiPayload;
    details?: CRUDDetails;
};

const CreateOrUpdate = ({ title, form, graphql, api, details }: Props) => {
    const { id } = useParams();

    const detailsConfig: CRUDDetails = {
        table: graphql?.table,
        fields: form?.fields?.map(({ name }) => name) || [],
        query: details?.query
    };

    const { data: detailsData } = useGetDetails(detailsConfig, id);

    const { fields, modalClassName = 'w-[600px]', formClassName, schema } = form || {};

    const methods = useForm<any>({
        mode: 'onSubmit',
        ...(schema && { resolver: yupResolver(schema) })
    });

    const navigate = useNavigate();

    const handleClose = () => navigate(-1);

    const { mutate, isLoading } = useCreateOrUpdate({
        graphql,
        api,
        id
    });

    const onSubmit = (data: any) => {
        if (form.beforeSend) {
            data = form.beforeSend(data);
        }

        mutate(data);
    };

    const { handleSubmit } = methods;

    return (
        <Modal
            contentClassnames={modalClassName}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            closeOnClickOutside={false}
            onClose={handleClose}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(onSubmit)}>
                <FormFactory beforeSetInitialData={form.beforeSetInitialData} fields={fields} className={formClassName} methods={methods} initialData={detailsData} />
                <div className="flex items-center">
                    <Button disabled={isLoading} loading={isLoading} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                        Enviar
                    </Button>
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                        Cancelar
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default CreateOrUpdate;
