import { useCallback, useMemo } from 'react';
import { HeaderText, TimelineVariant } from '..';
import { generateArray } from '../../utils';
import dayjs, { Dayjs } from 'dayjs';
import { months } from 'utils/date';

type CalendarHeadTextsProps = {
    timelineVariant: TimelineVariant;
};

export const useCalendarHeaderTexts = ({ timelineVariant }: CalendarHeadTextsProps) => {
    const currentYear = useMemo(() => new Date().getFullYear() || 2023, []);

    const getMonthsTexts = useCallback(({ years }: { years: number[] }) => {
        let result: HeaderText[] = [];

        years
            .map((year) =>
                months.map(
                    (month) =>
                        ({
                            legend: String(year),
                            title: month?.toUpperCase()
                        } as HeaderText)
                )
            )
            .forEach((year) => {
                result = [...result, ...year];
            });

        return result;
    }, []);

    const getWeeksTexts = useCallback(({ years }: { years: number[] }) => {
        type WeekItem = HeaderText & { daysLeftToTheNextSunday?: number };

        const result: Array<WeekItem> = [];

        const SUNDAY = 0;
        const TOTAL_DAYS_WEEK = 7;

        years.forEach((year) => {
            const firstDay = dayjs(`${year}-01-01`);

            const firstDayWeekDay = firstDay.get('day');

            const totalWeeksInYear = dayjs(`${year}-01-01`).isoWeeksInYear();

            // generate an empty array with the total weeks in that year
            generateArray(totalWeeksInYear).forEach((weekNumber) => {
                /**
                 * Handle first week missing days
                 */
                if (weekNumber === 0) {
                    const daysLeftInTheWeek = TOTAL_DAYS_WEEK - 1 - firstDayWeekDay;

                    const weekFirstDay = firstDayWeekDay === SUNDAY ? firstDay : firstDay.subtract(firstDayWeekDay - daysLeftInTheWeek, 'day');

                    const daysLeftInTheWeekArray = generateArray(TOTAL_DAYS_WEEK);

                    const legendDays: Dayjs[] = daysLeftInTheWeekArray.map((_, i) => {
                        return weekFirstDay.add(i, 'day');
                    });

                    const payload: WeekItem = {
                        legendDays,
                        title: weekFirstDay.format('MMMM')?.toUpperCase(),
                        legend: String(year)
                    };

                    return result.push(payload);
                }

                const lastWeekIndex = weekNumber - 1;

                const lastWeekLastDay = result[lastWeekIndex]?.legendDays?.at(-1);

                const weekFirstDay = lastWeekLastDay?.add(1, 'day');

                const legendDays: Dayjs[] = generateArray(TOTAL_DAYS_WEEK).map((_, i) => {
                    return weekFirstDay?.add(i, 'day')!;
                });

                const payload: WeekItem = {
                    legendDays,
                    title: weekFirstDay?.format('MMMM')?.toUpperCase()!,
                    legend: String(year)
                };

                return result.push(payload);
            });
        });

        return result;
    }, []);

    const headerTexts = useMemo<HeaderText[]>(() => {
        const years = [currentYear - 1, currentYear, currentYear + 1];

        switch (timelineVariant) {
            case 'MONTHS':
                return getMonthsTexts({ years });

            case 'WEEKS':
                return getWeeksTexts({ years });

            default:
                return [];
        }
    }, [currentYear, getMonthsTexts, getWeeksTexts, timelineVariant]);

    return headerTexts;
};
