import { PropsWithChildren, useMemo } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import useTheme from '@mui/material/styles/useTheme';
import Text from '../text';
import hexToRgba from 'utils/hex-to-rgba';
import { ColorVariant } from 'types/colors';

type BadgeProps = {
    variant: ColorVariant;
    className?: string;
};

const Badge = ({ className, children, variant }: PropsWithChildren<BadgeProps>) => {
    const { palette } = useTheme();
    const variantColors = get(palette, variant);

    const classes = classNames('px-3 py-2 rounded-lg inline-block', className);

    const bgColor = useMemo(() => (variant === 'grey' ? variantColors[200] : hexToRgba(variantColors[100], 0.3)), [variant, variantColors]);

    return (
        <span className={classes} style={{ backgroundColor: bgColor }}>
            <Text as="span" variant="body.medium.xs" className="block leading-[1]" style={{ color: variantColors[700] }}>
                {children}
            </Text>
        </span>
    );
};

export default Badge;
