import Select from 'components/core/form/select';
import { useListPageContext } from 'pages/private/crud/list/context';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useGetPersonContractStatusOptions from 'services/queries/person-contract-status/use-person-contract-status-options';
import _get from 'lodash/get';
import { PersonContractStatusTitle } from 'pages/private/people/details/tabs/contracts';
import Button from 'components/core/button';
import { useTableAdvancedSearch } from 'components/core/table/datatable/components/advanced-search';

type FormState = {
    contract_status_id: PersonContractStatusTitle;
};

type PersonAdvancedSearchProps = {
    setContractStatusId?: (value: number) => void;
};

const PersonAdvancedSearch = ({ setContractStatusId }: PersonAdvancedSearchProps) => {
    const { onClose } = useTableAdvancedSearch();
    const { params, onChangeParam } = useListPageContext();

    const { control, handleSubmit, reset } = useForm<FormState>({
        mode: 'onSubmit'
    });

    const { data: contractStatusOptions = [], isLoading: isLoadingStatus } = useGetPersonContractStatusOptions(true);

    useEffect(() => {
        const contract_status_id: PersonContractStatusTitle | undefined = _get(params, 'where.person_contract.contract_status.id._eq');

        if (contract_status_id) {
            reset({
                contract_status_id
            });
        }
    }, [params, reset]);

    const onSubmit = (data: FormState) => {
        // Sends the data to the list form
        onChangeParam({
            // sending name as "contracts" to make sure the query will not send any empty objects
            name: data?.contract_status_id === undefined ? 'person_contract' : 'person_contract.contract_status.id._eq',
            value: data?.contract_status_id
        });

        // Closes the filter
        onClose();
        setContractStatusId?.(data?.contract_status_id || null);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5">
                <Controller
                    name="contract_status_id"
                    control={control}
                    render={({ field }) => {
                        const value = contractStatusOptions.find((item) => (item as any)?.value === field.value);

                        return (
                            <Select
                                {...field}
                                isLoading={isLoadingStatus}
                                value={value}
                                options={contractStatusOptions}
                                label="Status do contrato"
                                placeholder="Selecione uma opção"
                                onChange={(option: any) => field.onChange(option?.value)}
                                isClearable={true}
                            />
                        );
                    }}
                />
            </div>

            <div className="flex">
                <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] ml-auto">
                    Filtrar
                </Button>
            </div>
        </form>
    );
};

export default PersonAdvancedSearch;
