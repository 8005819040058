import { useMemo, useRef } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Nav from 'components/nav';
import Text from 'components/core/text';

import theme from 'settings/theme';

import packageJSON from '../../../package.json';
import logoFull from 'assets/images/logo-full.svg';
import logoSmall from 'assets/images/logo-small.svg';
import useResponsive from 'hooks/responsive/use-responsive';
import useClickOutside from 'hooks/mouse/use-click-outside';

type SidebarProps = {
    isSidebarSmall: boolean;
    onClose?: () => void;
};

const Sidebar = ({ isSidebarSmall, onClose }: SidebarProps) => {
    const { isSmallerThenTabletLandscape } = useResponsive();
    const ref = useRef<HTMLDivElement>(null);

    useClickOutside(ref, () => {
        if (!isSmallerThenTabletLandscape) {
            return;
        }

        onClose?.();
    });

    const size = useMemo(() => (isSidebarSmall ? styles.containerSmall : styles.containerLarge), [isSidebarSmall]);
    const containerClasses = classNames(size, styles.container);

    const logo = useMemo(() => (isSidebarSmall ? logoSmall : logoFull), [isSidebarSmall]);

    const logoClasses = classNames(isSidebarSmall ? 'max-w-[36px]' : 'max-w-[150px] w-full');

    return (
        <aside className={isSmallerThenTabletLandscape ? styles.navWrapperMobile : ''} style={{ overflow: isSidebarSmall ? 'unset' : 'hidden' }}>
            <div ref={ref} className={`${containerClasses} ${isSmallerThenTabletLandscape && 'mobile-sidebar'}`}>
                <Link to="/app" className={styles.link}>
                    <img alt={theme.metadata.name} src={logo} className={logoClasses} />
                </Link>
                <nav className={styles.nav} style={{ overflow: isSidebarSmall ? 'unset' : 'auto' }}>
                    <Nav isSidebarSmall={isSidebarSmall} />
                </nav>
                {packageJSON && (
                    <footer className={styles.footer}>
                        <Text as="span" variant="body.regular.xs" className="text-base-400 italic">
                            Versão {packageJSON.version}
                        </Text>
                    </footer>
                )}
            </div>
        </aside>
    );
};

const styles = {
    container: 'flex flex-col h-full bg-base-100 border-r border-r-base-300 transition-all h-full max-h-full',
    containerSmall: 'max-w-[65px] w-[65px]',
    containerLarge: 'max-w-[270px] xs:max-w-xs w-[270px]',
    footer: 'border-t border-t-base-300 px-2 py-1 text-right',
    link: 'p-4 h-[70px] flex items-center',
    nav: 'flex-1 overflow-y-auto',
    navWrapperMobile: 'overflow-hidden w-full h-full z-[999] fixed left-0 top-0 bg-base-700/30'
};

export default Sidebar;
