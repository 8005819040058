// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import useToast from 'hooks/toast/use-toast';
import project, { getCisoKey, getCisosKey, updateProjectManagementInformationKey } from './graphql';

const useUpdateProjectManagementInformation = (projectId?: number, cisoId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async ({ createPayload, updatePayload }: any) => {
        return apiGraphQl<any>(project.updateProjectManagementInformation, {
            id: cisoId,
            createPayload,
            updatePayload
        });
    };

    return useMutation(updateProjectManagementInformationKey(projectId, cisoId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getCisoKey(cisoId));
            queryClient.invalidateQueries(getCisosKey(projectId));

            navigate(-1);

            showToast('CISO criado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar o CISO', 'danger');
        }
    });
};

export default useUpdateProjectManagementInformation;
