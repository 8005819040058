import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { ClassificationType } from 'types/models/classification';

export const classificationsQuery = (isComplementaryProject: boolean) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetClassificationsOptions',
                items: {
                    __aliasFor: 'classification',
                    __args: {
                        where: {
                            type: { _eq: isComplementaryProject ? ClassificationType.ScopeComplementaryProjects : ClassificationType.Scope },
                            classification_id: { _is_null: true },
                            status: { _eq: Status.Active }
                        },
                        order_by: { name: new EnumType('asc') }
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'name' },
                    has_third_part: true
                }
            }
        },
        { pretty: true }
    );
};
