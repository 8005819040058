import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import { createClassificationKey } from './graphql';
import { GraphqlPaginationVariables } from 'types/graphql';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

import apiGraphQl from 'services/graphql';
import { CreateClassificationPayload } from 'types/models/configurations-classifications';
import { getAllKey } from 'services/queries/crud/graphql';

const useCreateOrUpdateClassification = (id: string | undefined, params: GraphqlPaginationVariables<CreateClassificationPayload>, query: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();

    const fetcher = async (payload: CreateClassificationPayload) => {
        const data = {
            ...(id && { id }),
            payload
        };
        return await apiGraphQl(query, data);
    };

    return useMutation(createClassificationKey, fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('classification', params));

            navigate(-1);
            const statusEditUpdate = id ? 'atualizada' : 'criada';
            showToast(`Classificação ${statusEditUpdate} com sucesso`, 'success');
        },
        onError: (err) => {
            showToastErrors(err);
        }
    });
};

export default useCreateOrUpdateClassification;
