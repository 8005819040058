// Dependencies
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { ProjectStatus } from 'types/models/project';
import { releaseTapKey, getProjectKey } from './graphql';

type Payload = {
    status: ProjectStatus['slug'];
};

const useReleaseTap = (projectId: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const releaseTap = async (payload: Payload) => api.patch(`/projects/${projectId}/status`, payload);

    return useMutation(releaseTapKey(projectId), releaseTap, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectKey(projectId));

            showToast('TAP liberado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao liberar o TAP', 'danger');
        }
    });
};

export default useReleaseTap;
