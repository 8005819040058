// Helpers
import { BaseModel } from './base';
import { Address } from './address';
import { Timeline, TimelineBudget } from './timeline';
import { Account } from './account';
import { BusinessType } from './business-type';
import { Person } from './person';
import { File, FileApiModel } from './file';
import { CamelCase } from 'types/utils';
import { NumberFormatValues } from 'react-number-format';

export type Provider = {
    account_id: number;
    type_business_id: number;
    title: string;
    juridic_name: string;
    juridic_fantasy_name: string;
    juridic_document: string;
    address: Partial<Address>;
    contacts: Partial<ProviderContact>[];
    timelines: Partial<Timeline>[];
    account: Partial<Account>;
    type_business: Partial<BusinessType>;
    image: Partial<FileApiModel>;
} & BaseModel;

export type ProviderContact = {
    provider_id: number;
    provider: Partial<Provider>;
} & BaseModel &
    Omit<Person, 'account' | 'address' | 'contracts' | 'projects' | 'user'>;

export type ProviderFinancialMeasurement = {
    provider_financial_setup_id: number;
    order_measurement: number;
    order: number;
    expected_date: string;
    measurement_date: string;
    predicted_percentage: number;
    measurement_percentage: number;
    predicted_value: number;
    measurement_value: number;
    measurementValue: number;
    contractBalance: number;
    provider_financial_measurement_has_payments: Array<{ provider_financial_payment: Partial<ProviderFinancialPayment> }>;
    provider_financial_setup: Partial<ProviderFinancialSetup>;
} & BaseModel;

export type ProviderFinancialSetup = {
    timeline_budget_id: number;
    with_technical_retention: boolean;
    period: number;
    percentage_entry_installment: number;
    value_entry_installment: number;
    measured_value: number;
    measured_value_entry_installment: number;
    provider_financial_measurements: Partial<ProviderFinancialMeasurement>[];
    measurements: Partial<ProviderFinancialMeasurement>[];
    timeline_budget: TimelineBudget;
    provider_financial_payments: Partial<ProviderFinancialPayment>[];
    payments: Partial<ProviderFinancialPayment>[];
    provider_financial_measurements_aggregate: {
        aggregate: { count: number };
    };
    budget: TimelineBudget;
} & BaseModel;

export type ProviderFinancialPayment = {
    type: ProviderFinancialPaymentType;
    value: number;
    percentage: number;
    date: string;
    provider_name: string;
    provider_document: string;
    invoice_number: string;
    due_date: string;
    issue_date: string;
    receipt_date: string;
    sent_date: string;
    forecast_date: string;
    observations: string;
    provider_financial_setup_id: number;
    provider_financial_measurement_has_payments: Partial<{
        provider_financial_measurement_id: number;
        provider_financial_payment_id: number;
        provider_financial_measurement: ProviderFinancialMeasurement;
        provider_financial_payment: ProviderFinancialPayment;
    }>;
    provider_financial_payment_files: Partial<File>[];
    files: Array<
        Partial<{
            file: CamelCase<File>;
            id: number;
            name: string;
            type: number;
        }>
    >;
    provider_financial_setup: Partial<ProviderFinancialSetup>;
} & BaseModel;

export enum ProviderFinancialPaymentType {
    Start = 1,
    DirectBilling
}

export type ProviderFinancialSetupPayload = {
    withTechnicalRetention: boolean;
    period: number;
    budget: number;
    measurements: Array<{
        order: number;
        expectedDate: string;
        predictedPercentage: number;
        predictedValue?: NumberFormatValues;
    }>;
    payments: Array<{
        type: ProviderFinancialPaymentType;
        value?: NumberFormatValues;
        percentage: number;
        date: string;
    }>;
};

export type MeasurementPaymentPayload = {
    type: ProviderFinancialPaymentType;
    value: string;
    providerName: string;
    providerDocument: string;
    invoiceNumber: string;
    dueDate: string;
    issueDate: string;
    receiptDate: string;
    sentDate: string;
    forecastDate: string;
    observations: string;
    files: Array<{ file: number }>;
};

export type NewMeasurementPayload = {
    measurementDate: string;
    measurementResidue: NumberFormatValues;
    justification?: string;
};

export type MeasurementStats = {
    budget: string;
    predicted: {
        percentage: number;
        value: number;
        date: string;
        accumulated: {
            value: number;
            percentage: number;
        };
    };
    realized: {
        percentage: number;
        value: number;
    };
};
