import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { generateCommercialBudgetsByProjectIdQuery, getBudgetKeys } from './keys';
import apiGraphQl from 'services/graphql';

const useGetCommercialBudget = <T>(budgetId?: string, projectId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const request = async () => {
        const method = !!projectId ? apiGraphQl : api.get;
        const queryOrEndpoint = !!projectId ? generateCommercialBudgetsByProjectIdQuery(projectId) : `budgets/${budgetId}`;

        const response = await method<T>(queryOrEndpoint);

        return (response?.data || response) as T;
    };

    return useQuery(getBudgetKeys(budgetId), request, {
        enabled: !!budgetId || !!projectId,
        onError: () => {
            if (!!budgetId) {
                navigate(-1);
            }

            showToast('Ocorreu um problema ao buscar os detalhes dessa FP! Por favor, tente novamente.', 'danger');
        }
    });
};

export default useGetCommercialBudget;
