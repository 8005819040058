import React from 'react';
import Text from 'components/core/text';
import Select from 'components/core/form/select';
import { Option } from 'types/general';

type ReportsHeaderProps = {
    weeks: Option<number | undefined>[];
    weekHook: [Option<number | undefined>, React.Dispatch<React.SetStateAction<Option<number | undefined>>>];
};

const ReportsHeader = ({ weekHook, weeks }: ReportsHeaderProps) => {
    const [week, setWeek] = weekHook;

    return (
        <div className="flex flex-wrap gap-4 items-center justify-between mb-8">
            <Text as="h6" variant="h6" className="text-heading">
                Relatórios
            </Text>
            <Select value={week} options={weeks} placeholder="Selecione uma semana" onChange={(option: any) => setWeek(option)} size="small" parentClassName="w-full sm:w-80" />
        </div>
    );
};

export default ReportsHeader;
