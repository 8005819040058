import React, { useEffect } from 'react';

// Dependencies
import useTheme from '@mui/material/styles/useTheme';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { array, date, number, object, string } from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

// Components
import Empty from 'components/empty';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import Editor from 'components/core/form/editor';
import Select from 'components/core/form/select';
import DatePicker from 'components/core/datepicker';
import RemoveButton from 'components/buttons/remove';

// Helpers
import { Option } from 'types/general';
import dictionary from 'utils/dictionary';

type CreateOrUpdateTasksProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    people: Option[];
    subjects: Option[];
    defaultValues: TasksPayload;
    onSubmit: (data: TasksPayload) => void;
};

export type TaskPayload = Partial<{
    title: string;
    timeline: number;
    task: number;
    proposalType: number;
    description: string;
    person: number;
    deadline: string;
    projectMeetingRecordSubject: number;
    taskId: number;
    taskStatus: number;
    order: number;
}>;

export type TasksPayload = {
    tasks: TaskPayload[];
};

const CreateOrUpdateTasks = ({ defaultValues, isBuilding, isSubmitting, people, subjects, onSubmit }: CreateOrUpdateTasksProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { meetingRecordId } = useParams();

    const schema = object({
        tasks: array(
            object({
                title: string().required(dictionary.validation.required),
                description: string().required(dictionary.validation.required),
                person: number().required(dictionary.validation.required),
                order: number().required(dictionary.validation.required),
                deadline: date().required(dictionary.validation.required),
                proposalType: number(), // TODO: validar se é obrigatório quando for cadastrar tarefa no project type
                ...(meetingRecordId && { projectMeetingRecordSubject: number().required(dictionary.validation.required) })
            })
        )
    });

    const { control, formState, handleSubmit, reset } = useForm<TasksPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(schema),
        defaultValues
    });

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const { fields, append, remove } = useFieldArray({ name: 'tasks', control });

    const handleAddTask = () => append({ title: '', description: '', order: fields.length + 1 });

    const handleDeleteTask = (index: number) => () => remove(index);

    const handleClose = () => navigate(-1);

    return (
        <Modal contentClassnames="w-[700px] max-w-[1200px] mx-5" closeOnClickOutside={false} onClose={handleClose}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        Tarefas
                    </Text>
                    {Boolean(fields.length) ? (
                        fields.map((item, index) => {
                            return (
                                <div className="border border-base-300 p-4 rounded-2xl mb-2 group relative" key={item.id}>
                                    <RemoveButton onClick={handleDeleteTask(index)} />
                                    <Controller
                                        name={`tasks.${index}.title`}
                                        control={control}
                                        render={({ field }) => <Input {...field} error={(formState as any).errors.tasks?.[index]?.title?.message} label="Título" parentClassName="mb-4" />}
                                    />
                                    <Controller
                                        name={`tasks.${index}.description`}
                                        control={control}
                                        render={({ field }) => <Editor {...field} label="Descrição" error={(formState as any).errors.tasks?.[index]?.description?.message} parentClasses="mb-4" />}
                                    />
                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                                        <Controller
                                            name={`tasks.${index}.projectMeetingRecordSubject`}
                                            control={control}
                                            render={({ field }) => {
                                                const value = subjects.find((item) => item.value === field.value);

                                                return (
                                                    <Select
                                                        {...field}
                                                        value={value}
                                                        options={subjects}
                                                        label="Tópico"
                                                        placeholder="Selecione uma opção"
                                                        error={(formState as any).errors.tasks?.[index]?.projectMeetingRecordSubject?.message}
                                                        onChange={(option: any) => field.onChange(option.value)}
                                                    />
                                                );
                                            }}
                                        />
                                        <Controller
                                            name={`tasks.${index}.person`}
                                            control={control}
                                            render={({ field }) => {
                                                const value = people.find((item) => item.value === field.value);

                                                return (
                                                    <Select
                                                        {...field}
                                                        value={value}
                                                        options={people}
                                                        label="Responsável"
                                                        placeholder="Selecione uma opção"
                                                        error={(formState as any).errors.tasks?.[index]?.person?.message}
                                                        onChange={(option: any) => field.onChange(option.value)}
                                                    />
                                                );
                                            }}
                                        />
                                        <Controller
                                            name={`tasks.${index}.deadline`}
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    label="Data de entrega"
                                                    placeholderText="Selecione uma data"
                                                    error={(formState as any).errors.tasks?.[index]?.deadline?.message}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="border border-base-300 p-4 rounded-2xl mt-4">
                            <Empty title="Nenhuma tarefa adicionada até o momento" />
                        </div>
                    )}
                    <div className="py-4">
                        <AddButton onClick={handleAddTask}>Mais tarefas</AddButton>
                    </div>
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateTasks;
