import dayjs from 'dayjs';
import { Fragment } from 'react';
import { People, Week } from '../types';
import { DAY_WIDTH, generateArray, PERSON_HEIGHT, WEEK_WIDTH } from '../utils';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { formatDate, weekendDays } from 'utils/date';

type PeopleMapContentProps = {
    weeks: Week[];
    people: People[];
};

const Content = ({ weeks, people }: PeopleMapContentProps) => {
    const lineWidth = weeks.length * WEEK_WIDTH;

    return (
        <>
            {people.map(({ id, agenda }) => {
                return (
                    <>
                        {!agenda?.length && (
                            <div className="mt-[-1px] flex divide-x divide-slate-200 border-b border-slate-200 last:border-b-0" key={`line_person_${id}`} style={{ width: lineWidth }}>
                                {weeks.map(({ startDate }, i) => {
                                    const days = generateArray(5).map((day) => dayjs(startDate).add(day, 'day').format('DD/MM'));

                                    return (
                                        <Fragment key={`${startDate}_${i}`}>
                                            {days.map((day) => {
                                                return <div key={`${day}_${i}`} style={{ width: DAY_WIDTH, height: PERSON_HEIGHT }} className="flex-auto relative"></div>;
                                            })}
                                        </Fragment>
                                    );
                                })}
                            </div>
                        )}

                        {!!agenda?.length &&
                            agenda.map((ag) => (
                                <div className="mt-[-1px] flex divide-x divide-slate-200 w-full border-b border-slate-200 last:border-b-0" key={`line_person_${id}`} style={{ width: lineWidth }}>
                                    {weeks.map(({ startDate }, i) => {
                                        const days = generateArray(5).map((day) => dayjs(startDate).add(day, 'day').format('DD/MM'));

                                        return (
                                            <Fragment key={`${startDate}_${i}`}>
                                                {days.map((day) => {
                                                    const tasks = ag.filter((agendaItem) => formatDate(agendaItem.startDate, 'DD/MM') === day);

                                                    return (
                                                        <div key={`${day}_${i}`} style={{ width: DAY_WIDTH, height: PERSON_HEIGHT }} className="flex-auto relative">
                                                            {tasks.map((task) => {
                                                                const diffDays = dayjs(task.endDate).diff(task.startDate, 'day');

                                                                const totalBusinessDays =
                                                                    generateArray(isNaN(diffDays) ? 0 : diffDays).filter(
                                                                        (dayIndex) => !weekendDays.includes(dayjs(task.startDate).add(dayIndex, 'day').day())
                                                                    )?.length + 1;

                                                                const width = DAY_WIDTH * (totalBusinessDays > 0 ? totalBusinessDays : 0) - 4;

                                                                return (
                                                                    <Tooltip placement="top" title={task.title} disableFocusListener={true} key={task.id}>
                                                                        <div
                                                                            className="truncate absolute rounded-md border z-10 top-[4px] left-[2px] flex items-center pl-2 uppercase text-base-600"
                                                                            key={task.id}
                                                                            style={{ width: width, background: task.color, height: PERSON_HEIGHT - 8 }}>
                                                                            {task.title}
                                                                        </div>
                                                                    </Tooltip>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            ))}
                    </>
                );
            })}
        </>
    );
};

export default Content;
