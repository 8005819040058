import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableRow from '@mui/material/TableRow/TableRow';
import ExternalButton from 'components/buttons/external';
import Text from 'components/core/text';
import Empty from 'components/empty';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGetProjectProposalType from 'services/queries/projects/use-get-project-proposal-type';
import { ProjectManagementInformation, ProjectTypeManagementInformation } from 'types/models/project';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';

type CisoDetailsProps = {
    ciso?: Partial<ProjectManagementInformation>;
    withActions?: boolean;
    onSetSignpostUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
    projectProposalTypeId?: string;
};

const CisoDetails = ({ ciso, withActions, onSetSignpostUrl, projectProposalTypeId }: CisoDetailsProps) => {
    const navigate = useNavigate();
    const { projectProposalTypeId: projectProposalTypeIdParams, cisoId } = useParams();

    const { data: projectProposalTypeData } = useGetProjectProposalType(+projectProposalTypeIdParams! || +projectProposalTypeId!);

    const handleToggleSignpostModal = (value?: string) => () => onSetSignpostUrl(value!);

    const manager = useMemo(() => {
        if (projectProposalTypeData?.project_type_person_resources?.length) {
            return projectProposalTypeData.project_type_person_resources.find((resource) => resource.type_management_information === ProjectTypeManagementInformation.Manager);
        }
        return null;
    }, [projectProposalTypeData?.project_type_person_resources]);

    const residents = useMemo(() => {
        if (projectProposalTypeData?.project_type_person_resources?.length) {
            return projectProposalTypeData.project_type_person_resources.filter((resource) => resource.type_management_information === ProjectTypeManagementInformation.Resident);
        }
        return null;
    }, [projectProposalTypeData?.project_type_person_resources]);

    return (
        <>
            <div className="border-b border-b-base-300 pb-4 mb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                    <Text as="label" variant="h6" className="text-heading block mb-3">
                        Informações
                    </Text>
                    <Text as="p" className="text-base-500 mb-2">
                        Data inicial:{' '}
                        <Text as="span" className="text-heading">
                            {formatDate(ciso?.management_start_date) || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500 mb-2">
                        Data final:{' '}
                        <Text as="span" className="text-heading">
                            {formatDate(ciso?.management_end_date) || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500 mb-2">
                        Duração:{' '}
                        <Text as="span" className="text-heading">
                            {ciso?.duration} dia(s)
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500 mb-2">
                        Versão atual do CISO:{' '}
                        <Text as="span" className="text-heading">
                            {ciso?.revision}ª
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500 mb-2">
                        Data inicial do projeto:{' '}
                        <Text as="span" className="text-heading">
                            {formatDate(projectProposalTypeData?.start_date) || '-'}
                        </Text>
                    </Text>
                    <Text as="p" className="text-base-500 mb-2">
                        Data final do projeto:{' '}
                        <Text as="span" className="text-heading">
                            {formatDate(projectProposalTypeData?.end_date) || '-'}
                        </Text>
                    </Text>

                    <Text as="p" className="text-base-500 mb-2">
                        Gerente:{' '}
                        {!!manager ? (
                            <Text as="span" className="text-heading">
                                {manager.person?.name}
                            </Text>
                        ) : (
                            <Text as="span" className="text-heading">
                                -
                            </Text>
                        )}
                    </Text>
                </div>
                <div>
                    <Text as="label" variant="h6" className="text-heading block mb-3">
                        Providências
                    </Text>
                    {Boolean(ciso?.project_management_information_has_measures?.length) ? (
                        ciso?.project_management_information_has_measures?.map((item) => {
                            return (
                                <Text key={item.project_measure.id} as="p" className="text-base-500 mb-2">
                                    {item.project_measure.title}
                                </Text>
                            );
                        })
                    ) : (
                        <Text as="span" className="text-heading">
                            -
                        </Text>
                    )}
                </div>
            </div>
            <div className="border-b border-b-base-300 pb-4 mb-4">
                <Text as="label" variant="h6" className="text-heading block mb-3">
                    Residentes
                </Text>
                {Boolean(residents?.length) ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {residents?.map((resident) => (
                            <div key={resident.id} className="mb-4">
                                <Text as="p" className="text-base-500">
                                    Nome:{' '}
                                    <Text as="span" className="text-heading">
                                        {resident.person?.name}
                                    </Text>
                                </Text>
                                <Text as="p" className="text-base-500">
                                    Data início do contrato:{' '}
                                    <Text as="span" className="text-heading">
                                        {formatDate(resident.contract_start_date) || '-'}
                                    </Text>
                                </Text>
                                <Text as="p" className="text-base-500">
                                    Data fim do contrato:{' '}
                                    <Text as="span" className="text-heading">
                                        {formatDate(resident.contract_end_date) || '-'}
                                    </Text>
                                </Text>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Empty title="Nenhum residente cadastrado até o momento." />
                )}
            </div>
            <div>
                <Text as="label" variant="h6" className="text-heading block mb-3">
                    Placas
                </Text>
                {Boolean(ciso?.project?.project_signposts?.length) ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Nome
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Quantidade
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Tipo
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Dimensões
                                        </Text>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ciso?.project?.project_signposts?.map((signpost, ix) => {
                                    return (
                                        <TableRow key={ix} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {signpost.signpost?.title || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {signpost.quantity || 0}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {Object.values(dictionary.content.signpostTypes)[(signpost.type || 0) - 1] || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-base-500">
                                                    {Object.values(dictionary.content.signpostDimensions)[(signpost.dimension || 0) - 1] || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <ExternalButton onClick={handleToggleSignpostModal(signpost.signpost?.url)}>Visualizar</ExternalButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Empty
                        title="Nenhuma placa configurada até o momento."
                        buttonLabel={withActions && Boolean(ciso?.signpost_class_id) ? 'Configurar' : undefined}
                        onClick={
                            withActions && Boolean(ciso?.signpost_class_id)
                                ? () => navigate(`${cisoId ? '../' : ''}placas/${ciso?.signpost_class_id}/configurar?projectId=${ciso?.project_id}`)
                                : undefined
                        }
                    />
                )}
            </div>
            {Boolean(ciso?.observations) && (
                <div className="border-t border-t-base-300 pt-4 mt-4">
                    <Text as="label" variant="h6" className="text-heading block mb-3">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: ciso?.observations! }} />
                </div>
            )}
        </>
    );
};

export default CisoDetails;
