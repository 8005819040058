import React, { useMemo } from 'react';

// Dependencies
import classNames from 'classnames';
import { Accept, DropzoneInputProps, useDropzone } from 'react-dropzone';

// Components
import Uploader from 'components/uploader';
import ErrorMessage from 'components/error/message';

type DropzoneProps = {
    onUploadFiles: (files: File[]) => void;
    accept?: Accept;
    containerClasses?: string;
    disabled?: boolean;
    error?: string;
    multiple?: boolean;
};

const Dropzone = ({ accept, containerClasses, disabled, error, multiple = false, onUploadFiles }: DropzoneProps) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onUploadFiles,
        accept
    });

    const dropzoneConfig = useMemo<DropzoneInputProps>(
        () => ({
            ...getInputProps(),
            disabled,
            multiple
        }),
        [getInputProps, disabled, multiple]
    );

    const containerStyles = classNames(
        'bg-base-200 border-base-400 border border-dashed rounded-[14px] w-full h-[120px] flex flex-col items-center justify-center hover:bg-base-200 hover:bg-opacity-50',
        isDragActive ? 'opacity-50 dropzone-scale' : '',
        disabled && 'cursor-no-drop opacity-50',
        containerClasses
    );

    return (
        <>
            <div className={containerStyles} {...getRootProps()}>
                <Uploader dropzoneProps={dropzoneConfig} />
            </div>
            <ErrorMessage visible={Boolean(error)}>{error}</ErrorMessage>
        </>
    );
};

export default Dropzone;
