import useTheme from '@mui/material/styles/useTheme';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import { formatDate } from 'utils/date';
import Badge from 'components/core/badge';
import { Task } from 'types/models/task';
import { ColorVariant } from 'types/colors';
import Avatar from '@mui/material/Avatar/Avatar';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { generateFileUrl } from 'utils/file';

type TaskMetaProps = {
    task: Partial<Task>;
    withCounts?: boolean;
    withProgress: boolean;
};

export const getTaskVariant = (status: Task['taskStatus']['slug']): ColorVariant => {
    const statuses: any = {
        done: 'success',
        stopped: 'grey',
        partial: 'warning',
        pending: 'info',
        processing: 'grey'
    };

    return statuses[status!] ?? 'error';
};

const TaskMeta = ({ task, withCounts, withProgress }: TaskMetaProps) => {
    const { palette } = useTheme();

    return (
        <div className="flex items-center">
            {Boolean(task.provider) && (
                <Tooltip placement="top" title={task?.provider?.title || ''}>
                    <Avatar
                        src={generateFileUrl(task?.provider?.image?.filename, task?.provider?.image?.path)}
                        className="mr-4"
                        sx={{ width: 30, height: 30, overflow: 'hidden', borderRadius: '50% !important' }}>
                        {task?.provider?.image?.url ? <img src={task?.provider?.image?.url} className="w-full h-full" /> : task?.provider?.title?.charAt(0)}
                    </Avatar>
                </Tooltip>
            )}
            {Boolean(task.person) && (
                <Tooltip placement="top" title={task.person?.name || ''}>
                    <Avatar className="mr-4" sx={{ width: 30, height: 30, overflow: 'hidden' }}>
                        {task.person?.profileImage?.url ? <img src={task.person?.profileImage?.url} className="w-full h-full" /> : task.person?.name?.charAt(0)}
                    </Avatar>
                </Tooltip>
            )}
            {withProgress && (
                <div className="flex items-center mr-4">
                    <Icon name="ico-sort" color={palette.grey[500]} className="mr-1" />
                    <Text as="p" variant="body.regular.sm" className="text-heading text-right">
                        {task.progressPercentage || 0}%
                    </Text>
                </div>
            )}
            {withCounts && (
                <div className="flex items-center mr-4">
                    <Icon name="ico-chat" width={16} height={16} color={palette.grey[500]} className="mr-1" />
                    <Text as="p" variant="body.regular.sm" className="text-heading text-right">
                        {task.comments?.length || 0}
                    </Text>
                </div>
            )}
            <div className="flex items-center">
                <Icon name="ico-calendar" color={palette.grey[500]} className="mr-1" />
                <Text as="p" variant="body.regular.sm" className="text-heading text-right w-[65px]">
                    {formatDate(task.endDate, 'DD/MM/YY')}
                </Text>
            </div>
            {!!task.taskStatus?.title && !!task.endDate && !!task.taskStatus?.slug && (
                <Badge variant={getTaskVariant(task.taskStatus.slug)} className="ml-5 w-[137px] text-center">
                    {task.taskStatus?.title}
                </Badge>
            )}
        </div>
    );
};

export default TaskMeta;
