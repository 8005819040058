import { useMemo } from 'react';

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';

import { CreateClassificationPayload } from 'types/models/configurations-classifications';
import useGetClassificationId from 'services/queries/configs-classifications/use-get-classification-by-id';
import useCreateOrUpdateClassification from 'services/queries/configs-classifications/use-create-update-classification-asset';
import { createOrUpdateClassificationMutation } from '../../utils/utils';
import CreateOrUpdateClassification from './create-or-update';
import { ClassificationType } from 'types/models/classification';
import omit from 'lodash/omit';

const CreateOrUpdateConfigClassificationPage = ({ title, type }: CrudPageProps & { type: ClassificationType }) => {
    const { configClassificationId } = useParams();

    const navigate = useNavigate();

    const params = useOutletContext<GraphqlPaginationVariables<CreateClassificationPayload>>();

    const {
        mutateAsync: mutateCreateOrUpdateClassification,
        isLoading: isSubmitting,
        error: errorCreateUpdate
    } = useCreateOrUpdateClassification(configClassificationId, params, createOrUpdateClassificationMutation(configClassificationId));

    const { data: classificationDetails, isLoading: isLoadingDetails, error: errorGetClassificationId } = useGetClassificationId(configClassificationId);

    if (classificationDetails?.system) {
        navigate(-1);
    }

    const error = useMemo(() => errorCreateUpdate || errorGetClassificationId, [errorCreateUpdate, errorGetClassificationId]);

    const isBuilding = useMemo(() => isSubmitting || isLoadingDetails, [isSubmitting, isLoadingDetails]);

    const handleSubmit = async (data: CreateClassificationPayload) => {
        try {
            const newData = omit(data, ['classification', 'project_service']);

            if (newData) {
                await mutateCreateOrUpdateClassification({ ...newData, type } as CreateClassificationPayload);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return <CreateOrUpdateClassification isBuilding={isBuilding} isSubmitting={isSubmitting} onSubmit={handleSubmit} defaultValues={classificationDetails} error={error} title={title || ''} />;
};

export default CreateOrUpdateConfigClassificationPage;
