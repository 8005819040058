import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import { Controller, useFormContext } from 'react-hook-form';
import { classesTableRowHover } from '../utils';
import { generateTableClasses } from './update';
import { formatMoney } from 'utils/money';
import CurrencyInput from 'components/core/form/currency';
import get from 'lodash/get';
import { NumberFormatValues } from 'react-number-format/types/types';
import { Employee as EmployeeType, UpdateBudgetPayload } from 'types/models/commercial';

type EmployeeProps = {
    title: string;
    parentFieldName: 'manager' | 'coordinator';
    isDisabled: boolean;
};

const Employee = ({ title, parentFieldName, isDisabled }: EmployeeProps) => {
    const { formState, control, watch } = useFormContext<UpdateBudgetPayload>();

    const getName = (fieldName: keyof EmployeeType) => `${parentFieldName}.${fieldName}` as any;
    const getError = (name: keyof EmployeeType) => get(formState.errors, `${getName(name)}.message`);

    const employee = watch(parentFieldName);

    const rowEmployeeTotal = (employee?.hourMonth || 0) * ((employee?.hourValue || 0) + (employee?.licenseValue || 0));

    const handleChangeValue = (onChange: (...event: any[]) => void) => (value: React.ChangeEvent<HTMLInputElement> | NumberFormatValues) => {
        if ('target' in value) {
            return onChange(value.target.value);
        }

        return onChange(value.floatValue);
    };

    return (
        <TableRow classes={classesTableRowHover}>
            <TableCell className={generateTableClasses(false)}>{title}</TableCell>
            <TableCell className="!text-xs !text-base-500">{formatMoney(rowEmployeeTotal)}</TableCell>
            <TableCell className="!text-xs !text-base-500">
                <Controller
                    name={getName('hourMonth')}
                    control={control}
                    render={({ field }) => (
                        <CurrencyInput
                            ref={field.ref}
                            name={field.name}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={field.value}
                            placeholder="EX: 00,00"
                            label="HH/mês"
                            parentClassName="w-[130px]"
                            className="w-full !text-xs"
                            labelClassName="!text-xs"
                            onValueChange={handleChangeValue(field.onChange)}
                            disabled={isDisabled}
                            error={getError('hourMonth')}
                        />
                    )}
                />
            </TableCell>
            <TableCell className="!text-xs !text-base-500">
                <Controller
                    name={getName('hourValue')}
                    control={control}
                    render={({ field }) => (
                        <CurrencyInput
                            ref={field.ref}
                            name={field.name}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={field.value}
                            placeholder="EX: 00,00"
                            label="Valor Hora"
                            parentClassName="w-[130px]"
                            className="w-full !text-xs"
                            labelClassName="!text-xs"
                            onValueChange={handleChangeValue(field.onChange)}
                            disabled={isDisabled}
                            error={getError('hourValue')}
                        />
                    )}
                />
            </TableCell>
            <TableCell colSpan={2} className="!text-xs !text-base-500">
                <Controller
                    name={getName('licenseValue')}
                    control={control}
                    render={({ field }) => (
                        <CurrencyInput
                            name={field.name}
                            ref={field.ref}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={field.value || 0}
                            placeholder="EX: 00,00"
                            label="Licença"
                            parentClassName="w-[130px]"
                            className="w-full !text-xs"
                            labelClassName="!text-xs"
                            onValueChange={handleChangeValue(field.onChange)}
                            disabled={isDisabled}
                            error={getError('licenseValue')}
                        />
                    )}
                />
            </TableCell>
        </TableRow>
    );
};

export default Employee;
