// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { Option } from 'types/general';
import { Pagination } from 'types/pagination';
import customers, { getAllCustomerContactsOptionsKey } from './graphql';

const useGetCustomerContactsOptions = (customer_id?: number) => {
    const getAllCustomerContactsOptions = () =>
        apiGraphQl<Pagination<Option>>(customers.getAllCustomerContactsOptions, { customer_id }).then((data) =>
            data.items.map((item) => ({
                value: (item as any).value,
                label: (item as any).person.name
            }))
        );

    return useQuery(getAllCustomerContactsOptionsKey(customer_id), getAllCustomerContactsOptions, { enabled: Boolean(customer_id) });
};

export default useGetCustomerContactsOptions;
