import React, { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormFactoryField } from 'types/form-factory';
import FormFactoryItem from './item';
import _get from 'lodash/get';
import classNames from 'classnames';
import dictionary from 'utils/dictionary';

export type Props = {
    fields: FormFactoryField[];
    methods: UseFormReturn<any, any>;
    className?: string;
    initialData?: any;
    beforeSetInitialData?(data: any): any;
};

const FormFactory = ({ fields = [], methods, className, initialData, beforeSetInitialData }: Props) => {
    const { control, formState, reset } = methods || {};

    useEffect(() => {
        if (!!initialData) {
            reset(beforeSetInitialData ? beforeSetInitialData(initialData) : initialData);
        }
    }, [initialData, reset, beforeSetInitialData]);

    const getError = (name: string) => _get(formState.errors, `${name}.message`);
    const getLabel = (name: string, label?: string) => label || _get(dictionary.content, name, _get(dictionary.content.graphql, name, name));

    const formClasses = classNames('mb-6 gap-4 grid grid-cols-3', className);

    return (
        <div className={formClasses}>
            {fields.map((item, index) => (
                <Controller
                    name={item.name}
                    control={control}
                    key={`${item.name}_${index}`}
                    render={({ field }) => <FormFactoryItem label={getLabel(item.name, item.label)} error={getError(item.name)} {...item} {...field} />}
                />
            ))}
        </div>
    );
};

export default FormFactory;
