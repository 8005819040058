import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getFinancialMeasurementKey } from './graphql';
import { ProviderFinancialMeasurement } from 'types/models/provider';

type Response = {
    provider_financial_measurement_by_pk: Partial<ProviderFinancialMeasurement>;
};

const useGetFinancialMeasurementByPk = (query: string, measurementId?: number) => {
    const fetcher = async () => apiGraphQl<Response>(query).then(({ provider_financial_measurement_by_pk }) => provider_financial_measurement_by_pk);

    return useQuery(getFinancialMeasurementKey(measurementId), fetcher, { enabled: Boolean(measurementId) });
};

export default useGetFinancialMeasurementByPk;
