import api from 'services/api';
import { useMutation, useQueryClient } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import { getTimelineKey } from '../projects/graphql';

const useFinalizeTimelineCreation = (projectProposalTypeId: number, timelineId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = (payload: { status: string }) => api.patch(`/project-types/${projectProposalTypeId}/timelines/${timelineId}/status`, payload);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getTimelineKey(timelineId));
            showToast('Finalização do cronograma efetuado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao fechar o cronograma', 'danger');
        }
    });
};

export default useFinalizeTimelineCreation;
