import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';
import asset, { getAssetKey } from './graphql';
import { AssetResponse } from 'types/models/assets';

const useGetAssetById = (assetId?: string) => {
    const request = async () => {
        const { items } = await apiGraphQl<AssetResponse>(asset.getAssetById, { assetId });

        return items;
    };

    return useQuery(getAssetKey(assetId), request, { enabled: !!assetId });
};

export default useGetAssetById;
