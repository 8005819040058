import React from 'react';
import withPagePermissions from 'hocs/with-page-permissions';
import { Rule } from 'types/permissions';
import { CrudPageProps } from 'types/graphql';

export type PageProps = {
    Component: React.ElementType;
    permissions: Rule[];
    code: string;
    withModal?: boolean;
    [key: string]: any;
} & CrudPageProps;

const Page = ({ Component, code, permissions, withModal = false, ...props }: PageProps) => {
    return <Component code={code} permissions={permissions} withModal={withModal} {...props} />;
};

export default withPagePermissions(Page);
