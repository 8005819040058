import api from 'services/api';
import { useMutation, useQueryClient } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import { getProjectKey } from '../projects/graphql';
import { UpdateProjectTypeStatusPayload } from '../operationals/use-update-project-type-status';
import { Modify } from 'types/general';
import { ProjectStatusEnum } from 'types/models/project';

export type UpdateProjectStatusPayload = Modify<
    UpdateProjectTypeStatusPayload,
    {
        status: ProjectStatusEnum;
    }
>;

const useUpdateProjectStatus = (projectId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: UpdateProjectStatusPayload) => api.patch(`projects/${projectId}/status`, payload);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectKey(+projectId!));
            showToast('Status do projeto atualizado com sucesso!', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao atualizar o status do projeto.', 'danger');
        }
    });
};

export default useUpdateProjectStatus;
