import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { optionsKey } from './graphql';
import { Pagination } from 'types/pagination';
import { Option, Status } from 'types/general';

type Params = {
    tableName: string;
    labelColumn?: string;
    valueColumn?: string;
    where?: any;
    orderBy?: any;
    query?: string;
    serialize?(item: any): Option;
};

/**
 * This hook generates a graphql query based on the params.
 * Normally used to populate select options.
 *
 * @param params Params
 * @returns Pagination<Option>
 */
export const useGetOptions = (params: Params) => {
    const {
        tableName,
        labelColumn = 'name',
        valueColumn = 'id',
        where = {
            status: { _eq: Status.Active }
        },
        orderBy,
        query,
        serialize
    } = params || {};

    const generateQuery =
        query ||
        jsonToGraphQLQuery({
            query: {
                __name: `Get${tableName}Options`,
                items: {
                    __aliasFor: tableName,
                    __args: {
                        where,
                        order_by: orderBy || { [labelColumn]: new EnumType('asc') }
                    },
                    value: { __aliasFor: valueColumn },
                    label: { __aliasFor: labelColumn }
                }
            }
        });

    return useQuery(
        optionsKey(params),
        () =>
            apiGraphQl<Pagination<Option>>(generateQuery).then((data) => {
                return { ...data, ...(!!serialize && { items: data.items?.map(serialize) }) };
            }),
        { enabled: !!generateQuery }
    );
};
