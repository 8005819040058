import React, { useState } from 'react';

import theme from 'settings/theme';
import Empty from 'components/empty';
import Tab from '@mui/material/Tab/Tab';
import Text from 'components/core/text';
import Tabs from '@mui/material/Tabs/Tabs';
import PhysicalFinancialTable from './table';
import { UseQueryResult } from 'react-query';
import Spinner from 'components/core/spinner';
import HorizontalBarChart from '../horizontal-bar-chart';
import type { PhysicalFinancial as PhysicalFinancialType } from 'types/models/report';

const colors = ['#b3d3d3', theme.extend.colors.secondary[500]];

const PhysicalFinancial = ({ isLoading, isError, data: physicalFinancial }: UseQueryResult<PhysicalFinancialType, unknown>) => {
    const [tab, setTab] = useState<number | string>('GENERAL');

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    return (
        <div className="border border-base-300 rounded-[14px] mb-4">
            <Text as="h6" variant="h6" className="p-4 text-heading">
                Avanço físico financeiro
            </Text>
            {isLoading ? (
                <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} className="min-h-[100px]" />
            ) : isError ? (
                <Empty title="Dados não encontrados" className="px-4" />
            ) : (
                <>
                    <Tabs classes={{ root: 'bg-base-100 z-[10]' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                        <Tab value="GENERAL" label="Geral" />
                        <Tab value="DETAILS" label="Detalhes" />
                    </Tabs>
                    {tab === 'DETAILS' && (
                        <div className="p-4">
                            <PhysicalFinancialTable measurements={physicalFinancial?.measurements || []} providers={physicalFinancial?.providers || []} />
                        </div>
                    )}
                    {tab === 'GENERAL' && (
                        <div className="p-4">
                            <HorizontalBarChart colors={colors} labels={physicalFinancial?.graphic.labels || []} datasets={physicalFinancial?.graphic.datasets || []} className="min-h-[250px]" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default PhysicalFinancial;
