import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CrudPageProps } from 'types/graphql';

import { OutcomeProjectTransactionPayload } from 'types/models/organization';
import useCreateOrUpdateOutcometransactionProject from 'services/queries/operationals/use-create-update-transaction-outcome-project';
import CreateOrUpdateOutcome from './create-or-update';
import { ProjectTransactions } from 'types/general';
import useGetProjectOutcomeById from 'services/queries/operationals/use-get-project-transaction-outcome-by-id';
import omit from 'lodash/omit';

import dayjs from 'dayjs';
import { maskToCurrency } from 'utils/money';
import useUploadFile from 'services/queries/files/use-upload-file';

const CreateOrUpdateOutcomePage = ({ title }: CrudPageProps) => {
    const { projectProposalTypeId, outcomeId } = useParams();

    const { mutateAsync: mutateCreateOrUpdateOutcome, isLoading: isSubmitting, error: errorCreateUpdate } = useCreateOrUpdateOutcometransactionProject(outcomeId, projectProposalTypeId as string);

    const { data: projectOutcomeDetails, isLoading: isLoadingProjectOutcomeDetails, error: errorProjectOutcomeDetails } = useGetProjectOutcomeById(outcomeId as string, projectProposalTypeId);

    const { uploadFile, isLoading: isSubmittingDocument } = useUploadFile();

    const error = useMemo(() => errorCreateUpdate || errorProjectOutcomeDetails, [errorCreateUpdate, errorProjectOutcomeDetails]);

    const defaultValues = useMemo(
        () => ({
            id: projectOutcomeDetails?.id,
            classification: projectOutcomeDetails?.classification,
            description: projectOutcomeDetails?.description,
            value: {
                floatValue: projectOutcomeDetails?.value,
                formattedValue: maskToCurrency({ nextState: projectOutcomeDetails?.value }),
                value: projectOutcomeDetails?.value?.toString()
            },
            dueDate: projectOutcomeDetails?.dueDate && dayjs(projectOutcomeDetails?.dueDate).toISOString(),
            payday: projectOutcomeDetails?.payday && dayjs(projectOutcomeDetails?.payday),
            type: ProjectTransactions.Outcome,
            observation: projectOutcomeDetails?.observation || '',
            ...(Boolean(projectOutcomeDetails?.person) && { person: projectOutcomeDetails?.person }),
            ...(Boolean(projectOutcomeDetails?.provider) && { provider: projectOutcomeDetails?.provider }),
            ...(Boolean(projectOutcomeDetails?.data_service_classification?.id) && { service: projectOutcomeDetails?.data_service_classification.id })
        }),
        [projectOutcomeDetails]
    );

    const handleSubmit = async (data: OutcomeProjectTransactionPayload) => {
        try {
            const newData = omit(data, ['code', 'id', 'documents']);
            const documents: Partial<OutcomeProjectTransactionPayload['documents']> = [];

            if (Boolean(data?.documents?.length)) {
                for (const document of data.documents!) {
                    if (!document?.file) {
                        throw new Error();
                    }

                    if (document?.file?.id) {
                        documents.push(document);
                    } else {
                        try {
                            const responseDocument = await uploadFile(document.file, undefined, true);
                            documents.push({ ...document, file: responseDocument?.id as any });
                        } catch (error) {
                            console.error('Houve um erro ao enviar o arquivo.', error);
                        }
                    }
                }
            }

            await mutateCreateOrUpdateOutcome({
                ...newData,
                type: ProjectTransactions.Outcome,
                value: newData.value?.floatValue,
                ...(Boolean(newData?.person) && { person: newData.person }),
                ...(Boolean(documents.length) && { documents })
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <CreateOrUpdateOutcome
            isBuilding={isLoadingProjectOutcomeDetails}
            isSubmitting={isSubmitting || isSubmittingDocument}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            error={error}
            title={title || ''}
        />
    );
};

export default CreateOrUpdateOutcomePage;
