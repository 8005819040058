import { months } from 'utils/date';

export const generateArray = (length = 10) => [...new Array(Math.max(0, Math.floor(length))).keys()];

export const WEEK_WIDTH = 300;

export const DAY_WIDTH = WEEK_WIDTH / 5;

export const DAY_HEIGHT = 45;

export const PERSON_HEIGHT = 55;

export const monthsOptions = months.map((m, i) => ({ value: i + 1, label: m }));
