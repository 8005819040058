import { useCallback, useMemo, useState } from 'react';

// Dependencies
import { useQueryClient } from 'react-query';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import TableCell from '@mui/material/TableCell/TableCell';
import IconButton from '@mui/material/IconButton/IconButton';
import { Display, MUIDataTableColumn } from 'mui-datatables';
import { Link, Outlet, useNavigate, useOutletContext } from 'react-router-dom';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Spinner from 'components/core/spinner';
import AddButton from 'components/buttons/add';
import ConfirmModal from 'components/core/modal/confirm';
import Datatable from 'components/core/table/datatable';
import ExternalButton from 'components/buttons/external';

// Helpers
import useDelete from 'services/queries/crud/use-delete';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import { getProjectTypeSolicitationsKey } from 'services/queries/projects/graphql';
import useGetProjectTypeSolicitations from 'services/queries/projects/use-get-project-type-solicitations';
import { getSolicitationsQuery, QueryOrderBy } from './utils';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';
import { ItemsPerPage } from 'types/graphql';

type DocumentsProps = {
    projectProposalTypeIdProp?: number;
} & WithPagePermissionsProps;

const infoButton = { classes: { child: 'text-system-info-100' } };
const warningButton = { classes: { child: 'text-system-warning-100' } };
const dangerButton = { classes: { child: 'text-system-danger-100' } };

const INITIAL_PARAMS = {
    limit: 10 as ItemsPerPage,
    page: 1,
    offset: 0
};

const Requests = ({ projectProposalTypeIdProp }: DocumentsProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const queryClient = useQueryClient();
    const [params, setParams] = useState(INITIAL_PARAMS);
    const [orderBy, setOrderBy] = useState<QueryOrderBy>({ changedColumn: 'number', direction: 'asc' });
    const { isSmallerThenTabletLandscape } = useResponsive();

    const [solicitationToDeleteId, setSolicitationToDeleteId] = useState<number>();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const projectProposalTypeId = useMemo(() => projectProposalTypeIdProp || ctx?.projectProposalType?.id, [projectProposalTypeIdProp, ctx?.projectProposalType]);

    const query = useMemo(() => getSolicitationsQuery(projectProposalTypeId || 0, params, orderBy), [projectProposalTypeId, params, orderBy]);

    const { data, isLoading } = useGetProjectTypeSolicitations(query);

    const { mutateAsync: deleteItem, isLoading: isSubmitting } = useDelete({
        apiPayload: { endpoint: `/project-types/${projectProposalTypeId}/solicitations`, useApiTodelete: true }
    });

    const handleGoTo = useCallback((path: string) => () => navigate(path), [navigate]);

    const handleDelete = async () => {
        try {
            if (solicitationToDeleteId) {
                await deleteItem({ id: solicitationToDeleteId });

                setSolicitationToDeleteId(undefined);

                queryClient.invalidateQueries(getProjectTypeSolicitationsKey(query));
            }
        } catch (error) {
            console.log('handleDeleteDocument', error);
        }
    };

    const handleChangePage = (page: number) => {
        setParams({
            ...params,
            page,
            offset: (page || 1) * params.limit - params.limit
        });
    };

    const columns: MUIDataTableColumn[] = useMemo(() => {
        const arr = [
            {
                name: 'id',
                options: { display: 'excluded' as Display }
            },
            { name: 'number', label: 'Código' },
            { name: 'subject', label: 'Assunto' },
            {
                name: 'person',
                label: 'Criador',
                options: {
                    customBodyRender: (value) => <ExternalButton onClick={handleGoTo(`/app/pessoas/${value.id}`)}>{value.name}</ExternalButton>
                }
            },
            {
                name: '',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 130 }} />,
                    customBodyRender: (_: any, tableMeta: any) => {
                        const [id] = tableMeta.rowData;

                        return (
                            <div className="flex">
                                <Tooltip placement="top" title="Visualizar" disableFocusListener={true}>
                                    <IconButton component={Link} to={`${id}`} TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20">
                                        <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                    <IconButton component={Link} to={`${id}/editar`} TouchRippleProps={warningButton} className="hover:bg-system-warning-100 hover:bg-opacity-30">
                                        <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                    <IconButton TouchRippleProps={dangerButton} className="hover:bg-system-danger-100 hover:bg-opacity-30" onClick={() => setSolicitationToDeleteId(id)}>
                                        <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    }
                } as any
            }
        ];

        return arr;
    }, [palette, handleGoTo]);

    const handleColumnSortChange = (changedColumn: string, direction: 'asc' | 'desc') => setOrderBy({ changedColumn, direction });

    if (isLoading) {
        return <Spinner fixed={false} parentClasses="h-[calc(100%-49px)]" />;
    }

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <Text as="h6" variant="h6" className="text-heading mb-4">
                Solicitações
            </Text>
            <Datatable
                columns={columns}
                data={data?.items || []}
                loading={isLoading}
                footerLeft={<AddButton onClick={handleGoTo('novo')}>Nova solicitação</AddButton>}
                options={{
                    elevation: 0,
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    count: data?.pagination?.aggregate.count || 0,
                    page: params.page,
                    rowsPerPage: params.limit,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    textLabels: {
                        body: {
                            noMatch: 'Nenhuma solicitação encontrada.'
                        }
                    },
                    onChangePage: handleChangePage,
                    onColumnSortChange: handleColumnSortChange
                }}
                withItemsPerPage={false}
            />
            <Outlet context={query} />
            {Boolean(solicitationToDeleteId) && (
                <ConfirmModal
                    title="Apagar solicitação"
                    description="Você tem certeza que deseja apagar este termo?"
                    onClose={() => setSolicitationToDeleteId(undefined)}
                    isLoading={isSubmitting}
                    onConfirmAction={handleDelete}
                />
            )}
        </>
    );
};

export default Requests;
