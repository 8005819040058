import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';

const useCreateSubPages = () => {
    const request = async (payload: any) => {
        return apiGraphQl(
            `mutation CreateSubPages($payload: [subpage_insert_input!]!) {
                item: insert_subpage(objects: $payload) {
                    affected_rows
                }
            }`,
            { payload }
        );
    };

    return useMutation(request);
};

export default useCreateSubPages;
