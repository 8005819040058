import { useMemo, useState } from 'react';
import ListPage from 'pages/private/crud/list';

import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { listPeople } from './utils/utils';

const ListPeople = ({ page }: WithPagePermissionsProps) => {
    const contractStatusState = useState<null | number>(null);

    const list = useMemo(() => listPeople(contractStatusState), [contractStatusState]);

    return <ListPage page={page} {...list} />;
};

export default ListPeople;
