import React, { useContext, useMemo } from 'react';

// Dependencies
import { array, object, string } from 'yup';
import { useOutletContext, useParams } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

// Components
import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Button from 'components/core/button';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import Editor from 'components/core/form/editor';
import ErrorMessage from 'components/error/message';
import RemoveButton from 'components/buttons/remove';

// Helpers
import dictionary from 'utils/dictionary';
import { MeetingRecordContext } from 'contexts/meeting-record';
import { MeetingSteps, SubjectPayload } from 'contexts/meeting-record/types';
import useCreateOrUpdateMeetingRecord from 'services/queries/projects/use-create-or-update-meeting-record';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';

export type MeetingSubjectPayload = {
    subjects: SubjectPayload[];
};

const schema = object({
    subjects: array(
        object({
            title: string().required(dictionary.validation.required),
            description: string().required(dictionary.validation.required)
        })
    )
        .min(1, dictionary.validation.array.min(1))
        .required(dictionary.validation.required)
});

const Subjects = () => {
    const { palette } = useTheme();
    const { meetingRecordId, projectId: projectIdParam } = useParams();
    const { state, changeStep, setContent } = useContext(MeetingRecordContext);

    const ctx = useOutletContext<OperationalDetailsPageContext | undefined>();

    const projectId = useMemo(() => Number(projectIdParam) || ctx?.project?.id, [projectIdParam, ctx?.project?.id]);

    const { mutateAsync: createOrUpdateMeetingRecord, isLoading: isSubmitting } = useCreateOrUpdateMeetingRecord(meetingRecordId, projectId);

    const methods = useForm<MeetingSubjectPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: {
            subjects: state.content.subjects
        },
        resolver: yupResolver(schema)
    });

    const { control, formState, handleSubmit } = methods;

    const { fields, append, remove } = useFieldArray({ name: 'subjects', control });

    const handleAddSubject = () => append({ title: '', description: '' });

    const handleDeleteSubject = (index: number) => () => remove(index);

    const triggerSubmit = async (data: MeetingSubjectPayload) => {
        try {
            const payload = {
                subjects: data.subjects.map((item) => {
                    delete item['tasks'];

                    return item;
                })
            };

            const meetingRecord = await createOrUpdateMeetingRecord(payload);

            setContent({ subjects: meetingRecord.subjects });
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleBack = () => changeStep(state.currentStep.slug, undefined, MeetingSteps.Meeting);

    return (
        <form onSubmit={handleSubmit(triggerSubmit)}>
            <div className="p-[30px]">
                <Text as="h3" variant="h4" className="text-heading mb-5">
                    Tópicos
                </Text>
                {Boolean(fields.length) ? (
                    fields.map((item, index) => {
                        return (
                            <div className="border border-base-300 p-4 rounded-2xl mb-2 group relative" key={item.id}>
                                <RemoveButton onClick={handleDeleteSubject(index)} />
                                <Controller
                                    name={`subjects.${index}.title`}
                                    control={control}
                                    render={({ field }) => <Input {...field} error={formState.errors.subjects?.[index]?.title?.message} label="Título" parentClassName="mb-4" />}
                                />
                                <Controller
                                    name={`subjects.${index}.description`}
                                    control={control}
                                    render={({ field }) => <Editor {...field} label="Descrição" error={formState.errors.subjects?.[index]?.description?.message} />}
                                />
                            </div>
                        );
                    })
                ) : (
                    <div className="border border-base-300 p-4 rounded-2xl mb-2 group">
                        <Empty title="Nenhum tópico adicionado até o momento" />
                    </div>
                )}
                <ErrorMessage visible={Boolean((formState as any).errors?.subjects?.message)} className="block mb-4">
                    {(formState as any).errors?.subjects?.message}
                </ErrorMessage>
                <AddButton onClick={handleAddSubject}>Mais tópicos</AddButton>
            </div>
            <div className="border-t p-2 flex justify-between">
                <Button startIcon={<Icon name="ico-arrow-left" width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                    Voltar
                </Button>
                <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    endIcon={<Icon name="ico-arrow-right" width={18} color={isSubmitting ? palette.grey[400] : palette.secondary[500]} />}
                    type="submit"
                    variant="text"
                    color={isSubmitting ? 'inherit' : 'secondary'}
                    className="min-w-[100px]">
                    Avançar
                </Button>
            </div>
        </form>
    );
};

export default Subjects;
