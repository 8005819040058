import React, { useEffect, useState } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import Input from 'components/core/form/input';
import { getProjectDocumentClassificationsQuery, projectDocumentSchema } from '../utils';
import Editor from 'components/core/form/editor';
import Button from 'components/core/button';
import Select from 'components/core/form/select';
import Dropzone from 'components/dropzone';
import useCreateOrUpdateProjectDocument, { ProjectDocumentPayload } from 'services/queries/projects/use-create-or-update-project-document';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { FileApiModel } from 'types/models/file';
import useGetProjectDocument from 'services/queries/projects/use-get-project-document';
import FileRow from 'components/files-row';

const CreateOrUpdateDocumentPage = () => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const [files, setFiles] = useState<Array<Partial<File & FileApiModel>>>([]);

    const { documentId, projectId } = useParams();

    const { data: document, isLoading: isLoadingDocument } = useGetProjectDocument(projectId, documentId);
    const { data: classifications, isLoading: isLoadingClassifications } = useGetClassifications(getProjectDocumentClassificationsQuery);

    const { mutate: createOrUpdateProjectDocument, isLoading: isSubmitting } = useCreateOrUpdateProjectDocument(projectId, documentId);

    const { control, formState, handleSubmit, setValue, reset } = useForm<ProjectDocumentPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(projectDocumentSchema),
        defaultValues: {
            name: ''
        }
    });

    useEffect(() => {
        reset({
            classification: document?.classification?.id,
            description: document?.description || '',
            observation: document?.observation || '',
            name: document?.name || '',
            file: document?.file?.id
        });
    }, [document, reset]);

    useEffect(() => {
        if (Boolean(document?.file) && !Boolean(files.length)) {
            setFiles([document?.file] as any);
        }
    }, [document, files]);

    const handleBack = () => navigate(-1);

    const handlePersistFile = (file: FileApiModel) => {
        setValue('file', file.id);
        setFiles((prev) => {
            return prev.map((item) => ({
                ...item,
                ...(item.path === file.originalName && { ...file, id: file.id })
            }));
        });
    };

    const submit = (data: ProjectDocumentPayload) => createOrUpdateProjectDocument(data);

    return (
        <Modal
            contentClassnames="w-[600px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {documentId ? 'Atualizar' : 'Novo'} documento
                </Text>
            }
            onClose={handleBack}>
            {isLoadingClassifications || isLoadingDocument ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <Controller control={control} name="name" render={({ field }) => <Input {...field} type="text" label="Nome" error={formState.errors.name?.message} />} />
                        <Controller
                            name="classification"
                            control={control}
                            render={({ field }) => {
                                const value = classifications?.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={classifications || []}
                                        label="Classificação"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.classification?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                    <Controller
                        control={control}
                        name="description"
                        render={({ field }) => <Editor {...field} error={formState.errors?.description?.message} label="Descrição" parentClasses="mb-4" />}
                    />
                    <Controller
                        control={control}
                        name="observation"
                        render={({ field }) => <Editor {...field} error={formState.errors?.observation?.message} label="Observações" parentClasses="mb-4" />}
                    />
                    <div className="mb-6">
                        <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                            Arquivo
                        </Text>
                        <Dropzone disabled={Boolean(files.length)} multiple={false} error={formState.errors.file?.message} onUploadFiles={(filesList: File[]) => setFiles([...files, ...filesList])} />
                        {Boolean(files.length) && (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Text as="span" className="text-secondary-500">
                                                    Tipo
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-secondary-500">
                                                    Arquivo
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" className="text-secondary-500">
                                                    Status
                                                </Text>
                                            </TableCell>
                                            <TableCell width={90}>
                                                <Text as="span" className="text-secondary-500">
                                                    Ações
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <FileRow file={files[0]} onPersistFile={handlePersistFile} onRemoveFromList={() => setFiles([])} />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateDocumentPage;
