import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { AssetDetailsProps } from '../view/asset-details';
import { configsGraphql } from 'settings/routes/entities/utils/configs-graphql';
import { assetsModalities } from 'utils/statics';
import { formatMoney } from 'utils/money';
import { number, object, string } from 'yup';
import dictionary from 'utils/dictionary';

const { required } = dictionary.validation;

export const createOrUpdateClassificationMutation = (assetId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateAsset',
                __variables: {
                    ...(assetId
                        ? {
                              payload: 'asset_set_input',
                              id: 'Int!'
                          }
                        : {
                              payload: 'asset_insert_input!'
                          })
                },
                item: {
                    __aliasFor: assetId ? 'update_asset_by_pk' : 'insert_asset_one',
                    __args: {
                        ...(assetId
                            ? {
                                  pk_columns: { id: new VariableType('id') },
                                  _set: new VariableType('payload')
                              }
                            : {
                                  object: new VariableType('payload')
                              })
                    },
                    id: true
                }
            }
        },
        { pretty: true }
    );
};

export const generateAssetDetails = (asset: AssetDetailsProps['asset'], classificationType: string) => {
    const { name, code, serial_number, classification, modality, branch, label, brand, model, value, insurance_value } = asset;

    return [
        {
            label: 'Nome',
            value: name || '-'
        },

        {
            label: 'Código',
            value: code || '-'
        },
        {
            label: 'Série',
            value: serial_number || '-'
        },
        {
            label: 'Classificação',
            value: classification.name || '-'
        },
        {
            label: 'Ativo',
            value: configsGraphql[classificationType]?.title?.replace('Ativo', '') || '-'
        },
        {
            label: 'Modalidade',
            value: assetsModalities.find((item) => item.value === modality)?.label || '-'
        },
        {
            label: 'Unidade',
            value: branch?.name || '-'
        },
        {
            label: 'Etiqueta',
            value: label || '-'
        },
        {
            label: 'Marca',
            value: brand || '-'
        },
        {
            label: 'Modelo',
            value: model || '-'
        },
        {
            label: 'Valor',
            value: !!value ? formatMoney(value) : '-'
        },
        {
            label: 'Seguro',
            value: !!insurance_value ? formatMoney(insurance_value) : '-'
        }
    ];
};

const onlyNumbers = 'Apenas números';

export const assetSchema = object({
    name: string().typeError('Apenas letras').required(required),
    serial_number: string().optional(),
    status: number().typeError(onlyNumbers).required(required).default(1),
    classification_id: number().typeError(onlyNumbers).required(required),
    quantity: number().typeError(onlyNumbers).required(required),
    label: string().required(required),
    modality: number().optional(),
    value: number().typeError(onlyNumbers).optional(),
    insurance_value: number().typeError(onlyNumbers).optional(),
    branch_id: number().typeError(required).required(required),
    code: string().optional(),
    model: string().optional(),
    asset_status: number().typeError(required).required(required),
    classification_department_id: number().optional(),
    brand: string().required(required),
    observations: string().optional(),
    person_id: number().optional(),
    phone: string().optional()
});
