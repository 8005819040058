import React, { useEffect } from 'react';

import Text from 'components/core/text';
import dictionary from 'utils/dictionary';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';
import AddButton from 'components/buttons/add';
import checklistTemplate from './utils/template';
import { Timeline } from 'types/models/timeline';
import { array, mixed, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Task, { TaskType } from 'components/task-tree/task';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';

type ChecklistCreateOrUpdateProps = {
    isLoading: boolean;
    isSubmitting: boolean;
    timeline?: Timeline;
    onSubmit: (data: Payload) => void;
};

type Payload = {
    tasks: TaskType[];
};

const schema = object({
    tasks: array(
        object({
            title: string().required(dictionary.validation.required),
            tasks: mixed()
        })
    )
});

const ChecklistCreateOrUpdate = ({ isLoading, isSubmitting, timeline, onSubmit }: ChecklistCreateOrUpdateProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const methods = useForm<Payload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        defaultValues: {
            tasks: []
        }
    });

    const { fields, append, remove } = useFieldArray({ name: 'tasks', control: methods.control });

    useEffect(() => {
        if (timeline?.tasks) {
            methods.reset({
                tasks: timeline.tasks.map((item) => ({
                    id: item.id,
                    title: item.title || '',
                    mandatory: item.mandatory,
                    tasks: item.tasks?.map((task) => ({
                        id: task.id,
                        title: task.title || '',
                        mandatory: task.mandatory
                    }))
                }))
            });
        }
    }, [timeline?.tasks, methods]);

    const handleBack = () => navigate(-1);

    const handleAdd = () => append({ title: '', mandatory: false });

    const handleImport = () => append(checklistTemplate);

    return (
        <Modal
            contentClassnames="w-[700px]"
            closeOnClickOutside={false}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Checklist de start de obra
                </Text>
            }
            onClose={handleBack}>
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="px-6 pb-6">
                    <FormProvider {...methods}>
                        <div className="overflow-x-auto">
                            <div className={`${Boolean(fields?.length) ? 'min-w-[450px]' : ''} md:min-w-full`}>
                                {Boolean(fields.length) ? (
                                    fields.map((item, index) => <Task key={item.id} index={index} onDeleteTask={() => remove(index)} />)
                                ) : (
                                    <div className="border border-base-300 rounded-xl p-4 mb-4 flex flex-wrap gap-2 items-center justify-between">
                                        <Text as="p" className="text-base-700">
                                            Deseja importar um template com tarefas pré-definidas?
                                        </Text>
                                        <Button color="secondary" variant="contained" type="button" size="small" onClick={handleImport}>
                                            IMPORTAR
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <AddButton onClick={handleAdd}>Nova tarefa</AddButton>
                        </div>
                        <div className="flex items-center mt-8">
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                variant="contained"
                                color="secondary"
                                className="min-w-[100px] mr-4"
                                onClick={methods.handleSubmit(onSubmit)}>
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                                Fechar
                            </Button>
                        </div>
                    </FormProvider>
                </form>
            )}
        </Modal>
    );
};

export default ChecklistCreateOrUpdate;
