import Spinner from 'components/core/spinner';
import ConstructionsList, { OperationalsListMode } from './operational';
import { useLocation, useParams } from 'react-router-dom';
import useGetOperational, { ProjectTypeRouteSlug } from 'services/queries/operationals/use-get-operational';
import Filter from '../components/filter';
import { useMemo, useState } from 'react';
import { projectTypeTitleMap } from 'utils/project';
import QuickFilter, { OperationalOrderByValue } from './quick-filter';
import Button from 'components/core/button';
import Icon from 'components/core/icon';
import PageHeader from 'components/page/header';
import { useLocalStorageState } from 'hooks/local-storage/use-local-storage-state';
import qs from 'qs';
import useGetQueryParam from 'hooks/router/use-get-query-param';

export type OperationalListQueryFilterParsed = {
    filter?: {
        initialPeriodStartDate?: string;
        initialPeriodEndDate?: string;
        finalPeriodStartDate?: string;
        finalPeriodEndDate?: string;
        status?: string[];
        person?: string[];
        customer?: string[];
    };
};

const OperationalsListPage = () => {
    const { search } = useLocation();
    const { projectTypeSlug } = useParams<{ projectTypeSlug: ProjectTypeRouteSlug }>();

    const [listMode, setListMode] = useLocalStorageState<OperationalsListMode>({
        initialValue: 'cards',
        key: 'operationals-list-mode'
    });

    const [page, setPage] = useState(1);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [quickSearchText, setQuickSearchText] = useState<string>();
    const [orderBy, setOrderBy] = useState<OperationalOrderByValue>();
    const [totalPerPage, setTotalPerPage] = useState<any>(listMode === 'cards' ? 12 : 10);

    const filtersQueryParsed: OperationalListQueryFilterParsed = qs.parse(search, { ignoreQueryPrefix: true });

    const initialPeriodStartDate = useGetQueryParam('filter[initialPeriodStartDate]');
    const initialPeriodEndDate = useGetQueryParam('filter[initialPeriodEndDate]');
    const finalPeriodStartDate = useGetQueryParam('filter[finalPeriodStartDate]');
    const finalPeriodEndDate = useGetQueryParam('filter[finalPeriodEndDate]');
    const status = filtersQueryParsed.filter?.status;
    const person = filtersQueryParsed.filter?.person;
    const customer = filtersQueryParsed.filter?.customer;

    const queryFilters = useMemo(
        () =>
            qs.stringify(
                {
                    filter: {
                        ...(!!initialPeriodStartDate && { initialPeriodStartDate }),
                        ...(!!initialPeriodEndDate && { initialPeriodEndDate }),
                        ...(!!finalPeriodStartDate && { finalPeriodStartDate }),
                        ...(!!finalPeriodEndDate && { finalPeriodEndDate }),
                        ...(!!status && { status }),
                        ...(!!person && { person }),
                        ...(!!customer && { customer }),
                        ...(!!quickSearchText && { name: quickSearchText })
                    },
                    ...(!!orderBy && {
                        orderBy: {
                            [orderBy.field]: orderBy.direction
                        }
                    }),
                    totalPerPage,
                    page
                },
                {
                    addQueryPrefix: true,
                    encode: false,
                    arrayFormat: 'brackets'
                }
            ),
        [customer, finalPeriodEndDate, finalPeriodStartDate, initialPeriodEndDate, initialPeriodStartDate, orderBy, page, person, quickSearchText, status, totalPerPage]
    );

    const { data, isLoading } = useGetOperational(projectTypeSlug, queryFilters);

    const handleFilterVisible = () => setIsFilterVisible(false);

    const title = projectTypeTitleMap[projectTypeSlug || ''] || '';

    const handleChangeVisibility = (value: OperationalsListMode) => {
        setListMode(value);
        setTotalPerPage(value === 'cards' ? 12 : 10);
    };

    return (
        <>
            {isFilterVisible && <Filter onClose={handleFilterVisible} />}
            <PageHeader
                className="mb-8"
                title={title}
                right={
                    <div className="flex items-center justify-end gap-2">
                        <Button
                            color="inherit"
                            variant="outlined"
                            type="button"
                            size="medium"
                            className="bg-white"
                            onClick={() => setIsFilterVisible(true)}
                            endIcon={<Icon name="ico-filter" width={17} height={17} color="currentColor" />}>
                            Filtrar
                        </Button>
                        <Button
                            color="inherit"
                            variant="outlined"
                            type="button"
                            className="bg-white text-heading min-w-[42px] !w-[42px] !h-[42px]"
                            onClick={handleChangeVisibility.bind(this, 'table')}>
                            <Icon name="ico-list-2" width={17} height={17} color="currentColor" />
                        </Button>
                        <Button
                            color="inherit"
                            variant="outlined"
                            type="button"
                            className="bg-white text-heading min-w-[42px] !w-[42px] !h-[42px]"
                            onClick={handleChangeVisibility.bind(this, 'cards')}>
                            <Icon name="ico-grid" width={16} height={16} color="currentColor" />
                        </Button>
                    </div>
                }
            />
            <QuickFilter projectTypeSlug={projectTypeSlug} orderBy={orderBy} onChangeSearch={setQuickSearchText} onChangeOrderBy={setOrderBy} />
            {isLoading ? (
                <div className="relative w-full h-full">
                    <Spinner />
                </div>
            ) : (
                <ConstructionsList
                    totalPerPage={totalPerPage}
                    orderBy={orderBy}
                    data={data}
                    listMode={listMode}
                    page={page}
                    onChangeSort={setOrderBy}
                    onChangePage={setPage}
                    onChangeRowsPerPage={setTotalPerPage}
                />
            )}
        </>
    );
};

export default OperationalsListPage;
