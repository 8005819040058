import RemoveButton from 'components/buttons/remove';
import Select from 'components/core/form/select';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Option } from 'types/general';
import { ManagementResourcePayload } from 'types/models/resource';
import ManagementResourcePeriods from './management-resource-periods';
import AddButton from 'components/buttons/add';
import ErrorMessage from 'components/error/message';
import Text from 'components/core/text';

type ManagementResourceProps = {
    index: number;
    people: Option[];
    positions: Option[];
    onDeleteResource: (index: number) => void;
};

const ManagementResource = ({ index, people, positions, onDeleteResource }: ManagementResourceProps) => {
    const { control, formState } = useFormContext<ManagementResourcePayload>();

    const handleDeleteResource = () => onDeleteResource(index);

    const { fields: periods, append, remove } = useFieldArray({ control, name: `resources.${index}.periods` });

    const handleCreatePeriod = () => append({ contractEndDate: '', contractStartDate: '' });

    return (
        <div className="group mb-5 border border-base-300 p-4 rounded-[14px] relative">
            {Boolean(index) && <RemoveButton onClick={handleDeleteResource} />}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <Controller
                    name={`resources.${index}.person`}
                    control={control}
                    render={({ field }) => {
                        const value = people.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={people}
                                label="Colaborador"
                                placeholder="Selecione uma opção"
                                error={formState.errors.resources?.[index]?.person?.message}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
                <Controller
                    name={`resources.${index}.typeManagementInformation`}
                    control={control}
                    render={({ field }) => {
                        const value = positions.find((item) => item?.value === field?.value);
                        return (
                            <Select
                                {...field}
                                value={value}
                                options={positions}
                                label="Atribuição"
                                isClearable={true}
                                placeholder="Selecione uma opção"
                                error={formState.errors.resources?.[index]?.typeManagementInformation?.message}
                                onChange={(option: any) => field.onChange(option?.value)}
                            />
                        );
                    }}
                />
            </div>
            <div className="border border-base-300 p-4 rounded-[14px]">
                <Text variant="body.medium.2xs" as="label" className="block text-heading mb-4">
                    Períodos
                </Text>
                {periods.map((item, periodIndex) => {
                    return <ManagementResourcePeriods key={item.id} onDeletePeriod={remove} periodIndex={periodIndex} resourceIndex={index} />;
                })}
            </div>
            <ErrorMessage visible={Boolean((formState as any).errors.resources?.[index]?.periods?.message)} className="block mb-4">
                {(formState as any).errors.resources?.[index]?.periods?.message}
            </ErrorMessage>
            <AddButton className="mt-4" onClick={handleCreatePeriod}>
                Novo período
            </AddButton>
        </div>
    );
};

export default ManagementResource;
