import React, { useMemo } from 'react';

// Dependencies
import { MUIDataTableFooter } from 'mui-datatables';
import Pagination from '@mui/material/Pagination/Pagination';

// Components
import Text from 'components/core/text';
import Spinner from 'components/core/spinner';
import Select from 'components/core/form/select';
import classNames from 'classnames';
import useResponsive from 'hooks/responsive/use-responsive';

type DatatableFooterProps = {
    loading: boolean;
    withItemsPerPage: boolean;
    footerLeft?: React.ReactNode;
} & MUIDataTableFooter;

const perPageOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' }
];

const MAX_COUNT_ITEMS = 10;

const DatatableFooter = (props: DatatableFooterProps) => {
    const total = props.rowCount || 0;
    const itemsPerPage = props.rowsPerPage || 10;

    const { isMobile } = useResponsive();

    const containerClasses = classNames(
        'flex items-center p-6 border-t border-t-[#E8E9EB] flex-wrap gap-5',
        props.footerLeft ? 'justify-center sm:justify-between' : !props.withItemsPerPage ? 'justify-center sm:justify-end' : 'justify-center sm:justify-between',
        isMobile ? 'px-2' : ''
    );

    const value = useMemo(() => perPageOptions.find((item) => item.value === itemsPerPage), [itemsPerPage]);

    const pages = useMemo(() => Math.ceil(total / itemsPerPage) ?? 1, [total, itemsPerPage]);

    const handleChangeItemsPerPage = (option) => props.changeRowsPerPage && props.changeRowsPerPage(option.value);

    const handleChangePage = (_: any, page: number) => props.changePage && props.changePage(page);

    return (
        <footer className={containerClasses}>
            {props.footerLeft ||
                (props.withItemsPerPage ? (
                    <div className="flex items-center">
                        {total > 10 && <Select value={value} options={perPageOptions} size="small" parentClassName="mr-3" onChange={handleChangeItemsPerPage} />}
                        <Text as="span" variant="body.regular.2xs" className="text-base-500">
                            Exibindo {(props.rowCount || 0) < itemsPerPage ? props.rowCount : itemsPerPage} de {props.rowCount || '0'}
                        </Text>
                    </div>
                ) : null)}
            <Pagination
                count={pages}
                color="secondary"
                defaultPage={1}
                page={props.page || 0}
                boundaryCount={isMobile && props?.rowCount! > MAX_COUNT_ITEMS ? 0 : undefined}
                shape="rounded"
                showFirstButton={true}
                showLastButton={true}
                onChange={handleChangePage}
                disabled={itemsPerPage >= total}
            />
            {props.loading && <Spinner size={24} fixed={false} parentClasses="!justify-start" />}
        </footer>
    );
};

export default DatatableFooter;
