import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { GraphqlByPk } from 'types/graphql';
import { Project } from 'types/models/project';
import project, { getProjectKey } from './graphql';

const useGetProject = (projectId?: number) => {
    const getProject = () => apiGraphQl<GraphqlByPk<Project>>(project.getProject, { id: projectId }).then(({ project }) => project);

    return useQuery(getProjectKey(projectId), getProject, { enabled: Boolean(projectId) });
};

export default useGetProject;
