// Dependencies
import { useQuery } from 'react-query';
import api from 'services/api';

// Helpers
import { Provider } from 'types/models/provider';
import { Task } from 'types/models/task';
import { TimelineBudget, TimelineStatusCreation, TimelineType } from 'types/models/timeline';
import { getScopeKey } from './graphql';

export type GetScopeResponse = {
    id: number;
    provider: Provider | null;
    budget: TimelineBudget | null;
    startDate: string | null;
    endDate: string | null;
    type: TimelineType;
    progressPercentage: number;
    tasks: Task[];
    statusCreation: TimelineStatusCreation;
};

const useGetTaskPlanning = (projectProposalTypeId?: number, canGet?: boolean, projectTypeTimelineId?: string | number) => {
    const endpoint = !!projectTypeTimelineId ? `/project-types/${projectProposalTypeId}/timelines/${projectTypeTimelineId}` : `/project-types/${projectProposalTypeId}/timelines/scope`;

    const fetcher = async () => api.get<GetScopeResponse>(endpoint).then(({ data }) => data);

    return useQuery(getScopeKey(projectProposalTypeId), fetcher, { enabled: Boolean(projectProposalTypeId) && canGet });
};

export default useGetTaskPlanning;
