import React from 'react';
import RemoveButton from 'components/buttons/remove';
import { Control, Controller } from 'react-hook-form';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import DatePicker from 'components/core/datepicker';
import Editor from 'components/core/form/editor';
import { Option } from 'types/general';
import { attentionPointTypes } from 'utils/statics';

type AttentionPointFieldsProps = {
    backendId?: number;
    people: Option<number>[];
    control: Control<any, any>;
    fieldNamePrefix?: any;
    errorPrefix?: any;
    onDelete?: () => void;
};

const AttentionPointFields = ({ backendId, control, errorPrefix, people, fieldNamePrefix, onDelete }: AttentionPointFieldsProps) => {
    return (
        <div className="border border-base-300 p-4 rounded-2xl mb-2 group relative">
            {onDelete && !backendId && <RemoveButton onClick={onDelete} />}
            <Controller
                name={fieldNamePrefix ? `${fieldNamePrefix}.task.title` : 'task.title'}
                control={control}
                render={({ field }) => <Input {...field} error={errorPrefix?.task?.title?.message} label="Título" parentClassName="mb-4" />}
            />
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                <Controller
                    name={fieldNamePrefix ? `${fieldNamePrefix}.type` : 'type'}
                    control={control}
                    render={({ field }) => {
                        const value = attentionPointTypes.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={attentionPointTypes}
                                label="Tipo"
                                placeholder="Selecione uma opção"
                                error={errorPrefix?.type?.message}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
                <Controller
                    name={fieldNamePrefix ? `${fieldNamePrefix}.task.deadline` : 'task.deadline'}
                    control={control}
                    render={({ field }) => <DatePicker error={errorPrefix?.task?.deadline?.message} label="Data" placeholderText="Selecione uma data" {...field} />}
                />
                <Controller
                    name={fieldNamePrefix ? `${fieldNamePrefix}.task.person` : 'task.person'}
                    control={control}
                    render={({ field }) => {
                        const value = people.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={people}
                                label="Pessoa"
                                placeholder="Selecione uma opção"
                                error={errorPrefix?.task?.person?.message}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
            </div>
            <Controller
                name={fieldNamePrefix ? `${fieldNamePrefix}.task.description` : 'task.description'}
                control={control}
                render={({ field }) => <Editor {...field} label="Descrição" error={errorPrefix?.task?.description?.message} />}
            />
        </div>
    );
};

export default AttentionPointFields;
