// Dependencies
import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Helpers
import api from 'services/api';
import { createChecklistKey, getChecklistKey, getProjectProposalTypeKey } from './graphql';

type Payload = Array<{
    title: string;
    mandatory: boolean;
    order: number;
    timeline: number;
    proposalType: number;
    tasks: Array<{
        title: string;
        mandatory: boolean;
        order: number;
        timeline: number;
        proposalType: number;
    }>;
}>;

const useCreateOrUpdateChecklist = (projectProposalTypeId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: Payload) => api.post('/tasks/checklist', payload);

    return useMutation(createChecklistKey(projectProposalTypeId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectProposalTypeKey(projectProposalTypeId));
            queryClient.invalidateQueries(getChecklistKey(projectProposalTypeId?.toString()));

            showToast('Checklist atualizado com sucesso', 'success');

            navigate(-1);
        },
        onError: () => {
            showToast('Ocorreu um erro ao atualizar o checklist', 'danger');
        }
    });
};

export default useCreateOrUpdateChecklist;
