// Dependencies
import { useQuery } from 'react-query';
import api from 'services/api';

// Helpers
import apiGraphQl from 'services/graphql';
import { Company } from 'types/models/company';
import provider, { getProviderKey } from './graphql';
import { getProviderQueryFields } from './utils';
import { Provider } from 'types/models/provider';

type GetProviderResponse = {
    provider: Partial<Company> & Partial<Provider>;
};

const useGetProvider = (props: { providerId?: string; isDetails?: boolean }) => {
    const { providerId, isDetails = false } = props;

    const request = async () => {
        if (isDetails) {
            const { data } = await api.get<Partial<Provider>>(`/providers/${providerId}`);

            return data;
        }

        const { provider: data } = await apiGraphQl<GetProviderResponse>(provider.getProvider(getProviderQueryFields), { providerId });

        return data;
    };

    return useQuery(getProviderKey(providerId), request, { enabled: !!providerId });
};

export default useGetProvider;
