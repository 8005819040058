import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';
import operational, { getProviderOptionsKey } from './graphql';
import { Option } from 'types/general';

type Response = { provider: Option[] };

const useGetProviderOptions = (isExternal?: boolean, isEnabled = false) => {
    const fetcher = async () => apiGraphQl<Response>(operational.getProviderOptions(isExternal)).then(({ provider }) => provider);

    return useQuery(getProviderOptionsKey, fetcher, { enabled: !!isExternal || isEnabled });
};

export default useGetProviderOptions;
