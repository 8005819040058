import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { CommercialBudgetResponse, UpdateBudgetPayload } from 'types/models/commercial';
import { useOutletContext, useParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { formatMoney } from 'utils/money';
import CurrencyInput from 'components/core/form/currency';
import DatePicker from 'components/core/datepicker';
import { ProjectTypeEnum } from 'types/models/project';
import { NumberFormatValues } from 'react-number-format';

type ResumeTableProps = {
    contractValue: number;
    isDisabled: boolean;
};

const ResumeTable = ({ contractValue, isDisabled }: ResumeTableProps) => {
    const { projectTypeId } = useParams();
    const { control, formState } = useFormContext<UpdateBudgetPayload>();

    const ctx = useOutletContext<{ commercialBudget: CommercialBudgetResponse }>();

    const handleChangeValue = (onChange: (...event: any[]) => void) => (value: NumberFormatValues) => {
        return onChange(value.floatValue);
    };
    const isComplementaryProject = projectTypeId === String(ProjectTypeEnum.ComplementaryProjects);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="text-secondary-500 pl-6 w-[400px]">N° CONTRATO</TableCell>
                        <TableCell className="text-secondary-500 w-[215px]">CLIENTE</TableCell>
                        <TableCell className="text-secondary-500 text-center" colSpan={3}>
                            PROJETO
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ '& td:first-of-type': { paddingLeft: '24px' } }}>
                    <TableRow>
                        <TableCell className="!text-xs !text-base-500">{Boolean(ctx.commercialBudget?.code) ? `#${ctx.commercialBudget?.code}` : '-'}</TableCell>
                        <TableCell className="!text-xs !text-base-500">{ctx.commercialBudget?.customer.title || '-'}</TableCell>
                        <TableCell className="text-center !text-xs !text-base-500" colSpan={3}>
                            {ctx?.commercialBudget?.title || '-'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="text-secondary-500 pl-6 w-[400px]">VALOR CONTRATO</TableCell>
                        <TableCell className="text-secondary-500 w-[215px]">{isComplementaryProject ? 'ÁREA EFETIVA' : 'ÁREA'}</TableCell>
                        <TableCell className="text-secondary-500 w-[215px]">PRAZO (MESES)</TableCell>
                        <TableCell className="text-secondary-500 w-[250px]">DATA</TableCell>
                        <TableCell className="text-secondary-500">FATOR</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ '& td:first-of-type': { paddingLeft: '24px' } }}>
                    <TableRow>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(contractValue)}</TableCell>
                        <TableCell>
                            <Controller
                                control={control}
                                name="footage"
                                render={({ field }) => (
                                    <CurrencyInput
                                        name={field.name}
                                        ref={field.ref}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        onValueChange={handleChangeValue(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors.footage?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell>
                            <Controller
                                name="deadline"
                                render={({ field }) => (
                                    <CurrencyInput
                                        ref={field.ref}
                                        name={field.name}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        onValueChange={handleChangeValue(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors.deadline?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell>
                            <Controller
                                control={control}
                                name="date"
                                render={({ field }) => <DatePicker {...field} placeholderText="Selecione uma data" error={formState.errors.date?.message} disabled={isDisabled} className="!text-xs" />}
                            />
                        </TableCell>
                        <TableCell>
                            <Controller
                                control={control}
                                name="factor"
                                render={({ field }) => (
                                    <CurrencyInput
                                        ref={field.ref}
                                        name={field.name}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        onValueChange={handleChangeValue(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors.factor?.message}
                                    />
                                )}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default ResumeTable;
