import React from 'react';
import TableCell from '@mui/material/TableCell/TableCell';
import ListPage from 'pages/private/crud/list';
import dictionary from 'utils/dictionary';
import { CrudPageProps } from 'types/graphql';
import CreateOrUpdatePagesPage from 'pages/private/pages/create-or-update';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';

const [list]: CrudPageProps[] = [
    {
        graphql: {
            table: 'page',
            searchableField: 'title',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'title',
                    label: dictionary.content.graphql['title']
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'slug',
                    label: dictionary.content.graphql['slug']
                },
                {
                    name: 'permalink',
                    label: dictionary.content.graphql['permalink']
                },
                {
                    name: 'controller',
                    label: dictionary.content.graphql['controller']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ]
        },
        title: 'Páginas'
    }
];

const pages: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Páginas',
        path: 'paginas',
        element: <Page Component={ListPage} code="paginas" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdatePagesPage} code="paginas" permissions={[Rule.Create]} withModal={true} />
            },
            {
                path: 'editar/:pageId',
                element: <Page Component={CreateOrUpdatePagesPage} code="paginas" permissions={[Rule.Update]} withModal={true} />
            }
        ]
    }
];

export default pages;
