// Helpers
import { WhereClause } from 'types/graphql';
import { State } from 'types/models/address';

export const getAllStatesOptionsKey = ['states', 'all', 'options'];
export const getStateKey = (variables?: WhereClause<Partial<State>>) => ['state', variables];

const queries = {
    getAll: `query GetStatesOptions {
        items: state(order_by: {name: asc}) {
            value: id
            label: name
        }
    }`,
    getState: `query ($where: state_bool_exp) {
        state(where: $where) {
            id
        }
    }`
};

export default queries;
