import { useQuery } from 'react-query';
import api from 'services/api';
import { AttentionPoint } from 'types/models/project';
import { getAttentionPointKey } from './graphql';

const useGetAttentionPoint = (proposalTypeId?: string, solicitationId?: string, attentionPointId?: string) => {
    const request = async () => api.get<AttentionPoint>(`/project-types/${proposalTypeId}/solicitations/${solicitationId}/attention-points/${attentionPointId}`).then(({ data }) => data);

    return useQuery(getAttentionPointKey(attentionPointId), request, { enabled: !!proposalTypeId && !!solicitationId && !!attentionPointId });
};

export default useGetAttentionPoint;
