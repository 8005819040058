import React from 'react';
import Text from '../text';
import { NavLink, useLocation } from 'react-router-dom';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import classNames from 'classnames';

type BreadcrumbsProps = {
    externalBreadcrumbs?: any;
    className?: string;
};
const Breadcrumb = ({ externalBreadcrumbs, className }: BreadcrumbsProps) => {
    const { pathname } = useLocation();
    const { breadcrumbs } = useBreadcrumb();

    const breadcrumbsData = externalBreadcrumbs || breadcrumbs;
    const containerClasses = classNames('flex items-baseline flex-wrap', className);

    return (
        <>
            <Text as="span" variant="body.regular.xs" className="text-base-500 italic mr-2">
                Você está em:
            </Text>
            <ul className={containerClasses}>
                {breadcrumbsData.map(({ breadcrumb, match }, index) => (
                    <li key={match?.pathname}>
                        {pathname === match?.pathname ? (
                            <Text as="span" variant="body.medium.sm" className="text-base-500">
                                {breadcrumb}
                            </Text>
                        ) : (
                            <NavLink to={match?.pathname} className="text-secondary-500 font-semibold text-sm hover:text-secondary-700 transition-[color]">
                                {breadcrumb}
                            </NavLink>
                        )}
                        {index !== breadcrumbsData.length - 1 && (
                            <Text as="span" className="mx-2 text-base-500" variant="body.regular.xs">
                                {'>'}
                            </Text>
                        )}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default Breadcrumb;
