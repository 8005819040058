import { MUIDataTableColumn } from 'mui-datatables';
import dictionary from 'utils/dictionary';
import { TH } from './components/proposal';
import Badge from 'components/core/badge';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { Link } from 'react-router-dom';
import Icon from 'components/core/icon';
import IconButton from '@mui/material/IconButton/IconButton';
import theme from 'settings/theme';
import { ProjectStatusEnum } from 'types/models/project';
import { ColorVariant } from 'types/colors';

export const projectFPsByProjectId = {
    config: {
        table: 'budget',
        searchableField: 'code',
        columns: [
            {
                name: 'id'
            },
            {
                name: 'code'
            },
            {
                name: 'title'
            },
            {
                name: 'customer.title'
            },
            {
                name: 'budget_has_types.project_type.title'
            },
            {
                name: 'budget_status'
            },
            {
                name: 'budget_versions.id'
            },
            {
                name: 'budget_versions.version'
            },
            {
                name: 'budget_versions.contract_value'
            },
            {
                name: 'budget_versions.direct_costs'
            },
            {
                name: 'budget_versions.indirect_costs'
            },
            {
                name: 'budget_versions.active'
            },
            {
                name: 'budget_versions.percentage_margin'
            },
            {
                name: 'budget_versions.margin'
            },
            {
                name: 'budget_versions.footage'
            },
            {
                name: 'budget_versions.deadline'
            },
            {
                name: 'budget_versions.project_type.title'
            },
            {
                name: 'budget_versions.project_type.id'
            },
            {
                name: 'budget_versions.budget_id'
            }
        ]
    },
    variables: (projectId?: string, page = 1) => ({
        limit: 999 as any,
        offset: 0,
        page,
        where: { project_id: { _eq: projectId } }
    })
};

export const projectFPColumns: MUIDataTableColumn[] = [
    {
        name: 'id',
        options: {
            display: 'excluded',
            customHeadRender: TH
        }
    },
    {
        name: 'code',
        label: dictionary.content.graphql['code'],
        options: {
            customHeadRender: TH
        }
    },
    {
        name: 'title',
        label: dictionary.content.graphql['title'],
        options: {
            customHeadRender: TH
        }
    },
    {
        name: 'budget_has_types',
        label: 'Programas',
        options: {
            customHeadRender: TH,
            customBodyRender: (value = []) => {
                return (
                    <div className="flex flex-wrap gap-2">
                        {value.map((program, index) => (
                            <Badge variant="info" key={index}>
                                {program.project_type.title}
                            </Badge>
                        ))}
                    </div>
                );
            }
        }
    },
    {
        name: 'actions',
        label: 'Ações',
        options: {
            customHeadRender: TH,
            customBodyRender: (_, tableMeta) => {
                const [id] = tableMeta.rowData;
                return (
                    <Tooltip placement="top" title="Visualizar FP">
                        <IconButton component={Link} className="hover:bg-primary-100 hover:bg-opacity-20" to={`/app/fp/${id}`}>
                            <Icon name="ico-show" width={16} height={16} color={theme.extend.colors.system.info[500]} />
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    }
];

export const updateProjectLabelsByProjectStatus = {
    [ProjectStatusEnum.Done]: {
        title: 'Finalizar contrato',
        label: 'Data da finalização',
        reasonLabel: 'Motivo'
    },
    [ProjectStatusEnum.Stopped]: {
        title: 'Paralisar contrato',
        label: 'Data da paralisação',
        reasonLabel: 'Motivo de paralisação'
    },
    [ProjectStatusEnum.Partial]: {
        title: 'Reiniciar contrato',
        label: 'Data do reinício',
        resonLabel: 'Motivo'
    },
    [ProjectStatusEnum.Canceled]: {
        title: 'Cancelar contrato',
        label: 'Data do cancelamento',
        reasonLabel: 'Motivo'
    }
};

export const projectBadgeStatuses = new Map([
    [ProjectStatusEnum.Tap, ['info', 'Aguardando']],
    [ProjectStatusEnum.Partial, ['primary', 'Em andamento']],
    [ProjectStatusEnum.Done, ['success', 'Finalizado']],
    [ProjectStatusEnum.Stopped, ['warning', 'Paralisado']],
    [ProjectStatusEnum.Canceled, ['error', 'Cancelado']],
    [ProjectStatusEnum.PreTap, ['info', 'Pre-tap']]
]);

export const generateProjectStatusBadge = (projectStatus?: ProjectStatusEnum) => {
    const [variant, value] = projectBadgeStatuses.get(projectStatus!) || [];

    if (!projectStatus) {
        return <span>-</span>;
    }

    return <Badge variant={variant as ColorVariant}>{value}</Badge>;
};
