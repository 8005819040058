import PageHeader from 'components/page/header';
import Calendar from './calendar';
import { Outlet } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import useConfig from 'store/config/use-config';
import { useMainContext } from 'pages/private/main/context';

const Schedule = () => {
    const { handleToggleSidebar } = useMainContext();

    const { config, setConfig } = useConfig();

    const wasClosed = useRef(false);

    const { operational } = config;

    useEffect(() => {
        // Closes sidebar and navbar
        if (!wasClosed.current) {
            wasClosed.current = true;

            // Closes principal menu
            handleToggleSidebar(true);

            setConfig('operational', { ...operational, isNavbarOpen: false, isSidebarOpen: false });
        }
    }, [setConfig, operational, handleToggleSidebar]);

    useEffect(() => {
        return () => {
            setConfig('operational', { ...operational, isNavbarOpen: true, isSidebarOpen: true });
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <PageHeader title="Cronograma" className="mb-7 mt-6" />
            <div className="bg-base-100 sm:h-[calc(100%-96px)]">
                <div className="-ml-6 -mr-6 min-h-[calc(100vh-267px)] sm:min-h-full sm:h-full">
                    <Calendar />
                </div>
            </div>
            <Outlet />
        </>
    );
};

export default Schedule;
