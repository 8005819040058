// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { getDailyReportsKey } from './graphql';

type Response = {
    daily_report: Array<{ order_daily_report: number }>;
};

const useGetDailyReports = (query: string, projectProposalTypeId?: number) => {
    const fetcher = async () => apiGraphQl<Response>(query).then(({ daily_report }) => daily_report[0]?.order_daily_report);

    return useQuery(getDailyReportsKey(projectProposalTypeId), fetcher, { enabled: Boolean(projectProposalTypeId) });
};

export default useGetDailyReports;
