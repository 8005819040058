// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { GraphqlPagination } from 'types/graphql';
import project, { projectSignpostsOptionsKey } from './graphql';

const useGetProjectSignpostsOptions = (signpost?: string) => {
    const getProjectSignpostsOptions = () => apiGraphQl<GraphqlPagination<Option>>(project.getProjectSignpostsOptions).then((data) => data.items);

    return useQuery(projectSignpostsOptionsKey, getProjectSignpostsOptions, { enabled: Boolean(signpost) });
};

export default useGetProjectSignpostsOptions;
