import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';

import api from 'services/api';
import { getAllDreKey } from 'services/queries/operationals/graphql';
import { ProjectDreResponse } from 'types/models/project';

const useGetProjectDreDetails = (projectTypeId: string) => {
    const { showToast } = useToast();

    const fetcher = async () => {
        const { data } = await api.get<ProjectDreResponse>(`/project-types/${projectTypeId}/isy`);

        return data;
    };

    return useQuery(getAllDreKey, fetcher, {
        onError: () => {
            showToast('Tivemos um problema ao obter os detalhes desse contato.', 'danger');
        }
    });
};

export default useGetProjectDreDetails;
