import { useCallback, useMemo, useState } from 'react';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Empty from 'components/empty';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import ExternalButton from 'components/buttons/external';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import dictionary from 'utils/dictionary';
import { ProjectProposalType, ProjectStatusEnum, ProjectTypePersonResource, ProjectTypePersonResourcePeriods } from 'types/models/project';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import ResourceProjectTable from '../resourcers-project/resource';
import useProjectTypeSlug from 'pages/private/operationals/hooks/use-project-type-slug';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import Icon from 'components/core/icon';
import ConfirmModal from 'components/core/modal/confirm';
import useDelete from 'services/queries/crud/use-delete';
import { getProjectKey, getProjectProposalTypeKey } from 'services/queries/projects/graphql';
import { useQueryClient } from 'react-query';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';
import ModalResourcePeriods from './modal-resource-periods';
import { formatMoney } from 'utils/money';

type ResourcesProps = {
    resourcesProp?: Partial<ProjectTypePersonResource>[];
    withActions?: boolean;
    withTitle?: boolean;
    className?: string;
    onAddResource?: () => void;
};

const Resources = ({ resourcesProp, className, withActions, withTitle, onAddResource }: ResourcesProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();
    const { projectProposalTypeId } = useParams();
    const { isSmallerThenTabletLandscape } = useResponsive();
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const [periods, setPeriods] = useState<Partial<ProjectTypePersonResourcePeriods>[]>();

    const resources = useMemo(() => resourcesProp || ctx?.projectProposalType?.project_type_person_resources, [resourcesProp, ctx]);

    const { isManagement } = useProjectTypeSlug();

    const tapReleased = useMemo(() => ctx?.project?.project_status?.slug === ProjectStatusEnum.PreTap, [ctx?.project?.project_status?.slug]);

    const handleAddResource = useCallback(() => {
        if (onAddResource) {
            return onAddResource();
        }

        if (tapReleased) {
            return alert('O TAP deste projeto ainda não foi liberado.');
        }

        const resourcesCount = ctx?.project?.proposal?.proposal_types?.reduce((acc: number, curr: Partial<ProjectProposalType>) => acc + (curr.resources_aggregate?.aggregate.count || 0), 0);

        if (isManagement && !resourcesCount && tapReleased) {
            return alert('Nenhum recurso foi adicionado à proposta');
        }

        navigate(`novo`);
    }, [ctx, navigate, onAddResource, tapReleased, isManagement]);

    const [resourceProjectIdToDelete, setResourceProjectIdToDelete] = useState<number | null>(null);

    const { mutateAsync: deleteResource, isLoading: isSubmitting } = useDelete({
        apiPayload: { endpoint: `/project-types/${projectProposalTypeId}/resources`, useApiTodelete: true }
    });

    const handleDelete = async () => {
        try {
            if (resourceProjectIdToDelete) {
                await deleteResource({ id: resourceProjectIdToDelete });

                setResourceProjectIdToDelete(null);

                queryClient.invalidateQueries(getProjectKey(ctx?.project?.id));
                queryClient.invalidateQueries(getProjectProposalTypeKey(+projectProposalTypeId!));
            }
        } catch (error) {
            console.log('handleDeleteDocument', error);
        }
    };

    return (
        <>
            <div>
                {isSmallerThenTabletLandscape && pathname.includes('recursos') && <Breadcrumb className="mb-4" />}
                {withTitle && (
                    <Text as="h6" variant="h6" className="text-heading mb-4">
                        Recursos
                    </Text>
                )}
                {Boolean(resources?.length) ? (
                    !isManagement ? (
                        <ResourceProjectTable resources={resources} />
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Text as="span" className="text-secondary-500">
                                                Pessoa
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-secondary-500">
                                                Valor Hora
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-secondary-500">
                                                Períodos
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-secondary-500">
                                                Atribuição
                                            </Text>
                                        </TableCell>
                                        {withActions && (
                                            <TableCell>
                                                <Text as="span" className="text-secondary-500">
                                                    Ações
                                                </Text>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resources?.map((resource) => {
                                        return (
                                            <TableRow key={resource.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell>
                                                    <ExternalButton onClick={() => null}>{resource.person?.name || '-'}</ExternalButton>
                                                    {Boolean(resource.person?.contracts?.length) && (
                                                        <Text as="span" variant="body.regular.xs" className="text-base-500 italic">
                                                            {resource.person?.contracts?.map((item) => item.job_position?.title).join(', ')}
                                                        </Text>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Text as="span" className="text-base-500">
                                                        {formatMoney(resource.hour_value)}
                                                    </Text>
                                                </TableCell>
                                                <TableCell>
                                                    {Boolean(resource.project_type_person_resource_periods?.length) ? (
                                                        <ExternalButton onClick={() => setPeriods(resource.project_type_person_resource_periods)}>Ver períodos</ExternalButton>
                                                    ) : (
                                                        <Text as="span" className="text-base-500">
                                                            -
                                                        </Text>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Text as="span" className="text-base-500">
                                                        {Object.values(dictionary.content.typeManagementInformations)[(resource.type_management_information || 0) - 1] || '-'}
                                                    </Text>
                                                </TableCell>
                                                {withActions && (
                                                    <TableCell>
                                                        <div className="flex">
                                                            <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                                <IconButton className="hover:bg-system-warning-100 hover:bg-opacity-30" onClick={() => navigate(`editar/${resource?.id}`)}>
                                                                    <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                                                <IconButton
                                                                    className="hover:bg-system-danger-100 hover:bg-opacity-30"
                                                                    onClick={() => setResourceProjectIdToDelete(resource?.id || null)}>
                                                                    <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {withActions && (
                                    <TableFooter>
                                        <TableRow classes={{ root: 'border-t' }}>
                                            <TableCell colSpan={6} classes={{ root: 'border-none px-0' }}>
                                                <AddButton onClick={handleAddResource}>Novo recurso</AddButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                )}
                            </Table>
                        </TableContainer>
                    )
                ) : (
                    <Empty title="Nenhum recurso cadastrado." buttonLabel={withActions ? 'Alocar recurso' : undefined} className={className} onClick={withActions ? handleAddResource : undefined} />
                )}
                <Outlet context={ctx} />
                {Boolean(resourceProjectIdToDelete) && (
                    <ConfirmModal
                        title="Apagar recurso"
                        description="Você tem certeza que deseja apagar este recurso?"
                        onClose={() => setResourceProjectIdToDelete(null)}
                        isLoading={isSubmitting}
                        onConfirmAction={handleDelete}
                    />
                )}
                {Boolean(periods?.length) && <ModalResourcePeriods periods={periods!} onClose={() => setPeriods(undefined)} />}
            </div>
        </>
    );
};

export default Resources;
