import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { dateReduceFn } from './management/recursive-management';
import { Controller, FormProvider } from 'react-hook-form';
import { useTimelineForm as useTimelineFormManagement } from '../hooks/use-timeline-form-management';
import { useTimelineForm as useTimelineFormProject } from '../hooks/use-timeline-form-project';
import { Option } from 'types/general';
import useProjectTypeSlug from 'pages/private/operationals/hooks/use-project-type-slug';
import { predictedTimeReduceFn } from './project/recursive-project';
import { ProjectProposalType } from 'types/models/project';
import { CamelCase } from 'types/utils';
import { TimelineStatusCreation } from 'types/models/timeline';
import Empty from 'components/empty';
import isEmpty from 'lodash/isEmpty';
import useResponsive from 'hooks/responsive/use-responsive';
import TimelineOptions from './management/options';
import TimelineFile from './management/file';
import TimelineForm from './form';
import useGetPeopleByProjectTypeOptions from 'services/queries/people/use-get-people-options-by-project-proposal-type';

type TimelineCreateOrUpdateProps = {
    defaultValues: any;
    projectId?: number;
    isBuilding: boolean;
    isSubmitting: boolean;
    providers: Option[];
    proposalType?: Partial<CamelCase<ProjectProposalType>>;
    statusCreation?: TimelineStatusCreation;
    onSubmit: (data: any, callSuccessAction?: boolean) => void;
    isTimelineType?: boolean;
};

export const getItemsWithPerson = (tree) => {
    const arr = tree.flatMap((item) => {
        if (item.items) {
            return [item, ...getItemsWithPerson(item.items)];
        }

        return item;
    });

    return arr.filter((item) => item.person);
};

const TimelineCreateOrUpdate = ({ statusCreation, projectId, defaultValues, isBuilding, isSubmitting, providers, proposalType, onSubmit, isTimelineType }: TimelineCreateOrUpdateProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isMobile } = useResponsive();
    const { projectTypeTimelineId, taskPlanningId, projectProposalTypeId } = useParams();

    const { isManagement, isProject, isComplementaryProject } = useProjectTypeSlug();

    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [predictedTime, setPredictedTime] = useState(0);
    const [mode, setMode] = useState<'normal' | 'file'>();

    const isCreate = pathname.includes('novo');
    const isManagementSchedule = useMemo(() => pathname.includes('cronogramas') && isManagement, [pathname, isManagement]);

    const { data: people = [] } = useGetPeopleByProjectTypeOptions(+projectProposalTypeId!);

    const handleBack = () => navigate(-1);

    const handleForm = async (data: any, e?: any, callSuccessAction = true) => {
        const isValid = await methods.trigger();

        if (!isValid || isEmpty(methods.formState.touchedFields)) {
            return;
        }

        const isOutOfPlan = numberOfManagementHours() > (proposalType?.numberOfManagementHours || 0) || numberOfExecutionHours() > (proposalType?.numberOfExecutionHours || 0);

        const payload = {
            ...data,
            startDate,
            endDate,
            predictedTime,
            ...(isOutOfPlan && (isProject || isComplementaryProject || !isManagementSchedule) && { hasProblem: true })
        };

        onSubmit(payload, callSuccessAction);
    };

    const useTimelineForm = isManagementSchedule ? useTimelineFormManagement : useTimelineFormProject;

    const { handleSubmit, methods } = useTimelineForm(handleForm, defaultValues, isTimelineType);

    const values = methods.getValues();

    const calculateDates = useCallback(() => {
        const startDate = ((values.items as any) || [])?.filter((item) => !!item.startDate).reduce(dateReduceFn('startDate'), undefined);
        const endDate = ((values.items as any) || [])?.filter((item) => !!item.endDate).reduce(dateReduceFn('endDate'), undefined);

        if (startDate) {
            setStartDate(startDate);
        }

        if (endDate) {
            setEndDate(endDate);
        }
    }, [values]);

    const numberOfManagementHours = () => {
        const itemsWithPerson = getItemsWithPerson(values.items);

        const arr = itemsWithPerson.filter((item) => people.find((person) => person.value === item.person && !!person.manager));

        return arr.reduce((acc, curr) => acc + curr.predictedTime, 0);
    };

    const numberOfExecutionHours = () => {
        const itemsWithPerson = getItemsWithPerson(values.items);

        const arr = itemsWithPerson.filter((item) => people.find((person) => person.value === item.person && !person.manager));

        return arr.reduce((acc, curr) => acc + curr?.predictedTime, 0);
    };

    const valueOfExecutionHours = () => {
        const itemsWithPerson = getItemsWithPerson(values.items);

        const arr = itemsWithPerson
            .map((item) => {
                const person = people.find((person) => person.value === item.person && !person.manager);
                return person ? { ...item, person } : null;
            })
            .filter((item) => item !== null);

        const totalValue = arr.reduce((acc, curr: any) => {
            const predictedTime = curr?.predictedTime || 0;
            const hourValue = curr?.person?.hourValue || 0;
            return acc + hourValue * predictedTime;
        }, 0);

        return totalValue;
    };

    const valueOfManagementHours = () => {
        const itemsWithPerson = getItemsWithPerson(values.items);

        const arr = itemsWithPerson
            .map((item) => {
                const person = people.find((person) => person.value === item.person && !!person.manager);
                return person ? { ...item, person } : null;
            })
            .filter((item) => item !== null);

        const totalValue = arr.reduce((acc, curr: any) => {
            const predictedTime = curr?.predictedTime || 0;
            const hourValue = curr?.person?.hourValue || 0;
            return acc + hourValue * predictedTime;
        }, 0);

        return totalValue;
    };

    const calculatePredictedTimeTotal = useCallback(() => {
        if (!isManagementSchedule) {
            const predictedTime = ((values.items as any) || [])?.reduce(predictedTimeReduceFn, 0);

            setPredictedTime(predictedTime);
        }
    }, [isManagementSchedule, values]);

    const hasProviderError = !!(methods as any).formState.errors.provider?.message;

    useEffect(() => {
        if (defaultValues) {
            methods.reset(defaultValues);
        }
    }, [defaultValues, methods]);

    useEffect(() => {
        calculateDates();
    }, [calculateDates]);

    useEffect(() => {
        calculatePredictedTimeTotal();
    }, [calculatePredictedTimeTotal]);

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames={mode !== 'normal' && isCreate ? 'w-[750px]' : 'min-w-[90%]'}
            headerLeft={
                <div className="flex flex-wrap gap-2 items-baseline justify-between mr-4">
                    <Text as="h3" variant="h4" className="text-heading">
                        {projectTypeTimelineId || taskPlanningId ? 'Atualizar' : 'Novo'} {isManagementSchedule ? 'cronograma' : 'planejamento de tarefas'}
                    </Text>
                    {isManagementSchedule && statusCreation !== TimelineStatusCreation.Finalized && (
                        <Controller
                            name="provider"
                            control={methods.control as any}
                            render={({ field }) => {
                                const value = providers.find((item: any) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={providers}
                                        placeholder="Selecione um fornecedor/planejamento de tarefas"
                                        selectStyles={{
                                            control: (base) => ({
                                                ...base,
                                                ...(hasProviderError ? { borderWidth: 1, borderColor: palette.error[500] } : { borderWidth: 0 })
                                            }),
                                            placeholder: (base) => ({ ...base, fontSize: isMobile ? 14 : 18 }),
                                            singleValue: (base) => ({ ...base, fontSize: isMobile ? 14 : 18 })
                                        }}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    )}
                </div>
            }
            onClose={handleBack}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : isManagementSchedule && statusCreation === TimelineStatusCreation.Finalized ? (
                <Empty title="Cronograma finalizado para edição" className="px-6 pb-6" />
            ) : (
                <>
                    {isCreate && isManagementSchedule ? (
                        <>
                            {mode === undefined && <TimelineOptions onSelectOption={setMode} />}
                            {mode === 'file' && (
                                <form className="px-3 sm:px-6 pb-6" onSubmit={handleSubmit}>
                                    <TimelineFile isSubmitting={isSubmitting} {...methods} />
                                </form>
                            )}
                            {mode === 'normal' && (
                                <FormProvider {...(methods as any)}>
                                    <form className="px-3 sm:px-6 pb-6" onSubmit={handleSubmit}>
                                        <TimelineForm
                                            projectId={projectId}
                                            proposalType={proposalType}
                                            isSendButtonDisabled={isEmpty(methods.formState.dirtyFields)}
                                            isSubmitting={isSubmitting}
                                            endDate={endDate}
                                            startDate={startDate}
                                            numberOfExecutionHours={numberOfExecutionHours}
                                            numberOfManagementHours={numberOfManagementHours}
                                            valueOfExecutionHours={valueOfExecutionHours}
                                        />
                                    </form>
                                </FormProvider>
                            )}
                        </>
                    ) : (
                        <FormProvider {...(methods as any)}>
                            <form className="px-3 sm:px-6 pb-6" onSubmit={handleSubmit}>
                                <TimelineForm
                                    projectId={projectId}
                                    proposalType={proposalType}
                                    isSendButtonDisabled={isEmpty(methods.formState.dirtyFields)}
                                    isSubmitting={isSubmitting}
                                    endDate={endDate}
                                    startDate={startDate}
                                    numberOfExecutionHours={numberOfExecutionHours}
                                    numberOfManagementHours={numberOfManagementHours}
                                    valueOfExecutionHours={valueOfExecutionHours}
                                    valueOfManagementHours={valueOfManagementHours}
                                />
                            </form>
                        </FormProvider>
                    )}
                </>
            )}
        </Modal>
    );
};

export default memo(TimelineCreateOrUpdate);

// Phases:
// - adicionar tarefas + progresso + liberação de pagamento
