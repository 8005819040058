import { ForgotPasswordPayload } from 'types/models/user';
import useForgotPassword from 'services/queries/forgot-password/use-forgot-password';

import ForgotPasswordForm from '../components/form/forgot-password-form';
import PublicLayout from '../layout';

const ForgotPasswordPage = () => {
    const { mutateAsync: mutateForgotPassword, isLoading: isSubmitting } = useForgotPassword();

    const handleSubmit = async (data: ForgotPasswordPayload) => {
        try {
            await mutateForgotPassword(data);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <PublicLayout>
            <ForgotPasswordForm isLoading={isSubmitting} onSubmit={handleSubmit} />
        </PublicLayout>
    );
};

export default ForgotPasswordPage;
