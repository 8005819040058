import React from 'react';
import Text from 'components/core/text';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import Modal from 'components/core/modal';
import { useNavigate } from 'react-router-dom';

type ForbiddenProps = {
    title: string;
    description: string;
    isOpenWithModal: boolean;
};

const Content = ({ description, title }: Omit<ForbiddenProps, 'isOpenWithModal'>) => {
    const { palette } = useTheme();

    return (
        <>
            <Text as="strong" variant="h2" className="text-heading flex items-baseline mb-4">
                {title}
            </Text>
            <Icon name="ico-lock" color={palette.heading} className="mb-4" width={24} height={24} />
            <Text as="p" variant="body.medium.2xs" className="text-base-700">
                {description}
            </Text>
        </>
    );
};

const Forbidden = ({ title, description, isOpenWithModal }: ForbiddenProps) => {
    const navigate = useNavigate();

    const handleClose = () => navigate(-1);

    if (isOpenWithModal) {
        return (
            <Modal onClose={handleClose} contentClassnames="w-[600px] flex flex-col items-center justify-center p-8">
                <Content title={title} description={description} />
            </Modal>
        );
    }

    return (
        <div className="w-full h-full flex flex-col items-center justify-center p-4">
            <Content title={title} description={description} />
        </div>
    );
};

export default Forbidden;
