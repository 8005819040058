import ListPage from 'pages/private/crud/list';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import { useCustomerDetails } from 'pages/private/clients/useCustomerDetails';
import Spinner from 'components/core/spinner';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';

const CustomerDetailsProject = ({ page }: WithPagePermissionsProps) => {
    const { id } = useCustomerDetails();

    if (!id) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    const listConfig: CrudPageProps = {
        graphql: {
            table: 'project',
            searchableField: 'title',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'code',
                    label: dictionary.content.graphql['code']
                },
                {
                    name: 'title',
                    label: dictionary.content.graphql['title']
                },
                {
                    name: 'branch.name',
                    label: dictionary.content.graphql['branch.name']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    },
                    customRoutePath: (item) => `/app/contratos/${item?.id}`
                }
            ],
            where: {
                customer_id: {
                    _eq: id
                }
            }
        },
        title: 'Contratos',
        withBorder: true,
        hideCreateButton: true
    };

    return <ListPage page={page} {...listConfig} />;
};

export default CustomerDetailsProject;
