import { useShowApiErrors } from 'hooks/error/show-api-errors';
import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { BudgetAdditiveContractPayload, CommercialBudgetResponse } from 'types/models/commercial';

const useCreateBudgetAdditiveContract = (budgetId: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const { showToastErrors } = useShowApiErrors();

    const request = async (payload: BudgetAdditiveContractPayload) => {
        const { data } = await api.post<CommercialBudgetResponse>(`/budgets/${budgetId}/extra`, payload);

        return data;
    };

    return useMutation(request, {
        onSuccess: (data) => {
            showToast('Aditivo de contrato criado com sucesso!', 'success');
            navigate(`/app/contratos/${data.project.id}`);
        },
        onError: (err) => {
            showToastErrors(err);
        }
    });
};

export default useCreateBudgetAdditiveContract;
