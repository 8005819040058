import React from 'react';

// Dependencies
import { useForm } from 'react-hook-form';
import useTheme from '@mui/material/styles/useTheme';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import External from './external';
import Internal from './internal';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';

// Helpers
import { Option } from 'types/general';
import meetingParticipantSchema from '../../utils/schema';
import { ParticipantPayload } from 'contexts/meeting-record/types';

type ContentProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    participants: ParticipantPayload;
    people: Option[];
    onGoBack: () => void;
    onSubmit: (data: any) => void;
};

export type ParticipantsPayload = {
    internal: ParticipantPayload['internal'];
    external: ParticipantPayload['external'];
};

const Content = ({ isBuilding, isSubmitting, participants, people, onGoBack, onSubmit }: ContentProps) => {
    const { palette } = useTheme();

    const methods = useForm<ParticipantsPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: participants,
        resolver: yupResolver(meetingParticipantSchema)
    });

    return (
        <div>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="p-[30px]">
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            Participantes
                        </Text>
                        <Internal {...methods} people={people} />
                        <External {...methods} />
                    </div>
                    <div className="border-t p-2 flex justify-between">
                        <Button
                            startIcon={<Icon name="ico-arrow-left" width={18} color={palette.grey[700]} />}
                            type="button"
                            variant="text"
                            color="inherit"
                            className="min-w-[100px]"
                            onClick={onGoBack}
                        >
                            Voltar
                        </Button>
                        <Button
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            endIcon={<Icon name="ico-arrow-right" width={18} color={isSubmitting ? palette.grey[400] : palette.secondary[500]} />}
                            type="submit"
                            variant="text"
                            color={isSubmitting ? 'inherit' : 'secondary'}
                            className="min-w-[100px]"
                        >
                            Avançar
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Content;
