import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import apiGraphQl from 'services/graphql';

import useUploadFile from 'services/queries/files/use-upload-file';
import { getPeopleDetailsKey } from './graphql';

export const useChangePersonImage = (props: { id?: string }) => {
    const { id } = props || {};
    const navigate = useNavigate();
    const { showToast } = useToast();
    const client = useQueryClient();

    const { uploadFile } = useUploadFile();

    const request = async (payload: File) => {
        let fileId;

        await uploadFile(
            payload,
            ({ id }) => {
                fileId = id;
            },
            true
        );

        if (!fileId) {
            throw new Error();
        }

        return apiGraphQl(
            `mutation changePersonFile($personId: Int!, $fileId: Int!) {
            item: update_person_by_pk(pk_columns: {id: $personId}, _set: {file_id: $fileId}) {
              id
              file_id
            }
          }
          `,
            { personId: Number(id), fileId }
        );
    };

    return useMutation(request, {
        onSuccess: () => {
            client.invalidateQueries(getPeopleDetailsKey(id));

            navigate(-1);
            showToast('Foto atualizada com sucesso!', 'success');
        },
        onError: () => {
            showToast('Tivemos um problema ao atualizar a foto. Tente novamente.', 'danger');
        }
    });
};
