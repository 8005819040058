import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';

import api from 'services/api';

const useDeleteFile = () => {
    const { showToast } = useToast();
    const request = async (fileId: number) => api.delete(`/files/${fileId}`);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Arquivo deletado com sucesso', 'success');
        },
        onError: () => {
            showToast('Erro ao deletar arquivo', 'danger');
        }
    });
};

export default useDeleteFile;
