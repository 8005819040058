import React, { useCallback, useMemo, useState } from 'react';
import Icon from 'components/core/icon';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';
import useDelete from 'services/queries/crud/use-delete';
import { CustomProfile } from 'pages/private/crud/list/list';
import Spinner from 'components/core/spinner';
import PersonContractStatus, { PersonContractType } from 'components/core/modal/person-contract-status';
import ConfirmModal from 'components/core/modal/confirm';
import useResetPasswordPeople from 'services/queries/people/use-reset-password-people';

type PersonDetailsSidebarActionsProps = {
    hasUser: boolean;
    personId?: number;
    mainContractId?: number;
};

const PersonDetailsSidebarActions = ({ personId, mainContractId, hasUser }: PersonDetailsSidebarActionsProps) => {
    const { palette } = useTheme();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [showResetPasswordModal, setResetPasswordModal] = useState(false);

    const [contractFlowType, setContractFlowType] = useState<PersonContractType>();

    const navigate = useNavigate();

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useDelete<CustomProfile>({
        apiPayload: { endpoint: `/people`, useApiTodelete: true }
    });

    const { mutate: resetPeoplePassword, isLoading: isSubmittingPassword } = useResetPasswordPeople(personId!);

    const handleToggleDeleteModal = (value: boolean) => () => setShowDeleteModal(value);
    const handleToggleResetPasswordModal = (value: boolean) => () => setResetPasswordModal(value);

    const handleConfirmDelete = useCallback(async () => {
        handleToggleDeleteModal(false)();

        await deleteItem({ id: personId! });
        navigate('/app/pessoas');
    }, [deleteItem, navigate, personId]);

    const handleConfirmResetPassword = useCallback(async () => {
        handleToggleResetPasswordModal(false)();

        resetPeoplePassword({ id: personId! });
    }, [resetPeoplePassword, personId]);

    const handleContractType = () => setContractFlowType(undefined);

    const content: MenuItem[] = useMemo(() => {
        const generateRoute = (path: string) => `/app/pessoas/${personId}/${path}`;
        const arr = [
            {
                label: 'Editar',
                icon: {
                    color: palette.grey[500],
                    name: 'ico-edit'
                },
                onClick: () => navigate(generateRoute('editar'))
            },
            {
                label: 'Excluir',
                icon: {
                    color: palette.grey[500],
                    name: 'ico-trash'
                },
                onClick: () => setShowDeleteModal(true)
            },
            {
                label: 'Alterar foto',
                icon: {
                    color: palette.grey[500],
                    name: 'ico-image'
                },
                onClick: () => navigate(generateRoute('alterar-foto'))
            },
            {
                label: 'Novo contrato',
                icon: {
                    color: palette.grey[500],
                    name: 'ico-plus'
                },
                onClick: () => navigate(generateRoute('contratos/novo'))
            },
            ...(hasUser
                ? [
                      {
                          label: 'Resetar senha',
                          icon: {
                              color: palette.grey[500],
                              name: 'ico-hide'
                          },
                          onClick: () => setResetPasswordModal(true)
                      }
                  ]
                : []),
            ...(!!mainContractId
                ? [
                      {
                          label: 'Situação Contratual',
                          icon: {
                              color: palette.grey[500],
                              name: 'ico-edit'
                          },
                          onClick: () => setContractFlowType('situation')
                      },
                      {
                          label: 'Promoção',
                          icon: {
                              color: palette.grey[500],
                              name: 'ico-dollar'
                          },
                          onClick: () => setContractFlowType('promotion')
                      },
                      {
                          label: 'Demissão',
                          icon: {
                              color: palette.grey[500],
                              name: 'ico-minus'
                          },
                          onClick: () => setContractFlowType('resignation')
                      }
                  ]
                : [])
        ];

        return arr.map((item) => ({
            ...item,
            onClick: () => {
                handleClose();
                item.onClick();
            }
        }));
    }, [mainContractId, navigate, palette, hasUser, personId]);

    return (
        <>
            <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<Icon name="ico-chevron-down" width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} />
            {isLoadingDelete && <Spinner fixed={true} parentClasses="bg-base-100/[.6] z-[999]" />}
            {contractFlowType && <PersonContractStatus type={contractFlowType} onClose={handleContractType} contractId={mainContractId!} personId={personId!} />}
            {showDeleteModal && (
                <ConfirmModal
                    title="Apagar pessoa?"
                    description="Você tem certeza que deseja apagar esta pessoa?"
                    isLoading={isLoadingDelete}
                    onClose={handleToggleDeleteModal(false)}
                    onConfirmAction={handleConfirmDelete}
                />
            )}
            {showResetPasswordModal && (
                <ConfirmModal
                    title="Resetar senha?"
                    description="Você tem certeza que deseja resetar a senha desta pessoa?"
                    isLoading={isSubmittingPassword}
                    onClose={handleToggleResetPasswordModal(false)}
                    onConfirmAction={handleConfirmResetPassword}
                />
            )}
        </>
    );
};

export default PersonDetailsSidebarActions;
