import { useCallback, useEffect, useMemo, useState } from 'react';

// Dependencies
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { Display, MUIDataTableColumn } from 'mui-datatables';
import useTheme from '@mui/material/styles/useTheme';
import TableCell from '@mui/material/TableCell/TableCell';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import IconButton from '@mui/material/IconButton/IconButton';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Badge from 'components/core/badge';
import AddButton from 'components/buttons/add';
import Datatable from 'components/core/table/datatable';
import ExternalButton from 'components/buttons/external';

// Helpers
import { Status } from 'types/general';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { GraphqlPaginationVariables } from 'types/graphql';
import { ProjectMeetingRecord } from 'types/models/project';
import useGetMeetingRecords from 'services/queries/projects/use-get-meeting-records';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import useDelete from 'services/queries/crud/use-delete';
import { useQueryClient } from 'react-query';
import ConfirmModal from 'components/core/modal/confirm';
import { getProjectMeetingRecordsKey } from 'services/queries/projects/graphql';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';

type MeetingRecordsProps = {
    projectProposalTypeIdProp?: number;
    className?: string;
    elevation?: number;
    withTitle?: boolean;
    projectIdProp?: number;
    withActions?: boolean;
};

const infoButton = { classes: { child: 'text-system-info-100' } };
const warningButton = { classes: { child: 'text-system-warning-100' } };
const dangerButton = { classes: { child: 'text-system-danger-100' } };
const secondaryButton = { classes: { child: 'text-secondary-100' } };

const TH = (value: any) => (
    <TableCell key={value.label}>
        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
            {value.label}
        </Text>
    </TableCell>
);

const MeetingRecords = ({ className, elevation = 21, projectProposalTypeIdProp, withTitle, projectIdProp, withActions = false }: MeetingRecordsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const projectId = useMemo(() => projectIdProp || ctx?.project?.id, [projectIdProp, ctx]);
    const projectProposalTypeId = useMemo(() => projectProposalTypeIdProp || ctx?.projectProposalType?.id, [projectProposalTypeIdProp, ctx]);

    const tablesClasses = classNames('overflow-hidden proposals meeting-records', className);

    const [meetingRecordToDeleteId, setMeetingRecordToDeleteId] = useState<null | number>(null);

    const { mutateAsync: deleteItem, isLoading: isDeleting } = useDelete({
        apiPayload: { endpoint: `/meeting-records`, useApiTodelete: true }
    });

    const INITIAL_PARAMS: GraphqlPaginationVariables<ProjectMeetingRecord> = useMemo(
        () => ({
            limit: 10,
            page: 1,
            offset: 0,
            orderBy: { date: 'desc' },
            where: {
                project_id: { _eq: projectId },
                project_proposal_type_id: {
                    ...(projectProposalTypeId ? { _eq: projectProposalTypeId } : { _is_null: true })
                }
            }
        }),
        [projectId, projectProposalTypeId]
    );

    const [params, setParams] = useState<GraphqlPaginationVariables<ProjectMeetingRecord>>(INITIAL_PARAMS);

    useEffect(() => {
        if (Boolean(projectId) && !Boolean(params.where?.project_id?._eq)) {
            setParams((prev) => ({
                ...prev,
                where: {
                    ...prev.where,
                    project_id: { _eq: projectId }
                }
            }));
        }
    }, [projectId, params]);

    const handleDelete = async () => {
        try {
            if (meetingRecordToDeleteId) {
                await deleteItem({ id: meetingRecordToDeleteId });

                setMeetingRecordToDeleteId(null);

                const params = {
                    limit: 10,
                    page: 1,
                    offset: 0,
                    orderBy: { date: 'desc' },
                    where: {
                        project_id: { _eq: projectId }
                    }
                };

                queryClient.invalidateQueries(getProjectMeetingRecordsKey(params, projectId!));
            }
        } catch (error) {
            console.log('handleDeleteDocument', error);
        }
    };

    const { data, isLoading } = useGetMeetingRecords(params, projectId);

    const handleGoTo = useCallback((path: string) => () => navigate(path), [navigate]);

    const columns: MUIDataTableColumn[] = useMemo(() => {
        const arr = [
            {
                name: 'id',
                options: { display: 'excluded' as Display }
            },
            {
                name: 'title',
                label: 'Título',
                options: {
                    customHeadRender: TH
                }
            },
            {
                name: 'date',
                label: 'Data',
                options: {
                    customBodyRender: (value) => formatDate(value || 0, 'DD/MM/YYYY - HH:mm'),
                    customHeadRender: TH
                }
            },
            {
                name: 'person',
                label: 'Criador',
                options: {
                    customBodyRender: (value) => <ExternalButton onClick={() => null}>{value.name}</ExternalButton>,
                    customHeadRender: TH
                }
            },
            {
                name: 'status',
                label: 'Status',
                options: {
                    customBodyRender: (value) => (
                        <Badge variant={value === Status.Active ? 'success' : 'info'} className="px-[10px] py-[6px]">
                            {Object.values(dictionary.content.meetingRecordStatus)[value + 1]}
                        </Badge>
                    ),
                    customHeadRender: TH
                }
            }
        ];

        if (withActions) {
            arr.push({
                name: '',
                label: 'Ações',
                options: {
                    customHeadRender: (value: any) => (
                        <TableCell key={value.label} className="w-[160px]">
                            <Text variant="body.medium.sm" as="span" className="text-secondary-500 text-center">
                                {value.label}
                            </Text>
                        </TableCell>
                    ),
                    customBodyRender: (_: any, tableMeta: any) => {
                        const [id] = tableMeta.rowData;

                        const createTasksPath = () => {
                            if (pathname.includes('operacional')) {
                                return `${id}/tarefas`;
                            }

                            return projectProposalTypeId ? `atas-de-reuniao/${id}/tarefas?projectProposalTypeId=${projectProposalTypeId}` : `atas-de-reuniao/${id}/tarefas`;
                        };

                        return (
                            <div className="flex">
                                <Tooltip placement="top" title="Visualizar" disableFocusListener={true}>
                                    <IconButton
                                        TouchRippleProps={infoButton}
                                        className="hover:bg-primary-100 hover:bg-opacity-20"
                                        onClick={handleGoTo(pathname.includes('operacional') ? `${id}` : `atas-de-reuniao/${id}`)}>
                                        <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Adicionar tarefas" disableFocusListener={true}>
                                    <IconButton TouchRippleProps={secondaryButton} className="hover:bg-secondary-100 hover:bg-opacity-20" onClick={handleGoTo(createTasksPath())}>
                                        <Icon name="ico-plus" width={12} height={12} color={palette.secondary.main} className="m-0.5" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                    <IconButton
                                        TouchRippleProps={warningButton}
                                        className="hover:bg-system-warning-100 hover:bg-opacity-30"
                                        onClick={handleGoTo(pathname.includes('operacional') ? `${id}/editar` : `atas-de-reuniao/${id}/editar`)}>
                                        <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                    <IconButton TouchRippleProps={dangerButton} className="hover:bg-system-danger-100 hover:bg-opacity-30" onClick={() => setMeetingRecordToDeleteId(id)}>
                                        <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    }
                } as any
            });
        }

        return arr;
    }, [handleGoTo, palette, projectProposalTypeId, withActions, pathname]);

    const handleChangePage = (page: number) => {
        setParams({
            ...params,
            page,
            offset: (page || 1) * params.limit - params.limit
        });
    };

    const newMeetingRecordUrl = useMemo(() => {
        return ctx?.project?.id ? 'novo' : 'atas-de-reuniao/novo';
    }, [ctx?.project?.id]);

    return (
        <>
            {isSmallerThenTabletLandscape && pathname.includes('atas-de-reuniao') && <Breadcrumb className="mb-4" />}
            <Datatable
                columns={columns}
                data={data?.items || []}
                loading={isLoading}
                title={withTitle ? 'Atas de reunião' : ''}
                footerLeft={withActions && <AddButton onClick={handleGoTo(newMeetingRecordUrl)}>Nova ata de reunião</AddButton>}
                options={{
                    elevation,
                    sort: false,
                    filter: false,
                    search: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    count: data?.pagination?.aggregate.count || 0,
                    page: params.page,
                    rowsPerPage: params.limit,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    textLabels: {
                        body: {
                            noMatch: 'Nenhuma ata de reunião encontrada.'
                        }
                    },
                    onChangePage: handleChangePage
                }}
                // @ts-ignore
                className={tablesClasses}
                withItemsPerPage={false}
            />
            {Boolean(ctx?.project?.id) && <Outlet context={ctx} />}
            {Boolean(meetingRecordToDeleteId) && (
                <ConfirmModal
                    title="Apagar ata de reunião"
                    description="Você tem certeza que deseja apagar esta ata de reunião?"
                    onClose={() => setMeetingRecordToDeleteId(null)}
                    isLoading={isDeleting}
                    onConfirmAction={handleDelete}
                />
            )}
        </>
    );
};

export default MeetingRecords;
