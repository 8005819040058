import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';

export const createOrUpdateClassificationMutation = (pageId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateClassification',
                __variables: {
                    ...(pageId
                        ? {
                              payload: 'classification_set_input',
                              id: 'Int!'
                          }
                        : {
                              payload: 'classification_insert_input!'
                          })
                },
                item: {
                    __aliasFor: pageId ? 'update_classification_by_pk' : 'insert_classification_one',
                    __args: {
                        ...(pageId
                            ? {
                                  pk_columns: { id: new VariableType('id') },
                                  _set: new VariableType('payload')
                              }
                            : {
                                  object: new VariableType('payload')
                              })
                    },
                    id: true
                }
            }
        },
        { pretty: true }
    );
};

export const createOrUpdateJobPositionMutation = (pageId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateJobPosition',
                __variables: {
                    ...(pageId
                        ? {
                              payload: 'job_position_set_input',
                              id: 'Int!'
                          }
                        : {
                              payload: 'job_position_insert_input!'
                          })
                },
                item: {
                    __aliasFor: pageId ? 'update_job_position_by_pk' : 'insert_job_position_one',
                    __args: {
                        ...(pageId
                            ? {
                                  pk_columns: { id: new VariableType('id') },
                                  _set: new VariableType('payload')
                              }
                            : {
                                  object: new VariableType('payload')
                              })
                    },
                    id: true
                }
            }
        },
        { pretty: true }
    );
};
export const createOrUpdateTypeBusinessMutation = (pageId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateTypeBusiness',
                __variables: {
                    ...(pageId
                        ? {
                              payload: 'type_business_set_input',
                              id: 'Int!'
                          }
                        : {
                              payload: 'type_business_insert_input!'
                          })
                },
                item: {
                    __aliasFor: pageId ? 'update_type_business_by_pk' : 'insert_type_business_one',
                    __args: {
                        ...(pageId
                            ? {
                                  pk_columns: { id: new VariableType('id') },
                                  _set: new VariableType('payload')
                              }
                            : {
                                  object: new VariableType('payload')
                              })
                    },
                    id: true
                }
            }
        },
        { pretty: true }
    );
};
