import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { ProjectTransactionOutcomeDocumentPayload } from 'types/models/project';

import useUploadFile from '../files/use-upload-file';

const useCreateOrUpdateTransactionOutcomeDocument = (projectProposalTypeId?: string, projectTypeDocumentId?: string, outcomeId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const { uploadFile } = useUploadFile();

    const fetcher = async (payload: ProjectTransactionOutcomeDocumentPayload) => {
        if (payload?.file?.id) {
            payload.file = payload.file.id;
        } else {
            await uploadFile(
                payload.file,
                ({ id }) => {
                    payload.file = id;
                },
                true
            );
        }

        if (!payload?.file) {
            throw new Error();
        }

        const method = projectTypeDocumentId ? api.patch : api.post;
        const endpoint = projectTypeDocumentId
            ? `/project-types/${projectProposalTypeId}/transactions/${outcomeId}/documents/${projectTypeDocumentId}`
            : `/project-types/${projectProposalTypeId}/transactions/${outcomeId}/documents`;

        return method<{ id: number }>(endpoint, payload);
    };

    return useMutation(fetcher, {
        onSuccess: () => {
            navigate(-1);

            showToast(`Documento ${projectTypeDocumentId ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${projectTypeDocumentId ? 'atualizar' : 'criar'} o documento`, 'danger');
        }
    });
};

export default useCreateOrUpdateTransactionOutcomeDocument;
