import { useQuery } from 'react-query';
import api from 'services/api';

import { getOperationalKey } from './graphql';
import { Operational } from 'types/models/operationals';
import { Pagination } from 'types/pagination';

export type ProjectTypeRouteSlug = 'gerenciamento' | 'projetos-de-arquitetura' | 'bid' | 'projetos-complementares';

const useGetOperational = (projectTypeSlug?: ProjectTypeRouteSlug, filters?: string, isReversed = false) => {
    const fetcher = async () => {
        const endpoint = `/project-types/${getOperationalApiSlugByRoute(projectTypeSlug, isReversed)}${filters}`;

        const { data } = await api.get<Pagination<Operational>>(endpoint);
        return data;
    };

    return useQuery(getOperationalKey(projectTypeSlug, filters), fetcher, { enabled: !!filters || !!projectTypeSlug });
};

export default useGetOperational;

export const getOperationalApiSlugByRoute = (projectTypeSlug?: ProjectTypeRouteSlug, isReversed = false) => {
    const types = {
        gerenciamento: 'management',
        bid: 'bid',
        'projetos-de-arquitetura': 'projects',
        'projetos-complementares': 'complementary-projects'
    };

    const slug = isReversed ? projectTypeSlug : types[projectTypeSlug!];

    if (!slug) {
        throw new Error('PROJECT SLUG NOT FOUND');
    }

    return slug;
};
