import ListPage from 'pages/private/crud/list';
import { RouteObject } from 'react-router-dom';
import { commercialFPList } from './utils/commercial';
import Page from 'pages/shared/page';
import { Rule } from 'types/permissions';
import CreateOrUpdateCommercialFPPage from 'pages/private/commercial/budgets';
import CommercialFPDetailsPage from 'pages/private/commercial/budgets/details';
import UpdateCommercialBudgetVersionPage from 'pages/private/commercial/budgets/update-version';
import CommercialBudgetVersionsPage from 'pages/private/commercial/budgets/versions';
import GenerateContractPage from 'pages/private/commercial/budgets/contract';
import ProjectFormPage from 'pages/private/commercial/budgets/details/projects-form';

const commercial: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'FP',
        path: 'fp',
        element: <Page Component={ListPage} {...commercialFPList} code="comercial-fp" permissions={[Rule.List]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateCommercialFPPage} code="comercial-fp" withModal={true} permissions={[Rule.Create]} />
            }
        ]
    },
    {
        path: 'fp/:budgetId',
        element: <Page Component={CommercialFPDetailsPage} code="comercial-fp-detalhes" permissions={[Rule.Show]} />,
        children: [
            {
                path: 'versoes/:budgetVersionId/:projectTypeId/editar',
                element: <Page Component={UpdateCommercialBudgetVersionPage} code="comercial-fp-detalhes" withModal={true} permissions={[Rule.Update]} />
            },
            {
                path: 'versoes/:budgetVersionId/:projectTypeId/versionamentos',
                element: <Page Component={CommercialBudgetVersionsPage} code="comercial-fp-detalhes" withModal={true} permissions={[Rule.Show]} />
            },
            {
                path: 'versoes/:budgetVersionId/:projectTypeId/visualizar',
                element: <Page Component={UpdateCommercialBudgetVersionPage} code="comercial-fp-detalhes" withModal={true} permissions={[Rule.Show]} />
            },
            {
                path: 'gerar-contrato',
                element: <Page Component={GenerateContractPage} code="comercial-fp-detalhes" withModal={true} permissions={[Rule.Create]} />
            },
            {
                path: 'gerar-contrato-aditivo',
                element: <Page Component={ProjectFormPage} code="comercial-fp-detalhes" withModal={true} permissions={[Rule.Create]} />
            }
        ]
    }
];

export default commercial;
