import { useQuery } from 'react-query';
import api from 'services/api';
import { getDatabooksByProjectProposalTypeIdKey } from './graphql';
import { DatabookFilesResponse } from 'pages/private/operationals/databook/types/types';

type RecursiveDatabookChildrens = DatabookResponse;

export type DatabookResponse = {
    id: number;
    order: number;
    title: string;
    slug: string;
    number: string;
    files?: DatabookFilesResponse[];
    children: RecursiveDatabookChildrens;
    father?: {
        id: number;
        name: string;
        number?: string;
        slug: string;
    };
};

const useGetDatabooks = (projectProposalTypeId?: string, databookId?: string) => {
    const endpoint = !!databookId ? `/project-types/${projectProposalTypeId}/data-book/${databookId}` : `/project-types/${projectProposalTypeId}/data-book`;
    const request = async () => api.get<DatabookResponse>(endpoint).then(({ data }) => data);

    return useQuery(getDatabooksByProjectProposalTypeIdKey(projectProposalTypeId, databookId), request, { enabled: !!projectProposalTypeId || !!databookId });
};

export default useGetDatabooks;
