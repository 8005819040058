import { useMemo } from 'react';
import useMatchRoutes from './use-match-routes';

const useRoute = () => {
    const matches = useMatchRoutes();

    const route = useMemo(() => {
        const arr = matches?.filter((item) => !!item.route?.path);

        return arr?.[arr.length - 1];
    }, [matches]);

    return route;
};

export default useRoute;
