import Text from 'components/core/text';
import { useCallback, useMemo } from 'react';
import { OperationalDailyReportDetailsProps } from 'types/models/operationals';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import TableContainer from '@mui/material/TableContainer/TableContainer';

const Effectives = ({ details }: OperationalDailyReportDetailsProps) => {
    const providers = useMemo(() => {
        if (!details?.effective) {
            return [];
        }

        const mappedProviders = details?.effective.map((item) => ({
            id: item.provider.id || 0,
            title: item.provider.title || ''
        }));

        return uniqBy(mappedProviders, 'id');
    }, [details?.effective]);

    const getTotal = useCallback(
        (providerId: number) => {
            return details?.effective.filter((field) => field.provider.id === providerId).reduce((acc, curr) => acc + Number(curr.quantity), 0);
        },
        [details?.effective]
    );

    const formattedEffective = useMemo(() => Object.entries(groupBy(details?.effective, 'classification.name')), [details?.effective]);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell classes={{ root: 'bg-base-200' }} />
                        {providers.map((provider, index) => {
                            return (
                                <TableCell classes={{ root: 'bg-base-200' }} key={index}>
                                    <Text as="span" className="text-secondary-500 text-center block">
                                        {provider.title}
                                    </Text>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {formattedEffective.map((item) => {
                        const [classification, effectives] = item;

                        return (
                            <TableRow key={classification}>
                                <TableCell>
                                    <Text as="span" className="text-base-500">
                                        {classification}
                                    </Text>
                                </TableCell>
                                {effectives.map((effective) => {
                                    return (
                                        <TableCell key={effective.id}>
                                            <Text as="span" className="text-base-500 text-center block">
                                                {effective.quantity}
                                            </Text>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow className="uppercase bg-base-300">
                        <TableCell className="font-bold">
                            <Text className="text-base-700">Total</Text>
                        </TableCell>
                        {providers.map((item) => (
                            <TableCell key={item.id} align="center" className="font-bold">
                                <Text className="text-base-700">{getTotal(item.id)}</Text>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default Effectives;
