import Card from 'components/core/card';
import BudgetDetails from './budget-details';
import useGetCommercialBudget from 'services/queries/commercial/use-get-commercial-budget';
import { Outlet, useParams } from 'react-router-dom';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import Text from 'components/core/text';
import useGetCommercialBudgetVersions from 'services/queries/commercial/use-get-budget-versions';
import BudgetVersionTable from './budget-versions-table';
import Spinner from 'components/core/spinner';
import { useMemo, useState } from 'react';
import ConfirmModal from 'components/core/modal/confirm';
import useCreateNewCommercialBudgetVersion from 'services/queries/commercial/use-create-new-commercial-budget-version';
import { projectTypeTitleByIdMap } from 'utils/project';
import { BudgetAdditiveContractPayload, BudgetStatus, CommercialBudgetResponse } from 'types/models/commercial';
import useDelete from 'services/queries/crud/use-delete';
import { getBudgetVersionsKeys, getBudgetKeys } from 'services/queries/commercial/keys';
import { useQueryClient } from 'react-query';
import useCreateBudgetAdditiveContract from 'services/queries/commercial/use-create-budget-additive-contract';
import CommercialBudgetDetailsActions from './actions';

const CommercialFPDetailsPage = () => {
    const [projectTypeId, setProjectTypeId] = useState<number | null>(null);
    const [projectProposalTypeId, setProjectProposalTypeId] = useState<number | null>(null);
    const [projectTypeToDeleteId, setProjectTypeToDeleteId] = useState<number | null>(null);
    const [commercialBudgetId, setCommercialBudgetId] = useState<number | null>(null);
    const [hasConfigAdditiveContract, setHasConfigAdditiveContract] = useState(false);

    const { budgetId } = useParams();
    const queryClient = useQueryClient();

    const { mutateAsync: createNewCommercialBudgetVersion, isLoading: isSubmittingNewVersion } = useCreateNewCommercialBudgetVersion(budgetId, projectTypeId);
    const { data: commercialBudget, isLoading: isLoadingCommercialBudget } = useGetCommercialBudget<CommercialBudgetResponse>(budgetId);
    const { data: budgetVersions = [], isLoading: isLoadingCommercialBudgetVersions } = useGetCommercialBudgetVersions(budgetId, undefined, false, true);

    const { mutateAsync: deleteCommercialFp, isLoading: isDeleting } = useDelete({
        apiPayload: { endpoint: `/budgets`, useApiTodelete: true },
        customMessages: {
            success: 'A FP foi cancelada com sucesso!',
            error: 'Ocorreu um erro ao cancelar a FP. Por favor, tente novamente.'
        }
    });

    const { mutateAsync: deleteProjectProposalType, isLoading: isDeletingProjectProposalType } = useDelete({
        apiPayload: { endpoint: `/budgets/${budgetId}/${projectTypeToDeleteId}/versions`, useApiTodelete: true },
        customMessages: {
            success: 'Proposta cancelada com sucesso!',
            error: 'Ocorreu um erro ao cancelar a proposta. Por favor, tente novamente.'
        }
    });

    const { mutateAsync: createBudgetAdditiveContract, isLoading: isSubmitting } = useCreateBudgetAdditiveContract(commercialBudget?.id!);

    const isLoading = useMemo(() => isLoadingCommercialBudget || isLoadingCommercialBudgetVersions, [isLoadingCommercialBudget, isLoadingCommercialBudgetVersions]);

    const hasProjects = budgetVersions.some((versions) => versions.project_type.slug.includes('projects'));

    const handleGenerateNewVersion = async () => {
        try {
            await createNewCommercialBudgetVersion();
            setProjectTypeId(null);
        } catch (e) {
            console.error('submit: Ocorreu um erro ao criar uma nova versão para essa FP.');
        }
    };

    const handleDeleteCommercialFP = async () => {
        try {
            await deleteCommercialFp({ id: commercialBudgetId! });

            queryClient.invalidateQueries(getBudgetVersionsKeys(budgetId));
            queryClient.invalidateQueries(getBudgetKeys(budgetId));

            setCommercialBudgetId(null);
        } catch (e) {
            console.error('submit: Ocorreu um erro ao cancelar a FP.');
        }
    };

    const handleDeleteProjectProposalType = async () => {
        try {
            if (Boolean(projectProposalTypeId)) {
                await deleteProjectProposalType({ id: projectProposalTypeId! });

                queryClient.invalidateQueries(getBudgetVersionsKeys(budgetId));
                queryClient.invalidateQueries(getBudgetKeys(budgetId));

                setProjectTypeToDeleteId(null);
                setProjectProposalTypeId(null);
            }
        } catch (e) {
            console.error('handleDeleteProjectProposalType: ', e);
        }
    };

    const handleGenerateBudgetAdditiveContract = async () => {
        try {
            const payload: BudgetAdditiveContractPayload = {
                versions: budgetVersions.map((item) => ({
                    budgetVersion: item.id,
                    withPhase: false
                }))
            };

            await createBudgetAdditiveContract(payload);
            setHasConfigAdditiveContract(false);
        } catch (error) {
            console.error('handleGenerateBudgetAdditiveContract: ', error);
        }
    };

    useBreadcrumb(commercialBudget?.title);

    return (
        <>
            {isLoading ? (
                <Spinner fixed={true} />
            ) : (
                <div className="flex flex-col md:grid md:grid-cols-4 gap-4 my-4 mx-2 relative">
                    <div className="md:col-span-1 md:sticky md:top-0 md:h-full">
                        <Card>
                            <BudgetDetails
                                contractId={commercialBudget?.project?.id}
                                budgetStatus={commercialBudget?.budgetStatus}
                                code={commercialBudget?.code}
                                title={commercialBudget?.title}
                                customer={commercialBudget?.customer?.title}
                            />
                            {commercialBudget?.budgetStatus === BudgetStatus.Started && (
                                <div className="w-full flex justify-center mb-4">
                                    <CommercialBudgetDetailsActions
                                        hasProjects={hasProjects}
                                        budget={commercialBudget}
                                        onSetCommercialBudgetId={setCommercialBudgetId}
                                        onSetConfigAdditiveContract={setHasConfigAdditiveContract}
                                    />
                                </div>
                            )}
                        </Card>
                    </div>

                    <div className="md:col-span-3 flex flex-col gap-4">
                        <Card>
                            <div className="py-5 relative ">
                                <Text as="h5" className="font-medium text-heading !text-base md:!text-lg xl:!text-xl relative px-6">
                                    Propostas
                                </Text>
                                <BudgetVersionTable
                                    onSetProjectTypeToDeleteId={setProjectTypeToDeleteId}
                                    onSetProjectTypeId={setProjectTypeId}
                                    onSetProjectProposalTypeId={setProjectProposalTypeId}
                                    budgetStatus={commercialBudget?.budgetStatus}
                                    budgetVersions={budgetVersions}
                                />
                            </div>
                        </Card>
                    </div>
                    {Boolean(projectTypeId) && (
                        <ConfirmModal
                            isLoading={isSubmittingNewVersion}
                            title="Gerar nova versão"
                            description={`Você realmente deseja gerar uma nova versão de ${projectTypeTitleByIdMap[projectTypeId!] || '-'}?`}
                            onClose={setProjectTypeId.bind(this, null)}
                            onConfirmAction={handleGenerateNewVersion}
                        />
                    )}
                    {Boolean(commercialBudgetId) && (
                        <ConfirmModal
                            isLoading={isDeleting}
                            title="Cancelar FP?"
                            description="Você realmente deseja cancelar o FP?"
                            onClose={setCommercialBudgetId.bind(this, null)}
                            onConfirmAction={handleDeleteCommercialFP}
                        />
                    )}
                    {hasConfigAdditiveContract && (
                        <ConfirmModal
                            isLoading={isSubmitting}
                            title="Configurar BID/Gerenciamento?"
                            description="Você realmente deseja configurar o BID/Gerenciamento"
                            onClose={setHasConfigAdditiveContract.bind(this, false)}
                            onConfirmAction={handleGenerateBudgetAdditiveContract}
                        />
                    )}
                    {Boolean(projectTypeToDeleteId) && (
                        <ConfirmModal
                            isLoading={isDeletingProjectProposalType}
                            title="Cancelar proposta?"
                            description="Você realmente deseja cancelar a proposta?"
                            onClose={setProjectTypeToDeleteId.bind(this, null)}
                            onConfirmAction={handleDeleteProjectProposalType}
                        />
                    )}
                </div>
            )}
            <Outlet context={{ commercialBudget, budgetVersions, setProjectTypeId }} />
        </>
    );
};

export default CommercialFPDetailsPage;
