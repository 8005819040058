import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import apiGraphQl from 'services/graphql';
import { getProviderServiceType } from './utils/utils';
import { getServiceTypeClassificationByProviderKey } from './utils/graphql';

const useGetServiceType = (serviceTypeClassificationId?: string, providerId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const fetcher = async () => {
        const { provider_has_classification_by_pk } = await apiGraphQl<any>(getProviderServiceType(providerId, serviceTypeClassificationId));

        return provider_has_classification_by_pk;
    };

    return useQuery(getServiceTypeClassificationByProviderKey(serviceTypeClassificationId, providerId), fetcher, {
        enabled: !!serviceTypeClassificationId,
        onError: () => {
            navigate(-1);

            showToast('Tivemos um problema ao obter os detalhes desse tipo de serviço.', 'danger');
        }
    });
};

export default useGetServiceType;
