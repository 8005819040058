import ExternalButton from 'components/buttons/external';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useState } from 'react';
import { OperationalDailyReportDetailsProps } from 'types/models/operationals';
import { formatDate } from 'utils/date';

const DetailsTab = ({ details }: OperationalDailyReportDetailsProps) => {
    const [observation, setObservation] = useState<string | undefined>();

    const handleToggleObservationModal = (value?: string) => () => setObservation(value);

    return (
        <div className="my-4">
            <div className="grid gap-7">
                <div className="grid grid-cols-1 gap-2 mb-2">
                    <div>
                        <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                            Data acompanhamento:
                        </Text>
                        <Text variant="body.regular.sm" as="span" className="text-base-700">
                            {formatDate(details?.date, 'DD/MM/YYYY')}
                        </Text>
                    </div>
                    <div>
                        <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                            Condição climática
                        </Text>
                        <Text variant="body.regular.sm" as="span" className="text-base-700">
                            {details?.classification.name}
                        </Text>
                    </div>
                    <div className="flex">
                        <Text variant="body.regular.sm" as="span" className="text-heading mr-1">
                            Observações
                        </Text>

                        <ExternalButton onClick={handleToggleObservationModal(details?.observations)}>Visualizar</ExternalButton>
                    </div>
                </div>
            </div>
            {Boolean(observation) && (
                <Modal contentClassnames="py-5 px-7 " onClose={handleToggleObservationModal(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: observation! }} />
                </Modal>
            )}
        </div>
    );
};

export default DetailsTab;
