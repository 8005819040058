// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import provider, { getProviderProjectsAmountKey } from './graphql';

type Response = {
    active: {
        aggregate: {
            count: number;
        };
    };
    all: {
        aggregate: {
            count: number;
        };
    };
};

const useGetProviderProjectsAmount = (providerId?: number) => {
    const fetcher = async () =>
        apiGraphQl<Response>(provider.getProviderProjectsAmount, { providerId }).then(({ active, all }) => ({ active: ~~active?.aggregate?.count, all: ~~all?.aggregate?.count }));

    return useQuery(getProviderProjectsAmountKey(providerId), fetcher, { enabled: !!providerId });
};

export default useGetProviderProjectsAmount;
