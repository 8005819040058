import { useQuery } from 'react-query';
import api from 'services/api';
import qs from 'qs';

export const useGetOccupationMap = ({ filter, enabled }: Props) => {
    const query = qs.stringify({ filter }, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' });

    const fetcher = () => api.get<OccupationMapResponse>(`/map-of-people-projected-allocation${query}`).then(({ data }) => data);

    return useQuery(getOccupationMapKey(query), fetcher, { enabled });
};

const getOccupationMapKey = (query: string) => ['occupation-map', query];

type Props = {
    filter: any;
    enabled: boolean;
};

export type OccupationMapResponse = Array<{
    person: {
        id: number;
        name: string;
    };
    clients: any;
    totals: Array<{
        month: number;
        year: number;
        weeks: Array<{
            percentage: number;
            color: string;
        }>;
    }>;
    availability: Array<{
        month: number;
        year: number;
        weeks: Array<{
            percentage: number;
            color: string;
        }>;
    }>;
}>;
