import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';

export const createOrUpdateProviderClassificationMutation = (providerId?: string, serviceTypeId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdateProviderClassification',
                __variables: {
                    ...(providerId && serviceTypeId
                        ? {
                              payload: 'provider_has_classification_set_input',
                              classification_id: 'Int!',
                              provider_id: 'Int!'
                          }
                        : {
                              payload: 'provider_has_classification_insert_input!'
                          })
                },
                item: {
                    __aliasFor: providerId && serviceTypeId ? 'update_provider_has_classification_by_pk' : 'insert_provider_has_classification_one',
                    __args: {
                        ...(providerId && serviceTypeId
                            ? {
                                  pk_columns: { classification_id: new VariableType('classification_id'), provider_id: new VariableType('provider_id') },
                                  _set: new VariableType('payload')
                              }
                            : {
                                  object: new VariableType('payload')
                              })
                    },
                    classification: {
                        id: true
                    }
                }
            }
        },
        { pretty: true }
    );
};

export const getProviderServiceType = (providerId?: string, serviceTypeId?: string) =>
    jsonToGraphQLQuery({
        query: {
            __name: 'GetServiceTypeFromProvider',
            provider_has_classification_by_pk: {
                __args: { classification_id: serviceTypeId, provider_id: providerId },
                classification: {
                    name: true,
                    id: true,
                    classification: {
                        name: true,
                        id: true
                    }
                }
            }
        }
    });
