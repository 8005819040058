import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { createTimelineKey, getChecklistsKey, getProjectProposalTypeKey } from './graphql';
import { useNavigate } from 'react-router-dom';

const useCreateOrUpdateTimeline = (projectProposalTypeId?: string, taskPlanningId?: string, projectTypeTimelineId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const isUpdate = Boolean(taskPlanningId) || Boolean(projectTypeTimelineId);

    const fetcher = async (payload) => {
        const id = taskPlanningId || projectTypeTimelineId;

        const method = isUpdate ? api.patch : api.post;
        const endpoint = isUpdate ? `/project-types/${projectProposalTypeId}/timelines/${id}` : `/project-types/${projectProposalTypeId}/timelines`;

        return method(endpoint, payload);
    };

    return useMutation(createTimelineKey(projectProposalTypeId), fetcher, {
        retry: false,
        onSuccess: ({ data }) => {
            showToast(`Cronograma ${isUpdate ? 'atualizado' : 'cadastrado'} com sucesso`, 'success');

            queryClient.invalidateQueries(getChecklistsKey(projectProposalTypeId));
            queryClient.invalidateQueries(getProjectProposalTypeKey(+projectProposalTypeId!));

            if (!isUpdate) {
                navigate(`../${data.id}/editar`, { replace: true });
            }
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isUpdate ? 'atualizar' : 'cadastrar'} o registro`, 'danger');
        }
    });
};

export default useCreateOrUpdateTimeline;
