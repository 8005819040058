import React, { useEffect, useState } from 'react';

// Dependencies
import useTheme from '@mui/material/styles/useTheme';
import PaperButton from '@mui/material/Button/Button';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Button from 'components/core/button';
import Input from 'components/core/form/input';

// Helpers
import { Task } from 'types/models/task';
import { Option } from 'types/general';

type ChecklistSubItemProps = {
    parentTask: Partial<Task>;
    task: Partial<Task>;
    statuses: Option[];
    onSave: (description: string, taskId?: number, status?: number) => void;
};

const ChecklistSubItem = ({ parentTask, statuses, task, onSave }: ChecklistSubItemProps) => {
    const { palette } = useTheme();
    const [description, setDescription] = useState('');
    const [taskStatus, setTaskStatus] = useState<number>();

    useEffect(() => {
        setDescription(task.description || '');
    }, [task.description]);

    useEffect(() => {
        setTaskStatus(task.taskStatus?.id);
    }, [task.taskStatus?.id]);

    const handleSave = () => {
        onSave(description, task.id, taskStatus);
    };

    const handleSelectStatus = (value: number) => () => {
        if (value === taskStatus) {
            return;
        }

        setTaskStatus(value);
    };

    return (
        <TableRow sx={{ '&:hover': { backgroundColor: palette.grey[200] } }}>
            <TableCell classes={{ root: 'py-1.5 border-none' }}>
                <Text as="span" className="text-base-500">
                    {`${parentTask.order}.${task.order}`}
                </Text>
            </TableCell>
            <TableCell classes={{ root: 'py-1.5 border-none min-w-[315px]' }}>
                <Text as="span" className="text-base-500">
                    {task.title}
                </Text>
            </TableCell>
            <TableCell classes={{ root: 'py-1.5 border-none' }}>
                <div className="flex">
                    <ButtonGroup size="small">
                        {statuses.map((item) => (
                            <PaperButton
                                variant={taskStatus === item.value ? 'contained' : 'outlined'}
                                key={item.value}
                                disableElevation={true}
                                color="secondary"
                                onClick={handleSelectStatus(item.value)}
                            >
                                {item.label}
                            </PaperButton>
                        ))}
                    </ButtonGroup>
                </div>
            </TableCell>
            <TableCell classes={{ root: 'py-1.5 border-none' }}>
                <Input parentClassName="min-w-[400px]" value={description} name="description" type="text" onChange={(e) => setDescription(e.currentTarget.value)} />
            </TableCell>
            <TableCell classes={{ root: 'py-1.5 border-none' }}>
                <Button size="small" color="success" type="button" startIcon={<Icon color={palette.success[500]} name="ico-save" width={16} height={16} />} onClick={handleSave}>
                    Salvar
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default ChecklistSubItem;
