import React from 'react';
import Text from 'components/core/text';
import { ProjectTypeSolicitation } from 'types/models/project';
import Badge from 'components/core/badge';

type RequestDetailsProps = Pick<Partial<ProjectTypeSolicitation>, 'classifications' | 'subject' | 'observations' | 'description'>;

const RequestDetails = ({ classifications, subject, description, observations }: RequestDetailsProps) => {
    return (
        <div className="pt-4">
            {Boolean(subject) && (
                <div className="mb-6">
                    <Text as="label" variant="h6" className="text-heading block mb-2">
                        Assunto
                    </Text>
                    <Text variant="body.regular.sm" className="text-base-500">
                        {subject}
                    </Text>
                </div>
            )}
            {Boolean(classifications?.length) && (
                <div className="mb-6">
                    <Text as="label" variant="h6" className="text-heading block mb-2">
                        Tipo(s)
                    </Text>
                    <div className="flex flex-wrap gap-2 items-center">
                        {classifications?.map((item) => (
                            <Badge key={item.id} variant="secondary">
                                {item.name}
                            </Badge>
                        ))}
                    </div>
                </div>
            )}
            {Boolean(description) && (
                <div className="mb-6">
                    <Text as="label" variant="h6" className="text-heading block mb-2">
                        Descrição
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: description! }} />
                </div>
            )}
            {Boolean(observations) && (
                <div>
                    <Text as="label" variant="h6" className="text-heading block mb-2">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: observations! }} />
                </div>
            )}
        </div>
    );
};

export default RequestDetails;
