import React from 'react';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { WeeklyEffective } from 'types/models/report';
import { TableFooter } from '@mui/material';
import { formatDate } from 'utils/date';

const WeeklyEffectiveTable = ({ effective, totals, week }: Partial<Omit<WeeklyEffective, 'graphic'>>) => {
    return (
        <TableContainer>
            <Table className="mb-6">
                <TableHead>
                    <TableRow className="uppercase bg-base-300">
                        <TableCell align="center">Semana</TableCell>
                        <TableCell align="center" colSpan={7}>
                            Efetivo da semana
                        </TableCell>
                    </TableRow>
                    <TableRow className="bg-base-200">
                        <TableCell align="center">
                            {week?.title || ''} <br />
                            {week && `${formatDate(week?.startDate)} - ${formatDate(week?.endDate)}`}
                        </TableCell>
                        <TableCell align="center">Seg</TableCell>
                        <TableCell align="center">Ter</TableCell>
                        <TableCell align="center">Qua</TableCell>
                        <TableCell align="center">Qui</TableCell>
                        <TableCell align="center">Sex</TableCell>
                        <TableCell align="center">Sab</TableCell>
                        <TableCell align="center">Dom</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Boolean(effective?.length) &&
                        effective?.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{item.title}</TableCell>
                                    {item.days.map((day, ix) => (
                                        <TableCell align="center" key={ix}>
                                            {day.total}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                </TableBody>
                {Boolean(totals?.length) && (
                    <TableFooter>
                        <TableRow className="uppercase bg-base-300">
                            <TableCell className="font-bold">Total</TableCell>
                            {totals?.map((item, index) => (
                                <TableCell key={index} align="center" className="font-bold">
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
};

export default WeeklyEffectiveTable;
