import React, { useEffect, useMemo, useState } from 'react';

// Dependencies
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

// Components
import CreateOrUpdateProfile, { groupBy, RulesPayload } from './create-or-update';

// Helpers
import { slugify } from 'utils/string';
import { Status } from 'types/general';
import { Profile } from 'types/models/profile';
import { GraphqlPaginationVariables } from 'types/graphql';
import useGetPages from 'services/queries/pages/use-get-pages';
import useCreateOrUpdateProfile from 'services/queries/profiles/use-create-or-update-profile';
import useGetProfile from 'services/queries/profiles/use-get-profile';
import { getProfileQuery } from '../utils';
import useDeleteProfilePageRule from 'services/queries/profiles/use-delete-profile-page-rule';
import useCreateProfilePageRule from 'services/queries/profiles/use-create-profile-page-rule';
import useGetDashboards from 'services/queries/dashboard/use-get-dashboards';
import { getDashboardsQuery } from 'services/queries/dashboard/graphql';
import useToast from 'hooks/toast/use-toast';

const CreateOrUpdateProfilePage = () => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const { profileId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedRules, setSelectedRules] = useState<RulesPayload[]>([]);

    const params = useOutletContext<GraphqlPaginationVariables<Profile>>();

    const { isLoading: isLoadingPages, data: pages = [] } = useGetPages();
    const { isLoading: isLoadingDashboards, data: dashboards = [] } = useGetDashboards(getDashboardsQuery);
    const { data: profile, isLoading: isLoadingProfile } = useGetProfile(getProfileQuery(profileId), profileId);

    const { mutateAsync: createProfilePageRule } = useCreateProfilePageRule();
    const { mutateAsync: deleteProfilePageRule } = useDeleteProfilePageRule(profileId);
    const { mutateAsync: createOrUpdateProfile } = useCreateOrUpdateProfile(params, profileId);

    const isBuilding = useMemo(() => isLoadingProfile || isLoadingDashboards || isLoadingPages, [isLoadingProfile, isLoadingPages, isLoadingDashboards]);

    useEffect(() => {
        if (!!profile) {
            setSelectedRules(
                Array.from(groupBy(profile?.profile_page_rules || [], ({ page_id }) => page_id)).map(([key, value]) => ({
                    page_id: key,
                    rules_ids: value.map(({ rule_id }) => rule_id)
                }))
            );
        }
    }, [profile]);

    const handleSelectRules = (page_id: number, values: number[]) => {
        const isSelected = selectedRules.some((item) => item.page_id === page_id);

        const newRules = isSelected
            ? selectedRules.map((item) => ({
                  page_id: page_id === item.page_id ? page_id : item.page_id,
                  rules_ids: page_id === item.page_id ? values : item.rules_ids
              }))
            : [...selectedRules, { page_id, rules_ids: values }];

        setSelectedRules(newRules);
    };

    const handleSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            const payload = {
                dashboard_template_id: data.dashboard_template_id,
                name: data.name.trim(),
                slug: slugify(data.name.trim()),
                status: Status.Active
            };

            if (!profileId) {
                payload['profile_page_rules'] = {
                    data: selectedRules.flatMap((item) => item.rules_ids.map((rule) => ({ rule_id: rule, page_id: item.page_id })))
                };
            }

            await createOrUpdateProfile(payload);

            // Delete current rules
            if (!!profileId && !!selectedRules.length) {
                await deleteProfilePageRule();

                // Add new rules
                for (const rule of selectedRules) {
                    const pageRules = rule.rules_ids.map((id) => ({
                        page_id: rule.page_id,
                        profile_id: +profileId,
                        rule_id: id
                    }));

                    await createProfilePageRule(pageRules);
                }
            }

            navigate(-1);
            showToast(`Perfil ${!!profileId ? 'atualizado' : 'criado'} com sucesso`, 'success');
        } catch (error) {
            console.log('CreateOrUpdateProfilePage', error);
        } finally {
            setIsLoading(false);
        }
    };

    const defaultValues = useMemo(
        () => ({
            name: profile?.name || '',
            profile_page_rules: Array.from(groupBy(profile?.profile_page_rules || [], ({ page_id }) => page_id)).map(([key, value]) => ({
                page_id: key,
                rules_ids: value.map(({ rule_id }) => rule_id)
            }))
        }),
        [profile]
    );

    return (
        <CreateOrUpdateProfile
            dashboards={dashboards}
            pages={pages}
            profile={profile}
            selectedRules={selectedRules}
            onSelectRules={handleSelectRules}
            onSubmit={handleSubmit}
            isSubmittingForm={isLoading}
            defaultValues={defaultValues}
            isLoading={isBuilding}
        />
    );
};

export default CreateOrUpdateProfilePage;
