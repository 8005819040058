import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { GraphqlPagination } from 'types/graphql';
import { ProjectTypeSolicitation } from 'types/models/project';
import { getProjectTypeSolicitationsKey } from './graphql';

const useGetProjectTypeSolicitations = (query: string) => {
    const fetcher = async () => apiGraphQl<GraphqlPagination<ProjectTypeSolicitation>>(query).then((data) => data);

    return useQuery(getProjectTypeSolicitationsKey(query), fetcher);
};

export default useGetProjectTypeSolicitations;
