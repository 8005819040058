import { useState } from 'react';
import ReportsHeader from './header';
import useGetReportWeeks, { DEFAULT_WEEKS } from 'services/queries/operationals/use-get-reports-weeks';
import { useOutletContext } from 'react-router-dom';
import { OperationalDetailsPageContext } from '../view';
import useGetReportCharts from 'services/queries/operationals/use-get-reports';
import { Option } from 'types/general';
import MainActivities from './main-activities';
import PhysicalProgress from './physical-progress';
import PhysicalFinancial from './physical-financial';
import WeeklyEffective from './weekly-effective';
import Activities from './activities';
import AttentionPoints from './attention-points';
import Images from './images';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

const ReportsPage = () => {
    const weekHook = useState<Option<number | undefined>>(DEFAULT_WEEKS[0]);
    const { isSmallerThenTabletLandscape } = useResponsive();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const { data: weeks } = useGetReportWeeks(ctx?.projectProposalType?.id);

    const [mainActivities, physicalProgress, physicalFinancial, weeklyEffective, images, activities, attentionPoints] = useGetReportCharts(ctx?.projectProposalType?.id, weekHook[0].value);

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <ReportsHeader weeks={weeks || DEFAULT_WEEKS} weekHook={weekHook} />
            <MainActivities {...mainActivities} />
            <PhysicalProgress {...physicalProgress} />
            <PhysicalFinancial {...physicalFinancial} />
            <WeeklyEffective {...weeklyEffective} />
            <Images {...images} />
            <Activities {...activities} />
            <AttentionPoints {...attentionPoints} />
        </>
    );
};

export default ReportsPage;
