import ListPage from 'pages/private/crud/list';
import { useOutletContext } from 'react-router-dom';
import { Person } from 'types/models/person';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { CrudPageProps } from 'types/graphql';
import generateListPageProps from './utils/generate-list-page-props';

const PersonBankAccountsTab = ({ page }: WithPagePermissionsProps) => {
    const { id: personId } = useOutletContext<Partial<Person>>();

    const list: CrudPageProps = generateListPageProps(personId);

    return <ListPage {...list} page={page} />;
};

export default PersonBankAccountsTab;
