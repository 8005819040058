import useGetTaskPlanning from 'services/queries/operationals/use-get-task-planning';
import CalendarBody from './body';
import CalendarHeader from './header';
import { useParams } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import { useCallback, useMemo, useState } from 'react';
import { CalendarContextProvider } from './context';
import dayjs, { Dayjs } from 'dayjs';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useCalendarHeaderTexts } from './hooks/header-texts';
import SwitchTimelineVariant from './switch-timeline-variant';
import CalendarSidebar from './sidebar';
import { Task } from 'types/models/task';
import { generateArray } from '../utils';
import useTheme from '@mui/material/styles/useTheme';

dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekOfYear);

const Calendar = () => {
    const { projectProposalTypeId, projectTypeTimelineId } = useParams<any>();

    const [timelineVariant, setTimelineVariant] = useState<TimelineVariant>('MONTHS');

    const { data, isLoading } = useGetTaskPlanning(Number(projectProposalTypeId || 0), true, projectTypeTimelineId);

    const { id: timelineId } = data || {};

    const headerTexts = useCalendarHeaderTexts({ timelineVariant });

    const [tasksThatShouldShowSubtasks, setTasksThatShouldShowSubtasks] = useState<number[]>([]);

    const { palette } = useTheme();

    const tasks = useMemo(() => {
        const getColor = (taskIndex: number) => {
            const initialColors = [palette.info, palette.secondary, palette.success, palette.warning, palette.error, palette.primary];

            let colors = [...initialColors];

            // replicates the colors according to the total tasks
            generateArray(Math.ceil((data?.tasks?.length || 0) / initialColors.length)).forEach(() => {
                colors = [...colors, ...initialColors];
            });

            return colors?.[taskIndex];
        };

        return (
            data?.tasks?.map((task, index) => ({
                ...task,
                color: getColor(index),
                tasks: task?.tasks?.map((subtask) => ({ ...subtask, color: getColor(index), isChildren: true }))
            })) || []
        );
    }, [data?.tasks, palette]);

    const visibleTasks = useMemo(() => {
        let result: Task[] = [];

        tasks.forEach((task) => {
            result = [...result, task];

            if (tasksThatShouldShowSubtasks.includes(task.id)) {
                result = [...result, ...(task?.tasks || [])];
            }
        });

        return result;
    }, [tasks, tasksThatShouldShowSubtasks]);

    const handleToggleActiveTask = useCallback((id: number) => {
        setTasksThatShouldShowSubtasks((tasks) => {
            if (tasks.includes(id)) {
                return tasks.filter((taskId) => taskId !== id);
            }

            return [...tasks, id];
        });
    }, []);

    return (
        <>
            {isLoading && <Spinner fixed={true} />}
            <CalendarContextProvider
                value={{
                    headerTexts,
                    timelineVariant
                }}>
                <div className="h-full flex overflow-x-hidden w-full">
                    <CalendarSidebar tasks={tasks} tasksThatShouldShowSubtasks={tasksThatShouldShowSubtasks} onToggleActiveTask={handleToggleActiveTask} />
                    <div className={`flex flex-col w-full flex-1 overflow-x-auto overflow-y-hidden calendar-container min-h-[calc(100vh-267px)] sm:min-h-full sm:h-full`}>
                        <CalendarHeader />
                        <CalendarBody tasks={visibleTasks} timelineId={timelineId} />
                    </div>
                </div>
            </CalendarContextProvider>

            <SwitchTimelineVariant timelineVariant={timelineVariant} setTimelineVariant={setTimelineVariant} />
        </>
    );
};

export type HeaderText = {
    legend: string;
    title: string;
    legendDays?: Dayjs[];
};

export type TimelineVariant = 'MONTHS' | 'WEEKS';

export default Calendar;
