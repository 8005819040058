import React, { useMemo } from 'react';

// Dependencies
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { useOutletContext, useParams } from 'react-router-dom';

// Components
import CompanyForm from 'components/company/form';

// Helpers
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import useCreateCustomer from 'services/queries/customers/use-create-customer';
import useGetCustomerSetup from 'services/queries/customers/use-get-customer-setup';
import useGetBusinessTypesOptions from 'services/queries/business-type/use-get-business-types-options';
import useEditCustomer from 'services/queries/customers/use-edit-customer';
import useGetCustomer from 'services/queries/customers/use-get-customer';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import useUploadFile from 'services/queries/files/use-upload-file';

const customerDetailsQueryFields = jsonToGraphQLQuery({
    id: true,
    juridic_document: true,
    juridic_fantasy_name: true,
    juridic_name: true,
    title: true,
    type_business_id: true,
    address: {
        address: {
            id: true,
            compliment: true,
            neighbourhood: true,
            number: true,
            street: true,
            zip: true,
            city_id: true,
            state_id: true
        }
    },
    file: {
        id: true,
        filename: true,
        path: true,
        original_name: true
    }
});

const ClientCreatePage = ({ title }: CrudPageProps) => {
    const queries = useGetCustomerSetup();
    const params = useOutletContext<GraphqlPaginationVariables<any>>();

    const { customerId } = useParams();

    const isEdit = !!customerId;

    const { data: businessTypes, isLoading: isLoadingBusinessTypes } = useGetBusinessTypesOptions();

    const { mutateAsync: createCustomer, isLoading: isSubmitting } = useCreateCustomer(params);

    const { mutateAsync: editCustomer } = useEditCustomer(params, customerId);

    const { data: customerDetails, isLoading: isLoadingCustomerDetails } = useGetCustomer(customerDetailsQueryFields, customerId);

    const { isLoading: isLoadingFile, uploadFile } = useUploadFile();

    const isBuilding = useMemo(() => queries.some((query) => query.isLoading) || isLoadingCustomerDetails || isLoadingBusinessTypes, [isLoadingBusinessTypes, queries, isLoadingCustomerDetails]);

    const defaultValues = {
        ...customerDetails,
        address: get(customerDetails, 'address[0].address')
    };

    const handleSubmit = async (data: any) => {
        try {
            const hasChangedImage = !data?.file?.id && !!data?.file?.path;

            if (hasChangedImage) {
                await uploadFile(
                    data?.file,
                    ({ id }) => {
                        data.image = id;
                    },
                    true
                );
            } else {
                data.image = data?.file?.id ?? null;
            }

            const address = pick(data?.address, ['city_id', 'state_id', 'compliment', 'neighbourhood', 'number', 'street', 'zip']);

            const payload: any = {
                title: data?.title,
                typeBusiness: data?.type_business_id,
                ...(data?.juridic_name && { juridicName: data?.juridic_name }),
                ...(data?.juridic_fantasy_name && { juridicFantasyName: data?.juridic_fantasy_name }),
                ...(data?.juridic_document && { juridicDocument: data?.juridic_document }),
                ...(data?.image && { image: data?.image }),
                ...(!isEmpty(address) && { address })
            };

            if (isEdit) {
                await editCustomer(payload);
            } else {
                if (data.contacts.length) {
                    payload.contacts = data.contacts?.map((person) => ({ person })) ?? [];
                }

                await createCustomer(payload);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <CompanyForm
            defaultValues={defaultValues}
            businessTypes={businessTypes || []}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting || isLoadingFile}
            title={title || ''}
            onSubmit={handleSubmit}
            showImage={true}
        />
    );
};

export default ClientCreatePage;
