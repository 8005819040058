// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { ProjectProposalType } from 'types/models/project';
import project, { getProjectProposalTypeByPkKey } from './graphql';

type Response = {
    project_proposal_type_by_pk: Partial<ProjectProposalType>;
};

const useGetProjectProposalType = (projectProposalTypeId?: string) => {
    const fetcher = async () => {
        return apiGraphQl<Response>(project.getProjectProposalTypeByPk, { id: projectProposalTypeId }).then((data) => data.project_proposal_type_by_pk);
    };

    return useQuery(getProjectProposalTypeByPkKey(projectProposalTypeId), fetcher);
};

export default useGetProjectProposalType;
