import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';

import useToast from 'hooks/toast/use-toast';
import { getBudgetVersionsKeys } from './keys';
import { useNavigate } from 'react-router-dom';
import { CommercialBudgetVersionDetails, CostItem } from 'types/models/commercial';
import { Modify } from 'types/general';

type UpdateBudgetVersionPayloadPicked = Pick<CommercialBudgetVersionDetails, 'footage' | 'deadline' | 'factor' | 'date' | 'costs' | 'groupedCosts'>;

export type UpdateBudgetVersionPayload = Modify<
    UpdateBudgetVersionPayloadPicked,
    {
        percentageCostAdministrative: number;
        percentageCostTax: number;
        percentageCommercialExpenses: number;
        percentageFinancialExpenses: number;
        costs: CostItem[];
    }
>;

const useUpdateBudgetVersion = (budgetId?: string, projectTypeId?: string, budgetVersionId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const request = async (payload: UpdateBudgetVersionPayload) => {
        const endpoint = `/budgets/${budgetId}/${projectTypeId}/versions/${budgetVersionId}`;
        return api.patch(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getBudgetVersionsKeys(budgetId));
            showToast(`Versão atualizada com sucesso`, 'success');
            navigate(-1);
        },
        onError: () => {
            showToast('Ocorreu um erro ao atualizar a versão', 'danger');
        }
    });
};

export default useUpdateBudgetVersion;
