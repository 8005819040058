import React from 'react';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Empty from 'components/empty';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { Task } from 'types/models/task';
import { CamelCase } from 'types/utils';
import { formatDate } from 'utils/date';
import ExternalButton from 'components/buttons/external';

type ActivitiesTableProps = {
    data: CamelCase<Task>[];
};

const ActivitiesTable = ({ data }: ActivitiesTableProps) => {
    return (
        <>
            {Boolean(data.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tarefa</TableCell>
                                <TableCell>Data Fim</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => {
                                return (
                                    <TableRow sx={{ '&:last-child td': { border: 0 } }} key={index}>
                                        <TableCell>
                                            <ExternalButton href={`${window.location.origin}/app/tarefas/${item.id}`}>{item.title}</ExternalButton>
                                        </TableCell>
                                        <TableCell>{formatDate(item.endDate)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Empty title="Nenhuma tarefa encontrado" className="p-4" />
            )}
        </>
    );
};

export default ActivitiesTable;
