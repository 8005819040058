import { NumberFormatValues } from 'react-number-format';
import { maskToCurrency } from './money';
import { EnumType } from 'json-to-graphql-query';

export const removeFalsyValues = (obj) => JSON.parse(JSON.stringify(obj, (key, value) => (value === null ? undefined : value)));

export const flattenObject = <T>(object: T, initialPathPrefix = '') => {
    if (!object || typeof object !== 'object') {
        return [{ [initialPathPrefix]: object }];
    }

    const prefix = initialPathPrefix ? (Array.isArray(object) ? initialPathPrefix : `${initialPathPrefix}.`) : '';

    return Object.keys(object)
        .flatMap((key) => flattenObject(object[key], Array.isArray(object) ? `${prefix}[${key}]` : `${prefix}${key}`))
        .reduce((acc, path) => ({ ...acc, ...path }));
};

export const toCurrencyObject = (value: number): NumberFormatValues => {
    return {
        formattedValue: maskToCurrency({ nextState: value }),
        floatValue: value,
        value: value?.toString()
    };
};

export const convertObjectToGraphqlOrderBy = (obj) => {
    const orderBy = obj;

    for (const key in orderBy) {
        if (typeof obj[key] === 'object' && !(obj[key] instanceof EnumType)) {
            convertObjectToGraphqlOrderBy(orderBy[key]);
        } else {
            obj[key] = obj[key] instanceof EnumType ? obj[key] : new EnumType(obj[key] || 'asc');
            // orderBy[key] = 'asidhasiud';
        }
    }

    return orderBy;
};
