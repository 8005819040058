import Modal from 'components/core/modal';
import ResetUserPasswordForm from './reset-password-form';
import useResetUserPassword from 'services/queries/users/use-reset-user-password';
import { UserResetPassword } from 'types/models/user';

type ResetUserPasswordProps = {
    onResetPasswordModal: () => void;
};

const ResetUserPassword = ({ onResetPasswordModal }: ResetUserPasswordProps) => {
    const { mutateAsync: userPasswordMutation, isLoading: isSubmitting } = useResetUserPassword();

    const handleSubmit = async (data: UserResetPassword) => {
        await userPasswordMutation(data);
        onResetPasswordModal();
    };

    return (
        <Modal contentClassnames="w-[500px]" onClose={onResetPasswordModal}>
            <ResetUserPasswordForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />
        </Modal>
    );
};

export default ResetUserPassword;
