import { useEffect } from 'react';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import { useNavigate } from 'react-router-dom';
import theme from 'settings/theme';
import { Option } from 'types/general';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { personContractSchema } from 'pages/private/people/create-or-update/utils/schema';
import { CreatePersonContractPayload } from 'types/models/person';
import PersonContract from 'components/person-contract';

type PersonDetailsContractsCreateFormProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    branches: Option[];
    onSubmit: (data: CreatePersonContractPayload) => void;
    title: string;
    defaultValues?: any;
    isCreate: boolean;
};

const PersonDetailsContractsCreateForm = ({ isBuilding, branches, isSubmitting, onSubmit, defaultValues, title, isCreate = false }: PersonDetailsContractsCreateFormProps) => {
    const navigate = useNavigate();

    const handleClose = () => navigate(-1);

    const methods = useForm<CreatePersonContractPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(personContractSchema)
    });

    const { handleSubmit, reset } = methods;

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    return (
        <Modal onClose={handleClose} contentClassnames="w-[800px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            {title}
                        </Text>
                        <PersonContract {...methods} branches={branches} className="mb-8" hideFinalDate={isCreate} />
                        <div className="flex items-center">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default PersonDetailsContractsCreateForm;
