import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';
import { CreateClassificationPayload } from 'types/models/configurations-classifications';
import queries, { getClassificationKey } from './graphql';

type Response = {
    items: CreateClassificationPayload & {
        id: string;
        classification: { id: number; name: string };
        project_service: { id: number; title: string };
        system: boolean;
    };
};

const useGetClassificationId = (classificationId?: string) => {
    const fetcher = async () => apiGraphQl<Response>(queries.getClassificationById, { classificationId }).then(({ items }) => items);

    return useQuery(getClassificationKey(classificationId), fetcher, { enabled: !!classificationId });
};

export default useGetClassificationId;
