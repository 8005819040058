import Icon from 'components/core/icon';
import Text from 'components/core/text';
import { keys } from 'lodash';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatabookResponse } from 'services/queries/operationals/use-get-databooks';
import { DatabookFilesResponse } from '../types/types';
import useTheme from '@mui/material/styles/useTheme';
import Menu from '@mui/material/Menu/Menu';

type DatabookItemMenuProps = {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    itemRef: DatabookResponse & DatabookFilesResponse;
};

const DatabookItemMenu = ({ anchorEl, onClose, itemRef }: DatabookItemMenuProps) => {
    const { palette } = useTheme();

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const optionsContent = useMemo(
        () => [
            {
                label: 'Abrir',
                icon: {
                    color: palette.grey[500],
                    name: 'ico-folder-move'
                },
                onClick: () => {
                    const isFile = keys(itemRef).includes('file');

                    if (isFile) {
                        const path = itemRef.file?.url;
                        open(path, '_blank');
                        onClose();

                        return;
                    }

                    navigate(`${pathname}/${itemRef.id}`);

                    onClose();
                }
            }
        ],
        [palette, itemRef, onClose, navigate, pathname]
    );

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={Boolean(anchorEl)}
            PaperProps={{
                classes: {
                    elevation: 'shadow-none  max-w-sm rounded-[14px] custom-shadow'
                }
            }}
            onClose={onClose}>
            <div>
                {optionsContent.map((item, key) => (
                    <div key={key}>
                        <button className="w-full flex items-center py-3 gap-4 px-4 text-base-500 hover:bg-base-200 cursor-pointer" onClick={item.onClick}>
                            <Icon name={item.icon.name} color={item.icon.color} width={16} height={16} />
                            <Text as="span">{item.label}</Text>
                        </button>
                    </div>
                ))}
            </div>
        </Menu>
    );
};

export default DatabookItemMenu;
