// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import { User } from 'types/models/user';
import { createUserKey } from './graphql';
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { GraphqlPaginationVariables } from 'types/graphql';

export type UserPayload = {
    name: string;
    username: string;
    email: string;
    profile: number;
};

const useCreateUser = (params: GraphqlPaginationVariables<User>) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const createUser = async (payload: UserPayload) => api.post<any>('/users', payload);

    return useMutation(createUserKey(), createUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('users', params));

            navigate(-1);

            showToast('Usuário criado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar o usuário', 'danger');
        }
    });
};

export default useCreateUser;
