// Dependencies
import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import apiService from 'services/api';
import apiGraphQl from 'services/graphql';

// Helpers
import { createKey, getAllKey } from './graphql';

type Config = {
    api?: {
        endpoint: string;
        method?: 'POST' | 'PATCH' | 'PUT';
    };
    graphql?: {
        query?: (params?: any) => string;
        table?: string;
    };
    onError?(error: unknown): void;
    onSuccess?(data: any): void;
    id?: string;
};

function useCreateOrUpdate(config: Config) {
    const { api, graphql, onSuccess, onError, id } = config || {};
    const { showToast } = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const request = async (payload: any) => {
        if (!!graphql?.query) {
            const params = { payload, ...(!!id && { id }) };

            return apiGraphQl(graphql?.query?.(params), params);
        }

        return apiService[api?.method! || 'POST'](api?.endpoint!, payload);
    };

    return useMutation(createKey(config), request, {
        onError: (error) => {
            showToast('Ocorreu um erro ao salvar. Tente novamente.', 'danger');

            if (onError) {
                onError(error);
            }
        },
        onSuccess: (data) => {
            showToast('Registro salvo com sucesso!', 'success');

            navigate(-1);

            queryClient.invalidateQueries(getAllKey(graphql?.table!));

            if (onSuccess) {
                onSuccess(data);
            }
        }
    });
}

export default useCreateOrUpdate;
