import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { ClassificationType } from 'types/models/classification';
import dictionary from 'utils/dictionary';
import { number, object, string } from 'yup';

export const getProjectDocumentClassificationsQuery = jsonToGraphQLQuery(
    {
        query: {
            __name: 'GetClassificationsOptions',
            items: {
                __aliasFor: 'classification',
                __args: {
                    where: {
                        classification_id: { _is_null: true },
                        type: { _eq: ClassificationType.ProjectDocument }
                    },
                    order_by: { name: new EnumType('asc') }
                },
                value: { __aliasFor: 'id' },
                label: { __aliasFor: 'name' },
                parentId: { __aliasFor: 'classification_id' }
            }
        }
    },
    { pretty: true }
);

export const projectDocumentSchema = object({
    classification: number().required(dictionary.validation.required),
    file: number().required(dictionary.validation.required),
    name: string().required(dictionary.validation.required),
    description: string(),
    observation: string()
});
