import React, { useContext, useMemo } from 'react';

// Dependencies
import { useOutletContext, useParams } from 'react-router-dom';

// Components
import Content, { ParticipantsPayload } from './participants';

// Helpers
import { MeetingSteps } from 'contexts/meeting-record/types';
import { MeetingRecordContext } from 'contexts/meeting-record';
import useGetPeopleOptions from 'services/queries/people/use-get-people-options';
import useCreateOrUpdateMeetingRecord from 'services/queries/projects/use-create-or-update-meeting-record';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';

const Participants = () => {
    const { meetingRecordId, projectId: projectIdParam } = useParams();

    const ctx = useOutletContext<OperationalDetailsPageContext | undefined>();

    const projectId = useMemo(() => Number(projectIdParam) || ctx?.project?.id, [projectIdParam, ctx?.project?.id]);

    const { data: people, isLoading } = useGetPeopleOptions();

    const { state, changeStep, setContent } = useContext(MeetingRecordContext);

    const { mutateAsync: createOrUpdateMeetingRecord, isLoading: isSubmitting } = useCreateOrUpdateMeetingRecord(meetingRecordId, projectId);

    const handleBack = () => changeStep(state.currentStep.slug, undefined, MeetingSteps.Files);

    const handleSubmit = async (data: ParticipantsPayload) => {
        try {
            const internals = data.internal.map((item) => ({ ...item, person: item.person.value }));
            const participants = [...internals, ...data.external];

            const meetingRecord = await createOrUpdateMeetingRecord({ participants });

            const formattedParticipants = {
                internal: meetingRecord.participants
                    .filter((item) => item.person)
                    .map((item) => ({
                        validationRequired: item.validationRequired,
                        person: { value: item.person?.id, label: item.person?.name }
                    })),
                external: meetingRecord.participants
                    .filter((item) => item.email)
                    .map((item) => ({
                        name: item.name,
                        email: item.email,
                        phones: item.phones,
                        jobPosition: item.jobPosition,
                        company: item.company,
                        validationRequired: item.validationRequired
                    }))
            };

            setContent({ participants: formattedParticipants });
        } catch (error) {
            console.log('error', error);
        }
    };

    return <Content participants={state.content.participants} isBuilding={isLoading} isSubmitting={isSubmitting} people={people || []} onGoBack={handleBack} onSubmit={handleSubmit} />;
};

export default Participants;
