import { createActions, createReducer } from 'reduxsauce';
import { BreadcrumbActionsType, BreadcrumbState, BreadcrumbActions } from './types';
import { RouteObject } from 'react-router-dom';

export const { Creators, Types } = createActions<Record<BreadcrumbActionsType, BreadcrumbActionsType>, BreadcrumbActions>({
    setRoutes: ['payload'],
    toggleVisibility: ['payload']
});

const INITIAL_STATE: BreadcrumbState = {
    routes: [],
    isVisible: true
};

const setRoutes = (state = INITIAL_STATE, action: { type: string; payload: RouteObject[] }): BreadcrumbState => ({
    ...state,
    routes: action.payload
});

const toggleVisibility = (state = INITIAL_STATE, action: { type: string; payload: boolean }): BreadcrumbState => ({
    ...state,
    isVisible: action.payload
});

export default createReducer<BreadcrumbState>(INITIAL_STATE, {
    [Types.SET_ROUTES]: setRoutes,
    [Types.TOGGLE_VISIBILITY]: toggleVisibility
});
