import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { formatDate } from 'utils/date';
import { useLocation, useNavigate } from 'react-router-dom';

import ModalFilter from 'components/modal/filter';
import Select from 'components/core/form/select';
import DatePicker from 'components/core/datepicker';
import omitBy from 'lodash/omitBy';

import useResponsive from 'hooks/responsive/use-responsive';
import qs from 'qs';
import { FinancialExpensesFilterFormState, FinancialExpensesFilterQueryParsed } from 'types/models/financial';
import useGetProviderOptions from 'services/queries/operationals/use-get-provider-options';
import { projectTypes } from 'utils/statics';
import useGetClassificationsOptions from 'services/queries/configs-classifications/use-get-classifications-options';
import { ClassificationType } from 'types/models/classification';
import useGetCustomersOptions from 'services/queries/customers/use-get-customers-options';
import isEmpty from 'lodash/isEmpty';

type OperationalFilterProps = {
    onClose(): void;
    isReceipts?: boolean;
};

const FinancialExpensesFilter = ({ onClose, isReceipts = false }: OperationalFilterProps) => {
    const { isMobile } = useResponsive();
    const { search } = useLocation();
    const navigate = useNavigate();

    const { data: providerOptions = [], isLoading: isLoadingProviders } = useGetProviderOptions(false, !isReceipts);
    const { data: customerOptions = [], isLoading: isLoadingCustomers } = useGetCustomersOptions();
    const { data: classifications = [], isLoading: isLoadingClassifications } = useGetClassificationsOptions(ClassificationType.FinancialClassification);

    const filtersQueryParsed: FinancialExpensesFilterQueryParsed = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);

    const { control, formState, handleSubmit, reset } = useForm<FinancialExpensesFilterFormState>({
        mode: 'onSubmit'
    });

    useEffect(() => {
        const paramsWithFormatedDate = {
            ...(!!filtersQueryParsed.filter?.dueDateStartDate && { dueDateStartDate: formatDate(filtersQueryParsed.filter?.dueDateStartDate, 'MM/DD/YYYY') }),
            ...(!!filtersQueryParsed.filter?.dueDateEndDate && { dueDateEndDate: formatDate(filtersQueryParsed.filter?.dueDateEndDate, 'MM/DD/YYYY') }),
            ...(!!filtersQueryParsed.filter?.paydayStartDate && { paydayStartDate: formatDate(filtersQueryParsed.filter?.paydayStartDate, 'MM/DD/YYYY') }),
            ...(!!filtersQueryParsed.filter?.paydayEndDate && { paydayEndDate: formatDate(filtersQueryParsed.filter?.paydayEndDate, 'MM/DD/YYYY') }),
            ...(!!filtersQueryParsed.filter?.provider && { provider: filtersQueryParsed.filter?.provider }),
            ...(!!filtersQueryParsed.filter?.customer && { customer: filtersQueryParsed.filter?.customer })
        };

        reset(paramsWithFormatedDate);
    }, [reset, filtersQueryParsed.filter]);

    const clear = () => {
        onClose();
        navigate('.');
    };

    const submit = (data: FinancialExpensesFilterFormState) => {
        const newDataWithFormatedDate = {
            ...data,
            dueDateStartDate: formatDate(data.dueDateStartDate, 'YYYY-MM-DD'),
            dueDateEndDate: formatDate(data.dueDateEndDate, 'YYYY-MM-DD'),
            paydayStartDate: formatDate(data.paydayStartDate, 'YYYY-MM-DD'),
            paydayEndDate: formatDate(data.paydayEndDate, 'YYYY-MM-DD')
        };

        const result: any = omitBy(newDataWithFormatedDate, (v) => !v || v === '-' || !v?.length);

        if (isEmpty(result)) {
            return clear();
        }

        const filters = qs.stringify(
            { filter: result },
            {
                addQueryPrefix: true,
                encode: false,
                arrayFormat: 'brackets'
            }
        );

        if (isMobile) {
            onClose();
        }

        navigate(filters);
        //TODO: use useSearchParams for submit
    };

    const fieldCustomerOrProviderName = isReceipts ? 'customer' : 'provider';
    const isLoadingCustomerOrProviders = isLoadingCustomers || isLoadingProviders;

    return (
        <ModalFilter onClose={onClose} onSubmit={handleSubmit(submit)} onClear={clear}>
            <div className="gap-4 flex flex-col">
                <Controller
                    name={fieldCustomerOrProviderName}
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = providerOptions?.filter((item) => fieldValuesNumber?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={isReceipts ? customerOptions : providerOptions}
                                isLoading={isLoadingCustomerOrProviders}
                                label={isReceipts ? 'Cliente(s)' : 'Fornecedor(es)'}
                                isMulti={true}
                                isClearable={true}
                                placeholder="Selecione uma opção ou mais opções"
                                error={(formState.errors as any)?.[fieldCustomerOrProviderName]?.message}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                            />
                        );
                    }}
                />

                <Controller
                    name="programs"
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = projectTypes.filter((item) => fieldValuesNumber?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={projectTypes}
                                label="Programas"
                                isMulti={true}
                                isClearable={true}
                                placeholder="Selecione uma opção ou mais opções"
                                error={(formState.errors as any).programs?.message}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                            />
                        );
                    }}
                />

                <Controller
                    name="classifications"
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = classifications.filter((item) => fieldValuesNumber?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                isLoading={isLoadingClassifications}
                                options={classifications}
                                label="Classificações"
                                isMulti={true}
                                isClearable={true}
                                placeholder="Selecione uma opção ou mais opções"
                                error={(formState.errors as any).classifications?.message}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                            />
                        );
                    }}
                />

                <div>
                    <label htmlFor="initialPeriod" className="text-base-500 mb-4 block">
                        Período de vencimento
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                        <Controller
                            name="dueDateStartDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data inicial" error={formState.errors.dueDateStartDate?.message} {...field} />}
                        />
                        <Controller
                            name="dueDateEndDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data final" error={formState.errors.dueDateEndDate?.message} {...field} />}
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="finalPeriod" className="text-base-500 mb-4 block">
                        Período de Pagamento
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                        <Controller
                            name="paydayStartDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data inicial" error={formState.errors.paydayStartDate?.message} {...field} />}
                        />
                        <Controller
                            name="paydayEndDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data final" error={formState.errors.paydayEndDate?.message} {...field} />}
                        />
                    </div>
                </div>
            </div>
        </ModalFilter>
    );
};

export default FinancialExpensesFilter;
