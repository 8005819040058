import dayjs from 'dayjs';
import { months } from 'utils/date';

const generateYearsArr = () => {
    const startYear = 2022;
    const currentYear = dayjs().year();
    const numberOfYears = currentYear - startYear + 1;
    const moreYearOptions = 2;

    const years = Array.from({ length: numberOfYears + moreYearOptions }, (_, index) => startYear + index);

    return years;
};

export const yearsOptions = generateYearsArr().map((y) => ({ value: y, label: y.toString() }));

export const monthsOptions = months.map((m, i) => ({ value: i + 1, label: m }));
