import { useMutation } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import api from 'services/api';

import { useNavigate } from 'react-router-dom';

const useForgotPassword = () => {
    const { showToast } = useToast();
    const navigate = useNavigate();

    const fetcher = async (payload: { email: string }) => {
        return api.post('/forgot-password', payload);
    };

    return useMutation(fetcher, {
        onSuccess: ({ data }) => {
            showToast(`Email para renovação de senha foi enviado com sucesso`, 'success');
            navigate(`${data.token}`);
        },
        onError: () => {
            showToast(`Ocorreu um erro ao verificar seu email em nosso sistema`, 'danger');
        }
    });
};

export default useForgotPassword;
