import React from 'react';

// Dependencies
import useTheme from '@mui/material/styles/useTheme';

// Components
import Icon from 'components/core/icon';
import Button from 'components/core/button';

type AddButtonProps = {
    onClick?: () => void;
    className?: string;
    linkComponent?: any;
    to?: string;
};

const AddButton: React.FC<AddButtonProps> = ({ className, children, linkComponent, to, onClick }) => {
    const { palette } = useTheme();

    return (
        <Button
            LinkComponent={linkComponent}
            to={to}
            startIcon={<Icon name="ico-plus" width={12} height={12} color={palette.primary[500]} />}
            type="button"
            variant="outlined"
            size="small"
            className={`p-2 px-3 border-0 ${className}`}
            sx={{ '&:hover': { border: 'none' } }}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

export default AddButton;
