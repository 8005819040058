import React, { useCallback, useEffect, useMemo } from 'react';

// Dependencies
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import IconButton from '@mui/material/IconButton/IconButton';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';

// Helpers
import { FileApiModel } from 'types/models/file';
import useUploadFile from 'services/queries/files/use-upload-file';
import useDeleteFile from 'services/queries/files/use-delete-file';
import Spinner from 'components/core/spinner';

type FileRow = {
    onPersistFile: (file: FileApiModel) => void;
    onRemoveFromList: (fileName: string) => void;
    file?: Partial<File & FileApiModel>;
};

type Icon = {
    name: string;
    width: number;
    height: number;
};

const FileRow = ({ file: fileItem, onPersistFile, onRemoveFromList }: FileRow) => {
    const { palette } = useTheme();

    const handleContent = (data?: FileApiModel) => {
        if (!data) {
            return;
        }

        onPersistFile(data);
    };

    const { error, file, isLoading, isSuccess, uploadFile } = useUploadFile(handleContent);
    const { mutateAsync: deleteFile, isLoading: isDeleting } = useDeleteFile();

    useEffect(() => {
        if (!fileItem?.id && Boolean(fileItem?.name)) {
            uploadFile(fileItem);
        }
        // eslint-disable-next-line
    }, []);

    const handleRetry = useCallback(() => {
        if (fileItem?.name) {
            uploadFile(fileItem);
        }
    }, [fileItem, uploadFile]);

    const fileIcon = useMemo(() => {
        if (file?.mimeType.includes('image')) {
            return { name: 'ico-image', width: 20, height: 20 };
        }

        if (file?.mimeType.includes('pdf')) {
            return { name: 'ico-pdf', width: 20, height: 24.43 };
        }

        if (file?.mimeType.includes('sheet')) {
            return { name: 'ico-excel', width: 20, height: 24.43 };
        }

        return { name: 'ico-text', width: 20, height: 24.43 };
    }, [file?.mimeType]);

    const handleRemoveFromList = useCallback(async () => {
        if (Boolean(fileItem)) {
            try {
                await deleteFile(fileItem?.id!);
                onRemoveFromList(fileItem?.originalName! || fileItem?.path!);
            } catch (error) {
                console.error(error);
            }
        }
    }, [fileItem, onRemoveFromList, deleteFile]);

    const handleShowFile = useCallback(() => window.open(fileItem?.url || file?.url, '_blank'), [file?.url, fileItem?.url]);

    return (
        <TableRow
            sx={{
                '&:hover': { backgroundColor: palette.grey[200] },
                '&:last-child td, &:last-child th': { border: 0 }
            }}>
            <TableCell>{isLoading ? <Skeleton animation="wave" variant="circular" width={20} height={20} /> : <Icon {...fileIcon} color={palette.primary[500]} />}</TableCell>
            <TableCell>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={18} />
                ) : (
                    <Text as="span" variant="body.regular.sm" className="text-base-500">
                        {fileItem?.originalName || fileItem?.filename || fileItem?.name || fileItem?.path || file?.filename || ''}
                    </Text>
                )}
            </TableCell>
            <TableCell>
                {isLoading ? (
                    <Skeleton animation="wave" variant="circular" width={15} height={15} />
                ) : (
                    <Icon
                        name={isSuccess || Boolean(fileItem?.id) ? 'ico-check' : 'ico-close'}
                        width={12}
                        className="ml-2"
                        color={isSuccess || Boolean(fileItem?.id) ? palette.success[500] : palette.error[500]}
                    />
                )}
            </TableCell>
            <TableCell>
                {isLoading ? (
                    <div className="flex">
                        <Skeleton animation="wave" variant="circular" width={20} height={20} className="mr-1" />
                        <Skeleton animation="wave" variant="circular" width={20} height={20} />
                    </div>
                ) : (
                    <div className="flex items-center">
                        {(isSuccess || Boolean(fileItem?.id)) && (
                            <Tooltip title="Ver arquivo">
                                <IconButton size="small" type="button" color="info" onClick={handleShowFile}>
                                    <Icon name="ico-show" width={16} height={16} color={palette.info[500]} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {Boolean(error) && (
                            <Tooltip title="Tentar novamente">
                                <IconButton size="small" type="button" color="info" onClick={handleRetry}>
                                    <Icon name="ico-refresh" width={16} height={16} color={palette.info[500]} />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Remover da lista">
                            {isDeleting ? (
                                <Spinner size={16} fixed={false} color={palette.secondary[500]} />
                            ) : (
                                <IconButton size="small" type="button" color="error" onClick={handleRemoveFromList}>
                                    <Icon name="ico-trash" width={16} height={16} color={palette.error[500]} />
                                </IconButton>
                            )}
                        </Tooltip>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};

export default FileRow;
