import React, { useEffect, useState } from 'react';

// Dependencies
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table/Table';
import useTheme from '@mui/material/styles/useTheme';
import PaperButton from '@mui/material/Button/Button';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import ChecklistSubItem from './sub-item';
import Button from 'components/core/button';
import Input from 'components/core/form/input';

// Helpers
import { Task } from 'types/models/task';
import useCreateOrUpdateTask from 'services/queries/projects/use-create-or-update-task';
import { Option } from 'types/general';

type ChecklistItemProps = {
    task: Partial<Task>;
    statuses: Option[];
};

const ChecklistItem = ({ task, statuses }: ChecklistItemProps) => {
    const { palette } = useTheme();
    const { projectProposalTypeId } = useParams();

    const [taskStatus, setTaskStatus] = useState<number>();
    const [description, setDescription] = useState('');

    useEffect(() => {
        setDescription(task.description || '');
    }, [task.description]);

    useEffect(() => {
        setTaskStatus(task.taskStatus?.id);
    }, [task.taskStatus?.id]);

    const { mutateAsync: createOrUpdateTask } = useCreateOrUpdateTask(projectProposalTypeId);

    const handleSave = async (description: string, taskId?: number, status?: number) => {
        try {
            const payload = {
                description,
                taskId,
                taskStatus: status
            };

            await createOrUpdateTask(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleSelectStatus = (value: number) => () => {
        if (value === taskStatus) {
            return;
        }

        setTaskStatus(value);
    };

    return (
        <>
            <TableRow classes={{ root: 'bg-primary-100 bg-opacity-10' }}>
                <TableCell>
                    <Text as="span" className="text-base-600">
                        {task.order}
                    </Text>
                </TableCell>
                <TableCell>
                    <Text as="span" className="text-base-600">
                        {task.title}
                    </Text>
                </TableCell>
                <TableCell>
                    <div className="flex">
                        <ButtonGroup size="small">
                            {statuses.map((item) => (
                                <PaperButton
                                    variant={taskStatus === item.value ? 'contained' : 'outlined'}
                                    key={item.value}
                                    disableElevation={true}
                                    color="primary"
                                    onClick={handleSelectStatus(item.value)}>
                                    {item.label}
                                </PaperButton>
                            ))}
                        </ButtonGroup>
                    </div>
                </TableCell>
                <TableCell>
                    <Input parentClassName="min-w-[400px]" value={description} name="description" type="text" onChange={(e) => setDescription(e.currentTarget.value)} />
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        color="success"
                        type="button"
                        startIcon={<Icon color={palette.success[500]} name="ico-save" width={16} height={16} />}
                        onClick={() => handleSave(description, task.id, taskStatus)}>
                        Salvar
                    </Button>
                </TableCell>
            </TableRow>
            {Boolean(task.tasks?.length) && (
                <TableRow>
                    <TableCell classes={{ root: 'p-4' }} colSpan={5}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Sub-item
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Nome
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Status
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Observação
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Ações
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {task.tasks?.map((item) => {
                                    return <ChecklistSubItem statuses={statuses} key={item.id} parentTask={task} task={item} onSave={handleSave} />;
                                })}
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default ChecklistItem;
