import TableCell from '@mui/material/TableCell/TableCell';
import Badge from 'components/core/badge';
import { budgetStatusMap } from 'pages/private/commercial/budgets/utils';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';

export const commercialFPList: CrudPageProps = {
    graphql: {
        table: 'budget',
        excludedSearchColumnsName: ['budget_status'],
        columns: [
            {
                name: 'id',
                options: { display: 'excluded' }
            },
            {
                name: 'code',
                label: dictionary.content.graphql['code'],
                options: {
                    customBodyRender: (value) => value || '-'
                }
            },
            {
                name: 'title',
                label: dictionary.content.graphql['title'],
                options: {
                    customBodyRender: (value) => value || '-'
                }
            },
            {
                name: 'customer.title',
                label: dictionary.content.graphql['customer.title'],
                options: {
                    customBodyRender: (value) => value || '-'
                }
            },
            {
                name: 'budget_has_types.project_type.title',
                label: 'Programas',
                options: {
                    sort: false,
                    customBodyRender: (_, tableMeta) => {
                        const programs: { project_type: { title: string } }[] = tableMeta.rowData.at(-1).props.item?.budget_has_types || [];

                        if (!programs.length) {
                            return '-';
                        }

                        return (
                            <div className="flex flex-wrap gap-2">
                                {programs.map((program, index) => (
                                    <Badge variant="info" key={index}>
                                        {program.project_type.title}
                                    </Badge>
                                ))}
                            </div>
                        );
                    }
                }
            },
            {
                name: 'budget_status',
                label: 'Status',
                options: {
                    customBodyRender: (value) => (!!value ? <Badge variant={budgetStatusMap[value]['variant'] || 'grey'}>{budgetStatusMap[value]['label'] || '-'} </Badge> : '-')
                }
            },
            {
                name: 'actions',
                label: 'Ações',
                options: {
                    customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                }
            }
        ]
    },
    title: 'FP'
};
