import apiGraphQl from 'services/graphql';
import queries, { getProjectProposalResourceById } from './graphql';
import { useQuery } from 'react-query';
import { ProjectProposalTypeResource } from 'types/models/project';

type ProjectProposalResouceByIdResponse = {
    items: Partial<ProjectProposalTypeResource>;
};

const useGetProjectProposalResourceById = (resourceId: string) => {
    const request = () => apiGraphQl<ProjectProposalResouceByIdResponse>(queries.getProjectProposalResourceById, { resourceId }).then(({ items }) => items);

    return useQuery(getProjectProposalResourceById(resourceId), request, { enabled: Boolean(resourceId) });
};

export default useGetProjectProposalResourceById;
