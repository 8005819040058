/**
 * For the brave souls who get this far: You are the chosen ones,
 * the valiant knights of programming who toil away, without rest,
 * fixing our most awful code. To you, true saviors, kings of men,
 * I say this: never gonna give you up, never gonna let you down,
 * never gonna run around and desert you. Never gonna make you cry,
 * never gonna say goodbye. Never gonna tell a lie and hurt you.
 *
 *
 * Once you are done trying to 'optimize' this routine,
 * and have realized what a terrible mistake that was,
 * please increment the following counter as a warning
 * to the next guy:
 *
 * total_hours_wasted_here = 4
 *
 */

import _isArray from 'lodash/isArray';

function isArray(data) {
    return _isArray(data);
}

function isString(data) {
    return typeof data === 'string';
}

function isArrayOfStrings(data) {
    return isArray(data) && !data.some((i) => typeof i !== 'string');
}

function isObject(data) {
    return !isArray(data) && !isString(data) && !!data && typeof data === 'object';
}

type ErrorReturn = { name: string; message: string };

function convertErrorByType(error: any, key?: string): ErrorReturn[] {
    if (!error) {
        return [];
    }

    let msgs: ErrorReturn[] = [];

    const concatKeyWithError = (err) => ({ name: key!, message: err });

    if (isArrayOfStrings(error)) {
        msgs = [...msgs, ...error.map(concatKeyWithError)];
    } else if (isArray(error)) {
        let formattedErrors: ErrorReturn[] = [];

        error.forEach((e) => {
            formattedErrors = [...formattedErrors, ...generateErrorMessages(e, key)];
        });

        msgs = [...msgs, ...formattedErrors];
    } else if (isString(error)) {
        msgs.push(concatKeyWithError(error));
    } else if (isObject(error)) {
        msgs = [...msgs, ...generateErrorMessages(error)];
    }

    return msgs;
}

/**
 *
 * This function converts the API error response into an array of strings
 *
 * @param errorsObj Object of errors
 * @returns array of strings
 */
export function generateErrorMessages(errorsObj: any, key?: string): ErrorReturn[] {
    if (!errorsObj) {
        return [];
    }

    if (!isObject(errorsObj)) {
        return convertErrorByType(errorsObj);
    }

    const keys = Object.keys(errorsObj);

    let msgs: ErrorReturn[] = [];

    keys.forEach((name) => {
        const error = errorsObj[name];

        msgs = [...msgs, ...convertErrorByType(error, [key, name].filter(Boolean).join('.'))];
    });

    return msgs;
}
