import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import DatePicker from 'components/core/datepicker';
import Editor from 'components/core/form/editor';
import FormButtons from 'components/core/form/buttons';
import useUpdateProjectTypeStatus, { UpdateProjectTypeStatusPayload } from 'services/queries/operationals/use-update-project-type-status';
import { ProjectTypeStatus } from 'types/models/project';
import { schemaUpdateProjectStatus, updateProjectLabelsByProjectTypeStatus } from '../utils';
import { yupResolver } from '@hookform/resolvers/yup';

type UpdateProjectTypeStatusProps = {
    updateProjectTypeStatus: ProjectTypeStatus;
    onSetProjectTypeStatus: React.Dispatch<React.SetStateAction<ProjectTypeStatus | null>>;
};

const UpdateProjectTypeStatus = ({ updateProjectTypeStatus, onSetProjectTypeStatus }: UpdateProjectTypeStatusProps) => {
    const { projectProposalTypeId, projectId, taskPlanningId } = useParams();

    const { mutateAsync: updateProjectTypeStatusMutate, isLoading: isSubmitting } = useUpdateProjectTypeStatus(projectProposalTypeId, projectId, taskPlanningId);

    const { control, formState, handleSubmit } = useForm<UpdateProjectTypeStatusPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schemaUpdateProjectStatus)
    });

    const submit = async (data: UpdateProjectTypeStatusPayload) => {
        try {
            await updateProjectTypeStatusMutate({
                ...data,
                status: updateProjectTypeStatus
            });

            onSetProjectTypeStatus(null);
        } catch (error) {
            console.error('submit - updateProjectStatus: ', error);
        }
    };

    return (
        <Modal onClose={onSetProjectTypeStatus.bind(this, null)} closeOnClickOutside={false} contentClassnames="w-[800px]">
            <form className="py-5 px-7" onSubmit={handleSubmit(submit)}>
                <Text as="h3" variant="h4" className="text-heading mb-5">
                    {updateProjectLabelsByProjectTypeStatus[updateProjectTypeStatus].title}
                </Text>
                <div className="grid gap-4 mb-4">
                    <Controller
                        name="informedDate"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                label={updateProjectLabelsByProjectTypeStatus[updateProjectTypeStatus].label}
                                placeholderText="Selecione uma data"
                                error={formState.errors.informedDate?.message}
                                {...field}
                            />
                        )}
                    />
                </div>
                <div className="mb-4">
                    <Controller
                        name="reason"
                        control={control}
                        render={({ field }) => (
                            <Editor {...field} error={formState.errors.reason?.message} label={updateProjectLabelsByProjectTypeStatus[updateProjectTypeStatus].reasonLabel} parentClasses="mb-6" />
                        )}
                    />
                </div>
                <FormButtons onCancel={onSetProjectTypeStatus.bind(this, null)} isLoading={isSubmitting} />
            </form>
        </Modal>
    );
};

export default UpdateProjectTypeStatus;
