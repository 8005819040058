// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import cities, { getAllCitiesOptionsKey } from './graphql';

const useGetCitiesOptions = (state_id?: number) => {
    const getAllCities = () => apiGraphQl<Pagination<Option>>(cities.getAll, { state_id }).then((data) => data.items);

    return useQuery(getAllCitiesOptionsKey(state_id), getAllCities, { enabled: !!state_id });
};

export default useGetCitiesOptions;
