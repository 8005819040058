import { useQuery } from 'react-query';
import api from 'services/api';
import { ProviderFinancialPayment } from 'types/models/provider';
import { CamelCase } from 'types/utils';
import { getMeasurementPaymentsKey } from './graphql';

const useGetMeasurementPayments = (providerFinancialSetupId?: number, measurementId?: number, date?: string) => {
    const fetcher = async () => {
        return api.post<CamelCase<ProviderFinancialPayment>[]>(`/provider-financial-setups/${providerFinancialSetupId}/measurements/${measurementId}/payments`, { date }).then(({ data }) => data);
    };

    return useQuery(getMeasurementPaymentsKey(date), fetcher, { enabled: Boolean(date) });
};

export default useGetMeasurementPayments;
