import React from 'react';

// Dependencies
import { useNavigate } from 'react-router-dom';

// Components
import ManagementContent from './management';

// Helpers
import { ProjectTypeProps } from '../types';
import useGetProjectProposalType from 'services/queries/projects/use-get-project-proposal-type';
import useGetChecklist from 'services/queries/projects/use-get-checklist';

const Management = ({ activeAccordion, managementInformations, projectProposalId, projectTypeId, projectProposalTypeId, onToggleAccordion }: ProjectTypeProps) => {
    const navigate = useNavigate();

    const { data: projectProposalType, isLoading } = useGetProjectProposalType(projectProposalTypeId);
    const { data: checklist } = useGetChecklist(true, projectProposalType?.id?.toString());

    const handleGoTo = (path: string) => () => navigate(path);

    return (
        <ManagementContent
            activeAccordion={activeAccordion}
            projectProposalType={projectProposalType}
            isLoading={isLoading}
            managementInformations={managementInformations}
            onCreateProposalType={handleGoTo(`proposta/${projectProposalId}/tipo/${projectTypeId}`)}
            onToggleAccordion={onToggleAccordion}
            checklist={checklist}
        />
    );
};

export default Management;
