import React, { useMemo } from 'react';
import theme from 'settings/theme';
import Empty from 'components/empty';
import Text from 'components/core/text';
import { UseQueryResult } from 'react-query';
import Spinner from 'components/core/spinner';
import type { ReportImage } from 'types/models/report';
import TaskLightbox from '../../task/lightbox';
import { FileApiModel } from 'types/models/file';

const Images = ({ isLoading, isError, data: images }: UseQueryResult<ReportImage[], unknown>) => {
    const files = useMemo(() => images?.reduce((acc: FileApiModel[], curr) => [...acc, curr.file], []), [images]) || [];

    return (
        <div className="border border-base-300 rounded-[14px] mb-4">
            <Text as="h6" variant="h6" className="p-4 text-heading">
                Fotos obra
            </Text>
            {isLoading ? (
                <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} className="min-h-[100px]" />
            ) : isError ? (
                <Empty title="Dados não encontrados" className="p-4" />
            ) : (
                <>
                    {Boolean(files?.length) ? (
                        <div className="flex items-center px-4 pb-4 gap-4 flex-wrap">
                            <TaskLightbox items={files?.map((item) => ({ url: item.url, urlThumb: item.urlThumb })) || []} />
                        </div>
                    ) : (
                        <Empty title="Nenhum arquivo encontrado" className="p-4 pt-0" />
                    )}
                </>
            )}
        </div>
    );
};

export default Images;
