import get from 'lodash/get';

export const getNameOrErrorFieldArray = (formState: any, baseName?: string, index?: number) => (name: string) => {
    const start = baseName ? `${baseName}.` : '';
    const nameFieldArray = typeof index === 'number' ? `${start}${index}.${name}` : start + name;
    return {
        name: nameFieldArray,
        error: get(formState.errors, `${nameFieldArray}.message`)
    };
};
