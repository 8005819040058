// Dependencies
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import { CamelCase } from 'types/utils';
import useToast from 'hooks/toast/use-toast';
import { ProjectMeetingRecord } from 'types/models/project';
import { getProjectKey, getProjectMeetingRecordsKey, publishMeetingRecordKey } from './graphql';

const usePublishMeetingRecord = (meetingRecordId?: string, projectId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();

    const fetcher = async () => {
        return api.patch<CamelCase<ProjectMeetingRecord>>(`/meeting-records/${meetingRecordId}/publish`).then((response) => response.data);
    };

    return useMutation(publishMeetingRecordKey(meetingRecordId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectKey(projectId));

            if (pathname.includes('operacional')) {
                const params = {
                    limit: 10,
                    page: 1,
                    offset: 0,
                    orderBy: { date: 'desc' },
                    where: {
                        project_id: { _eq: projectId }
                    }
                };

                queryClient.invalidateQueries(getProjectMeetingRecordsKey(params, projectId));

                navigate(-1);
            } else {
                navigate(`/app/contratos/${projectId}`);
            }

            showToast('Ata de reunião criada com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar a ata de reunião', 'danger');
        }
    });
};

export default usePublishMeetingRecord;
