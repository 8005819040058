import CalendarHeaderMonths from './months';

const CalendarHeader = () => {
    return (
        <div className="flex sticky top-0 mt-0 ">
            <CalendarHeaderMonths />
        </div>
    );
};

export default CalendarHeader;
