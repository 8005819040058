import { useCallback, useEffect } from 'react';

const useClickOutside = (ref: any, handler: any) => {
    const listener = useCallback(
        (event: any) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            if (handler) {
                handler(event);
            }
        },
        [ref, handler]
    );

    useEffect(() => {
        document.addEventListener('mousedown', listener, false);

        return () => document.removeEventListener('mousedown', listener, false);
    }, [listener]);
};

export default useClickOutside;
