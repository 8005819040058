import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { CommercialBudgetVersionDetails } from 'types/models/commercial';
import { getBudgetVersionsKeys } from './keys';
import { useNavigate } from 'react-router-dom';

const useCreateNewCommercialBudgetVersion = (budgetId?: string, projectTypeId?: number | null) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { showToast } = useToast();

    const request = async () => {
        const { data } = await api.post<CommercialBudgetVersionDetails>(`/budgets/${budgetId}/${projectTypeId}/versions`);

        return data;
    };

    return useMutation(request, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(getBudgetVersionsKeys(budgetId));
            navigate(`/app/fp/${budgetId}/versoes/${data.id}/${data.projectType.id}/editar`);
            showToast(`Nova versão criada com sucesso!`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao criar uma nova versão do FP.`, 'danger');
        }
    });
};

export default useCreateNewCommercialBudgetVersion;
