import React, { useEffect } from 'react';

// Dependencies
import { useParams } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { array, date, number, object, string } from 'yup';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';

// Helpers
import { Option } from 'types/general';
import dictionary from 'utils/dictionary';
import { MeetingPayload } from 'contexts/meeting-record/types';

type DetailsProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    meeting: MeetingPayload;
    people: Option[];
    projectProposalTypeId?: number;
    onSubmit: (data: MeetingPayload) => void;
};

const Details = ({ isBuilding, isSubmitting, meeting, people, projectProposalTypeId, onSubmit }: DetailsProps) => {
    const { palette } = useTheme();
    const { meetingRecordId, projectId } = useParams();

    const schema = object({
        project: number().required(dictionary.validation.required).default(Number(projectId!)),
        projectProposalType: number().default(projectProposalTypeId!),
        title: string().required(dictionary.validation.required),
        dateFormatted: date().required(dictionary.validation.required),
        editors: array(
            object({
                value: number().required(dictionary.validation.required),
                label: string().required(dictionary.validation.required)
            })
        ).default([])
    });

    const { control, formState, handleSubmit, reset } = useForm<MeetingPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: {
            dateFormatted: meeting.dateFormatted || undefined,
            editors: meeting.editors,
            title: meeting.title
        },
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (meeting) {
            reset(meeting);
        }
    }, [meeting, reset]);

    return (
        <div>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-[30px]">
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            Detalhes
                        </Text>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                            <Controller name="title" control={control} render={({ field }) => <Input {...field} error={formState.errors.title?.message} label="Título" />} />
                            <Controller name="dateFormatted" control={control} render={({ field }) => <Input {...field} type="datetime-local" label="Data da reunião" />} />
                        </div>
                        <Controller
                            name="editors"
                            control={control}
                            render={({ field }) => {
                                return <Select {...field} isMulti={true} options={people} label="Responsáveis" placeholder="Selecione uma opção" error={(formState as any).errors.editors?.message} />;
                            }}
                        />
                    </div>
                    <div className="border-t py-2 px-2 flex justify-end">
                        <Button
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            endIcon={<Icon name="ico-arrow-right" width={18} color={isSubmitting ? palette.grey[400] : palette.secondary[500]} />}
                            type="submit"
                            variant="text"
                            color={isSubmitting ? 'inherit' : 'secondary'}
                            className="min-w-[100px]">
                            {meetingRecordId ? 'Atualizar ata' : 'Criar ata'}
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Details;
