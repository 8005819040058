import { useMemo, useState } from 'react';
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';

import { useNavigate, useParams } from 'react-router-dom';
import Empty from 'components/empty';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';

import useGetFollowup from 'services/queries/operationals/use-get-followup';

import DetailsTab from '../components/tabs/details';
import Effectives from '../components/tabs/effectives';
import Files from 'pages/private/projects/meeting-records/components/publish/files';

type PartialFileType = Partial<{ filename: string; url: string; originalName?: string | undefined }>;

const DetailsTabsModal = () => {
    const navigate = useNavigate();
    const [tab, setTab] = useState('DETAILS');

    const { projectProposalTypeId, dailyReportId } = useParams();

    const { data, isLoading, error } = useGetFollowup(+projectProposalTypeId!, dailyReportId);

    const files = useMemo(() => {
        if (data?.workDiaries) {
            return data.workDiaries.map((item) => ({ ...(item as any).file, provider: { id: (item.provider as any).id, title: (item.provider as any).title } }));
        }
    }, [data?.workDiaries]);

    const handleClose = () => navigate(-1);

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[700px]" onClose={handleClose}>
            {isLoading ? (
                <Spinner />
            ) : Boolean(error) || !Boolean(data) ? (
                <Empty title="Ocorreu um erro ao carregar a ata de reunião" className="p-6" />
            ) : (
                <div className="py-7 px-3 sm:p-7">
                    <Text as="h6" variant="h6" className="text-heading mb-3">
                        Acompanhamento #{data?.order.toString().padStart(3, '0')}
                    </Text>
                    <Tabs variant="scrollable" classes={{ root: 'px-0' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                        <Tab value="DETAILS" label="Detalhes" />
                        <Tab value="FILES" label="Arquivos" />
                        <Tab value="EFFECTIVES" label="Efetivos" />
                    </Tabs>
                    {tab === 'DETAILS' && <DetailsTab details={data} />}
                    {tab === 'FILES' && <Files files={(files as PartialFileType[]) || []} className="border-0 mb-0 px-0" withTableHead={true} />}
                    {tab === 'EFFECTIVES' && <Effectives details={data} />}
                </div>
            )}
        </Modal>
    );
};

export default DetailsTabsModal;
