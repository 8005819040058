// Dependencies
import storage from 'redux-persist/lib/storage';
import createMigrate from 'redux-persist/es/createMigrate';
import { persistReducer, persistStore } from 'redux-persist';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

// Helpers
import theme from 'settings/theme';
import migrations from './migrations';
import { isDev } from 'utils/environment';
import reducers, { ReduxStore } from './ducks';
import authMiddleware from './middlewares/auth';

const persistConfig = {
    key: theme.metadata.slug,
    storage,
    whitelist: ['auth', 'config'],
    stateReconciler: autoMergeLevel2,
    debug: false,
    version: 4,
    migrate: createMigrate(migrations)
};

const persistedReducer = persistReducer<ReduxStore>(persistConfig, reducers as any);

const composition = isDev ? composeWithDevTools : compose;

const store = createStore(persistedReducer, composition(applyMiddleware(authMiddleware) as any));

const persistor = persistStore(store);

export { store, persistor };
