// Dependencies
import dayjs, { Dayjs } from 'dayjs';
import { WeekDaysEnum } from 'types/date';

const HOUR_IN_MINUTES_OR_MINUTES_IN_SECONDS = 60;

export const formatDate = (date?: string | Date | Dayjs, format = 'DD/MM/YYYY', fallbackMessage: any = '-', withUTC = false) => {
    if (!isValidDate(date)) {
        return fallbackMessage;
    }

    return dayjs(date).utc(withUTC).locale('pt-br').format(format);
};

export const isValidDate = (date: any) => {
    if (!date) {
        return false;
    }

    return dayjs(date).isValid();
};

// Preventing the date picker component to break
export const convertToDateOrReturnUndefined = (date?: any) => {
    if (date === null || !isValidDate(new Date(date))) {
        return undefined;
    }

    return new Date(date);
};

export const weekDays = [
    { label: 'Segunda-feira', value: 1 },
    { label: 'Terça-feira', value: 2 },
    { label: 'Quarta-feira', value: 3 },
    { label: 'Quinta-feira', value: 4 },
    { label: 'Sexta-feira', value: 5 },
    { label: 'Sábado', value: 6 },
    { label: 'Domingo', value: 7 }
];

export const timeToMinutes = (value?: string): number => {
    if (!value) {
        return 0;
    }

    const [hours, minutes] = value.split(':');

    return (Number(hours) || 0) * HOUR_IN_MINUTES_OR_MINUTES_IN_SECONDS + Number(minutes);
};

export const timeToHours = (value?: string): number => {
    if (!value) {
        return 0;
    }

    const [hours] = value.split(':');

    return Number(hours) || 0;
};

export const minutesToHours = (minutes: number, type: 'string' | 'number' = 'string') => {
    const minutesToHour = minutes / HOUR_IN_MINUTES_OR_MINUTES_IN_SECONDS;

    if (type === 'number') {
        return minutesToHour;
    }

    const hours = Math.floor(minutesToHour);
    const min = minutes % HOUR_IN_MINUTES_OR_MINUTES_IN_SECONDS;

    return `${hours.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
};

export const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

export const weekendDays = [WeekDaysEnum.Sunday, WeekDaysEnum.Saturday];

export const hourToMinutes = (hour = 0) => hour * HOUR_IN_MINUTES_OR_MINUTES_IN_SECONDS;
