import React from 'react';

// Dependencies
import { useFieldArray } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';

// Components
import Empty from 'components/empty';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import ErrorMessage from 'components/error/message';
import RemoveButton from 'components/buttons/remove';
import ContactsFields from './fields';

const defaultContact = {
    name: '',
    title: ''
};

type Props = UseFormReturn<any, any> & { withUser?: boolean };

const Contacts = ({ withUser = true, ...props }: Props) => {
    const { control, formState } = props;

    const handleAdd = () => append(defaultContact);

    const handleDelete = (index: number) => () => remove(index);

    const { fields, append, remove } = useFieldArray({ name: 'contacts', control });

    return (
        <div className="border border-base-300 rounded-[14px] py-4 px-2 sm:p-4 mb-4">
            <Text as="h5" variant="h6" className="text-heading mb-3">
                Contatos
            </Text>
            {Boolean(fields.length) ? (
                fields.map((item, index) => {
                    return (
                        <div key={item.id} className="border border-base-300 rounded-[14px] py-4 px-2 sm:p-4 mb-4 group relative">
                            <RemoveButton onClick={handleDelete(index)} />
                            <ContactsFields baseName="contacts" index={index} methods={props} withUser={withUser} />
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhum contato adicionado ainda" className="mb-2" />
            )}
            <AddButton onClick={handleAdd}>Novo contato</AddButton>
            <ErrorMessage visible={Boolean(formState.errors?.contacts?.message)} className="block mt-4">
                {formState.errors?.contacts?.message}
            </ErrorMessage>
        </div>
    );
};

export default Contacts;
