import useGetQueryParam from 'hooks/router/use-get-query-param';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useCreateOrUpdateTaskProgress from 'services/queries/operationals/use-create-or-update-task-progress';
import TaskProgress, { TaskProgressPayload } from './progress';
import useGetTask from 'services/queries/task/use-get-task';
import { useQueryClient } from 'react-query';
import { getTasksByStatusKey } from 'services/queries/task-dashboard/graphql';
import useChangeTaskStatus from 'services/queries/task/use-change-task-status';
import useGetTaskProgress from 'services/queries/task/use-get-task-progress';

const TASK_STATUS_DONE = 3;
const TASK_STATUS_PROCESSING = 5;

const TaskProgressPage = () => {
    const { taskId } = useParams();
    const navigate = useNavigate();
    const { pathname, state } = useLocation();
    const queryClient = useQueryClient();
    const timelineId = useGetQueryParam('timelineId');
    const taskProgressId = useGetQueryParam('taskProgressId');

    const { data: task } = useGetTask(taskId);

    const taskType = task?.type;

    const { mutateAsync: createTaskProgress, isLoading } = useCreateOrUpdateTaskProgress(timelineId, taskId, taskProgressId);
    const { mutateAsync: changeTaskStatus, isLoading: isLoadingChangeStatus } = useChangeTaskStatus();
    const { data: taskProgressDetails, isLoading: isLoadingProgressDetails } = useGetTaskProgress(task?.id, +taskProgressId!);

    const isDashboardTasksPage = pathname.includes('horas-gastas');

    const handleSubmitWithProgress = async (data: TaskProgressPayload) => {
        try {
            await createTaskProgress(data);

            if (state?.source?.statusId === TASK_STATUS_PROCESSING) {
                await updateTaskStatus();
            }
        } catch (error) {
            console.log('handleSubmitWithProgress', error);
        }
    };

    const updateTaskStatus = async (withRedirect?: boolean) => {
        try {
            await changeTaskStatus({ status: TASK_STATUS_DONE, taskId: Number(taskId || 0) });

            if (isDashboardTasksPage) {
                queryClient.invalidateQueries(getTasksByStatusKey(TASK_STATUS_PROCESSING));
                queryClient.invalidateQueries(getTasksByStatusKey(TASK_STATUS_DONE));
            }

            if (withRedirect) {
                navigate('/app/tarefas', { replace: true });
            }
        } catch (error) {
            console.log('handleSubmitWithProgress', error);
        }
    };

    const defaultValues = {
        observations: taskProgressDetails?.observations || '',
        date: taskProgressDetails?.date,
        strHour: taskProgressDetails?.hour
    };

    return (
        <TaskProgress
            isDashboardTasksPage={isDashboardTasksPage}
            taskType={taskType}
            taskStatus={task?.taskStatus.slug}
            isBuilding={isLoadingProgressDetails}
            isSubmitting={isLoading || isLoadingChangeStatus}
            onSubmitWithProgress={handleSubmitWithProgress}
            onSubmitWithoutProgress={() => updateTaskStatus(true)}
            defaultValues={defaultValues}
        />
    );
};

export default TaskProgressPage;
