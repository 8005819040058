import React from 'react';

// Dependencies
import ptBR from 'react-timeago/lib/language-strings/pt-br';
import ReactTimeAgo, { ReactTimeagoProps } from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(ptBR);

const TimeAgo = (props: ReactTimeagoProps & { className?: string }) => {
    return <ReactTimeAgo {...props} component="span" formatter={formatter} />;
};

export default TimeAgo;
