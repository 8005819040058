import React from 'react';

// Dependencies
import { useParams } from 'react-router-dom';

// Components
import MeetingRecord from './meeting-record';

// Helpers
import useGetMeetingRecord from 'services/queries/projects/use-get-meeting-record';

const MeetingRecordPage = () => {
    const { meetingRecordId } = useParams();
    const meetingRecord = useGetMeetingRecord(meetingRecordId);

    return <MeetingRecord {...meetingRecord} />;
};

export default MeetingRecordPage;
