import { MenuItem } from 'types/models/menu';
import { dropdownFloatedClasses, menuItemStyles } from '../item';
import { NavLink } from 'react-router-dom';

type SubmenuProps = {
    isSidebarSmall: boolean;
    handleNavLinkClasses: ({ isActive }: { isActive: boolean }) => string;
    item: MenuItem;
};

const Submenu = ({ isSidebarSmall, handleNavLinkClasses, item }: SubmenuProps) => {
    return (
        <ul className={`${isSidebarSmall ? dropdownFloatedClasses : ''}`}>
            {item.pages?.map((submenu, ix) => (
                <li key={ix}>
                    <NavLink to={submenu.link!} className={handleNavLinkClasses}>
                        <div className={menuItemStyles.bulletWrapper}>
                            <span className={menuItemStyles.bullet} />
                        </div>
                        {submenu.title}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

export default Submenu;
