import React from 'react';

// Dependencies
import { Outlet, useParams } from 'react-router-dom';

// Components
import Empty from 'components/empty';
import Spinner from 'components/core/spinner';

// Helpers
import useGetDocument from 'services/queries/projects/use-get-document';

const Document = () => {
    const { projectProposalTypeId, projectTypeDocumentId } = useParams();
    const document = useGetDocument(projectProposalTypeId, projectTypeDocumentId);

    if (document.isLoading) {
        return <Spinner />;
    }

    if (Boolean(document.error) || !document.data) {
        return <Empty title="Documento não encontrado" />;
    }

    return <Outlet context={document.data} />;
};

export default Document;
