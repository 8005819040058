export const reorderArray = (arr, startIndex, endIndex) => {
    const [removed] = arr.splice(startIndex, 1);

    arr.splice(endIndex, 0, removed);

    return arr;
};

export const arrayMove = <T>(array: readonly T[], from: number, to: number) => {
    const slicedArray = array.slice();

    slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);

    return slicedArray;
};
