import { formatDate, minutesToHours } from 'utils/date';
import { People } from '../../types';
import { DAY_HEIGHT, DAY_WIDTH } from '../../utils';
import _sumBy from 'lodash/sumBy';

type PeopleMapPerMonthContentProps = {
    days: Date[];
    people: People[];
};

const Content = ({ days, people }: PeopleMapPerMonthContentProps) => {
    const lineWidth = (days?.length + 1) * DAY_WIDTH;

    return (
        <>
            {people.map(({ id, agenda }) => {
                const total = minutesToHours(_sumBy(agenda, 'totalInt'), 'string');

                return (
                    <div className="mt-[-1px] flex divide-x divide-slate-200 w-full border-b border-slate-200 last:border-b-0" key={`line_person_${id}`} style={{ width: lineWidth }}>
                        {days.map((day, i) => {
                            const task = agenda.find((agendaItem) => formatDate(agendaItem.date, 'DD/MM') === formatDate(day, 'DD/MM'));

                            return (
                                <div key={`${day}_${i}`} style={{ width: DAY_WIDTH, height: DAY_HEIGHT }} className=" relative">
                                    {!!task && (
                                        <div
                                            className={`truncate flex items-center uppercase text-base-600 justify-center text-heading font-medium text-xs h-full w-full`}
                                            key={task.id}
                                            style={{ background: task.color }}>
                                            {task.total}
                                        </div>
                                    )}
                                </div>
                            );
                        })}

                        <div style={{ width: DAY_WIDTH, height: DAY_HEIGHT }} className=" relative">
                            <div className={`truncate flex items-center uppercase text-base-600 justify-center text-heading font-bold text-xs w-full h-full`}>{total}</div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default Content;
