import Empty from 'components/empty';
import Spinner from 'components/core/spinner';
import Customer from './details';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import { CRUDDetailsProps } from 'types/graphql';
import { Company } from 'types/models/company';

type Response = Partial<Company>;

const CustomerPage = ({ page, data, isLoading, error }: CRUDDetailsProps<Response>) => {
    useBreadcrumb(data?.title);

    if (isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (Boolean(error) || !data) {
        return <Empty title="Cliente não encontrado" />;
    }

    return <Customer subpages={page?.subpages || []} customer={data} />;
};

export default CustomerPage;
