import api from 'services/api';
import { useMutation, useQueryClient } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import { getTaskKey } from './graphql';

const useDeleteTaskProgress = (taskId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (taskProgressId?: number) => api.delete(`/tasks/${taskId}/progress/${taskProgressId}`);

    return useMutation(request, {
        onSuccess: () => {
            showToast('Progresso da tarefa excluído com sucesso!', 'success');

            queryClient.invalidateQueries(getTaskKey(taskId));
        },
        onError: () => {
            showToast('Ocorreu um erro ao excluir o progresso da tarefa', 'danger');
        }
    });
};

export default useDeleteTaskProgress;
