// Dependencies
import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { getContactKey } from './graphql';

const useGetContact = (props: { contactId?: string; endpoint: string }) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const { endpoint, contactId } = props || {};

    const fetcher = async () => {
        const { data } = await api.get<any>(`${endpoint}/${contactId}`);

        if (!data?.id) {
            throw new Error();
        }

        return data;
    };

    return useQuery(getContactKey(contactId), fetcher, {
        enabled: !!endpoint && !!contactId,
        onError: () => {
            navigate(-1);

            showToast('Tivemos um problema ao obter os detalhes desse contato.', 'danger');
        }
    });
};

export default useGetContact;
