import React, { useEffect, useMemo } from 'react';

// Dependencies
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

// Components
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import AddButton from 'components/buttons/add';
import ContractForm from 'components/contract';
import Input from 'components/core/form/input';
import Editor from 'components/core/form/editor';
import RemoveButton from 'components/buttons/remove';

// Helpers
import { Status } from 'types/general';
import { ProjectProposalResourcesPayload, ProjectProposalTypeResource } from 'types/models/project';
import { projectProposalResourceSchema } from './utils';

type CreateOrUpdateResourcesProps = {
    isSubmitting: boolean;
    onSubmit: (data: ProjectProposalResourcesPayload) => void;
    defaultValues?: Partial<ProjectProposalTypeResource>;
};

const defaultResource = {
    quantity: 0,
    status: Status.Active
};

const CreateOrUpdateResources = ({ isSubmitting, onSubmit, defaultValues }: CreateOrUpdateResourcesProps) => {
    const navigate = useNavigate();
    const { proposalTypeId, resourceId } = useParams();

    const handleClose = () => navigate(-1);

    const schema = useMemo(() => projectProposalResourceSchema(Number(proposalTypeId)), [proposalTypeId]);

    const methods = useForm<ProjectProposalResourcesPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        defaultValues: {
            resources: [defaultResource]
        }
    });

    const { control, formState, handleSubmit, reset } = methods;

    useEffect(() => {
        if (Boolean(defaultValues?.id) && Boolean(resourceId)) {
            reset({ resources: [defaultValues] });
        }
    }, [defaultValues, reset, resourceId]);

    const { fields, append, remove } = useFieldArray({ control, name: 'resources' });

    const handleCreateResource = () => append(defaultResource);

    const handleDeleteResource = (index: number) => () => remove(index);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="sm:min-w-[500px]" onClose={handleClose}>
            <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                <Text as="h3" variant="h4" className="text-heading mb-5">
                    {Boolean(resourceId) ? 'Editar recurso' : 'Adicionar recursos'}
                </Text>
                {fields.map((item, index) => {
                    return (
                        <div key={item.id} className="group mb-5 border border-base-300 p-4 rounded-[14px] relative">
                            {Boolean(index) && <RemoveButton onClick={handleDeleteResource(index)} />}
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                                <ContractForm
                                    fieldNames={{
                                        frequency: `resources.${index}.frequency`,
                                        jobPosition: `resources.${index}.job_position_id`,
                                        seniority: `resources.${index}.seniority`,
                                        shift: `resources.${index}.shift`
                                    }}
                                    {...methods}
                                />
                                <Controller
                                    name={`resources.${index}.quantity`}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            error={formState.errors.resources?.[index]?.quantity?.message}
                                            label="Quantidade"
                                            placeholder="Ex: 10"
                                            rightClasses="bg-base-200 px-4"
                                            right={
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    profissionais
                                                </Text>
                                            }
                                        />
                                    )}
                                />
                            </div>
                            <Controller name={`resources.${index}.observations`} control={control} render={({ field }) => <Editor {...field} label="Observações" />} />
                        </div>
                    );
                })}
                {!resourceId && <AddButton onClick={handleCreateResource}>Novo recurso</AddButton>}

                <div className="flex items-center mt-4">
                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                        Enviar
                    </Button>
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                        Cancelar
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default CreateOrUpdateResources;
