import React from 'react';

import SubTask from './subtask';
import ActionButton from './action';
import Icon from 'components/core/icon';
import Input from 'components/core/form/input';
import useTheme from '@mui/material/styles/useTheme';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Checkbox from 'components/core/form/checkbox';
import Tooltip from '@mui/material/Tooltip/Tooltip';

export type TaskType = {
    id?: number;
    title: string;
    mandatory: boolean;
    tasks: Array<{
        title: string;
        mandatory: boolean;
    }>;
};

type TaskProps = {
    index: number;
    onDeleteTask: () => void;
};

const Task = ({ index, onDeleteTask }: TaskProps) => {
    const { palette } = useTheme();

    const { control, formState, setValue, getValues } = useFormContext<{ tasks: TaskType[] }>();
    const { fields, append, remove } = useFieldArray({ name: `tasks.${index}.tasks`, control });

    const handleAddSubtask = () => append({ title: '', mandatory: false });

    const handleCheck = (event) => {
        const isChecked = event.currentTarget.checked;

        setValue(`tasks.${index}.mandatory`, isChecked);

        const item = getValues(`tasks.${index}`);

        if (Boolean(item.tasks?.length)) {
            for (const [ix] of item.tasks.entries()) {
                setValue(`tasks.${index}.tasks.${ix}.mandatory`, isChecked);
            }
        }
    };

    return (
        <div className="border rounded-xl relative p-5 mb-4 border-base-300">
            <div className="flex items-baseline flex-wrap">
                <Controller
                    name={`tasks.${index}.mandatory`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Tooltip placement="top" title="Obrigatório" disableFocusListener={true}>
                                <Checkbox {...field} value={field.value as any} checked={field.value} containerClasseName="mr-4 pl-[7px]" onChange={handleCheck} />
                            </Tooltip>
                        );
                    }}
                />
                <span className="flex items-center justify-center bg-base-200 text-heading rounded-[10px] min-w-[30px] px-2 py-[2px] mr-4">{index + 1}</span>
                <Controller
                    name={`tasks.${index}.title`}
                    control={control}
                    render={({ field }) => (
                        <Input {...field} autoComplete="nope" type="text" placeholder="Título da tarefa" error={formState.errors.tasks?.[index]?.title?.message} parentClassName="flex-1 w-[unset]" />
                    )}
                />
                <ActionButton variant="add" className="ml-2" onClick={handleAddSubtask} />
                <ActionButton variant="remove" className="ml-2" onClick={onDeleteTask} />
            </div>
            {Boolean(fields?.length) && (
                <div className="flex items-center relative w-full">
                    <Icon name="ico-children" color={palette.grey[300]} width={10} height={45} className="h-[unset] absolute left-[14px] top-0" />
                    <div className="w-full">
                        {fields.map((subtask, ix) => {
                            return <SubTask key={subtask.id} parentIndex={index} childrenIndex={ix} onDeleteSubtask={() => remove(ix)} />;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Task;
