import React from 'react';

// Dependencies
import MuiCard, { CardProps } from '@mui/material/Card/Card';

const Card = ({ children, elevation = 21, ...props }: CardProps) => (
    <MuiCard elevation={elevation} {...props}>
        {children}
    </MuiCard>
);

export default Card;
