import React from 'react';

// Dependencies
import Table from '@mui/material/Table/Table';
import useTheme from '@mui/material/styles/useTheme';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import IconButton from '@mui/material/IconButton/IconButton';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';

// Components
import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Switch from 'components/core/switch';
import AddButton from 'components/buttons/add';
import Select from 'components/core/form/select';

// Helpers
import { Option } from 'types/general';
import { ParticipantsPayload } from './participants';

type InternalProps = {
    people: Option[];
} & UseFormReturn<ParticipantsPayload, any>;

const defaultUser = {
    person: undefined,
    validationRequired: false
};

const Internal = ({ control, formState, people, watch, setValue }: InternalProps) => {
    const { palette } = useTheme();

    const { fields, append, remove } = useFieldArray({ name: 'internal', control });

    const handleAddUser = () => append(defaultUser);

    const handleDeleteUser = (index: number) => () => remove(index);

    const handleValidationField = (index: number) => (value: boolean) => setValue(`internal.${index}.validationRequired`, value);

    return (
        <div className="border border-base-300 !rounded-2xl mb-4">
            <Text as="h6" variant="h6" className="text-heading px-4 py-3">
                Usuários do sistema
            </Text>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell classes={{ root: 'py-2 min-w-[150px]' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Usuário
                                </Text>
                            </TableCell>
                            <TableCell classes={{ root: 'py-2' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Necessário validação?
                                </Text>
                            </TableCell>
                            <TableCell classes={{ root: 'py-2' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Ação
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(fields.length) ? (
                            fields.map((item, index) => {
                                const validationRequired = watch(`internal.${index}.validationRequired`);

                                return (
                                    <TableRow key={item.id}>
                                        <TableCell classes={{ root: 'p-0 py-1' }} width="55%">
                                            <Controller
                                                name={`internal.${index}.person`}
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            options={people}
                                                            placeholder="Selecione uma opção"
                                                            error={(formState as any).errors.internal?.[index]?.person?.message}
                                                            errorMessageClasses="pl-4"
                                                            selectStyles={{
                                                                control: (base) => ({
                                                                    ...base,
                                                                    border: 'none',
                                                                    borderColor: 'transparent !important',
                                                                    boxShadow: 'none !important',
                                                                    padding: '0 16px'
                                                                })
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell classes={{ root: 'py-0 py-1' }} width="35%">
                                            <Switch value={validationRequired} onChange={handleValidationField(index)} />
                                        </TableCell>
                                        <TableCell classes={{ root: 'py-0 py-1' }} width="10%">
                                            <IconButton size="large" type="button" color="error" onClick={handleDeleteUser(index)}>
                                                <Icon name="ico-trash" width={16} height={16} color={palette.error[500]} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Empty title="Nenhum usuário adicionado até o momento" />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell classes={{ root: 'py-2 border-none' }} colSpan={2}>
                                <AddButton onClick={handleAddUser}>Mais usuários</AddButton>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Internal;
