import classNames from 'classnames';
import { HeaderText } from '../..';
import { isValidDate } from 'utils/date';

type Props = {
    items: HeaderText['legendDays'];
};

const WeekDays = ({ items = [] }: Props) => {
    return (
        <div className="w-full grid grid-cols-7 items-end mt-1 justify-between gap-1 px-1">
            {items.map((day) => {
                if (!isValidDate(day)) {
                    return null;
                }

                const isWeekend = [0, 6].includes(day.get('day'));

                return (
                    <span className={classNames('bg-slate-200 text-heading text-xs flex items-center justify-center rounded-md', isWeekend && 'opacity-40')} key={day.format('DD/MM/YYYY')}>
                        {day.format('D')}
                    </span>
                );
            })}
        </div>
    );
};

export default WeekDays;
