import { useOutletContext, useParams } from 'react-router-dom';
import CreateOrUpdatePersonBankAccount from './create-or-update';

import { PersonBankAccountDetails, PersonBankAccountPayload } from 'types/models/person';
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';

import useGetPersonBankAccountById from 'services/queries/people/use-get-person-bank-account-by-id';
import useCreateOrUpdatePersonBankAccountMutation from 'services/queries/people/use-create-or-update-person-bank-account';

const CreateOrUpdatePersonBankAccountPage = ({ title }: CrudPageProps) => {
    const { personId, bankAccountId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<PersonBankAccountDetails>>();

    const { mutateAsync: mutatePersonBankAccount, isLoading: isSubmiting, error } = useCreateOrUpdatePersonBankAccountMutation({ params, personId, bankAccountId });

    const { data: personBankAccountDetails, isLoading: isLoadingDetails } = useGetPersonBankAccountById(bankAccountId);

    const handleSubmit = async (data: PersonBankAccountDetails & { bank: { value: number; label: string } }) => {
        try {
            const newDataPayload: PersonBankAccountPayload = {
                bank: data.bank.value,
                agency: data.agency,
                agencyDigit: data.agency_digit,
                account: data.account,
                accountDigit: data.account_digit,
                pix: data.pix,
                main: data.main,
                type: 1
            };
            await mutatePersonBankAccount(newDataPayload);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <CreateOrUpdatePersonBankAccount title={title || ''} isBuilding={isLoadingDetails} isSubmitting={isSubmiting} onSubmit={handleSubmit} defaultValues={personBankAccountDetails} error={error} />
    );
};

export default CreateOrUpdatePersonBankAccountPage;
