import React, { memo, useRef } from 'react';

// Dependencies
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton/IconButton';

// Components
import Icon from '../icon';

// Helpers
import theme from 'settings/theme';
import useClickOutside from 'hooks/mouse/use-click-outside';

type ModalProps = {
    closeOnClickOutside?: boolean;
    headerLeft?: JSX.Element;
    headerRight?: JSX.Element;
    contentClassnames?: string;
    containerClassnames?: string;
    onClose: () => void;
    hideCloseButton?: boolean;
    headerClassnames?: string;
};

const Modal: React.FC<ModalProps> = ({ children, closeOnClickOutside = true, contentClassnames, headerLeft, headerRight, onClose, hideCloseButton = false, containerClassnames, headerClassnames }) => {
    const ref = useRef<HTMLDivElement>(null);

    const headerClasses = classNames(styles.header, Boolean(headerLeft) ? 'py-4 px-3 sm:px-6' : 'p-0', headerClassnames);
    const contentClasses = classNames(styles.content, contentClassnames);
    const containerClasses = classNames(styles.container, containerClassnames);

    const closeButton = classNames(!Boolean(headerLeft) && Boolean(onClose) ? '!absolute right-4 top-4 z-50' : '', 'bg-base-200');

    useClickOutside(ref, () => {
        if (!closeOnClickOutside) {
            return;
        }

        onClose();
    });

    return ReactDOM.createPortal(
        <div className={containerClasses}>
            <div ref={ref} className={contentClasses}>
                {(Boolean(onClose) || Boolean(headerLeft)) && (
                    <header className={headerClasses}>
                        <div className="flex-1">{headerLeft}</div>
                        <div className="flex items-center">
                            {headerRight && <div className="mr-4">{headerRight}</div>}
                            {!hideCloseButton && (
                                <IconButton className={closeButton} size="large" color="default" onClick={onClose}>
                                    <Icon name="ico-close" color={theme.extend.colors.heading} width={10} height={10} />
                                </IconButton>
                            )}
                        </div>
                    </header>
                )}
                {children}
            </div>
        </div>,
        document.body
    );
};

const styles = {
    container: 'fixed left-0 top-0 w-full h-full bg-base-700/30 flex items-center justify-center z-[9998]',
    content: 'bg-base-100 rounded-2xl max-w-[90%] max-h-[90%] overflow-y-auto relative shadow-md',
    header: 'flex items-center'
};

export default memo<React.FC<ModalProps>>(Modal);
