import { useMutation } from 'react-query';

import queries, { createAssetKey } from './graphql';
import apiGraphQl from 'services/graphql';
import { CreateAssetLeasingPayload } from 'types/models/assets';

const useCreateAssetLeasing = () => {
    const request = async (payload: CreateAssetLeasingPayload) => {
        const data = {
            payload
        };
        return await apiGraphQl(queries.createAssetLeasing, data);
    };

    return useMutation(createAssetKey, request);
};

export default useCreateAssetLeasing;
