import { Status } from 'types/general';

export const getAllKey = () => ['pages'];
export const getPageRulesKey = (id: number) => ['pages', 'rules', id];

export const getPageKey = (pageId?: string) => ['pages', pageId];

const queries = {
    getAll: `query GetAllPages {
        items: page(where: {status: {_eq: ${Status.Active}}}) {
            id
            name
            rules: page_rules {
                rule { id name }
            }
        }
    }`,
    getPageRules: `query GetPageRules ($page_id: Int!) {
        items: page_rule(where: { page_id: { _eq: $page_id } }) {
            page_id
            rule { id name status }
        }
    }`
};

export default queries;
