import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';

import theme from 'settings/theme';

import { useShowApiErrors } from 'hooks/error/show-api-errors';

import { CreateJobPositionPayload } from 'types/models/configurations-classifications';
import schema from '../../utils/schema';
import { Switch } from '@mui/material';
import Select from 'components/core/form/select';
import { ClassificationType } from 'types/models/classification';
import useGetClassificationsOptions from 'services/queries/configs-classifications/use-get-classifications-options';

type JobPositionProps = {
    title: string;
    isBuilding: boolean;
    isSubmitting: boolean;
    onSubmit: (data: CreateJobPositionPayload) => void;
    defaultValues?: CreateJobPositionPayload;
    error?: any;
};

const INITIAL_FORM_STATE = {
    title: '',
    manager: false
};

const CreateOrUpdateJobPosition = ({ isBuilding, isSubmitting, onSubmit, defaultValues, error, title }: JobPositionProps) => {
    const { data } = useGetClassificationsOptions(ClassificationType.Departament);

    const navigate = useNavigate();

    const methods = useForm<CreateJobPositionPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema.jobPosition),
        defaultValues: INITIAL_FORM_STATE
    });

    const { formState, control, handleSubmit, reset, setError } = methods;

    const { showFormErrors } = useShowApiErrors();

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleClose = () => navigate(-1);

    return (
        <Modal onClose={handleClose} contentClassnames="w-[500px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {title}
                    </Text>
                    <div className="mb-4">
                        <Controller name="title" control={control} render={({ field }) => <Input {...field} label="Cargo" error={formState.errors.title?.message} />} />
                    </div>
                    <div className="mb-4">
                        <Controller
                            name="classification_department_id"
                            control={control}
                            render={({ field }) => {
                                const value = data?.find((item) => field.value === item?.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={data}
                                        label="Departamento"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.classification_department_id?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <Text variant="body.medium.sm" as="span" className="text-primary-700">
                            Esta pessoa é gerente?
                        </Text>

                        <Controller
                            name="manager"
                            control={control}
                            render={({ field }) => (
                                <Switch value={field?.value} checked={field?.value} onChange={(value) => field.onChange(value.target.checked ? (field.value = true) : (field.value = false))} />
                            )}
                        />
                    </div>
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateJobPosition;
