import { useParams } from 'react-router-dom';
import { ProjectTypeRouteSlug } from 'services/queries/operationals/use-get-operational';

type Params = { projectTypeSlug: ProjectTypeRouteSlug };

const useProjectTypeSlug = () => {
    const { projectTypeSlug } = useParams<Params>();

    const isManagement = projectTypeSlug === 'gerenciamento';
    const isProject = projectTypeSlug === 'projetos-de-arquitetura';
    const isBid = projectTypeSlug === 'bid';
    const isComplementaryProject = projectTypeSlug === 'projetos-complementares';

    return {
        isManagement,
        isProject,
        isBid,
        isComplementaryProject
    };
};

export default useProjectTypeSlug;
