import api from 'services/api';
import { useMutation, useQueryClient } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import { getProjectChartsKey, getProjectKey, getProjectProposalTypeKey, getTimelineKey } from '../projects/graphql';
import { ProjectTypeStatus } from 'types/models/project';
import { getScopeKey } from './graphql';

export type UpdateProjectTypeStatusPayload = {
    reason: string;
    status: ProjectTypeStatus;
    informedDate: string;
};

const useUpdateProjectTypeStatus = (projectProposalId?: string, projectId?: string, taskPlanningId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: UpdateProjectTypeStatusPayload) => api.patch(`project-types/${projectProposalId}/status`, payload);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectProposalTypeKey(+projectProposalId!));
            queryClient.invalidateQueries(getProjectKey(+projectId!));
            queryClient.invalidateQueries(getProjectChartsKey(projectProposalId!));
            queryClient.invalidateQueries(getScopeKey(+projectProposalId!));
            queryClient.invalidateQueries(getTimelineKey(taskPlanningId));

            showToast('Status do projeto atualizado com sucesso!', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao atualizar o status do projeto.', 'danger');
        }
    });
};

export default useUpdateProjectTypeStatus;
