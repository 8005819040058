import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import useToast from 'hooks/toast/use-toast';

import apiGraphQl from 'services/graphql';
import { createOrUpdateServiceTypeClassificationKey } from './utils/graphql';
import { getAllKey } from '../crud/graphql';
import { GraphqlPaginationVariables } from 'types/graphql';

const useProviderServicesTypesMutation = (providerId: string, serviceTypeId?: string, params?: GraphqlPaginationVariables<any>, query?: any) => {
    const navigate = useNavigate();

    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const isEdit = !!providerId && !!serviceTypeId;

    const request = async (payload: any) => {
        const data = {
            payload,
            classification_id: serviceTypeId,
            ...(isEdit && { provider_id: providerId })
        };

        return await apiGraphQl(query, data);
    };

    return useMutation(createOrUpdateServiceTypeClassificationKey(providerId, serviceTypeId), request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('provider_has_classification', params));

            navigate(-1);

            showToast(`Tipo de serviço ${isEdit ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isEdit ? 'atualizar' : 'criar'} o tipo de serviço`, 'danger');
        }
    });
};

export default useProviderServicesTypesMutation;
