// Dependencies
import { useOutletContext } from 'react-router-dom';

// Components
import CreateOrUpdateUser from './create-or-update';

// Helpers
import { User } from 'types/models/user';
import { Profile } from 'types/models/profile';
import useGetAll from 'services/queries/crud/use-get-all';
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import useCreateUser, { UserPayload } from 'services/queries/users/use-create-user';

const profileQueryConfig = {
    config: {
        table: 'profile',
        searchableField: 'name',
        columns: [
            {
                name: 'id'
            },
            {
                name: 'name'
            }
        ]
    },
    variables: {
        limit: 999 as any,
        offset: 0,
        page: 1
    }
};

const CreateOrUpdateUserPage = ({ title }: CrudPageProps) => {
    const params = useOutletContext<GraphqlPaginationVariables<User>>();

    const { mutateAsync, isLoading: isSubmitting } = useCreateUser(params);

    const { data: profiles, isLoading } = useGetAll<Pick<Profile, 'id' | 'name'>>(profileQueryConfig.config, profileQueryConfig.variables);

    const handleSubmit = async (data: UserPayload) => {
        try {
            await mutateAsync(data);
        } catch (error) {
            console.log('error', error);
        }
    };

    return <CreateOrUpdateUser isLoading={isLoading} profiles={profiles} isSubmitting={isSubmitting} title={title || ''} onSubmit={handleSubmit} />;
};

export default CreateOrUpdateUserPage;
