import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import classNames from 'classnames';
import { formatMoney } from 'utils/money';
import { spaceBorderTableClasses } from 'pages/private/operationals/dre';
import { classesTableRowHover, getManagementTeamTotal, calculateValueByPercentage, getDirectCosts, convertToPercentage, generatePercentage, getCommercialBudgetDataByProjectType } from '../utils';
import { generateLabelPercentageWithBadge } from 'pages/private/operationals/dre/utils';
import useGetProjectServicesOptions from 'services/queries/configs-classifications/use-get-project-services-options';
import ThirdParty from './third-party';
import TeamProduction from './team-production';
import { generateTableClasses } from './update';
import OtherExpenses from './others-expenses';
import Employee from './employee';
import { Controller, useFormContext } from 'react-hook-form';
import CurrencyInput from 'components/core/form/currency';
import { NumberFormatValues } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { UpdateBudgetPayload } from 'types/models/commercial';
import { getNumberValueOrZero } from 'utils/number';

export type UpdateBudgetTableProps = {
    contractValue: number;
    indirectCostsValue: number;
    directCostsValue: number;
    margin: {
        value: number;
        percentage: number;
    };
    isDisabled: boolean;
};

const UpdateBudgetTable = ({ contractValue = 0, indirectCostsValue = 0, directCostsValue = 0, margin, isDisabled = false }: UpdateBudgetTableProps) => {
    const { projectTypeId } = useParams();
    const { geop, projectServiceId } = getCommercialBudgetDataByProjectType(projectTypeId);

    const GEOPName = geop.name;

    const { data: projectServices = [], isLoading: isLoadingProjectServices } = useGetProjectServicesOptions(projectServiceId);
    const { watch, control, formState } = useFormContext<UpdateBudgetPayload>();

    const values = watch();

    const getValueByPercentage = calculateValueByPercentage(contractValue);

    const managementTeamCostTotalValue = getManagementTeamTotal(values, projectTypeId);
    const postDeliveryCostValue = getDirectCosts(values, contractValue, false) * convertToPercentage(values.postDeliveryCost);
    const GEOPValue = getDirectCosts(values, contractValue, false, true) * convertToPercentage(values[GEOPName]);

    const directCostsPercentage = generatePercentage(directCostsValue / contractValue, false) as number;
    const indirectCostsPercentage = generatePercentage(indirectCostsValue / contractValue, false) as number;
    const contingencyPercentage = generatePercentage(getValueByPercentage(values.contingency) / contractValue, false) as number;
    const managementTeamCostPercentage = generatePercentage(managementTeamCostTotalValue / contractValue, false) as number;
    const postDeliveryCostPercentage = generatePercentage(postDeliveryCostValue / contractValue, false) as number;

    const handleValueChange = (onChange: (...event: any[]) => void) => (values: NumberFormatValues) => onChange(values.floatValue);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classNames('text-secondary-500 w-[530px]', spaceBorderTableClasses)}></TableCell>
                        <TableCell className="text-secondary-500 w-[200px]">Contrato</TableCell>
                        <TableCell className="text-secondary-500 w-[180px]">HH/mês</TableCell>
                        <TableCell className="text-secondary-500 w-[180px]">Valor (HH)</TableCell>
                        <TableCell className="text-secondary-500 w-[100px]">Licença</TableCell>
                        <TableCell className="text-secondary-500 w-[110px]">%</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ '& td:first-of-type': { paddingLeft: '24px' } }}>
                    <TableRow classes={classesTableRowHover}>
                        <TableCell className={generateTableClasses(true)}>Custos Diretos</TableCell>
                        <TableCell colSpan={4} className="!text-xs !text-base-500">
                            {formatMoney(directCostsValue)}
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500">{generateLabelPercentageWithBadge(directCostsPercentage)}</TableCell>
                    </TableRow>

                    <ThirdParty isLoadingProjectServices={isLoadingProjectServices} projectServices={projectServices} contractValue={contractValue} isDisabled={isDisabled} />
                    <OtherExpenses contractValue={contractValue} isLoadingProjectServices={isLoadingProjectServices} projectServices={projectServices} isDisabled={isDisabled} />
                    <TeamProduction contractValue={contractValue} isLoadingProjectServices={isLoadingProjectServices} projectServices={projectServices} isDisabled={isDisabled} />

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(true)}>Custo Equipe Gestão</TableCell>
                        <TableCell colSpan={4} className="!text-xs !text-base-500">
                            {formatMoney(managementTeamCostTotalValue)}
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500">{generateLabelPercentageWithBadge(managementTeamCostPercentage)}</TableCell>
                    </TableRow>

                    <TableRow classes={classesTableRowHover}>
                        <TableCell className={generateTableClasses(false)}>{geop.label}</TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(GEOPValue)}</TableCell>
                        <TableCell colSpan={4}>
                            <Controller
                                control={control}
                                name={GEOPName}
                                render={({ field }) => (
                                    <CurrencyInput
                                        name={field.name}
                                        ref={field.ref}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: R$ 00,00"
                                        label="Porcentagem"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        labelClassName="!text-xs"
                                        onValueChange={handleValueChange(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors?.[GEOPName]?.message}
                                    />
                                )}
                            />
                        </TableCell>
                    </TableRow>

                    <Employee title="Gerente" parentFieldName="manager" isDisabled={isDisabled} />
                    <Employee title="Coordenador" parentFieldName="coordinator" isDisabled={isDisabled} />

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(true)}>Custo Pós Entrega</TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(postDeliveryCostValue)}</TableCell>
                        <TableCell colSpan={3} className="!text-xs !text-base-500">
                            <Controller
                                control={control}
                                name="postDeliveryCost"
                                render={({ field }) => (
                                    <CurrencyInput
                                        name={field.name}
                                        ref={field.ref}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        label="Porcentagem"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        labelClassName="!text-xs"
                                        onValueChange={handleValueChange(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors.postDeliveryCost?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500">{generateLabelPercentageWithBadge(postDeliveryCostPercentage)}</TableCell>
                    </TableRow>

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(true)}>CONTINGÊNCIA</TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(getValueByPercentage(values.contingency))}</TableCell>
                        <TableCell colSpan={3} className="!text-xs !text-base-500">
                            <Controller
                                control={control}
                                name="contingency"
                                render={({ field }) => (
                                    <div>
                                        <CurrencyInput
                                            name={field.name}
                                            ref={field.ref}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={field.value}
                                            placeholder="EX: 00,00"
                                            label="Porcentagem"
                                            parentClassName="w-[130px]"
                                            className="w-full !text-xs"
                                            labelClassName="!text-xs"
                                            onValueChange={handleValueChange(field.onChange)}
                                            disabled={isDisabled}
                                            error={formState.errors.contingency?.message}
                                        />
                                    </div>
                                )}
                            />
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500">{generateLabelPercentageWithBadge(contingencyPercentage)}</TableCell>
                    </TableRow>

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(true)}>Custos Indiretos (BDI)</TableCell>
                        <TableCell colSpan={4} className="!text-xs text-base-500 font-semibold">
                            {formatMoney(indirectCostsValue)}
                        </TableCell>
                        <TableCell>{generateLabelPercentageWithBadge(indirectCostsPercentage || 0)}</TableCell>
                    </TableRow>

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(false)}>BACKOFFICE (DI FIXO)</TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(getValueByPercentage(values.backoffice))}</TableCell>
                        <TableCell colSpan={4} className="!text-xs !text-base-500">
                            <Controller
                                control={control}
                                name="backoffice"
                                render={({ field }) => (
                                    <div>
                                        <CurrencyInput
                                            name={field.name}
                                            ref={field.ref}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={field.value}
                                            placeholder="EX: 00,00"
                                            label="Porcentagem"
                                            parentClassName="w-[130px]"
                                            className="w-full !text-xs"
                                            labelClassName="!text-xs"
                                            onValueChange={handleValueChange(field.onChange)}
                                            disabled={isDisabled}
                                            error={formState.errors.backoffice?.message}
                                        />
                                    </div>
                                )}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(false)}>IMPOSTO</TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(getValueByPercentage(values.tax))}</TableCell>
                        <TableCell colSpan={4} className="!text-xs !text-base-500">
                            <Controller
                                control={control}
                                name="tax"
                                render={({ field }) => (
                                    <div>
                                        <CurrencyInput
                                            name={field.name}
                                            ref={field.ref}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={field.value}
                                            placeholder="EX: 00,00"
                                            label="Porcentagem"
                                            parentClassName="w-[130px]"
                                            className="w-full !text-xs"
                                            labelClassName="!text-xs"
                                            onValueChange={handleValueChange(field.onChange)}
                                            disabled={isDisabled}
                                            error={formState.errors.tax?.message}
                                        />
                                    </div>
                                )}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(false)}>DESPESAS COMERCIAIS</TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(getValueByPercentage(values.commercialExpense))}</TableCell>
                        <TableCell colSpan={4} className="!text-xs !text-base-500">
                            <Controller
                                control={control}
                                name="commercialExpense"
                                render={({ field }) => (
                                    <div>
                                        <CurrencyInput
                                            name={field.name}
                                            ref={field.ref}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={field.value}
                                            placeholder="EX: 00,00"
                                            label="Porcentagem"
                                            parentClassName="w-[130px]"
                                            className="w-full !text-xs"
                                            labelClassName="!text-xs"
                                            onValueChange={handleValueChange(field.onChange)}
                                            disabled={isDisabled}
                                            error={formState.errors.commercialExpense?.message}
                                        />
                                    </div>
                                )}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(false)}>DESPESAS FINANCEIRAS</TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(getValueByPercentage(values.financialExpense))}</TableCell>
                        <TableCell colSpan={4} className="!text-xs !text-base-500">
                            <Controller
                                control={control}
                                name="financialExpense"
                                render={({ field }) => (
                                    <div>
                                        <CurrencyInput
                                            name={field.name}
                                            ref={field.ref}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={field.value}
                                            placeholder="EX: 00,00"
                                            label="Porcentagem"
                                            parentClassName="w-[130px]"
                                            className="w-full !text-xs"
                                            labelClassName="!text-xs"
                                            onValueChange={handleValueChange(field.onChange)}
                                            disabled={isDisabled}
                                            error={formState.errors.financialExpense?.message}
                                        />
                                    </div>
                                )}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow classes={classesTableRowHover} className="border-t border-b">
                        <TableCell className={generateTableClasses(true)}>MARGEM (%)</TableCell>
                        <TableCell colSpan={4} className="!text-xs !text-base-500 font-semibold">
                            {formatMoney(margin.value)}
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500">{generateLabelPercentageWithBadge(getNumberValueOrZero(margin.percentage.toFixed(2)))}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UpdateBudgetTable;
