import Badge from 'components/core/badge';
import { DatatableColumn } from 'types/graphql';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';

export const financialExpensesColumns: DatatableColumn[] = [
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'project.title',
        label: 'Projeto',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'projectProposalType.type.title',
        label: 'Programa',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'provider.title',
        label: 'Fornecedor',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'classification.name',
        label: 'Classificação',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: {
            customBodyRender: (value) => (!!value ? formatDate(value) : '-')
        }
    },
    {
        name: 'payday',
        label: 'Pagamento',
        options: {
            customBodyRender: (value) => (!!value ? formatDate(value) : '-')
        }
    },
    {
        name: 'value',
        label: 'Valor',
        options: {
            customBodyRender: (value) => formatMoney(value)
        }
    },
    {
        name: 'payday',
        label: 'Status',
        options: {
            customBodyRender: (value) => {
                return <Badge variant={!!value ? 'success' : 'error'}>{!!value ? 'Pago' : 'Não pago'}</Badge>;
            }
        }
    }
];

export const financialReceiptsColumns: DatatableColumn[] = [
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'origin.project.title',
        label: 'Projeto',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'origin.projectProposalType.type.title',
        label: 'Programa',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'origin.customer.title',
        label: 'Cliente',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'classification.name',
        label: 'Classificação',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'dueDate',
        label: 'Vencimento Original',
        options: {
            customBodyRender: (value) => (!!value ? formatDate(value) : '-')
        }
    },
    {
        name: 'dueDateOriginal',
        label: 'Vencimento',
        options: {
            customBodyRender: (value) => (!!value ? formatDate(value) : '-')
        }
    },
    {
        name: 'payday',
        label: 'Pagamento',
        options: {
            customBodyRender: (value) => (!!value ? formatDate(value) : '-')
        }
    },
    {
        name: 'netValue',
        label: 'Valor',
        options: {
            customBodyRender: (value) => formatMoney(value)
        }
    },
    {
        name: 'payday',
        label: 'Status',
        options: {
            customBodyRender: (value) => {
                return <Badge variant={!!value ? 'success' : 'error'}>{!!value ? 'Pago' : 'Não pago'}</Badge>;
            }
        }
    }
];
