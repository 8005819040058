import Card from 'components/core/card';
import Content from './content';
import Team from './team';
import PageHeader from 'components/page/header';
import Days from './days';
import { useMemo, useState } from 'react';
import { generateArray } from '../utils';
import Filters from './filters';
import { People } from '../types';
import { PeopleMapFilter } from 'services/queries/people-map/types';
import Spinner from 'components/core/spinner';
import useGetMapOfHoursPerPersonAndMonth from 'services/queries/people-map/use-get-map-of-hours-per-person-and-month';
import Empty from 'components/empty';
import { WeekDaysEnum } from 'types/date';

export type Response = {
    year: number;
    month: number;
    people: People[];
};

const forbiddenWeekDays = [WeekDaysEnum.Sunday, WeekDaysEnum.Saturday];

const PeopleMapHHMonth = () => {
    const title = `Mapa de HH por Mês`;

    const branchState = useState<number>();
    const departmentState = useState<number>();
    const monthState = useState<number | undefined>(new Date().getMonth() + 1);
    const yearState = useState<number | undefined>(new Date().getFullYear());
    const filtersState = useState<PeopleMapFilter[]>([]);

    const [filters, setFilters] = filtersState;

    const [year] = yearState;
    const [month] = monthState;

    const { data, isLoading } = useGetMapOfHoursPerPersonAndMonth<Response>(filters);

    const { people = [] } = data || {};

    const days = useMemo(() => {
        if (!!year && !!month?.toString()) {
            const totalMonthDays = new Date(year, month, 0).getDate();

            return generateArray(totalMonthDays)
                .map((day) => new Date(year, month - 1, day + 1))
                .filter((date) => !forbiddenWeekDays.includes(date.getDay()));
        }
        return [];
    }, [year, month]);

    return (
        <>
            {isLoading && <Spinner parentClasses="bg-base-100/20 !z-50" />}

            <PageHeader
                title={title}
                className="mb-7"
                right={<Filters branchState={branchState} yearState={yearState} monthState={monthState} departmentState={departmentState} onSetFilters={setFilters} />}
            />

            {!isLoading && !!data?.people?.length ? (
                <Card>
                    <div className="flex max-h-[calc(100vh-220px)] overflow-y-auto">
                        <div className="sticky h-max left-0 bg-white z-[998]">
                            <Team items={people} />
                        </div>

                        <div className="flex-1 pb-2">
                            <Days days={days} />
                            <Content days={days} people={people} />
                        </div>
                    </div>
                </Card>
            ) : (
                <Empty title="Não foi executada consulta ou nenhum dado foi encontrado." />
            )}
        </>
    );
};

export default PeopleMapHHMonth;
