import classNames from 'classnames';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Empty from 'components/empty';
import Text from 'components/core/text';
import { ProjectMeetingRecordSubject } from 'types/models/project';
import { formatDate } from 'utils/date';
import ExternalButton from 'components/buttons/external';

type SubjectsProps = {
    subjects?: Partial<ProjectMeetingRecordSubject>[];
    className?: string;
    withTitle?: boolean;
};

const Subjects = ({ className, subjects, withTitle }: SubjectsProps) => {
    const containerClasses = classNames('border border-base-300 p-4 rounded-2xl mb-2', className);

    return (
        <div className={containerClasses}>
            {withTitle && (
                <Text as="h6" variant="h6" className="text-heading mb-2">
                    Tópicos
                </Text>
            )}
            <div>
                {Boolean(subjects?.length) ? (
                    <>
                        {subjects!.map((item, index) => {
                            const isLastChild = subjects!.length - 1 === index;

                            return (
                                <div key={item.id} className={classNames('border border-b-base-300 rounded-[14px] p-4', isLastChild ? '' : 'mb-4')}>
                                    <Text as="p" variant="body.medium.2xs" className="text-heading mb-4">
                                        {item.title}
                                    </Text>
                                    {Boolean(item.description) && (
                                        <div
                                            className="rich-content break-all mb-4"
                                            dangerouslySetInnerHTML={{
                                                __html: item.description!
                                            }}
                                        />
                                    )}
                                    <TableContainer>
                                        <Table className="rounded-[14px] border border-b-base-300">
                                            <TableHead>
                                                <TableRow className=" rounded-[14px]">
                                                    <TableCell className="bg-base-200" colSpan={5}>
                                                        <Text className="text-secondary-500">Tarefas</Text>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Text variant="body.medium.sm" as="span" className="text-base-700">
                                                            Código
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.medium.sm" as="span" className="text-base-700">
                                                            Título
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.medium.sm" as="span" className="text-base-700">
                                                            Prazo
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.medium.sm" as="span" className="text-base-700">
                                                            Atribuida à
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text variant="body.medium.sm" as="span" className="text-base-700">
                                                            Descrição
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Boolean(item.tasks?.length) ? (
                                                    <>
                                                        {item.tasks?.map((item) => {
                                                            return (
                                                                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell>
                                                                        <Text as="span" variant="body.regular.sm" className="text-base-500 whitespace-nowrap">
                                                                            {item.code}
                                                                        </Text>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Text as="span" variant="body.regular.sm" className="text-base-500 whitespace-nowrap">
                                                                            {item.title}
                                                                        </Text>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Text as="span" variant="body.regular.sm" className="text-base-500 whitespace-nowrap">
                                                                            {formatDate(item.deadline, 'DD/MM/YYYY')}
                                                                        </Text>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <ExternalButton onClick={() => null}>{item.person?.name}</ExternalButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div
                                                                            className="rich-content w-max max-w-[300px] break-all"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.description!
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={5}>
                                                            <Empty title="Nenhuma tarefa encontrada" />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <Empty title="Nenhum tópico encontrado." />
                )}
            </div>
        </div>
    );
};

export default Subjects;
