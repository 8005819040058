import React, { useState } from 'react';
import Icon from 'components/core/icon';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';
import { ProjectStatusEnum } from 'types/models/project';

type ContractDetailsActions = {
    contractId?: number;
    hasBudgetId: boolean;
    projectStatus?: ProjectStatusEnum;
    onSetUpdateProjectStatus?: (value: ProjectStatusEnum) => void;
};

const ContractDetailsActions = ({ contractId, hasBudgetId, projectStatus, onSetUpdateProjectStatus }: ContractDetailsActions) => {
    const { palette } = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const navigate = useNavigate();

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const getContentStatusActions = () => {
        const contentStatusActions: any[] = [];

        if ([ProjectStatusEnum.Tap, ProjectStatusEnum.Partial].includes(projectStatus!)) {
            contentStatusActions.push(
                {
                    label: 'Paralisar projeto',
                    icon: {
                        color: palette.warning[500],
                        name: 'ico-lock'
                    },
                    onClick: () => {
                        setAnchorEl(null);
                        onSetUpdateProjectStatus?.(ProjectStatusEnum.Stopped);
                    }
                },
                {
                    label: 'Finalizar projeto',
                    icon: {
                        color: palette.success[500],
                        name: 'ico-check'
                    },
                    onClick: () => {
                        onSetUpdateProjectStatus?.(ProjectStatusEnum.Done);
                        setAnchorEl(null);
                    }
                },
                {
                    label: 'Cancelar projeto',
                    icon: {
                        color: palette.error[500],
                        name: 'ico-close-circle'
                    },
                    onClick: () => {
                        onSetUpdateProjectStatus?.(ProjectStatusEnum.Canceled);
                        setAnchorEl(null);
                    }
                }
            );
        }

        if (projectStatus === ProjectStatusEnum.Stopped) {
            contentStatusActions.push(
                {
                    label: 'Reiniciar projeto',
                    icon: {
                        color: palette.success[500],
                        name: 'ico-refresh'
                    },
                    onClick: () => {
                        setAnchorEl(null);
                        onSetUpdateProjectStatus?.(ProjectStatusEnum.Partial);
                    }
                },
                {
                    label: 'Cancelar projeto',
                    icon: {
                        color: palette.error[500],
                        name: 'ico-close-circle'
                    },
                    onClick: () => {
                        onSetUpdateProjectStatus?.(ProjectStatusEnum.Canceled);
                        setAnchorEl(null);
                    }
                }
            );
        }

        return contentStatusActions;
    };

    const content: MenuItem[] = [
        {
            label: 'Editar contrato',

            icon: {
                color: palette.grey[500],
                name: 'ico-edit'
            },
            onClick: () => {
                navigate(`/app/contratos/${contractId}/editar`);
                handleClose();
            }
        },
        ...(hasBudgetId
            ? [
                  {
                      label: 'Adicionar aditivo de FP',
                      icon: {
                          color: palette.success[500],
                          name: 'ico-plus'
                      },
                      onClick: () => {
                          navigate(`/app/contratos/${contractId}/fp/novo-aditivo`);
                          handleClose();
                      }
                  }
              ]
            : [])
    ];

    return (
        <>
            <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<Icon name="ico-chevron-down" width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={content} footer={getContentStatusActions()} />
        </>
    );
};

export default ContractDetailsActions;
