import { getMonthWidth } from '../utils';
import BodyMonth from './month';
import { useCalendarContext } from '../context';

const CalendarBody = () => {
    const { headerTexts } = useCalendarContext();

    return (
        <div className="flex flex-1 float-right">
            {headerTexts.map((item, index) => {
                const { year, title: month, monthNumber: monthIndex } = item;

                const width = getMonthWidth({ year, month: monthIndex });

                return <BodyMonth width={width} headerText={item} key={[month, year, index, 'bar'].join('-')}></BodyMonth>;
            })}
        </div>
    );
};

export default CalendarBody;
