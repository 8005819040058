import React from 'react';

// Dependecies
import TableCell from '@mui/material/TableCell/TableCell';

// Components
import ListPage from 'pages/private/crud/list';
import CreateOrUpdateUserPage from 'pages/private/users/create-or-update';

// Helpers
import dictionary from 'utils/dictionary';
import { CrudPageProps } from 'types/graphql';
import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';

const [list, create]: CrudPageProps[] = [
    {
        graphql: {
            table: 'users',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'person.email',
                    label: dictionary.content.graphql['email']
                },
                {
                    name: 'username',
                    label: dictionary.content.graphql['username']
                },
                {
                    name: 'profile.name',
                    label: dictionary.content.graphql['profile.name']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ]
        },
        title: 'Usuários'
    },
    {
        title: 'Novo usuário'
    }
];

const users: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Usuários',
        path: 'usuarios',
        element: <Page Component={ListPage} code="usuarios" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateUserPage} code="usuarios" permissions={[Rule.Create]} withModal={true} {...create} />
            },
            {
                path: 'editar/:userId',
                element: <Page Component={CreateOrUpdateUserPage} code="usuarios" permissions={[Rule.Update]} withModal={true} {...create} />
            }
        ]
    }
];

export default users;
