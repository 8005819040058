import React, { useMemo } from 'react';
import Card from 'components/core/card';
import { Option } from 'types/general';

import { Chart as ChartJS, ArcElement, ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import classNames from 'classnames';
import ProjectChartSkeleton from './skeleton';
import useTheme from '@mui/material/styles/useTheme';

ChartJS.register(ArcElement);

export type ProjectChartItem = { value: number; color: string };
export type ProjectChartLegend = { color: string; isPercentage?: boolean; label: string; value: number };

type ProjectChartProps = {
    items?: Array<ProjectChartItem[]>;
    className?: string;
    legendsClassName?: string;
    title: string;
    isLoading?: boolean;
    total?: Option<string | number>;
    legends?: ProjectChartLegend[];
    horizontal?: boolean;
    filter?: React.ReactNode;
    withCard?: boolean;
    withPlaceholderBackground?: boolean;
    hideTooltip?: boolean;
};

const ProjectChart = ({
    items = [],
    className,
    title,
    isLoading,
    total,
    legends,
    horizontal = false,
    legendsClassName,
    filter,
    withCard = true,
    withPlaceholderBackground,
    hideTooltip
}: ProjectChartProps) => {
    const { palette } = useTheme();

    const [mainItems, ...restItems] = items || [];

    if (isLoading || !mainItems) {
        return <ProjectChartSkeleton title={title} className={className} />;
    }

    const data: ChartData<'doughnut', number[], string> = useMemo(
        () => ({
            labels: [],
            datasets: [
                {
                    data: mainItems.map(({ value }) => value),
                    backgroundColor: mainItems.map(({ color }) => color),
                    borderWidth: 0,
                    borderRadius: 30,
                    spacing: 4,
                    rotation: 234,
                    ...(!horizontal && { circumference: 252 })
                }
            ]
        }),
        [horizontal, mainItems]
    );

    const cardClasses = classNames('md:flex-1 md:w-full flex flex-col py-6 px-4 pt-6', className);
    const totalContainerClasses = classNames('absolute flex flex-col items-center', !horizontal && 'mt-8');
    const containerClasses = classNames('flex h-full', horizontal ? 'items-center justify-center flex-col md:flex-row' : 'flex-col');
    const legendsContainerClasses = classNames(legendsClassName, horizontal ? 'flex flex-col gap-4' : `divide-x justify-center grid grid-cols-${legends?.length}`);
    const wrapperClasses = classNames('relative flex items-center justify-center md:flex-1', horizontal ? 'max-w-[300px] mr-0 md:mr-10' : 'max-w-[241px] w-full m-auto');

    const content = useMemo(() => {
        return (
            <>
                <div className="flex items-center">
                    <p className="text-heading text-xl font-medium mb-0 flex-1">{title}</p>
                    {filter}
                </div>
                <div className={containerClasses}>
                    <div className={wrapperClasses}>
                        <Doughnut
                            data={data}
                            options={{
                                cutout: '75%',
                                plugins: {
                                    tooltip: {
                                        enabled: !hideTooltip
                                    }
                                }
                            }}
                            className="z-10 max-w-[250px] md:max-w-[300px]"
                        />
                        {restItems?.map((items, i) => {
                            return (
                                <Doughnut
                                    key={`${i}_chart`}
                                    className={`absolute z-[${i + 1}]`}
                                    data={{
                                        labels: [],
                                        datasets: [
                                            {
                                                data: items.map(({ value }) => value),
                                                backgroundColor: items.map(({ color }) => color),
                                                borderWidth: 0,
                                                borderRadius: 30,
                                                spacing: 4,
                                                rotation: 234,
                                                ...(!horizontal && { circumference: 252 })
                                            }
                                        ]
                                    }}
                                    options={{
                                        cutout: '75%',
                                        plugins: {
                                            tooltip: {
                                                enabled: !hideTooltip
                                            }
                                        }
                                    }}
                                />
                            );
                        })}

                        {withPlaceholderBackground && (
                            <Doughnut
                                className={`absolute `}
                                data={{
                                    labels: [],
                                    datasets: [
                                        {
                                            data: [100],
                                            backgroundColor: [palette.grey[300]],
                                            borderWidth: 0,
                                            borderRadius: 30,
                                            spacing: 4,
                                            rotation: 234,
                                            ...(!horizontal && { circumference: 252 })
                                        }
                                    ]
                                }}
                                options={{
                                    cutout: '75%',
                                    plugins: {
                                        tooltip: {
                                            enabled: false
                                        }
                                    }
                                }}
                            />
                        )}
                        <div className={totalContainerClasses}>
                            <p className="text-heading text-4xl font-medium">{total?.value}</p>
                            <p className="text-base-500 text-sm">{total?.label}</p>
                        </div>
                    </div>

                    <div className={legendsContainerClasses}>
                        {legends?.map(({ label, color, value, isPercentage }, i) =>
                            horizontal ? (
                                <div key={`legend_${i}`} className="flex flex-row md:flew-col gap-2 mt-2 md:mt-0">
                                    <div className="flex items-center">
                                        <span className="w-[8px] h-[8px] mr-2 rounded-full" style={{ backgroundColor: color }}></span>
                                        <p className="text-base-500 text-sm break-all text-center">{label}</p>
                                    </div>
                                    <p className="text-heading font-medium text-2xl">
                                        {value}
                                        {isPercentage && '%'}
                                    </p>
                                </div>
                            ) : (
                                <div key={`legend_${i}`} className="flex flex-col items-center px-2">
                                    <span className="w-[6px] h-[6px] rounded-[2px] mb-1" style={{ backgroundColor: color }}></span>
                                    <p className="text-heading text-xl 2xl:text-2xl font-medium">
                                        {value}
                                        {isPercentage && '%'}
                                    </p>
                                    <p className="text-base-500 text-xs 2xl:text-sm break-all text-center">{label}</p>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </>
        );
    }, [
        data,
        filter,
        legends,
        horizontal,
        legendsContainerClasses,
        restItems,
        title,
        total?.label,
        total?.value,
        containerClasses,
        totalContainerClasses,
        wrapperClasses,
        hideTooltip,
        palette.grey,
        withPlaceholderBackground
    ]);

    if (withCard) {
        return <Card className={cardClasses}>{content}</Card>;
    }

    return <>{content}</>;
};

export default ProjectChart;
