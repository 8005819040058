import React, { createContext, useReducer } from 'react';
import findIndex from 'lodash/findIndex';
import * as Types from './types';

const INITIAL_STATE: Types.RequestRecordState = {
    completeds: [],
    content: {
        description: '',
        subject: '',
        observations: '',
        classifications: [],
        files: [],
        attentionPoints: []
    },
    currentStep: {
        slug: Types.RequestSteps.Details,
        label: 'Detalhes'
    },
    isLoading: false,
    steps: [
        {
            slug: Types.RequestSteps.Details,
            label: 'Detalhes'
        },
        {
            slug: Types.RequestSteps.Files,
            label: 'Arquivos'
        },
        {
            slug: Types.RequestSteps.AttentionPoints,
            label: 'Pontos de atenção'
        }
    ]
};

const RequestContext = createContext({} as Types.RequestRecordContextType);

const actions = new Map<Types.RequestActions, keyof Types.RequestRecordState>([
    ['SET_CONTENT', 'content'],
    ['SET_CURRENT_STEP', 'currentStep'],
    ['SET_STEP_COMPLETED', 'completeds'],
    ['TOGGLE_LOADER', 'isLoading']
]);

const RequestProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer((state: Types.RequestRecordState, stateAction: Types.ReducerAction) => {
        try {
            const action = actions.get(stateAction.type);

            if (!action) {
                throw new Error();
            }

            return { ...state, [action]: stateAction.payload };
        } catch (error) {
            console.log('error', error);
            return state;
        }
    }, INITIAL_STATE);

    const getStep = (slug: Types.RequestSteps) => state.steps.find((item) => item.slug === slug);

    const getNextStep = () => {
        const ix = findIndex(state.steps, { slug: state.currentStep.slug });

        return state.steps[ix + 1];
    };

    const completeStep = (step: Types.Step) => {
        const stepExistsOnCompletedArray = state.completeds.some((item) => item.slug === step.slug);

        const completedPayload = stepExistsOnCompletedArray ? state.completeds : [...state.completeds, step];

        dispatch({ type: 'SET_STEP_COMPLETED', payload: completedPayload });
    };

    const completeMultipleSteps = (steps: Types.Step[]) => {
        dispatch({ type: 'SET_STEP_COMPLETED', payload: steps });
    };

    const setCurrentStep = (slug: Types.RequestSteps) => {
        const step = getStep(slug);

        dispatch({ type: 'SET_CURRENT_STEP', payload: step });
    };

    const changeStep = (currentStepSlug: Types.RequestSteps, nextStepSlug?: Types.RequestSteps, previousStepSlug?: Types.RequestSteps) => {
        const step = getStep(currentStepSlug);

        if (!step) {
            throw new Error();
        }

        completeStep(step);

        if (previousStepSlug) {
            return setCurrentStep(previousStepSlug);
        }

        if (nextStepSlug) {
            setCurrentStep(nextStepSlug);
        }
    };

    const setContent = (payload: any, jumpToNextStep = true) => {
        dispatch({ type: 'SET_CONTENT', payload: { ...state.content, ...payload } });

        if (jumpToNextStep) {
            const nextStep = getNextStep();

            changeStep(state.currentStep.slug, nextStep.slug);
        }
    };

    const values = {
        state,
        setCurrentStep,
        changeStep,
        completeStep,
        getStep,
        setContent,
        completeMultipleSteps
    };

    return <RequestContext.Provider value={values}>{children}</RequestContext.Provider>;
};

export { RequestContext, RequestProvider };
