import useTheme from '@mui/material/styles/useTheme';
import AddButton from 'components/buttons/add';
import FormButtons from 'components/core/form/buttons';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import ErrorMessage from 'components/error/message';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import { ManagementResourcePayload } from 'types/models/resource';
import ManagementResource from './management-resource';
import { typeManagementInformations } from 'utils/statics';
import { ProjectTypeManagementInformation } from 'types/models/project';
import { CreateOrUpdateResourceProps } from './non-management';
import { yupResolver } from '@hookform/resolvers/yup';
import { managementResourceSchema } from './utils/schema';
import { formatDate } from 'utils/date';
import useCreateOrUpdateProjectTypeResourcesProjects from 'services/queries/projects/use-create-or-update-project-resources';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import { useEffect, useMemo } from 'react';

const defaultResource: ManagementResourcePayload['resources'][0] = { periods: [{}] };

const CreateOrUpdateManagementResource = ({ isBuilding, hasManager, resources, people, onClose }: CreateOrUpdateResourceProps) => {
    const { palette } = useTheme();
    const { resourceId, projectProposalTypeId } = useParams();
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const { mutateAsync: createOrUpdateResources, isLoading: isSubmitting } = useCreateOrUpdateProjectTypeResourcesProjects(ctx?.project?.id, Number(projectProposalTypeId), Number(resourceId));

    const isUpdate = !!resourceId;

    const methods = useForm<ManagementResourcePayload>({
        mode: 'onChange',
        resolver: yupResolver(managementResourceSchema),
        defaultValues: {
            resources: [defaultResource]
        }
    });

    const { handleSubmit, formState, control, reset } = methods;

    const { fields, append, remove } = useFieldArray({ control, name: 'resources' });

    const handleCreateResource = () => append(defaultResource);

    const submit = async (data: ManagementResourcePayload) => {
        try {
            const createPayload = {
                resources: data.resources.map((item) => ({
                    ...item,
                    periods: item.periods.map((period) => ({
                        ...period,
                        contractStartDate: formatDate(period.contractStartDate, 'YYYY-MM-DD', undefined, true),
                        contractEndDate: formatDate(period.contractEndDate, 'YYYY-MM-DD', undefined, true)
                    }))
                }))
            };

            const [resource] = data.resources;

            const updatePayload = {
                person: resource.person,
                typeManagementInformation: resource.typeManagementInformation,
                periods: resource.periods.map((period) => ({
                    ...period,
                    contractStartDate: formatDate(period.contractStartDate, 'YYYY-MM-DD', undefined, true),
                    contractEndDate: formatDate(period.contractEndDate, 'YYYY-MM-DD', undefined, true)
                }))
            };

            await createOrUpdateResources(isUpdate ? updatePayload : createPayload);
        } catch (error) {
            console.log('submit', error);
        }
    };

    const findedResource = useMemo(() => resources?.find((resource) => resource?.id === (+resourceId! as number)), [resourceId, resources]);

    const positions = useMemo(() => {
        return typeManagementInformations.filter((item) => {
            const isNotManager = hasManager ? item.value !== ProjectTypeManagementInformation.Manager : true;
            const isNotDirector = item.value !== ProjectTypeManagementInformation.Director;
            const isNotProduction = item.value !== ProjectTypeManagementInformation.Production;

            const manager = resources?.find((item) => item?.type_management_information === ProjectTypeManagementInformation.Manager);

            if (Boolean(resourceId) && manager?.person?.id === findedResource?.person?.id) {
                return isNotDirector;
            }

            return isNotDirector && isNotManager && isNotProduction;
        });
    }, [hasManager, resourceId, findedResource, resources]);

    useEffect(() => {
        if (findedResource) {
            reset({
                resources: [
                    {
                        periods: findedResource.project_type_person_resource_periods?.map((item) => ({
                            contractEndDate: item.contract_end_date,
                            contractStartDate: item.contract_start_date,
                            percentage: item.percentage
                        })),
                        person: findedResource.person?.id,
                        typeManagementInformation: findedResource.type_management_information
                    }
                ]
            });
        }
    }, [findedResource, reset]);

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {resourceId ? 'Editar' : 'Adicionar'} recurso
                </Text>
            }
            closeOnClickOutside={false}
            contentClassnames="w-[800px]"
            onClose={onClose}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="pb-5 px-6" onSubmit={handleSubmit(submit)}>
                        <div className="flex items-start">
                            <div className="group flex-1 relative">
                                {fields.map((item, index) => {
                                    return <ManagementResource key={item.id} index={index} people={people} positions={positions} onDeleteResource={remove} />;
                                })}
                                <ErrorMessage visible={Boolean((formState as any).errors?.resources?.message)} className="block mb-4">
                                    {(formState as any).errors?.resources?.message}
                                </ErrorMessage>
                                {!isUpdate && <AddButton onClick={handleCreateResource}>Novo recurso</AddButton>}
                            </div>
                        </div>
                        <FormButtons className="mt-5" isLoading={isSubmitting} onCancel={onClose} />
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdateManagementResource;
