// Dependencies
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Helpers
import api from 'services/api';
import { Auth } from './types';
import { useAppSelector } from 'store';
import { Creators as AuthActions } from 'store/auth';

const useAuth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const auth = useAppSelector(({ auth }) => auth);

    const handleLogout = (redirectPath?: string) => {
        // Clear store
        dispatch(AuthActions.logout());
        // Remove token from axios header
        delete api.defaults.headers.common.Authorization;

        const path = redirectPath ? `/?redirectPath=${redirectPath}` : '/';

        // Redirect to login page
        navigate(path);
    };

    return {
        auth,
        setAuth: (payload: Auth) => dispatch(AuthActions.setAuth(payload)),
        logout: handleLogout
    };
};

export default useAuth;
