import React from 'react';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import { ProviderFinancialPayment, ProviderFinancialPaymentType } from 'types/models/provider';
import { CamelCase } from 'types/utils';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Text from 'components/core/text';
import TableBody from '@mui/material/TableBody/TableBody';
import { formatMoney } from 'utils/money';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import { formatDate } from 'utils/date';

type NewMeasurementPaymentsProps = {
    total?: number;
    payments: CamelCase<ProviderFinancialPayment>[];
};

const NewMeasurementPayments = ({ payments, total }: NewMeasurementPaymentsProps) => {
    return (
        <TableContainer>
            <Table classes={{ root: 'border border-base-300' }}>
                <TableHead>
                    <TableRow>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                Tipo
                            </Text>
                        </TableCell>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                Fornecedor
                            </Text>
                        </TableCell>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                NF
                            </Text>
                        </TableCell>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                Vencimento
                            </Text>
                        </TableCell>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                Valor
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments.map((item) => {
                        return (
                            <TableRow key={item.id}>
                                <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                                    <Text as="span" className="text-base-500">
                                        {item.type === ProviderFinancialPaymentType.Start ? 'Entrada' : 'Faturamento Direto'}
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                                    <Text as="span" className="text-base-500">
                                        {item.providerName || '-'} <br />
                                        {item.providerDocument || ''}
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                                    <Text as="span" className="text-base-500">
                                        {item.invoiceNumber || '-'}
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'border-r border-r-base-300 w-32' }}>
                                    <Text as="span" className="text-base-500">
                                        {formatDate(item.dueDate) || '-'}
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'border-r border-r-base-300 w-32' }}>
                                    <Text as="span" className="text-base-500">
                                        {formatMoney(item.value) || '-'}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                {Boolean(total) && (
                    <TableFooter>
                        <TableRow>
                            <TableCell align="right" colSpan={5}>
                                <Text as="span" variant="body.regular.xs" className="text-base-500 mr-2">
                                    Total:
                                </Text>
                                <Text as="strong" variant="h6" className="text-base-500">
                                    {formatMoney(total)}
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
};

export default NewMeasurementPayments;
