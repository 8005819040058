import { RouteObject } from 'react-router-dom';

import CreateOrUpdateAssetPage from 'pages/private/assets/create-or-update';
import AssetDetailsPage from 'pages/private/assets/view';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import AssetPage from 'pages/private/assets';

const administrative: RouteObject[] = [
    {
        path: 'ativos/:classificationTypeId',
        element: <Page Component={AssetPage} code="ativos" permissions={[Rule.List, Rule.Show]} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateAssetPage} withModal={true} code="ativos" title="Criar" permissions={[Rule.Create]} />
            },
            {
                path: 'editar/:assetId',
                element: <Page Component={CreateOrUpdateAssetPage} withModal={true} code="ativos" title="Atualizar" permissions={[Rule.Update]} />
            },
            {
                path: ':assetId',
                element: <Page Component={AssetDetailsPage} code="ativos" withModal={true} permissions={[Rule.Show]} />
            }
        ]
    }
];

export default administrative;
