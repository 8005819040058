import React from 'react';

// Dependencies
import classNames from 'classnames';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';

// Components
import Empty from 'components/empty';
import Text from 'components/core/text';

// Helpers
import { Phone } from 'types/models/person';

type ParticipantsProps = {
    internal: Array<{
        name: string;
        validationRequired: boolean;
    }>;
    external: Array<{
        name: string;
        email: string;
        phones: Phone[];
        jobPosition?: string;
        company?: string;
        validationRequired: boolean;
    }>;
    className?: string;
    withTitle?: boolean;
};

const Participants = ({ className, external, internal, withTitle }: ParticipantsProps) => {
    const containerClasses = classNames('border border-base-300 p-4 rounded-2xl mb-2', className);

    return (
        <div className={containerClasses}>
            {withTitle && (
                <Text as="h6" variant="h6" className="text-heading mb-2">
                    Participantes
                </Text>
            )}
            <TableContainer className="mb-5 sm:mb-0">
                <Table className="border border-base-300 rounded-bl-2xl rounded-br-2xl border-separate mb-2">
                    <caption className="bg-base-200 border border-base-300 -mb-[1px] rounded-tl-2xl rounded-tr-2xl" style={{ captionSide: 'top' }}>
                        <Text variant="body.medium.sm" as="span" className="text-primary-500 sticky left-3">
                            Usuários do sistema
                        </Text>
                    </caption>
                    <TableHead>
                        <TableRow>
                            <TableCell classes={{ root: 'py-2 min-w-[150px]' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Usuário
                                </Text>
                            </TableCell>
                            <TableCell classes={{ root: 'py-2' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Necessário validação?
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(internal.length) ? (
                            internal.map((item, index) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                                    <TableCell classes={{ root: 'py-2' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.name || '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell classes={{ root: 'py-2' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.validationRequired ? 'Sim' : 'Não'}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={2}>
                                    <Empty title="Nenhum usuário encontrado" />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table className="border border-base-300 rounded-bl-2xl rounded-br-2xl border-separate">
                    <caption className="bg-base-200 border border-base-300 -mb-[1px] rounded-tl-2xl rounded-tr-2xl" style={{ captionSide: 'top' }}>
                        <Text variant="body.medium.sm" as="span" className="text-primary-500 sticky left-3">
                            Pessoas externas
                        </Text>
                    </caption>
                    <TableHead>
                        <TableRow>
                            <TableCell classes={{ root: 'py-2 min-w-[150px]' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Nome
                                </Text>
                            </TableCell>
                            <TableCell classes={{ root: 'py-2' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    E-mail
                                </Text>
                            </TableCell>
                            <TableCell classes={{ root: 'py-2' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Telefone(s)
                                </Text>
                            </TableCell>
                            <TableCell classes={{ root: 'py-2' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Cargo
                                </Text>
                            </TableCell>
                            <TableCell classes={{ root: 'py-2' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Empresa
                                </Text>
                            </TableCell>
                            <TableCell classes={{ root: 'py-2' }}>
                                <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                    Necessário validação?
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(external.length) ? (
                            external.map((item, index) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                                    <TableCell classes={{ root: 'py-2' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.name || '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell classes={{ root: 'py-2' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.email || '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell classes={{ root: 'py-2' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.phones.map((item) => `(${item.ddd}) ${item.number}`).join(', ') || '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell classes={{ root: 'py-2' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.jobPosition || '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell classes={{ root: 'py-2' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.company || '-'}
                                        </Text>
                                    </TableCell>
                                    <TableCell classes={{ root: 'py-2' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.validationRequired ? 'Sim' : 'Não'}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={4}>
                                    <Empty title="Nenhuma pessoa encontrada" />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Participants;
