import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, boolean, date, number, object, string } from 'yup';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Radio from 'components/core/form/radio';
import DatePicker from 'components/core/datepicker';
import Checkbox from 'components/core/form/checkbox';
import theme from 'settings/theme';
import dictionary from 'utils/dictionary';
import { Option, Status } from 'types/general';
import { ProjectManagementInformationPayload } from 'types/models/project';
import { MeasureOption } from 'services/queries/projects/use-get-project-measures';
import ExternalButton from 'components/buttons/external';
import SignPostLightbox from './signpost-lightbox';
import useGetSignpostClass from 'services/queries/signposts/use-get-signpost-class';
import Editor from 'components/core/form/editor';

type CreateOrUpdateCisoProps = {
    defaultValues: Partial<ProjectManagementInformationPayload>;
    isBuilding: boolean;
    isSubmitting: boolean;
    measures: MeasureOption[];
    signposts: Option[];
    onAbleSignpostRequest: (signpost: string) => void;
    onSubmit: (data: ProjectManagementInformationPayload) => void;
    projectId?: number;
};

const CreateOrUpdateCiso = ({ defaultValues, isBuilding, isSubmitting, measures, projectId, signposts, onAbleSignpostRequest, onSubmit }: CreateOrUpdateCisoProps) => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const [signPostClassId, setSignPostClassId] = useState<number>();

    const { data: signpostClass } = useGetSignpostClass(signPostClassId?.toString());

    useEffect(() => {
        if (signpostClass && !isOpen) {
            setIsOpen(true);
        }
    }, [signpostClass, isOpen]);

    const schema = object({
        duration: string().required(dictionary.validation.required),
        management_start_date: date().required(dictionary.validation.required),
        management_end_date: date()
            .required(dictionary.validation.required)
            .when('management_start_date', (start, schema) => (start ? schema.min(start, dictionary.validation.date.min) : schema.nullable(true).notRequired())),
        project_id: number()
            .required(dictionary.validation.required)
            .default(+projectId!),
        signpost_class_id: number().when('isSignsSelected', (isSignsSelected, schema) => (isSignsSelected ? schema.required(dictionary.validation.required) : schema.nullable(true).notRequired())),
        status: number().required(dictionary.validation.required).default(Status.Active),
        project_management_information_has_measures: array(),
        isSignsSelected: boolean(),
        revision: number().default(1)
    });

    const { control, formState, handleSubmit, clearErrors, getFieldState, reset, setValue, watch } = useForm<ProjectManagementInformationPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        defaultValues
    });

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const isSignsSelected = watch('isSignsSelected');
    const managementStartDate = watch('management_start_date');
    const selectedMeasures = watch('project_management_information_has_measures');

    const handleClose = () => navigate(-1);

    const handleSelectMeasure = useCallback(
        (index: number, measure: MeasureOption) => () => {
            const measureSelected = selectedMeasures.some((item) => item?.value === measure.value);
            const value = !measureSelected ? measure : (undefined as any);

            setValue(`project_management_information_has_measures.${index}`, value);

            const { invalid } = getFieldState('project_management_information_has_measures');

            if (!invalid) {
                clearErrors('project_management_information_has_measures');
            }

            const isSelected = selectedMeasures.some((item) => item?.slug === 'placa-de-obra');

            if (!isSelected) {
                setValue('signpost_class_id', undefined as any);
            }

            setValue('isSignsSelected', isSelected);

            if (!Boolean(signposts.length)) {
                onAbleSignpostRequest(measure.slug);
            }
        },
        [selectedMeasures, signposts, clearErrors, onAbleSignpostRequest, getFieldState, setValue]
    );

    const handleEndDate = (date: Date) => {
        setValue('management_end_date', date as any);

        const start = dayjs(managementStartDate);
        const end = dayjs(date);
        const days = end.diff(start, 'days');

        setValue('duration', days.toString());
    };

    return (
        <Modal closeOnClickOutside={false} contentClassnames="sm:min-w-[500px]" onClose={handleClose}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <>
                    <form className="py-5 px-3 sm:px-7" onSubmit={handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            CISO
                        </Text>
                        <Text as="h5" variant="h6" className="text-heading mb-3">
                            Controle de contrato
                        </Text>
                        <div className="mb-5 border border-base-300 py-4 px-2 sm:p-4 rounded-[14px]">
                            <div className="mb-5 flex flex-col sm:flex-row gap-4 items-center">
                                <Controller
                                    control={control}
                                    name="management_start_date"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            label="Início do Gerenciamento"
                                            placeholderText="Selecione uma data"
                                            error={formState.errors.management_start_date?.message}
                                            parentClasses="w-full sm:w-[300px]"
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="management_end_date"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            minDate={new Date(managementStartDate)}
                                            label="Fim do Gerenciamento"
                                            placeholderText="Selecione uma data"
                                            error={formState.errors.management_end_date?.message}
                                            parentClasses="w-full sm:w-[300px]"
                                            onChange={handleEndDate}
                                        />
                                    )}
                                />
                                <Controller
                                    name="duration"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            disabled={true}
                                            type="number"
                                            error={formState.errors.duration?.message}
                                            label="Prazo"
                                            placeholder="Ex: 10"
                                            right={
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    /dias
                                                </Text>
                                            }
                                            parentClassName="w-full sm:w-[200px]"
                                            rightClasses="bg-base-200 px-4"
                                        />
                                    )}
                                />
                            </div>
                            <Text as="label" className="pl-0 block mb-4 sm:mb-1.5 text-base-500">
                                Providências
                            </Text>
                            {Boolean(measures.length) && (
                                <div className="flex flex-wrap gap-6 overflow-x-auto justify-start">
                                    {measures.map((measure, index) => {
                                        return (
                                            <Controller
                                                key={measure.value}
                                                name={`project_management_information_has_measures.${index}`}
                                                control={control}
                                                render={({ field }) => {
                                                    const isSelected = selectedMeasures.some((item) => item?.value === measure.value);

                                                    return (
                                                        <Checkbox
                                                            labelClassName="!justify-start"
                                                            {...field}
                                                            value={field.value?.value}
                                                            checked={isSelected}
                                                            label={measure.label}
                                                            onChange={handleSelectMeasure(index, measure)}
                                                        />
                                                    );
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                            {Boolean((formState as any).errors.project_management_information_has_measures?.message) && (
                                <Text as="span" variant="body.regular.xs" className="text-system-danger-500 italic block w-full mt-3">
                                    {(formState as any).errors.project_management_information_has_measures?.message}
                                </Text>
                            )}
                            {isSignsSelected && (
                                <div className="mt-3 bg-base-200 border border-dashed border-base-300 px-4 py-2 rounded-lg">
                                    <Text as="span" variant="body.regular.sm" className="mb-2 block text-base-500">
                                        Selecione uma categoria de placas
                                    </Text>
                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                                        {signposts.map((signpost) => (
                                            <Controller
                                                key={signpost.value}
                                                name="signpost_class_id"
                                                control={control}
                                                render={({ field }) => (
                                                    <div className="flex flex-wrap items-baseline">
                                                        <Radio
                                                            {...field}
                                                            label={signpost.label}
                                                            checked={signpost.value === field.value}
                                                            onChange={() => setValue('signpost_class_id', signpost.value)}
                                                            containerClasseName="mr-2"
                                                        />
                                                        <ExternalButton type="button" onClick={() => setSignPostClassId(signpost.value)}>
                                                            Ver placas
                                                        </ExternalButton>
                                                    </div>
                                                )}
                                            />
                                        ))}
                                    </div>
                                    {Boolean(formState.errors.signpost_class_id?.message) && (
                                        <Text as="span" variant="body.regular.xs" className="text-system-danger-500 italic block w-full mt-3">
                                            {formState.errors.signpost_class_id?.message}
                                        </Text>
                                    )}
                                </div>
                            )}
                            <Controller name="observations" control={control} render={({ field }) => <Editor {...field} label="Observações" parentClasses="mt-5" />} />
                        </div>
                        <div className="flex items-center">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                    {Boolean(signpostClass?.signposts?.length) && (
                        <SignPostLightbox
                            items={signpostClass?.signposts?.map((item) => item.url || '').filter(Boolean) || []}
                            onClose={() => {
                                setSignPostClassId(undefined);
                                setIsOpen(false);
                            }}
                        />
                    )}
                </>
            )}
        </Modal>
    );
};

export default CreateOrUpdateCiso;
