import React, { useContext } from 'react';

// Dependencies
import { useParams } from 'react-router-dom';

// Components
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';

// Helpers
import { ProjectContext } from 'contexts/project';
import { ProjectStatusEnum } from 'types/models/project';
import useReleaseTap from 'services/queries/projects/use-release-tap';

const ReleaseTap = () => {
    const { projectId } = useParams();
    const { dispatch } = useContext(ProjectContext);
    const { mutateAsync: releaseTap, isLoading: isReleasingTap } = useReleaseTap(Number(projectId || 0));

    const handleClose = () => dispatch({ type: 'SET_RELEASE_TAP_MODAL', payload: false });

    const handleReleaseTap = async () => {
        try {
            await releaseTap({ status: ProjectStatusEnum.Tap });

            handleClose();
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <Modal closeOnClickOutside={false} onClose={handleClose}>
            <div className="p-6 sm:min-w-[500px]">
                <Text as="h6" variant="h5" className="text-heading mb-3">
                    Liberar TAP
                </Text>
                <Text as="p" variant="body.regular.sm" className="text-base-700 mb-6">
                    Você tem certeza que deseja liberar este TAP?
                </Text>
                <div className="flex items-center justify-end">
                    <Button disabled={isReleasingTap} loading={isReleasingTap} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4" onClick={handleReleaseTap}>
                        Enviar
                    </Button>
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ReleaseTap;
