import React from 'react';

// Dependencies
import classNames from 'classnames';
import Timeline from '@mui/lab/Timeline/Timeline';

// Components
import Text from 'components/core/text';
import ActivityItem, { ActivityItemProps } from './item';

type ActivitiesProps = {
    activities: ActivityItemProps[];
    className?: string;
    style?: any;
    timelineClasses?: string;
    title?: string;
};

const Activities = ({ activities, className, timelineClasses, title, style }: ActivitiesProps) => {
    const containerClasses = classNames('relative', className);
    const timelineRootClasses = classNames('p-0', timelineClasses);

    return (
        <div className={containerClasses}>
            {title && (
                <Text as="h5" variant="h5" className="text-heading mb-2">
                    {title}
                </Text>
            )}
            <Timeline classes={{ root: timelineRootClasses }} style={style}>
                {activities.map((item, index) => {
                    const isLastItem = index === activities.length - 1;

                    return <ActivityItem key={index} {...item} withConnector={!isLastItem} />;
                })}
            </Timeline>
        </div>
    );
};

export default Activities;
