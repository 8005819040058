import { useQuery } from 'react-query';
import api from 'services/api';
import { getProjectTypeSolicitationKey } from './graphql';
import { ProjectTypeSolicitation } from 'types/models/project';
import { CamelCase } from 'types/utils';

const useGetSolicitation = (projectProposalTypeId?: string, solicitationId?: string) => {
    const fetcher = async () => api.get<CamelCase<ProjectTypeSolicitation>>(`/project-types/${projectProposalTypeId}/solicitations/${solicitationId}`).then(({ data }) => data);

    return useQuery(getProjectTypeSolicitationKey(solicitationId), fetcher, { enabled: Boolean(solicitationId) });
};

export default useGetSolicitation;
