// Dependencies
import { useQueries, UseQueryOptions } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import states, { getAllStatesOptionsKey } from '../states/graphql';

const useGetCustomerSetup = () => {
    const getAllStates = () => apiGraphQl<Pagination<Option>>(states.getAll).then((data) => data.items);

    const requests: UseQueryOptions<any, any, any, any>[] = [
        {
            queryKey: getAllStatesOptionsKey,
            queryFn: getAllStates
        }
    ];

    return useQueries(requests);
};

export default useGetCustomerSetup;
