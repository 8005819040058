import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Profile } from 'types/models/profile';
import { getProfileKey } from './graphql';

const useGetProfile = (query: string, profileId?: string) => {
    const request = async () => apiGraphQl<{ profile_by_pk: Partial<Profile> }>(query).then(({ profile_by_pk }) => profile_by_pk);

    return useQuery(getProfileKey(profileId), request, { enabled: !!profileId });
};

export default useGetProfile;
