import { useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import TermsCreateOrUpdate from './terms';
import { CamelCase } from 'types/utils';
import { ProjectTypeTerm, ProjectTypeTermPayload } from 'types/models/project';
import useCreateOrUpdateTerm from 'services/queries/projects/use-create-or-update-term';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import useFinalizeTerm from 'services/queries/terms/use-finalize-term';

const TermsCreateOrUpdatePage = () => {
    const ctx = useOutletContext();
    const termStatus = useGetQueryParam('termStatus');
    const term = useOutletContext<CamelCase<ProjectTypeTerm>>();
    const { projectProposalTypeId, projectTypeTermId } = useParams();

    const { mutateAsync: finalizeTerm, isLoading: isSubmittingFinalize } = useFinalizeTerm(Number(projectProposalTypeId) || 0, projectTypeTermId);
    const { mutateAsync: createOrUpdateTerm, isLoading: isSubmittingCrud } = useCreateOrUpdateTerm(Number(projectProposalTypeId) || 0, projectTypeTermId, ctx);

    console.log('ctx', ctx);

    const handleSubmit = async (data: any) => {
        try {
            if (Boolean(termStatus)) {
                await finalizeTerm(data);
                return;
            }

            await createOrUpdateTerm(data);
        } catch (error) {
            console.log('handleSubmit', error);
        }
    };

    const defaultValues = useMemo<ProjectTypeTermPayload>(
        () => ({
            withCaveat: term.withCaveat || false,
            caveatDescription: term.caveatDescription,
            type: term.type?.id!,
            documents: term?.documents?.map((item) => ({ file: item.file.id })) || [],
            fields: term.fields || [],
            observations: term?.observations || ''
        }),
        [term]
    );

    const documents = term.documents?.map((item) => item.file) || [];

    return <TermsCreateOrUpdate defaultValues={defaultValues} documents={documents} isSubmitting={isSubmittingCrud || isSubmittingFinalize} onSubmit={handleSubmit} />;
};

export default TermsCreateOrUpdatePage;
