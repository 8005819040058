import { useQuery } from 'react-query';
import api from 'services/api';
import { getProjectChartsKey } from './graphql';
import useTheme from '@mui/material/styles/useTheme';
import { ProjectChartItem, ProjectChartLegend } from 'pages/private/home/components/project-chart';
import { Option } from 'types/general';
import _sum from 'lodash/sum';

type Item = {
    items: Array<ProjectChartItem[]>;
    legends: ProjectChartLegend[];
    total?: Option<string | number>;
    withPlaceholderBackground?: boolean;
    hideTooltip?: boolean;
};

type ResponseData = {
    timeline: Item;
    checklist: Item;
    solicitations: Item;
    meetingRecords: Item;
};

const useGetProjectCharts = (projectTypeId?: string) => {
    const { palette } = useTheme();

    const request = async () =>
        api
            .get<{
                timeline?: {
                    progress: number;
                    predicted: number;
                };
                checklist: number;
                solicitations?: {
                    active: number;
                    finished: number;
                    stopped: number;
                };
                meetingRecords?: {
                    active: number;
                    finished: number;
                    stopped: number;
                };
            }>(`/project-types/${projectTypeId}/dashboard`)
            .then(({ data }) => {
                const valueOrZero = (value?: number) => ~~(value || 0);

                const { timeline, checklist, solicitations, meetingRecords } = data || {};

                const response: ResponseData = {
                    timeline: {
                        items: [
                            [
                                {
                                    value: valueOrZero(timeline?.progress),
                                    color: palette.success[600]
                                },
                                {
                                    value: 100 - valueOrZero(timeline?.progress),
                                    color: 'transparent'
                                }
                            ],
                            [
                                {
                                    value: valueOrZero(timeline?.predicted),
                                    color: palette.warning[300]
                                },
                                {
                                    value: 100 - valueOrZero(timeline?.predicted),
                                    color: 'transparent'
                                }
                            ]
                        ],
                        legends: [
                            {
                                label: 'Realizado',
                                value: valueOrZero(timeline?.progress),
                                color: palette.success[600],
                                isPercentage: true
                            },
                            {
                                label: 'Previsto',
                                value: valueOrZero(timeline?.predicted),
                                color: palette.warning[300],
                                isPercentage: true
                            }
                        ],
                        withPlaceholderBackground: true,
                        hideTooltip: true
                    },
                    checklist: {
                        items: [
                            [
                                {
                                    value: valueOrZero(checklist),
                                    color: palette.success[600]
                                },
                                {
                                    value: 100 - valueOrZero(checklist),
                                    color: 'transparent'
                                }
                            ]
                        ],

                        legends: [
                            {
                                label: 'Realizado',
                                value: valueOrZero(checklist),
                                color: palette.success[600],
                                isPercentage: true
                            }
                        ],
                        withPlaceholderBackground: true,
                        hideTooltip: true
                    },
                    solicitations: {
                        total: {
                            value: _sum([valueOrZero(solicitations?.finished), valueOrZero(solicitations?.active), valueOrZero(solicitations?.stopped)]),
                            label: 'Total'
                        },
                        items: [
                            [
                                {
                                    value: valueOrZero(solicitations?.finished),
                                    color: palette.success[600]
                                },
                                {
                                    value: valueOrZero(solicitations?.active),
                                    color: palette.info[400]
                                },
                                {
                                    value: valueOrZero(solicitations?.stopped),
                                    color: palette.error[400]
                                }
                            ]
                        ],
                        legends: [
                            {
                                label: 'Realizado',
                                value: valueOrZero(solicitations?.finished),
                                color: palette.success[600]
                            },
                            {
                                label: 'Em andamento',
                                value: valueOrZero(solicitations?.active),
                                color: palette.info[400]
                            },
                            {
                                label: 'Paralisado',
                                value: valueOrZero(solicitations?.stopped),
                                color: palette.error[400]
                            }
                        ],
                        withPlaceholderBackground: true
                    },
                    meetingRecords: {
                        total: {
                            value: _sum([valueOrZero(meetingRecords?.finished), valueOrZero(meetingRecords?.active), valueOrZero(meetingRecords?.stopped)]),
                            label: 'Total'
                        },
                        items: [
                            [
                                {
                                    value: valueOrZero(meetingRecords?.finished),
                                    color: palette.success[600]
                                },
                                {
                                    value: valueOrZero(meetingRecords?.active),
                                    color: palette.info[400]
                                },
                                {
                                    value: valueOrZero(meetingRecords?.stopped),
                                    color: palette.error[400]
                                }
                            ]
                        ],
                        legends: [
                            {
                                label: 'Realizado',
                                value: valueOrZero(meetingRecords?.finished),
                                color: palette.success[600]
                            },
                            {
                                label: 'Em andamento',
                                value: valueOrZero(meetingRecords?.active),
                                color: palette.info[400]
                            },
                            {
                                label: 'Paralisado',
                                value: valueOrZero(meetingRecords?.stopped),
                                color: palette.error[400]
                            }
                        ],
                        withPlaceholderBackground: true
                    }
                };

                return response;
            });

    return useQuery(getProjectChartsKey(projectTypeId), request, { enabled: Boolean(projectTypeId) });
};

export default useGetProjectCharts;
