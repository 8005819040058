export const getAllBusinessTypesOptionsKey = ['business-type', 'all', 'options'];

const queries = {
    getAll: `query GetAllBusinessTypesOptions {
        items: type_business(order_by: {title: asc}, where: {status: {_neq: -1}}) {
            value: id
            label: title
        }
    }`
};

export default queries;
