import Datatable from 'components/core/table/datatable';
import { useMemo, useState } from 'react';
import useGetFinancialExpenses from 'services/queries/financial/use-get-financial-expenses';
import { financialExpensesColumns } from '../utils';
import PageHeader from 'components/page/header';
import Button from 'components/core/button';
import Icon from 'components/core/icon';
import FinancialExpensesFilter from './filter';
import qs from 'qs';

const FinancialExpensesPage = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState<string | null>('');

    const queryFilters = useMemo(
        () =>
            qs.stringify(
                {
                    filters: {
                        searchText
                    },
                    page
                },
                {
                    addQueryPrefix: true,
                    encode: false,
                    arrayFormat: 'brackets'
                }
            ),
        [page, searchText]
    );

    const { data, isLoading } = useGetFinancialExpenses(queryFilters);

    return (
        <>
            {isFilterVisible && <FinancialExpensesFilter onClose={setIsFilterVisible.bind(this, false)} />}
            <PageHeader
                className="mb-5"
                title="Despesas"
                right={
                    <Button
                        color="inherit"
                        variant="outlined"
                        type="button"
                        size="medium"
                        className="bg-white"
                        onClick={setIsFilterVisible.bind(this, true)}
                        endIcon={<Icon name="ico-filter" width={17} height={17} color="currentColor" />}>
                        Filtrar
                    </Button>
                }
            />
            <Datatable
                loading={isLoading}
                columns={financialExpensesColumns}
                data={data?.items || []}
                options={{
                    count: data?.total,
                    elevation: 0,
                    sort: true,
                    filter: false,
                    search: true,
                    searchAlwaysOpen: true,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    page,
                    onSearchChange: setSearchText,
                    onChangePage: setPage
                }}
            />
        </>
    );
};

export default FinancialExpensesPage;
