import ModalFilter from 'components/modal/filter';
import React, { useEffect } from 'react';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import DatePicker from 'components/core/datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useGetOptions } from 'services/queries/options/use-get-options';
import { Modify, Status } from 'types/general';
import { formatDate, isValidDate } from 'utils/date';
import _omitBy from 'lodash/omitBy';
import { useNavigate } from 'react-router-dom';
import { TaskFilters } from '..';
import useAuth from 'store/auth/use-auth';

type Props = {
    onClose(): void;
};

// This function transforms "20230104" into a Date
// TODO: For sure there's a better way to do that
const formatDefaultDate = (date: string) => {
    const [YYYY, MM, DD] = [date.slice(0, 4), date.slice(4, 6), date.slice(6, 8)];

    const generateDate = new Date([MM, DD, YYYY].join('/'));

    if (!isValidDate(generateDate)) {
        return undefined;
    }

    return generateDate;
};

type FormState = Modify<TaskFilters, { project: number[] }>;

const TasksFilter = ({ onClose }: Props) => {
    const navigate = useNavigate();

    const { control, formState, handleSubmit, reset } = useForm<FormState>({
        mode: 'onSubmit'
    });

    const costumers = useGetOptions({
        tableName: 'customer',
        labelColumn: 'title',
        where: { projects: { status: { _eq: Status.Active } }, status: { _eq: Status.Active } }
    });

    const { auth } = useAuth();
    const personId = auth?.credentials?.user?.person?.id;

    const projects = useGetOptions({
        tableName: 'project',
        labelColumn: 'title',
        where: { project_proposals: { proposal_types: { project_type_person_resources: { person_id: { _eq: personId } } } } }
    });

    useEffect(() => {
        if (!!window.location?.search) {
            // Get filters from query string
            const params: any = Object.fromEntries(new URLSearchParams(window.location?.search));

            if (params?.initialPeriodStartDate) {
                params.initialPeriodStartDate = formatDefaultDate(params.initialPeriodStartDate);
            }

            if (params?.initialPeriodEndDate) {
                params.initialPeriodEndDate = formatDefaultDate(params.initialPeriodEndDate);
            }

            if (params?.finalPeriodStartDate) {
                params.finalPeriodStartDate = formatDefaultDate(params.finalPeriodStartDate);
            }

            if (params?.finalPeriodEndDate) {
                params.finalPeriodEndDate = formatDefaultDate(params.finalPeriodEndDate);
            }

            // Set form default value if there's any query string
            if (!!Object.keys(params)?.length) {
                reset(params);
            }
        }
    }, [reset]);

    const onSubmit = (data: FormState) => {
        const payload: FormState = { ...data };

        if (data?.initialPeriodStartDate) {
            payload.initialPeriodStartDate = formatDate(data.initialPeriodStartDate, 'YYYYMMDD');
        }

        if (data?.initialPeriodEndDate) {
            payload.initialPeriodEndDate = formatDate(data.initialPeriodEndDate, 'YYYYMMDD');
        }

        if (data?.finalPeriodStartDate) {
            payload.finalPeriodStartDate = formatDate(data.finalPeriodStartDate, 'YYYYMMDD');
        }

        if (data?.finalPeriodEndDate) {
            payload.finalPeriodEndDate = formatDate(data.finalPeriodEndDate, 'YYYYMMDD');
        }

        if (Array.isArray(data?.project) && !!data?.project?.length) {
            payload.project = data.project.join(',') as any;
        }

        const result: any = _omitBy(payload, (v) => !v || v === '-' || (Array.isArray(v) && !v?.length));

        if (!Object.keys(result)?.length) {
            return clear();
        }

        const params = new URLSearchParams(result).toString();

        onClose();

        if (!params) {
            return;
        }

        navigate(`/app/tarefas?${params}`);
    };

    const clear = () => {
        onClose();
        navigate('/app/tarefas');
    };

    return (
        <ModalFilter onClose={onClose} onSubmit={handleSubmit(onSubmit)} onClear={clear}>
            <div className="gap-4 flex flex-col">
                <Controller name="title" control={control} render={({ field }) => <Input {...field} autoFocus={false} error={formState.errors.title?.message} label="Título" placeholder="Título" />} />

                <Controller
                    name="customer"
                    control={control}
                    render={({ field }) => {
                        const value = costumers?.data?.items?.find((item) => item.value === ~~field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={costumers?.data?.items}
                                label="Cliente"
                                placeholder="Selecione uma opção"
                                error={formState.errors.customer?.message}
                                onChange={(option: any) => field.onChange(option?.value)}
                                isLoading={costumers?.isLoading}
                                isClearable={true}
                            />
                        );
                    }}
                />

                <Controller
                    name="project"
                    control={control}
                    render={({ field }) => {
                        const value = projects?.data?.items?.filter((item) => field?.value?.includes(item.value));
                        const errorMessage = formState.errors.project?.map(({ message }) => message)?.join(', ');

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={projects?.data?.items}
                                label="Projeto(s)"
                                placeholder="Selecione uma opção"
                                error={errorMessage}
                                onChange={(option: any) => {
                                    field.onChange(option?.map(({ value }) => value));
                                }}
                                isLoading={projects?.isLoading}
                                isClearable={true}
                                isMulti={true}
                            />
                        );
                    }}
                />

                <div>
                    <label htmlFor="initialPeriod" className="text-base-500 mb-4 block">
                        Período Inicial
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                        <Controller
                            name="initialPeriodStartDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data inicial" error={formState.errors.initialPeriodStartDate?.message} {...field} />}
                        />
                        <Controller
                            name="initialPeriodEndDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data final" error={formState.errors.initialPeriodEndDate?.message} {...field} />}
                        />
                    </div>
                </div>
                <div className="">
                    <label htmlFor="finalPeriod" className="text-base-500 mb-4 block">
                        Período Final
                    </label>

                    <div className="grid grid-cols-2 gap-2">
                        <Controller
                            name="finalPeriodStartDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data inicial" error={formState.errors.finalPeriodStartDate?.message} {...field} />}
                        />
                        <Controller
                            name="finalPeriodEndDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data final" error={formState.errors.finalPeriodEndDate?.message} {...field} />}
                        />
                    </div>
                </div>
            </div>
        </ModalFilter>
    );
};

export default TasksFilter;
