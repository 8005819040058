import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import apiGraphQl from 'services/graphql';
import { convertToDateOrReturnUndefined } from 'utils/date';
import { formatMoney } from 'utils/money';
import { getPeopleDetailsKey, getPeopleKey } from './graphql';
import { Status } from 'types/general';

const useGetPerson = (id?: string, isDetails = false) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const fetcher = async () => {
        const { data } = await api.get<any>(`/people/${id}`);

        const { contracts } = await apiGraphQl<{ contracts: any[] }>(
            `query GetPersonContract($id: Int!) {
                contracts: person_contract(where: {person_id: {_eq: $id}, status: {_eq: ${Status.Active}}}) {
                  id
                  finalDate: final_date
                  juridicDocument: juridic_document
                  juridicFantasyName: juridic_fantasy_name
                  juridicName: juridic_name
                  startDate: start_date
                  salary
                  position: position_id
                  seniority
                  shift
                  frequency
                  branch: branch_id
                  type
                  ${
                      isDetails
                          ? `job_position {
                    title
                  }
                  contract_status {
                    id
                    title
                  }`
                          : ''
                  }
                  
                }
              }
              `,
            { id }
        );

        const [contract] = contracts || [];

        return {
            ...data,
            ...(data?.user && {
                user: {
                    ...data?.user,
                    profile: data?.user?.profile?.id
                }
            }),
            birthday: convertToDateOrReturnUndefined(data?.birthday),
            ...(!!contract && {
                contracts: {
                    ...contract,
                    startDate: convertToDateOrReturnUndefined(contract?.startDate),
                    finalDate: convertToDateOrReturnUndefined(contract?.finalDate),
                    salary: formatMoney(contract?.salary)
                }
            })
        };
    };

    return useQuery(isDetails ? getPeopleDetailsKey(id) : getPeopleKey(id), fetcher, {
        enabled: !!id,
        onError: () => {
            navigate(-1);
            showToast('Tivemos um problema ao obter os detalhes dessa pessoa. Tente novamente.', 'danger');
        }
    });
};

export default useGetPerson;
