import React, { useMemo } from 'react';

// Dependencies
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';

// Components
import CreateOrUpdateTasks, { TasksPayload } from './create-or-update';

// Helpers
import { formatDate } from 'utils/date';
import useCreateOrUpdateTask from 'services/queries/projects/use-create-or-update-task';
import useGetPeopleOptions from 'services/queries/people/use-get-people-options';
import useGetMeetingRecord from 'services/queries/projects/use-get-meeting-record';
import useGetSubjectsByMeetingRecord from 'services/queries/projects/use-get-subjects-by-meeting-record';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';

const CreateOrUpdateTasksPage = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { projectId: projectIdParam, meetingRecordId } = useParams();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const projectId = useMemo(() => projectIdParam || ctx?.project?.id, [projectIdParam, ctx]);

    const meetingRecord = useGetMeetingRecord(meetingRecordId);
    const { data: people, isLoading: isLoadingPeople } = useGetPeopleOptions();
    const { data: subjects, isLoading: isLoadingSubjects } = useGetSubjectsByMeetingRecord(meetingRecord.data?.id);

    const { mutateAsync: createOrUpdateTask, isLoading: isSubmitting } = useCreateOrUpdateTask();

    const isBuilding = useMemo(() => meetingRecord.isLoading || isLoadingPeople || isLoadingSubjects, [isLoadingPeople, isLoadingSubjects, meetingRecord]);

    const handleSubmit = async (data: TasksPayload) => {
        try {
            const payload = data.tasks.map((item) => ({
                ...item,
                deadline: formatDate(item.deadline, 'YYYY-MM-DD')
            }));

            const requests = payload.map((item) => createOrUpdateTask(item));

            const responses = await Promise.allSettled(requests);

            const isSuccess = responses.some((item) => item.status === 'fulfilled');

            if (isSuccess) {
                if (pathname.includes('operacional')) {
                    return navigate(-1);
                }

                navigate(`/app/contratos/${projectId}`);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const defaultValues = useMemo<TasksPayload>(() => {
        const tasks = meetingRecord.data?.subjects?.flatMap((item) => item.tasks?.map((task) => ({ ...task, subjectId: item.id }))) || [];

        return {
            tasks: tasks?.map((item) => ({
                taskId: item?.id,
                title: item?.title,
                description: item?.description,
                person: item?.person?.id,
                deadline: (item?.deadline ? new Date(item?.deadline) : '') as string,
                proposalType: meetingRecord.data?.projectProposalType?.id,
                order: item?.order,
                ...(meetingRecord.data?.id && { projectMeetingRecordSubject: item?.subjectId })
            }))
        };
    }, [meetingRecord.data]);

    return <CreateOrUpdateTasks defaultValues={defaultValues} isBuilding={isBuilding} isSubmitting={isSubmitting} people={people || []} subjects={subjects || []} onSubmit={handleSubmit} />;
};

export default CreateOrUpdateTasksPage;
