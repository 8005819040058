export const getAllNotificationsKey = ['notifications', 'all'];
export const readNotificationKey = ['notifications', 'read'];

export const queries = {
    getAll: `query GetNotifications ($user_id: Int!) {
        notifications: notification(where: {user_id: {_eq: $user_id}}, limit: 10, order_by: {created_at: desc}) {
            entity
            entity_id
            id
            is_read
            page_uri
            read_date
            status
            subject
            text
            type
            created_at
        }
    }`,
    readNotification: `mutation ($payload: notification_set_input!, $id: Int!) {
        update_notification_by_pk(pk_columns: { id: $id }, _set: $payload) {
            id
        }
    }`
};

export default queries;
