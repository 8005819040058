import SimpleCard from './simple-card';
import ComplexCard from './complex-card';
import { formatMoney } from 'utils/money';
import { ProjectDreResponse } from 'types/models/project';

type CardsInfoProp = {
    data?: ProjectDreResponse;
    isLoading: boolean;
};

const CardsInfo = ({ isLoading, data }: CardsInfoProp) => {
    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-4 my-5 mb-10">
                <SimpleCard data={formatMoney(data?.resume.total)} isLoading={isLoading} title="Valor do Projeto" icon="ico-dollar" />
                <SimpleCard data={formatMoney(data?.resume.indirectCosts)} isLoading={isLoading} title="Custos Indiretos" icon="ico-dollar" />
                <ComplexCard
                    data={{
                        firstData: { value: formatMoney(data?.resume.directCosts.sale) },
                        secondData: { value: formatMoney(data?.resume.directCosts.eac) }
                    }}
                    isLoading={isLoading}
                    title="Custos Diretos"
                />
                <ComplexCard
                    data={{
                        firstData: { value: `${data?.resume.margin.sale || 0}%` },
                        secondData: {
                            value: `${data?.resume.margin.eac || 0}%`,
                            valueClassName: (data?.resume.margin.eac || 0) < 0 ? 'text-system-danger-500' : 'text-system-success-500'
                        }
                    }}
                    isLoading={isLoading}
                    title="Margem"
                />
            </div>
        </>
    );
};

export default CardsInfo;
