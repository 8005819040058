import ListPage from 'pages/private/crud/list';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import { Person, PersonContractType } from 'types/models/person';
import _get from 'lodash/get';
import { formatMoney } from 'utils/money';
import { formatDate } from 'utils/date';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Button from 'components/core/button';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

export enum PersonContractStatusTitle {
    Active = 1,
    MaternityLeave = 2,
    SicknessAllowance = 3,
    Resignation = 4,
    Finished = 5,
    Transferred = 6,
    Vacation = 7,
    MedicalCertificate = 8
}

const PersonDetailsContracts = ({ page }: WithPagePermissionsProps) => {
    const { id } = useOutletContext<Partial<Person>>();
    const navigate = useNavigate();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const handleClickReactivateContract = (contractId: number) => {
        navigate(`/app/pessoas/${id}/contratos/reativar/${contractId}`);
    };

    const listPageProps: CrudPageProps = {
        title: 'Contratos',
        graphql: {
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },

                {
                    name: 'start_date',
                    label: dictionary.content.graphql['start_date'],
                    options: {
                        customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY')
                    }
                },
                {
                    name: 'final_date',
                    label: dictionary.content.graphql['final_date'],
                    options: {
                        customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY')
                    }
                },

                {
                    name: 'juridic_name',
                    label: dictionary.content.graphql['juridic_name'],
                    options: {
                        customBodyRender: (value) => value || '-'
                    }
                },
                {
                    name: 'job_position.title',
                    label: dictionary.content.graphql['job_position'],
                    options: {
                        customBodyRender: (value) => value || '-'
                    }
                },
                {
                    name: 'salary',
                    label: dictionary.content.graphql['salary'],
                    options: {
                        customBodyRender: (value = 0) => <>{formatMoney(value || 0)}</>
                    }
                },
                {
                    name: 'contract_status.id',
                    label: dictionary.content.graphql['status'],
                    options: {
                        customBodyRender: (value = 0, tableMeta) => {
                            const label = _get(dictionary.content.personContractStatusTitle, PersonContractStatusTitle[value], '-');
                            const isEligibleToReactivate = [
                                PersonContractStatusTitle.MaternityLeave,
                                PersonContractStatusTitle.MedicalCertificate,
                                PersonContractStatusTitle.Vacation,
                                PersonContractStatusTitle.SicknessAllowance
                            ].includes(value);

                            if (!isEligibleToReactivate) {
                                return label;
                            }

                            const [id] = tableMeta.rowData;

                            return (
                                <div className="flex flex-col">
                                    <span className="mb-2">{label}</span>
                                    <Button
                                        onClick={() => handleClickReactivateContract(id)}
                                        size="small"
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        className="text-[0.6rem] py-[3px] px-[5px] mr-auto text-center">
                                        Reativar contrato
                                    </Button>
                                </div>
                            );
                        }
                    }
                },
                {
                    name: 'type',
                    label: dictionary.content['type'],
                    options: {
                        customBodyRender: (value: any) => _get(dictionary.content.contractTypes, PersonContractType[value]?.toLocaleLowerCase(), '')
                    }
                },

                {
                    name: 'shift',
                    label: dictionary.content.graphql['shift'],
                    options: {
                        customBodyRender: (value) => Object.values(dictionary.content.shifts)[value - 1] || '-'
                    }
                },

                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ],
            table: 'person_contract',
            searchableField: 'juridic_name',
            where: {
                person_id: {
                    _eq: id
                }
            },
            orderBy: { start_date: 'desc' }
        },
        hideHeader: true
    };

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <ListPage page={page} {...listPageProps} />
        </>
    );
};

export default PersonDetailsContracts;
