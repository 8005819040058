import 'quill-paste-smart';
import { forwardRef } from 'react';
import classNames from 'classnames';
import Text from 'components/core/text';
import ErrorMessage from 'components/error/message';
import ReactQuill, { ReactQuillProps } from 'react-quill';

type EditorProps = {
    label: string;
    error?: string;
    parentClasses?: string;
} & ReactQuillProps;

const modules = {
    clipboard: {
        allowed: {
            tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
            attributes: ['href', 'rel', 'target', 'class']
        },
        keepSelection: true,
        substituteBlockElements: true,
        magicPasteLinks: true
    }
};

const Editor = forwardRef<ReactQuill, EditorProps>(({ label, parentClasses, error, ...props }, ref) => {
    const labelClasses = classNames(labelText, error && 'text-system-danger-500');
    const containerClasses = classNames(parentClasses, error ? 'editor-error' : '');

    return (
        <div className={containerClasses}>
            {label && (
                <Text as="label" variant="body.regular.sm" className={labelClasses}>
                    {label}
                </Text>
            )}
            <ReactQuill ref={ref} theme="snow" {...props} modules={modules} />
            <ErrorMessage visible={Boolean(error)}>{error}</ErrorMessage>
        </div>
    );
});

const { labelText } = {
    labelText: 'block mb-1.5 text-base-500'
};

export default Editor;
