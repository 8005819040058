import React from 'react';

import { TaskType } from './task';
import ActionButton from './action';
import Input from 'components/core/form/input';
import { Controller, useFormContext } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Checkbox from 'components/core/form/checkbox';

type SubTaskProps = {
    childrenIndex: number;
    parentIndex: number;
    onDeleteSubtask: () => void;
};

const SubTask = ({ childrenIndex, parentIndex, onDeleteSubtask }: SubTaskProps) => {
    const { control, formState } = useFormContext<{ tasks: TaskType[] }>();

    return (
        <div className="flex items-center flex-wrap pl-[46px] mt-4 w-full">
            <Controller
                name={`tasks.${parentIndex}.tasks.${childrenIndex}.mandatory`}
                control={control}
                render={({ field }) => {
                    return (
                        <Tooltip placement="top" title="Obrigatório" disableFocusListener={true}>
                            <Checkbox {...field} value={field.value as any} checked={field.value} containerClasseName="mr-4" />
                        </Tooltip>
                    );
                }}
            />
            <span className="flex items-center justify-center bg-base-200 text-heading rounded-[10px] min-w-[30px] px-2 py-[2px] mr-4">
                {parentIndex + 1}.{childrenIndex + 1}
            </span>
            <Controller
                name={`tasks.${parentIndex}.tasks.${childrenIndex}.title`}
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        autoComplete="nope"
                        type="text"
                        placeholder="Título da tarefa"
                        error={formState.errors.tasks?.[parentIndex]?.tasks?.[childrenIndex]?.title?.message}
                        parentClassName="flex-1 w-[unset]"
                    />
                )}
            />
            <ActionButton variant="remove" className="ml-2" onClick={onDeleteSubtask} />
        </div>
    );
};

export default SubTask;
