import axios from 'axios';
import useToast from 'hooks/toast/use-toast';
import { useMutation } from 'react-query';
import { AuthPayload } from 'types/models/user';

const { REACT_APP_API_URL, REACT_APP_API_VERSION } = process.env;

const useDeleteSystemAccount = () => {
    const { showToast } = useToast();

    const request = (payload: AuthPayload) => {
        return axios({
            method: 'DELETE',
            url: `${REACT_APP_API_URL}${REACT_APP_API_VERSION}/tokens`,
            data: payload
        });
    };

    return useMutation(request, {
        onSuccess: () => {
            showToast('Gostaríamos de informar que sua requisição de conta foi solicitada com sucesso do nosso sistema. Em breve entraremos em contado para dar continuidade.', 'success', {
                duration: 150000
            });
        },
        onError: () => {
            showToast('Ocorreu um erro ao solicitar a exclusão da sua conta, por favor, tente novamente.', 'danger');
        }
    });
};

export default useDeleteSystemAccount;
