import React, { forwardRef, TextareaHTMLAttributes } from 'react';

// Components
import Text from 'components/core/text';
import ErrorMessage from 'components/error/message';

// Helpers
import classNames from 'classnames';

type TextareaProps = {
    label?: string;
    error?: string;
    parentClassName?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ label, className, error, parentClassName, ...props }, ref) => {
    const parentClasses = classNames('w-full', parentClassName);
    const labelClasses = classNames(labelText, error && 'text-system-danger-500');
    const inputClasses = classNames(input, error ? 'text-system-danger-500 border-system-danger-500 hover:border-system-danger-600' : 'text-heading border-base-300 hover:border-base-400', className);

    return (
        <div className={parentClasses}>
            {label && (
                <Text as="label" variant="body.regular.sm" className={labelClasses}>
                    {label}
                </Text>
            )}
            <textarea {...props} ref={ref} className={inputClasses} />
            <ErrorMessage visible={Boolean(error)}>{error}</ErrorMessage>
        </div>
    );
});

const { input, labelText } = {
    input: 'border border-solid rounded-[14px] text-sm w-full min-h-[100px] px-4 py-3 disabled:bg-base-200',
    labelText: 'block mb-1.5 text-base-500'
};

export default Textarea;
