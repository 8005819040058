import React from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useNavigate, useParams } from 'react-router-dom';
import Empty from 'components/empty';
import useTheme from '@mui/material/styles/useTheme';
import useGetFinancialPayment from 'services/queries/operationals/use-get-financial-payment';
import Spinner from 'components/core/spinner';
import { formatMoney } from 'utils/money';
import { formatDate } from 'utils/date';
import TaskFiles from '../../task/files';

const MeasurementPaymentPage = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const { providerFinancialSetupId, paymentId } = useParams();

    const { data: payment, isLoading, isError } = useGetFinancialPayment(Number(providerFinancialSetupId), Number(paymentId));

    const handleBack = () => navigate(-1);

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[500px]"
            headerLeft={
                Boolean(payment?.invoiceNumber) ? (
                    <Text as="h3" variant="h4" className="text-heading">
                        #{payment?.invoiceNumber?.padStart(3, '0') || ''}
                    </Text>
                ) : undefined
            }
            onClose={handleBack}
        >
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : isError || !payment ? (
                <Empty title="Pagamento não encontrado" className="p-5" />
            ) : (
                <div className="p-6 pt-0">
                    <div className="border-b border-b-base-300 pb-4 mb-4">
                        <Text as="label" variant="h6" className="text-heading block mb-3">
                            Fornecedor
                        </Text>
                        <Text as="p" className="text-base-500 mb-2">
                            Nome:{' '}
                            <Text as="span" className="text-heading">
                                {payment.providerName || '-'}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500">
                            CNPJ:{' '}
                            <Text as="span" className="text-heading">
                                {payment.providerDocument || '-'}
                            </Text>
                        </Text>
                    </div>
                    <div className="border-b border-b-base-300 pb-4 mb-4">
                        <Text as="label" variant="h6" className="text-heading block mb-3">
                            Valor contratual
                        </Text>
                        <Text as="p" className="text-base-500 mb-2">
                            Valor:{' '}
                            <Text as="span" className="text-heading">
                                {formatMoney(payment.value || 0)}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500 mb-2">
                            Data de emissão:{' '}
                            <Text as="span" className="text-heading">
                                {formatDate(payment.issueDate)}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500">
                            Data de vencimento:{' '}
                            <Text as="span" className="text-heading">
                                {formatDate(payment.dueDate)}
                            </Text>
                        </Text>
                    </div>
                    <div className="border-b border-b-base-300 pb-4 mb-4">
                        <Text as="label" variant="h6" className="text-heading block mb-3">
                            Total medido
                        </Text>
                        <Text as="p" className="text-base-500 mb-2">
                            Data de recebimento:{' '}
                            <Text as="span" className="text-heading">
                                {formatDate(payment.receiptDate)}
                            </Text>
                        </Text>
                        <Text as="p" className="text-base-500">
                            Data de envio:{' '}
                            <Text as="span" className="text-heading">
                                {formatDate(payment.sentDate)}
                            </Text>
                        </Text>
                    </div>
                    <div className="border-b border-b-base-300 pb-4 mb-4">
                        <Text as="label" variant="h6" className="text-heading block mb-3">
                            Previsão
                        </Text>
                        <Text as="p" className="text-base-500">
                            Previsão de pagamento:{' '}
                            <Text as="span" className="text-heading">
                                {formatDate(payment.forecastDate)}
                            </Text>
                        </Text>
                    </div>
                    <div className="border-b border-b-base-300 pb-4 mb-4">
                        <Text as="label" variant="h6" className="text-heading block mb-3">
                            Anexos
                        </Text>
                        <TaskFiles files={payment.files as any} className="!p-0" itemsPerRow={5} emptyClassName="!mb-0" />
                    </div>
                    <div>
                        <Text as="label" variant="h6" className="text-heading block mb-3">
                            Observações
                        </Text>
                        <div className="rich-content" dangerouslySetInnerHTML={{ __html: payment.observations }} />
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default MeasurementPaymentPage;
