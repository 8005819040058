import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { getPeopleCondition } from 'pages/private/projects/resource';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useGetBranchesOptions from 'services/queries/branches/use-get-branches-options';
import useGetClassificationOptions from 'services/queries/operationals/use-get-classifications-options';
import useGetPeopleOptions from 'services/queries/people/use-get-people-options';
import { CreateAssetPayload } from 'types/models/assets';
import { ClassificationType } from 'types/models/classification';
import { assetsStatuses } from 'utils/statics';

const CommonFields = () => {
    const { control, formState } = useFormContext<CreateAssetPayload>();

    const { classificationTypeId, assetId } = useParams();

    const isTechnolyClassification = +classificationTypeId! === ClassificationType.AssetTechnology;

    const { data: departmentsOptions = [], isLoading: isLoadingDepartments } = useGetClassificationOptions(ClassificationType.Departament);
    const { data: branchesOptions = [], isLoading: isLoadingBranches } = useGetBranchesOptions();
    const { data: peopleOptions = [], isLoading: isLoadingPeopleOptions } = useGetPeopleOptions(getPeopleCondition, !assetId && isTechnolyClassification);

    return (
        <>
            <Controller name="name" control={control} render={({ field }) => <Input {...field} error={formState.errors?.name?.message} label="Nome" placeholder="Nome" />} />
            <Controller name="label" control={control} render={({ field }) => <Input {...field} error={formState.errors?.label?.message} label="Etiqueta" placeholder="Etiqueta" />} />
            <Controller
                name="quantity"
                control={control}
                render={({ field }) => <Input type="number" {...field} min={0} error={formState.errors?.quantity?.message} label="Quantidade" placeholder="Ex: 0" />}
            />
            <Controller
                name="branch_id"
                control={control}
                render={({ field }) => {
                    const value = branchesOptions?.find((item) => item.value === field.value);
                    return (
                        <Select
                            {...field}
                            value={value}
                            options={branchesOptions}
                            isLoading={isLoadingBranches}
                            onChange={(option: any) => field?.onChange(option?.value)}
                            error={formState.errors?.branch_id?.message}
                            label="Unidade"
                            placeholder="Selecione a unidade"
                        />
                    );
                }}
            />
            <Controller name="brand" control={control} render={({ field }) => <Input {...field} error={formState.errors?.brand?.message} label="Marca" placeholder="Marca" />} />
            <Controller name="model" control={control} render={({ field }) => <Input {...field} error={formState.errors?.model?.message} label="Modelo" placeholder="Modelo" />} />
            {isTechnolyClassification && (
                <Controller
                    name="classification_department_id"
                    control={control}
                    render={({ field }) => {
                        const value = departmentsOptions.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={departmentsOptions}
                                isLoading={isLoadingDepartments}
                                onChange={(option: any) => field.onChange(option?.value)}
                                error={formState.errors?.classification_department_id?.message}
                                label="Setor"
                                placeholder="Selecione o setor"
                            />
                        );
                    }}
                />
            )}
            <Controller
                name="serial_number"
                control={control}
                render={({ field }) => <Input {...field} value={field.value} error={formState.errors?.model?.message} label="Série" placeholder="Série" />}
            />
            {!assetId && isTechnolyClassification && (
                <Controller
                    name="person_id"
                    control={control}
                    render={({ field }) => {
                        const value = peopleOptions.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={peopleOptions}
                                isLoading={isLoadingPeopleOptions}
                                onChange={(option: any) => field.onChange(option?.value)}
                                error={formState.errors?.person_id?.message}
                                label="Colaborador"
                                placeholder="Selecione o colaborador"
                            />
                        );
                    }}
                />
            )}
            <Controller
                name="asset_status"
                control={control}
                render={({ field }) => {
                    const value = assetsStatuses.find((item) => item.value === +field.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={assetsStatuses}
                            onChange={(option: any) => field.onChange(option?.value)}
                            error={formState.errors?.asset_status?.message}
                            label="Status"
                            placeholder="Selecione o status"
                        />
                    );
                }}
            />
        </>
    );
};

export default CommonFields;
