import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'components/core/icon';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { TaskProgress, TaskType } from 'types/models/task';
import { formatDate } from 'utils/date';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import TaskLightbox from './lightbox';
import classNames from 'classnames';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import useAuth from 'store/auth/use-auth';
import { dangerButton, warningButton } from '../outcomes/list';
import ConfirmModal from 'components/core/modal/confirm';
import useDeleteTaskProgress from 'services/queries/task/use-delete-task-progress';

type TaskProgressListProps = {
    taskType?: TaskType;
    items: TaskProgress[];
    timelineId: string | null;
};

const TaskProgressList = ({ items, taskType, timelineId }: TaskProgressListProps) => {
    const { palette } = useTheme();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const { taskId } = useParams();

    const [observations, setObservations] = useState<string>();
    const [taskProgressIdToDelete, setTaskProgressIdToDelete] = useState<number>();

    const handleToggleObservations = (value?: string) => () => setObservations(value);

    const userAllowedToEditOrDelete = auth.credentials.user.person?.id;

    const handleGoTo = (taskProgressId: number) => navigate(`progresso?timelineId=${timelineId}&taskProgressId=${taskProgressId}`);
    const { mutateAsync: deleteTaskProgress, isLoading: isDeletingTaskProgress } = useDeleteTaskProgress(taskId);

    const handleDeleteTaskProgress = async () => {
        try {
            await deleteTaskProgress(taskProgressIdToDelete!);
            setTaskProgressIdToDelete(undefined);
        } catch (e) {
            setTaskProgressIdToDelete(undefined);
            console.error('handleDeleteTaskProgress', e);
        }
    };

    return (
        <div className="pb-6">
            {Boolean(items.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Pessoa
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Progresso
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Data
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Lançado em
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Arquivo(s)
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Observações
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Ações
                                </Text>
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => {
                                const images = item.files
                                    .filter((file) => file.file.mimeType.includes('image'))
                                    .map((image) => ({
                                        url: image.file.url,
                                        urlThumb: image.file.urlThumb
                                    }));

                                const lightboxClasses = classNames('grid gap-2', images.length > 5 ? 'grid-cols-5' : `grid-cols-${images.length} mr-2`);

                                const files = item.files.filter((file) => !file.file.mimeType.includes('image'));

                                return (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className="px-6 py-2 border-b border-b-base-300 last:border-b-0" key={item.id}>
                                        <TableCell className="h-[48px] py-[5px]">
                                            <div className="flex items-center">
                                                <Icon name="ico-user-outline" width={15} className="mr-2" color={palette.grey[500]} />
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    {item.person.name}
                                                </Text>
                                            </div>
                                        </TableCell>
                                        <TableCell className="h-[48px] py-[5px]">
                                            {taskType === TaskType.Hour ? (
                                                <div className="flex items-center">
                                                    <Icon name="ico-clock" className="mr-2" color={palette.grey[500]} />
                                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                                        {item.hour || 0} hora(s)
                                                    </Text>
                                                </div>
                                            ) : (
                                                <div className="flex items-cente h-[48px]r">
                                                    <Icon name="ico-sort" className="mr-2" color={palette.grey[500]} />
                                                    <Text as="span" variant="body.regular.xs" className="text-heading">
                                                        {item.percentage || 0}%
                                                    </Text>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell className="h-[48px] py-[5px]">
                                            <div className="flex items-center">
                                                <Icon name="ico-calendar" className="mr-2" color={palette.grey[500]} />
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    {formatDate(item?.date)}
                                                </Text>
                                            </div>
                                        </TableCell>
                                        <TableCell className="h-[48px] py-[5px]">
                                            <div className="flex items-center">
                                                <Icon name="ico-calendar" className="mr-2" color={palette.grey[500]} />
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    {formatDate(item?.createdAt)}
                                                </Text>
                                            </div>
                                        </TableCell>
                                        <TableCell className="h-[48px] py-[5px]">
                                            {Boolean(files?.length) ? (
                                                <div className="flex flex-wrap items-center">
                                                    {Boolean(images.length) && (
                                                        <div className={lightboxClasses}>
                                                            <TaskLightbox className="!w-[18px] !h-[18px]" items={images} />
                                                        </div>
                                                    )}

                                                    {files.map((file) => {
                                                        return (
                                                            <Tooltip key={file.id} placement="top" title="Fazer download" disableFocusListener={true}>
                                                                <IconButton
                                                                    href={file.file.url}
                                                                    target="_blank"
                                                                    color="secondary"
                                                                    classes={{ root: 'p-1' }}
                                                                    sx={{ '&:hover': { backgroundColor: palette.grey[200] } }}>
                                                                    <Icon name="ico-text" width={18} color={palette.secondary[500]} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                '-'
                                            )}
                                        </TableCell>
                                        <TableCell className="h-[48px] py-[5px]">
                                            {Boolean(item.observations) && (
                                                <Tooltip placement="top" title="Ver observações" disableFocusListener={true}>
                                                    <IconButton color="secondary" sx={{ '&:hover': { backgroundColor: palette.grey[200] } }} onClick={handleToggleObservations(item.observations)}>
                                                        <Icon name="ico-arrow-right" width={18} color={palette.secondary[500]} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        {userAllowedToEditOrDelete === item.person.id && (
                                            <TableCell className="h-[48px] py-[5px]">
                                                <div className="flex">
                                                    <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                        <IconButton
                                                            TouchRippleProps={warningButton}
                                                            className="hover:bg-system-warning-100 hover:bg-opacity-30"
                                                            onClick={handleGoTo.bind(this, item.id)}>
                                                            <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                                        <IconButton
                                                            TouchRippleProps={dangerButton}
                                                            className="hover:bg-system-danger-100 hover:bg-opacity-30"
                                                            onClick={setTaskProgressIdToDelete.bind(this, item?.id)}>
                                                            <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Empty title="Nenhum informe de progresso cadastrado até o momento" className="p-6" />
            )}
            {Boolean(observations) && (
                <Modal contentClassnames="py-5 px-7 w-[500px]" onClose={handleToggleObservations(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: observations! }} />
                </Modal>
            )}
            {Boolean(taskProgressIdToDelete) && (
                <ConfirmModal
                    title="Apagar progresso"
                    description="Você tem certeza que deseja apagar este progresso?"
                    isLoading={isDeletingTaskProgress}
                    onClose={() => setTaskProgressIdToDelete(undefined)}
                    onConfirmAction={handleDeleteTaskProgress}
                />
            )}
        </div>
    );
};

export default TaskProgressList;
