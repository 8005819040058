import { useEffect, useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import useCreateOrUpdateDailyReport from 'services/queries/operationals/use-create-daily-report';
import useGetDailyReports from 'services/queries/operationals/use-get-daily-reports';
import useGetTimelineProviders from 'services/queries/operationals/use-get-providers';
import { ClassificationType } from 'types/models/classification';
import { DailyReportPayload } from 'types/models/organization';
import { formatDate } from 'utils/date';
import { OperationalDetailsPageContext } from '../..';
import CreateOrUpdateFollowup from './create-or-update';
import { createClassificationQuery, getDailyReportsQuery, providerQuery } from './utils';
import useGetFollowup from 'services/queries/operationals/use-get-followup';
import { omit } from 'lodash';

const CreateOrUpdateFollowupPage = () => {
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const { projectProposalTypeId, dailyReportId } = useParams();

    const navigate = useNavigate();

    const { data: lastDailyReport } = useGetDailyReports(getDailyReportsQuery(ctx?.projectProposalType?.id), ctx?.projectProposalType?.id);

    const providers = useGetTimelineProviders(providerQuery(ctx?.projectProposalType?.id), ctx?.projectProposalType?.id);

    const effectiveClassifications = useGetClassifications(createClassificationQuery(ClassificationType.Effective));
    const dailyClassifications = useGetClassifications(createClassificationQuery(ClassificationType.WeatherCondition));

    const { mutateAsync: createDailyReport, isLoading: isSubmitting } = useCreateOrUpdateDailyReport(ctx?.projectProposalType?.id, Number(dailyReportId));

    const { data: followupDetails } = useGetFollowup(+projectProposalTypeId!, dailyReportId);

    const isBuilding = useMemo(() => effectiveClassifications.isLoading || dailyClassifications.isLoading, [dailyClassifications.isLoading, effectiveClassifications.isLoading]);

    useEffect(() => {
        if (!providers.data?.length) {
            navigate(-1);
        }
    }, [providers, navigate]);

    const handleSubmit = async (data: DailyReportPayload) => {
        try {
            const payload: DailyReportPayload = {
                ...omit(data, 'id', 'person'),
                workDiaries: data.workDiaries.map((item) => ({ provider: item.provider, file: (item.file as any).id })),
                date: formatDate(data.date, 'DD/MM/YYYY'),
                order: (lastDailyReport || 0) + 1
            };

            await createDailyReport(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    const defaultValues = useMemo(
        () => ({
            ...omit(followupDetails, ['person'])
        }),
        [followupDetails]
    );

    return (
        <CreateOrUpdateFollowup
            providers={providers.data || []}
            effectiveClassifications={effectiveClassifications.data || []}
            dailyClassifications={dailyClassifications.data || []}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            defaultValues={defaultValues || null}
        />
    );
};

export default CreateOrUpdateFollowupPage;
