// Dependencies
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import { CamelCase } from 'types/utils';
import useToast from 'hooks/toast/use-toast';
import { ProjectMeetingRecord } from 'types/models/project';
import { MeetingRecordPayload } from 'contexts/meeting-record/types';
import { createOrUpdateMeetingRecordKey, getProjectMeetingRecordsKey } from './graphql';

const useCreateOrUpdateMeetingRecord = (meetingRecordId?: string, projectId?: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: Partial<MeetingRecordPayload>) => {
        const method = meetingRecordId ? api.patch : api.post;
        const endpoint = meetingRecordId ? `/meeting-records/${meetingRecordId}` : '/meeting-records';

        return method<CamelCase<ProjectMeetingRecord>>(endpoint, payload).then((response) => response.data);
    };

    return useMutation(createOrUpdateMeetingRecordKey(meetingRecordId), fetcher, {
        onSuccess: () => {
            const params = {
                limit: 10,
                page: 1,
                offset: 0,
                orderBy: { date: 'desc' },
                where: {
                    project_id: { _eq: projectId }
                }
            };

            queryClient.invalidateQueries(getProjectMeetingRecordsKey(params, projectId));
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar a ata de reunião', 'danger');
        }
    });
};

export default useCreateOrUpdateMeetingRecord;
