// Helpers
import { CRUDDetails } from 'types/graphql';
import { Company } from 'types/models/company';
import useGetDetails from '../crud/use-get-details';
import customer from './graphql';

type Response = {
    customer: Partial<Company>;
};

const useGetCustomer = (queryFields: string, customerId?: string) => {
    const config: CRUDDetails<Response> = {
        table: 'customer',
        query: customer.getCustomer(queryFields),
        formatResponse: ({ customer }) => ({
            ...customer,
            juridic_name: customer?.juridic_name || '',
            juridic_document: customer?.juridic_document || ''
        })
    };

    return useGetDetails<Response>(config, customerId);
};

export default useGetCustomer;
