import React, { useState } from 'react';
import Text from 'components/core/text';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import AddButton from 'components/buttons/add';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Empty from 'components/empty';
import { DailyReport } from 'types/models/organization';
import { formatDate } from 'utils/date';
import ExternalButton from 'components/buttons/external';
import Modal from 'components/core/modal';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import { useQueryClient } from 'react-query';
import useDelete from 'services/queries/crud/use-delete';
import ConfirmModal from 'components/core/modal/confirm';
import { getFollowupsKey } from 'services/queries/operationals/graphql';
import useGetTimelineProviders from 'services/queries/operationals/use-get-providers';
import { providerQuery } from '../create-or-update/utils';
import { OperationalDetailsPageContext } from '../..';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

type FollowupListProps = {
    followups: DailyReport[];
    isConstructionStarted: boolean;
    withActions?: boolean;
};

const infoButton = { classes: { child: 'text-system-info-100' } };
const warningButton = { classes: { child: 'text-system-warning-100' } };
const dangerButton = { classes: { child: 'text-system-danger-100' } };

const FollowupList = ({ isConstructionStarted, followups, withActions }: FollowupListProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { projectProposalTypeId } = useParams();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const [observations, setObservations] = useState<string>();
    const [followupToDeleteId, setFollowupToDeleteId] = useState<number | undefined>();

    const { data: providers } = useGetTimelineProviders(providerQuery(ctx?.projectProposalType?.id), ctx?.projectProposalType?.id);

    const queryClient = useQueryClient();

    const { mutateAsync: deleteItem, isLoading: isSubmitting } = useDelete({
        apiPayload: { endpoint: `/project-types/${projectProposalTypeId}/daily-reports`, useApiTodelete: true }
    });

    const handleGoTo = (path: string) => () => navigate(path);

    const handleDelete = async () => {
        try {
            if (followupToDeleteId) {
                await deleteItem({ id: followupToDeleteId });

                setFollowupToDeleteId(undefined);
                queryClient.invalidateQueries(getFollowupsKey(+projectProposalTypeId!));
            }
        } catch (error) {
            console.log('handleDeleteDocument', error);
        }
    };

    if (!providers?.length) {
        return (
            <>
                <Empty title="Nenhum cronograma cadastrado ou iniciado." buttonLabel="Criar ou finalizar a crição de um cronograma" onClick={handleGoTo('../cronogramas')} />
            </>
        );
    }

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <Text as="h6" variant="h6" className="text-heading mb-4">
                Acompanhamentos diários
            </Text>

            {Boolean(followups.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Ordem
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Data
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Condição climática
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Observações
                                    </Text>
                                </TableCell>
                                {withActions && (
                                    <TableCell>
                                        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                            Ações
                                        </Text>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {followups.map((item, index) => {
                                return (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                #{item.order.toString().padStart(3, '0')}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {formatDate(item.date)}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {item.classification.name}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            {item.observations ? (
                                                <ExternalButton onClick={() => setObservations(item.observations)}>Visualizar</ExternalButton>
                                            ) : (
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    -
                                                </Text>
                                            )}
                                        </TableCell>
                                        {withActions && (
                                            <TableCell>
                                                <div className="flex">
                                                    <Tooltip placement="top" title="Visualizar">
                                                        <IconButton TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20" onClick={handleGoTo(`${item.id}`)}>
                                                            <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                        <IconButton
                                                            TouchRippleProps={warningButton}
                                                            className="hover:bg-system-warning-100 hover:bg-opacity-30"
                                                            onClick={handleGoTo(`${item.id}/editar`)}>
                                                            <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                                        <IconButton
                                                            TouchRippleProps={dangerButton}
                                                            className="hover:bg-system-danger-100 hover:bg-opacity-30"
                                                            onClick={() => setFollowupToDeleteId(item.id)}>
                                                            <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {isConstructionStarted && (
                            <TableFooter>
                                <TableRow>
                                    <TableCell className="py-2 border-none" colSpan={6}>
                                        <AddButton linkComponent={Link} to="novo">
                                            Novo acompanhamento diário
                                        </AddButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </TableContainer>
            ) : (
                <Empty title="Nenhum acompanhamento encontrado." buttonLabel="Criar acompanhamento diário" onClick={handleGoTo('novo')} />
            )}
            {Boolean(observations) && (
                <Modal contentClassnames="py-5 px-7 w-[500px]" onClose={() => setObservations(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: observations! }} />
                </Modal>
            )}
            {Boolean(followupToDeleteId) && (
                <ConfirmModal
                    title="Apagar acompanhamento"
                    description="Você tem certeza que deseja apagar este acompanhamento?"
                    onClose={() => setFollowupToDeleteId(undefined)}
                    isLoading={isSubmitting}
                    onConfirmAction={handleDelete}
                />
            )}
        </>
    );
};

export default FollowupList;
