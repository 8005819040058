import React from 'react';

// Dependencies
import { Outlet, useParams } from 'react-router-dom';

// Components
import Empty from 'components/empty';
import Spinner from 'components/core/spinner';

// Helpers
import useGetChecklist from 'services/queries/projects/use-get-checklist';
import useProjectTypeSlug from 'pages/private/operationals/hooks/use-project-type-slug';

const Checklist = () => {
    const { isManagement } = useProjectTypeSlug();
    const { projectProposalTypeId } = useParams();

    const checklist = useGetChecklist(isManagement, projectProposalTypeId);

    if (checklist.isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (Boolean(checklist.error) || !checklist.data) {
        return <Empty title="Checklist não encontrado" />;
    }

    return <Outlet context={{ ...checklist.data, isLoading: checklist.isLoading }} />;
};

export default Checklist;
