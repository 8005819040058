import React, { useMemo } from 'react';

import theme from 'settings/theme';
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';
import { lineChartOptions } from './utils';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend, ChartData, PointElement, LineElement } from 'chart.js';

type LineChartProps = {
    title?: string;
    colors: string[];
    datasets: ChartData<'line', number[], any>['datasets'];
    labels: any;
    className?: string;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ colors, datasets, labels, title, className }: LineChartProps) => {
    const containerClasses = classNames('w-full max-h-[516px]', className);

    const dataFormatted = useMemo<ChartData<'line', number[], string>>(() => {
        return {
            labels,
            datasets: datasets.map((item, index) => ({
                ...item,
                borderColor: colors[index],
                pointBackgroundColor: theme.extend.colors.base[100],
                pointBorderColor: colors[index],
                pointBorderWidth: 2
            }))
        };
    }, [datasets, labels, colors]);

    return (
        <div className={containerClasses}>
            <Line options={lineChartOptions(title, 100)} data={dataFormatted} className="!max-w-full" updateMode="resize" />
        </div>
    );
};

export default LineChart;
