import { useMutation, useQueryClient } from 'react-query';

import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { createAssetKey } from './graphql';
import { GraphqlPaginationVariables } from 'types/graphql';
import { useShowApiErrors } from 'hooks/error/show-api-errors';
import { CreateAssetPayload } from 'types/models/assets';
import apiGraphQl from 'services/graphql';

type ResponseAssetMutation = {
    item: {
        id: number;
    };
};

const useCreateOrUpdateAsset = (assetId: string | undefined, params: GraphqlPaginationVariables<CreateAssetPayload>, query: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();

    const request = async (payload: CreateAssetPayload) => {
        const data = {
            ...(assetId && { id: assetId }),
            payload
        };
        const { item } = await apiGraphQl<ResponseAssetMutation>(query, data);

        return item?.id;
    };

    return useMutation(createAssetKey, request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('asset', params));

            const statusEditUpdate = assetId ? 'atualizado' : 'criado';
            showToast(`Ativo ${statusEditUpdate} com sucesso`, 'success');
        },
        onError: (err) => {
            showToastErrors(err);
        }
    });
};

export default useCreateOrUpdateAsset;
