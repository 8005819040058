// Dependencies
import { useState } from 'react';

// Helpers
import useToast from 'hooks/toast/use-toast';
import api from 'services/api';
import { FileApiModel } from 'types/models/file';

const useUploadFile = (cb?: (file: FileApiModel) => void) => {
    const [error, setError] = useState(false);
    const [file, setFile] = useState<FileApiModel>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { showToast } = useToast();

    const uploadFile = async (payload: any, callback?: (file: FileApiModel) => void, throwOnError = false) => {
        try {
            setIsLoading(true);

            const formData = new FormData();

            formData.append('file', payload);

            const { data } = await api.post<FileApiModel>(`/files`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            setFile(data);
            setIsSuccess(true);
            setError(false);

            const cbFunction = cb ?? callback;

            if (cbFunction) {
                cbFunction(data);
            }

            return data;
        } catch (error: any) {
            console.log('error', error);

            setError(true);
            setIsSuccess(false);
            setFile(undefined);

            if (throwOnError) {
                showToast('Tivemos um problema ao fazer upload da imagem. Tente novamente.', 'danger');

                throw error;
            }
        } finally {
            setIsLoading(false);
        }
    };

    return {
        error,
        file,
        isLoading,
        isSuccess,
        uploadFile
    };
};

export default useUploadFile;
