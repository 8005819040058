import { RouteObject } from 'react-router-dom';
import MainPage from 'pages/private/main';
import LoginPage from 'pages/public/login';
import ForgotPasswordPage from 'pages/public/forgot-password';

import PRIVATE_ROUTES from './private';
import ErrorPage from 'pages/shared/error';
import ResetPasswordPage from 'pages/public/reset-password';
import PrivacyPolicyPage from 'pages/public/privacy-policy';
import TermsOfUsePage from 'pages/public/terms';
import DeleteSystemAccountPage from 'pages/public/delete-system-account';

const routes: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: null,
        path: '/',
        element: <LoginPage />
    },
    {
        path: 'esqueceu-senha',
        element: <ForgotPasswordPage />
    },
    {
        path: 'esqueceu-senha/:token',
        element: <ResetPasswordPage />
    },
    {
        path: 'politica-de-privacidade',
        element: <PrivacyPolicyPage />
    },
    {
        path: 'termos-de-uso',
        element: <TermsOfUsePage />
    },
    {
        path: 'exclusao-de-conta',
        element: <DeleteSystemAccountPage />
    },
    {
        // @ts-ignore
        breadcrumb: null,
        path: 'app',
        element: <MainPage />,
        children: PRIVATE_ROUTES,
        errorElement: <ErrorPage title="Ops!" subtitle="Tivemos um problema" />
    },
    {
        path: '*',
        element: <ErrorPage subtitle="Página não encontrada." description="Esta página não existe mais ou foi movida para outro lugar." />,
        errorElement: <ErrorPage subtitle="Página não encontrada." description="Esta página não existe mais ou foi movida para outro lugar." />
    }
];

export default routes;
