import { useMemo } from 'react';

import { useOutletContext, useParams } from 'react-router-dom';

import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';

import { CreateTypeBusinessPayload } from 'types/models/configurations-classifications';

import { createOrUpdateTypeBusinessMutation } from '../utils/utils';
import useCreateOrUpdateTypeBusiness from 'services/queries/configs-classifications/use-create-update-type-business';
import CreateOrUpdateTypeBusinessPage from './create-or-update';
import useGetTypeBusinessById from 'services/queries/configs-classifications/use-get-type-business-by-id';

const CreateOrUpdateConfigTypeBusinessPage = ({ title }: CrudPageProps) => {
    const { configTypeBusinessId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<CreateTypeBusinessPayload>>();

    const {
        mutateAsync: mutateTypeBusiness,
        isLoading: isSubmitting,
        error: errorCreateUpdate
    } = useCreateOrUpdateTypeBusiness(configTypeBusinessId, params, createOrUpdateTypeBusinessMutation(configTypeBusinessId));

    const { data: typeBusinessDetails, isLoading: isLoadingTypeBusiness, error: errorGetTypeBusinessById } = useGetTypeBusinessById(configTypeBusinessId);

    const error = useMemo(() => errorCreateUpdate || errorGetTypeBusinessById, [errorCreateUpdate, errorGetTypeBusinessById]);

    const isBuilding = useMemo(() => isSubmitting || isLoadingTypeBusiness, [isSubmitting, isLoadingTypeBusiness]);

    const handleSubmit = async (data: CreateTypeBusinessPayload) => {
        try {
            await mutateTypeBusiness(data);
        } catch (error) {
            console.log('error', error);
        }
    };

    return <CreateOrUpdateTypeBusinessPage isBuilding={isBuilding} isSubmitting={isSubmitting} onSubmit={handleSubmit} defaultValues={typeBusinessDetails} error={error} title={title || ''} />;
};

export default CreateOrUpdateConfigTypeBusinessPage;
