// Dependencies
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { createTaskKey, getChecklistKey } from './graphql';
import { TaskPayload } from 'pages/private/projects/tasks/create-or-update';

const useCreateOrUpdateTask = (projectProposalTypeId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async ({ taskId, ...payload }: TaskPayload) => {
        const method = taskId ? api.patch : api.post;
        const endpoint = taskId ? `/tasks/${taskId}` : '/tasks';

        return method<any>(endpoint, payload);
    };

    return useMutation(createTaskKey, fetcher, {
        onSuccess: (_, variables) => {
            const message = variables.taskId ? 'Tarefa editada' : 'Tarefa criada';

            showToast(message, 'success');

            queryClient.invalidateQueries(getChecklistKey(projectProposalTypeId));
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar a tarefa', 'danger');
        }
    });
};

export default useCreateOrUpdateTask;
