import { useEffect, useState } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'components/core/datepicker';
import Editor from 'components/core/form/editor';
import Button from 'components/core/button';
import Files from './files';
import { FileApiModel } from 'types/models/file';
import Input from 'components/core/form/input';
import { TaskStatus, TaskType } from 'types/models/task';
import { timeToMinutes } from 'utils/date';
import Switch from 'components/core/switch';
import hexToRgba from 'utils/hex-to-rgba';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import { taskProgressSchema } from './utils';
import { isEmpty } from 'lodash';

type TaskProgressProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    isDashboardTasksPage: boolean;
    taskType?: TaskType;
    taskStatus?: TaskStatus['slug'];
    onSubmitWithProgress: (data: TaskProgressPayload) => void;
    onSubmitWithoutProgress: () => void;
    defaultValues?: Pick<TaskProgressPayload, 'date' | 'strHour' | 'observations'>;
};

export type TaskProgressPayload = {
    date?: string;
    strPercentage?: string;
    strHour?: string;
    files?: number[];
    images?: number[];
    observations?: string;
    accomplishedTime?: number;
};

const accept = {
    'image/png': [],
    'image/jpeg': []
};

const TaskProgress = ({ isBuilding, isDashboardTasksPage, isSubmitting, taskType, taskStatus, defaultValues, onSubmitWithProgress, onSubmitWithoutProgress }: TaskProgressProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();

    const [isFinishingWithoutProgress, setIsFinishingWithoutProgress] = useState(false);
    const filesHook = useState<Array<Partial<File & FileApiModel>>>([]);
    const imagesHook = useState<Array<Partial<File & FileApiModel>>>([]);
    const taskProgressId = useGetQueryParam('taskProgressId');

    const handleBack = () => navigate(-1);

    const { control, formState, reset, handleSubmit } = useForm<TaskProgressPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(taskProgressSchema(taskType)),
        defaultValues: {
            date: '',
            ...(taskType === TaskType.Hour && {
                strHour: ''
            })
        }
    });

    const submit = (data: TaskProgressPayload) => {
        const [files] = filesHook;
        const [images] = imagesHook;

        const filesIds = files.map((item) => item.id || 0).filter(Boolean);
        const imagesIds = images.map((item) => item.id || 0).filter(Boolean);

        onSubmitWithProgress({
            date: data.date,
            observations: data.observations,
            strPercentage: data.strPercentage,
            ...(Boolean(data.strHour) && { accomplishedTime: timeToMinutes(data.strHour) }),
            ...(Boolean(filesIds.length) && { files: filesIds }),
            ...(Boolean(imagesIds.length) && { images: imagesIds })
        });
    };

    const submitWithoutValidation = (e) => {
        e.preventDefault();
        onSubmitWithoutProgress();
    };

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    return (
        <Modal
            contentClassnames="w-[800px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {`${!!taskProgressId ? 'Editar' : 'Informar'}`} progresso
                </Text>
            }
            onClose={handleBack}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="px-3 sm:px-6 pb-6" onSubmit={isFinishingWithoutProgress ? submitWithoutValidation : handleSubmit(submit)}>
                    {isDashboardTasksPage && taskStatus === 'processing' && (
                        <div className="-mx-3 sm:-mx-6 px-7 py-5 mb-5" style={{ backgroundColor: hexToRgba(palette.secondary[100], 0.2) }}>
                            <div className="flex flex-wrap gap-2 justify-between items-center">
                                <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                                    Deseja finalizar esta tarefa sem registrar progresso?
                                </Text>
                                <Switch value={isFinishingWithoutProgress} onChange={setIsFinishingWithoutProgress} />
                            </div>
                        </div>
                    )}
                    <div className={`relative ${isFinishingWithoutProgress ? 'opacity-50' : 'opacity-100'}`}>
                        {isFinishingWithoutProgress && <div className="absolute left-0 top-0 z-10 w-full h-full" />}
                        <div className="grid grid-cols-1 sm:grid-cols-2 border border-base-300 rounded-2xl p-4 mb-9 gap-4">
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => <DatePicker label="Data de lançamento" placeholderText="Selecione uma data" error={formState.errors.date?.message} {...field} />}
                            />
                            {taskType === TaskType.Hour ? (
                                <Controller
                                    name="strHour"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            autoComplete="nope"
                                            placeholder="Ex: 10"
                                            type="time"
                                            label="Quantidade de horas"
                                            error={formState.errors.strHour?.message}
                                            right={
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    hora(s)
                                                </Text>
                                            }
                                            rightClasses="bg-base-200 px-4"
                                        />
                                    )}
                                />
                            ) : (
                                <Controller
                                    name="strPercentage"
                                    control={control}
                                    render={({ field }) => (
                                        <Input {...field} autoComplete="nope" placeholder="Ex: 99,99" label="Percentual de progresso" error={formState.errors.strPercentage?.message} />
                                    )}
                                />
                            )}
                        </div>
                        {!taskProgressId && (
                            <>
                                <Files hook={imagesHook} accept={accept} label="Imagens destacadas" />
                                <Files hook={filesHook} label="Arquivos" />
                            </>
                        )}
                        <Controller name="observations" control={control} render={({ field }) => <Editor {...field} label="Observações" parentClasses="mb-6" />} />
                    </div>
                    <div className="flex items-center mt-6">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default TaskProgress;
