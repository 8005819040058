// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { Customer } from 'types/models/customer';
import { CompanyPayload } from 'types/models/company';
import { createCustomerKey } from './graphql';
import { GraphqlPaginationVariables } from 'types/graphql';
import api from 'services/api';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

const useCreateCustomer = (params: GraphqlPaginationVariables<Customer>) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();

    const createCustomer = async (payload: CompanyPayload) => api.post('/customers', payload);

    return useMutation(createCustomerKey(), createCustomer, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('customer', params));

            navigate(-1);

            showToast('Cliente criado com sucesso', 'success');
        },
        onError: (err) => {
            showToastErrors(err);
        }
    });
};

export default useCreateCustomer;
