import React, { Fragment } from 'react';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { PhysicalFinancial } from 'types/models/report';
import { formatMoney } from 'utils/money';

const PhysicalFinancialTable = ({ measurements, providers }: Partial<Omit<PhysicalFinancial, 'graphic'>>) => {
    const count = (providers?.length || 0) * 2 + 1;

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                {Boolean(providers?.length) && (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow className="uppercase bg-base-300">
                                    <TableCell align="center" colSpan={2}>
                                        Contrato
                                    </TableCell>
                                </TableRow>
                                <TableRow className="uppercase bg-base-200">
                                    <TableCell align="center">Fornecedor</TableCell>
                                    <TableCell align="center">R$ valor</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {providers?.map((item, index) => {
                                    return (
                                        <TableRow key={index} sx={{ '&:last-child td': { border: 0 } }}>
                                            <TableCell align="center">{item.provider.title}</TableCell>
                                            <TableCell align="center">{formatMoney(Number(item.budget.value))}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={2} className="uppercase bg-base-300">
                                        Resultado Alcançado Físico Financeiro
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" className="bg-base-200" />
                                    <TableCell align="center" className="bg-base-200">
                                        (%) percentual
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {providers?.map((item, index) => {
                                    return (
                                        <TableRow key={index} sx={{ '&:last-child td': { border: 0 } }}>
                                            <TableCell align="center">Acumulado {item.provider.title}</TableCell>
                                            <TableCell align="center">{item.accumulated}%</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
            {Boolean(measurements?.length) && (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className="uppercase bg-base-300">
                                <TableCell align="center" colSpan={count}>
                                    Avanço físico financeiro
                                </TableCell>
                            </TableRow>
                            <TableRow className="bg-base-200">
                                <TableCell align="center" rowSpan={2} classes={{ root: 'border-r border-r-base-300' }}>
                                    Medição
                                </TableCell>
                                <TableCell align="center" colSpan={count - 1} padding="none">
                                    <Table>
                                        <TableRow>
                                            {providers?.map((item) => (
                                                <TableCell align="center" colSpan={2} key={item.provider.id}>
                                                    {item.provider.title}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            {providers?.map((item) => (
                                                <Fragment key={item.provider.id}>
                                                    <TableCell align="center" classes={{ root: 'border-b-[0] border-r border-r-base-300' }}>
                                                        Valor medido
                                                    </TableCell>
                                                    <TableCell align="center" classes={{ root: 'border-none' }}>
                                                        Saldo contrato
                                                    </TableCell>
                                                </Fragment>
                                            ))}
                                        </TableRow>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {measurements?.map((item) => {
                                return (
                                    <TableRow key={item.order} sx={{ '&:last-child td': { border: 0 } }}>
                                        <TableCell align="center">{item.order}ª</TableCell>
                                        {item.providers.map((prov) => (
                                            <>
                                                <TableCell align="center">{prov.measuredValue}</TableCell>
                                                <TableCell align="center">{prov.contractBalance}</TableCell>
                                            </>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default PhysicalFinancialTable;
