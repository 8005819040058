import React, { useCallback } from 'react';
import Text from 'components/core/text';
import { Option } from 'types/general';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Input from 'components/core/form/input';
import { DailyReportPayload } from 'types/models/organization';

type EffectiveProps = {
    effectiveClassifications: Option[];
    providers: Option[];
};

const Effective = ({ effectiveClassifications, providers }: EffectiveProps) => {
    const { control, formState, watch } = useFormContext<DailyReportPayload>();

    const { fields: effectives } = useFieldArray({ name: 'effective', control });

    const effective = watch('effective');

    const getTotal = useCallback(
        (fieldName: string, value: number) => () => effective.filter((field) => field[fieldName] === value).reduce((acc, curr) => acc + Number(curr.quantity), 0),
        [effective]
    );

    const bigTotal = effective.reduce((acc, curr) => acc + Number(curr.quantity), 0);

    return (
        <div className="border border-base-300 rounded-[14px] py-4 px-2 sm:p-4 mb-4">
            <Text as="h6" variant="h6" className="text-heading mb-2">
                Efetivo
            </Text>
            <div className="overflow-x-hidden">
                <div className="overflow-x-auto">
                    <div className="flex border border-base-300 rounded-[14px] min-w-max">
                        <div className="flex flex-col border-r border-r-base-300">
                            <Text as="span" className="bg-base-200 p-4 text-secondary-500 font-semibold sticky left-0 top-0 rounded-tl-[14px]">
                                Função
                            </Text>
                            {effectiveClassifications.map((item) => {
                                return (
                                    <div key={item.value} className="h-[49px] px-4 flex items-center border-b border-base-300 last:border-b-0">
                                        <Text as="span" className="text-base-500 block">
                                            {item.label}
                                        </Text>
                                    </div>
                                );
                            })}
                            <Text as="span" className="bg-base-200 p-4 text-base-700 uppercase font-bold rounded-bl-[14px]">
                                Total
                            </Text>
                        </div>
                        {providers.map((item) => {
                            const total = getTotal('provider', item.value)();

                            return (
                                <div key={item.value} className="flex flex-col flex-1 border-r border-r-base-300">
                                    <Text as="span" className="bg-base-200 p-4 text-secondary-500 font-semibold sticky left-0 top-0">
                                        {item.label}
                                    </Text>
                                    {effectives.map((option, index) => {
                                        if (option.provider !== item.value) {
                                            return null;
                                        }

                                        return (
                                            <div key={option.id} className="border-b border-base-300 last:border-b-0">
                                                <Controller
                                                    name={`effective.${index}.quantity`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            placeholder="Ex: 10"
                                                            error={formState.errors.effective?.[index]?.quantity?.message}
                                                            inpuWrapperClasses="rounded-none border-0"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        );
                                    })}
                                    <Text as="span" className="bg-base-200 p-4 text-base-700 uppercase font-bold">
                                        {total || 0}
                                    </Text>
                                </div>
                            );
                        })}
                        <div className="flex flex-col">
                            <Text as="span" className="bg-base-200 p-4 text-secondary-500 font-semibold sticky left-0 top-0 rounded-tr-[14px]">
                                Efetivo total
                            </Text>
                            {effectiveClassifications.map((item) => {
                                const total = getTotal('classification', item.value)();

                                return (
                                    <div key={item.value} className="h-[49px] px-4 flex items-center border-b border-base-300 last:border-b-0">
                                        <Text as="span" className="text-base-500">
                                            {total || 0}
                                        </Text>
                                    </div>
                                );
                            })}
                            <Text as="span" className="bg-base-200 p-4 text-base-700 uppercase font-bold rounded-br-[14px]">
                                {bigTotal || 0}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Effective;
