import { useQuery } from 'react-query';
import api from 'services/api';
import { getTaskProgressKey } from './graphql';
import { TaskProgress } from 'types/models/task';

type TaskProgressResponse = TaskProgress & {
    hour?: string;
};

const useGetTaskProgress = (taskId?: number, taskProgressId?: number) => {
    const endpoint = `tasks/${taskId}/progress/${taskProgressId}`;

    const request = async () => {
        const { data } = await api.get<TaskProgressResponse>(endpoint);

        return data;
    };

    return useQuery(getTaskProgressKey(taskId, taskProgressId), request, {
        enabled: !!taskProgressId && !!taskId
    });
};

export default useGetTaskProgress;
