import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import { GraphqlPaginationVariables } from 'types/graphql';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

import apiGraphQl from 'services/graphql';
import { CreateTypeBusinessPayload } from 'types/models/configurations-classifications';
import { getAllKey } from 'services/queries/crud/graphql';

const useCreateOrUpdateTypeBusiness = (id?: string, params?: GraphqlPaginationVariables<CreateTypeBusinessPayload>, query?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { showToastErrors } = useShowApiErrors();

    const fetcher = async (payload: CreateTypeBusinessPayload) => {
        const data = {
            ...(id && { id }),
            payload
        };
        return await apiGraphQl(query!, data);
    };

    return useMutation(fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('type_business', params));

            navigate(-1);
            const statusEditUpdate = id ? 'atualizado' : 'criado';
            showToast(`Tipo de negócio ${statusEditUpdate} com sucesso`, 'success');
        },
        onError: (err) => {
            showToastErrors(err);
        }
    });
};

export default useCreateOrUpdateTypeBusiness;
