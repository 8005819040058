import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';
import operational, { getClassificationOptionsKey } from './graphql';
import { ClassificationType } from 'types/models/classification';
import { Option } from 'types/general';

type Response = { classification: Option[]; children_classifications?: Option[] };

const useGetClassificationOptions = (classficationType: ClassificationType, where?: any, enabled = true) => {
    const request = async () => apiGraphQl<Response>(operational.getClassificationsByType(classficationType, where)).then(({ classification }) => classification);

    return useQuery(getClassificationOptionsKey(classficationType), request, { enabled });
};

export default useGetClassificationOptions;
