import React, { forwardRef } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { FormFactoryField } from 'types/form-factory';
import Input from '../input';

type Props = ControllerRenderProps<any> & FormFactoryField & { error?: string };

const FormFactoryItem = forwardRef(({ type, ...field }: Props, ref: any) => {
    switch (type) {
        case 'text':
        case 'number':
            return <Input type={type} {...field} ref={ref} />;

        default:
            return null;
    }
});

export default FormFactoryItem;
