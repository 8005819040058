import { useEffect, useMemo } from 'react';
import { MONTH_WIDTH } from '../../utils';
import classNames from 'classnames';
import { HeaderText, TimelineVariant } from '../..';
import dayjs from 'dayjs';

type Props = {
    item: HeaderText;
    timelineVariant: TimelineVariant;
};

/**
 * This component is responsible for displaying the vertical bars of the calendar
 */
const BodyMonth = ({ timelineVariant, item }: Props) => {
    const isActive = useMemo<boolean>(() => {
        const today = dayjs();

        const { legend: year, title: month, legendDays } = item;

        const isSameYear = Number(year) === today.get('year');

        const isSameMonth = month === today.format('MMMM').toUpperCase();

        const includesToday = !!legendDays?.map((day) => day.format('DD/MM'))?.includes(today?.format('DD/MM'));

        switch (timelineVariant) {
            case 'MONTHS':
                return isSameYear && isSameMonth;

            case 'WEEKS':
                return isSameYear && isSameMonth && includesToday;

            default:
                return false;
        }
    }, [timelineVariant, item]);

    useEffect(() => {
        if (isActive) {
            document.getElementById('active-month')?.scrollIntoView({
                behavior: 'smooth',
                inline: 'center'
            });
        }
    }, [isActive]);

    return (
        <div
            {...(isActive && {
                id: 'active-month'
            })}
            className={classNames('relative flex items-center h-full border-slate-200 border-r hover:bg-slate-100', isActive && 'bg-slate-100/30')}
            style={{ width: MONTH_WIDTH, maxWidth: MONTH_WIDTH, flex: '1 0 auto' }}></div>
    );
};

export default BodyMonth;
