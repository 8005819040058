// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Modify, Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { GraphqlPagination } from 'types/graphql';
import project, { projectMeasuresOptionsKey } from './graphql';

export type MeasureOption = Modify<Option, { slug: string }>;

const useGetProjectMeasuresOptions = () => {
    const getProjectMeasuresOptions = () => apiGraphQl<GraphqlPagination<MeasureOption>>(project.getProjectMeasuresOptions).then((data) => data.items);

    return useQuery(projectMeasuresOptionsKey, getProjectMeasuresOptions);
};

export default useGetProjectMeasuresOptions;
