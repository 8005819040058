import { useNavigate } from 'react-router-dom';

import { ProjectTypeAccordion, ProjectTypeProps } from '../types';
import useGetProjectProposalType from 'services/queries/projects/use-get-project-proposal-type';
import ProjectContent from '../project/project';

const ComplementaryProject = ({ activeAccordion, projectTypeId, projectProposalId, projectProposalTypeId, onToggleAccordion }: ProjectTypeProps) => {
    const navigate = useNavigate();

    const { data: projectProposalType, isLoading } = useGetProjectProposalType(projectProposalTypeId);

    const handleGoToCreateProposalType = () => navigate(`proposta/${projectProposalId}/tipo/${projectTypeId}`);

    return (
        <ProjectContent
            title="Projetos Complementares"
            projectTypeAccordion={ProjectTypeAccordion.ComplementaryProject}
            activeAccordion={activeAccordion}
            isLoading={isLoading}
            projectProposalType={projectProposalType}
            onCreateProposalType={handleGoToCreateProposalType}
            onToggleAccordion={onToggleAccordion}
        />
    );
};

export default ComplementaryProject;
