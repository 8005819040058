import { useParams } from 'react-router-dom';
import useGetBranchesOptions from 'services/queries/branches/use-get-branches-options';
import useGetPersonContract from 'services/queries/person-contract/use-get-use-person-contract';
import usePersonContractMutation from 'services/queries/person-contract/use-person-contract-mutation';
import { CreatePersonContractPayload } from 'types/models/person';
import { formatDate } from 'utils/date';
import PersonDetailsContractsCreateForm from './form';

const CreateOrUpdatePersonDetailsContractsPage = () => {
    const { contractId, personId } = useParams();

    const title = `${!!contractId ? 'Editar' : 'Novo'} Contrato`;

    const { data: branches = [], isLoading: isLoadingBranches } = useGetBranchesOptions();

    const { mutateAsync: mutatePersonContract, isLoading: isSubmitting } = usePersonContractMutation({ personId, contractId });

    const { data: personContract, isLoading: isLoadingDetails } = useGetPersonContract<Partial<CreatePersonContractPayload>>({ contractId });

    const isBuilding = isLoadingBranches || isLoadingDetails;

    const handleSubmit = async (data: CreatePersonContractPayload) => {
        try {
            const payload = {
                ...data,
                startDate: formatDate(data.startDate, 'DD/MM/YYYY'),
                finalDate: !!data.finalDate ? formatDate(data.finalDate, 'DD/MM/YYYY') : null
            };

            await mutatePersonContract(payload);
        } catch (e) {
            console.error('Error:', e);
        }
    };

    const defaultValues = {
        ...personContract,
        juridicName: '',
        juridicFantasyName: '',
        juridicDocument: ''
    };

    return (
        <PersonDetailsContractsCreateForm
            isCreate={!contractId}
            branches={branches}
            isBuilding={isBuilding}
            title={title}
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
        />
    );
};

export default CreateOrUpdatePersonDetailsContractsPage;
