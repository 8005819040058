import { useQuery } from 'react-query';
import { getPeopleByProjectOptionsKey } from './graphql';
import api from 'services/api';
import { Person } from 'types/models/person';

const useGetPeopleByProjectOptions = (projectId?: number) => {
    const request = async () => {
        return api.get<Person[]>(`/projects/${projectId}/people`).then(({ data }) => {
            return data.map((item) => ({
                value: item.id,
                label: item.title || item.name,
                manager: item.manager
            }));
        });
    };

    return useQuery(getPeopleByProjectOptionsKey(projectId), request, { enabled: Boolean(projectId) });
};

export default useGetPeopleByProjectOptions;
