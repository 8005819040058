import React, { useCallback } from 'react';

// Dependencies
import { useNavigate } from 'react-router-dom';

// Components
import BIDContent from './bid';

// Helpers
import { ProjectTypeProps } from '../types';
import useGetProjectProposalType from 'services/queries/projects/use-get-project-proposal-type';

const BID = ({ activeAccordion, projectProposalId, projectProposalTypeId, projectTypeId, onToggleAccordion }: ProjectTypeProps) => {
    const navigate = useNavigate();
    const { data: projectProposalType, isLoading } = useGetProjectProposalType(projectProposalTypeId);

    const handleGoToCreateProposalType = useCallback(() => navigate(`proposta/${projectProposalId}/tipo/${projectTypeId}`), [navigate, projectProposalId, projectTypeId]);

    return (
        <BIDContent
            activeAccordion={activeAccordion}
            isLoading={isLoading}
            projectProposalType={projectProposalType}
            onCreateProposalType={handleGoToCreateProposalType}
            onToggleAccordion={onToggleAccordion}
        />
    );
};

export default BID;
