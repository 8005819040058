import { Status } from 'types/general';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

export type QueryOrderBy = { changedColumn: string; direction: string };
export type QueryPagination = { limit: number; offset: number };

export const getSolicitationsQuery = (projectProposalTypeId: number, pagination: QueryPagination, orderBy: QueryOrderBy) => {
    const where = {
        status: { _eq: Status.Active },
        project_proposal_type_id: { _eq: projectProposalTypeId }
    };

    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetAllSolicitations',
                items: {
                    __aliasFor: 'project_type_solicitation',
                    __args: {
                        where,
                        order_by: {
                            [orderBy.changedColumn]: new EnumType(orderBy.direction)
                        },
                        limit: pagination.limit,
                        offset: pagination.offset
                    },
                    id: true,
                    number: true,
                    subject: true,
                    person: {
                        id: true,
                        name: true
                    }
                },
                pagination: {
                    __aliasFor: 'project_type_solicitation_aggregate',
                    __args: { where },
                    aggregate: {
                        count: true
                    }
                }
            }
        },
        { pretty: true }
    );
};
