// Dependencies
import { useMutation } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { ProfilePageRule } from 'types/models/profile';
import queries, { createProfilePageRulesKey } from './graphql';

type ProfilePageRulePayload = Array<Pick<ProfilePageRule, 'page_id' | 'rule_id' | 'profile_id'>>;

const useCreateProfilePageRule = () => {
    const createProfilePageRule = (payload: ProfilePageRulePayload) => apiGraphQl(queries.createProfilePageRules, { payload });

    return useMutation(createProfilePageRulesKey, createProfilePageRule);
};

export default useCreateProfilePageRule;
