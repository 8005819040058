import React from 'react';

// Dependencies
import { useOutletContext, useParams } from 'react-router-dom';

// Components
import ChecklistCreateOrUpdate from './checklist';

// Helpers
import { Timeline } from 'types/models/timeline';
import { TaskType } from 'components/task-tree/task';
import useCreateOrUpdateChecklist from 'services/queries/projects/use-create-or-update-checklist';

const ChecklistCreateOrUpdatePage = () => {
    const { projectProposalTypeId } = useParams();

    const timeline = useOutletContext<(Timeline & { isLoading: boolean }) | undefined>();
    const { mutateAsync: createOrUpdateChecklist, isLoading: isSubmitting } = useCreateOrUpdateChecklist(Number(projectProposalTypeId) || 0);

    const handleSubmit = async (data: { tasks: TaskType[] }) => {
        const payload = data.tasks.map((item, index) => ({
            ...item,
            order: index + 1,
            timeline: timeline?.id || 0,
            proposalType: Number(projectProposalTypeId) || 0,
            tasks: item.tasks.map((task, ix) => ({
                ...task,
                timeline: timeline?.id || 0,
                proposalType: Number(projectProposalTypeId) || 0,
                order: ix + 1
            }))
        }));

        await createOrUpdateChecklist(payload);
    };

    return <ChecklistCreateOrUpdate isSubmitting={isSubmitting} isLoading={timeline?.isLoading || false} timeline={timeline} onSubmit={handleSubmit} />;
};

export default ChecklistCreateOrUpdatePage;
