import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import dictionary from 'utils/dictionary';
import { array, date, mixed, number, object, string } from 'yup';

export type TimelineFormManagemnt = {
    provider: number;
    documents?: Array<{ file: number }>;
    startDate: string;
    endDate: string;
    items: Array<{
        title: string;
        endDate: string;
        startDate: string;
        items: Array<{
            title: string;
            endDate: string;
            startDate: string;
        }>;
    }>;
};

const schema = (isTimelineType: boolean) =>
    object({
        provider: isTimelineType ? number().required(dictionary.validation.required) : number(),
        documents: array(object({ file: number() })).optional(),
        items: array(
            object({
                title: string().required(dictionary.validation.required),
                startDate: date().required(dictionary.validation.required).typeError('Campo vazio'),
                endDate: date()
                    .required(dictionary.validation.required)
                    .typeError('Campo vazio')
                    .when('startDate', (startDate, schema) => {
                        return startDate ? schema.min(startDate, dictionary.validation.date.min).required(dictionary.validation.required) : schema;
                    }),
                items: mixed()
            })
        ).optional()
    });

export const useTimelineForm = (onSubmit: (data: TimelineFormManagemnt) => void, defaultValues: Partial<TimelineFormManagemnt>, isTimeline = false) => {
    const methods = useForm<TimelineFormManagemnt>({
        defaultValues,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema(isTimeline))
    });

    return {
        methods,
        handleSubmit: methods.handleSubmit(onSubmit)
    };
};

export const useTimelineFieldFormManagement = (prefix: string) => {
    const { control, formState, getValues, register, watch, setValue } = useFormContext<TimelineFormManagemnt>();

    const itemsArrayInputPath = `${prefix}items` as any;
    const titleInputPath = (ix: number) => `${prefix}items.${ix}.title` as any;
    const endDateInputPath = (ix: number) => `${prefix}items.${ix}.endDate` as any;
    const startDateInputPath = (ix: number) => `${prefix}items.${ix}.startDate` as any;

    const { fields, append, remove } = useFieldArray<any>({
        name: itemsArrayInputPath,
        control
    });

    const handleAdd = () => {
        return append({
            title: '',
            endDate: '',
            items: []
        });
    };

    const handleDelete = (itemIndex: number) => () => remove(itemIndex);

    return {
        fields,
        formState,
        endDateInputPath,
        startDateInputPath,
        titleInputPath,
        control,
        itemsArrayInputPath,
        getValues,
        handleAdd,
        handleDelete,
        register,
        watch,
        setValue
    };
};
