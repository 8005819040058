// Dependencies
import { array, boolean, number, object, string } from 'yup';

// Helpers
import dictionary from 'utils/dictionary';

const meetingParticipantSchema = object({
    internal: array(
        object({
            validationRequired: boolean().required(dictionary.validation.required).default(false),
            person: object({
                value: number().required(dictionary.validation.required),
                label: string().required(dictionary.validation.required)
            }).required(dictionary.validation.required)
        })
    ),
    external: array(
        object({
            name: string().required(dictionary.validation.required),
            email: string().required(dictionary.validation.required).lowercase(),
            phones: array(
                object({
                    ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)),
                    number: string()
                })
            ),
            validationRequired: boolean().required(dictionary.validation.required).default(true)
        })
    )
});

export default meetingParticipantSchema;
