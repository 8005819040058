import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import dictionary from 'utils/dictionary';
import { array, date, mixed, number, object, string } from 'yup';

export type TimelineFormProject = {
    startDate: string;
    endDate: string;
    predictedTime: number;
    items: Array<{
        person: number;
        title: string;
        endDate: string;
        startDate: string;
        predictedTime: number;
        items: Array<{
            person: number;
            title: string;
            endDate: string;
            startDate: string;
            predictedTime: number;
        }>;
    }>;
};

const schema = object({
    items: array(
        object({
            title: string().required(dictionary.validation.required),
            startDate: date(),
            endDate: date().when('startDate', (startDate, schema) => {
                return startDate ? schema.min(startDate, dictionary.validation.date.min).required(dictionary.validation.required) : schema;
            }),
            person: number(),
            predictedTime: number(),
            items: mixed()
        })
    )
});

export const useTimelineForm = (onSubmit: (data: TimelineFormProject) => void, defaultValues: Partial<TimelineFormProject>) => {
    const methods = useForm<TimelineFormProject>({
        defaultValues,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema)
    });

    return {
        methods,
        handleSubmit: methods.handleSubmit(onSubmit)
    };
};

export const useTimelineFieldFormProject = (prefix: string) => {
    const { control, formState, getValues, register, watch, setValue } = useFormContext<TimelineFormProject>();

    const itemsArrayInputPath = `${prefix}items` as any;
    const titleInputPath = (ix: number) => `${prefix}items.${ix}.title` as any;
    const personInputPath = (ix: number) => `${prefix}items.${ix}.person` as any;
    const endDateInputPath = (ix: number) => `${prefix}items.${ix}.endDate` as any;
    const startDateInputPath = (ix: number) => `${prefix}items.${ix}.startDate` as any;
    const predictedTimePath = (ix: number) => `${prefix}items.${ix}.predictedTime` as any;

    const { fields, append, remove } = useFieldArray<any>({
        name: itemsArrayInputPath,
        control
    });

    const handleAdd = () => append({ title: '', items: [] });

    const handleDelete = (itemIndex: number) => () => remove(itemIndex);

    return {
        fields,
        formState,
        personInputPath,
        endDateInputPath,
        startDateInputPath,
        titleInputPath,
        predictedTimePath,
        control,
        itemsArrayInputPath,
        getValues,
        handleAdd,
        handleDelete,
        register,
        watch,
        setValue
    };
};
