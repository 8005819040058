// Dependencies
import createCache from '@emotion/cache';
import lightBlue from '@mui/material/colors/lightBlue';
import createTheme from '@mui/material/styles/createTheme';
import shadows, { Shadows } from '@mui/material/styles/shadows';

// Helpers
import theme from './index';

export const MATERIAL_CACHE = createCache({
    key: 'mili',
    prepend: true
});

export const materialTheme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
        button: {
            textTransform: 'none'
        }
    },
    components: {
        MUIDataTable: {
            styleOverrides: {
                paper: {
                    borderRadius: 20
                }
            }
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                data: {
                    color: theme.extend.colors.base[500],
                    textTransform: 'uppercase'
                },
                root: {
                    padding: '4px 16px'
                },
                sortAction: {
                    alignItems: 'center'
                }
            }
        },
        MUIDataTableBody: {
            styleOverrides: {
                emptyTitle: {
                    fontSize: 'inherit',
                    fontStyle: 'italic',
                    padding: '0 8px',
                    textAlign: 'left'
                }
            }
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                stackedCommon: {
                    color: theme.extend.colors.base[500]
                }
            }
        },
        MUIDataTableSelectCell: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFF',
                    paddingLeft: 0,
                    textAlign: 'center',
                    '> div': {
                        justifyContent: 'center'
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomColor: theme.extend.colors.base[300]
                }
            }
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
                root: {
                    '&.mui-row-selected': {
                        backgroundColor: theme.extend.colors.base[200],
                        '&:hover': {
                            backgroundColor: theme.extend.colors.base[200]
                        }
                    },
                    '&:last-child': {
                        td: {
                            borderBottom: 0
                        }
                    }
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                ul: {
                    li: {
                        '&:not(:last-child)': {
                            marginRight: 8
                        }
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    minWidth: '224px !important'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: theme.extend.colors.base[700],
                    fontSize: 14,
                    paddingLeft: 24,
                    paddingRight: 24
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: '32px !important'
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    margin: 0,
                    '&:disabled': {
                        backgroundColor: `${theme.extend.colors.base[300]} !important`,
                        color: theme.extend.colors.base[500]
                    }
                },
                previousNext: {
                    backgroundColor: '#E6F5F7 !important',
                    color: theme.extend.colors.secondary[500]
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:disabled': {
                        backgroundColor: `${theme.extend.colors.base[300]}`
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&:disabled': {
                        cursor: 'no-drop',
                        pointerEvents: 'unset'
                    }
                }
            }
        },
        MUIDataTableSearch: {
            styleOverrides: {
                searchText: {
                    flex: 1,
                    fontStyle: 'italic',
                    '.MuiInput-root': {
                        color: theme.extend.colors.base[500],
                        fontSize: 14,
                        '&:before': {
                            display: 'none'
                        },
                        '&:after': {
                            display: 'none'
                        }
                    }
                },
                main: {
                    border: `1px solid ${theme.extend.colors.base[300]}`,
                    borderRadius: 14,
                    paddingLeft: 12
                },
                searchIcon: {
                    marginRight: 12
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: 'unset !important'
                }
            }
        },
        MUIDataTableToolbar: {
            styleOverrides: {
                root: {
                    padding: 24
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#EDEDED'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: 17,
                    minWidth: 17
                },
                label: {
                    fontSize: 10,
                    padding: '0 5px'
                },
                // @ts-ignore
                colorError: {
                    color: theme.extend.colors.base[100]
                },
                filledError: {
                    backgroundColor: theme.extend.colors.system.danger[500]
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    zIndex: 9999
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    padding: 4,
                    minWidth: 18,
                    fontSize: 10,
                    lineHeight: 1,
                    height: 'unset'
                },
                colorError: {
                    color: theme.extend.colors.base[100]
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 20
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${theme.extend.colors.base[300]}`
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'outlined', color: 'inherit' },
                    style: {
                        borderColor: theme.extend.colors.base[300],
                        color: theme.extend.colors.base[700]
                    }
                },
                {
                    props: { variant: 'contained', color: 'error' },
                    style: {
                        // borderColor: theme.extend.colors.base[300],
                        color: theme.extend.colors.base[100]
                    }
                }
            ],
            styleOverrides: {
                sizeSmall: {
                    fontSize: 12,
                    padding: '5.5px 12px'
                },
                sizeMedium: {
                    fontSize: 15,
                    padding: '7px 16px'
                },
                sizeLarge: {
                    fontSize: 15,
                    padding: '7px 16px'
                },
                root: {
                    borderRadius: 14
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 14
                },
                action: {
                    paddingTop: 2
                }
            }
        }
    },
    palette: {
        info: lightBlue,
        primary: {
            ...theme.extend.colors.primary,
            main: theme.extend.colors.primary[500],
            light: theme.extend.colors.primary[100],
            dark: theme.extend.colors.primary[700]
        },
        secondary: {
            ...theme.extend.colors.secondary,
            main: theme.extend.colors.secondary[500],
            light: theme.extend.colors.secondary[100],
            dark: theme.extend.colors.secondary[700]
        },
        error: theme.extend.colors.system.danger,
        warning: theme.extend.colors.system.warning,
        success: theme.extend.colors.system.success,
        grey: theme.extend.colors.base,
        heading: theme.extend.colors.heading
    },
    shadows: shadows.map((item, index) => {
        if (index === 21) {
            return '-4px 8px 24px rgba(44, 63, 88, 0.15)';
        }

        if (index === 22) {
            return '0px 8px 24px rgba(44, 63, 88, 0.1)';
        }

        if (index === 23) {
            return '0px 20px 50px rgba(44, 63, 88, 0.15)';
        }

        return item;
    }) as Shadows,
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 1023,
            lg: 1279,
            xl: 1280
        }
    }
});
