import { ChartOptions } from 'chart.js';
import theme from 'settings/theme';

const textStyle = {
    color: theme.extend.colors.base[500],
    font: {
        family: 'Poppins',
        weight: '400',
        size: 14
    }
};

function baseOptions(title?: string) {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    ...textStyle
                }
            },
            y: {
                ticks: {
                    ...textStyle,
                    crossAlign: 'far'
                }
            }
        },
        plugins: {
            title: {
                display: Boolean(title),
                text: title,
                align: 'start',
                color: theme.extend.colors.heading,
                font: {
                    family: 'Poppins',
                    weight: '500',
                    size: 18
                }
            },
            legend: {
                align: 'end',
                position: 'top' as const,
                labels: {
                    ...textStyle,
                    usePointStyle: true,
                    boxWidth: 8,
                    boxHeight: 8
                }
            }
        }
    };
}

export const horizontalBarChartOptions = (title?: string): ChartOptions<'bar'> => {
    const options = baseOptions(title) as ChartOptions<'bar'>;

    return {
        ...options,
        indexAxis: 'y' as const,
        layout: {
            padding: 0
        },
        elements: {
            bar: {
                borderWidth: 0,
                borderRadius: 10
            }
        },
        scales: {
            x: {
                ticks: {
                    ...textStyle
                },
                max: 100,
                min: 0
            },
            y: {
                ticks: {
                    ...textStyle,
                    crossAlign: 'far'
                },
                grid: {
                    display: false
                }
            }
        },
        plugins: options.plugins
    };
};

export const lineChartOptions = (title?: string, max?: number): ChartOptions<'line'> => {
    const options = baseOptions(title) as ChartOptions<'line'>;

    return {
        ...options,
        plugins: {
            ...options.plugins,
            legend: {
                ...options.plugins?.legend,
                position: 'top' as const
            }
        },
        scales: {
            ...options.scales,
            y: {
                ...options.scales?.y,
                max,
                min: 0
            }
        }
    };
};

export const pieChartOptions = (title?: string): ChartOptions<'pie'> => {
    const options = baseOptions(title) as ChartOptions<'pie'>;

    return {
        ...options,
        layout: {
            ...options.layout,
            padding: {
                bottom: 10
            }
        },
        plugins: {
            ...options.plugins,
            legend: {
                ...options.plugins?.legend,
                align: 'center',
                position: 'bottom',
                labels: {
                    ...options.plugins?.legend?.labels
                }
            }
        },
        scales: {
            grid: {
                display: false
            }
        }
    };
};
