import React, { useCallback, useRef } from 'react';

// Dependencies
import { DropzoneInputProps } from 'react-dropzone';
import useTheme from '@mui/material/styles/useTheme';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';

type UploaderProps = {
    dropzoneProps: DropzoneInputProps;
};

const Uploader = ({ dropzoneProps }: UploaderProps) => {
    const { palette } = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputFile = useCallback(() => {
        if (!inputRef.current) {
            return;
        }

        inputRef.current?.click();
    }, [inputRef]);

    return (
        <>
            <input ref={inputRef} {...dropzoneProps} className="hidden" />
            <Icon name="ico-upload-cloud" width={24} height={20} color={palette.grey[500]} className="mb-3" />
            <Text as="p" variant="body.regular.sm" className="text-base-500 text-center px-5">
                Arraste para cá ou{' '}
                <button type="button" className="text-secondary-500" onClick={handleInputFile}>
                    procure
                </button>{' '}
                para fazer upload
            </Text>
        </>
    );
};

export default Uploader;
