import { ProjectTypeStatus } from 'types/models/project';

export const projectTypeStatuses = [
    {
        value: ProjectTypeStatus.Waiting,
        label: 'Aguardando'
    },
    {
        value: ProjectTypeStatus.Started,
        label: 'Iniciado'
    },
    {
        value: ProjectTypeStatus.Done,
        label: 'Finalizado'
    },
    {
        value: ProjectTypeStatus.Stopped,
        label: 'Paralisado'
    },
    {
        value: ProjectTypeStatus.Canceled,
        label: 'Cancelado'
    },
    {
        value: ProjectTypeStatus.Processing,
        label: 'Processando'
    }
];
