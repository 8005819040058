import React from 'react';

// Dependencies
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

export type ButtonVariant = 'base' | 'primary' | 'secondary' | 'outline-primary' | 'outline-secondary' | 'outline-base' | 'outline-danger' | 'outline-success' | 'warning' | 'danger';

const Button: React.FC<LoadingButtonProps & { to?: string }> = ({ children, ...props }) => {
    return (
        <LoadingButton {...props} disableElevation={true}>
            {children}
        </LoadingButton>
    );
};

export default Button;
