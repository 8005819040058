import CalendarBody from './body';
import CalendarHeader from './header';
import { useCallback, useState } from 'react';
import { CalendarContextProvider } from './context';
import dayjs from 'dayjs';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useCalendarHeaderTexts } from './hooks/header-texts';
import CalendarSidebar from './sidebar';
import { OccupationMapResponse } from 'services/queries/occupation-map/use-get-occupation-map';

dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekOfYear);

type Props = {
    data: OccupationMapResponse;
    year: number;
};

const Calendar = ({ data, year }: Props) => {
    const headerTexts = useCalendarHeaderTexts({ year });

    const [activePeople, setActivePeople] = useState<number[]>([]);

    const handleTogglePerson = useCallback((id: number) => {
        setActivePeople((tasks) => {
            if (tasks.includes(id)) {
                return tasks.filter((taskId) => taskId !== id);
            }

            return [...tasks, id];
        });
    }, []);

    return (
        <CalendarContextProvider
            value={{
                headerTexts,
                items: data,
                activePeople
            }}>
            <>
                <div className="grid grid-cols-5 overflow-auto w-full h-full border-t border-slate-200 bg-white">
                    <div
                        className="col-span-1 border-b border-r border-slate-200 bg-white h-[75px]"
                        style={{
                            background: 'repeating-linear-gradient(145deg,#fff,#fff 3px,rgb(226 232 240/.35) 0,rgb(226 232 240/.35) 6px)'
                        }}></div>

                    <CalendarHeader />

                    <div
                        className="col-span-1 sticky left-0 col-start-1 border-r border-slate-200 bg-white"
                        style={{
                            minHeight: 'calc(100vh - 130px)'
                        }}>
                        <CalendarSidebar onTogglePerson={handleTogglePerson} items={data} activePeople={activePeople} />
                    </div>

                    <CalendarBody />
                </div>
            </>
        </CalendarContextProvider>
    );
};

export type HeaderText = {
    year: number;
    title: string;
    monthNumber: number;
    totalWeeks: number;
};

export type TimelineVariant = 'MONTHS';

export default Calendar;
