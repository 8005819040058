import React, { useMemo } from 'react';

// Dependencies
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';

// Components
import Icon from 'components/core/icon';
import hexToRgba from 'utils/hex-to-rgba';

type ActionButtonProps = {
    variant: 'add' | 'save' | 'remove';
    onClick: () => void;
    className?: string;
    disabled?: boolean;
};

const ActionButton = ({ className, disabled, variant, onClick }: ActionButtonProps) => {
    const { palette } = useTheme();

    const content = useMemo(() => {
        if (disabled) {
            return {
                background: 'bg-gray-200',
                title: 'Remover',
                border: `none`,
                hover: {
                    backgroundColor: 'bg-gray-200',
                    borderColor: 'none'
                },
                icon: {
                    color: palette.grey[500],
                    name: 'ico-trash'
                }
            };
        }

        if (variant === 'add') {
            return {
                background: 'bg-secondary-500',
                color: 'secondary',
                title: 'Adicionar sub-tarefa',
                border: `1px solid ${palette.secondary[500]}`,
                hover: {
                    backgroundColor: palette.secondary.dark,
                    borderColor: palette.secondary.dark
                },
                icon: {
                    color: palette.grey[100],
                    name: 'ico-plus'
                }
            };
        }

        if (variant === 'save') {
            return {
                background: 'bg-base-100',
                color: 'secondary',
                title: 'Salvar',
                border: `1px solid ${palette.success[500]}`,
                hover: {
                    backgroundColor: hexToRgba(palette.success[100], 0.3)
                },
                icon: {
                    color: palette.success[500],
                    name: 'ico-save'
                }
            };
        }

        return {
            background: 'bg-system-danger-500',
            color: 'error',
            title: 'Remover',
            border: `1px solid ${palette.error[500]}`,
            hover: {
                backgroundColor: palette.error.dark,
                borderColor: palette.error.dark
            },
            icon: {
                color: palette.grey[100],
                name: 'ico-trash'
            }
        };
    }, [palette, variant, disabled]);

    return (
        <Tooltip disableHoverListener={disabled} disableInteractive={disabled} disableTouchListener={disabled} placement="top" title={content.title} disableFocusListener={true}>
            <span>
                <IconButton
                    color={content.color as any}
                    disabled={disabled}
                    size="large"
                    classes={{ root: `p-2 ${content.background}` }}
                    className={className}
                    sx={{ '&:hover': content.hover, border: content.border }}
                    onClick={onClick}>
                    <Icon {...content.icon} width={12} height={12} />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default ActionButton;
