import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';

const useCreatePageRules = () => {
    const request = async (payload: any) => {
        return apiGraphQl(
            `mutation CreatePageRules($payload: [page_rule_insert_input!]!) {
                item: insert_page_rule(objects: $payload) {
                    affected_rows
                }
            }`,
            { payload }
        );
    };

    return useMutation(request);
};

export default useCreatePageRules;
