import { useQuery } from 'react-query';
import api from 'services/api';
import { getPeopleMapKey } from './graphql';
import { PeopleMapFilter } from './types';

type Params = {
    filters: PeopleMapFilter[];
    endpoint: string;
};

const usePeopleMapRequest = <T>({ filters, endpoint }: Params) => {
    const request = async () => {
        const formatFilters = filters
            .filter(({ value }) => !!value || value === 0)
            .map(({ key, value }) => `filter[${key}]=${value}`)
            .join('&');

        if (!!formatFilters.length) {
            endpoint += `?${formatFilters}`;
        }

        return api.get<T>(endpoint).then(({ data }) => data);
    };

    const filterMapByYearQuantity = 3;
    const isMapPeopleByYear = filters.length > filterMapByYearQuantity;

    const enabledFilterLength = isMapPeopleByYear ? 2 : 1;

    return useQuery(getPeopleMapKey(filters, endpoint), request, {
        enabled: filters.filter((item) => item.value).length > enabledFilterLength
    });
};

export default usePeopleMapRequest;
