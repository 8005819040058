import React from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';

type Props = {
    title: string;
    total: number;
};

const ColumnHeader = ({ title, total = 0 }: Props) => {
    return (
        <div className="flex items-center mb-4">
            <div className="flex items-center flex-1">
                <p className="mr-2 text-base-700 font-medium text-lg uppercase">{title}</p>
                <span className="bg-base-300 rounded-md min-w-[20px] h-[20px] text-xs flex items-center justify-center px-1 text-base-500 font-semibold">{total}</span>
            </div>

            <IconButton onClick={() => {}}>
                <Icon name="ico-dots-horizontal" width={16} height={16} className="text-base-500" />
            </IconButton>
        </div>
    );
};

export default ColumnHeader;
