import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import Menu from 'components/menu';
import Icon from 'components/core/icon';
import { Rule } from 'types/permissions';
import { MenuItem } from 'components/menu';

type DatatableActionsProps = {
    item: any;
    actions: Rule[];
    customRoutePath?: (value?: any) => string;
    onDelete?: (id?: number, slug?: string) => void;
    customActions?: (item: any) => any[];
    canShowDeleteButton?: (item: any) => boolean;
};

const DatatableActions = ({ actions, customActions = () => [], canShowDeleteButton, customRoutePath, item, onDelete }: DatatableActionsProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { pathname } = useLocation();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleGoTo = useCallback(
        (path?: string) => (event: React.MouseEvent<Element, MouseEvent>) => {
            handleClose();

            const customPath = customRoutePath?.(item);

            if (event.ctrlKey || event.metaKey) {
                const newRoute = customPath || `${pathname}/${path}`;

                return window.open(newRoute, '_blank');
            }

            const url = customPath || path;

            if (!!url) {
                navigate(url);
            }
        },
        [navigate, customRoutePath, pathname, item]
    );

    const handleDelete = useCallback(() => onDelete?.(item?.id, item?.slug), [item?.id, item?.slug, onDelete]);

    const options = useMemo(() => {
        const content = new Map<Rule, MenuItem>([
            [
                Rule.Show,
                {
                    label: 'Abrir',
                    icon: { name: 'ico-show', color: palette.grey[500] },
                    onClick: handleGoTo(item?.id?.toString())
                }
            ],
            [
                Rule.Update,
                {
                    label: 'Editar',
                    icon: { name: 'ico-edit', color: palette.grey[500] },
                    onClick: handleGoTo(`editar/${item?.id}`)
                }
            ]
        ]);

        const newActions = actions.filter((action) => {
            if (Boolean(item.system) && (action === Rule.Update || action === Rule.Delete)) {
                return;
            }

            return action;
        });

        const arr: MenuItem[] = newActions
            .map((item) => content.get(item)!)
            .filter(Boolean)
            .sort((a, b) => a.label.localeCompare(b.label));

        const customActionsArr = customActions(item);

        if (!!customActionsArr.length) {
            const customActionsArr = customActions(item);
            handleClose();

            return customActionsArr;
        }

        return arr;
    }, [actions, palette, handleGoTo, customActions, item]);

    const footer = useMemo(() => {
        const hasDeleteAction = actions.some((item) => item === Rule.Delete);

        const isDeleteButtonVisible = canShowDeleteButton?.(item);

        if (!hasDeleteAction || (!!canShowDeleteButton && !isDeleteButtonVisible)) {
            return;
        }

        return [
            {
                className: 'text-system-danger-500',
                label: 'Apagar',
                icon: {
                    color: palette.error.main,
                    name: 'ico-trash'
                },
                onClick: handleDelete
            }
        ];
    }, [actions, palette, handleDelete, canShowDeleteButton, item]);

    if (!Boolean(options.length)) {
        return null;
    }

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Icon name="ico-dots" width={16} height={16} color={palette.grey[500]} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} content={options} footer={footer} />
        </>
    );
};

export default DatatableActions;
