import useToast from 'hooks/toast/use-toast';
import { useRef } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { generateErrorMessages } from 'utils/errors';

export const useShowApiErrors = () => {
    const { showToast } = useToast();

    const showToastErrors = (error: any, defaultMessage = 'Ocorreu um erro. Tente novamente') => {
        const messages = generateErrorMessages(error?.response?.data?.errors);

        if (!messages?.length) {
            showToast(defaultMessage, 'danger');
        } else {
            messages.forEach(({ name, message }) => showToast([name, message].join(': '), 'danger'));
        }
    };

    const lastError = useRef<any>(null);

    const showFormErrors = (options: { error: any; setError: UseFormSetError<any>; blackList?: string[] }) => {
        const { error, setError, blackList = [] } = options || {};

        if (!error || lastError.current === error) {
            return;
        }

        lastError.current = error;

        const errors = generateErrorMessages(error?.response?.data?.errors);

        errors
            .filter(({ name }) => !blackList?.includes(name))
            .forEach(({ name, message }) => {
                if (!name) {
                    return;
                }

                setError(name, { message });
            });
    };

    return { showToastErrors, showFormErrors };
};
