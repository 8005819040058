import FormButtons from 'components/core/form/buttons';
import Select from 'components/core/form/select';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Switch from 'components/core/switch';
import Text from 'components/core/text';
import findIndex from 'lodash/findIndex';
import ProjectPhase from 'pages/private/projects/proposal/project/phase';
import { classificationsQuery } from 'pages/private/projects/proposal/project/utils';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import useCreateBudgetAdditiveContract from 'services/queries/commercial/use-create-budget-additive-contract';
import useGetProjectServiceByType from 'services/queries/projects/use-get-project-service-by-type';
import theme from 'settings/theme';
import { Modify, Option } from 'types/general';
import { BudgetAdditiveContractPayload, CommercialBudgetOutletContext } from 'types/models/commercial';
import { ProjectTypeEnum, ProjectTypeSlug } from 'types/models/project';

type ProjectByTypeSlugPayload = Modify<
    BudgetAdditiveContractPayload['versions'][0],
    {
        hasPhases: boolean;
    }
>;

type ProjectFormPayload = {
    [ProjectTypeSlug.ComplementaryProjects]: ProjectByTypeSlugPayload;
    [ProjectTypeSlug.Projects]: ProjectByTypeSlugPayload;
};

const ProjectFormPage = () => {
    const navigate = useNavigate();
    const ctx = useOutletContext<CommercialBudgetOutletContext>();

    const { data: projectServicesOptions = [], isLoading: isLoadingProjectServices } = useGetProjectServiceByType(ProjectTypeEnum.Projects);
    const { data: complementaryServicesOptions = [], isLoading: isLoadingComplementaryServices } = useGetProjectServiceByType(ProjectTypeEnum.ComplementaryProjects);
    const { data: projectClassifications = [], isLoading: isProjectClassifications } = useGetClassifications(classificationsQuery(false));
    const { data: complementaryClassifications = [], isLoading: isLoadingComplementaryClassifications } = useGetClassifications(classificationsQuery(true));
    const { mutateAsync: createBudgetAdditiveContract, isLoading: isSubmitting } = useCreateBudgetAdditiveContract(ctx?.commercialBudget?.id);

    const isLoading = isLoadingComplementaryClassifications || isProjectClassifications || isLoadingComplementaryServices || isLoadingProjectServices;

    const methods = useForm<any>({
        mode: 'onSubmit',
        defaultValues: {
            [ProjectTypeSlug.ComplementaryProjects]: {
                hasPhases: false
            },
            [ProjectTypeSlug.Projects]: {
                hasPhases: false
            }
        }
    });

    const onlyProjects = ctx?.budgetVersions?.filter((item) => item.project_type.slug.includes('projects'));

    const { fields: projectsPhases, remove: removeProjectsPhases, append: appendProjectsPhases } = useFieldArray({ control: methods.control, name: `${ProjectTypeSlug.Projects}.phases` });

    const {
        fields: complementaryPhases,
        remove: removeComplementaryPhases,
        append: appendComplementaryPhases
    } = useFieldArray({ control: methods.control, name: `${ProjectTypeSlug.ComplementaryProjects}.phases` });

    const projectServicesIds = methods.watch(`${ProjectTypeSlug.Projects}.services`);
    const complementaryServicesIds = methods.watch(`${ProjectTypeSlug.ComplementaryProjects}.services`);

    const handleChangeSubjects = (selectedOptions: Option[], baseName: Omit<ProjectTypeSlug, 'Bid' | 'Management'>) => {
        const ids = selectedOptions.map((item) => item.value) as number[];

        if (!Boolean(ids?.length)) {
            methods.setValue(`${baseName}.selectedPhases`, undefined);
            methods.setValue(`${baseName}.phases`, undefined);
        }

        methods.setValue(`${baseName}.services`, ids);
    };

    const handleChangePhases = (options: any[], newValue, baseName: Omit<ProjectTypeSlug, 'Bid' | 'Management'>) => {
        methods.setValue(
            `${baseName}.selectedPhases`,
            options.map((item) => item.value)
        );

        const isProjectTypeSlug = baseName === ProjectTypeSlug.Projects;

        const appendMethod = isProjectTypeSlug ? appendProjectsPhases : appendComplementaryPhases;
        const removeMethod = isProjectTypeSlug ? removeProjectsPhases : removeComplementaryPhases;

        const removed = newValue.removedValue?.value;

        if (Boolean(removed)) {
            const removedIndex = findIndex(projectsPhases, { classification: removed } as any);

            return removeMethod(removedIndex);
        }

        return appendMethod({
            classification: newValue.option.value,
            paymentPercentage: 0,
            hasThirdPart: newValue.option.has_third_part
        });
    };

    const handleToggleSwitch = (value: boolean, baseName: string) => {
        methods.setValue(`${baseName}.hasPhases`, value);

        if (!value) {
            methods.setValue(`${baseName}.services`, []);
            methods.setValue(`${baseName}.selectedPhases`, []);
            methods.setValue(`${baseName}.phases`, []);
        }
    };

    const getHasPhasesValueByBaseName = (baseName: ProjectTypeSlug) => methods.watch(`${baseName}.hasPhases`);

    const submit = async (data: ProjectFormPayload) => {
        try {
            const payload: BudgetAdditiveContractPayload = {
                versions: ctx.budgetVersions.map((item) => {
                    const dataBySlug: ProjectByTypeSlugPayload = data[item.project_type.slug];

                    return {
                        budgetVersion: item?.id,
                        withPhase: dataBySlug?.hasPhases || false,
                        phases: (dataBySlug?.phases || []).map((phase) => ({
                            classification: phase.classification,
                            order: phase?.order,
                            paymentPercentage: phase.paymentPercentage,
                            classifications: phase.classifications
                        }))
                    };
                })
            };

            await createBudgetAdditiveContract(payload);
        } catch (error) {
            console.error('submit: projects-form', error);
        }
    };

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-full sm:w-[960px] mx-5 sm:min-w-[500px]" onClose={navigate.bind(this, -1)}>
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={methods.handleSubmit(submit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        Configurar aditivo de contrato
                    </Text>
                    <FormProvider {...methods}>
                        {onlyProjects.map((item, index) => {
                            const baseName = item?.project_type.slug;
                            const hasPhases = getHasPhasesValueByBaseName(baseName);
                            const phases = baseName === ProjectTypeSlug.Projects ? projectsPhases : complementaryPhases;

                            return (
                                <div key={index} className="border border-base-300 p-4 rounded-[14px] mb-4">
                                    <div className="flex justify-between flex-wrap">
                                        <Text as="h3" variant="h4" className="text-heading !text-base">
                                            {item.project_type.title}
                                        </Text>
                                        <div className="flex gap-2 items-center">
                                            <Text variant="body.medium.sm" as="span" className="text-primary-700 !tex-base">
                                                Essa configuração terá fases?
                                            </Text>
                                            <Switch value={hasPhases} onChange={(value) => handleToggleSwitch(value, baseName)} />
                                        </div>
                                    </div>
                                    {hasPhases && (
                                        <div className="border border-base-300 p-4 rounded-[14px] mt-5">
                                            <Controller
                                                name={`${baseName}.services`}
                                                control={methods.control}
                                                render={({ field }) => {
                                                    const options = baseName === ProjectTypeSlug.Projects ? projectServicesOptions : complementaryServicesOptions;
                                                    const value = options.filter((item) => field.value?.find((value) => value === item.value));

                                                    return (
                                                        <Select
                                                            {...field}
                                                            isMulti={true}
                                                            value={value}
                                                            options={options}
                                                            label="Disciplinas"
                                                            parentClassName="mb-6"
                                                            placeholder="Selecione uma ou mais opções"
                                                            error={(methods as any).formState.errors?.[baseName]?.services?.message}
                                                            onChange={(option: any) => handleChangeSubjects(option, baseName)}
                                                        />
                                                    );
                                                }}
                                            />
                                            <Controller
                                                name={`${baseName}.selectedPhases`}
                                                control={methods.control}
                                                render={({ field }) => {
                                                    const options = baseName === ProjectTypeSlug.Projects ? projectClassifications : complementaryClassifications;
                                                    const servicesByBaseName = baseName === ProjectTypeSlug.Projects ? projectServicesIds : complementaryServicesIds;
                                                    const value = options.filter((item) => field.value?.find((value) => value === item.value));

                                                    return (
                                                        <Select
                                                            {...field}
                                                            isDisabled={!servicesByBaseName?.length}
                                                            isMulti={true}
                                                            value={value}
                                                            options={options}
                                                            label="Escopos"
                                                            placeholder="Selecione uma ou mais opções"
                                                            parentClassName="mb-6"
                                                            error={(methods as any).formState.errors?.[baseName]?.selectedPhases?.message}
                                                            onChange={(options: any, newValue) => handleChangePhases(options, newValue, baseName)}
                                                        />
                                                    );
                                                }}
                                            />
                                            {phases.map((phase: any, index) => {
                                                const classification = projectClassifications.find((classification) => classification.value === phase?.classification);

                                                return (
                                                    <ProjectPhase
                                                        isComplementaryProject={item.project_type.slug === ProjectTypeSlug.ComplementaryProjects}
                                                        index={index}
                                                        classificationName={classification?.label || ''}
                                                        phase={phase as any}
                                                        baseName={item.project_type.slug}
                                                        key={phase.classification}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </FormProvider>
                    <FormButtons isLoading={isSubmitting} />
                </form>
            )}
        </Modal>
    );
};

export default ProjectFormPage;
