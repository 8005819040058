// Dependencies
import { useQuery } from 'react-query';

// Helpers
import api from 'services/api';
import { getMeetingRecordKey } from './graphql';
import { ProjectMeetingRecord } from 'types/models/project';

const useGetMeetingRecord = (meetingRecordId?: string) => {
    const fetcher = () => api.get<ProjectMeetingRecord>(`/meeting-records/${meetingRecordId}`).then(({ data }) => data);

    return useQuery(getMeetingRecordKey(meetingRecordId), fetcher, { enabled: Boolean(meetingRecordId) });
};

export default useGetMeetingRecord;
