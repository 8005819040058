import { AgendaYear, People } from '../../types';
import { DAY_HEIGHT } from '../../utils';
import Tooltip from '@mui/material/Tooltip/Tooltip';

type PeopleMapPerMonthTeamProps = {
    items: People<AgendaYear>[];
};

const Team = ({ items = [] }: PeopleMapPerMonthTeamProps) => {
    return (
        <div className="w-[150px] md:w-[250px] border-r border-slate-200 divide-y divide-slate-200">
            <div style={{ height: DAY_HEIGHT + 10 }} className="uppercase text-heading flex items-center justify-center font-medium">
                EQUIPE
            </div>

            {items.map(({ name }, i) => (
                <div key={`person_${name}_${i}`} style={{ height: DAY_HEIGHT }} className="flex items-center font-medium  px-4">
                    <Tooltip placement="top" title={name} disableFocusListener={true}>
                        <span className="text-ellipsis overflow-hidden truncate text-[#6d7178] text-xs">{name}</span>
                    </Tooltip>
                </div>
            ))}
        </div>
    );
};

export default Team;
