import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import ChangeImageModalForm from './form';

type Props = {
    onClose?(): void;
    title?: string;
    isLoading?: boolean;
    onSubmit(data: File): void;
};

const ChangeImageModal = ({ onClose, title = 'Alterar foto', onSubmit, isLoading = false }: Props) => {
    const navigate = useNavigate();

    const handleClose = () => {
        if (isLoading) {
            return;
        }

        if (onClose) {
            onClose();
        } else {
            navigate(-1);
        }
    };

    return (
        <Modal
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {title}
                </Text>
            }
            contentClassnames="w-[800px]"
            onClose={handleClose}
            hideCloseButton={isLoading}>
            <div className="p-6 pt-0">
                <ChangeImageModalForm isSubmitting={isLoading} onCancel={handleClose} onSubmit={onSubmit} />
            </div>
        </Modal>
    );
};

export default ChangeImageModal;
