import useDownloadReport from './use-download-report';

const useHandleDownloadReport = (endpoint: string) => {
    const { mutate, isLoading: isDownloading } = useDownloadReport(endpoint);

    const handleDownload = () => {
        mutate();
        return false;
    };

    return { handleDownload, isDownloading };
};

export default useHandleDownloadReport;
