// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import project, { getCisosKey } from './graphql';
import { ProjectManagementInformation } from 'types/models/project';

type Response = {
    project_management_information: Partial<ProjectManagementInformation>[];
};

const useGetCisos = (projectId?: number) => {
    const request = () => apiGraphQl<Response>(project.getCisos, { projectId }).then((response) => response.project_management_information);

    return useQuery(getCisosKey(projectId), request, { enabled: Boolean(projectId) });
};

export default useGetCisos;
