import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';

import queries, { getPersonBankAccountKey } from './graphql';
import { PersonBankAccountDetails } from 'types/models/person';

type Response = {
    data: PersonBankAccountDetails;
};

const useGetPersonBankAccountById = (bankAccountId?: string) => {
    const fetcher = async () => apiGraphQl<Response>(queries.getPersonBankAccountById, { bankAccountId }).then(({ data }) => data);

    return useQuery(getPersonBankAccountKey(bankAccountId), fetcher, { enabled: !!bankAccountId });
};

export default useGetPersonBankAccountById;
