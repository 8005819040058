// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import { getClassificationsOptionsKey } from './graphql';

const useGetClassifications = (query?: string) => {
    const fetcher = async () => apiGraphQl<Pagination<Option>>(query || '').then(({ items }) => items);

    return useQuery(getClassificationsOptionsKey(query), fetcher, { enabled: Boolean(query) });
};

export default useGetClassifications;
