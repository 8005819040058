import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';

import theme from 'settings/theme';

import { useShowApiErrors } from 'hooks/error/show-api-errors';

import schema from '../utils/schema';
import { CreateTypeBusinessPayload } from 'types/models/configurations-classifications';

type TypeBusinessProps = {
    title: string;
    isBuilding: boolean;
    isSubmitting: boolean;
    onSubmit: (data: CreateTypeBusinessPayload) => void;
    defaultValues?: CreateTypeBusinessPayload;
    error?: any;
};

const INITIAL_FORM_STATE = {
    title: ''
};

const CreateOrUpdateTypeBusiness = ({ isBuilding, isSubmitting, onSubmit, defaultValues, error, title }: TypeBusinessProps) => {
    const navigate = useNavigate();

    const methods = useForm<CreateTypeBusinessPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema.typeBusiness),
        defaultValues: INITIAL_FORM_STATE
    });

    const { formState, control, handleSubmit, reset, setError } = methods;

    const { showFormErrors } = useShowApiErrors();

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!!defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleClose = () => navigate(-1);

    return (
        <Modal onClose={handleClose} contentClassnames="min-w-[500px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {title}
                    </Text>
                    <div className="grid gap-4 mb-4">
                        <Controller name="title" control={control} render={({ field }) => <Input {...field} label="Tipo de negócio" error={formState.errors.title?.message} />} />
                    </div>
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateTypeBusiness;
