import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';

import queries, { getAllBanksOptionsKey } from './graphql';
import { Option } from 'types/general';

type Response = {
    items: Option[];
};
const useGetAllBanksOptions = () => {
    const fetcher = async () => apiGraphQl<Response>(queries.getAllBanks).then(({ items }) => items);

    return useQuery(getAllBanksOptionsKey, fetcher);
};

export default useGetAllBanksOptions;
