import React, { useCallback, useEffect } from 'react';
import Text from 'components/core/text';
import Dropzone from 'components/dropzone';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { Accept } from 'react-dropzone';
import { FileApiModel } from 'types/models/file';
import { useFormContext } from 'react-hook-form';
import { DailyReportPayload } from 'types/models/organization';
import { Option } from 'types/general';
import get from 'lodash/get';
import ErrorMessage from 'components/error/message';
import { useLocation } from 'react-router-dom';
import FileRow from 'components/files-row';

export type HookValue = FileApiModel | File | number | undefined;

type FilesProps = {
    provider?: Option;
    hook: [HookValue, React.Dispatch<React.SetStateAction<HookValue>>];
    index: number;
    accept?: Accept;
    className?: string;
};

const Files = ({ accept, index, className, provider, hook }: FilesProps) => {
    const [file, setFile] = hook;
    const { pathname } = useLocation();
    const { formState, setValue, watch } = useFormContext<DailyReportPayload>();

    const workDiary = watch(`workDiaries.${index}`);

    useEffect(() => {
        if (workDiary.file && pathname.includes('editar')) {
            setFile(workDiary.file);
        }
    }, [workDiary.file, setFile, pathname]);

    const handleUploadFiles = (filesList: File[]) => setFile(filesList[0]);

    const handleRemoveFromList = useCallback(() => {
        setFile(undefined);
        setValue(`workDiaries.${index}`, {
            provider: provider?.value || 0,
            file: undefined as any
        });
    }, [provider, setFile, setValue, index]);

    const handlePersistFile = (filePersist: FileApiModel) => {
        setFile({ ...(file as any), ...((file as any)?.path === filePersist.originalName && { ...filePersist, id: filePersist.id }) });
        setValue(`workDiaries.${index}`, { ...workDiary, file: filePersist as any });
    };

    const error = get(formState.errors, `workDiaries.${index}`);

    return (
        <div className={className}>
            <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                {provider?.label || ''}
            </Text>
            <Dropzone disabled={Boolean(file)} accept={accept} multiple={false} onUploadFiles={handleUploadFiles} />
            <ErrorMessage visible={Boolean(error)}>{error?.file?.message}</ErrorMessage>
            {Boolean(file) && (
                <TableContainer>
                    <Table className="mt-2">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Tipo
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Arquivo
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Status
                                    </Text>
                                </TableCell>
                                <TableCell width={90}>
                                    <Text as="span" className="text-secondary-500">
                                        Ações
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <FileRow file={file as any} onPersistFile={handlePersistFile} onRemoveFromList={handleRemoveFromList} />
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default Files;
