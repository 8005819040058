import { PeopleMapFilter } from './types';
import usePeopleMapRequest from './use-people-map-request';

const useGetMapOfHoursPerPersonAndMonth = <T>(filters: PeopleMapFilter[]) => {
    return usePeopleMapRequest<T>({
        endpoint: '/map-of-hours-per-person-and-month',
        filters
    });
};

export default useGetMapOfHoursPerPersonAndMonth;
