import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import PersonDetailsSidebar from './sidebar';
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import { useTabRoute, UseTabRouteChildren } from 'hooks/tab/use-tab-route';
import { Person } from 'types/models/person';
import { Page } from 'types/models/page';

type Props = {
    person: Person;
    subpages: Page[];
};

const PersonDetailsScreen = ({ subpages, person }: Props) => {
    const nav = useMemo(() => {
        return (
            subpages.map<UseTabRouteChildren>((item) => ({
                label: item.title,
                path: item.link
            })) || []
        );
    }, [subpages]);

    const { handleChangeTab, activeTabIndex, tabLabels } = useTabRoute(nav);

    return (
        <div className="bg-base-100 flex flex-col w-[auto] md:flex-row -ml-6 -mr-4 -my-6 md:h-[calc(100vh-70px)] md:max-h-[calc(100vh-70px)] overflow-hidden">
            <PersonDetailsSidebar person={person} />
            <section className="flex-1 bg-base-200 overflow-y-auto relative w-full h-full pb-6">
                <div className="bg-base-100 mb-6 pl-6 pr-4">
                    <Tabs variant="scrollable" indicatorColor="secondary" value={activeTabIndex} onChange={handleChangeTab}>
                        {tabLabels.map((label) => (
                            <Tab label={label} key={label} className="text-base py-5" />
                        ))}
                    </Tabs>
                </div>
                <div className="px-6">
                    <Outlet context={person} />
                </div>
            </section>
        </div>
    );
};

export default PersonDetailsScreen;
