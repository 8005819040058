import { PropsWithChildren, createContext, useContext } from 'react';
import { HeaderText, TimelineVariant } from '..';

type ContextState = {
    headerTexts: HeaderText[];
    timelineVariant: TimelineVariant;
};

const CalendarContext = createContext({} as ContextState);

export const CalendarContextProvider = ({ value, children }: PropsWithChildren<{ value: ContextState }>) => {
    return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};

export const useCalendarContext = () => {
    return useContext(CalendarContext);
};
