import { useQuery } from 'react-query';
import api from 'services/api';

import { getFollowupKey } from './graphql';
import { DailyReport } from 'types/models/organization';
import { OperationalDailyReportEffective, OperationalDailyReportWorkDiaries } from 'types/models/operationals';

type Response = DailyReport & {
    workDiaries: OperationalDailyReportWorkDiaries[];
    effective: OperationalDailyReportEffective[];
};

const useGetFollowup = (projectProposalTypeId?: number, followupId?: string | number) => {
    const endpoint = `/project-types/${projectProposalTypeId}/daily-reports/${followupId}`;

    const fetcher = async () => api.get<Response>(endpoint).then(({ data }) => data);

    return useQuery(getFollowupKey(+projectProposalTypeId!, followupId), fetcher, { enabled: !!followupId });
};

export default useGetFollowup;
