import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import TaskProgressList from './progress';
import { Task, TaskComment, TaskProgress, TaskType } from 'types/models/task';
import TaskFiles from './files';
import TaskComments from './comments';

type TaskInteractionsProps = {
    comments: TaskComment[];
    files: Task['files'];
    progress: TaskProgress[];
    taskType?: TaskType;
    timelineId: string | null;
};

type TabValue = 'PROGRESS' | 'ACTIVITY' | 'COMMENTS' | 'FILES';

const TaskInteractions = ({ comments, progress, files, taskType, timelineId }: TaskInteractionsProps) => {
    const [tab, setTab] = useState<TabValue>('PROGRESS');

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    return (
        <>
            <Tabs variant="scrollable" classes={{ root: 'px-6 bg-base-100 z-[10]' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                <Tab value="PROGRESS" label={`PROGRESSO (${progress.length})`} />
                <Tab value="FILES" label={`ARQUIVOS (${files.length})`} />
                <Tab value="COMMENTS" label={`COMENTÁRIOS (${comments.length})`} />
            </Tabs>
            {tab === 'PROGRESS' && <TaskProgressList timelineId={timelineId} taskType={taskType} items={progress} />}
            {tab === 'FILES' && <TaskFiles files={files} />}
            {tab === 'COMMENTS' && <TaskComments items={comments} />}
        </>
    );
};

export default TaskInteractions;
