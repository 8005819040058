import React from 'react';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Button from 'components/core/button';

// Helpers
import { useNavigate } from 'react-router-dom';
import Modal from 'components/core/modal';

type ErrorProps = Partial<{
    title: string;
    subtitle: string;
    description?: React.ReactNode | string;
    isOpenWithModal?: boolean;
}>;

const ErrorPage = ({ title = '404', description, isOpenWithModal, subtitle = 'Desculpe, tivemos um problema.' }: ErrorProps) => {
    const navigate = useNavigate();

    if (isOpenWithModal) {
        return (
            <Modal contentClassnames="w-[700px]" onClose={() => null} hideCloseButton={true}>
                <div className={styles.container}>
                    <h2 className="text-primary-500 font-bold text-9xl mb-5">{title}</h2>
                    <Text as="h2" variant="h2" className="text-heading mb-4">
                        {subtitle}
                    </Text>
                    {description && (
                        <Text as="h4" variant="body.regular.2xs" className="text-base-700">
                            {description}
                        </Text>
                    )}
                    <Button
                        type="button"
                        size="small"
                        color="secondary"
                        variant="contained"
                        className="mt-16"
                        onClick={() => navigate(-1)}
                        startIcon={<Icon name="ico-arrow-left" width={16} height={10} />}>
                        Voltar
                    </Button>
                </div>
            </Modal>
        );
    }

    return (
        <div className={styles.container}>
            <h2 className="text-primary-500 font-bold text-9xl mb-5">{title}</h2>
            <Text as="h2" variant="h2" className="text-heading mb-4">
                {subtitle}
            </Text>
            {description && (
                <Text as="h4" variant="body.regular.2xs" className="text-base-700">
                    {description}
                </Text>
            )}
            <Button type="button" size="small" color="secondary" variant="contained" className="mt-16" onClick={() => navigate(-1)} startIcon={<Icon name="ico-arrow-left" width={16} height={10} />}>
                Voltar
            </Button>
        </div>
    );
};

export default ErrorPage;

const styles = {
    container: 'w-full h-full flex flex-col items-center justify-center bg-secondary-100/10 bg-notFound bg-no-repeat bg-center error, p-10'
};
