// Dependencies
import axios, { AxiosRequestConfig } from 'axios';

// Helpers
import apiErrorHandling from './utils/error-handling';

const { REACT_APP_API_URL, REACT_APP_API_VERSION } = process.env;

export const headers = {
    headers: {
        'Content-Type': 'application/json'
    }
};

const config: AxiosRequestConfig = {
    baseURL: REACT_APP_API_URL! + REACT_APP_API_VERSION!,
    headers: headers.headers
};

const api = axios.create(config);

api.interceptors.response.use(
    (response) => response,
    async (error) => apiErrorHandling(error)
);

export default api;
