import Datatable from 'components/core/table/datatable';
import { projectsReportColumns } from './utils';
import Text from 'components/core/text';
import useGetProjectsReport from 'services/queries/reports/use-get-projects-report';
import { useState } from 'react';
import dictionary from 'utils/dictionary';
import useHandleDownloadReport from 'services/queries/reports/use-handle-download-report';

const ProjectsReportPage = () => {
    const [page, setPage] = useState(1);

    const { data: projectsReport, isLoading: isLoadingProjectsReport } = useGetProjectsReport(page);
    const { handleDownload, isDownloading } = useHandleDownloadReport('project-types-isy');

    return (
        <>
            <Text as="h6" variant="h6" className="text-heading !text-2xl mb-4">
                Relatório de Projetos
            </Text>
            <Datatable
                loading={isLoadingProjectsReport}
                options={{
                    count: projectsReport?.total,
                    elevation: 0,
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: isDownloading ? 'disabled' : true,
                    viewColumns: false,
                    serverSide: true,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    textLabels: {
                        ...dictionary.content.datatable,
                        toolbar: {
                            downloadCsv: 'Download XLSX'
                        }
                    },
                    onChangePage: setPage,
                    onDownload: handleDownload
                }}
                columns={projectsReportColumns}
                data={projectsReport?.items || []}
            />
        </>
    );
};

export default ProjectsReportPage;
