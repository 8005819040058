import api from 'services/api';
import { useQuery } from 'react-query';
import { getProjectTypeTermsKey } from './graphql';
import { ProjectTypeTerm } from 'types/models/project';
import { CamelCase } from 'types/utils';

const useGetProjectTypeTerms = (projectProposalTypeId?: number, origin?: 'project' | 'operational') => {
    const fetcher = async () => api.get<CamelCase<ProjectTypeTerm>[]>(`/project-types/${projectProposalTypeId}/terms`).then(({ data }) => data);

    return useQuery(getProjectTypeTermsKey(projectProposalTypeId), fetcher, { enabled: origin === 'project' });
};

export default useGetProjectTypeTerms;
