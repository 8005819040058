import React, { useEffect, useMemo, useState } from 'react';

// Dependencies
import { useOutletContext, useParams } from 'react-router-dom';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

// Components
import DocumentsCreateOrUpdate from './documents';

// Helpers
import { ProjectTypeDocument, ProjectTypeDocumentPayload } from 'types/models/project';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import useCreateOrUpdateDocument from 'services/queries/projects/use-create-or-update-document';
import { ClassificationType } from 'types/models/classification';
import { Status } from 'types/general';

const query = jsonToGraphQLQuery(
    {
        query: {
            __name: 'GetClassificationsOptions',
            items: {
                __aliasFor: 'classification',
                __args: {
                    where: {
                        classification_id: { _is_null: true },
                        type: { _eq: ClassificationType.Document },
                        status: { _eq: Status.Active }
                    },
                    order_by: { name: new EnumType('asc') }
                },
                value: { __aliasFor: 'id' },
                label: { __aliasFor: 'name' },
                parentId: { __aliasFor: 'classification_id' }
            }
        }
    },
    { pretty: true }
);

const DocumentsCreateOrUpdatePage = () => {
    const [subQuery, setSubQuery] = useState<string>();
    const document = useOutletContext<ProjectTypeDocument | undefined>();
    const { projectProposalTypeId, projectTypeDocumentId } = useParams();

    const { data: subClassifications } = useGetClassifications(subQuery);
    const { data: classifications, isLoading } = useGetClassifications(query);
    const { mutateAsync: createOrUpdateDocument, isLoading: isSubmitting } = useCreateOrUpdateDocument(projectProposalTypeId, projectTypeDocumentId);

    const createSubQuery = (parentId: number) => {
        const sub = jsonToGraphQLQuery({
            query: {
                __name: 'GetSubClassificationsOptions',
                items: {
                    __aliasFor: 'classification',
                    __args: {
                        where: {
                            classification_id: { _eq: parentId },
                            status: { _eq: Status.Active }
                        },
                        order_by: { name: new EnumType('asc') }
                    },
                    value: { __aliasFor: 'id' },
                    label: { __aliasFor: 'name' }
                }
            }
        });

        setSubQuery(sub);
    };

    useEffect(() => {
        if (document?.classification.father?.id && !subQuery) {
            createSubQuery(document?.classification.father?.id);
        }
    }, [document?.classification, subQuery]);

    const handleSubmit = async (data: ProjectTypeDocumentPayload) => {
        try {
            const payload: ProjectTypeDocumentPayload = {
                file: data.file,
                name: data.name,
                classification: (data.classification && subClassifications?.length ? data.classification : data.classification_holder) as number,
                ...(data.observation && { observation: data.observation }),
                ...(data.description && { description: data.description })
            };

            await createOrUpdateDocument(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    const defaultValues = useMemo<Partial<ProjectTypeDocumentPayload>>(() => {
        if (!document) {
            return {
                name: ''
            };
        }

        return {
            classification_holder: document.classification.father?.id || document.classification.id,
            classification: document.classification.id,
            file: document.file.id,
            name: document.name,
            observation: document.observation,
            description: document.description
        };
    }, [document]);

    return (
        <DocumentsCreateOrUpdate
            classifications={classifications || []}
            defaultValues={defaultValues}
            file={document ? { ...document?.file, name: document?.file.original_name } : undefined}
            subClassifications={subClassifications || []}
            isBuilding={isLoading}
            isSubmitting={isSubmitting}
            onGetSubClassifications={createSubQuery}
            onSubmit={handleSubmit}
        />
    );
};

export default DocumentsCreateOrUpdatePage;
