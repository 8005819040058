import Spinner from 'components/core/spinner';
import { useNavigate, useParams } from 'react-router-dom';
import useGetJobPositionsOptions from 'services/queries/job-positions/use-get-job-positions-options';
import usePersonContractStatusMutation from 'services/queries/person-contract-status/use-person-contract-status-mutation';
import useGetPersonContractStatusOptions from 'services/queries/person-contract-status/use-person-contract-status-options';
import theme from 'settings/theme';
import { formatDate } from 'utils/date';
import Modal from '..';
import PersonContractStatusForm from './form';
import { useQueryClient } from 'react-query';
import { getPeopleDetailsKey } from 'services/queries/people/graphql';
import useToast from 'hooks/toast/use-toast';

export type PersonContractType = 'promotion' | 'resignation' | 'situation' | 'reactivate';

type PersonContractStatusProps = {
    type: PersonContractType;
    onClose?(): void;
    contractId?: number;
    personId?: number;
};

const PersonContractStatus = ({ type, ...props }: PersonContractStatusProps) => {
    const navigate = useNavigate();
    const params = useParams();

    const queryClient = useQueryClient();

    const { showToast } = useToast();

    const personId = ~~(props?.personId || params?.personId)!;
    const contractId = ~~(props?.contractId || params?.contractId)!;

    const { data: jobPositions = [], isLoading: isLoadingPositions } = useGetJobPositionsOptions(type === 'promotion');
    const { data: contractStatus = [], isLoading: isLoadingStatus } = useGetPersonContractStatusOptions(type === 'situation');

    const handleClose = () => {
        if (props?.onClose) {
            props.onClose();
        } else {
            navigate(`/app/pessoas/${personId}/contratos`);
        }
    };

    const { isLoading: isSubmitting, mutateAsync: mutatePersonContractStatus } = usePersonContractStatusMutation({
        contractId,
        personId
    });

    const isBuilding = isLoadingPositions || isLoadingStatus;

    const onSubmit = async (data: any) => {
        try {
            const payload = {
                ...data,
                type
            };

            if (['promotion', 'situation', 'reactivate'].includes(type)) {
                payload.startDate = formatDate(data?.startDate, 'DD/MM/YYYY');
            }

            if (type === 'promotion') {
                payload.salary = data?.salary?.floatValue;
            }

            if (type === 'resignation') {
                payload.finalDate = formatDate(data?.finalDate, 'DD/MM/YYYY');
                payload.earlyWarningStartDate = formatDate(data?.earlyWarningStartDate, 'DD/MM/YYYY');
                payload.earlyWarningFinalDate = formatDate(data?.earlyWarningFinalDate, 'DD/MM/YYYY');
            }

            await mutatePersonContractStatus(payload);

            handleClose();

            queryClient.invalidateQueries(getPeopleDetailsKey(String(personId)));

            showToast('Contrato atualizado com sucesso.', 'success');
        } catch (error) {
            showToast('Desculpe, tivemos um problema para atualizar o contrato.', 'danger');
        }
    };

    return (
        <Modal onClose={handleClose} contentClassnames="w-[800px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <PersonContractStatusForm contractStatus={contractStatus} onClose={handleClose} isSubmitting={isSubmitting} jobPositions={jobPositions} type={type} onSubmit={onSubmit} />
            )}
        </Modal>
    );
};

export default PersonContractStatus;
