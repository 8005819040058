import React, { useState } from 'react';

// Dependencies
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import { useParams } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import Accordion from '@mui/material/Accordion/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';

// Components
import Terms from '../terms/terms';
import Scopes from '../scopes';
import Documents from '../documents';
import Empty from 'components/empty';
import Resources from '../resources';
import Card from 'components/core/card';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import ProjectTypeDetails from '../details';
import Spinner from 'components/core/spinner';
import MeetingRecords from '../../meeting-records';

// Helpers
import { ProjectProposalType } from 'types/models/project';
import { ProjectTypeAccordion, ProjectTypeProps } from '../types';

type BIDContentProps = {
    activeAccordion: ProjectTypeProps['activeAccordion'];
    isLoading: boolean;
    projectProposalType?: Partial<ProjectProposalType>;
    onCreateProposalType: () => void;
    onToggleAccordion: ProjectTypeProps['onToggleAccordion'];
};

const BIDContent = ({ activeAccordion, isLoading, projectProposalType, onCreateProposalType, onToggleAccordion }: BIDContentProps) => {
    const { palette } = useTheme();
    const { projectId } = useParams();

    const [tab, setTab] = useState(0);

    const isOpen = activeAccordion === ProjectTypeAccordion.BID;

    const handleAccordion = (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => onToggleAccordion(ProjectTypeAccordion.BID, expanded);

    const handleChangeTab = (_, value: number) => setTab(value);

    return (
        <Card elevation={21} className="mb-4">
            <Accordion expanded={isOpen} TransitionProps={{ unmountOnExit: true }} onChange={handleAccordion}>
                <AccordionSummary
                    classes={{ content: 'm-0', root: 'px-6 py-4', expanded: '!m-0' }}
                    expandIcon={<Icon name="ico-chevron-down" width={14} color={isOpen ? palette.heading : palette.grey[400]} />}>
                    <Text as="h5" variant="h5" className="text-heading !text-base md:!text-lg xl:!text-xl">
                        BID
                    </Text>
                </AccordionSummary>
                {isLoading ? (
                    <div className="pb-6">
                        <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                    </div>
                ) : (
                    <AccordionDetails classes={{ root: 'p-0' }}>
                        {projectProposalType ? (
                            <>
                                <Tabs variant="scrollable" indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                                    <Tab label="Detalhes" />
                                    <Tab label="Escopos" />
                                    <Tab label="Recursos" />
                                    <Tab label="Atas de reunião" />
                                    <Tab label="Documentos" />
                                </Tabs>
                                {tab === 0 && <ProjectTypeDetails projectProposalType={projectProposalType} />}
                                {tab === 1 && <Scopes projectProposalTypeProp={projectProposalType} className="p-4" />}
                                {tab === 2 && <Resources resourcesProp={projectProposalType.project_type_person_resources} className="p-4" />}
                                {tab === 3 && <MeetingRecords projectIdProp={Number(projectId)} elevation={0} projectProposalTypeIdProp={projectProposalType?.id} />}
                                {tab === 4 && <Documents projectProposalTypeIdProp={projectProposalType?.id} className="p-4" />}
                                {tab === 5 && <Terms projectProposalTypeIdProp={projectProposalType?.id} origin="project" className="p-4" />}
                            </>
                        ) : (
                            <Empty title="Proposta não encontrada." buttonLabel="Configurar proposta" className="px-6 pb-6" onClick={onCreateProposalType} />
                        )}
                    </AccordionDetails>
                )}
            </Accordion>
        </Card>
    );
};

export default BIDContent;
