import Badge from 'components/core/badge';
import { AssetsStatus } from 'types/models/assets';
import { ClassificationType } from 'types/models/classification';
import dictionary from 'utils/dictionary';
import { assetsStatuses } from 'utils/statics';

export const getAssetStatusVariant = {
    [AssetsStatus.Available]: 'success',
    [AssetsStatus.InUse]: 'info',
    [AssetsStatus.Breakdown]: 'error'
};

export const administrativeAssets = {
    [ClassificationType.AssetTechnology]: {
        title: 'Ativos Tecnologia',
        columns: [
            {
                name: 'classification.name',
                label: dictionary.content.graphql['classification'],
                options: {
                    customBodyRender: (value) => value || '-'
                }
            },
            {
                name: 'name',
                label: dictionary.content.graphql['name'],
                options: {
                    customBodyRender: (value) => value || '-'
                }
            },
            {
                name: 'brand',
                label: dictionary.content.graphql['brand'],
                options: {
                    customBodyRender: (value) => value || '-'
                }
            },
            {
                name: 'model',
                label: dictionary.content.graphql['model'],
                options: {
                    customBodyRender: (value) => value || '-'
                }
            },
            {
                name: 'serial_number',
                label: 'Série',
                options: {
                    customBodyRender: (value) => value || '-'
                }
            },
            {
                name: 'asset_status',
                label: dictionary.content.graphql['status'],
                options: {
                    customBodyRender: (value) => {
                        const status = assetsStatuses.find((item) => item.value === value);
                        const variant = getAssetStatusVariant[status?.value!] || 'grey';
                        return <Badge variant={variant}>{status?.label}</Badge>;
                    }
                }
            }
        ]
    },
    [ClassificationType.AssetFurniture]: {
        title: 'Ativos Mobiliário',
        columns: [
            {
                name: 'classification.name',
                label: dictionary.content.graphql['classification']
            },
            {
                name: 'name',
                label: dictionary.content.graphql['name']
            },
            {
                name: 'brand',
                label: dictionary.content.graphql['brand'],
                options: {
                    customBodyRender: (value = '-') => value
                }
            },
            {
                name: 'model',
                label: dictionary.content.graphql['model']
            },
            {
                name: 'asset_status',
                label: dictionary.content.graphql['status'],
                options: {
                    customBodyRender: (value) => {
                        const status = assetsStatuses.find((item) => item.value === value);
                        const variant = getAssetStatusVariant[status?.value!] || 'grey';

                        return <Badge variant={variant}>{status?.label}</Badge>;
                    }
                }
            }
        ]
    }
};
