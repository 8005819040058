import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';

import queries, { getAllProjectServicesKey } from './graphql';
import { Option } from 'types/general';
import { ProjectTypeEnum } from 'types/models/project';

type Response = {
    project_service: Option[];
};

const useGetProjectServicesOptions = (projectTypeId?: ProjectTypeEnum) => {
    const request = async () => {
        const { project_service } = await apiGraphQl<Response>(queries.getProjectServicesOptions(projectTypeId));

        return project_service;
    };

    return useQuery(getAllProjectServicesKey, request);
};

export default useGetProjectServicesOptions;
