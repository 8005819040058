import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export type TextElementVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'strong' | 'span' | 'label' | 'figcaption' | 'p';
export type TextVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body.regular.xs' | 'body.regular.sm' | 'body.regular.2xs' | 'body.medium.xs' | 'body.medium.sm' | 'body.medium.2xs';

type TextProps = {
    as?: TextElementVariant;
    variant?: TextVariant;
};

const classes = new Map<TextVariant, string>([
    ['h1', 'text-xl sm:text-2xl md:text-4xl font-bold'],
    ['h2', 'text-lg xl:text-4xl font-medium'],
    ['h3', 'text-base xl:text-4xl font-medium'],
    ['h4', 'text-sm xl:text-3xl font-medium'],
    ['h5', 'text-sm xl:text-xl font-medium'],
    ['h6', 'text-xs xl:text-lg font-medium'],
    ['body.regular.xs', 'text-xs'],
    ['body.regular.sm', 'text-sm'],
    ['body.regular.2xs', 'text-2xs'],
    ['body.medium.xs', 'text-xs font-medium'],
    ['body.medium.sm', 'text-sm font-medium'],
    ['body.medium.2xs', 'text-2xs font-medium']
]);

const Text: React.FC<TextProps & HTMLAttributes<HTMLElement>> = ({ as: Element = 'span', children, className, style, variant = 'body.regular.sm', ...props }) => {
    const variantClasses = classNames(classes.get(variant), className);

    return (
        <Element className={variantClasses} style={style} {...props}>
            {children}
        </Element>
    );
};

export default Text;
