import ListPage from 'pages/private/crud/list';
import { CrudPageProps } from 'types/graphql';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import { Person } from 'types/models/person';
import { useOutletContext } from 'react-router-dom';
import { Status } from 'types/general';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

const PersonDetailsProjects = ({ page }: WithPagePermissionsProps) => {
    const { id } = useOutletContext<Partial<Person>>();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const listPageProps: CrudPageProps = {
        title: 'Projetos',
        graphql: {
            table: 'project_type_person_resource',
            searchableField: 'project_proposal_type.project_proposal.project.title',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'project_proposal_type.project_proposal.project.id',
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    name: 'project_proposal_type.project_proposal.project.title',
                    label: dictionary.content.graphql['title']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    },
                    customRoutePath: (item) => `/app/contratos/${item?.project_proposal_type?.project_proposal?.project?.id}`
                }
            ],
            where: {
                person_id: {
                    _eq: id
                },
                project_proposal_type: { project_proposal: { project: { status: { _eq: Status.Active } } } }
            }
        },
        hideHeader: true
    };

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <ListPage page={page} {...listPageProps} />
        </>
    );
};

export default PersonDetailsProjects;
