import Modal from 'components/core/modal';

import Text from 'components/core/text';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/core/button';
import { object } from 'yup';

import Spinner from 'components/core/spinner';

import useProviderServicesTypesMutation from 'services/queries/provider-services-types/use-create-update-service-type-classification';
import useGetServiceType from 'services/queries/provider-services-types/use-get-provider-service-type';

import { createOrUpdateProviderClassificationMutation } from 'services/queries/provider-services-types/utils/utils';
import { useEffect } from 'react';

import { GraphqlPaginationVariables } from 'types/graphql';
import ClassificationFields from './components/classifications/fields';

const schema = object({
    classification_id: object().nullable(true),
    subcategory_id: object().nullable(true)
});

const ProviderServicesTypesForm = () => {
    const { serviceTypeId, providerId } = useParams();
    const navigate = useNavigate();
    const methods = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(schema)
    });

    const { handleSubmit, reset } = methods;

    const params = useOutletContext<GraphqlPaginationVariables<any>>();

    const isEdit = !!providerId && !!serviceTypeId;

    const { mutate: createOrUpdateServiceType, isLoading: isSubmitting } = useProviderServicesTypesMutation(
        providerId as string,
        serviceTypeId,
        params,
        createOrUpdateProviderClassificationMutation(providerId, serviceTypeId)
    );

    const { data: servicesTypesDetails, isLoading: isLoadingDetails } = useGetServiceType(serviceTypeId, providerId);

    const handleClose = () => {
        if (isLoadingDetails) return;

        navigate(-1);
    };

    const onSubmit = (data: any) => {
        const payload = { classification_id: data?.subcategory_id?.value ? data.subcategory_id?.value : data?.classification_id?.value, provider_id: providerId };

        createOrUpdateServiceType(payload);
    };

    useEffect(() => {
        if (!!servicesTypesDetails?.classification) {
            const isChildren = servicesTypesDetails.classification?.classification;

            const formatData = {
                ...(isChildren
                    ? {
                          classification_id: {
                              value: servicesTypesDetails?.classification.classification?.id,
                              label: servicesTypesDetails?.classification.classification?.name
                          },
                          subcategory_id: {
                              value: servicesTypesDetails?.classification?.id,
                              label: servicesTypesDetails?.classification?.name
                          }
                      }
                    : {
                          classification_id: {
                              value: servicesTypesDetails?.classification?.id,
                              label: servicesTypesDetails?.classification?.name
                          },
                          subcategory_id: {
                              value: servicesTypesDetails?.classification.classification?.id,
                              label: servicesTypesDetails?.classification.classification?.name
                          }
                      })
            };

            reset(formatData);
        }
    }, [servicesTypesDetails, reset]);

    return (
        <Modal contentClassnames="w-[800px]" onClose={handleClose}>
            {isLoadingDetails ? (
                <div className="h-[150px]">
                    <Spinner size={30} fixed={true} />
                </div>
            ) : (
                <form className="py-5 px-3 sm:px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {isEdit ? 'Editar' : 'Novo'} tipo de serviço
                    </Text>
                    <div className="mb-7 group relative">
                        <ClassificationFields methods={methods} />
                    </div>

                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default ProviderServicesTypesForm;
