import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { Modify } from 'types/general';
import { NewMeasurementPayload } from 'types/models/provider';
import { getFinancialSetupKey, updateMeasurementKey } from './graphql';

type Payload = Modify<NewMeasurementPayload, { measurementResidue: number }>;

const useUpdateMeasurement = (providerFinancialSetupId?: number, measurementId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: Payload) => {
        if (!providerFinancialSetupId || !measurementId) {
            throw Error();
        }

        return api.patch(`/provider-financial-setups/${providerFinancialSetupId}/measurements/${measurementId}`, payload);
    };

    return useMutation(updateMeasurementKey(measurementId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getFinancialSetupKey(providerFinancialSetupId));

            navigate(-1);

            showToast('Medição realizada com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao realizar a medição', 'danger');
        }
    });
};

export default useUpdateMeasurement;
