import Spinner from 'components/core/spinner';
import { useOutletContext } from 'react-router-dom';
import { CrudPageProps } from 'types/graphql';
import { BaseModel } from 'types/models/base';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import ListPage from 'pages/private/crud/list';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';

const ProviderServicesTypesPage = ({ page }: WithPagePermissionsProps) => {
    const { id } = useOutletContext<Partial<BaseModel>>();

    if (!id) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    const listConfig: CrudPageProps = {
        graphql: {
            table: 'provider_has_classification',
            searchableField: 'classification.name',
            statusPath: 'provider.status',
            columns: [
                {
                    name: 'classification.id',
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    name: 'classification.name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'classification.classification.name',
                    label: 'Classificação Pai'
                },

                {
                    name: 'actions',
                    label: 'Ações',

                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ],
            where: {
                provider: {
                    id: { _eq: id }
                }
            }
        },
        title: 'Tipos de serviços',
        withBorder: true,
        api: {
            endpoint: `/providers/${id}/classifications`,
            useApiTodelete: true
        }
    };

    return <ListPage page={page} {...listConfig} />;
};

export default ProviderServicesTypesPage;
