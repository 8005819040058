import { useState } from 'react';
import Avatar from '@mui/material/Avatar/Avatar';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import useTheme from '@mui/material/styles/useTheme';
import logoJP from 'assets/images/logo-jp.png';
import Text from 'components/core/text';
import hexToRgba from 'utils/hex-to-rgba';
import Icon from 'components/core/icon';
import { formatDate } from 'utils/date';
import { useOutletContext } from 'react-router-dom';
import { OperationalDetailsPageContext } from '.';
import { generateFileUrl } from 'utils/file';
import Button from 'components/core/button';
import ChangeImageModal from 'components/core/modal/change-image';
import useChangeProjectTypeImage from 'services/queries/operationals/use-change-project-type-image';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

const ConstructionDetails = () => {
    const { palette } = useTheme();
    const { project, projectProposalType } = useOutletContext<Partial<OperationalDetailsPageContext>>();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const [isModalPhoto, setIsModalPhoto] = useState(false);
    const { isLoading: isSubmittingImage, mutateAsync: updateImage } = useChangeProjectTypeImage(projectProposalType?.id);

    const featuredImage = generateFileUrl(projectProposalType?.file?.filename, projectProposalType?.file?.path);

    const handleUpdateImage = async (data: File) => {
        try {
            await updateImage(data);

            setIsModalPhoto(false);
        } catch (error) {
            console.log('handleUpdateImage', error);
        }
    };

    return (
        <>
            <div
                className="flex items-end bg-no-repeat bg-cover h-72 -m-6 -mt-12 mb-6 relative bg-primary-100"
                style={{ backgroundImage: `url(${featuredImage})`, backgroundPosition: 'center bottom' }}>
                <div className="absolute z-10 w-full h-full bg-gradient-to-b from-transparent to-black" />
                <div className="relative z-20 p-6 pb-16 sm:pb-6">
                    <CardHeader
                        avatar={
                            <div
                                className="w-12 h-12 rounded-[14px] bg-cover bg-center bg-no-repeat"
                                style={{ backgroundImage: `url(${project?.customer.file?.filename ? generateFileUrl(project?.customer.file?.filename, project?.customer.file?.path) : logoJP})` }}
                            />
                        }
                        classes={{ root: 'p-0' }}
                        title={
                            <>
                                {project?.title}
                                <Text as="span" variant="body.medium.sm" className="text-base-400 uppercase ml-2">
                                    #{project?.code}
                                </Text>
                            </>
                        }
                        subheader={project?.customer.title}
                        titleTypographyProps={{ fontSize: 18, color: palette.grey[100], fontWeight: 500 }}
                        subheaderTypographyProps={{ color: palette.grey[300] }}
                    />
                </div>
                <div className="absolute right-6 bottom-6 z-20">
                    <Button variant="contained" size="small" color="inherit" onClick={() => setIsModalPhoto(true)}>
                        Alterar imagem destacada
                    </Button>
                </div>
            </div>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 mb-10">
                {projectProposalType?.start_date && (
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-calendar" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title="Data de início"
                        subheader={formatDate(projectProposalType.start_date, 'DD [de] MMMM [de] YYYY')}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                )}
                {projectProposalType?.end_date && (
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-calendar" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title="Data final"
                        subheader={formatDate(projectProposalType.end_date, 'DD [de] MMMM [de] YYYY')}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                )}
                {!!project?.footage && (
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-ruler" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title="Metragem (m²)"
                        subheader={Number(project.footage).toLocaleString('pt-BR')}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                )}
            </div>
            {Boolean(project?.address?.length) && (
                <>
                    <Text as="span" variant="body.medium.sm" className="text-base-700 uppercase mb-[10px] block">
                        Endereço(s)
                    </Text>
                    <div className="rich-content mb-10">
                        {project?.address?.map((item, index) => (
                            <Text key={index} variant="body.regular.sm" as="span" className="text-base-500 block mb-1 leading-5">
                                {item.address.street}
                                {', '}
                                {item.address.number}
                                {' - '}
                                {item.address.compliment}
                                {', '}
                                {item.address.neighbourhood}
                                {' - '}
                                {item.address.city?.name}/{item.address.state?.initials}
                                {', '}
                                {item.address.zip}
                            </Text>
                        ))}
                    </div>
                </>
            )}
            {Boolean(project?.customer.contacts?.length) && (
                <>
                    <Text as="span" variant="body.medium.sm" className="text-base-700 uppercase mb-[10px] block">
                        Contato(s)
                    </Text>
                    <div className="rich-content mb-10">
                        {project?.customer.contacts?.map((item, index) => (
                            <div key={index}>
                                <Text as="span" className="mr-2 text-heading">
                                    {item.person?.name}
                                </Text>
                                {Boolean(item?.person?.phones?.length) && (
                                    <Text variant="body.regular.sm" as="span" className="text-base-500 text-right">
                                        {item?.person?.phones?.map((item) => `(${item.ddd}) ${item.number}`).join(', ')}
                                    </Text>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
            <Text as="span" variant="body.medium.sm" className="text-base-700 uppercase mb-[10px] block">
                Descrição
            </Text>
            <div className="rich-content mb-10" dangerouslySetInnerHTML={{ __html: project?.notes || '' }} />
            {isModalPhoto && <ChangeImageModal onSubmit={handleUpdateImage} isLoading={isSubmittingImage} title="Alterar imagem destacada" onClose={() => setIsModalPhoto(false)} />}
        </>
    );
};

export default ConstructionDetails;
