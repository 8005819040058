// Dependencies
import { useParams } from 'react-router-dom';

// Helpers
import useGetProvider from 'services/queries/providers/use-get-provider';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import ProviderDetails from './details';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';

const Provider = ({ page }: WithPagePermissionsProps) => {
    const { providerId } = useParams();
    const provider = useGetProvider({ providerId, isDetails: true });

    useBreadcrumb(provider?.data?.title);

    if (provider.isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    if (Boolean(provider.error) || !provider.data) {
        return <Empty title="Contrato não encontrado" />;
    }

    return <ProviderDetails subpages={page?.subpages || []} provider={provider.data} />;
};

export default Provider;
