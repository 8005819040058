import React, { forwardRef, InputHTMLAttributes } from 'react';

// Dependencies
import classNames from 'classnames';

// Components
import Text from 'components/core/text';
import ErrorMessage from 'components/error/message';

type RadioProps = {
    containerClasseName?: string;
    inputClassName?: string;
    labelClassName?: string;
    error?: string;
    label?: string;
};

const Radio = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & RadioProps>(({ containerClasseName, inputClassName, labelClassName, error, label, ...props }, ref) => {
    const radioClasses = classNames(
        radio,
        error ? 'border-system-danger-500 group-hover:border-system-danger-600' : 'border-base-300 group-hover:border-base-400',
        props.checked && `border-secondary-500 radio-selected group-hover:border-secondary-500`
    );

    const fakeRadioClasses = classNames(fakeRadio, error && 'border-system-danger-500', inputClassName);
    const labelClasses = classNames(labelStyle, labelClassName, error ? 'text-system-danger-500' : 'text-base-500');

    return (
        <div className={containerClasseName}>
            <label className={labelWrapper}>
                <div className="flex flex-row-reverse relative">
                    <input ref={ref} type="radio" {...props} className={fakeRadioClasses} />
                    <span className={radioClasses} />
                </div>
                {label && (
                    <Text as="span" variant="body.regular.sm" className={labelClasses}>
                        {label}
                    </Text>
                )}
            </label>
            <ErrorMessage visible={Boolean(error)}>{error}</ErrorMessage>
        </div>
    );
});

const { radio, fakeRadio, labelWrapper, labelStyle } = {
    fakeRadio: 'h-4 left-0 opacity-0 absolute top-0 w-4 z-[1] cursor-pointer',
    labelWrapper: 'group items-center cursor-pointer flex',
    radio: 'bg-base-100 border rounded-full items-center flex h-4 justify-center w-4 radio',
    labelStyle: 'whitespace-nowrap ml-2'
};

export default Radio;
