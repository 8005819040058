import { useContext } from 'react';
import { array, number, object, string } from 'yup';
import useTheme from '@mui/material/styles/useTheme';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Button from 'components/core/button';
import AddButton from 'components/buttons/add';
import ErrorMessage from 'components/error/message';
import dictionary from 'utils/dictionary';
import { RequestContext } from 'contexts/request';
import { RequestSteps } from 'contexts/request/types';
import { AttentionPointPayload } from 'types/models/project';
import Spinner from 'components/core/spinner';
import { RequestTabCommonProps } from './types';
import useCreateOrUpdateSolicitation from 'services/queries/projects/use-create-or-update-solicitation';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { formatDate } from 'utils/date';
import useGetPeopleByProjectOptions from 'services/queries/people/use-get-people-options-by-project';
import AttentionPointFields from './attention-point-fields';

export type RequestAttentionPointsPayload = { attentionPoints: AttentionPointPayload[] };

const schema = object({
    attentionPoints: array(
        object({
            type: number().required(dictionary.validation.required),
            task: object({
                title: string().required(dictionary.validation.required),
                description: string().required(dictionary.validation.required),
                person: number().required(dictionary.validation.required),
                deadline: string().required(dictionary.validation.required)
            })
        })
    ).required(dictionary.validation.required)
});

const INITIAL_ITEM = {
    task: {
        title: '',
        description: '',
        deadline: ''
    }
};

const RequestAttentionPoints = ({ projectProposalTypeId, projectTypeSolicitationId, solicitation }: RequestTabCommonProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const listQuery = useOutletContext<string>();

    const { data: people = [], isLoading } = useGetPeopleByProjectOptions(solicitation.projectType.project.id);

    const { state, changeStep } = useContext(RequestContext);

    const { mutateAsync: createOrUpdateSolicitation, isLoading: isSubmitting } = useCreateOrUpdateSolicitation<RequestAttentionPointsPayload>(
        listQuery,
        projectProposalTypeId,
        projectTypeSolicitationId
    );

    const methods = useForm<RequestAttentionPointsPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: {
            attentionPoints: state.content.attentionPoints.map((item) => ({
                backendId: (item as any).id,
                type: item.type,
                task: {
                    deadline: item.task.deadline,
                    description: item.task.description,
                    person: (item as any).task.person?.id,
                    title: item.task.title
                }
            }))
        },
        resolver: yupResolver(schema)
    });

    const { control, formState, handleSubmit } = methods;

    const { fields, append, remove } = useFieldArray({ name: 'attentionPoints', control });

    const handleAdd = () => append(INITIAL_ITEM);

    const handleDelete = (index: number) => () => remove(index);

    const submit = async (data: RequestAttentionPointsPayload) => {
        try {
            const payload = {
                attentionPoints: data.attentionPoints.map((item) => ({
                    ...item,
                    task: {
                        ...item.task,
                        deadline: formatDate(item.task.deadline, 'YYYY-MM-DD')
                    }
                }))
            };

            await createOrUpdateSolicitation(payload);

            navigate(-1);
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleBack = () => changeStep(state.currentStep.slug, undefined, RequestSteps.Files);

    if (isLoading) {
        return (
            <div className="p-4">
                <Spinner color={palette.secondary[100]} fixed={false} size={20} />
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="pt-7 px-3 sm:p-[30px]">
                <Text as="h3" variant="h4" className="text-heading mb-5">
                    Pontos de atenção
                </Text>
                {Boolean(fields.length) ? (
                    fields.map((item, index) => (
                        <AttentionPointFields
                            backendId={item.backendId}
                            control={control}
                            fieldNamePrefix={`attentionPoints.${index}`}
                            errorPrefix={formState.errors.attentionPoints?.[index]}
                            people={people}
                            key={item.id}
                            onDelete={handleDelete(index)}
                        />
                    ))
                ) : (
                    <div className="border border-base-300 p-4 rounded-2xl mb-2 group">
                        <Empty title="Nenhum ponto de atenção adicionado até o momento" />
                    </div>
                )}
                <ErrorMessage visible={Boolean((formState as any).errors?.subjects?.message)} className="block mb-4">
                    {(formState as any).errors?.subjects?.message}
                </ErrorMessage>
                <AddButton onClick={handleAdd}>Mais pontos de atenção</AddButton>
            </div>
            <div className="border-t p-2 flex justify-between">
                <Button startIcon={<Icon name="ico-arrow-left" width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                    Voltar
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="text" color="secondary" className="min-w-[100px]">
                    Salvar
                </Button>
            </div>
        </form>
    );
};

export default RequestAttentionPoints;
