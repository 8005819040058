// Dependencies
import { useNavigate } from 'react-router-dom';

// Components
import ProjectContent from './project';

// Helpers
import { ProjectTypeAccordion, ProjectTypeProps } from '../types';
import useGetProjectProposalType from 'services/queries/projects/use-get-project-proposal-type';

const ArchitecturalProject = ({ activeAccordion, projectTypeId, projectProposalId, projectProposalTypeId, onToggleAccordion }: ProjectTypeProps) => {
    const navigate = useNavigate();

    const { data: projectProposalType, isLoading } = useGetProjectProposalType(projectProposalTypeId);

    const handleGoToCreateProposalType = () => navigate(`proposta/${projectProposalId}/tipo/${projectTypeId}`);

    return (
        <ProjectContent
            title="Projetos de Arquitetura"
            projectTypeAccordion={ProjectTypeAccordion.Project}
            activeAccordion={activeAccordion}
            isLoading={isLoading}
            projectProposalType={projectProposalType}
            onCreateProposalType={handleGoToCreateProposalType}
            onToggleAccordion={onToggleAccordion}
        />
    );
};

export default ArchitecturalProject;
