import { ProjectTransactions } from 'types/general';

export const DOCUMENTS_INITIAL_STATE = { name: '', classification: undefined, file: undefined };

export const INITIAL_FORM_STATE = {
    type: ProjectTransactions.Outcome,
    description: '',
    observation: '',
    dueDate: '',
    payday: '',
    value: {
        floatValue: '',
        value: ''
    }
};
