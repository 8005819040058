import { useState } from 'react';
import Card from 'components/core/card';
import Spinner from 'components/core/spinner';
import PageHeader from 'components/page/header';
import { PeopleMapFilter } from 'services/queries/people-map/types';
import useGetMapOfPeopleByAllocation from 'services/queries/people-map/use-get-map-of-people-by-allocation';
import Content from './content';
import Filters from './filters';
import Team from './team';
import { PeopleMapResponse } from './types';
import Weeks from './weeks';
import Empty from 'components/empty';

const quantityItemsToHaveScroll = 4;

const PeopleMap = () => {
    const branchState = useState<number>();
    const departmentState = useState<number>();
    const monthState = useState<number | undefined>(new Date().getMonth() + 1);
    const yearState = useState<number | undefined>(new Date().getFullYear());
    const filtersState = useState<PeopleMapFilter[]>([]);

    const [filters, setFilters] = filtersState;

    const { data, isLoading } = useGetMapOfPeopleByAllocation<PeopleMapResponse>(filters);

    const { weeks = [], people = [] } = data || {};

    const hasScrollX = weeks.length > quantityItemsToHaveScroll;

    return (
        <div>
            {isLoading && <Spinner parentClasses="bg-base-100/20 !z-50" />}
            <PageHeader
                title="Mapa de pessoa por alocação"
                className="mb-7"
                right={<Filters branchState={branchState} yearState={yearState} monthState={monthState} departmentState={departmentState} onSetFilters={setFilters} />}
            />

            {!isLoading && !!data?.people?.length ? (
                <Card>
                    <div className={`flex ${hasScrollX ? 'max-h-[68.5vh]' : 'max-h-[69.5vh]'} overflow-y-auto`}>
                        <div className="sticky h-max z-[998] bg-white left-0">
                            <Team items={people} />
                        </div>
                        <div className="flex-1 overflow-initial">
                            <Weeks items={weeks} />
                            <Content weeks={weeks} people={people} />
                        </div>
                    </div>
                </Card>
            ) : (
                <Empty title="Não foi executada consulta ou nenhum dado foi encontrado." />
            )}
        </div>
    );
};

export default PeopleMap;
