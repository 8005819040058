import { useMemo } from 'react';

import { useOutletContext, useParams } from 'react-router-dom';

import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';

import { CreateClassificationPayload } from 'types/models/configurations-classifications';
import useGetClassificationId from 'services/queries/configs-classifications/use-get-classification-by-id';
import useCreateOrUpdateClassification from 'services/queries/configs-classifications/use-create-update-classification-asset';

import { ClassificationType } from 'types/models/classification';
import { createOrUpdateClassificationMutation } from 'pages/private/config-classifications/utils/utils';
import CreateUpdateClassificationScope from './create-or-update';

const CreateConfigClassificationScopePage = ({ title, type }: CrudPageProps & { type: ClassificationType }) => {
    const { scopeId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<CreateClassificationPayload>>();

    const {
        mutateAsync: mutateCreateOrUpdateClassification,
        isLoading: isSubmitting,
        error: errorCreateUpdate
    } = useCreateOrUpdateClassification(scopeId, params, createOrUpdateClassificationMutation(scopeId));

    const { data: classificationDetails, isLoading: isLoadingDetails, error: errorGetClassificationId } = useGetClassificationId(scopeId);

    const error = useMemo(() => errorCreateUpdate || errorGetClassificationId, [errorCreateUpdate, errorGetClassificationId]);

    const isBuilding = useMemo(() => isSubmitting || isLoadingDetails, [isSubmitting, isLoadingDetails]);

    const handleSubmit = async (data: CreateClassificationPayload & { project_service_id: number }) => {
        try {
            await mutateCreateOrUpdateClassification({ ...data, type });
        } catch (error) {
            console.log('error', error);
        }
    };

    const newDefaultValues = useMemo(
        () => ({
            name: classificationDetails?.name,
            id: classificationDetails?.id,
            classification_id: classificationDetails?.classification?.id,
            project_service_id: classificationDetails?.project_service?.id
        }),
        [classificationDetails?.name, classificationDetails?.id, classificationDetails?.classification?.id, classificationDetails?.project_service?.id]
    );

    return <CreateUpdateClassificationScope isBuilding={isBuilding} isSubmitting={isSubmitting} onSubmit={handleSubmit} defaultValues={newDefaultValues} error={error} title={title || ''} />;
};

export default CreateConfigClassificationScopePage;
