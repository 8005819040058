import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar/Avatar';
import Text from 'components/core/text';
import TimeAgo from 'components/timeago';
import { TaskComment as TaskCommentModel } from 'types/models/task';
import { useParams } from 'react-router-dom';
import useDeleteTaskComment from 'services/queries/task/use-delete-task-comment';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import hexToRgba from 'utils/hex-to-rgba';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import useAuth from 'store/auth/use-auth';

const TaskComment = ({ person, createdAt, text, id }: TaskCommentModel) => {
    const { palette } = useTheme();
    const { taskId } = useParams();
    const { auth } = useAuth();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { mutateAsync: deleteComment, isLoading: isDeletingComment } = useDeleteTaskComment(id, taskId);

    const handleDeleteComment = async () => {
        try {
            await deleteComment();
        } catch (error) {
            console.log('TaskComment -> handleDeleteComment', error);
        }
    };

    return (
        <>
            <div className="flex items-start mb-8 last:mb-0">
                <Avatar src={person.profileImage?.url as string} sx={{ width: 30, height: 30, marginRight: 2 }}>
                    {person.title.charAt(0)}
                </Avatar>
                <div className="flex-1">
                    <div className="flex items-baseline mb-[2px]">
                        <Text as="span" variant="body.medium.2xs" className="text-heading mr-2">
                            {person.title}
                        </Text>
                        <TimeAgo date={createdAt} className="text-base-500 text-xs" />
                    </div>
                    <Text as="p" variant="body.regular.sm" className="text-base-700 whitespace-pre-wrap">
                        {text}
                    </Text>
                </div>
                {auth.credentials.user.person?.id === person.id && (
                    <Tooltip placement="left" title="Apagar comentário" disableFocusListener={true}>
                        <IconButton className="relative opacity-100" sx={{ '&:hover': { backgroundColor: hexToRgba(palette.error[100], 0.3) } }} onClick={() => setIsModalOpen(true)}>
                            <Icon name="ico-trash" width={14} height={14} color={palette.error[500]} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {isModalOpen && (
                <Modal closeOnClickOutside={false} onClose={() => setIsModalOpen(false)}>
                    <div className="p-6 min-w-[500px]">
                        <Text as="h6" variant="h5" className="text-heading mb-3">
                            Apagar comentário
                        </Text>
                        <Text as="p" variant="body.regular.sm" className="text-base-700 mb-6">
                            Você tem certeza que deseja apagar este comentário?
                        </Text>
                        <div className="flex items-center justify-end">
                            <Button
                                disabled={isDeletingComment}
                                loading={isDeletingComment}
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className="min-w-[100px] mr-4"
                                onClick={handleDeleteComment}
                            >
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={() => setIsModalOpen(false)}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default TaskComment;
