// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import useToast from 'hooks/toast/use-toast';
import project, { createProjectSignpostsKey, getProjectKey } from './graphql';

const useCreateProjectSignposts = (projectId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const createProjectSignposts = async (payload: any) => apiGraphQl(project.createProjectSignposts, { payload });

    return useMutation(createProjectSignpostsKey(projectId), createProjectSignposts, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectKey(projectId));

            navigate(-1);

            showToast('Placas configuradas com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao configurar as placas', 'danger');
        }
    });
};

export default useCreateProjectSignposts;
