import React, { useState } from 'react';
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Empty from 'components/empty';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import { ProjectTypeSolicitation } from 'types/models/project';
import { CamelCase } from 'types/utils';
import RequestDetails from './details';
import Files from 'pages/private/projects/meeting-records/components/publish/files';
import RequestAttentionPoints from './attention-points';
import useGetQueryParam from 'hooks/router/use-get-query-param';

const Request = (props: UseQueryResult<CamelCase<ProjectTypeSolicitation>, unknown>) => {
    const navigate = useNavigate();
    const tabParam = useGetQueryParam('tab');

    const [tab, setTab] = useState(tabParam ?? 'DETAILS');

    const handleClose = () => navigate(-1);

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    return (
        <Modal closeOnClickOutside={false} contentClassnames="w-[992px]" onClose={handleClose}>
            {props.isLoading ? (
                <Spinner />
            ) : Boolean(props.error) || !Boolean(props.data) ? (
                <Empty title="Ocorreu um erro ao carregar a ata de reunião" className="p-6" />
            ) : (
                <div className="px-3 py-7 sm:p-7">
                    <Text as="h3" variant="h4" className="text-heading mb-3">
                        {props.data?.number}
                    </Text>
                    <Tabs variant="scrollable" classes={{ root: 'px-0' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                        <Tab value="DETAILS" label="Detalhes" />
                        <Tab value="FILES" label="Arquivos" />
                        <Tab value="ATTENTION_POINTS" label="Pontos de atenção" />
                    </Tabs>
                    {tab === 'DETAILS' && (
                        <RequestDetails description={props.data?.description} observations={props.data?.observations} classifications={props.data?.classifications} subject={props.data?.subject} />
                    )}
                    {tab === 'FILES' && <Files files={props.data?.files || []} className="border-0 mb-0 px-0" withTableHead={false} />}
                    {tab === 'ATTENTION_POINTS' && (
                        <RequestAttentionPoints
                            solicitationId={props.data?.id}
                            projectProposalTypeId={props.data?.projectType?.id}
                            projectId={props.data?.projectType?.project?.id}
                            attentionPoints={[...(props.data?.criticalAttentionPoints || []), ...(props.data?.relevantAttentionPoints || [])]}
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};

export default Request;
