import { useNavigate, useParams } from 'react-router-dom';
import { getAssetStatusVariant } from 'settings/routes/entities/utils/administrative';
import { assetsStatuses } from 'utils/statics';

import Empty from 'components/empty';
import Spinner from 'components/core/spinner';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Badge from 'components/core/badge';

import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import useGetAssetById from 'services/queries/assets/use-get-asset-by-id';

import AssetDetails from './asset-details';
import Divider from '@mui/material/Divider/Divider';

const AssetDetailsPage = () => {
    const { assetId } = useParams();
    const navigate = useNavigate();

    const { data: assetDetails, isLoading, isError } = useGetAssetById(assetId);

    useBreadcrumb(assetDetails?.name);

    const handleClose = () => {
        navigate(-1);
    };

    const assetStatus = assetsStatuses.find((item) => item.value === +assetDetails?.asset_status!);

    return (
        <Modal
            contentClassnames="w-[1200px]"
            onClose={handleClose}
            headerLeft={
                <div className="flex items-center">
                    <Text as="h3" variant="h3" className="mr-4 !text-3xl font-medium text-heading">
                        {assetDetails?.name || '-'}
                    </Text>
                    {!!assetDetails?.asset_status && <Badge variant={getAssetStatusVariant[assetStatus?.value!] || 'grey'}>{assetStatus?.label}</Badge>}
                </div>
            }>
            <Divider />
            {isLoading ? (
                <Spinner />
            ) : isError || !assetDetails?.id ? (
                <div className="p-6">
                    <Empty title="Ativo não encontrado" />{' '}
                </div>
            ) : (
                <AssetDetails asset={assetDetails} />
            )}
        </Modal>
    );
};

export default AssetDetailsPage;
