import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useGetTask from 'services/queries/task/use-get-task';

const TaskPage = () => {
    const { taskId } = useParams();

    const task = useGetTask(taskId);

    return <Outlet context={task} />;
};

export default TaskPage;
