import React, { createContext, useContext, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import theme from 'settings/theme';
import MuiMenu from '@mui/material/Menu/Menu';
import { menuConfig } from 'components/menu';
import _set from 'lodash/set';

type Props = {
    component: React.ReactNode;
};

const TableAdvancedSearch = ({ component }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const config = useMemo(() => {
        const defaultConfig = { ...menuConfig };

        // Best way to edit nested items without messing the original object
        _set(defaultConfig, 'PaperProps.style.marginTop', 7);
        _set(defaultConfig, 'PaperProps.style.width', 350);

        return defaultConfig;
    }, []);

    return (
        <>
            <IconButton onClick={handleOpen} className="bg-base-200 p-0 w-[40px] h-[40px] absolute right-[4px] top-[4px] rounded-[14px]" size="large" color="default">
                <Icon name="ico-filter" color={theme.extend.colors.heading} width={20} height={20} />
            </IconButton>

            <MuiMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} {...config}>
                <div className="py-5 px-6">
                    <p className="text-heading text-2xl font-medium mb-5">Filtro</p>
                    <TableAdvancedSearchContext.Provider value={{ onClose: handleClose }}>{component}</TableAdvancedSearchContext.Provider>
                </div>
            </MuiMenu>
        </>
    );
};

const TableAdvancedSearchContext = createContext({ onClose: () => {} });

export const useTableAdvancedSearch = () => {
    return useContext(TableAdvancedSearchContext);
};

export default TableAdvancedSearch;
