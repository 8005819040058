import React from 'react';

// Dependencies
import { useParams } from 'react-router-dom';

// Components
import CreateOrUpdateResources from './create-or-update';

// Helpers
import { ProjectProposalResourcesPayload } from 'types/models/project';
import useCreateOrUpdateProjectProposalResources from 'services/queries/projects/use-create-or-update-project-proposal-resources';
import useGetProjectProposalResourceById from 'services/queries/projects/use-get-project-proposal-resouce-by-id';

const CreateOrUpdateResourcesPage = () => {
    const { projectId, resourceId } = useParams();

    const { data: resourceData } = useGetProjectProposalResourceById(resourceId!);

    const { mutateAsync, isLoading: isSubmitting } = useCreateOrUpdateProjectProposalResources(Number(projectId), resourceId);

    const handleSubmit = async (data: ProjectProposalResourcesPayload) => {
        try {
            await mutateAsync(Boolean(resourceId) ? data.resources[0] : data.resources);
        } catch (error) {
            console.log('error', error);
        }
    };

    return <CreateOrUpdateResources isSubmitting={isSubmitting} onSubmit={handleSubmit} defaultValues={resourceData} />;
};

export default CreateOrUpdateResourcesPage;
