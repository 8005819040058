import React, { useMemo, useState } from 'react';
import Table from '@mui/material/Table/Table';
import { Link, Outlet, useLocation, useOutletContext } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import { ApprovalStatus } from 'types/general';
import { ProjectManagementInformation } from 'types/models/project';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import useGetCisos from 'services/queries/projects/use-get-cisos';
import { formatDate } from 'utils/date';
import Badge from 'components/core/badge';
import Spinner from 'components/core/spinner';
import useGetActiveCiso from 'services/queries/projects/use-get-active-ciso';

import CisoDetails from 'pages/private/projects/ciso/view/ciso-details';
import Modal from 'components/core/modal';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';

type CisoProps = {
    projectIdProp?: number | null;
    ciso?: Partial<ProjectManagementInformation>;
    withActions?: boolean;
    withTitle?: boolean;
    projectProposalTypeId?: string;
};

const infoButton = { classes: { child: 'text-system-info-100' } };
const warningButton = { classes: { child: 'text-system-warning-100' } };

const CisoContainer = ({ withTitle, projectIdProp, withActions = false, projectProposalTypeId }: CisoProps) => {
    const { palette } = useTheme();
    const [signpostUrl, setSignpostUrl] = useState<string>();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const { pathname } = useLocation();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const projectId = useMemo(() => projectIdProp || ctx?.project?.id, [projectIdProp, ctx?.project]);

    const { data: cisos, isLoading } = useGetCisos(projectId);
    const { data: activeCiso } = useGetActiveCiso(Boolean(cisos?.length), projectId);

    const handleToggleSignpostModal = (value?: string) => () => setSignpostUrl(value);

    if (isLoading) {
        return <Spinner fixed={false} parentClasses="h-[calc(100%-49px)] !p-4" />;
    }

    return (
        <>
            {isSmallerThenTabletLandscape && pathname.includes('cisos') && <Breadcrumb className="mb-4" />}
            <div className="px-4 mt-4">
                {pathname.includes('cisos') && !!activeCiso && (
                    <div className="mb-12 border-b border-gray-200 pb-8">
                        <Text as="h6" variant="h6" className="text-heading mb-8">
                            CISO Ativo
                        </Text>
                        <div>
                            <CisoDetails withActions={withActions} ciso={activeCiso!} onSetSignpostUrl={setSignpostUrl} />
                        </div>
                    </div>
                )}

                {withTitle && (
                    <Text as="h6" variant="h6" className="text-heading mb-4">
                        CISOS
                    </Text>
                )}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Data inicial
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Data final
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Duração
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Placas
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Status
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'w-[130px]' }}>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Ações
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Boolean(cisos?.length) ? (
                                cisos?.map((item) => {
                                    return (
                                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    {formatDate(item.management_start_date) || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    {formatDate(item.management_end_date) || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    {item.duration || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    {item.signpost_class?.title || '-'}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Badge variant={item.approval_status === ApprovalStatus.Active ? 'secondary' : 'grey'}>
                                                    {item.approval_status === ApprovalStatus.Active ? 'Ativo' : 'Versão anterior'}
                                                </Badge>
                                            </TableCell>
                                            <TableCell>
                                                <div className="flex">
                                                    <Tooltip placement="top" title="Visualizar">
                                                        <IconButton
                                                            component={Link}
                                                            TouchRippleProps={infoButton}
                                                            className="hover:bg-primary-100 hover:bg-opacity-20"
                                                            to={Boolean(ctx) ? `${item.id}` : `cisos/${item.id}?projectProposalTypeId=${projectProposalTypeId}`}>
                                                            <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {withActions && (
                                                        <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                            <IconButton
                                                                component={Link}
                                                                disabled={item.approval_status !== ApprovalStatus.Active}
                                                                TouchRippleProps={warningButton}
                                                                className="hover:bg-system-warning-100 hover:bg-opacity-30 disabled:bg-base-100"
                                                                to={`${item.id}/editar`}>
                                                                <Icon
                                                                    name="ico-edit"
                                                                    width={16}
                                                                    height={16}
                                                                    color={item.approval_status !== ApprovalStatus.Active ? palette.grey[400] : palette.warning.main}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={4}>
                                        <Empty title="Nenhum CISO encontrado" />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Outlet context={ctx} />
                {Boolean(signpostUrl) && (
                    <Modal onClose={handleToggleSignpostModal()}>
                        <img src={signpostUrl} />
                    </Modal>
                )}
            </div>
        </>
    );
};

export default CisoContainer;
