import useCreateOrUpdateCommercialFP from 'services/queries/commercial/use-create-or-update-budget';
import CreateOrUpdateCommercialFP from './create-or-update';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CommercialBudgetPayload } from 'types/models/commercial';

const CreateOrUpdateCommercialFPPage = () => {
    const { budgetId, projectId } = useParams();
    const navigate = useNavigate();

    const ctxParams = useOutletContext<any>();
    const { mutateAsync: createOrUpdateCommercialFp, isLoading: isSubmitting } = useCreateOrUpdateCommercialFP(budgetId, ctxParams, projectId);

    const handleSubmit = async (data: CommercialBudgetPayload) => {
        try {
            const { data: commercialFpResponse } = await createOrUpdateCommercialFp({
                ...data,
                code: Boolean(projectId) && Boolean(ctxParams?.projectDetails?.code) ? `${ctxParams.projectDetails.code}-${data.code}` : data.code
            });

            navigate(`/app/fp/${commercialFpResponse.id}`);
        } catch (e) {
            console.error('handleSubmit: createOrUpdateCommercialFPPage: ', e);
        }
    };

    return <CreateOrUpdateCommercialFP isSubmitting={isSubmitting} isLoading={false} onSubmit={handleSubmit} />;
};

export default CreateOrUpdateCommercialFPPage;
