import { useQuery } from 'react-query';

import { OutcomeProjectTransactionResponse } from 'types/models/organization';
import operational, { getOutcomeTransactionProjectById } from './graphql';
import apiGraphQl from 'services/graphql';
import pick from 'lodash/pick';

const useGetProjectOutcomeById = (transactionId?: string, projectTypeId?: string) => {
    const fetcher = async () => {
        if (!transactionId) {
            return;
        }

        const { transactionOutcome } = await apiGraphQl<OutcomeProjectTransactionResponse>(operational.getProjectTransactionOutcomeById, { id: transactionId });

        return {
            ...pick(transactionOutcome.transaction, ['id', 'code', 'description', 'dueDate', 'payday', 'value', 'classification', 'data_classification', 'observation']),
            person: transactionOutcome.person,
            provider: transactionOutcome.provider,
            service: transactionOutcome.service,
            data_service_classification: transactionOutcome.data_service_classification,
            data_provider: transactionOutcome.data_provider,
            documents: transactionOutcome.transaction.documents.map((item) => ({
                ...item?.document,
                classification: item?.document?.classification_id
            }))
        };
    };

    return useQuery(getOutcomeTransactionProjectById(projectTypeId, transactionId), fetcher, { enabled: !!transactionId });
};

export default useGetProjectOutcomeById;
