import React, { ElementType, useCallback, useEffect } from 'react';

// Dependencies
import { useLocation } from 'react-router-dom';

// Helpers
import useAuth from 'store/auth/use-auth';

const withAuth = (WrappedComponent: ElementType) => {
    return (props: any) => {
        const { auth, logout } = useAuth();
        const { pathname, search } = useLocation();

        const checkAndRedirect = useCallback(() => {
            if (!auth.token) {
                logout(pathname.replace('/', '') + search);
            }
        }, [auth.token, pathname, search, logout]);

        useEffect(() => {
            checkAndRedirect();
        }, [checkAndRedirect]);

        return <WrappedComponent {...props} />;
    };
};

export default withAuth;
