import React from 'react';
import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import DatePicker from 'components/core/datepicker';
import Input from 'components/core/form/input';
import Text from 'components/core/text';
import { ProviderFinancialSetupPayload } from 'types/models/provider';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import ErrorMessage from 'components/error/message';
import { NumberFormatValues } from 'react-number-format';
import CurrencyInput from 'components/core/form/currency';

type MeasurementsProps = {
    budget: number;
};

const Measurements = ({ budget }: MeasurementsProps) => {
    const { control, formState, setValue } = useFormContext<ProviderFinancialSetupPayload>();

    const { fields } = useFieldArray({ name: 'measurements', control });

    const resetValues = (index: number) => {
        setValue(`measurements.${index}.predictedValue`, undefined);
        setValue(`measurements.${index}.predictedPercentage`, 0);
    };

    const handlePaymentPercent = (index: number) => (event) => {
        const value = Number(event.currentTarget?.value) || 0;

        if (Boolean(value)) {
            setValue(`measurements.${index}.predictedPercentage`, Math.round(value * 100) / 100);

            const otherValue = (budget * value) / 100;

            setValue(`measurements.${index}.predictedValue`, {
                floatValue: otherValue,
                formattedValue: otherValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim(),
                value: otherValue.toString()
            });
        } else {
            resetValues(index);
        }
    };

    const handlePaymentValue = (index: number) => (values: NumberFormatValues) => {
        if (Boolean(values)) {
            setValue(`measurements.${index}.predictedValue`, values);

            const value = ((values.floatValue || 0) * 100) / budget;
            const percentage = parseFloat((Math.round(value * 100) / 100).toFixed(2));

            setValue(`measurements.${index}.predictedPercentage`, percentage);
        } else {
            resetValues(index);
        }
    };

    return (
        <>
            <Table classes={{ root: 'border border-base-300' }}>
                <TableHead>
                    <TableRow>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                Ordem
                            </Text>
                        </TableCell>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                Data
                            </Text>
                        </TableCell>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                Percentual
                            </Text>
                        </TableCell>
                        <TableCell classes={{ root: 'border-r border-r-base-300' }}>
                            <Text as="span" className="text-secondary-500">
                                Valor
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((item, index) => {
                        return (
                            <TableRow key={item.id}>
                                <TableCell classes={{ root: 'py-0 border-r border-r-base-300' }}>
                                    <span className="border border-base-300 flex items-center justify-center bg-base-200 text-heading rounded-[10px] min-w-[30px] px-2 py-[2px]">
                                        {(index + 1).toString().padStart(3, '0')}
                                    </span>
                                </TableCell>
                                <TableCell classes={{ root: 'p-0 border-r border-r-base-300' }}>
                                    <Controller
                                        name={`measurements.${index}.expectedDate`}
                                        control={control}
                                        render={({ field }) => <DatePicker {...field} inpuWrapperClasses="border-0" placeholderText="Selecione uma data" />}
                                    />
                                </TableCell>
                                <TableCell classes={{ root: 'p-0 border-r border-r-base-300 w-32' }}>
                                    <Controller
                                        name={`measurements.${index}.predictedPercentage`}
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Ex: 10"
                                                right={
                                                    <Text as="span" variant="body.medium.sm" className="text-heading">
                                                        %
                                                    </Text>
                                                }
                                                rightClasses="bg-base-200 px-4"
                                                inpuWrapperClasses="rounded-none border-0"
                                                onChange={handlePaymentPercent(index)}
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell classes={{ root: 'p-0 border-r border-r-base-300' }}>
                                    <Controller
                                        name={`measurements.${index}.predictedValue`}
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <CurrencyInput
                                                    left={
                                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                                            R$
                                                        </Text>
                                                    }
                                                    value={field.value?.formattedValue}
                                                    placeholder="Ex: 1000"
                                                    inpuWrapperClasses="rounded-none border-0"
                                                    onValueChange={handlePaymentValue(index)}
                                                />
                                            );
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <ErrorMessage className="block mt-4" visible={Boolean((formState.errors as any)?.measurements?.message)}>
                {(formState.errors as any)?.measurements?.message}
            </ErrorMessage>
        </>
    );
};

export default Measurements;
