import { CrudPageProps } from 'types/graphql';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { PersonContractStatusTitle } from '../../details/tabs/contracts';
import { ColorVariant } from 'types/colors';
import { get } from 'lodash';
import { TableCell } from '@mui/material';
import { PersonContractType } from 'types/models/person';
import PersonAdvancedSearch from 'components/advanced-search/person';
import Badge from 'components/core/badge';

export const listPeople = (contractStatudIdState): CrudPageProps => {
    const [contractStatusId, setContractStatusId] = contractStatudIdState;

    return {
        graphql: {
            table: 'person',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name'],
                    options: {
                        customBodyRender: (value) => value || '-'
                    }
                },
                {
                    name: 'document',
                    label: dictionary.content.graphql['document'],
                    options: {
                        customBodyRender: (value) => value || '-'
                    }
                },
                {
                    name: 'birthday',
                    label: dictionary.content.graphql['birthday'],
                    options: {
                        customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY')
                    }
                },
                {
                    name: 'marital_status',
                    label: dictionary.content.graphql['marital_status'],
                    options: {
                        customBodyRender: (value) => Object.values(dictionary.content.maritalStatus)[value - 1] || '-'
                    }
                },
                {
                    name: 'gender',
                    label: dictionary.content.graphql['gender'],
                    options: {
                        customBodyRender: (value) => Object.values(dictionary.content.genders)[value - 1] || '-'
                    }
                },
                {
                    name: 'phones',
                    label: dictionary.content.graphql['phones'],
                    options: {
                        customBodyRender: (value) => {
                            return value?.map((item) => `(${item.ddd}) ${item.number}`).join(', ') || '-';
                        }
                    }
                },

                {
                    name: 'person_contract.contract_status.id',
                    label: 'Status do contrato',
                    options: {
                        customBodyRender: (_, tableMeta) => {
                            const status = get(tableMeta, `rowData[8].props.item.person_contract.contract_status.id`);

                            const statusColors = new Map<PersonContractStatusTitle | 'default', ColorVariant>([
                                [PersonContractStatusTitle.Active, 'success'],
                                [PersonContractStatusTitle.MaternityLeave, 'grey'],
                                [PersonContractStatusTitle.SicknessAllowance, 'grey'],
                                [PersonContractStatusTitle.Resignation, 'warning'],
                                [PersonContractStatusTitle.Finished, 'error'],
                                [PersonContractStatusTitle.Transferred, 'info'],
                                [PersonContractStatusTitle.Vacation, 'primary'],
                                [PersonContractStatusTitle.MedicalCertificate, 'grey']
                            ]);

                            const label = get(dictionary.content.personContractStatusTitle, PersonContractStatusTitle[status]);

                            if (!label) {
                                return '-';
                            }

                            return (
                                <Badge variant={statusColors.get(status) || 'grey'} className="px-[10px] py-[6px]">
                                    {label}
                                </Badge>
                            );
                        }
                    }
                },

                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ],

            where: {
                type: {
                    _eq: PersonContractType.Personal
                },
                person_contract: {
                    contract_status: {
                        id: { _eq: contractStatusId !== null ? contractStatusId : PersonContractStatusTitle.Active }
                    }
                }
            }
        },
        api: {
            endpoint: '/people',
            useApiTodelete: true
        },
        title: 'Pessoas',
        advancedSearchComponent: <PersonAdvancedSearch setContractStatusId={setContractStatusId} />
    };
};
