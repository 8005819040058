import { useMemo } from 'react';

// Dependencies
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';

// Components
import Empty from 'components/empty';
import Text from 'components/core/text';

// Helpers
import { useLocation, useOutletContext } from 'react-router-dom';
import { ProjectProposalType } from 'types/models/project';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import useProjectTypeSlug from 'pages/private/operationals/hooks/use-project-type-slug';
import TableContainer from '@mui/material/TableContainer';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

type ScopesProps = {
    projectProposalTypeProp?: Partial<ProjectProposalType>;
    withTitle?: boolean;
    className?: string;
};

const Scopes = ({ projectProposalTypeProp, withTitle, className }: ScopesProps) => {
    const { isProject, isComplementaryProject } = useProjectTypeSlug();
    const { pathname } = useLocation();
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const projectProposalType = useMemo(() => projectProposalTypeProp || ctx?.projectProposalType, [projectProposalTypeProp, ctx?.projectProposalType]);

    return (
        <>
            {isSmallerThenTabletLandscape && pathname.includes('escopos') && <Breadcrumb className="mb-4" />}
            {withTitle && (
                <Text as="h6" variant="h6" className="text-heading mb-4">
                    Escopos
                </Text>
            )}
            {Boolean(projectProposalType?.phases?.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Título
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Duração
                                    </Text>
                                </TableCell>
                                <TableCell sx={{ minWidth: '150px' }}>
                                    <Text as="span" className="text-secondary-500">
                                        Percentual de liberação de pagamento
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text as="span" className="text-secondary-500">
                                        Ordem
                                    </Text>
                                </TableCell>
                                {(isProject || isComplementaryProject) && Boolean(ctx) && (
                                    <TableCell>
                                        <Text as="span" className="text-secondary-500">
                                            Ações
                                        </Text>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectProposalType?.phases?.map((phase) => {
                                return (
                                    <TableRow key={phase.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {phase.title || '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {phase.duration || 0} dias
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {phase.payment_percentage || 0}%
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" className="text-base-500">
                                                {phase.order_phase || '-'}
                                            </Text>
                                        </TableCell>
                                        {isProject && Boolean(ctx) && <TableCell>{/* TODO: */}</TableCell>}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Empty title="Este projeto não possui escopos." className={className} />
            )}
        </>
    );
};

export default Scopes;
