import useTheme from '@mui/material/styles/useTheme';
import RemoveButton from 'components/buttons/remove';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Dropzone from 'components/dropzone';
import { memo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import useUploadFile from 'services/queries/files/use-upload-file';
import { FileApiModel } from 'types/models/file';
import Button from 'components/core/button';
import { useNavigate } from 'react-router-dom';

type TimelineFileProps = {
    isSubmitting: boolean;
} & UseFormReturn<any, any>;

const TimelineFile = ({ isSubmitting, formState, setValue }: TimelineFileProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();

    const [apiFile, setApiFile] = useState<FileApiModel>();

    const { uploadFile, isLoading: isUploading } = useUploadFile();

    const handleClose = () => navigate(-1);

    const handleFile = async (files: File[]) => {
        try {
            const [localFile] = files;

            const apiFile = await uploadFile(localFile);

            setValue('documents', [{ file: apiFile?.id! }]);

            setApiFile(apiFile);
        } catch (error) {
            console.log('handleUploadFiles', error);
        }
    };

    const handleRemoveFile = () => {
        setValue('documents', undefined);
        setApiFile(undefined);
    };

    return (
        <>
            <div className="mb-6">
                <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                    Arquivo
                </Text>
                <Dropzone
                    accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
                    multiple={false}
                    disabled={!!apiFile}
                    error={formState.errors.documents?.[0]?.file?.message}
                    onUploadFiles={handleFile}
                />
                {(isUploading || !!apiFile) && (
                    <div className="flex items-center mt-4 relative border border-base-300 rounded-[14px] py-6 px-4">
                        {isUploading && <Spinner color={palette.secondary[100]} fixed={false} size={20} />}
                        {!!apiFile && (
                            <>
                                <Text className="italic text-base-500">{apiFile.originalName || ''}</Text>
                                <RemoveButton onClick={handleRemoveFile} className="opacity-100 right-[18px]" />
                            </>
                        )}
                    </div>
                )}
            </div>
            <div className="flex items-center">
                <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                    Enviar
                </Button>
                <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                    Cancelar
                </Button>
            </div>
        </>
    );
};

export default memo(TimelineFile);
