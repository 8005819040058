// Dependencies
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import { startConstructionKey } from './graphql';
import useToast from 'hooks/toast/use-toast';
import { getProjectProposalTypeKey } from '../projects/graphql';

const useStartConstruction = (projectProposalTypeId?: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: { id: number }) => api.post(`/project-types/start`, payload);

    return useMutation(startConstructionKey(projectProposalTypeId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectProposalTypeKey(projectProposalTypeId));

            showToast('Obra iniciada com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao iniciar a obra', 'danger');
        }
    });
};

export default useStartConstruction;
