// Helpers
import { Status } from 'types/general';

export const createProfileKey = ['profile', 'create'];
export const createProfilePageRulesKey = ['profile', 'page', 'rule', 'create'];

export const getProfilesOptionsKey = ['profile', 'all', 'options'];

export const getProfileKey = (profileId?: string) => ['profile', profileId];

const queries = {
    createProfile: `mutation ($payload: profile_insert_input!) {
        item: insert_profile_one(object: $payload) {
            id
        }
    }`,
    updateProfile: `mutation ($payload: profile_set_input!, $id: Int!) {
        update_profile_by_pk(pk_columns: { id: $id }, _set: $payload) {
            id
        }
    }`,
    createProfilePageRules: `mutation ($payload: [profile_page_rule_insert_input!]!) {
        item: insert_profile_page_rule(objects: $payload) {
            affected_rows
        }
    }`,
    getProfilesOptions: `query GetProfilesOptions {
        items: profile(order_by: {name: asc}, where: {status: {_eq: ${Status.Active}}, system: {_eq: false}}) {
          value: id
          label: name
        }
    }`
};

export default queries;
