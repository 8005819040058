import { Fragment } from 'react';

// Dependencies
import classNames from 'classnames';
import TimelineDot from '@mui/lab/TimelineDot/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem/TimelineItem';
import TimelineContent from '@mui/lab/TimelineContent/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector/TimelineConnector';
import TimelineSeparator from '@mui/lab/TimelineSeparator/TimelineSeparator';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';

export type ActivityItemProps = {
    done: boolean;
    doneText: string;
    doneActions?: Array<{
        label: string;
        action: () => void;
    }>;
    title: string;
    helperText: string | React.ReactNode;
    button?: {
        label: string;
        action: () => void;
    };
    withConnector?: boolean;
};

type SeparatorProps = {
    done: boolean;
    withConnector: boolean;
};

export const Separator = ({ done, withConnector }: SeparatorProps) => {
    const dotClasses = classNames('shadow-none p-[2px]', done ? 'bg-system-success-500' : 'bg-transparent');

    return (
        <TimelineSeparator>
            <TimelineDot classes={{ root: dotClasses }} variant={done ? 'filled' : 'outlined'} />
            {withConnector && <TimelineConnector classes={{ root: `w-[1px] ${done ? 'bg-system-success-500' : 'bg-base-300'}` }} />}
        </TimelineSeparator>
    );
};

const ActivityItem = ({ button, done, doneActions, doneText, helperText, title, withConnector = true }: ActivityItemProps) => {
    const classes = classNames(done ? 'min-h-[40px]' : undefined);

    return (
        <TimelineItem classes={{ root: classes }} sx={{ '&:before': { display: 'none' } }}>
            <Separator done={done} withConnector={withConnector} />
            <TimelineContent classes={{ root: 'flex flex-col pb-4' }}>
                {!done ? (
                    <Fragment>
                        <Text variant="body.medium.2xs" as="span" className="text-primary-500">
                            {title}
                        </Text>
                        <Text variant="body.regular.xs" as="span" className="text-base-500 mb-2">
                            {helperText}
                        </Text>
                        {Boolean(button) && (
                            <button className="flex items-center" onClick={button?.action}>
                                <Text variant="body.regular.xs" as="span" className="text-secondary-500 mr-1">
                                    {button?.label}
                                </Text>
                            </button>
                        )}
                    </Fragment>
                ) : (
                    <div>
                        <Text variant="body.medium.sm" as="span" className="text-system-success-500 flex items-center">
                            {doneText}
                            <Icon name="ico-check" width={12} className="ml-2" />
                        </Text>
                        {Boolean(doneActions?.length) && (
                            <div className="flex mt-2">
                                {doneActions?.map((item, index) => {
                                    const isLastItem = doneActions.length - 1 === index;

                                    return (
                                        <Fragment key={index}>
                                            <button key={index} className="flex items-center" onClick={item.action}>
                                                <Text variant="body.regular.xs" as="span" className="text-primary-500">
                                                    {item.label}
                                                </Text>
                                            </button>
                                            {!isLastItem && (
                                                <Text variant="body.regular.xs" as="span" className="text-base-500 mx-2">
                                                    |
                                                </Text>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </TimelineContent>
        </TimelineItem>
    );
};

export default ActivityItem;
