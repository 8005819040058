import React, { useEffect, useMemo, useState } from 'react';

// Dependencies
import { useOutletContext, useParams } from 'react-router-dom';

// Components
import CreateOrUpdateCiso from './create-or-update';

// Helpers
import useGetCiso from 'services/queries/projects/use-get-ciso';
import { ProjectManagementInformationPayload } from 'types/models/project';
import useGetProjectMeasuresOptions from 'services/queries/projects/use-get-project-measures';
import useGetProjectSignpostsOptions from 'services/queries/projects/use-get-project-signposts';
import useCreateProjectManagementInformation from 'services/queries/projects/use-create-project-management-information';
import useUpdateProjectManagementInformation from 'services/queries/projects/use-update-project-management-information';
import { ApprovalStatus } from 'types/general';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import { formatDate } from 'utils/date';
import dayjs from 'dayjs';

const CreateOrUpdateCisoPage = () => {
    const { cisoId } = useParams();
    const [signpost, setSignpost] = useState<string>();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const { data: ciso, isLoading: isLoadingCiso } = useGetCiso(cisoId);

    const { data: signposts } = useGetProjectSignpostsOptions(signpost);
    const { data: measures, isLoading: isLoadingMeasures } = useGetProjectMeasuresOptions();

    const { mutateAsync: createCiso, isLoading: isSubmittingCreate } = useCreateProjectManagementInformation(ctx?.project?.id);
    const { mutateAsync: updateCiso, isLoading: isSubmittingUpdate } = useUpdateProjectManagementInformation(ctx?.project?.id, cisoId);

    const isBuilding = useMemo(() => isLoadingCiso || isLoadingMeasures, [isLoadingCiso, isLoadingMeasures]);

    useEffect(() => {
        if (Boolean(ciso?.project_management_information_has_measures)) {
            const measureSignpost = ciso?.project_management_information_has_measures?.find((item) => item.project_measure.slug === 'placa-de-obra');

            if (measureSignpost) {
                setSignpost(measureSignpost.project_measure.slug);
            }
        }
    }, [ciso]);

    const handleSubmitCreate = async (data: ProjectManagementInformationPayload) => {
        try {
            delete data.isSignsSelected;

            const payload = {
                ...data,
                management_start_date: formatDate(data.management_start_date, 'YYYY-MM-DD'),
                management_end_date: formatDate(data.management_end_date, 'YYYY-MM-DD'),
                approval_status: ApprovalStatus.Active,
                duration: Number(data.duration),
                project_management_information_has_measures: {
                    data: data.project_management_information_has_measures.filter(Boolean).map((item) => ({
                        project_measure_id: item.value
                    }))
                }
            };

            await createCiso(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleSubmitUpdate = async (data: ProjectManagementInformationPayload) => {
        try {
            delete data.isSignsSelected;

            const updatePayload = {
                approval_status: ApprovalStatus.Inactive,
                updated_at: new Date().toISOString()
            };

            const createPayload = {
                ...data,
                management_start_date: dayjs(data.management_start_date).format('YYYY-MM-DD'),
                management_end_date: dayjs(data.management_end_date).format('YYYY-MM-DD'),
                approval_status: ApprovalStatus.Active, // TODO: implementar fluxo de aprovacao de CISO
                revision: ciso?.revision! + 1,
                duration: Number(data.duration) + 1,
                project_management_information_has_measures: {
                    data: data.project_management_information_has_measures.filter(Boolean).map((item) => ({
                        project_measure_id: item.value
                    }))
                }
            };

            await updateCiso({ createPayload, updatePayload });
        } catch (error) {
            console.log('error', error);
        }
    };

    const defaultValues: Partial<ProjectManagementInformationPayload> = useMemo(
        () => ({
            duration: ciso?.duration ? ciso.duration.toString() : '',
            management_end_date: ciso?.management_end_date,
            management_start_date: ciso?.management_start_date,
            project_id: Number(ctx?.project?.id!),
            project_management_information_has_measures:
                ciso?.project_management_information_has_measures?.reduce((arr, value) => {
                    const finder = measures?.find((item) => item.value === value.project_measure?.id);
                    if (finder) {
                        const index = measures?.findIndex((item) => item.value === finder.value);
                        arr[index!] = finder;
                    }

                    return arr;
                }, Array(measures?.length)) || [],
            signpost_class_id: ciso?.signpost_class_id,
            status: ciso?.status,
            isSignsSelected: ciso?.project_management_information_has_measures?.some((item) => item?.project_measure.slug === 'placa-de-obra'),
            observations: ciso?.observations || ''
        }),
        [ciso, ctx?.project?.id, measures]
    );

    return (
        <CreateOrUpdateCiso
            defaultValues={defaultValues}
            isBuilding={isBuilding}
            isSubmitting={isSubmittingCreate || isSubmittingUpdate}
            measures={measures || []}
            projectId={ctx?.project?.id}
            signposts={signposts || []}
            onAbleSignpostRequest={setSignpost}
            onSubmit={Boolean(cisoId) ? handleSubmitUpdate : handleSubmitCreate}
        />
    );
};

export default CreateOrUpdateCisoPage;
