import { useMutation, useQueryClient } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getTasksByStatusKey, taskDashboardQueries } from './graphql';
import useAuth from 'store/auth/use-auth';
import { reorderArray } from 'utils/array';
import { TaskDashboardItem } from 'types/models/task-dashboard';
import useToast from 'hooks/toast/use-toast';
import { getTaskKey } from '../task/graphql';
import useChangeTaskStatus from '../task/use-change-task-status';

type Source = {
    taskId: number;
    statusId: number;
    index: number;
};

type Destination = {
    statusId: number;
    index: number;
};

export type MoveTaskPayload = {
    source: Source;
    destination: Destination;
    skipProgressModal?: boolean;
};

const move = (source: Source & { items: any[] }, destination: Destination & { items: any[] }) => {
    const sourceClone = Array.from(source.items);
    const destClone = Array.from(destination.items);
    const [removed] = sourceClone.splice(source.index, 1);

    destClone.splice(destination.index, 0, removed);

    return {
        updatedSource: sourceClone,
        updatedDestination: destClone
    };
};

const useUpdateTaskDashboardStatus = () => {
    const { auth } = useAuth();
    const { showToast } = useToast();
    const { mutateAsync: changeTaskStatus } = useChangeTaskStatus();

    const client = useQueryClient();

    const personId = auth?.credentials?.user?.person?.id;

    const fetcher = async (payload: MoveTaskPayload) => {
        const { source, destination } = payload;

        // get cached data
        const destinationTasks = client.getQueryData<TaskDashboardItem[]>(getTasksByStatusKey(destination.statusId));
        const sourceTasks = client.getQueryData<TaskDashboardItem[]>(getTasksByStatusKey(source.statusId));

        // if the task is going to the same column we only reorder
        if (source.statusId === destination.statusId) {
            const reorderTasks = reorderArray(destinationTasks, source.index, destination.index);

            // Update temporarily cached value so the UI doesn't show incorrect information
            client.setQueryData(getTasksByStatusKey(destination.statusId), reorderTasks);

            // update the list of tasks with the correct order
            await apiGraphQl(taskDashboardQueries.updateTasks(reorderTasks), {
                personId
            });
        } else {
            const { updatedDestination, updatedSource } = move({ ...source, items: sourceTasks || [] }, { ...destination, items: destinationTasks || [] });

            // Update temporarily cached value so the UI doesn't show incorrect information
            client.setQueryData(getTasksByStatusKey(source.statusId), updatedSource);
            client.setQueryData(getTasksByStatusKey(destination.statusId), updatedDestination);

            // Send the new task status to the API
            await changeTaskStatus({ taskId: source.taskId, status: destination.statusId });

            // update the list of tasks of each column
            if (!!updatedSource?.length) {
                const tasks = [...new Map(updatedSource.map((item) => [item.id, item])).values()];

                await apiGraphQl(taskDashboardQueries.updateTasks(tasks), { personId });
            }

            const tasks = [...new Map(updatedDestination.map((item) => [item.id, item])).values()];

            await apiGraphQl(taskDashboardQueries.updateTasks(tasks), { personId });
        }

        // invalidate columns
        client.invalidateQueries(getTasksByStatusKey(destination.statusId));

        if (source.statusId !== destination.statusId) {
            client.invalidateQueries(getTasksByStatusKey(source.statusId));
        }

        return Promise.resolve();
    };

    return useMutation(fetcher, {
        onError: () => {
            showToast('Tivemos um problema para mover a tarefa, tente novamente.', 'danger');
        },
        onSuccess: (_, payload) => {
            showToast('Tarefa movida com sucesso.', 'success');

            client.invalidateQueries(getTaskKey(payload.source.taskId.toString()));
        }
    });
};

export default useUpdateTaskDashboardStatus;
