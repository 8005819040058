import React, { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/core/button';
import Editor from 'components/core/form/editor';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useGetFinancialMeasurementByPk from 'services/queries/measurement/use-get-financial-measurement-by-pk';
import useGetMeasurementPayments from 'services/queries/operationals/use-get-measurement-payments';
import { NewMeasurementPayload, ProviderFinancialSetup } from 'types/models/provider';
import { CamelCase } from 'types/utils';
import { formatDate } from 'utils/date';
import dictionary from 'utils/dictionary';
import { formatMoney } from 'utils/money';
import { date as dateYup, number, object, string } from 'yup';
import NewMeasurementHeader from './header';
import NewMeasurementPayments from './payments';
import { getFinancialMeasurementByPk } from './utils';
import useUpdateMeasurement from 'services/queries/operationals/use-update-measurement';
import useGetMeasurementStats from 'services/queries/operationals/use-get-measurement-stats';
import NewMeasurementSidebar from './sidebar';
import CurrencyInput from 'components/core/form/currency';

type Ctx = { setup: CamelCase<ProviderFinancialSetup> | undefined };

const NewMeasurement = () => {
    const navigate = useNavigate();
    const dateHook = useState<string>();
    const [date] = dateHook;

    const ctx = useOutletContext<Ctx | undefined>();

    const { providerFinancialSetupId, measurementId } = useParams();

    const { data: measurement } = useGetFinancialMeasurementByPk(getFinancialMeasurementByPk(Number(measurementId)), Number(measurementId));
    const { data: stats, isLoading: isFetchingStats } = useGetMeasurementStats(Number(providerFinancialSetupId), Number(measurementId));
    const { data: payments, isLoading: isFetchingPayments, isError } = useGetMeasurementPayments(Number(providerFinancialSetupId), Number(measurementId), date);

    const { mutateAsync: updateMeasurement, isLoading: isSubmitting } = useUpdateMeasurement(ctx?.setup?.id, measurement?.id);

    const total = useMemo(() => (payments || []).reduce((acc, curr) => acc + curr.value, 0), [payments]);

    const schema = object({
        measurementDate: dateYup().required(dictionary.validation.required),
        measurementResidue: object({
            formattedValue: string(),
            value: string(),
            floatValue: number()
        }),
        justification: string().when('measurementResidue', (measurementResidue, schema) => {
            return (total || 0) + (measurementResidue || 0) - (measurement?.predicted_value || 0) !== 0 ? schema.required(dictionary.validation.required) : schema.nullable().notRequired();
        })
    });

    const methods = useForm<NewMeasurementPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        defaultValues: {
            measurementDate: ''
        }
    });

    const { control, formState, handleSubmit, setValue } = methods;

    const handleBack = () => navigate(-1);

    const submit = async (data: NewMeasurementPayload) => {
        try {
            const payload = {
                ...data,
                measurementDate: formatDate(data.measurementDate, 'YYYY-MM-DD'),
                measurementResidue: data.measurementResidue?.floatValue || 0
            };

            await updateMeasurement(payload);
        } catch (error) {
            console.log('NewMeasurement -> submit', error);
        }
    };

    return (
        <Modal
            contentClassnames={`w-[1024px] ${Boolean(date) ? 'h-full' : ''}`}
            closeOnClickOutside={false}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading mr-4">
                    Realizar medição #{measurement?.order_measurement?.toString()?.padStart(3, '0') || '000'}
                </Text>
            }
            headerRight={
                <>
                    <Text as="span" className="text-base-500 italic mr-4">
                        Avanço físico:{' '}
                        <Text as="span" className="text-heading">
                            {measurement?.provider_financial_setup?.timeline_budget?.timeline?.progress_percentage || 0}%
                        </Text>
                    </Text>
                    <Text as="span" className="text-base-500 italic">
                        Valor do contrato {ctx?.setup?.budget?.provider?.title || 'fornecedor'}:{' '}
                        <Text as="span" className="text-heading">
                            {formatMoney(Number(ctx?.setup?.budget?.value))}
                        </Text>
                    </Text>
                </>
            }
            onClose={handleBack}>
            <FormProvider {...methods}>
                <form className="h-[calc(100%-74px)]" onSubmit={handleSubmit(submit)}>
                    {Boolean(stats) && <NewMeasurementHeader stats={stats!} />}
                    <div className="flex flex-col sm:flex-row h-[calc(100%-249px)] border-b border-b-base-300 overflow-hidden">
                        <NewMeasurementSidebar stats={stats} measurement={measurement} payments={payments || []} dateHook={dateHook} />
                        <div className="overflow-y-auto w-full sm:w-[calc(100%-340px)]">
                            {!Boolean(date) ? (
                                <div className="p-6 flex items-center justify-center">
                                    <Text as="p" variant="body.regular.sm" className="italic text-center text-base-500">
                                        Selecione uma data para buscar os faturamentos diretos.
                                    </Text>
                                </div>
                            ) : isFetchingPayments || isFetchingStats ? (
                                <Spinner fixed={false} parentClasses="w-full h-full" />
                            ) : isError || !Boolean(payments?.length) ? (
                                <Empty title="Nenhum faturamento direto encontrado." className="flex items-center justify-center mt-5" />
                            ) : (
                                <div className="p-6 border-b border-b-base-300">
                                    <Text as="h6" variant="h6" className="text-heading mb-6">
                                        Faturamentos diretos encontrados com o vencimento até {formatDate(date)}
                                    </Text>
                                    <NewMeasurementPayments total={total} payments={payments || []} />
                                </div>
                            )}
                            <div className="p-6">
                                <div className="border border-base-300 p-4 rounded-[14px] mb-4">
                                    <Controller
                                        name="measurementResidue"
                                        control={control}
                                        render={() => {
                                            return (
                                                <CurrencyInput
                                                    left={
                                                        <Text as="span" variant="body.medium.sm" className="text-heading">
                                                            R$
                                                        </Text>
                                                    }
                                                    label="Valor líquido"
                                                    placeholder="Ex: 1000"
                                                    onValueChange={(values) => setValue('measurementResidue', values)}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <Controller
                                    name="justification"
                                    control={control}
                                    render={({ field }) => <Editor {...field} error={formState.errors?.justification?.message} label="Justificativas" />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center p-6">
                        <Button disabled={!date || isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default NewMeasurement;
