import { useQuery } from 'react-query';
import api from 'services/api';
import { ProjectDocument } from 'types/models/project';
import { getProjectDocumentKey } from './graphql';

export type ProjectDocumentPayload = {
    classification: number;
    file: number;
    name: string;
    description?: string;
    observation?: string;
};

const useGetProjectDocument = (projectId?: string, documentId?: string) => {
    const request = async () => api.get<ProjectDocument>(`/projects/${projectId}/documents/${documentId}`).then(({ data }) => data);

    return useQuery(getProjectDocumentKey(projectId, documentId), request, { enabled: Boolean(documentId) });
};

export default useGetProjectDocument;
