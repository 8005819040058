import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import TableCell from '@mui/material/TableCell/TableCell';
import IconButton from '@mui/material/IconButton/IconButton';
import DataTable, { MUIDataTableColumnDef } from 'mui-datatables';

import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';

import { weekDays } from 'utils/date';
import { formatMoney } from 'utils/money';
import { ProjectStatus, ProjectStatusEnum, ProjectTypeSlug } from 'types/models/project';
import useGetProjectProposalSensitiveData from 'services/queries/projects/use-get-project-proposal-sensitive-data';
import { useMemo } from 'react';
import ProposalDetails from './details';
import { generatePercentage } from 'pages/private/commercial/budgets/utils';
import { CommercialBudgetVersionsResponse } from 'services/queries/commercial/use-get-budget-versions';

type ProjectProposalProps = {
    projectProposalId?: number;
    projectStatus?: Partial<ProjectStatus>;
    types: Array<{
        project_type_id: number | undefined;
        project_proposal_id: number;
        project_type_slug?: string;
    }>;
    budgetVersions: CommercialBudgetVersionsResponse['items'];
};

export const TH = (value: any) => (
    <TableCell key={value.label}>
        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
            {value.label}
        </Text>
    </TableCell>
);

const ProjectProposal = ({ projectProposalId, projectStatus, types, budgetVersions }: ProjectProposalProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const typesRequest = useMemo(
        () =>
            types.map((item) => ({
                project_type_id: item?.project_type_id,
                project_proposal_id: item?.project_proposal_id
            })),
        [types]
    );

    const requests = useGetProjectProposalSensitiveData(typesRequest || []);

    const datas = useMemo(
        () =>
            requests
                .filter((item) => Boolean(item.data))
                .map((item) => {
                    const versionByProjectType = budgetVersions.find((versionItem) => versionItem.project_type.slug === item.data?.project_type?.slug);

                    return {
                        ...item.data,
                        budget_version: versionByProjectType
                    };
                }),
        [requests, budgetVersions]
    );

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'id',
            options: { display: 'excluded' }
        },
        {
            name: 'project_type.title',
            label: 'Tipo',
            options: {
                customHeadRender: TH
            }
        },
        {
            name: 'budget_version.contract_value',
            label: 'Valor do contrato',
            options: {
                customBodyRender: (value = 0) => formatMoney(value),
                customHeadRender: TH
            }
        },
        {
            name: 'budget_version.indirect_costs',
            label: 'Custos indiretos',
            options: {
                customBodyRender: (value = 0) => formatMoney(value),
                customHeadRender: TH
            }
        },
        {
            name: 'budget_version.direct_costs',
            label: 'Custos diretos',
            options: {
                customBodyRender: (value = 0) => formatMoney(value),
                customHeadRender: TH
            }
        },
        {
            name: 'budget_version.percentage_margin',
            label: 'Margem',
            options: {
                customBodyRender: (value = 0) => generatePercentage(value),
                customHeadRender: TH
            }
        },
        ...(!budgetVersions.length
            ? [
                  {
                      name: '',
                      label: 'Recursos',
                      options: {
                          customHeadRender: TH,
                          customBodyRender: (_, tableMeta: any) => {
                              const [id] = tableMeta.rowData;

                              const slug = tableMeta.rowData.at(-2);

                              if (slug === ProjectTypeSlug.Management && projectStatus?.slug === ProjectStatusEnum.PreTap) {
                                  return (
                                      <Tooltip placement="top" title="Adicionar recursos à proposta" disableFocusListener={true}>
                                          <IconButton
                                              color="primary"
                                              size="small"
                                              sx={{ backgroundColor: palette.primary[300], '&:hover': { backgroundColor: palette.primary.main } }}
                                              onClick={(e: any) => {
                                                  e.stopPropagation();
                                                  navigate(`proposta/${projectProposalId}/tipo/${id}/recursos`);
                                              }}>
                                              <Icon name="ico-plus" width={10} height={10} color={palette.grey[100]} />
                                          </IconButton>
                                      </Tooltip>
                                  );
                              }

                              return null;
                          }
                      }
                  }
              ]
            : []),

        {
            name: 'project_type.slug',
            options: { display: 'excluded' }
        },
        {
            name: '',
            label: 'Ações',
            options: {
                customHeadRender: () => (
                    <TableCell className="text-sm font-medium text-secondary-500" key="actions" style={{ width: 130 }}>
                        Ações
                    </TableCell>
                ),
                customBodyRender: (_, tableMeta: any) => {
                    const [proposalId] = tableMeta.rowData;
                    const projectTypeSlug = tableMeta.rowData.at(-2);

                    const projectTypeFinder = types.find((item) => item?.project_type_slug === projectTypeSlug);
                    const projectTypeId = projectTypeFinder?.project_type_id;

                    const budgetVersionByProjectType = budgetVersions.find((versionItem) => versionItem.project_type.slug === projectTypeFinder?.project_type_slug);

                    return (
                        <>
                            {Boolean(budgetVersions.length) && (
                                <Tooltip placement="top" title="Visualizar FP" disableFocusListener={true}>
                                    <IconButton
                                        component={Link}
                                        to={`fp/${budgetVersionByProjectType?.budget_id}/versoes/${budgetVersionByProjectType?.id}/${projectTypeId}/visualizar`}
                                        className="hover:bg-system-info-100 hover:bg-opacity-30">
                                        <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                <IconButton
                                    component={Link}
                                    to={`proposta/${projectProposalId}/tipo/${projectTypeId}/editar/${proposalId}`}
                                    className="hover:bg-system-warning-100 hover:bg-opacity-30">
                                    <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                </IconButton>
                            </Tooltip>
                        </>
                    );
                }
            } as any
        }
    ];

    return Boolean(datas.length) ? (
        <DataTable
            columns={columns}
            data={datas}
            options={{
                sort: false,
                elevation: 0,
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                customToolbar: null as any,
                selectableRows: 'none',
                enableNestedDataAccess: '.',
                expandableRowsHeader: false,
                pagination: false,
                expandableRows: false,
                expandableRowsOnClick: false,
                responsive: 'standard',
                ...(!budgetVersions.length && {
                    expandableRowsOnClick: true,
                    expandableRows: true,
                    renderExpandableRow: (rowData, rowMeta) => {
                        const item = datas[rowMeta.rowIndex];

                        const handleGoTo = () => navigate(`proposta/${projectProposalId}/tipo/${item.id}/recursos`);

                        const days = (item?.week_days || '')
                            .split(';')
                            .map((day) => {
                                const finded = weekDays?.find((item) => item.value === Number(day));

                                if (!finded) {
                                    return;
                                }

                                return finded.label;
                            })
                            .filter(Boolean)
                            .join(', ');

                        return <ProposalDetails colSpan={rowData.length + 1} item={item} projectStatus={projectStatus} projectProposalId={projectProposalId} days={days} onAddResource={handleGoTo} />;
                    }
                })
            }}
            // @ts-ignore
            className="mb-4 overflow-hidden proposals meeting-records"
        />
    ) : (
        <Empty className="my-5 ml-4" title="Nenhuma proposta encontrada." />
    );
};

export default ProjectProposal;
