import { useEffect, useMemo } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DailyReportPayload } from 'types/models/organization';

import DatePicker from 'components/core/datepicker';
import { Option } from 'types/general';
import Select from 'components/core/form/select';
import Editor from 'components/core/form/editor';
import Button from 'components/core/button';
import Effective from './effective';
import WorkDiaries from './work-diaries';
import { mainSchema } from '../utils/schema';

type CreateOrUpdateFollowupProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    providers: Option[];
    dailyClassifications: Option[];
    effectiveClassifications: Option[];
    defaultValues: any;
    onSubmit: (data: DailyReportPayload) => void;
};

const CreateOrUpdateFollowup = ({ dailyClassifications, providers, effectiveClassifications, isBuilding, isSubmitting, onSubmit, defaultValues }: CreateOrUpdateFollowupProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const { dailyReportId } = useParams();

    const methods = useForm<DailyReportPayload>({
        mode: 'onSubmit',
        defaultValues: {
            // order: 0,
            workDiaries: [],
            effective: []
        },
        resolver: yupResolver(mainSchema)
    });

    const arr = useMemo(() => {
        return providers
            .map((provider) => {
                return effectiveClassifications.map((item) => ({
                    provider: provider.value,
                    classification: item.value,
                    quantity: 0
                }));
            })
            .flatMap((item) => item);
    }, [providers, effectiveClassifications]);

    useEffect(() => {
        if (Boolean(effectiveClassifications.length) || Boolean(providers.length)) {
            methods.reset({
                effective: arr,
                workDiaries: providers.map((item) => ({ provider: item.value }))
            });
        }
    }, [effectiveClassifications, providers, arr, methods]);

    const { control, formState, handleSubmit, reset } = methods;

    useEffect(() => {
        if (defaultValues.hasOwnProperty('workDiaries')) {
            reset({
                ...defaultValues,
                classification: defaultValues?.classification?.id,
                workDiaries: defaultValues.workDiaries.map((item) => ({ provider: item.provider.id, file: item.file })),
                effective: defaultValues?.effective.map((item) => ({ classification: item.classification.id, provider: item.provider.id, quantity: item.quantity }))
            });
        }
    }, [defaultValues, reset]);

    const handleBack = () => navigate(-1);

    return (
        <Modal
            closeOnClickOutside={false}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {dailyReportId ? 'Editar' : 'Novo'} acompanhamento diário
                </Text>
            }
            onClose={handleBack}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <FormProvider {...methods}>
                    <form className="pb-5 px-2 sm:px-6" onSubmit={handleSubmit(onSubmit)}>
                        <div className="border border-base-300 rounded-[14px] grid grid-cols-1 sm:grid-cols-2 gap-4 py-4 px-2 sm:p-4 mb-4">
                            <Controller
                                control={control}
                                name="date"
                                render={({ field }) => <DatePicker {...field} label="Data" placeholderText="Selecione uma data" error={formState.errors.date?.message} />}
                            />
                            <Controller
                                name="classification"
                                control={control}
                                render={({ field }) => {
                                    const value = dailyClassifications.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={dailyClassifications}
                                            label="Condição climática"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.classification?.message}
                                            onChange={(option: any) => field.onChange(option.value)}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <Effective providers={providers} effectiveClassifications={effectiveClassifications || []} />
                        <Controller
                            name="observations"
                            control={control}
                            render={({ field }) => {
                                return <Editor {...field} label="Observações" parentClasses="mb-6" />;
                            }}
                        />
                        <WorkDiaries providers={providers} />
                        <div className="flex items-center mt-6">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </Modal>
    );
};

export default CreateOrUpdateFollowup;
