import React, { useEffect, useMemo } from 'react';

// Dependencies
import { number, object, string } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

// Components
import Page from './components/page';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';

// Helpers
import theme from 'settings/theme';
import dictionary from 'utils/dictionary';
import { Profile } from 'types/models/profile';
import { PagesResponse } from 'services/queries/pages/use-get-pages';
import { Option } from 'types/general';
import Select from 'components/core/form/select';

export type RulesPayload = {
    page_id: number;
    rules_ids: number[];
};

type ProfileCreateProps = {
    dashboards: Option[];
    defaultValues: Pick<Profile, 'name' | 'profile_page_rules'>;
    pages: PagesResponse['items'];
    isLoading: boolean;
    isSubmittingForm: boolean;
    selectedRules: RulesPayload[];
    onSelectRules: (page_id: number, rules: number[]) => void;
    onSubmit: (data: any) => void;
    profile?: Partial<Profile>;
};

const schema = object({
    name: string().required(dictionary.validation.required),
    dashboard_template_id: number().required(dictionary.validation.required)
});

export const groupBy = (list, keyGetter) => {
    const map = new Map();

    list.forEach((item) => {
        const key = keyGetter(item);

        const collection = map.get(key);

        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
};

const CreateOrUpdateProfile = ({ defaultValues, dashboards, pages, isLoading, isSubmittingForm, selectedRules, profile, onSelectRules, onSubmit }: ProfileCreateProps) => {
    const navigate = useNavigate();
    const { profileId } = useParams();

    const { control, handleSubmit, formState, reset } = useForm<Pick<Profile, 'name' | 'profile_page_rules' | 'dashboard_template_id'>>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        defaultValues
    });

    useEffect(() => {
        if (!!profile) {
            reset({
                name: profile.name,
                dashboard_template_id: profile.dashboard_template_id,
                profile_page_rules: Array.from(groupBy(profile?.profile_page_rules || [], ({ page_id }) => page_id)).map(([key, value]) => ({
                    page_id: key,
                    rules_ids: value.map(({ rule_id }) => rule_id)
                }))
            });
        }
    }, [profile, reset]);

    const handleClose = () => navigate(-1);

    const isEmpty = useMemo(() => !Boolean(pages?.length), [pages]);

    const handleSelectItem = (page_id: number) => (values: number[]) => {
        onSelectRules(page_id, values);
    };

    return (
        <Modal contentClassnames="w-[1000px]" onClose={handleClose}>
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {!!profileId ? 'Atualizar perfil' : 'Novo perfil'}
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                        <Controller name="name" control={control} render={({ field }) => <Input {...field} label="Nome" error={formState.errors.name?.message} />} />
                        <Controller
                            name="dashboard_template_id"
                            control={control}
                            render={({ field }) => {
                                const value = dashboards.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={dashboards}
                                        label="Dashboard"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.dashboard_template_id?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                    <Text as="h6" variant="h6" className="text-heading mb-1">
                        Páginas
                    </Text>
                    <Text as="span" className="block mb-6 text-base-500 italic" variant="body.regular.xs">
                        Selecione as permissões que este perfil possuirá em cada página.
                    </Text>
                    {isEmpty ? (
                        <Text as="p" className="text-base-400 italic mb-7">
                            Nenhum resultado encontrado.
                        </Text>
                    ) : (
                        <>
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-10 mb-8">
                                {pages?.map((item) => {
                                    const pageSelected = selectedRules.find((rule) => rule.page_id === item.id);

                                    return <Page {...item} rulesSelecteds={pageSelected?.rules_ids} title={item.name} rules={item.rules} key={item.id} onSelectItem={handleSelectItem(item.id)} />;
                                })}
                            </div>
                            <div className="flex items-center">
                                <Button disabled={isSubmittingForm} loading={isSubmittingForm} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                    Enviar
                                </Button>
                                <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                    Cancelar
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateProfile;
