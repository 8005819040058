import { useEffect, useState } from 'react';
import { array, boolean, mixed, number, object, string } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import dictionary from 'utils/dictionary';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ProjectTypeTermPayload } from 'types/models/project';
import Editor from 'components/core/form/editor';
import Button from 'components/core/button';
import Select from 'components/core/form/select';
import Files from 'pages/private/operationals/progress/files';
import { File, FileApiModel } from 'types/models/file';
import useGetTermOptions from 'services/queries/terms/use-get-term-type-options';
import useGetTermType from 'services/queries/terms/use-get-term-type';
import { getTermTypeQuery } from '../utils';
import Input from 'components/core/form/input';
import DatePicker from 'components/core/datepicker';
import { Modify } from 'types/general';
import usePreviousValue from 'hooks/value/use-previous-value';
import useGetQueryParam from 'hooks/router/use-get-query-param';

const caveatOptions = [
    { value: true, label: 'Projeto/Obra com ressalvas' },
    { value: false, label: 'Projeto/Obra sem ressalvas' }
];

type TermsCreateOrUpdateProps = {
    defaultValues: ProjectTypeTermPayload;
    documents: File[];
    isSubmitting: boolean;
    onSubmit: (data: Partial<Modify<ProjectTypeTermPayload, { fields: Array<{ name: string; value: any }> }>>) => void;
};

const TermsCreateOrUpdate = ({ defaultValues, documents, isSubmitting, onSubmit }: TermsCreateOrUpdateProps) => {
    const navigate = useNavigate();
    const { projectTypeTermId } = useParams();
    const termStatus = useGetQueryParam('termStatus');

    const filesHook = useState<Array<Partial<File & FileApiModel>>>([]);

    const { data: termTypes = [] } = useGetTermOptions();

    const schema = object({
        type: number().required(dictionary.validation.required),
        caveatDescription: string().nullable(true),
        withCaveat: boolean().default(false),
        observations: string(),
        fields: array(
            object({
                value: mixed(),
                name: string()
            })
        ).nullable(true)
    });

    const { control, formState, handleSubmit, watch, reset } = useForm<ProjectTypeTermPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(schema),
        defaultValues
    });

    useEffect(() => {
        if (Boolean(documents.length) && !Boolean(termStatus)) {
            filesHook[1](documents);
        }
        // eslint-disable-next-line
    }, [documents, termStatus]);

    const termTypeId = watch('type');
    const newTermTypeId = usePreviousValue(termTypeId);

    const withCaveat = watch('withCaveat');

    const { data: termType } = useGetTermType(getTermTypeQuery(termTypeId), termTypeId);

    const { fields, replace } = useFieldArray({ name: 'fields', control });

    useEffect(() => {
        if (newTermTypeId !== termTypeId && !!newTermTypeId) {
            reset({
                withCaveat: false,
                fields: [],
                documents: [],
                type: termTypeId,
                caveatDescription: undefined,
                observations: undefined
            });
        }
    }, [newTermTypeId, termTypeId, reset]);

    useEffect(() => {
        if (termType?.fields) {
            const arr = termType?.fields.fields?.filter((item) => item.visible) || [];

            replace(arr || []);
        }
    }, [termType?.fields, replace, projectTypeTermId]);

    const handleBack = () => navigate(-1);

    const submit = (data: ProjectTypeTermPayload) => {
        const [files] = filesHook;
        const filesMapped: Array<{ file: number }> = files.map((item) => ({ file: item.id || 0 })).filter((item) => Boolean(item.file));

        if (Boolean(termStatus)) {
            return onSubmit(filesMapped[0] as any);
        }

        const payload = {
            type: data.type,
            withCaveat: data.withCaveat,
            fields: (data.fields || [])?.map((item) => ({
                name: item.name,
                value: item.value,
                type: item.type,
                title: item.title
            })),
            ...(Boolean(data.caveatDescription) && { caveatDescription: data.caveatDescription }),
            ...(Boolean(data.observations) && { observations: data.observations }),
            ...(Boolean(filesMapped.length) && { documents: filesMapped })
        };

        onSubmit(payload);
    };

    return (
        <Modal
            contentClassnames="w-[700px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {termStatus ? 'Finalizar' : projectTypeTermId ? 'Atualizar' : 'Novo'} termo
                </Text>
            }
            onClose={handleBack}>
            <form className="px-3 sm:px-6 pb-6" onSubmit={handleSubmit(submit)}>
                {Boolean(termStatus) ? (
                    <Files multiple={false} hook={filesHook} label="Arquivos" className="!mb-9" />
                ) : (
                    <>
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => {
                                const value = termTypes.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={termTypes}
                                        label="Tipo"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.type?.message}
                                        parentClassName="mb-6"
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                        {fields.map((item, index) => {
                            return (
                                <Controller
                                    key={item.id}
                                    control={control}
                                    name={`fields.${index}.value` as any}
                                    render={({ field }) => {
                                        if (item.type === 'text') {
                                            return <Input {...field} label={item.title} parentClassName="mb-6" error={formState.errors.fields?.[index]?.value?.message} />;
                                        }

                                        return (
                                            <DatePicker
                                                label={item.title}
                                                placeholderText="Selecione uma data"
                                                parentClasses="mb-6"
                                                error={formState.errors.fields?.[index]?.value?.message}
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            );
                        })}
                        {termType?.with_attachment && <Files hook={filesHook} label="Arquivos" className="!mb-9" />}
                        {termType?.with_observations && (
                            <Controller
                                name="observations"
                                control={control}
                                render={({ field }) => <Editor {...field} label="Observações" error={formState.errors.observations?.message} parentClasses="mb-6" />}
                            />
                        )}
                        {termType?.with_caveat_fields && (
                            <div className="border border-base-300 py-4 px-2 sm:p-4 rounded-[14px] mb-6">
                                <Text as="p" variant="h6" className="text-heading mb-4">
                                    Ressalvas
                                </Text>
                                <Controller
                                    control={control}
                                    name="withCaveat"
                                    render={({ field }) => {
                                        const value = caveatOptions.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={caveatOptions}
                                                label="Ressalvas do Projeto/Obra"
                                                placeholder="Selecione uma opção"
                                                error={formState.errors.type?.message}
                                                onChange={(option: any) => field.onChange(option.value)}
                                            />
                                        );
                                    }}
                                />
                                {withCaveat && (
                                    <Controller
                                        name="caveatDescription"
                                        control={control}
                                        render={({ field }) => <Editor {...field} label="Descrição da ressalva" error={formState.errors.observations?.message} parentClasses="mt-6" />}
                                    />
                                )}
                            </div>
                        )}
                    </>
                )}
                <div className="flex items-center">
                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                        Enviar
                    </Button>
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                        Cancelar
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default TermsCreateOrUpdate;
