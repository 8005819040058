// Dependencies
import { object, string, number, array } from 'yup';
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { Option } from 'types/general';
import { PersonContractType } from 'types/models/person';

// Helpers
import { Status } from 'types/general';
import dictionary from 'utils/dictionary';
import { Gender } from 'types/models/person';

const cnpjSchema = string().when('type', {
    is: (type) => type === PersonContractType.Juridic,
    then: string()
        .test('is-valid-cnpj', 'CNPJ inválido', (value = '') => {
            if (!value.length) {
                return true;
            }
            return isValidCNPJ(value?.replace(/[^\d]+/g, ''));
        })
        .required(dictionary.validation.required)
});

const cpfSchema = string().when('type', {
    is: (type) => type === PersonContractType.Personal,
    then: string()
        .trim()
        .test('is-valid-cpf', 'CPF inválido', (value = '') => {
            if (!value.length) {
                return true;
            }
            return isValidCPF(value?.replace(/[^\d]+/g, ''));
        })
        .required(dictionary.validation.required)
});

export const companyFormSchema = (options: { id: number; contactsWithUser: boolean }) =>
    object({
        title: string().required(dictionary.validation.required).trim(),
        type_business_id: number().positive().required(dictionary.validation.required),
        status: number().default(Status.Active),
        address: object({
            city_id: number(),
            compliment: string().nullable(),
            neighbourhood: string().trim(),
            number: string().trim(),
            state_id: number(),
            status: number().default(Status.Active),
            street: string().trim(),
            zip: string().trim()
        })
            .notRequired()
            .nullable(true),
        ...(!options?.id && {
            contacts: array(
                object({
                    gender: number().oneOf([Gender.Female, Gender.Male, Gender.Other]),
                    name: string().trim(),
                    phones: array(
                        object({
                            ddd: string().min(2, dictionary.validation.string.min(2)).max(2, dictionary.validation.string.max(2)).trim(),
                            number: string()
                        })
                    ),
                    title: string().trim()
                })
            )
        }),
        juridic_name: string().when('type', {
            is: PersonContractType.Juridic,
            then: string().required(dictionary.validation.required)
        }),
        juridic_fantasy_name: string().when('type', {
            is: PersonContractType.Juridic,
            then: string().trim().required(dictionary.validation.required)
        }),
        juridic_document: cnpjSchema,
        personal_document: cpfSchema
    });

export const contractTypesOptionsWithoutIntern: Option[] = [
    { label: 'Pessoa Jurídica', value: PersonContractType.Juridic },
    { label: 'Pessoa Física', value: PersonContractType.Personal }
];

export const getLabelNameByPersonContractType = (personContractType: PersonContractType) => {
    const finderByPersonContractType = contractTypesOptionsWithoutIntern?.find((item) => item.value === personContractType);

    return finderByPersonContractType?.label;
};
