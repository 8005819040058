import { yupResolver } from '@hookform/resolvers/yup';
import forgotPasswordPadlock from 'assets/images/forgot-password-padlocks.svg';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import Button from 'components/core/button';
import { Link } from 'react-router-dom';
import { ForgotPasswordPayload } from 'types/models/user';
import Input from 'components/core/form/input';
import dictionary from 'utils/dictionary';
import { object, string } from 'yup';

type ForgotPasswordFormProps = {
    isLoading: boolean;
    onSubmit: (payload: ForgotPasswordPayload) => void;
};
const ForgotPasswordForm = ({ isLoading, onSubmit }: ForgotPasswordFormProps) => {
    const schema = object({
        email: string().required(dictionary.validation.required)
    });

    const { control, handleSubmit, formState } = useForm<ForgotPasswordPayload>({
        mode: 'onSubmit',
        defaultValues: {
            email: ''
        },
        resolver: yupResolver(schema)
    });

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center mb-[2.3125rem]">
                <img src={forgotPasswordPadlock} alt="Ícone de cadeado" />
            </div>
            <Text as="h1" variant="h1" className={styles.title}>
                Recupere sua senha
            </Text>
            <div className="w-full mb-6">
                <Controller name="email" control={control} render={({ field }) => <Input {...field} placeholder="Ex: email@email.com" label="E-mail" error={formState.errors.email?.message} />} />
            </div>
            <Button type="submit" size="medium" variant="contained" color="primary" fullWidth={true} disabled={isLoading} loading={isLoading}>
                Recuperar senha
            </Button>
            <div className="flex align-center justify-center mt-[5rem] mb-[1.5rem]">
                <Text>
                    <span className="text-base-500">Voltar para o </span>
                    <Link className="text-primary-400" to={'/'}>
                        Login
                    </Link>
                </Text>
            </div>
        </form>
    );
};

const styles = {
    form: 'bg-white w-full rounded-t-2xl p-7 sm:rounded-b-2xl sm:px-20 sm:pt-20 xl:max-w-2xl m-h[508px]',
    title: 'mb-4 sm:text-center text-heading sm:mb-10'
};

export default ForgotPasswordForm;
