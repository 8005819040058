import { useEffect, useMemo, useState } from 'react';

// Dependencies
import _set from 'lodash/set';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';

// Components
import List, { CustomProfile } from './list';

// Helpers
import useDelete from 'services/queries/crud/use-delete';
import useGetAll from 'services/queries/crud/use-get-all';
import { CrudPageProps, GraphqlPaginationVariables, ItemsPerPage } from 'types/graphql';
import ConfirmModal from 'components/core/modal/confirm';
import { Rule } from 'types/permissions';
import { Page } from 'types/models/page';
import { slugify } from 'utils/string';
import { ListPageContextProvider } from './context';

const INITAL_GRAPHQL = {
    columns: [],
    table: '',
    searchableField: ''
};

type ListPageProps = {
    page?: Page;
} & CrudPageProps;

const ListPage = ({
    graphql = INITAL_GRAPHQL,
    title,
    createPagePath = 'novo',
    api,
    hideHeader = false,
    hideCreateButton = false,
    withBorder = false,
    page,
    advancedSearchComponent,
    customActions,
    customTableOptions,
    canShowDeleteButton
}: ListPageProps) => {
    const [deletePayload, setDeletePayload] = useState<{ id?: number; slug?: string }>({});

    const navigate = useNavigate();

    const initialParams = useMemo(() => {
        const orderBy = graphql.orderBy || {};

        const [firstColumn] = graphql?.columns?.filter(({ options }) => options?.display !== 'excluded') || [];

        if (!Object.keys(orderBy)?.length) {
            // using 'set' here to manipulate nested items
            _set(orderBy, firstColumn?.name, 'asc');
        }

        return {
            limit: 10 as const,
            page: 1,
            offset: 0,
            where: graphql.where,
            orderBy
        };
    }, [graphql]);

    const [params, setParams] = useState<GraphqlPaginationVariables<any>>(initialParams);

    useEffect(() => {
        setParams(initialParams);
    }, [initialParams]);

    const list = useGetAll<CustomProfile>(graphql, params);

    const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useDelete<CustomProfile>({
        queryConfig: graphql,
        variables: params,
        apiPayload: api
    });

    const handleAction = () => navigate(createPagePath);

    const handleChangePage = (page: number) => {
        setParams({
            ...params,
            page,
            offset: (page || 1) * params.limit - params.limit
        });
    };

    const handleChangeRowsPerPage = (numberOfRows: number) => setParams({ ...params, limit: numberOfRows as ItemsPerPage, offset: 0 });

    const handleColumnSortChange = (changedColumn: string, direction: 'asc' | 'desc') => {
        const orderBy = {};

        // using 'set' here to manipulate nested items
        if (!!changedColumn) {
            _set(orderBy, changedColumn, direction);
        }

        setParams({
            ...params,
            orderBy
        });
    };

    const handleDelete = async () => {
        try {
            if (!!deletePayload) {
                if (deletePayload.slug) {
                    deletePayload.slug = slugify(`${deletePayload.slug.trim()} ${new Date().getTime()}`);
                }

                await deleteItem(deletePayload);

                setDeletePayload({});
            }
        } catch (error) {
            console.log('ListPage -> handleDelete": ', error);
        }
    };

    useEffect(() => {
        // Clear search field on every table name change
        if (graphql.table) {
            setParams((params) => {
                _set(params, `where.${graphql.searchableField}`, undefined);
                _set(params, `where._or`, undefined);

                return params;
            });
        }
    }, [graphql.searchableField, graphql.table]);

    const isLoading: any = !!list?.isLoading || isLoadingDelete;

    const cantCreateRecord = useMemo(() => {
        const hasPermission = page?.rules.some((item) => item.key === Rule.Create);

        return hideCreateButton || !hasPermission;
    }, [page?.rules, hideCreateButton]);

    const handleDeletePayload = (id?: number, slug?: string) => setDeletePayload({ id, slug });

    const excludedColumns = useMemo(() => {
        const columns = ['id', 'actions', ''];
        if (!!graphql?.excludedSearchColumnsName?.length) {
            columns.push(...graphql.excludedSearchColumnsName);
        }

        return columns;
    }, [graphql.excludedSearchColumnsName]);

    const columnsToSearch = useMemo(() => {
        const columnsWithoutExcludedsColumns = graphql?.columns?.filter((column) => !excludedColumns.includes(column.name));
        const columnsSearch = columnsWithoutExcludedsColumns?.map((column) => column.name);

        return columnsSearch;
    }, [graphql.columns, excludedColumns]);

    // Util to update a single param
    const handleChangeParam = (data: { name: string; value: any }) => {
        const { name, value } = data || {};

        const payload: any = {
            ...params,
            offset: 0,
            page: 1
        };

        // lodash makes it possible to pass nested names
        if (value === undefined) {
            // Removing the filter from the param if it's undefined

            if (name) {
                setParams(_omit(payload, `where.${name}`.replace(/._eq|._ilike/, '')) as any);
                return;
            }

            setParams(_omit(payload, `where._or`) as any);
            return;
        }
        if (name) {
            _set(payload, `where.${name}`, value);
        } else {
            columnsToSearch?.forEach((column, index) => {
                _set(payload, `where._or.[${index}].${column}`, value);
            });
        }

        setParams(payload);
    };

    // TODO: We should use Context to call the onChangeParams inside the input component
    const handleSearchTextChange = (term: string | null) => {
        handleChangeParam({ name: graphql.searchableField!, value: term ? { _ilike: `%${term}%` } : undefined });
    };

    const getParam = (name: string) => _get(`${params}.where`, name);

    const handleChangeParams = (newParams: any) => {
        const payload: any = {
            ...params,
            offset: 0,
            page: 1
        };

        _set(payload, 'where', {
            ...payload?.where,
            ...newParams
        });
    };

    return (
        <ListPageContextProvider params={params} onChangeParams={handleChangeParams} onChangeParam={handleChangeParam} getParam={getParam}>
            <List
                {...list}
                page={page}
                isLoading={isLoading}
                columns={graphql?.columns || []}
                params={params}
                title={title || ''}
                searchableField={graphql.searchableField!}
                onAction={handleAction}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onColumnSortChange={handleColumnSortChange}
                onDelete={handleDeletePayload}
                onSearchChange={handleSearchTextChange}
                hideHeader={hideHeader}
                hideCreateButton={cantCreateRecord}
                withBorder={withBorder}
                advancedSearchComponent={advancedSearchComponent}
                customActions={customActions}
                canShowDeleteButton={canShowDeleteButton}
                columnsToSearch={columnsToSearch}
                tableOptions={customTableOptions}
            />
            {!isEmpty(deletePayload) && (
                <ConfirmModal
                    title="Apagar registro"
                    description="Você tem certeza que deseja apagar este registro?"
                    isLoading={isLoadingDelete}
                    onClose={() => setDeletePayload({})}
                    onConfirmAction={handleDelete}
                />
            )}
        </ListPageContextProvider>
    );
};

export default ListPage;
