import { useQuery } from 'react-query';
import api from 'services/api';
import { getProjectMovementsKey } from './graphql';
import { UpdateProjectTypeStatusPayload } from './use-update-project-type-status';
import { Project, ProjectTypeStatus } from 'types/models/project';
import { CommercialBudgetResponse } from 'types/models/commercial';
import { CamelCase } from 'types/utils';

export type ProjectMovementsResponse = Pick<UpdateProjectTypeStatusPayload, 'informedDate' | 'reason'> & {
    projectTypeStatus: ProjectTypeStatus;
    observations?: string;
} & Partial<Pick<CommercialBudgetResponse, 'person'>> &
    Partial<CamelCase<Pick<Project, 'project_status'>>>;

const useGetProjectMovements = (projectProposalTypeId?: number, isProjectTypes = true) => {
    const base = isProjectTypes ? 'project-types' : 'project';

    const request = async () => {
        const { data } = await api.get<ProjectMovementsResponse[]>(`/${base}/${projectProposalTypeId}/movements`);

        return data;
    };

    return useQuery(getProjectMovementsKey(projectProposalTypeId), request);
};

export default useGetProjectMovements;
