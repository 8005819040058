import { array, boolean, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isValidDate } from 'utils/date';

dayjs.extend(isBetween);

const dateExistsInAnotherPeriod = (value, context) => {
    const findedFrom = context?.from?.find((item) => !!item?.value?.periods);
    const periods = findedFrom?.value?.periods || [];

    // console.log('periods', periods);

    const isInvalidDate = periods.some((item) => {
        // console.log('item', item);
        // console.log('value', value);

        if (!isValidDate(item.contractStartDate) || !isValidDate(item.contractEndDate) || !value) {
            return false;
        }

        return dayjs(value).isBetween(item.contractStartDate, item.contractEndDate, 'day');
    });

    return !isInvalidDate;
};

export const managementResourceSchema = object({
    resources: array(
        object({
            person: number().required(dictionary.validation.required),
            typeManagementInformation: number().required(dictionary.validation.required),
            periods: array(
                object({
                    percentage: number().required(dictionary.validation.required),
                    contractStartDate: string().required(dictionary.validation.required).test({
                        name: 'date-exists-in-another-period',
                        message: 'Data existente em outro período',
                        test: dateExistsInAnotherPeriod
                    }),
                    contractEndDate: string().required(dictionary.validation.required).test({
                        name: 'date-exists-in-another-period',
                        message: 'Data existente em outro período',
                        test: dateExistsInAnotherPeriod
                    })
                })
            ).min(1, dictionary.validation.array.min(1))
        })
    ).min(1, dictionary.validation.array.min(1))
});

export const nonManagementResourceSchema = object({
    resources: array(
        object({
            isExternal: boolean().required(dictionary.validation.required).default(false),
            services: array(number()).when('isExternal', (isExternal, schema) => {
                return isExternal ? schema.required(dictionary.validation.required).min(1, dictionary.validation.array.min(1)) : schema.notRequired().nullable();
            }),
            provider: number().when('isExternal', (isExternal, schema) => (isExternal ? schema.required(dictionary.validation.required) : schema.notRequired().nullable())),
            person: number().when('isExternal', (isExternal, schema) => (!isExternal ? schema.required(dictionary.validation.required) : schema.notRequired().nullable())),
            typeManagementInformation: number().when('isExternal', (isExternal, schema) => (!isExternal ? schema.required(dictionary.validation.required) : schema.notRequired().nullable()))
        })
    ).min(1, dictionary.validation.array.min(1))
});
