import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { MenuGroup } from 'types/models/menu';
import { getMenuGroupKey } from './graphql';

const useGetMenuGroup = (query: string, menuGroupId?: string) => {
    const request = async () => {
        return apiGraphQl<{ menu_group_by_pk: Partial<MenuGroup> }>(query).then(({ menu_group_by_pk }) => ({
            ...menu_group_by_pk,
            menu_pages: menu_group_by_pk.menu_pages?.map((item) => ({
                value: item.page.id,
                label: item.page.slug
            }))
        }));
    };

    return useQuery(getMenuGroupKey(menuGroupId), request, { enabled: !!menuGroupId });
};

export default useGetMenuGroup;
