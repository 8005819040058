import React, { useState } from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import Empty from 'components/empty';
import useGetCiso from 'services/queries/projects/use-get-ciso';
import Badge from 'components/core/badge';
import { ApprovalStatus } from 'types/general';
import CisoDetails from './ciso-details';
import useGetQueryParam from 'hooks/router/use-get-query-param';

type CisoViewPageProps = {
    withActions: boolean;
};
const CisoViewPage = ({ withActions }: CisoViewPageProps) => {
    const { cisoId } = useParams();
    const { palette } = useTheme();
    const navigate = useNavigate();

    const projectProposalTypeId = useGetQueryParam('projectProposalTypeId');

    const [signpostUrl, setSignpostUrl] = useState<string>();

    const { data: ciso, isLoading, isError } = useGetCiso(cisoId);

    const handleToggleSignpostModal = (value?: string) => () => setSignpostUrl(value);

    const handleBack = () => navigate(-1);

    return (
        <>
            <Modal
                closeOnClickOutside={false}
                contentClassnames="w-[800px]"
                headerLeft={
                    <Text as="h3" variant="h4" className="text-heading">
                        CISO
                    </Text>
                }
                headerRight={
                    <Badge variant={ciso?.approval_status === ApprovalStatus.Active ? 'secondary' : 'grey'}>{ciso?.approval_status === ApprovalStatus.Active ? 'Ativo' : 'Versão anterior'}</Badge>
                }
                onClose={handleBack}>
                {isLoading ? (
                    <div className="p-4">
                        <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                    </div>
                ) : isError || !ciso ? (
                    <Empty title="CISO não encontrado" className="p-5" />
                ) : (
                    <>
                        <div className="p-6 pt-0">
                            <CisoDetails withActions={withActions} projectProposalTypeId={projectProposalTypeId!} ciso={ciso!} onSetSignpostUrl={setSignpostUrl} />
                        </div>
                    </>
                )}
            </Modal>
            {Boolean(signpostUrl) && (
                <Modal onClose={handleToggleSignpostModal()}>
                    <img src={signpostUrl} />
                </Modal>
            )}
        </>
    );
};

export default CisoViewPage;
