import DocumentsForm from 'components/documents';
import React from 'react';
import { useParams } from 'react-router-dom';
import useGetPersonDocument from 'services/queries/people/use-get-person-document';
import { usePersonDocumentMutation } from 'services/queries/people/use-person-document-mutation';

const PersonDetailsDocumentsForm = () => {
    const { documentId, personId, contractId } = useParams();

    const title = !!documentId ? 'Editar documento' : 'Novo documento';

    const { isLoading, mutate } = usePersonDocumentMutation({ documentId, personId, contractId });
    const { isLoading: isBuilding, data: document } = useGetPersonDocument({ documentId });

    const onSubmit = (data: any) => {
        mutate(data);
    };

    return <DocumentsForm defaultValues={document} title={title} onSubmit={onSubmit} isSubmitting={isLoading} isBuilding={isBuilding} />;
};

export default PersonDetailsDocumentsForm;
