import { useEffect, useMemo, useState } from 'react';
import Modal from 'components/core/modal';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/core/button';
import Input from 'components/core/form/input';
import { createOrUpdatePageMutation, getPageQuery, pageSchema } from './utils';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Page } from 'types/models/page';
import Checkbox from 'components/core/form/checkbox';
import { Modify, Option, Status } from 'types/general';
import useCreateOrUpdatePage from 'services/queries/pages/use-create-or-update-page';
import useGetPage from 'services/queries/pages/use-get-page';
import hexToRgba from 'utils/hex-to-rgba';
import useTheme from '@mui/material/styles/useTheme';
import Switch from 'components/core/switch';
import useGetPages from 'services/queries/pages/use-get-pages';
import useDeleteSubPages from 'services/queries/pages/use-delete-subpages';
import useCreateSubPages from 'services/queries/pages/use-create-subpages';
import useGetRules from 'services/queries/rules/use-get-rules';
import { getRulesQuery } from 'pages/private/rules/utils';
import useDeletePageRules from 'services/queries/rules/use-delete-page-rule';
import useCreatePageRules from 'services/queries/rules/use-create-page-rule';
import useToast from 'hooks/toast/use-toast';
import SortableSelect from 'components/core/form/select-sortable';

export type PagePayload = {
    has_subpages: boolean;
    subpages: Array<Option & { order_menu: number }>;
    page_rules: string[] | [];
} & Modify<Pick<Page, 'key_page' | 'name' | 'slug' | 'permalink' | 'system' | 'title' | 'controller'>, { system: number }>;

const CreateOrUpdatePagesPage = () => {
    const { pageId } = useParams();
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { showToast } = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const params = useOutletContext<GraphqlPaginationVariables<Page>>();

    const { data: pages = [] } = useGetPages();

    const { mutateAsync: createSubPages } = useCreateSubPages();
    const { mutateAsync: createPageRules } = useCreatePageRules();

    const { mutateAsync: deleteSubPages } = useDeleteSubPages();
    const { mutateAsync: deletePageRules } = useDeletePageRules();

    const { data: rules = [] } = useGetRules(getRulesQuery);
    const { data: page } = useGetPage(getPageQuery(pageId), pageId);
    const { mutateAsync: createOrUpdatePage } = useCreateOrUpdatePage(params, createOrUpdatePageMutation(pageId), Number(pageId) || 0);

    const { control, formState, handleSubmit, reset, setValue, watch, register } = useForm<PagePayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(pageSchema),
        defaultValues: {
            key_page: '',
            slug: '',
            permalink: '',
            system: 0,
            title: '',
            has_subpages: false,
            subpages: [],
            page_rules: [],
            controller: ''
        }
    });

    const hasSubpages = watch('has_subpages');
    const subpages = watch('subpages');
    const pageRules = watch('page_rules');

    useEffect(() => {
        if (page) {
            const subpagesArr = (page.subpages || []).map((item) => ({
                label: item.page?.name,
                value: item.page_id,
                order_menu: item.order_menu
            }));

            reset({
                ...page,
                system: page.system ? 1 : 0,
                subpages: subpagesArr,
                page_rules: page.page_rules?.map((item) => item.id.toString()) || [],
                ...(Boolean(page.subpages?.length) && { has_subpages: true })
            });
        }
    }, [page, reset]);

    const formattedPages = useMemo(() => {
        return pages.map((item) => ({
            value: item.id,
            label: item.name
        }));
    }, [pages]);

    const handleClose = () => navigate(-1);

    const handleSubpages = (value: boolean) => {
        setValue('has_subpages', value);

        if (hasSubpages) {
            setValue('subpages', []);
            return setValue('has_subpages', false);
        }
    };

    const submit = async (data: PagePayload) => {
        try {
            setIsLoading(true);
            delete (data as any)['has_subpages'];

            if (Boolean(pageId)) {
                delete (data as any)['subpages'];
                delete (data as any)['page_rules'];
            }

            const { subpages: dataSubPages, ...rest } = data;

            const payload = {
                ...rest,
                system: Boolean(data.system)
            };

            if (Boolean(dataSubPages?.length)) {
                payload['subpages'] = {
                    data: dataSubPages.map((item) => ({
                        order_menu: item.order_menu,
                        page_id: item.value,
                        status: Status.Active
                    }))
                } as any;
            }

            if (Boolean(payload.page_rules?.length)) {
                payload['page_rules'] = {
                    data: payload.page_rules.map((item) => ({ rule_id: item }))
                } as any;
            }

            await createOrUpdatePage(payload as any);

            // DELETE ALL SUBPAGES
            if (Boolean(pageId)) {
                if (!!subpages.length) {
                    await deleteSubPages(Number(pageId));

                    const newSubpages = subpages.map((item) => ({
                        order_menu: item.order_menu,
                        status: Status.Active,
                        page_id: item?.value,
                        page_parent_id: pageId
                    }));

                    await createSubPages(newSubpages);
                }

                if (!!pageRules.length) {
                    await deletePageRules(Number(pageId));

                    const newPageRules = pageRules.map((item) => ({
                        rule_id: item,
                        page_id: pageId
                    }));

                    await createPageRules(newPageRules);
                }
            }

            navigate(-1);
            showToast(`Página ${!!pageId ? 'atualizada' : 'criada'} com sucesso`, 'success');
        } catch (error) {
            console.log('submit', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            contentClassnames="w-[850px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {pageId ? 'Atualizar' : 'Nova'} página
                </Text>
            }
            closeOnClickOutside={false}
            onClose={handleClose}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(submit)}>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-4">
                    <Controller name="title" control={control} render={({ field }) => <Input {...field} error={formState.errors.title?.message} label="Título (exibição)" />} />
                    <Controller name="name" control={control} render={({ field }) => <Input {...field} error={formState.errors.name?.message} label="Nome" />} />
                    <Controller name="slug" control={control} render={({ field }) => <Input {...field} error={formState.errors.slug?.message} label="Slug" />} />
                    <Controller name="controller" control={control} render={({ field }) => <Input {...field} error={formState.errors.controller?.message} label="Controller" />} />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
                    <Controller name="key_page" control={control} render={({ field }) => <Input {...field} error={formState.errors.key_page?.message} label="Chave" />} />
                    <Controller name="permalink" control={control} render={({ field }) => <Input {...field} error={formState.errors.permalink?.message} label="Link" />} />
                    <div className="h-12 border border-base-300 p-[13px] rounded-xl mt-[26px] grid place-items-start">
                        <Controller
                            name="system"
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    value={field.value}
                                    label="Página do sistema"
                                    checked={Boolean(field.value)}
                                    onChange={(event) => {
                                        const isChecked = event.currentTarget.checked;
                                        setValue('system', isChecked ? 1 : 0);
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="mb-6 border border-base-300 rounded-[14px] p-4">
                    <Text variant="body.medium.sm" as="span" className="text-heading mb-3 block">
                        Permissões
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-5 place-items-start gap-4">
                        {rules.map((item) => {
                            const isChecked = pageRules.find((selected) => +selected === item.value);

                            return <Checkbox {...register('page_rules')} checked={!!isChecked} value={item.value} key={item.value} label={item.label} />;
                        })}
                    </div>
                </div>
                <div className="-mx-6 px-6 py-5 mb-6" style={{ backgroundColor: hexToRgba(palette.secondary[100], 0.2) }}>
                    <div className="flex justify-between items-center">
                        <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                            Esta página possui subpáginas?
                        </Text>
                        <Switch value={hasSubpages} onChange={handleSubpages} />
                    </div>
                </div>
                {hasSubpages && (
                    <>
                        <Controller
                            name="subpages"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <SortableSelect
                                        {...field}
                                        size="small"
                                        value={subpages}
                                        isMulti={true}
                                        options={formattedPages}
                                        label="Páginas"
                                        placeholder="Selecione uma opção"
                                        error={(formState as any).errors.subpages?.message}
                                        parentClassName="mb-6"
                                        onChange={(options: any) => {
                                            const arr = options?.map((item, index) => ({ ...item, order_menu: index + 1 }));

                                            setValue('subpages', arr);
                                        }}
                                    />
                                );
                            }}
                        />
                    </>
                )}
                <div className="flex items-center">
                    <Button disabled={isLoading} loading={isLoading} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                        Enviar
                    </Button>
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                        Cancelar
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default CreateOrUpdatePagesPage;
