import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useGetSolicitation from 'services/queries/projects/use-get-solicitation';
import Request from './request';

const RequestPage = () => {
    const { projectProposalTypeId, projectTypeSolicitationId } = useParams();

    const solicitation = useGetSolicitation(projectProposalTypeId, projectTypeSolicitationId);

    return (
        <>
            <Request {...solicitation} />
            <Outlet />
        </>
    );
};

export default RequestPage;
