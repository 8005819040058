import { useQuery } from 'react-query';
import api from 'services/api';
import { ProviderFinancialSetup } from 'types/models/provider';
import { CamelCase } from 'types/utils';
import { getFinancialSetupKey } from './graphql';

const useGetFinancialSetup = (providerFinancialSetupId?: number) => {
    const fetcher = async () => api.get<CamelCase<ProviderFinancialSetup>>(`/provider-financial-setups/${providerFinancialSetupId}`).then(({ data }) => data);

    return useQuery(getFinancialSetupKey(providerFinancialSetupId), fetcher, { enabled: Boolean(providerFinancialSetupId) });
};

export default useGetFinancialSetup;
