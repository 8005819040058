import classNames from 'classnames';
import Card from 'components/core/card';
import HomeMyTasksItem, { HomeMyTasksItemProps } from './item';
import HomeMyTasksSkeleton from './skeleton';
import MyTasksSwitch from './switch';
import Icon from 'components/core/icon';

type Props = {
    title: string;
    className?: string;
    items: HomeMyTasksItemProps[];
    activePeriod: number;
    setActivePeriod(value: number): void;
    isLoading?: boolean;
    emptyMessage?: string;
    emptyIcon?: string;
};

const HomeMyTasks = ({ title, className, items = [], activePeriod, setActivePeriod, isLoading = false, emptyMessage = 'Nenhum item foi encontrado', emptyIcon = 'ico-emoji-shocked' }: Props) => {
    const isEmpty = !isLoading && !items.length;

    if (isLoading) {
        return <HomeMyTasksSkeleton title={title} className={className} />;
    }

    return (
        <Card style={{ height: '100%' }} className={classNames('flex-1 flex flex-col py-6 px-4 pt-4 w-full md:w-auto', className)}>
            <div className="flex flex-col md:mt-0 md:flex-row md:items-center mb-4 flex-wrap">
                <p className="text-heading text-xl mr-auto font-medium mb-2 md:mb-0">{title}</p>
                <MyTasksSwitch activePeriod={activePeriod} onChange={setActivePeriod} />
            </div>
            <div className={classNames('flex flex-col ', isEmpty ? 'items-center justify-center flex-1' : 'overflow-y-auto')}>
                {isEmpty ? (
                    <>
                        <p className="text-base-500 text-lg mb-2">{emptyMessage}</p>
                        <Icon name={emptyIcon} height={40} width={40} />
                    </>
                ) : (
                    items?.map((item) => <HomeMyTasksItem key={item.id} {...item} />)
                )}
            </div>
        </Card>
    );
};

export default HomeMyTasks;
