import { useOutletContext } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { CommercialBudgetOutletContext } from 'types/models/commercial';
import useCreateOrUpdateProject from 'services/queries/projects/use-create-or-update-project';
import { ProjectPayload } from 'types/models/project';
import useGetCustomerContactsOptions from 'services/queries/customers/use-get-customer-contacts-options';
import useGetCustomersOptions from 'services/queries/customers/use-get-customers-options';
import useGetProjectTypesOptions from 'services/queries/projects/use-get-project-types';
import ProjectCreateOrUpdate from 'pages/private/projects/create-or-update/create-or-update';
import { Status } from 'types/general';

const GenerateContractPage = () => {
    const [customerId, setCustomerId] = useState<number>();
    const { commercialBudget, budgetVersions = [] } = useOutletContext<CommercialBudgetOutletContext>();

    const { data: customerContacts = [] } = useGetCustomerContactsOptions(customerId);
    const { data: customers = [], isLoading: isLoadingCustomers } = useGetCustomersOptions();
    const { data: projectTypes = [], isLoading: isLoadingProjectTypes } = useGetProjectTypesOptions();

    const isBuilding = isLoadingCustomers || isLoadingProjectTypes || !commercialBudget?.id;

    const { mutateAsync: createOrUpdateProject, isLoading: isSubmitting } = useCreateOrUpdateProject(undefined, commercialBudget?.id, true);

    const submit = async (data: ProjectPayload) => {
        try {
            await createOrUpdateProject(data);
        } catch (e) {
            console.error('submit: Ocorreu um ao gerar um novo contrato para esta FP!', e);
        }
    };

    const activeBudgetVersions = useMemo(() => {
        return budgetVersions.filter((item) => item.status === Status.Active);
    }, [budgetVersions]);

    const defaultValues = useMemo(() => {
        const versionsValues = activeBudgetVersions.reduce(
            (acc: any, { footage, project_type: { id } }) => ({
                ...acc,
                footage: { floatValue: acc.footage.floatValue + footage },
                types: [...acc.types, id]
            }),
            { footage: { floatValue: 0 }, types: [] }
        );

        return {
            code: commercialBudget?.code,
            title: commercialBudget?.title,
            customer: commercialBudget?.customer.id,
            branch: commercialBudget?.branch?.id,
            footage: versionsValues?.footage,
            types: versionsValues?.types
        };
    }, [commercialBudget, activeBudgetVersions]);

    return (
        <ProjectCreateOrUpdate
            customerContacts={customerContacts}
            customers={customers}
            projectTypes={projectTypes}
            onSelectCustomer={setCustomerId}
            isSubmitting={isSubmitting}
            onSubmit={submit}
            isBuilding={isBuilding}
            defaultValues={defaultValues as any}
        />
    );
};

export default GenerateContractPage;
