import React, { useEffect, useState } from 'react';

// Dependencies
import { array, number, object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';

// Helpers
import theme from 'settings/theme';
import { Status } from 'types/general';
import dictionary from 'utils/dictionary';
import { SignpostClass } from 'types/models/signpost';
import { ProjectSignpostPayload } from 'types/models/project';
import { signpostDimensions, signpostTypes } from 'utils/statics';

type SetupSignpostProps = {
    isBuilding: boolean;
    isSubmitting: boolean;
    projectId?: number;
    onSubmit: (data: any) => void;
} & Partial<SignpostClass>;

const schema = object({
    projectSignposts: array(
        object({
            dimension: number(),
            project_id: number().required(dictionary.validation.required),
            quantity: string().required(dictionary.validation.required),
            signpost_id: number().required(dictionary.validation.required),
            status: number().required(dictionary.validation.required).default(Status.Active),
            type: number()
        })
    )
});

const SetupSignpost = ({ isBuilding, isSubmitting, projectId, signposts, title, onSubmit }: SetupSignpostProps) => {
    const navigate = useNavigate();
    const [signpostUrl, setSignpostUrl] = useState<string>();

    const handleClose = () => navigate(-1);

    const { control, formState, handleSubmit, reset } = useForm<ProjectSignpostPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (signposts) {
            reset({
                projectSignposts: signposts.map((item) => ({
                    quantity: '',
                    project_id: projectId,
                    signpost_id: item.id
                }))
            });
        }
    }, [signposts, projectId, reset]);

    const handleToggleModal = (value?: string) => () => setSignpostUrl(value);

    return (
        <>
            <Modal contentClassnames="min-w-[400px]" closeOnClickOutside={false} onClose={handleClose}>
                {isBuilding ? (
                    <div className="p-4">
                        <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                    </div>
                ) : (
                    <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                        <Text as="h3" variant="h4" className="text-heading mb-5">
                            Configurar placas
                        </Text>
                        <Text as="h5" variant="h6" className="text-heading mb-3">
                            {title}
                        </Text>
                        {signposts?.map((item, index) => {
                            return (
                                <div key={item.id} className="mb-5 border border-base-300 p-4 rounded-[14px]">
                                    <div className="flex items-center justify-between mb-2">
                                        <Text as="h5" variant="h6" className="text-heading">
                                            {item.title}
                                        </Text>
                                        <button className="flex items-center" type="button" onClick={handleToggleModal(item.url)}>
                                            <Icon name="ico-image" width={16} height={16} color={theme.extend.colors.secondary[500]} className="mr-1" />
                                            <Text as="span" variant="body.regular.sm" className="text-secondary-500">
                                                Ver placa
                                            </Text>
                                        </button>
                                    </div>
                                    <div className="grid grid-cols-3 gap-3">
                                        <Controller
                                            name={`projectSignposts.${index}.quantity`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    error={formState.errors.projectSignposts?.[index]?.quantity?.message}
                                                    label="Quantidade"
                                                    placeholder="Ex: 10"
                                                    parentClassName="w-[200px]"
                                                    rightClasses="bg-base-200 px-4"
                                                    right={
                                                        <Text as="span" variant="body.regular.xs" className="text-heading">
                                                            placa(s)
                                                        </Text>
                                                    }
                                                />
                                            )}
                                        />
                                        {item.with_type && (
                                            <Controller
                                                name={`projectSignposts.${index}.type`}
                                                control={control}
                                                render={({ field }) => {
                                                    const value = signpostTypes.find((item) => item.value === field.value);

                                                    return (
                                                        <Select
                                                            {...field}
                                                            value={value}
                                                            options={signpostTypes}
                                                            label="Tipo"
                                                            placeholder="Selecione uma opção"
                                                            error={formState.errors.projectSignposts?.[index]?.type?.message}
                                                            onChange={(option: any) => field.onChange(option.value)}
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                        {item.with_dimensions && (
                                            <Controller
                                                name={`projectSignposts.${index}.dimension`}
                                                control={control}
                                                render={({ field }) => {
                                                    const value = signpostDimensions.find((item) => item.value === field.value);

                                                    return (
                                                        <Select
                                                            {...field}
                                                            value={value}
                                                            options={signpostDimensions}
                                                            label="Dimensões"
                                                            placeholder="Selecione uma opção"
                                                            error={formState.errors.projectSignposts?.[index]?.type?.message}
                                                            onChange={(option: any) => field.onChange(option.value)}
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="flex items-center">
                            <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                                Enviar
                            </Button>
                            <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                )}
            </Modal>
            {signpostUrl && (
                <Modal onClose={handleToggleModal()}>
                    <img src={signpostUrl} />
                </Modal>
            )}
        </>
    );
};

export default SetupSignpost;
