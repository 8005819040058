import { TableCell } from '@mui/material';
import { Status } from 'types/general';
import { CrudPageProps } from 'types/graphql';
import { ClassificationType } from 'types/models/classification';
import { configsGraphql } from './configs-graphql';

export const classificationByType = (table: string, classificationType: ClassificationType | string): CrudPageProps[] => {
    return [
        {
            graphql: {
                table,
                searchableField: configsGraphql[classificationType].columns[0].name,
                columns: [
                    {
                        name: 'id',
                        options: { display: 'excluded' }
                    },
                    ...configsGraphql[classificationType].columns,
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                        }
                    }
                ],
                where: {
                    ...configsGraphql[classificationType].where,
                    _and: {
                        status: {
                            _eq: Status.Active
                        }
                    }
                },
                orderBy: {
                    ...{ [configsGraphql[classificationType].columns[0].name]: 'asc' }
                }
            },
            title: ''
        }
    ];
};
