import { useQuery } from 'react-query';
import api from 'services/api';
import { ProjectDocument } from 'types/models/project';
import { getProjectDocumentsKey } from './graphql';

export type ProjectDocumentPayload = {
    classification: number;
    file: number;
    name: string;
    description?: string;
    observation?: string;
};

const useGetProjectDocuments = (projectId?: string) => {
    const request = async () => api.get<ProjectDocument[]>(`/projects/${projectId}/documents`).then(({ data }) => data);

    return useQuery(getProjectDocumentsKey(projectId), request);
};

export default useGetProjectDocuments;
