import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';

const useDeleteSubPages = () => {
    const request = async (pageParentId: number) => {
        return apiGraphQl(
            `mutation DeleteSubPages($pageParentId: Int!) {
                item: delete_subpage(where: {page_parent_id: {_eq: $pageParentId}}) {
                    affected_rows
                }
            }`,
            { pageParentId }
        );
    };

    return useMutation(request);
};

export default useDeleteSubPages;
