import { useQuery } from 'react-query';
import api from 'services/api';
import { financialReceiptsKey } from './keys';
import { Links, Pagination } from 'types/pagination';
import { Modify } from 'types/general';

type GetFinancialReceiptsResponse = Modify<Omit<Pagination<any>, '_links'>, { links: Links }>; //TODO: type correctly - HIGOR

const useGetFinancialReceipts = (filters = '') => {
    const request = async () => {
        const { data } = await api.get<GetFinancialReceiptsResponse>(`/transactions/receipts${filters}`);

        return data;
    };

    return useQuery(financialReceiptsKey(filters), request);
};

export default useGetFinancialReceipts;
