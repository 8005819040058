import { AgendaYear, People } from '../../types';
import { DAY_HEIGHT } from '../../utils';
import _sumBy from 'lodash/sumBy';

type PeopleMapPerYearContentProps = {
    months: string[];
    people: People<AgendaYear>[];
};

const Content = ({ months, people }: PeopleMapPerYearContentProps) => {
    return (
        <>
            {people.map(({ id, agenda }) => {
                return (
                    <div className="mt-[-1px] flex divide-x divide-slate-200 w-full border-b border-slate-200 last:border-b-0" key={`line_person_${id}`}>
                        {months.map((month, monthIndex) => {
                            if (month === 'Total') {
                                return (
                                    <div key={`${month}_${monthIndex}`} style={{ height: DAY_HEIGHT }} className="min-w-[130px] sm:min-w-[60px] flex-auto sm:flex-1 relative">
                                        <div className={`truncate flex items-center uppercase text-base-600 justify-center text-heading font-bold text-xs w-full h-full`}>
                                            {_sumBy(agenda, 'total')}
                                        </div>
                                    </div>
                                );
                            }

                            const result = agenda.find((agendaItem) => agendaItem.month === monthIndex);

                            return (
                                <div key={`${month}_${monthIndex}`} style={{ height: DAY_HEIGHT }} className="flex-auto sm:flex-1 relative sm:min-w-[70px] min-w-[130px]">
                                    {!!result && (
                                        <div
                                            className={`truncate flex items-center uppercase text-base-600 justify-center text-heading font-medium text-xs w-full h-full`}
                                            style={{ background: result.color }}>
                                            {result.total}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
};

export default Content;
