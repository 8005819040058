import React, { useCallback, useMemo } from 'react';
import Modal from 'components/core/modal';
import { useNavigate, useParams } from 'react-router-dom';
import Text from 'components/core/text';
import useTheme from '@mui/material/styles/useTheme';
import { Option } from 'types/general';
import useGetPersonContract from 'services/queries/person-contract/use-get-use-person-contract';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import _get from 'lodash/get';
import { PersonContractStatusTitle } from '..';
import dictionary from 'utils/dictionary';
import Button from 'components/core/button';
import { PersonContractType } from 'types/models/person';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import Documents from './documents';
import PersonContractDetailsMovements, { PersonContractMovement } from './movements';
import { Rule } from 'types/permissions';

type Response = {
    id: number;
    contractStatus: {
        id: number;
        title: string;
    };
    branch: {
        name: string;
        id: number;
    };
    position: {
        id: number;
        title: string;
    };
    contractType?: any;
    startDate: Date;
    finalDate?: any;
    earlyWarningStartDate?: any;
    earlyWarningFinalDate?: any;
    type: number;
    salary: string;
    benefits?: any;
    hourValue?: any;
    juridicName?: any;
    juridicFantasyName?: any;
    juridicDocument?: any;
    movements: PersonContractMovement[];
    vacationSchedules: any[];
    documents: any[];
    numberOfHours: number;
    numberOfDays: number;
};

const PersonContractDetails = () => {
    const { palette } = useTheme();
    const { contractId, personId } = useParams();

    const { data: personContract } = useGetPersonContract<Response>({ contractId, personId, isDetails: true });

    const navigate = useNavigate();

    const handleClose = () => navigate(`/app/pessoas/${personId}/contratos`);

    const handleClickEdit = () => navigate(`/app/pessoas/${personId}/contratos/editar/${contractId}`);

    const handleClickReactivateContract = useCallback(() => {
        navigate(`/app/pessoas/${personId}/contratos/reativar/${contractId}`);
    }, [personId, contractId, navigate]);

    const handleClickCreateDocument = useCallback(() => {
        navigate(`/app/pessoas/${personId}/contratos/${contractId}/documentos/novo`);
    }, [personId, contractId, navigate]);

    const {
        contractStatus,
        startDate,
        finalDate,
        salary,
        position,
        type,
        juridicName,
        juridicFantasyName,
        juridicDocument,
        documents = [],
        movements = [],
        hourValue,
        numberOfHours,
        numberOfDays,
        benefits
    } = personContract || {};

    const status = useMemo(() => {
        if (!contractStatus) {
            return;
        }

        const label = _get(dictionary.content.personContractStatusTitle, PersonContractStatusTitle[contractStatus.id!], '-');

        const isEligibleToReactivate = [
            PersonContractStatusTitle.MaternityLeave,
            PersonContractStatusTitle.MedicalCertificate,
            PersonContractStatusTitle.Vacation,
            PersonContractStatusTitle.SicknessAllowance
        ].includes(contractStatus.id);

        if (!isEligibleToReactivate) {
            return label;
        }

        return (
            <div className="flex flex-col">
                <span className="mb-2">{label}</span>
                <Button onClick={handleClickReactivateContract} size="small" type="button" variant="contained" color="secondary" className="text-[0.6rem] py-[3px] px-[5px] mr-auto text-center">
                    Reativar contrato
                </Button>
            </div>
        );
    }, [handleClickReactivateContract, contractStatus]);

    const details: Option<any>[] = [
        {
            label: 'data inicial',
            value: formatDate(startDate)
        },
        {
            label: 'data final',
            value: formatDate(finalDate)
        },
        {
            label: 'status',
            value: status
        },
        {
            label: 'salário',
            value: formatMoney(Number(salary))
        },
        {
            label: 'Cargo',
            value: position?.title
        },
        {
            label: 'Senioridade',
            value: ''
        },
        {
            label: 'Turno',
            value: ''
        },
        {
            label: 'Tipo de contratação',
            value: _get(dictionary.content.contractTypes, PersonContractType[type!]?.toLocaleLowerCase(), '')
        },

        {
            label: 'Nome jurídico',
            value: juridicName
        },
        {
            label: 'Nome fantasia',
            value: juridicFantasyName
        },
        {
            label: 'CNPJ',
            value: juridicDocument
        },

        {
            label: 'Valor hora',
            value: formatMoney(hourValue)
        },
        {
            label: 'Número de horas',
            value: numberOfHours
        },
        {
            label: 'Número de dias',
            value: numberOfDays
        },
        {
            label: 'Benefícios',
            value: formatMoney(benefits)
        }
    ].map(({ value, label }) => ({ value: value || '-', label }));

    return (
        <Modal onClose={handleClose} contentClassnames="w-[1200px] sm:max-w-full" closeOnClickOutside={false} containerClassnames="!z-[999]">
            <IconButton
                className="w-[34px] h-[34px] absolute right-[60px] top-4"
                color="warning"
                size="large"
                sx={{ backgroundColor: palette.warning.main, '&:hover': { backgroundColor: palette.warning.dark } }}
                onClick={handleClickEdit}>
                <Icon name="ico-edit" width={13} height={13} color={palette.grey[100]} />
            </IconButton>
            <div className="py-5 px-7">
                <Text as="h3" variant="h4" className="text-heading mb-6 mr-auto">
                    Detalhes do contrato
                </Text>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-6">
                    {details.map(({ label, value }, i) => (
                        <div key={`contract_detail_${i}`} className="flex flex-col">
                            <Text variant="body.medium.sm" as="span" className="mb-2 uppercase" style={{ color: palette.grey[500] }}>
                                {label}
                            </Text>
                            <Text variant="body.medium.sm" as="span" className="mb-1" style={{ color: palette.heading }}>
                                {value}
                            </Text>
                        </div>
                    ))}
                </div>
                <div className="flex mb-6 flex-col">
                    <div className="flex items-center mb-3">
                        <Text as="h3" variant="h6" className="text-heading mr-3">
                            Documentos
                        </Text>
                        <IconButton
                            className="w-[30px] h-[30px]"
                            color="secondary"
                            size="large"
                            sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}
                            onClick={handleClickCreateDocument}>
                            <Icon name="ico-plus" width={10} height={10} color={palette.grey[100]} />
                        </IconButton>
                    </div>
                    <Documents actions={[Rule.Update, Rule.Delete]} items={documents} />
                </div>
                <div className="flex mb-6 flex-col">
                    <Text as="h3" variant="h6" className="text-heading mb-3">
                        Movimentações
                    </Text>
                    <PersonContractDetailsMovements items={movements} />
                </div>
            </div>
        </Modal>
    );
};

export default PersonContractDetails;
