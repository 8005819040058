import { useMutation, useQueryClient } from 'react-query';
import { createMeasurementPaymentKey } from './graphql';
import { MeasurementPaymentPayload } from 'types/models/provider';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import useToast from 'hooks/toast/use-toast';
import { getFinancialSetupKey } from '../operationals/graphql';
import { Modify } from 'types/general';

type Payload = Modify<MeasurementPaymentPayload, { value: number }>;

const useCreateMeasurementPayment = (providerFinancialSetupId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: Payload) => api.post(`/provider-financial-setups/${providerFinancialSetupId}/payments`, payload);

    return useMutation(createMeasurementPaymentKey(providerFinancialSetupId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getFinancialSetupKey(providerFinancialSetupId));

            navigate(-1);

            showToast('Pagamento criado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar o pagamento', 'danger');
        }
    });
};

export default useCreateMeasurementPayment;
