import { object, string, date, number, array, mixed } from 'yup';

import dictionary from 'utils/dictionary';

const { required } = dictionary.validation;

const transactionOutcomeSchema = (isServiceAndProviderRequired: boolean, isPersonRequired?: boolean) =>
    object({
        description: string().typeError('Apenas letras').required(required),
        value: object({
            floatValue: number(),
            formattedValue: string(),
            value: string()
        }).required(required),
        dueDate: date().typeError('Apenas data').required(required),
        classification: string().typeError(required).required(required),
        person: isPersonRequired ? string().typeError(dictionary.validation.string).required(required) : string().optional(),
        provider: isServiceAndProviderRequired ? string().typeError(dictionary.validation.string).required(required) : string().optional(),
        service: isServiceAndProviderRequired ? string().typeError(dictionary.validation.string).required(required) : string().optional(),
        observation: string().optional(),
        documents: array(
            object({
                file: mixed().required(dictionary.validation.required),
                name: string().required(dictionary.validation.required),
                classification: number().required(dictionary.validation.required)
            })
        )
    });

export default transactionOutcomeSchema;
