import { store } from 'store';
import { Creators as AuthActions } from 'store/auth';
import api from 'services/api';
import { router } from 'index';

type Response<T> = {
    data?: T | undefined;
};

const logout = () => {
    // Clear store
    store.dispatch(AuthActions.logout());
    // Remove token from axios header
    delete api.defaults.headers.common.Authorization;
    // Redirect to login page
    router.navigate('/');
};

const apiGraphQl = async <T>(query: string, variables?: any) => {
    const reduxState = store.getState();

    if (!reduxState.auth.isAuthenticated) {
        logout();
    }

    const queryName = query?.match(/(?<=\bquery\s)(\w+)/g);

    const { REACT_APP_GRAPHQL_API_URL, REACT_APP_GRAPHQL_API_URL_VERSION } = process.env;
    const endpoint = `${REACT_APP_GRAPHQL_API_URL! + REACT_APP_GRAPHQL_API_URL_VERSION!}/graphql?n=${queryName || ''}`;

    const options = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${reduxState.auth.token}`
        },
        method: 'POST'
    };

    const response = await fetch(endpoint, {
        ...options,
        body: JSON.stringify({ query, variables })
    });

    const bodyResponse = await response.json();

    if (!!bodyResponse?.errors?.length && (bodyResponse?.errors[0]?.extensions?.code === 'access-denied' || !!bodyResponse?.errors[0]?.message?.includes('authorization hook'))) {
        throw new Error();
    }

    return (bodyResponse as Response<T> | undefined)?.data as T;
};

export default apiGraphQl;
