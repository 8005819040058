// Dependencies
import { StylesConfig } from 'react-select';

// Helpers
import theme from 'settings/theme';
import hexToRgba from 'utils/hex-to-rgba';

const getBorderColor = (isFocused, hasError) => {
    if (hasError) {
        return theme.extend.colors.system.danger[500];
    }

    return isFocused ? theme.extend.colors.primary[200] : theme.extend.colors.base[300];
};

const selectStyles = (size: 'small' | 'medium', hasError: boolean): StylesConfig => {
    const isMedium = size === 'medium';

    return {
        indicatorSeparator: () => ({ display: 'none' }),
        indicatorsContainer: (base) => ({
            ...base,
            padding: 0
        }),
        control: (base, { isFocused }) => ({
            ...base,
            borderColor: getBorderColor(isFocused, hasError),
            borderRadius: '14px',
            boxShadow: 'none',
            cursor: 'pointer',
            padding: '4px 16px',
            flex: 1,
            minHeight: isMedium ? 48 : 40,
            transition: 'border-color .2s ease',
            ':hover': {
                borderColor: theme.extend.colors.base[400]
            }
        }),
        placeholder: (base) => ({
            ...base,
            color: theme.extend.colors.base[400],
            fontSize: 14,
            lineHeight: 1,
            margin: 0
        }),
        option: () => ({
            borderRadius: 14,
            color: theme.extend.colors.heading,
            cursor: 'pointer',
            fontSize: 14,
            padding: '8px 14px',
            transition: 'background .2s ease',
            ':hover': {
                backgroundColor: theme.extend.colors.base[200]
            }
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: theme.extend.colors.base[100],
            borderRadius: 14,
            boxShadow: 'unset',
            margin: 0,
            marginTop: '4px !important',
            filter: 'drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15))',
            zIndex: 10
        }),
        menuList: (base) => ({
            ...base,
            padding: 12
        }),
        valueContainer: (base) => ({
            ...base,
            padding: 0,
            marginRight: 8
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999
        }),
        noOptionsMessage: (base) => ({
            ...base,
            color: theme.extend.colors.base[400],
            fontSize: 14
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: hexToRgba(theme.extend.colors.primary[100], 0.3),
            color: theme.extend.colors.primary[500],
            borderRadius: 8,
            zIndex: 19999
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: theme.extend.colors.primary[500]
        }),
        multiValueRemove: (base) => ({
            ...base,
            borderRadius: '0 8px 8px 0'
        }),
        singleValue: (base) => ({
            ...base,
            color: theme.extend.colors.heading,
            fontSize: 14
        })
    };
};

export default selectStyles;
