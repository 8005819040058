import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import { array, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';

export const getPageQuery = (pageId?: string) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetPage',
            page_by_pk: {
                __args: { id: Number(pageId || 0) },
                key_page: true,
                name: true,
                permalink: true,
                system: true,
                title: true,
                controller: true,
                subpages: {
                    id: true,
                    page_id: true
                }
            }
        }
    });
};

export const createOrUpdatePageMutation = (pageId?: string) => {
    return jsonToGraphQLQuery(
        {
            mutation: {
                __name: 'CreateOrUpdatePage',
                __variables: {
                    ...(pageId
                        ? {
                              payload: 'page_set_input',
                              id: 'Int!'
                          }
                        : {
                              payload: 'page_insert_input!'
                          })
                },
                item: {
                    __aliasFor: pageId ? 'update_page_by_pk' : 'insert_page_one',
                    __args: {
                        ...(pageId
                            ? {
                                  pk_columns: { id: new VariableType('id') },
                                  _set: new VariableType('payload')
                              }
                            : {
                                  object: new VariableType('payload')
                              })
                    },
                    id: true
                }
            }
        },
        { pretty: true }
    );
};

export const updateSubPageMutation = jsonToGraphQLQuery(
    {
        mutation: {
            __name: 'UpdateSubPage',
            __variables: {
                payload: 'subpage_set_input',
                id: 'Int!'
            },
            where: {
                id: { _eq: new VariableType('id') }
            },
            item: {
                __aliasFor: 'update_subpage_by_pk',
                __args: {
                    pk_columns: { id: new VariableType('id') },
                    _set: new VariableType('payload')
                },
                id: true
            }
        }
    },
    { pretty: true }
);

export const menuGroupSchema = object({
    name: string().required(dictionary.validation.required),
    icon: string().required(dictionary.validation.required),
    subpages: array(number())
});

export const getMenuGroupQuery = (menuGroupId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetMenuGroup',
                menu_group_by_pk: {
                    __args: { id: Number(menuGroupId || 0) },
                    icon: true,
                    order_menu: true,
                    name: true,
                    menu_pages: {
                        page: {
                            id: true,
                            name: true
                        }
                    }
                }
            }
        },
        { pretty: true }
    );
};
