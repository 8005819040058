import { useQuery } from 'react-query';
import api from 'services/api';
import { MeasurementStats } from 'types/models/provider';
import { updateMeasurementKey } from './graphql';

const useGetMeasurementStats = (providerFinancialSetupId?: number, measurementId?: number) => {
    const fetcher = async () => api.get<MeasurementStats>(`/provider-financial-setups/${providerFinancialSetupId}/measurements/${measurementId}/stats`).then(({ data }) => data);

    return useQuery(updateMeasurementKey(measurementId), fetcher, { enabled: Boolean(measurementId) });
};

export default useGetMeasurementStats;
