import React, { useState } from 'react';
import Icon from 'components/core/icon';
import Menu, { MenuItem } from 'components/menu';
import useTheme from '@mui/material/styles/useTheme';
import Button from 'components/core/button';
import { ProjectTypeStatus } from 'types/models/project';

type ProjectSidebarActionsProps = {
    projectTypeStatus: ProjectTypeStatus;
    onSetUpdateProjectTypeStatus?: React.Dispatch<React.SetStateAction<ProjectTypeStatus | null>>;
};

const ProjectSidebarActions = ({ projectTypeStatus, onSetUpdateProjectTypeStatus }: ProjectSidebarActionsProps) => {
    const { palette } = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const content: MenuItem[] = [
        ...([ProjectTypeStatus.Waiting, ProjectTypeStatus.Started].includes(projectTypeStatus)
            ? [
                  {
                      label: 'Paralisar projeto',
                      icon: {
                          color: palette.warning[500],
                          name: 'ico-lock'
                      },
                      onClick: () => {
                          setAnchorEl(null);
                          onSetUpdateProjectTypeStatus?.(ProjectTypeStatus.Stopped);
                      }
                  },
                  {
                      label: 'Finalizar projeto',
                      icon: {
                          color: palette.success[500],
                          name: 'ico-check'
                      },
                      onClick: () => {
                          onSetUpdateProjectTypeStatus?.(ProjectTypeStatus.Done);
                          setAnchorEl(null);
                      }
                  },
                  {
                      label: 'Cancelar projeto',
                      icon: {
                          color: palette.error[500],
                          name: 'ico-close-circle'
                      },
                      onClick: () => {
                          onSetUpdateProjectTypeStatus?.(ProjectTypeStatus.Canceled);
                          setAnchorEl(null);
                      }
                  }
              ]
            : [
                  {
                      label: 'Reiniciar projeto',
                      icon: {
                          color: palette.success[500],
                          name: 'ico-refresh'
                      },
                      onClick: () => {
                          setAnchorEl(null);
                          onSetUpdateProjectTypeStatus?.(ProjectTypeStatus.Started);
                      }
                  }
              ])
    ];

    return (
        <>
            <Button type="button" variant="contained" color="primary" onClick={handleOpen} endIcon={<Icon name="ico-chevron-down" width={13} color="#fff" />}>
                Ações
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={setAnchorEl.bind(this, null)} content={content} />
        </>
    );
};

export default ProjectSidebarActions;
