// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Status } from 'types/general';
import apiGraphQl from 'services/graphql';
import { ProjectMeetingRecord } from 'types/models/project';
import project, { getProjectMeetingRecordsKey } from './graphql';
import { GraphqlPagination, GraphqlPaginationVariables } from 'types/graphql';

const useGetMeetingRecords = (variables: GraphqlPaginationVariables<ProjectMeetingRecord>, projectId?: number) => {
    const fetcher = async () => {
        const params = {
            limit: variables.limit,
            offset: variables.offset,
            orderBy: variables.orderBy,
            where: variables.where
        };

        return apiGraphQl<GraphqlPagination<Partial<ProjectMeetingRecord>>>(project.getProjectMeetingRecords, {
            ...params,
            where: {
                ...params.where,
                status: { _neq: Status.Deleted }
            }
        });
    };

    const queryParams = {
        ...variables,
        where: {
            project_id: { _eq: projectId }
        }
    };

    return useQuery(getProjectMeetingRecordsKey(queryParams, projectId), fetcher, { enabled: typeof variables?.where?.project_id?._eq === 'number' });
};

export default useGetMeetingRecords;
