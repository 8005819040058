import React from 'react';

import 'dayjs/locale/pt-br';

import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import utc from 'dayjs/plugin/utc';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import timezone from 'dayjs/plugin/timezone';
import { CacheProvider } from '@emotion/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { PersistGate } from 'redux-persist/es/integration/react';
import Error from 'pages/shared/error';
import ErrorBoundary from 'components/error';
import toast from 'settings/toast';
import routes from 'settings/routes';
import { persistor, store } from 'store';
import ReactQueryProvider from 'contexts/react-query';
import { materialTheme, MATERIAL_CACHE } from 'settings/theme/material';

import 'react-quill/dist/quill.snow.css';
import './index.css';

dayjs.extend(utc);
dayjs.locale('pt-br');
dayjs.extend(timezone);

export const router = createBrowserRouter(routes);

const App = () => {
    return (
        <React.StrictMode>
            <StyledEngineProvider injectFirst={true}>
                <CacheProvider value={MATERIAL_CACHE}>
                    <ThemeProvider theme={materialTheme}>
                        <ErrorBoundary component={<Error title="Ops!" subtitle="Tivemos um problema" />}>
                            <Provider store={store}>
                                <PersistGate persistor={persistor}>
                                    <ReactQueryProvider>
                                        <RouterProvider router={router} />
                                        <Toaster toastOptions={toast} position="bottom-right" />
                                    </ReactQueryProvider>
                                </PersistGate>
                            </Provider>
                        </ErrorBoundary>
                    </ThemeProvider>
                </CacheProvider>
            </StyledEngineProvider>
        </React.StrictMode>
    );
};

const rootElement = document.getElementById('root');

ReactDOM.render(App(), rootElement);
