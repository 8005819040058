import { useQuery } from 'react-query';

import { OutcomeProjectTransaction } from 'types/models/organization';
import operational, { getOutcomeTransactionsProject } from './graphql';

import apiGraphQl from 'services/graphql';
import { GraphqlPaginationVariables } from 'types/graphql';

type OutcomeGraphql = { project_id: string; transaction: OutcomeProjectTransaction };
const useGetProjectOutcomes = (projectTypeId?: string, params?: GraphqlPaginationVariables<OutcomeGraphql>) => {
    type Response = {
        items: {
            project_id: string;
            transaction: OutcomeProjectTransaction;
        }[];
        pagination: { aggregate: { count: number } };
    };

    const fetcher = async () => {
        const newParams = {
            limit: params?.limit,
            offset: params?.offset,
            orderBy: params?.orderBy,
            where: params?.where
        };

        const outcomeGraphqlResponse = await apiGraphQl<Response>(operational.getProjectsOutcomes, {
            ...newParams,
            where: {
                ...newParams.where,
                project_proposal_type_id: { _eq: projectTypeId }
            }
        });

        return outcomeGraphqlResponse;
    };

    return useQuery(getOutcomeTransactionsProject(projectTypeId, params), fetcher, { enabled: !!projectTypeId });
};

export default useGetProjectOutcomes;
