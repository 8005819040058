// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { ProjectProposalType } from 'types/models/project';
import project, { getProjectProposalTypeKey } from './graphql';

type Response = {
    project_proposal_type_by_pk: Partial<ProjectProposalType>;
};

const useGetProjectProposalType = (projectProposalTypeId?: number) => {
    const getProjectProposalType = async () => {
        return apiGraphQl<Response>(project.getProjectProposalType, { id: projectProposalTypeId }).then((data) => data.project_proposal_type_by_pk);
    };

    return useQuery<Partial<ProjectProposalType>>(getProjectProposalTypeKey(projectProposalTypeId), getProjectProposalType, { enabled: Boolean(projectProposalTypeId) });
};

export default useGetProjectProposalType;
