import Icon from 'components/core/icon';
import Text from 'components/core/text';
import React, { useState } from 'react';
import DatabookItemMenu from './databook-item-menu';
import { DatabookResponse } from 'services/queries/operationals/use-get-databooks';
import { DatabookFilesResponse } from '../types/types';
import { formattedOrderNumber } from './utils';

type DatabookItemsProps = {
    item: DatabookFilesResponse & DatabookResponse;
    iconName: string;
};

const DatabookItems = ({ item, iconName }: DatabookItemsProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onClose = () => setAnchorEl(null);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        return setAnchorEl(event.currentTarget);
    };

    return (
        <div className="mt-3 cursor-pointer relative">
            <div onClick={handleOpen} className="flex flex-col items-center hover:bg-[#F8F8F8] rounded-lg px-[40px] py-[20px] max-w-[250px] w-full">
                <Icon name={`ico-${iconName}`} width={96} height={87} color="#FCD297" />
                <Text className="text-heading  text-[0.9375rem] text-center">
                    {!!item.number && formattedOrderNumber(item.number) + ' -'} {item.title || item.file?.title}
                </Text>
            </div>
            <DatabookItemMenu itemRef={item} anchorEl={anchorEl} onClose={onClose} />
        </div>
    );
};

export default DatabookItems;
