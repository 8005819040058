import { DAY_HEIGHT, DAY_WIDTH } from '../../utils';
import { formatDate } from 'utils/date';

type PeopleMapPerMonthDaysProps = {
    days: Date[];
};

const Days = ({ days }: PeopleMapPerMonthDaysProps) => {
    return (
        <div className={`flex z-50 sticky top-0 bg-white divide-x divide-slate-200 border-b border-slate-200 font-medium text-[#6d7178]`} style={{ width: DAY_WIDTH * (days.length + 1) }}>
            {days.map((date) => {
                return (
                    <div key={`day_${date}`} style={{ width: DAY_WIDTH, height: DAY_HEIGHT + 10 }} className="flex flex-col items-center justify-center text-xs text-center flex-auto">
                        {formatDate(date, 'DD/MM')}
                        <span className="font-bold text-heading">{formatDate(date, 'ddd')}</span>
                    </div>
                );
            })}

            <div style={{ width: DAY_WIDTH, height: DAY_HEIGHT + 10 }} className="flex flex-col items-center justify-center text-xs text-center">
                <span className="font-bold text-heading">Total</span>
            </div>
        </div>
    );
};

export default Days;
