import Badge from 'components/core/badge';
import { MUIDataTableColumn } from 'mui-datatables';
import { formatDate, minutesToHours } from 'utils/date';
import { formatMoney } from 'utils/money';
import { typeManagementInformations } from 'utils/statics';

export const projectsReportColumns: MUIDataTableColumn[] = [
    {
        name: 'project.code',
        label: 'Contrato',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'project.title',
        label: 'Projeto',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'project.customer.title',
        label: 'Cliente',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'type.title',
        label: 'Disciplina',
        options: {
            customBodyRender: (value) => (!!value ? <Badge variant="info">{value}</Badge> : '-')
        }
    },
    {
        name: 'investment',
        label: 'Valor do contrato',
        options: {
            customBodyRender: (value = 0) => formatMoney(value)
        }
    },
    {
        name: 'report.indirectCosts',
        label: 'Custos indiretos',
        options: {
            customBodyRender: (value = 0) => formatMoney(value)
        }
    },
    {
        name: 'report.directCosts',
        label: 'Custos diretos',
        options: {
            customBodyRender: (value = 0) => formatMoney(value)
        }
    },
    {
        name: 'report.eac',
        label: 'EAC',
        options: {
            customBodyRender: (value = 0) => formatMoney(value)
        }
    },
    {
        name: 'report.saleMargin',
        label: 'Margem % venda',
        options: {
            customBodyRender: (value = 0) => `${value}%`
        }
    },
    {
        name: 'report.saleValue',
        label: 'Valor Margem',
        options: {
            customBodyRender: (value = 0) => formatMoney(value)
        }
    },
    {
        name: 'report.eacMargin',
        label: 'Margem % EAC',
        options: {
            customBodyRender: (value = 0) => `${value}%`
        }
    },
    {
        name: 'report.etc',
        label: 'Valor ETC',
        options: {
            customBodyRender: (value = 0) => formatMoney(value)
        }
    }
];

export const expensesReportColumns: MUIDataTableColumn[] = [
    {
        name: 'project.code',
        label: 'Contrato',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'project.title',
        label: 'Projeto',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'projectProposalType.type.title',
        label: 'Operacional',
        options: {
            customBodyRender: (value) => (!!value ? <Badge variant="info">{value}</Badge> : '-')
        }
    },
    {
        name: 'code',
        label: 'Código',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'description',
        label: 'Descrição',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'value',
        label: 'Valor',
        options: {
            customBodyRender: (value = 0) => formatMoney(value)
        }
    },
    {
        name: 'dueDate',
        label: 'Vencimento',
        options: {
            customBodyRender: (value) => formatDate(value)
        }
    },
    {
        name: 'payday',
        label: 'Pagamento',
        options: {
            customBodyRender: (value) => formatDate(value)
        }
    }
];

export const resourcesReportColumns: MUIDataTableColumn[] = [
    {
        name: 'projectType.project.code',
        label: 'Contrato',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'projectType.project.title',
        label: 'Projeto',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'person.title',
        label: 'Recurso',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'periods.0.contractStartDate',
        label: 'Início do contrato',
        options: {
            customBodyRender: (value) => formatDate(value)
        }
    },
    {
        name: 'periods.0.contractEndDate',
        label: 'Fim do contrato',
        options: {
            customBodyRender: (value) => formatDate(value)
        }
    },
    {
        name: 'periods.0.percentage',
        label: 'Percentual',
        options: {
            customBodyRender: (value = '-') => value
        }
    },
    {
        name: 'typeManagementInformation',
        label: 'Atribuição',
        options: {
            customBodyRender: (value) => {
                const position = typeManagementInformations.find((item) => item.value === value);

                return position?.label || '-';
            }
        }
    },
    {
        name: 'projectType.type.title',
        label: 'Operacional',
        options: {
            customBodyRender: (value) => (!!value ? <Badge variant="info">{value}</Badge> : '-')
        }
    },
    {
        name: 'predictedTime',
        label: 'Horas previstas',
        options: {
            customBodyRender: (value = 0) => minutesToHours(value, 'number')
        }
    },
    {
        name: 'accomplishedTime',
        label: 'Horas alocadas',
        options: {
            customBodyRender: (value = 0) => minutesToHours(value, 'number')
        }
    },
    {
        name: 'hourValue',
        label: 'Valor HH',
        options: {
            customBodyRender: (value = 0) => formatMoney(value)
        }
    }
];
