// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import { getPeopleDetailsKey, getPersonDetailsTabKey } from './graphql';
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Person } from 'types/models/person';

const usePersonMutation = (props: { id?: string; params: GraphqlPaginationVariables<Person> }) => {
    const { id, params } = props || {};

    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const isEdit = !!id;

    const request = async (payload: any) => {
        const apiService = isEdit ? api.patch : api.post;
        const endpoint = isEdit ? `/people/${id}` : `/people`;

        return apiService<any>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(request, {
        onSuccess: (data) => {
            navigate(`/app/pessoas/${data?.id}`);

            queryClient.invalidateQueries(getAllKey('person', params));

            if (isEdit) {
                queryClient.invalidateQueries(getPersonDetailsTabKey(id));
                queryClient.invalidateQueries(getPeopleDetailsKey(id));
            }

            showToast(`Pessoa ${isEdit ? 'atualizada' : 'criada'} com sucesso`, 'success');
        }
    });
};

export default usePersonMutation;
