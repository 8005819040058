import { Task } from 'types/models/task';
import { MONTH_WIDTH } from '../utils';
import BodyMonth from './month';
import Bar from './bar';
import { useCalendarContext } from '../context';

type Props = { tasks: Task[]; timelineId?: number };

const CalendarBody = ({ tasks = [], timelineId }: Props) => {
    const { headerTexts, timelineVariant } = useCalendarContext();

    return (
        <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden" style={{ minWidth: MONTH_WIDTH * headerTexts.length }}>
            <div className="flex-1 flex h-full relative" id="calendar-body">
                {headerTexts.map((item, index) => {
                    const { legend: year, title: month } = item;

                    return <BodyMonth item={item} timelineVariant={timelineVariant} key={[month, year, index, timelineVariant, 'bar'].join('-')}></BodyMonth>;
                })}

                {tasks?.map((task, index) => (
                    <Bar timelineId={timelineId} task={task} index={index} key={task.id} />
                ))}
            </div>
        </div>
    );
};

export default CalendarBody;
