import { useRef } from 'react';
import { DAY_HEIGHT, DAY_WIDTH } from '../utils';

const Days = () => {
    const uniqueId = useRef(Math.random());

    const days = ['seg', 'ter', 'qua', 'qui', 'sex'];

    return (
        <>
            {days.map((day) => (
                <div key={`day_${day}_${uniqueId.current}`} style={{ width: DAY_WIDTH, height: DAY_HEIGHT }} className="flex items-center justify-center text-sm">
                    {day}
                </div>
            ))}
        </>
    );
};

export default Days;
