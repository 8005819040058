import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import ExternalButton from 'components/buttons/external';
import Icon from 'components/core/icon';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ProjectTypeTerm, TermStatus } from 'types/models/project';
import Badge from 'components/core/badge';
import { ColorVariant } from 'types/colors';
import { formatDate } from 'utils/date';
import useGetTermPrintUrl from 'services/queries/terms/use-get-term-print-url';
import { useQueryClient } from 'react-query';
import { getProjectTypeTermKey, getProjectTypeTermsKey } from 'services/queries/projects/graphql';
import Spinner from 'components/core/spinner';
import { getAllKey } from 'services/queries/crud/graphql';
import classNames from 'classnames';

type Colors = {
    [key: string | number]: ColorVariant;
};

const secondaryButton = { classes: { child: 'text-secondary-100' } };

const statusesLabel = {
    [TermStatus.Waiting]: 'Aguardando impressão',
    [TermStatus.Created]: 'Termo gerado',
    [TermStatus.Finalized]: 'Termo finalizado'
};

const statusesColors: Colors = {
    [TermStatus.Waiting]: 'grey',
    [TermStatus.Created]: 'info',
    [TermStatus.Finalized]: 'success'
};

const TermViewPage = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { projectProposalTypeId, projectTypeTermId } = useParams();

    const term = useOutletContext<ProjectTypeTerm | undefined>();

    const { mutateAsync: getPrintUrl, isLoading: isLoadingPrintUrl } = useGetTermPrintUrl();

    const handleShowFile = (url: string) => () => window.open(url, '_blank');

    const descriptionClassNames = classNames('pb-4 mb-4', Boolean(term?.caveatDescription) || Boolean(term?.observations) ? 'border-b border-b-base-300' : '');

    const handlePrint = async () => {
        try {
            const url = await getPrintUrl({ projectProposalTypeId, projectTypeTermId });

            handleShowFile(url)();

            queryClient.invalidateQueries(getProjectTypeTermKey(projectTypeTermId));
            queryClient.invalidateQueries(getProjectTypeTermsKey(+projectProposalTypeId!));
            queryClient.invalidateQueries(getAllKey('project_type_term'));
        } catch (error) {
            console.log('handlePrint', error);
        }
    };

    return (
        <Modal
            headerLeft={
                <Text as="h4" variant="h4" className="text-heading">
                    Termo #{term?.number}
                </Text>
            }
            contentClassnames="w-[800px]"
            headerRight={
                <>
                    {term?.termStatus === TermStatus.Created && (
                        <Tooltip placement="left" title="Finalizar termo" disableFocusListener={true}>
                            <IconButton
                                className="bg-secondary-100 bg-opacity-20 hover:bg-secondary-100 hover:bg-opacity-40 p-[9px]"
                                TouchRippleProps={secondaryButton}
                                color="default"
                                onClick={() => navigate(`editar?termStatus=${term.termStatus}`, { replace: true })}>
                                <Icon name="ico-check-square" color={palette.secondary[400]} width={16} height={16} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {term?.termStatus !== TermStatus.Finalized && (
                        <Tooltip placement="top" title="Imprimir termo">
                            <IconButton disabled={isLoadingPrintUrl} TouchRippleProps={secondaryButton} className="hover:bg-secondary-100 hover:bg-opacity-20 ml-2" onClick={handlePrint}>
                                {isLoadingPrintUrl ? <Spinner fixed={false} size={16} /> : <Icon name="ico-printer" color={palette.secondary[500]} width={16} height={16} />}
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            }
            closeOnClickOutside={true}
            onClose={() => navigate(-1)}>
            <div className="py-6 px-3 sm:p-6 pt-0">
                {Boolean(term) ? (
                    <>
                        <div className="border-b border-b-base-300 pb-4 mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <div>
                                <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                    Criador
                                </Text>
                                <Text as="p" className="text-base-500 mb-2 break-words">
                                    {term?.person?.title || term?.person?.name || '-'}
                                </Text>
                            </div>
                            <div>
                                <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                    Tipo
                                </Text>
                                <Text as="p" className="text-base-500 mb-2 break-words">
                                    {term?.type.title}
                                </Text>
                            </div>
                            {Boolean(statusesLabel[term?.termStatus!]) && (
                                <div>
                                    <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                        Status
                                    </Text>
                                    <Badge variant={statusesColors[term?.termStatus!] || 'grey'}>{statusesLabel[term?.termStatus!]}</Badge>
                                </div>
                            )}
                        </div>
                        {Boolean(term?.fields.length) && (
                            <div className="border-b border-b-base-300 pb-4 mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                                {term?.fields.map((item) => {
                                    return (
                                        <div key={item.name}>
                                            <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                                {item.title}
                                            </Text>
                                            <Text as="p" className="text-base-500 mb-2 break-words">
                                                {item.type === 'date' ? formatDate(item.value) : item.value}
                                            </Text>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {(Boolean(term?.mainFile) || Boolean(term?.originalFile)) && (
                            <div className="border-b border-b-base-300 pb-4 mb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {Boolean(term?.mainFile) && (
                                    <div>
                                        <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                            Arquivo de finalização do termo
                                        </Text>
                                        <ExternalButton className="w-full" onClick={handleShowFile(term?.mainFile.url!)}>
                                            {term?.mainFile.title}
                                        </ExternalButton>
                                    </div>
                                )}
                                {Boolean(term?.originalFile) && (
                                    <div>
                                        <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                            Arquivo original
                                        </Text>
                                        <ExternalButton className="w-full" onClick={handleShowFile(term?.originalFile.url!)}>
                                            {term?.originalFile.title}
                                        </ExternalButton>
                                    </div>
                                )}
                            </div>
                        )}
                        {Boolean(term?.documents.length) && (
                            <div className="border-b border-b-base-300 pb-4 mb-4">
                                <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                    Arquivos
                                </Text>
                                <div>
                                    {term!.documents.map((item) => (
                                        <ExternalButton className="w-full" key={item.id} onClick={handleShowFile(item.file.url)}>
                                            {item.file.filename}
                                        </ExternalButton>
                                    ))}
                                </div>
                            </div>
                        )}
                        {Boolean(term?.description) && (
                            <div className={descriptionClassNames}>
                                <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                    Descrição
                                </Text>
                                <div className="rich-content" dangerouslySetInnerHTML={{ __html: term?.description! }} />
                            </div>
                        )}
                        {Boolean(term?.caveatDescription) && (
                            <div>
                                <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                    Ressalvas
                                </Text>
                                <div className="rich-content" dangerouslySetInnerHTML={{ __html: term?.caveatDescription! }} />
                            </div>
                        )}
                        {Boolean(term?.observations) && (
                            <div className="border-t border-t-base-300 pt-4 mt-4">
                                <Text as="label" variant="body.medium.2xs" className="text-heading block mb-2">
                                    Observações
                                </Text>
                                <div className="rich-content" dangerouslySetInnerHTML={{ __html: term?.observations! }} />
                            </div>
                        )}
                    </>
                ) : (
                    <Empty title="Termo não encontrado" />
                )}
            </div>
        </Modal>
    );
};

export default TermViewPage;
