// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { GraphqlPagination } from 'types/graphql';
import profile, { getProfilesOptionsKey } from './graphql';

const useGetProfilesOptions = (enabled: boolean) => {
    const getProjectMeasuresOptions = () => apiGraphQl<GraphqlPagination<Option>>(profile.getProfilesOptions).then((data) => data.items);

    return useQuery(getProfilesOptionsKey, getProjectMeasuresOptions, { enabled });
};

export default useGetProfilesOptions;
