import { RouteObject } from 'react-router-dom';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';
import FinancialExpensesPage from 'pages/private/financial/expenses';
import FinancialReceiptsPage from 'pages/private/financial/receipts';

const financial: RouteObject[] = [
    {
        path: 'financeiro/despesas',
        element: <Page Component={FinancialExpensesPage} code="financeiro-despesas" permissions={[Rule.List]} />
    },
    {
        path: 'financeiro/receitas',
        element: <Page Component={FinancialReceiptsPage} code="financeiro-receitas" permissions={[Rule.List]} />
    }
];

export default financial;
