import { useEffect, useMemo, useState } from 'react';

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';

import theme from 'settings/theme';

import { useShowApiErrors } from 'hooks/error/show-api-errors';

import { OutcomeProjectTransactionPayload } from 'types/models/organization';
import transactionOutcomeSchema from './utils/schema';
import DatePicker from 'components/core/datepicker';
import Select from 'components/core/form/select';
import useGetClassificationOptions from 'services/queries/operationals/use-get-classifications-options';
import useGetProviderOptions from 'services/queries/operationals/use-get-provider-options';
import { ClassificationType } from 'types/models/classification';
import CurrencyInput from 'components/core/form/currency';
import { OperationalDetailsPageContext } from '../../view';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import { classificationsQuery } from 'components/documents/utils/graphql';
import DocumentsFields from 'components/documents/fields';
import AddButton from 'components/buttons/add';
import RemoveButton from 'components/buttons/remove';
import { DOCUMENTS_INITIAL_STATE, INITIAL_FORM_STATE } from './utils/transaction-outcome';
import Editor from 'components/core/form/editor';

type CreateOrUpdateOutcomeProps = {
    title: string;
    isBuilding: boolean;
    isSubmitting: boolean;
    onSubmit: (data: OutcomeProjectTransactionPayload) => void;
    defaultValues?: any;
    error?: any;
};

const CreateOrUpdateOutcome = ({ isBuilding, isSubmitting, onSubmit, defaultValues, error, title }: CreateOrUpdateOutcomeProps) => {
    const [isThirdClassification, setIsThirdClassification] = useState(false);
    const [isResidentClassification, setIsResidentClassification] = useState(false);

    const navigate = useNavigate();
    const { data: documentsClassificationsOptions = [] } = useGetClassifications(classificationsQuery(true));
    const { data: classificationOptions } = useGetClassificationOptions(ClassificationType.FinancialClassification);

    const { data: servicesOptions } = useGetClassificationOptions(ClassificationType.ProviderServices, 'classification_id: { _is_null: true }');

    const { outcomeId } = useParams();

    const { data: providerOptions } = useGetProviderOptions(true);

    const methods = useForm<OutcomeProjectTransactionPayload>({
        mode: 'onSubmit',
        resolver: yupResolver(transactionOutcomeSchema(isThirdClassification, isResidentClassification)),
        defaultValues: INITIAL_FORM_STATE as any
    });

    const { formState, control, handleSubmit, reset, setError, setValue, resetField } = methods;

    const { fields, append, remove } = useFieldArray({ name: 'documents', control });

    const handleAddDocument = () => append(DOCUMENTS_INITIAL_STATE);
    const handleRemoveDocument = (index?: number) => () => remove(index);

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const peopleMap = useMemo(() => {
        return ctx.projectProposalType?.project_type_person_resources
            ?.filter((item) => !item.provider && !(item as any)?.manager)
            .map((item) => ({
                value: item?.person?.id,
                label: item?.person?.name
            }));
    }, [ctx]);

    useEffect(() => {
        if (defaultValues?.provider) {
            setIsThirdClassification(true);
        }

        if (defaultValues?.person) {
            setIsResidentClassification(true);
        }
    }, [defaultValues?.provider, defaultValues?.person]);
    const { showFormErrors } = useShowApiErrors();

    const handleChangeSelectClassification = (option: any, field: any) => {
        resetField('provider');
        resetField('service');
        resetField('person');
        if (option.label === 'Terceiros') {
            setIsThirdClassification(true);
            setIsResidentClassification(false);
        }

        if (option.label === 'Residentes') {
            setIsResidentClassification(true);
            setIsThirdClassification(false);
        }

        field.onChange(option.value);
    };

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!!defaultValues?.id) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleClose = () => navigate(-1);

    return (
        <Modal onClose={handleClose} contentClassnames="w-[800px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-3 sm:px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {title}
                    </Text>
                    <div className="mb-4">
                        <Controller name="description" control={control} render={({ field }) => <Input {...field} error={formState?.errors?.description?.message} label="Descrição" />} />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                        <Controller
                            name="value"
                            control={control}
                            render={({ field }) => (
                                <CurrencyInput
                                    value={field?.value.floatValue}
                                    label="Valor"
                                    decimalScale={2}
                                    autoComplete="nope"
                                    placeholder=""
                                    error={(formState.errors as any).value?.message}
                                    onValueChange={(values) => setValue('value', values)}
                                />
                            )}
                        />
                        <Controller
                            name="dueDate"
                            control={control}
                            render={({ field }) => {
                                return <DatePicker required={true} placeholderText="Selecione uma data" {...field} label="Data de vencimento" adjustDateOnChange={true} />;
                            }}
                        />
                        <Controller
                            name="payday"
                            control={control}
                            render={({ field }) => {
                                return <DatePicker required={false} placeholderText="Selecione uma data" {...field} label="Data de pagamento" />;
                            }}
                        />
                        <Controller
                            name="classification"
                            control={control}
                            render={({ field }) => {
                                const value = classificationOptions?.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={classificationOptions}
                                        label="Classificação"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.classification?.message}
                                        onChange={(option: any) => handleChangeSelectClassification(option, field)}
                                    />
                                );
                            }}
                        />
                        {isResidentClassification ? (
                            <Controller
                                name="person"
                                control={control}
                                render={({ field }) => {
                                    const value = peopleMap?.find((item) => item.value! === field.value);
                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={peopleMap}
                                            label="Pessoa"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.person?.message}
                                            onChange={(option: any) => handleChangeSelectClassification(option, field)}
                                        />
                                    );
                                }}
                            />
                        ) : null}
                        {isThirdClassification ? (
                            <>
                                <Controller
                                    name="service"
                                    control={control}
                                    render={({ field }) => {
                                        const value = servicesOptions?.find((item) => item.value === field.value) || null;

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={servicesOptions}
                                                label="Serviços"
                                                placeholder="Selecione uma opção"
                                                error={formState.errors.service?.message}
                                                onChange={(option: any) => field.onChange(option.value)}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    name="provider"
                                    control={control}
                                    render={({ field }) => {
                                        const value = providerOptions?.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={providerOptions}
                                                label="Fornecedor"
                                                placeholder="Selecione uma opção"
                                                error={formState.errors.provider?.message}
                                                onChange={(option: any) => field.onChange(option.value)}
                                            />
                                        );
                                    }}
                                />
                            </>
                        ) : null}

                        <Controller
                            control={control}
                            name="observation"
                            render={({ field }) => <Editor {...field} error={formState.errors?.observation?.message} label="Observações" parentClasses="col-span-3" />}
                        />
                    </div>
                    {!outcomeId && (
                        <>
                            {fields.map((field, index) => (
                                <div key={field.id} className="border border-base-300 rounded-[14px] py-4 px-2 sm:p-4 mb-4 group relative">
                                    <RemoveButton onClick={handleRemoveDocument(index)} />
                                    <DocumentsFields classifications={documentsClassificationsOptions} methods={methods} index={index} baseName="documents" />
                                </div>
                            ))}
                            <AddButton onClick={handleAddDocument} className="my-4">
                                Adicionar documento
                            </AddButton>
                        </>
                    )}

                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateOutcome;
