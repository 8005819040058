// Dependencies
import React, { createContext, useReducer } from 'react';

// Helpers
import * as Types from './types';

const INITIAL_STATE = {
    isReleaseTapModalOpen: false
};

const ProjectContext = createContext<Types.ProjectContextType>({
    state: INITIAL_STATE,
    dispatch: () => {}
});

const actions = new Map<Types.ProjectActions, keyof Types.ProjectState>([['SET_RELEASE_TAP_MODAL', 'isReleaseTapModalOpen']]);

const ProjectProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer((state: Types.ProjectState, stateAction: Types.ReducerAction) => {
        try {
            const action = actions.get(stateAction.type);

            if (!action) {
                throw new Error();
            }

            return { ...state, [action]: stateAction.payload };
        } catch (error) {
            console.log('error', error);
            return state;
        }
    }, INITIAL_STATE);

    const values: Types.ProjectContextType = { state, dispatch };

    return <ProjectContext.Provider value={values}>{children}</ProjectContext.Provider>;
};

export { ProjectContext, ProjectProvider };
