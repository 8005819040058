import Table from '@mui/material/Table/Table';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import Text from 'components/core/text';
import { formatMoney } from 'utils/money';
import Badge from 'components/core/badge';
import { Timeline } from 'types/models/timeline';
import classNames from 'classnames';

type TableHeaderDetailsProps = {
    report: Timeline['proposalType']['report'];
};

const TableHeaderDetails = ({ report }: TableHeaderDetailsProps) => {
    const executionBalanceClasses = classNames('!text-xs', (report?.resume?.execution?.budget?.value || 0) < 0 ? 'text-system-danger-500' : '');
    const managementBalanceClasses = classNames('!text-xs', (report?.resume?.management?.budget?.value || 0) < 0 ? 'text-system-danger-500' : '');
    const realizedValueClasses = classNames('!text-xs', (report?.resume?.execution?.realized?.value || 0) > (report?.resume?.execution?.contract?.value || 0) ? 'text-system-danger-500' : '');
    const realizedHourClasses = classNames('!text-xs', (report?.resume?.execution?.realized?.hour || 0) > (report?.resume?.execution?.contract?.hour || 0) ? 'text-system-danger-500' : '');

    return (
        <TableContainer className="h-full">
            <Table
                className="h-full"
                sx={{
                    '& tr:last-child td': { borderBottom: 0 }
                }}>
                <TableHead>
                    <TableRow className="text-secondary">
                        <TableCell className="text-secondary"> </TableCell>
                        <TableCell className="text-secondary-500 text-center text-xs">Contrato</TableCell>
                        <TableCell className="text-secondary-500 text-center text-xs">Planejado</TableCell>
                        <TableCell className="text-secondary-500 text-center text-xs">Realizado</TableCell>
                        <TableCell className="text-secondary-500 text-center text-xs">Saldo</TableCell>
                        <TableCell className="text-secondary-500 text-center text-xs">PREVISTO ETC</TableCell>
                        <TableCell className="text-secondary-500 text-center text-xs">PREVISÃO EAC</TableCell>
                        <TableCell className="text-secondary-500 text-center text-xs">Variação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className="text-xs">Produção</TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.execution?.contract?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.execution?.contract?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.execution?.planned?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.execution?.planned?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className={realizedValueClasses}>{formatMoney(report?.resume?.execution?.realized?.value || 0)}</Text>
                            </div>
                            <Text className={realizedHourClasses}>({report?.resume?.execution?.realized?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className={executionBalanceClasses}>{formatMoney(report?.resume?.execution?.budget?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.execution?.budget?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.execution?.etc?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.execution?.etc?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.execution?.eac?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.execution?.eac?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Badge className="mb-2 !text-xs" variant={(report?.resume?.execution?.variation?.value || 0) >= 0 ? 'success' : 'error'}>
                                    {report?.resume?.execution?.variation?.value || 0}%
                                </Badge>
                            </div>
                            <Badge variant={(report?.resume?.execution?.variation?.hour || 0) >= 0 ? 'success' : 'error'} className="!text-xs">
                                {report?.resume?.execution?.variation?.hour || 0}%
                            </Badge>
                        </TableCell>
                    </TableRow>
                    <TableRow className="!border-b-0">
                        <TableCell className="text-xs">Gestão</TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.management?.contract?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.management?.contract?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.management?.planned?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.management?.planned?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.management?.realized?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.management?.realized?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className={managementBalanceClasses}>{formatMoney(report?.resume?.management?.budget?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.management?.budget?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.management?.etc?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.management?.etc?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Text className="!text-xs">{formatMoney(report?.resume?.management?.eac?.value || 0)}</Text>
                            </div>
                            <Text className="!text-xs">({report?.resume?.management?.eac?.hour || 0}h)</Text>
                        </TableCell>
                        <TableCell align="center">
                            <div className="mb-2">
                                <Badge className="mb-2 !text-xs" variant={(report?.resume?.management?.variation?.value || 0) >= 0 ? 'success' : 'error'}>
                                    {report?.resume?.management?.variation?.value || 0}%
                                </Badge>
                            </div>
                            <Badge variant={(report?.resume?.management?.variation?.hour || 0) >= 0 ? 'success' : 'error'} className="!text-xs">
                                {report?.resume?.management?.variation?.hour || 0}%
                            </Badge>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableHeaderDetails;
