import Badge from 'components/core/badge';
import { ProjectTypeRouteSlug } from 'services/queries/operationals/use-get-operational';
import { ColorVariant } from 'types/colors';
import { ProjectTypeStatus } from 'types/models/project';

export const convertProjectTypeSlugInTitle = (projectTypeSlug?: ProjectTypeRouteSlug) => {
    return projectTypeSlug?.toLocaleLowerCase().replaceAll('-', ' ') || '';
};

export const projectTypeBadgeStatuses = new Map([
    [ProjectTypeStatus.Waiting, ['info', 'Aguardando']],
    [ProjectTypeStatus.Started, ['primary', 'Iniciado']],
    [ProjectTypeStatus.Done, ['success', 'Finalizado']],
    [ProjectTypeStatus.Stopped, ['warning', 'Paralisado']],
    [ProjectTypeStatus.Canceled, ['error', 'Cancelado']],
    [ProjectTypeStatus.Processing, ['grey', 'Processando']],
    [ProjectTypeStatus.InCreation, ['info', 'Em criação']]
]);

export const operationalTableSortByColumnName = {
    'project.code': 'pj.code',
    'project.customer.title': 'c.title',
    'project.title': 'pj.title',
    startDate: 'p.startDate',
    endDate: 'p.endDate',
    'project.footage': 'pj.footage',
    'manager.title': 'm.title',
    projectTypeStatus: 'p.projectTypeStatus',
    progress: 'p.progress'
};

export const generateProjectTypeStatusBadge = (projectTypeStatus?: ProjectTypeStatus) => {
    const [variant, value] = projectTypeBadgeStatuses.get(projectTypeStatus!) || [];

    if (!projectTypeStatus) {
        return;
    }

    return <Badge variant={variant as ColorVariant}>{value}</Badge>;
};
