import React from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import theme from 'settings/theme';
import Icon from 'components/core/icon';
import Button from 'components/core/button';

type Props = {
    onClose(): void;
    children: React.ReactNode;
    title?: string;
    onSubmit(event: React.FormEvent<HTMLFormElement>): void;
    onClear?(): void;
};

const ModalFilter = ({ onClose, title = 'Filtro', children, onSubmit, onClear }: Props) => {
    return (
        <div className="absolute overflow-auto top-[0px] right-0 h-full w-[100vw] sm:w-[400px] bg-white flex flex-col px-6 py-7 shadow-2xl z-[999]">
            <div className="flex items-center mb-6">
                <h3 className="text-2xl text-heading font-medium flex-1">{title}</h3>

                <IconButton className="bg-base-200" size="large" color="default" onClick={onClose}>
                    <Icon name="ico-close" color={theme.extend.colors.heading} width={10} height={10} />
                </IconButton>
            </div>

            <form onSubmit={onSubmit}>
                {children}

                <span className="block mb-6"></span>

                <div className="flex items-center">
                    <Button type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                        Filtrar
                    </Button>
                    {onClear && (
                        <Button type="button" color="primary" className="min-w-[100px] underline" variant="text" onClick={onClear}>
                            Limpar filtros
                        </Button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default ModalFilter;
