import { getDetailsKey } from '../crud/graphql';

export const createCustomerKey = () => ['customer', 'create'];
export const getAllCustomersOptionsKey = ['customer', 'all', 'options'];
export const getCustomerKey = (customerId?: string) => getDetailsKey('customer', customerId);
export const getCustomerSetupKey = (entity: string) => ['customer', 'setup', entity];
export const getAllCustomerContactsOptionsKey = (customerId?: number) => ['customer', 'contacts', 'all', 'options', customerId];

const queries = {
    getAll: `query GetCustomersOptions ($account_id: Int!) {
        items: customer(order_by: {title: asc}, where: {account_id: {_eq: $account_id}, status: {_neq: -1}}) {
            value: id
            label: title
        }
    }`,
    getAllCustomerContactsOptions: `query GetCustomerContactsOptions ($customer_id: Int!) {
        items: customer_contact(where: {customer_id: {_eq: $customer_id}}) {
            value: id
            person {
                name
            }
        }
    }`,
    createCustomer: `mutation CreateCustomer ($payload: customer_insert_input!) {
        item: insert_customer_one(object: $payload) {
            id
        }
    }`,
    getCustomer: (fields: string) => `query GetCustomer($id: Int!) {
        customer: customer_by_pk(id: $id) {
            ${fields}
        }
    }`,
    editCustomer: `mutation EditCustomer($id: Int!, $payload: customer_set_input!, $addressId: Int!, $addressPayload: address_set_input!) {
        item: update_customer_by_pk(pk_columns: {id: $id}, _set: $payload) {
          id
        }
        update_address_by_pk(pk_columns: {id: $addressId}, _set: $addressPayload) {
          id
        }
      }
      `,
    createContact: `mutation CreateCustomerContact($payload: customer_contact_insert_input!) {
        item: insert_customer_contact_one(object: $payload) {
          id
        }
      }
      `,
    editContact: `mutation EditCustomerContactPerson($id: Int!, $payload: person_set_input!) {
        item: update_person_by_pk(pk_columns: {id: $id}, _set: $payload) {
          id
        }
      }`
};

export default queries;
