import React, { useEffect, useMemo } from 'react';
import Modal from 'components/core/modal';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Text from 'components/core/text';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/core/button';
import Input from 'components/core/form/input';
import { getMenuGroupQuery, menuGroupSchema } from './utils';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Page } from 'types/models/page';
import useGetPages from 'services/queries/pages/use-get-pages';
import Select from 'components/core/form/select';
import AllIcons from 'components/core/icon/all';
import useCreateOrUpdateMenuGroup from 'services/queries/menus/use-create-or-update-menu-group';
import useGetMenuGroup from 'services/queries/menus/use-get-menu-group';

export type MenuGroupPayload = {
    name: string;
    icon: string;
    order: number;
    pages: number[];
};

const CreateOrUpdateMenuGroupPage = () => {
    const navigate = useNavigate();
    const { menuGroupId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<Page>>();

    const { data: pages = [] } = useGetPages();
    const { data: menuGroup } = useGetMenuGroup(getMenuGroupQuery(menuGroupId), menuGroupId);
    const { mutateAsync: createOrUpdateMenuGroup, isLoading: isSubmitting } = useCreateOrUpdateMenuGroup(params, menuGroupId);

    const { control, formState, handleSubmit, setValue, watch, reset } = useForm<MenuGroupPayload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(menuGroupSchema),
        defaultValues: {
            order: 0,
            name: '',
            icon: '',
            pages: []
        }
    });

    useEffect(() => {
        if (menuGroup) {
            reset({
                order: menuGroup.order_menu,
                icon: menuGroup.icon,
                name: menuGroup.name,
                pages: menuGroup.menu_pages?.map((item) => item.value) || []
            });
        }
    }, [menuGroup, reset]);

    const icon = watch('icon');

    const formattedPages = useMemo(() => {
        return pages.map((item) => ({
            value: item.id,
            label: item.name
        }));
    }, [pages]);

    const handleClose = () => navigate(-1);

    const submit = async (data: MenuGroupPayload) => {
        try {
            const payload = {
                ...data,
                order: Number(data.order),
                pages: data.pages.map((item, index) => ({
                    page: item,
                    order: index + 1
                }))
            };

            await createOrUpdateMenuGroup(payload as any);
        } catch (error) {
            console.log('submit', error);
        }
    };

    return (
        <Modal
            contentClassnames="w-[650px]"
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    {menuGroupId ? 'Atualizar' : 'Novo'} grupo de menu
                </Text>
            }
            closeOnClickOutside={false}
            onClose={handleClose}>
            <form className="px-3 sm:px-6 pb-6" onSubmit={handleSubmit(submit)}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                    <Controller name="name" control={control} render={({ field }) => <Input {...field} error={formState.errors.name?.message} label="Nome" />} />
                    <Controller name="order" control={control} render={({ field }) => <Input {...field} min={0} type="number" label="Ordem" />} />
                </div>
                <Controller
                    name="pages"
                    control={control}
                    render={({ field }) => {
                        const value = formattedPages.filter((item) => field.value.find((value) => value === item.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                isMulti={true}
                                options={formattedPages}
                                label="Páginas"
                                placeholder="Selecione uma opção"
                                error={(formState as any).errors.subpages?.message}
                                parentClassName="mb-6"
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                            />
                        );
                    }}
                />
                <div className="border border-base-300 rounded-[14px] p-4 mb-6">
                    <Text as="label" variant="body.regular.sm" className="block mb-2 text-base-500">
                        Ícone
                    </Text>
                    <AllIcons selected={icon} onSelectIcon={(name: string) => setValue('icon', name)} />
                </div>
                <div className="flex items-center">
                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                        Enviar
                    </Button>
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                        Cancelar
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default CreateOrUpdateMenuGroupPage;
