import { RouteObject } from 'react-router-dom';
import TasksPage from 'pages/private/tasks';
import TaskPage from 'pages/private/tasks/details';
import TaskProgressPage from 'pages/private/operationals/progress';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';

const tasks: RouteObject[] = [
    {
        path: 'tarefas',
        element: <Page Component={TasksPage} code="tarefas" permissions={[Rule.List]} />,
        children: [
            {
                path: ':taskId',
                element: <Page Component={TaskPage} withModal={true} code="tarefas" permissions={[Rule.Show]} />
            },
            {
                path: ':taskId/horas-gastas',
                element: <Page Component={TaskProgressPage} withModal={true} code="tarefas-horas-gastas" permissions={[Rule.Create]} />
            },
            {
                path: ':taskId/progresso',
                element: <Page Component={TaskProgressPage} withModal={true} code="tarefas-progresso" permissions={[Rule.Create]} />
            }
        ]
    }
];

export default tasks;
