import React, { useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { horizontalBarChartOptions } from './utils';
import classNames from 'classnames';

type HorizontalBarChartProps = {
    title?: string;
    colors: string[];
    datasets: ChartData<'bar', number[], string>['datasets'];
    labels: string[];
    className?: string;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HorizontalBarChart = ({ colors, datasets, labels, title, className }: HorizontalBarChartProps) => {
    const containerClasses = classNames('w-full max-h-96', className);

    const dataFormatted = useMemo<ChartData<'bar', number[], string>>(() => {
        return {
            labels,
            datasets: datasets.map((item, index) => ({
                ...item,
                backgroundColor: colors[index],
                barPercentage: 1,
                categoryPercentage: 0.7
            }))
        };
    }, [datasets, labels, colors]);

    return (
        <div className={containerClasses}>
            <Bar options={horizontalBarChartOptions(title)} data={dataFormatted} className="!max-w-full" updateMode="resize" />
        </div>
    );
};

export default HorizontalBarChart;
