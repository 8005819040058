// Dependencies
import { useQuery } from 'react-query';
import api from 'services/api';

// Helpers
import { ProjectTypeDocument } from 'types/models/project';
import { getProjectTypeDocumentsKey } from './graphql';

const useGetProjectTypeDocuments = (fields: string, projectProposalTypeId?: number, isTransactionOutcome?: boolean, outcomeId?: number) => {
    const fetcher = async () => api.get<ProjectTypeDocument[]>(`/project-types/${projectProposalTypeId}${isTransactionOutcome ? `/transactions/${outcomeId}` : ''}/documents`).then(({ data }) => data);

    return useQuery(getProjectTypeDocumentsKey(projectProposalTypeId), fetcher);
};

export default useGetProjectTypeDocuments;
