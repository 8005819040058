import { Status } from 'types/general';

export const createAssetKey = ['asset', 'create'];
export const getAllAssetsOptionsKey = ['asset', 'all', 'options'];
export const getAssetKey = (assetId?: string) => ['asset', assetId];
export const getAssetSetupKey = (entity: string) => ['asset', 'setup', entity];
export const getClassificationsByPkKey = (id?: string) => ['asset', 'classification', id];
export const editAssetKey = (assetId?: string) => ['asset', 'edit', assetId];

export const getAssetTypesOptionsKey = ['asset-types', 'options'];

const queries = {
    getAssetTypesOptions: `query {
        items: asset_type(where: {status: {_eq: ${Status.Active}}}) {
          value: id
          label: name
        }
    }`,
    createAsset: `mutation CreateAsset ($payload: asset_insert_input!) {
    items: insert_asset_one(object: $payload) {
        id
    }
  }`,
    getAssetById: `
    query GetAssetById($assetId: Int!) {
      items: asset_by_pk(id: $assetId){
        id,
        name,
        code,
        serial_number,
        account_id,
        classification_id,
        phone,
        branch_id,
        brand,
        value,
        insurance_value,
        label,
        model,
        asset_status,
        observations,
        modality,
        quantity,
        classification_department_id,
        classification {
          name,
        },
        branch {
            name
        }
      }
    }
  `,
    editAssetBy: `mutation EditAsset($id: Int!, $payload: asset_set_input!){
    items: update_asset_by_pk(pk_columns: {id: $id}, _set: $payload) {
      id
    }
  }`,
    createAssetLeasing: `mutation CreateAssetLeasing ($payload: asset_leasing_insert_input!) {
    items: insert_asset_leasing_one(object: $payload) {
        id
    }
  }`
};

export default queries;
