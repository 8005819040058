import Days from '../days';
import { Week } from '../types';
import { DAY_HEIGHT, WEEK_WIDTH } from '../utils';
import dayjs from 'dayjs';

type Props = {
    items: Week[];
};

const Weeks = ({ items }: Props) => {
    return (
        <div className="flex flex-col sticky top-0 bg-white z-50 " style={{ width: WEEK_WIDTH * items.length }}>
            <div className="flex divide-x divide-slate-200 border-b border-slate-200">
                {items.map(({ startDate, endDate }, i) => {
                    const startDay = dayjs(startDate).format('DD/MM');
                    const endDay = dayjs(endDate).format('DD/MM');

                    return (
                        <div key={`semana_${i}`} style={{ width: WEEK_WIDTH, height: DAY_HEIGHT }} className="flex items-center justify-center font-medium text-heading uppercase">
                            {startDay} a {endDay}
                        </div>
                    );
                })}
            </div>

            <div className="flex divide-x divide-slate-200 border-b border-slate-200 font-medium text-[#6d7178]">
                {items.map((_, i) => (
                    <Days key={`days_${i}`} />
                ))}
            </div>
        </div>
    );
};

export default Weeks;
