// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import queries, { getAllKey } from './graphql';

export type PagesResponse = {
    items: Array<{
        id: number;
        name: string;
        rules: Array<{
            rule: {
                id: number;
                name: string;
            };
        }>;
    }>;
};

const useGetPages = () => {
    const getAllPages = async () => apiGraphQl<PagesResponse>(queries.getAll).then(({ items }) => items);

    return useQuery(getAllKey(), getAllPages);
};

export default useGetPages;
