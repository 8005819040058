import React from 'react';

// Dependencies
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';

// Components
import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';

// Helpers
import hexToRgba from 'utils/hex-to-rgba';

type PhonesProps = {
    accessor?: string;
    index: number;
    className?: string;
} & UseFormReturn<any, any>;

export const defaultPhone = {
    ddd: '',
    number: ''
};

const Phones = ({ accessor = 'external', control, formState, index, className }: PhonesProps) => {
    const { palette } = useTheme();

    const { fields, append, remove } = useFieldArray({ name: `${accessor}.${index}.phones`, control });

    const handleAddPhone = () => append(defaultPhone);

    const handleDeleteUser = (index: number) => () => remove(index);

    return (
        <div className={className}>
            <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                Telefone(s)
            </Text>
            {Boolean(fields.length) ? (
                fields.map((item, phoneIndex) => {
                    const dddError = formState.errors?.[accessor]?.[index]?.phones?.[phoneIndex]?.ddd?.message;

                    return (
                        <div key={item.id} className="relative mb-2">
                            {Boolean(phoneIndex) && (
                                <div className="absolute right-[10px] top-[50%] -mt-[18px]">
                                    <IconButton
                                        size="large"
                                        type="button"
                                        color="error"
                                        sx={{ backgroundColor: palette.grey[100], '&:hover': { backgroundColor: hexToRgba(palette.error[100], 0.5) } }}
                                        onClick={handleDeleteUser(phoneIndex)}>
                                        <Icon name="ico-trash" width={12} height={12} color={palette.error[500]} />
                                    </IconButton>
                                </div>
                            )}
                            <Controller
                                name={`${accessor}.${index}.phones.${phoneIndex}.number`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Input
                                            {...field}
                                            autoFocus={false}
                                            error={formState.errors?.[accessor]?.[index]?.phones?.[phoneIndex]?.number?.message}
                                            placeholder="Ex: 99999-9999"
                                            mask={String(field?.value)?.charAt(0) === '9' ? '99999-9999' : '9999-9999'}
                                            left={
                                                <Controller
                                                    name={`${accessor}.${index}.phones.${phoneIndex}.ddd`}
                                                    control={control}
                                                    render={({ field: ddd }) => (
                                                        <Input
                                                            {...ddd}
                                                            autoFocus={true}
                                                            mask="99"
                                                            error={dddError}
                                                            placeholder="(99)"
                                                            className={`bg-transparent border-r ${Boolean(dddError) && 'border border-solid border-system-danger-500 my-[1px] rounded-l-[14px]'}`}
                                                            inpuWrapperClasses="border-0 border-r border-r-base-300 rounded-none"
                                                            parentClassName="w-[65px]"
                                                            showErrorMessage={false}
                                                        />
                                                    )}
                                                />
                                            }
                                            leftClasses="bg-base-200"
                                        />
                                    );
                                }}
                            />
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhum telefone adicionado até o momento" />
            )}
            <div className="py-2 -mt-2">
                <AddButton onClick={handleAddPhone}>Mais telefones</AddButton>
            </div>
        </div>
    );
};

export default Phones;
