import React from 'react';

// Dependencies
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';

// Components
import Phones from './phones';
import Empty from 'components/empty';
import Text from 'components/core/text';
import Switch from 'components/core/switch';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';
import RemoveButton from 'components/buttons/remove';

// Helpers
import { ParticipantsPayload } from './participants';

const defaultUser = {
    email: '',
    name: '',
    phones: [],
    company: '',
    jobPosition: '',
    validationRequired: true
};

const External = (props: UseFormReturn<ParticipantsPayload, any>) => {
    const { control, formState, setValue, watch } = props;

    const { fields, append, remove } = useFieldArray({ name: 'external', control });

    const handleAddUser = () => append(defaultUser);

    const handleDeleteUser = (index: number) => () => remove(index);

    const handleValidationField = (index: number) => (value: boolean) => setValue(`external.${index}.validationRequired`, value);

    return (
        <div className="border border-base-300 !rounded-2xl p-4 pb-0">
            <Text as="h6" variant="h6" className="text-heading mb-3">
                Pessoas externas
            </Text>
            {Boolean(fields.length) ? (
                fields.map((item, index) => {
                    const validationRequired = watch(`external.${index}.validationRequired`);

                    return (
                        <div className="border border-base-300 p-4 pb-0 rounded-2xl mb-2 group relative" key={item.id}>
                            <RemoveButton onClick={handleDeleteUser(index)} />
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                                <Controller
                                    name={`external.${index}.name`}
                                    control={control}
                                    render={({ field }) => <Input {...field} autoFocus={false} error={formState.errors.external?.[index]?.name?.message} label="Nome" />}
                                />
                                <Controller
                                    name={`external.${index}.email`}
                                    control={control}
                                    render={({ field }) => <Input {...field} type="email" error={formState.errors.external?.[index]?.email?.message} label="E-mail" />}
                                />
                                <div className="w-full">
                                    <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                                        Necessário validação?
                                    </Text>
                                    <Switch value={validationRequired} onChange={handleValidationField(index)} />
                                </div>
                                <Controller
                                    name={`external.${index}.jobPosition`}
                                    control={control}
                                    render={({ field }) => <Input {...field} placeholder="Digite o nome do cargo" error={formState.errors.external?.[index]?.jobPosition?.message} label="Cargo" />}
                                />
                                <Controller
                                    name={`external.${index}.company`}
                                    control={control}
                                    render={({ field }) => <Input {...field} placeholder="Digite o nome da empresa" error={formState.errors.external?.[index]?.company?.message} label="Empresa" />}
                                />
                            </div>
                            <Phones index={index} {...props} />
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhuma pessoa adicionada até o momento" className="mb-2" />
            )}
            <div className="py-2 -mt-2">
                <AddButton onClick={handleAddUser}>Mais usuários</AddButton>
            </div>
        </div>
    );
};

export default External;
