import React from 'react';

// Dependencies
import IconButton from '@mui/material/IconButton/IconButton';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

// Components
import Text from '../text';
import Icon from '../icon';

// Helpers
import theme from 'settings/theme';
import { formatDate } from 'utils/date';

const DatePickerHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }: ReactDatePickerCustomHeaderProps) => {
    return (
        <div className="flex items-center">
            <IconButton size="large" disabled={prevMonthButtonDisabled} color="default" className="bg-base-200" onClick={decreaseMonth}>
                <Icon name="ico-chevron-left" color={prevMonthButtonDisabled ? theme.extend.colors.base[700] : theme.extend.colors.heading} width={10} height={10} />
            </IconButton>
            <div className="flex-1 px-2">
                <Text as="span" variant="h5" className="capitalize text-heading">
                    {formatDate(date, 'MMMM')}
                </Text>{' '}
                <Text as="span" variant="h5" className="text-base-500">
                    {formatDate(date, 'YYYY')}
                </Text>
            </div>
            <IconButton size="large" disabled={nextMonthButtonDisabled} color="default" className="bg-base-200" onClick={increaseMonth}>
                <Icon name="ico-chevron-right" color={nextMonthButtonDisabled ? theme.extend.colors.base[700] : theme.extend.colors.heading} width={10} height={10} />
            </IconButton>
        </div>
    );
};

export default DatePickerHeader;
