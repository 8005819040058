import { useQuery } from 'react-query';
import api from 'services/api';
import { getProjectTransactionsReportKey } from './graphql';
import { ReportResume } from 'types/models/timeline';

export type ReportWithoutForecast = {
    [K in Exclude<keyof ReportResume, 'forecast'>]: number;
};

type ProjectTransactionsReportResponse = {
    all: ReportWithoutForecast;
    others: ReportWithoutForecast;
};

const useGetProjectTransactionsReport = (projectProposalTypeId?: string) => {
    const endpoint = `/project-types/${projectProposalTypeId}/transactions/report`;
    const request = async () => api.get<ProjectTransactionsReportResponse>(endpoint).then(({ data }) => data);

    return useQuery(getProjectTransactionsReportKey(projectProposalTypeId), request, { enabled: !!projectProposalTypeId });
};

export default useGetProjectTransactionsReport;
