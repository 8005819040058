import useTheme from '@mui/material/styles/useTheme';
import Card from 'components/core/card';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import { DRECardProps } from './simple-card';
import { Modify } from 'types/general';
import classNames from 'classnames';

type ComplexCardData = {
    data: {
        firstData: {
            value: string | number;
            valueClassName?: string;
        };
        secondData: {
            value: string | number;
            valueClassName?: string;
        };
    };
};

type ComplexCardProps = Modify<DRECardProps, ComplexCardData>;

const descriptionClasses = 'block text-base-500 mb-2 font-normal';

const ComplexCard = ({ isLoading, data, title }: ComplexCardProps) => {
    const firstValueClasses = classNames('font-bold text-heading text-lg', data.firstData.valueClassName);
    const secondValueClasses = classNames('font-bold text-heading text-lg', data.secondData.valueClassName);

    const { palette } = useTheme();

    return (
        <Card className="py-4 px-4 shadow-lg rounded-2xl border border-base-200">
            <div className="flex flex-col h-full justify-between">
                <Text className="text-heading font-semibold text-center sm:text-left !text-base mb-2">{title}</Text>
                {isLoading ? (
                    <div className="p-4">
                        <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                    </div>
                ) : (
                    <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start gap-2 mt-2 w-full">
                        <div className="flex flex-col items-center sm:items-start">
                            <Text as="span" className={firstValueClasses}>
                                {data.firstData.value}
                            </Text>
                            <Text className={descriptionClasses}>VENDA</Text>
                        </div>
                        <div className="flex flex-col items-center sm:items-start">
                            <Text as="span" className={secondValueClasses}>
                                {data.secondData.value}
                            </Text>
                            <Text className={descriptionClasses}>EAC</Text>
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default ComplexCard;
