import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { generateCommercialBudgetVersionsQuery, getBudgetVersionsKeys } from './keys';
import apiGraphQl from 'services/graphql';
import { CommercialBudgetVersionDetails, UpdateBudgetPayload } from 'types/models/commercial';
import { SnakeCase } from 'types/utils';
import { Modify, Status } from 'types/general';
import { BaseModel } from 'types/models/base';

type PickedCommercialBudgetVersionsItem = Pick<
    CommercialBudgetVersionDetails,
    | 'version'
    | 'contractValue'
    | 'percentageMargin'
    | 'indirectCosts'
    | 'directCosts'
    | 'percentageCommercialExpenses'
    | 'percentageCostAdministrative'
    | 'percentageCostTax'
    | 'percentageFinancialExpenses'
    | 'projectType'
    | 'active'
    | 'deadline'
    | 'footage'
    | 'margin'
>;

export type CommercialBudgetVersionsResponse = {
    items: Modify<
        SnakeCase<PickedCommercialBudgetVersionsItem>,
        {
            budget_version: number;
            budget_id: number;
            status: Status;
        } & Pick<UpdateBudgetPayload, 'footage'> &
            Pick<BaseModel, 'id'>
    >[];
};

const useGetCommercialBudgetVersions = (budgetId?: string, projectTypeId?: string, isVersioning = false, isWithFalsyVersions = false) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const request = async () => {
        const { items } = await apiGraphQl<CommercialBudgetVersionsResponse>(generateCommercialBudgetVersionsQuery(budgetId, projectTypeId, isWithFalsyVersions));

        return items;
    };
    return useQuery(getBudgetVersionsKeys(budgetId, projectTypeId), request, {
        enabled: !!budgetId || isVersioning,
        onError: () => {
            navigate(-1);

            showToast('Ocorreu um problema ao buscar os dados dessa FP! Por favor, tente novamente.', 'danger');
        }
    });
};

export default useGetCommercialBudgetVersions;
