// Dependencies
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { getPersonDocumentKey } from '../people/graphql';
import { getPersonContractKey } from '../person-contract/keys';

const useDeletePersonDocument = (props: { personId?: string; contractId?: string }) => {
    const { personId, contractId } = props || {};

    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (documentId: number) => {
        queryClient.invalidateQueries(getPersonDocumentKey(String(documentId)));

        return api.delete(`/people/${personId}/documents/${documentId}`).then(({ data }) => data);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getPersonContractKey(contractId, true));

            showToast(`Documento apagado com sucesso.`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao apagar o documento.`, 'danger');
        }
    });
};

export default useDeletePersonDocument;
