import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import { useParams } from 'react-router-dom';
import { CrudPageProps } from 'types/graphql';
import ListPage from '../crud/list';
import { ClassificationType } from 'types/models/classification';
import Empty from 'components/empty';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import TableCell from '@mui/material/TableCell/TableCell';
import { administrativeAssets } from 'settings/routes/entities/utils/administrative';

const ALLOWED_CLASSIFICATIONS = [ClassificationType.AssetTechnology, ClassificationType.AssetFurniture];

const AssetPage = ({ page }: WithPagePermissionsProps) => {
    const { classificationTypeId } = useParams();

    if (!ALLOWED_CLASSIFICATIONS.includes(+classificationTypeId!)) {
        return <Empty title="Classificação de ativo não permitida!" />;
    }

    const [list]: CrudPageProps[] = [
        {
            graphql: {
                table: 'asset',
                excludedSearchColumnsName: ['asset_status'],
                columns: [
                    {
                        name: 'id',
                        options: { display: 'excluded' }
                    },
                    ...(administrativeAssets[classificationTypeId!]?.columns || []),
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                        }
                    }
                ],
                where: {
                    classification: { type: { _eq: classificationTypeId! } }
                },
                orderBy: {
                    name: 'asc'
                }
            },
            title: administrativeAssets[classificationTypeId!]?.title || ''
        }
    ];

    useBreadcrumb(administrativeAssets[classificationTypeId!]?.title || 'Ativo');

    return <ListPage page={page} {...list} />;
};

export default AssetPage;
