import { People } from '../types';
import { DAY_HEIGHT, PERSON_HEIGHT } from '../utils';
import Tooltip from '@mui/material/Tooltip/Tooltip';

type Props = {
    items: People[];
};

const Team = ({ items = [] }: Props) => {
    return (
        <div className="w-[150px] sm:w-[300px] border-r border-slate-200 divide-y divide-slate-200">
            <div style={{ height: DAY_HEIGHT * 2 + 1 }} className="uppercase text-heading flex items-center justify-center font-medium">
                EQUIPE
            </div>

            <div className="divide-y divide-slate-200">
                {items.map(({ name, agenda }, i) => (
                    <div key={`person_${name}_${i}`} style={{ height: (agenda.length || 1) * PERSON_HEIGHT }} className="flex items-center font-medium  px-4">
                        <Tooltip placement="top" title={name} disableFocusListener={true}>
                            <span className="text-ellipsis overflow-hidden truncate text-[#6d7178] text-xs">{name}</span>
                        </Tooltip>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;
