import React, { useContext, useMemo } from 'react';

// Dependencies
import useTheme from '@mui/material/styles/useTheme';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

// Components
import Files from './files';
import Details from './details';
import Subjects from './subjects';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Participants from './participants';
import Button from 'components/core/button';

// Helpers
import { MeetingSteps } from 'contexts/meeting-record/types';
import { MeetingRecordContext } from 'contexts/meeting-record';
import usePublishMeetingRecord from 'services/queries/projects/use-publish-meeting-record';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';

const Publish = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { meetingRecordId, projectId: projectIdParam } = useParams();

    const ctx = useOutletContext<OperationalDetailsPageContext | undefined>();

    const projectId = useMemo(() => Number(projectIdParam) || ctx?.project?.id, [projectIdParam, ctx?.project?.id]);

    const { state, changeStep } = useContext(MeetingRecordContext);

    const { mutateAsync: publishMeetingRecord, isLoading: isSubmitting } = usePublishMeetingRecord(meetingRecordId, projectId);

    const handleBack = () => changeStep(state.currentStep.slug, undefined, MeetingSteps.Participants);

    const handleSubmit = async () => {
        try {
            await publishMeetingRecord();
        } catch (error) {
            console.log('error', error);
        }
    };

    const participants = useMemo(
        () => ({
            internal: state.content.participants.internal
                .filter((item) => item.person)
                .map((item) => ({
                    validationRequired: item.validationRequired,
                    name: item.person?.label
                })),
            external: state.content.participants.external.filter((item) => item.email)
        }),
        [state.content.participants]
    );

    const handleFinish = () => navigate(-1);

    return (
        <>
            <div className="p-[30px]">
                <Text as="h3" variant="h4" className="text-heading text-xl mb-5">
                    Resumo
                </Text>
                <Details withTitle={true} title={state.content.meeting.title} date={state.content.meeting.dateFormatted} editors={state.content.meeting.editors.map((item) => item.label)} />
                <Subjects withTitle={true} subjects={state.content.subjects} />
                <Files withTitle={true} files={state.content.files.map((item) => ({ filename: item.filename, url: item.url }))} />
                <Participants withTitle={true} {...participants} />
            </div>
            <div className="border-t p-2 flex justify-between">
                <Button startIcon={<Icon name="ico-arrow-left" width={18} color={palette.grey[700]} />} type="button" variant="text" color="inherit" className="min-w-[100px]" onClick={handleBack}>
                    Voltar
                </Button>
                <div className="flex flex-wrap">
                    <Button disabled={isSubmitting} variant="text" color="info" className="min-w-[100px]" onClick={handleFinish}>
                        Salvar como rascunho
                    </Button>
                    <Button
                        disabled={isSubmitting}
                        endIcon={<Icon name="ico-arrow-right" width={18} color={palette.secondary[500]} />}
                        variant="text"
                        color="secondary"
                        className="min-w-[100px]"
                        onClick={handleSubmit}>
                        Salvar e publicar
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Publish;
