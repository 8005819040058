import React from 'react';

// Dependencies
import classNames from 'classnames';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';

// Components
import Empty from 'components/empty';
import Text from 'components/core/text';
import ExternalButton from 'components/buttons/external';
import hexToRgba from 'utils/hex-to-rgba';
import useTheme from '@mui/material/styles/useTheme';

type FileProps = {
    files: Array<
        Partial<{
            filename: string;
            url: string;
            originalName?: string;
        }>
    >;
    className?: string;
    withTitle?: boolean;
    withTableHead?: boolean;
};

const Files = ({ className, files, withTitle, withTableHead = true }: FileProps) => {
    const { palette } = useTheme();
    const containerClasses = classNames('border border-base-300 p-4 rounded-2xl mb-2', className);

    return (
        <div className={containerClasses}>
            {withTitle && (
                <Text as="h6" variant="h6" className="text-heading mb-2">
                    Arquivos
                </Text>
            )}
            <TableContainer>
                <Table>
                    {withTableHead && (
                        <TableHead>
                            <TableRow>
                                <TableCell classes={{ root: 'py-3' }}>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Nome do arquivo
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'py-3' }}>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Ação
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {Boolean(files.length) ? (
                            files.map((item, index) => (
                                <TableRow sx={{ td: { border: 0 }, '&:hover': { backgroundColor: hexToRgba(palette.grey[200]) } }} key={index}>
                                    <TableCell classes={{ root: 'py-3' }}>
                                        <Text as="span" variant="body.regular.sm" className="text-base-500">
                                            {item.originalName || item.filename}
                                        </Text>
                                    </TableCell>
                                    <TableCell classes={{ root: 'py-3' }}>
                                        <ExternalButton href={item.url}>Visualizar</ExternalButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={2} classes={{ root: 'pl-0' }}>
                                    <Empty title="Nenhum arquivo encontrado" />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Files;
