// Helpers
import { Status } from 'types/general';
import { ProjectManagementInformation, ProjectSignpost } from './project';

export type Signpost = {
    id: number;
    created_at: string;
    updated_at: string;
    status: Status;
    signpost_class_id: number;
    title: string;
    url: string;
    order_signpost: number;
    with_type: boolean;
    with_dimensions: boolean;
    signpost_class?: Partial<SignpostClass>;
    project_signposts: Partial<ProjectSignpost>;
};

export type SignpostClass = {
    id: number;
    created_at: string;
    updated_at: string;
    status: Status;
    title: string;
    slug: string;
    project_management_informations?: Partial<ProjectManagementInformation>[];
    signposts?: Partial<Signpost>[];
};

export type SignpostPayload = {
    dimension: number;
    project_id: number;
    quantity: string;
    signpost_id: number;
    status: Status;
    type: SignpostType;
};

export enum SignpostType {
    Sticker = 1,
    Canvas,
    Metallic
}

export enum SignpostDimension {
    Small = 1,
    Medium,
    Large
}
