import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { Page } from 'types/models/page';
import { getPageKey } from './graphql';

const useGetPage = (query: string, pageId?: string) => {
    const fetcher = async () => apiGraphQl<{ page_by_pk: Partial<Page> }>(query).then(({ page_by_pk }) => page_by_pk);

    return useQuery(getPageKey(pageId), fetcher, { enabled: Boolean(pageId) });
};

export default useGetPage;
