import React, { useMemo } from 'react';
import Text from 'components/core/text';
import { MeasurementStats, NewMeasurementPayload, ProviderFinancialMeasurement, ProviderFinancialPayment, ProviderFinancialPaymentType } from 'types/models/provider';
import { formatMoney } from 'utils/money';
import DatePicker from 'components/core/datepicker';
import { CamelCase } from 'types/utils';
import { useFormContext } from 'react-hook-form';
import { formatDate } from 'utils/date';
import dayjs from 'dayjs';

type NewMeasurementSidebarProps = {
    payments: CamelCase<ProviderFinancialPayment>[];
    measurement?: Partial<ProviderFinancialMeasurement>;
    dateHook: [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>];
    stats?: MeasurementStats;
};

const MAX = 100;

const NewMeasurementSidebar = ({ stats, payments, measurement, dateHook }: NewMeasurementSidebarProps) => {
    const [date, setDate] = dateHook;

    const { watch, setValue } = useFormContext<NewMeasurementPayload>();

    const measurementResidue = watch('measurementResidue');

    const total = useMemo(() => payments.reduce((acc, curr) => acc + curr.value, 0), [payments]);

    const consolidatedTotal = useMemo(() => (total || 0) + (measurementResidue?.floatValue || 0), [total, measurementResidue]);

    const diffValue = useMemo(() => parseFloat(consolidatedTotal.toFixed(2)) - (measurement?.predicted_value || 0), [consolidatedTotal, measurement?.predicted_value]);

    const getPaymentValueByType = useMemo(
        () => (type: ProviderFinancialPaymentType) => {
            return (payments || []).filter((item) => item.type === type).reduce((acc, curr) => acc + curr.value, 0);
        },
        [payments]
    );

    const handleDate = (value) => {
        if (value) {
            setDate(formatDate(value, 'YYYY-MM-DD'));
            setValue('measurementDate', value);
        }
    };

    const itemPercentage = useMemo(
        () => (value: number) => {
            const predictedValue = stats?.predicted?.value || 0;

            return (value * MAX) / predictedValue;
        },
        [stats?.predicted?.value]
    );

    const totalDiffPercentage = useMemo(() => MAX - (itemPercentage(consolidatedTotal) || 0), [itemPercentage, consolidatedTotal]);

    return (
        <div className="border-r border-r-base-300 p-6 overflow-y-auto w-full sm:w-[340px]">
            <div className="border border-base-300 p-4 rounded-[14px] w-full">
                <DatePicker value={date ? dayjs(date).toISOString() : undefined} label="Selecione uma data de vencimento" placeholderText="Vencimento" onChange={handleDate} />
            </div>
            <div className="border border-base-300 p-4 rounded-[14px] mt-4">
                <div className={`flex items-end justify-between mb-2`}>
                    <Text as="span" variant="body.regular.xs" className="text-base-500 block">
                        Entrada
                    </Text>
                    <Text as="span" variant="body.medium.2xs" className="text-heading text-right">
                        {formatMoney(getPaymentValueByType(ProviderFinancialPaymentType.Start))}
                        <Text as="span" variant="body.regular.xs" className="block text-[10px]">
                            {(itemPercentage(getPaymentValueByType(ProviderFinancialPaymentType.Start)) || 0).toFixed(2)}% da previsão
                        </Text>
                    </Text>
                </div>
                <div className={`flex items-end justify-between mb-2`}>
                    <Text as="span" variant="body.regular.xs" className="text-base-500 block">
                        Faturamento direto
                    </Text>
                    <Text as="span" variant="body.medium.2xs" className="text-heading text-right">
                        {formatMoney(getPaymentValueByType(ProviderFinancialPaymentType.DirectBilling))}
                        <Text as="span" variant="body.regular.xs" className="block text-[10px]">
                            {(itemPercentage(getPaymentValueByType(ProviderFinancialPaymentType.DirectBilling)) || 0).toFixed(2)}% da previsão
                        </Text>
                    </Text>
                </div>
                <div className={`flex items-end justify-between mb-3 pb-3 border-b border-b-base-300`}>
                    <Text as="span" variant="body.regular.xs" className="text-base-500 block">
                        Resíduo
                    </Text>
                    <Text as="span" variant="body.medium.2xs" className="text-heading text-right">
                        {formatMoney(measurementResidue?.floatValue)}
                        <Text as="span" variant="body.regular.xs" className="block text-[10px]">
                            {itemPercentage(measurementResidue?.floatValue || 0).toFixed(2)}% da previsão
                        </Text>
                    </Text>
                </div>
                <div className={`flex items-end justify-between`}>
                    <Text as="strong" variant="body.regular.xs" className="text-base-500 block">
                        Total
                    </Text>
                    <Text as="span" variant="body.medium.2xs" className={`text-right ${diffValue > 0 ? 'text-system-danger-500' : 'text-system-success-500'}`}>
                        {formatMoney(consolidatedTotal)}
                        {totalDiffPercentage !== 100 && (
                            <Text as="span" variant="body.regular.xs" className="block text-[10px]">
                                {Math.abs(totalDiffPercentage).toFixed(2)}% {diffValue > 0 ? 'acima' : 'abaixo'} da previsão
                            </Text>
                        )}
                    </Text>
                </div>
            </div>
            <div className="border border-base-300 p-4 rounded-[14px] mt-4">
                <div className="flex items-end justify-between mb-3">
                    <Text as="span" variant="body.regular.xs" className="text-base-500 block">
                        Previsto
                    </Text>
                    <Text as="span" variant="body.medium.2xs" className="text-heading text-right">
                        {formatMoney(measurement?.predicted_value || 0)}
                        <Text as="span" variant="body.regular.xs" className="block text-[10px]">
                            100% da previsão
                        </Text>
                    </Text>
                </div>
                <div className={`flex items-end justify-between mb-3 pb-3 border-b border-b-base-300`}>
                    <Text as="span" variant="body.regular.xs" className="text-base-500 block">
                        Realizado
                    </Text>
                    <Text as="span" variant="body.medium.2xs" className="text-heading text-right">
                        {formatMoney(consolidatedTotal)}
                        <Text as="span" variant="body.regular.xs" className="block text-[10px]">
                            {Math.abs(itemPercentage(consolidatedTotal)).toFixed(2)}% da previsão
                        </Text>
                    </Text>
                </div>
                <div className="flex items-end justify-between">
                    <Text as="strong" variant="body.regular.xs" className="text-base-500 block">
                        Diferença
                    </Text>
                    <Text as="span" variant="body.medium.2xs" className={`text-right ${diffValue !== 0 ? 'text-system-danger-500' : 'text-system-success-500'}`}>
                        {formatMoney(Math.abs(diffValue))}
                        <Text as="span" variant="body.regular.xs" className="block text-[10px]">
                            {Math.abs(totalDiffPercentage).toFixed(2)}% da previsão
                        </Text>
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default NewMeasurementSidebar;
