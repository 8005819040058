import { TaskType } from 'types/models/task';
import { timeToMinutes } from 'utils/date';
import dictionary from 'utils/dictionary';
import { array, date, number, object, string } from 'yup';

const MAX_MINUTES = 599;

export const validateTime = (value?: string) => {
    if (!value) {
        return false;
    }

    const allMinutes = timeToMinutes(value);

    return allMinutes <= MAX_MINUTES;
};

export const isValid = (value?: string) => {
    if (!value) {
        return true;
    }

    const parsed = parseFloat(value.replace(',', '.').replace(' ', ''));

    return parsed > 0 && parsed <= 100;
};

export const taskProgressSchema = (taskType?: TaskType) =>
    object({
        date: date().required(dictionary.validation.required),
        files: array(number()).optional().notRequired(),
        images: array(number()).nullable().optional().notRequired(),
        observations: string().nullable().optional().notRequired(),
        strPercentage: string().optional().notRequired().test('isValid', 'Percentual inválido', isValid),
        ...(taskType === TaskType.Hour && {
            strHour: string().required(dictionary.validation.required).test('hour', 'A quantidade de horas precisa ser menor que 10', validateTime)
        })
    });
