// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import useToast from 'hooks/toast/use-toast';
import project, { getCisoKey, getCisosKey, getProjectKey } from './graphql';
import api from 'services/api';

const useCreateProjectManagementInformation = (projectId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const sendNotification = async (projectManagementInformation?: number) => {
        if (!projectManagementInformation) {
            return;
        }

        return api.post('/notifications/ciso', { id: projectManagementInformation });
    };

    const createProjectManagementInformation = async (payload: any) => apiGraphQl<{ item: { id: number } }>(project.createProjectManagementInformation, { payload });

    return useMutation(createProjectManagementInformation, {
        onSuccess: async (data) => {
            await sendNotification(data?.item?.id);

            queryClient.invalidateQueries(getCisosKey(projectId));
            queryClient.invalidateQueries(getCisoKey(data?.item?.id.toString()));
            queryClient.invalidateQueries(getProjectKey(projectId));

            navigate(-1);

            showToast('CISO criado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar o CISO', 'danger');
        }
    });
};

export default useCreateProjectManagementInformation;
