import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { TaskDashboardStatus } from 'types/models/task-dashboard';
import { getTaskStatusesKey } from './graphql';

type Response = {
    items: TaskDashboardStatus[];
};

const useGetTaskStatuses = () => {
    const fetcher = async () =>
        apiGraphQl<Response>(`query getTaskStatuses {
        items: task_status(order_by: {order: asc}) {
          id
          title
          slug
        }
      }`).then(({ items }) => items);

    return useQuery(getTaskStatusesKey, fetcher);
};

export default useGetTaskStatuses;
