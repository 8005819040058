import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { getBudgetVersionKeys } from './keys';
import { CommercialBudgetVersionDetails } from 'types/models/commercial';

const useGetCommercialBudgetVersion = (budgetId?: string, budgetVersionId?: string, projectTypeId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const request = async () => {
        const { data } = await api.get<CommercialBudgetVersionDetails>(`budgets/${budgetId}/${projectTypeId}/versions/${budgetVersionId}`);

        return data;
    };

    return useQuery(getBudgetVersionKeys(budgetId, projectTypeId, budgetVersionId), request, {
        enabled: !!budgetId && !!budgetVersionId && !!projectTypeId,
        onError: () => {
            navigate(-1);

            showToast('Ocorreu um problema ao buscar os detalhes dessa versão! Por favor, tente novamente.', 'danger');
        }
    });
};

export default useGetCommercialBudgetVersion;
