import { jsonToGraphQLQuery } from 'json-to-graphql-query';

export const getProfileQuery = (profileId?: string) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetProfile',
                profile_by_pk: {
                    __args: { id: Number(profileId || 0) },
                    name: true,
                    dashboard_template_id: true,
                    profile_page_rules: {
                        page_id: true,
                        rule_id: true
                    }
                }
            }
        },
        { pretty: true }
    );
};
