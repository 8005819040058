import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import useToast from 'hooks/toast/use-toast';
import { getProjectTypeSolicitationKey } from '../projects/graphql';

const useUpdateAttentionPoint = (proposalTypeId?: string, solicitationId?: string, attentionPointId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload) => api.patch(`/project-types/${proposalTypeId}/solicitations/${solicitationId}/attention-points/${attentionPointId}`, payload);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectTypeSolicitationKey(solicitationId));

            navigate(-1);

            showToast('Ponto de atenção atualizado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao atualizar o ponto de atenção', 'danger');
        }
    });
};

export default useUpdateAttentionPoint;
