import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import apiGraphQl from 'services/graphql';
import useUploadFile from 'services/queries/files/use-upload-file';
import { getProjectProposalTypeKey } from '../projects/graphql';

const useChangeProjectTypeImage = (projectProposalTypeId?: number) => {
    const { showToast } = useToast();
    const client = useQueryClient();

    const { uploadFile } = useUploadFile();

    const request = async (payload: File) => {
        let fileId;

        await uploadFile(
            payload,
            ({ id }) => {
                fileId = id;
            },
            true
        );

        if (!fileId) {
            throw new Error();
        }

        return apiGraphQl(
            `mutation ($projectProposalTypeId: Int!, $fileId: Int!) {
                item: update_project_proposal_type_by_pk(pk_columns: {id: $projectProposalTypeId}, _set: {file_id: $fileId}) {
                    id
                    file_id
                }
            }`,
            { projectProposalTypeId, fileId }
        );
    };

    return useMutation(request, {
        onSuccess: () => {
            client.invalidateQueries(getProjectProposalTypeKey(projectProposalTypeId));

            showToast('Imagem atualizada com sucesso!', 'success');
        },
        onError: () => {
            showToast('Tivemos um problema ao atualizar a imagem', 'danger');
        }
    });
};

export default useChangeProjectTypeImage;
