import { useQuery } from 'react-query';
import api from 'services/api';
import useCredentials from 'store/credentials/use-credentials';
import { Credentials } from 'types/models/credentials';
import { getCredentialsKey } from './keys';

const useGetCredentials = (isAuthenticated: boolean) => {
    const { setCredentials } = useCredentials();

    const request = async () => api.get<Credentials>('/credentials').then(({ data }) => data);

    return useQuery(getCredentialsKey, request, {
        enabled: isAuthenticated,
        onSuccess: setCredentials
    });
};

export default useGetCredentials;
