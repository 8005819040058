import { useMemo, useState } from 'react';

import Text from 'components/core/text';

import TableCell from '@mui/material/TableCell/TableCell';

import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';

import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { ProjectTransactions, Status } from 'types/general';
import { OutcomeProjectTransaction } from 'types/models/organization';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Display, MUIDataTableColumn } from 'mui-datatables';
import dictionary from 'utils/dictionary';
import { formatDate } from 'utils/date';

import Datatable from 'components/core/table/datatable';

import { useQueryClient } from 'react-query';
import useDelete from 'services/queries/crud/use-delete';

import useGetProjectOutcomes from 'services/queries/operationals/use-get-project-transactions-outcomes';
import AddButton from 'components/buttons/add';
import ConfirmModal from 'components/core/modal/confirm';
import { OperationalDetailsPageContext } from '../../view';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';
import SimpleTable, { SimpleTableContent } from 'components/core/table/simple';
import { formatMoney } from 'utils/money';
import Badge from 'components/core/badge';
import useGetProjectTransactionsReport from 'services/queries/operationals/use-get-transactions-report';

type OutcomesPageProps = {
    withActions: boolean;
};

export const infoButton = { classes: { child: 'text-system-info-100' } };
export const warningButton = { classes: { child: 'text-system-warning-100' } };
export const dangerButton = { classes: { child: 'text-system-danger-100' } };

type OutcomeGraphql = { project_id: string; transaction: OutcomeProjectTransaction };

const INITIAL_PARAMS: GraphqlPaginationVariables<OutcomeGraphql> = {
    limit: 10,
    page: 1,
    offset: 0,
    where: {
        transaction: { status: { _eq: Status.Active }, type: { _eq: ProjectTransactions.Outcome } }
    }
};

const outcomesHeadTitles = [
    { label: '' },
    { label: 'CONTRATO' },
    { label: 'PLANEJADO' },
    { label: 'REALIZADO' },
    { label: 'SALDO' },
    { label: 'PREVISTO ETC' },
    { label: 'PREVISÃO EAC' },
    { label: 'VAR' }
];

const OutcomesPage = ({ withActions }: OutcomesPageProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const queryClient = useQueryClient();

    const { projectProposalTypeId } = useParams();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const [outcomeTransactionToDeleteId, setOutcomeTransactionToDeleteId] = useState<string | null>(null);

    const [params, setParams] = useState<GraphqlPaginationVariables<OutcomeGraphql>>(INITIAL_PARAMS);

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const { data: transactionOutcomes } = useGetProjectOutcomes(projectProposalTypeId, params);
    const { data: transactionsReport } = useGetProjectTransactionsReport(projectProposalTypeId);

    const outcomesTableData: SimpleTableContent[] = [
        {
            id: 0,
            contents: [
                {
                    label: 'TERCEIROS/RESIDENTES'
                },
                {
                    label: formatMoney(transactionsReport?.all.contract || 0)
                },
                {
                    label: formatMoney(transactionsReport?.all.planned || 0)
                },
                {
                    label: formatMoney(transactionsReport?.all?.realized || 0)
                },
                {
                    label: formatMoney(transactionsReport?.all?.budget || 0)
                },
                {
                    label: formatMoney(transactionsReport?.all?.etc || 0)
                },
                {
                    label: formatMoney(transactionsReport?.all?.eac || 0)
                },
                {
                    label: <Badge variant={(transactionsReport?.all?.variation || 0) < 0 ? 'error' : 'success'}>{transactionsReport?.all?.variation || 0}%</Badge>
                }
            ]
        },
        {
            id: 1,
            contents: [
                {
                    label: 'OUTRAS DESPESAS'
                },
                {
                    label: formatMoney(transactionsReport?.others.contract || 0)
                },
                {
                    label: formatMoney(transactionsReport?.others.planned || 0)
                },
                {
                    label: formatMoney(transactionsReport?.others?.realized || 0)
                },
                {
                    label: formatMoney(transactionsReport?.others?.budget || 0)
                },
                {
                    label: formatMoney(transactionsReport?.others?.etc || 0)
                },
                {
                    label: formatMoney(transactionsReport?.others?.eac || 0)
                },
                {
                    label: <Badge variant={(transactionsReport?.others?.variation || 0) < 0 ? 'error' : 'success'}>{transactionsReport?.others?.variation || 0}%</Badge>
                }
            ]
        }
    ];

    const { mutateAsync: deleteOutcomeTransactionById, isLoading: isDeletingOutcome } = useDelete({
        apiPayload: { endpoint: `/project-types/${projectProposalTypeId}/transactions`, useApiTodelete: true }
    });

    const handleChangePage = (page: number) => {
        setParams({
            ...params,
            page,
            offset: (page || 1) * params.limit - params.limit
        });
    };

    const TH = (value: any) => (
        <TableCell key={value.label}>
            <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                {value.label}
            </Text>
        </TableCell>
    );

    const handleDelete = async (outcomeId) => {
        try {
            await deleteOutcomeTransactionById({ id: outcomeId });

            setOutcomeTransactionToDeleteId(null);

            queryClient.invalidateQueries('operational');
        } catch (error) {
            console.log('handleDeleteOutcomeTransaction', error);
        }
    };

    const columns: MUIDataTableColumn[] = useMemo(() => {
        const handleGoTo = (path: string) => () => navigate(path);

        const arr = [
            {
                name: 'transaction.id',
                options: { display: 'excluded' as Display }
            },
            {
                name: 'transaction.code',
                label: dictionary.content.graphql['code'],
                options: {
                    customHeadRender: TH
                }
            },
            {
                name: 'transaction.description',
                label: dictionary.content.graphql['description'],
                options: {
                    customHeadRender: TH
                }
            },
            {
                name: 'transaction.due_date',
                label: dictionary.content.graphql['due_date'],
                options: {
                    customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') || '-',
                    customHeadRender: TH
                }
            },
            {
                name: 'transaction.payday',
                label: dictionary.content.graphql['payday'],
                options: {
                    customBodyRender: (value) => formatDate(value, 'DD/MM/YYYY') || '-',
                    customHeadRender: TH
                }
            },
            {
                name: 'transaction.value',
                label: dictionary.content.graphql['value'],
                options: {
                    customBodyRender: (value: number) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                    customHeadRender: TH
                }
            }
        ];

        if (withActions) {
            arr.push({
                name: '',
                label: 'Ações',
                options: {
                    customHeadRender: (value: any) => (
                        <TableCell key={value.label} className="w-[160px]">
                            <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                {value.label}
                            </Text>
                        </TableCell>
                    ),
                    customBodyRender: (_, rowIndex) => {
                        const [idTransactionOutcome] = rowIndex.rowData;
                        return (
                            <div className="flex">
                                <Tooltip placement="top" title="Visualizar" disableFocusListener={true}>
                                    <IconButton TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20" onClick={handleGoTo(`${idTransactionOutcome}`)}>
                                        <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                    <IconButton TouchRippleProps={warningButton} className="hover:bg-system-warning-100 hover:bg-opacity-30" onClick={handleGoTo(`editar/${idTransactionOutcome}`)}>
                                        <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                    <IconButton
                                        TouchRippleProps={dangerButton}
                                        className="hover:bg-system-danger-100 hover:bg-opacity-30"
                                        onClick={() => setOutcomeTransactionToDeleteId(idTransactionOutcome)}>
                                        <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    }
                } as any
            });
        }

        return arr;
    }, [palette, withActions, navigate]);

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <Text as="h6" variant="h6" className="text-heading mb-4">
                Relatório
            </Text>
            <div className="mb-16">
                <SimpleTable columns={outcomesHeadTitles} items={outcomesTableData} />
            </div>
            <Text as="h6" variant="h6" className="text-heading mb-4">
                Despesas
            </Text>
            <Datatable
                data={transactionOutcomes?.items || []}
                columns={columns || []}
                footerLeft={<AddButton onClick={() => navigate('novo')}>Nova despesa</AddButton>}
                options={{
                    elevation: 0,
                    sort: false,
                    filter: false,
                    print: false,
                    download: false,
                    viewColumns: false,
                    serverSide: true,
                    count: transactionOutcomes?.pagination.aggregate.count,
                    page: params.page,
                    search: false,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,
                    textLabels: {
                        body: {
                            noMatch: 'Nenhuma despesa para esse projeto encontrada.'
                        }
                    },

                    onChangePage: handleChangePage
                }}
                withItemsPerPage={false}
            />
            <Outlet context={ctx} />
            {Boolean(outcomeTransactionToDeleteId) && (
                <ConfirmModal
                    title="Apagar despesa"
                    description="Você tem certeza que deseja apagar esta despesa?"
                    onClose={() => setOutcomeTransactionToDeleteId(null)}
                    isLoading={isDeletingOutcome}
                    onConfirmAction={async () => await handleDelete(outcomeTransactionToDeleteId)}
                />
            )}
        </>
    );
};

export default OutcomesPage;
