import dictionary from 'utils/dictionary';
import { number, object, string, array } from 'yup';

export const mainSchema = object({
    classification: number().required(dictionary.validation.required),
    date: string().required(dictionary.validation.required),
    effective: array(
        object({
            provider: number().required(dictionary.validation.required),
            classification: number().required(dictionary.validation.required),
            quantity: number().required(dictionary.validation.required)
        })
    ),
    observations: string(),
    workDiaries: array(
        object({
            provider: number().required(dictionary.validation.required),
            file: object().required(dictionary.validation.required)
        })
    )
});
