import ProjectChart from 'pages/private/home/components/project-chart';
import HomeMyTasks from 'pages/private/home/components/my-tasks';
import useProjectTypeSlug from '../hooks/use-project-type-slug';
import { useParams } from 'react-router-dom';
import useGetProjectCharts from 'services/queries/projects/use-get-project-charts';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';

const borderStyles = 'border-base-300 border w-full md:w-auto min-w-[270px]';

const OperationalDashboard = () => {
    const { isManagement } = useProjectTypeSlug();
    const { projectProposalTypeId } = useParams<{ projectProposalTypeId: string }>();
    const { isLoading, data: chartData } = useGetProjectCharts(projectProposalTypeId);
    const { timeline, meetingRecords, checklist, solicitations } = chartData || {};
    const { isSmallerThenTabletLandscape } = useResponsive();

    return (
        <div className="flex-1 flex-col pb-[50px] overflow-y-auto md:mb-[300px]">
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <div className={isManagement ? 'grid grid-cols-1 gap-4' : 'flex flex-col flex-wrap md:flex-row items-start gap-4'}>
                <HomeMyTasks
                    activePeriod={0}
                    setActivePeriod={() => {}}
                    items={[]}
                    title="Pendências"
                    className={borderStyles}
                    emptyMessage="Nenhuma pendência até o momento!"
                    emptyIcon="ico-emoji-nerd"
                />
                <div className={`grid gap-4 ${isManagement ? 'grid-cols-1 lg:grid-cols-2' : 'grid-cols-1 w-full xl:w-auto'}`}>
                    <ProjectChart isLoading={isLoading} {...timeline} className={borderStyles} title="Cronogramas" />
                    {isManagement && (
                        <>
                            <ProjectChart isLoading={isLoading} {...checklist} className={borderStyles} title="Checklist" />
                            <ProjectChart isLoading={isLoading} {...meetingRecords} className={borderStyles} title="Atas" />
                            <ProjectChart isLoading={isLoading} {...solicitations} className={borderStyles} title="Solicitações" />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OperationalDashboard;
