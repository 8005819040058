import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';

import queries, { getClassificationOptionsByTypeKey } from './graphql';
import { ClassificationType } from 'types/models/classification';
import { Option } from 'types/general';

type Response = {
    classification: Option[];
};

const useGetClassificationsOptions = (classificationType: ClassificationType) => {
    const fetcher = async () => apiGraphQl<Response>(queries.getClassificationsOptions, { classificationType }).then(({ classification }) => classification);

    return useQuery(getClassificationOptionsByTypeKey(classificationType), fetcher);
};

export default useGetClassificationsOptions;
