import { useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import CreateOrUpdatePerson, { CustomCreatePersonPayload } from './create-or-update';
import { formatDate } from 'utils/date';
import { currencyToFloat } from 'utils/money';
import { Person } from 'types/models/person';
import useCreatePerson from 'services/queries/people/use-create-person';
import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';
import useGetBranchesOptions from 'services/queries/branches/use-get-branches-options';
import useGetProfilesOptions from 'services/queries/profiles/use-get-profiles-options';
import useGetPerson from 'services/queries/people/use-get-person';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import _some from 'lodash/some';

const CreateOrUpdatePersonPage = ({ title }: CrudPageProps) => {
    const { personId } = useParams();

    const [isUser, setIsUser] = useState(false);
    const params = useOutletContext<GraphqlPaginationVariables<Person>>();

    const { data: profiles = [] } = useGetProfilesOptions(isUser);
    const { data: branches = [], isLoading: isLoadingBranches } = useGetBranchesOptions();

    const { mutateAsync: mutatePerson, isLoading: isSubmitting, error } = useCreatePerson({ params, id: personId });
    const { data: personDetails, isLoading: isLoadingDetails } = useGetPerson(personId);

    const isBuilding = isLoadingBranches || isLoadingDetails;

    const handleSubmit = async (data: CustomCreatePersonPayload) => {
        const hasContract = _some(_omit(data.contracts, ['juridicDocument', 'juridicFantasyName', 'juridicName']));
        const hasAddress = _some(data.address);

        try {
            const payload: any = {
                ..._omit(data, ['contracts', 'address', 'isUser', 'account', 'user']),
                birthday: formatDate(data.birthday, 'DD/MM/YYYY'),
                expeditionDate: data.expeditionDate ? formatDate(data.expeditionDate, 'DD/MM/YYYY') : null,
                ...(!!hasContract && {
                    contracts: [
                        {
                            ...data?.contracts,
                            salary: data.contracts.salary !== '' ? currencyToFloat(data.contracts?.salary) : (null as any),
                            ...(Boolean(data.contracts.startDate) && { startDate: formatDate(data.contracts?.startDate, 'DD/MM/YYYY') }),
                            ...(Boolean(data.contracts.finalDate) && { startDate: formatDate(data.contracts?.finalDate, 'DD/MM/YYYY') })
                        }
                    ]
                }),
                ...(!!hasAddress && {
                    address: _omit(data.address, ['status', 'id', 'full'])
                }),
                emails: _get(data, 'emails[0].emails', []),
                phones: _get(data, 'phones[0].phones', [])?.map((phone) => ({ ...phone, number: phone?.number?.replace(/_/g, '') })),
                ...(!personId && {
                    account: data.account
                }),
                ...(data.user !== null && {
                    user: data.user
                })
            };

            await mutatePerson(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <CreateOrUpdatePerson
            title={title || ''}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting}
            profiles={profiles}
            branches={branches}
            onChangeUser={setIsUser}
            onSubmit={handleSubmit}
            defaultValues={personDetails}
            error={error}
        />
    );
};

export default CreateOrUpdatePersonPage;
