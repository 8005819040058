import CalendarHeaderMonths from './months';

const CalendarHeader = () => {
    return (
        <div className="flex top-0 mt-0 sticky bg-white h-[75px]">
            <CalendarHeaderMonths />
        </div>
    );
};

export default CalendarHeader;
