import Datatable from 'components/core/table/datatable';
import { resourcesReportColumns } from './utils';
import Text from 'components/core/text';

import { useState } from 'react';
import dictionary from 'utils/dictionary';
import useGetResourcesReport from 'services/queries/reports/use-get-resources-report';
import useHandleDownloadReport from 'services/queries/reports/use-handle-download-report';

const ResourcesReportPage = () => {
    const [page, setPage] = useState(1);

    const { data: resourcesReport, isLoading: isLoadingResourcesReport } = useGetResourcesReport(page);
    const { handleDownload, isDownloading } = useHandleDownloadReport('reports/project-types-resources');

    return (
        <>
            <Text as="h6" variant="h6" className="text-heading !text-2xl mb-4">
                Relatório de Recursos
            </Text>
            <Datatable
                loading={isLoadingResourcesReport}
                options={{
                    count: resourcesReport?.total,
                    elevation: 0,
                    sort: true,
                    filter: false,
                    search: false,
                    print: false,
                    download: isDownloading ? 'disabled' : true,
                    viewColumns: false,
                    serverSide: true,
                    selectableRows: 'none',
                    enableNestedDataAccess: '.',
                    pagination: true,

                    textLabels: {
                        ...dictionary.content.datatable,
                        toolbar: {
                            downloadCsv: 'Download XLSX'
                        }
                    },
                    onChangePage: setPage,
                    onDownload: handleDownload
                }}
                columns={resourcesReportColumns}
                data={resourcesReport?.items || []}
            />
        </>
    );
};

export default ResourcesReportPage;
