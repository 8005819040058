import { useQuery } from 'react-query';

import apiGraphQl from 'services/graphql';
import { CreateJobPositionPayload } from 'types/models/configurations-classifications';
import queries, { getJobPositionKey } from './graphql';

type Response = {
    items: CreateJobPositionPayload & { id: string };
};

//TODO: GRAPHQL.ts mutation query to get job_position by id
const useGetJobPositionById = (jobPositionId?: string) => {
    const fetcher = async () => apiGraphQl<Response>(queries.getJobPositionById, { jobPositionId }).then(({ items }) => items);

    return useQuery(getJobPositionKey(jobPositionId), fetcher, { enabled: !!jobPositionId });
};

export default useGetJobPositionById;
