import Icon from 'components/core/icon';
import { MONTH_HEIGHT } from '../utils';
import { OccupationMapResponse } from 'services/queries/occupation-map/use-get-occupation-map';

type Props = {
    items: OccupationMapResponse;
    onTogglePerson(id: number): void;
    activePeople: number[];
};

const CalendarSidebar = ({ items, activePeople, onTogglePerson }: Props) => {
    const getIsOpen = (taskId: number) => activePeople.includes(taskId);

    return (
        <>
            <div className="w-full flex flex-col sticky ml-0 left-0 flex-1 float-left" style={{ flex: '1 0 auto' }}>
                {items?.map(({ person, clients }, index) => {
                    const isOpen = getIsOpen(person.id);

                    return (
                        <div key={`person_${person.id}`} className="flex flex-col border-b border-slate-200 bg-white">
                            <div
                                className="hover:bg-base-200 cursor-pointer relative flex flex-col sm:flex-row items-center sm:justify-start justify-center px-4"
                                onClick={() => onTogglePerson(person.id)}
                                style={{ height: index === 0 ? MONTH_HEIGHT - 2 : MONTH_HEIGHT - 1 }}>
                                <span
                                    className="w-1 h-full absolute left-0 top-0"
                                    // style={{ backgroundColor: color?.[400] }}
                                ></span>

                                <Icon name={isOpen ? 'ico-chevron-down' : 'ico-chevron-right'} width={15} height={15} className="!text-base-500 mr-3" />

                                <span className="text-heading sm:text-left text-center text-sm truncate mr-2 w-full text-ellipsis sm:flex-1">{person?.name}</span>
                            </div>

                            {isOpen && (
                                <div className="flex flex-col bg-base-200">
                                    {clients?.map((client) => {
                                        return (
                                            <div
                                                key={`person_${person.id}_client_${client.id}`}
                                                className="flex flex-col sm:flex-row items-center sm:justify-start justify-center pl-11 pr-4"
                                                style={{ height: MONTH_HEIGHT }}>
                                                <span className="text-base-700 sm:text-left text-center text-sm truncate sm:mr-2 text-ellipsis w-full sm:flex-1">{client.title}</span>
                                            </div>
                                        );
                                    })}

                                    <div className="flex flex-col sm:flex-row items-center sm:justify-start justify-center pl-11 pr-4" style={{ height: MONTH_HEIGHT }}>
                                        <span className="text-base-700 sm:text-left text-center text-sm truncate sm:mr-2 text-ellipsis w-full sm:flex-1 font-bold">Disponibilidade</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default CalendarSidebar;
