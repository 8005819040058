import CurrencyInput from 'components/core/form/currency';
import Editor from 'components/core/form/editor';
import Select from 'components/core/form/select';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateAssetPayload } from 'types/models/assets';
import { assetsModalities } from 'utils/statics';
import Text from 'components/core/text';
import CommonFields from '../commonFields';

const FurnitureFields = () => {
    const { control, formState } = useFormContext<CreateAssetPayload>();

    return (
        <>
            <CommonFields />
            <Controller
                name="modality"
                control={control}
                render={({ field }) => {
                    const value = assetsModalities.find((item) => item.value === field?.value);

                    return (
                        <Select
                            {...field}
                            value={value}
                            options={assetsModalities}
                            onChange={(option: any) => field.onChange(option?.value)}
                            label="Modalidade"
                            placeholder="Selecione a modalidade"
                            error={formState.errors?.modality?.message}
                        />
                    );
                }}
            />
            <Controller
                name="value"
                control={control}
                render={({ field }) => (
                    <div>
                        <CurrencyInput
                            name={field.name}
                            ref={field.ref}
                            value={field.value}
                            left={
                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                    R$
                                </Text>
                            }
                            fixedDecimalScale={true}
                            decimalScale={2}
                            allowLeadingZeros={true}
                            onValueChange={({ floatValue }) => field.onChange(floatValue)}
                            error={formState.errors?.value?.message}
                            label="Valor"
                            placeholder="Ex: 99,99"
                        />
                    </div>
                )}
            />
            <Controller
                name="insurance_value"
                control={control}
                render={({ field }) => (
                    <div>
                        <CurrencyInput
                            name={field.name}
                            ref={field.ref}
                            value={field.value}
                            left={
                                <Text as="span" variant="body.medium.sm" className="text-heading">
                                    R$
                                </Text>
                            }
                            fixedDecimalScale={true}
                            decimalScale={2}
                            allowLeadingZeros={true}
                            onValueChange={({ floatValue }) => field.onChange(floatValue)}
                            error={formState.errors?.insurance_value?.message}
                            label="Seguro"
                            placeholder="Ex: 99,99"
                        />
                    </div>
                )}
            />
            <Controller
                name="observations"
                control={control}
                render={({ field }) => <Editor parentClasses="col-span-3" {...field} label="Observação" error={formState.errors?.observations?.message} placeholder="Observação" />}
            />
        </>
    );
};

export default FurnitureFields;
