import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AuthPayload } from 'types/models/user';
import theme from 'settings/theme';
import Icon from 'components/core/icon';
import { Link } from 'react-router-dom';
import Button from 'components/core/button';
import Input from 'components/core/form/input';
import Text from 'components/core/text';
import { object, string } from 'yup';
import dictionary from 'utils/dictionary';
import classNames from 'classnames';

type LoginFormProps = {
    isLoading: boolean;
    onSubmit: (payload: AuthPayload) => void;
    submitButtonTitle?: string;
    isLoginPage?: boolean;
    contentClassNames?: string;
};

const LoginForm = ({ isLoading, onSubmit, submitButtonTitle = 'Entrar', isLoginPage = true, contentClassNames }: LoginFormProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const formClassNames = classNames(styles.form, contentClassNames);

    const schema = object({
        username: string().required(dictionary.validation.required),
        password: string().required(dictionary.validation.required)
    });

    const { control, handleSubmit, formState } = useForm<AuthPayload>({
        mode: 'onSubmit',
        defaultValues: {
            password: '',
            username: ''
        },
        resolver: yupResolver(schema)
    });

    const handlePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

    const Toggle = useMemo(
        () => (
            <IconButton color="primary" className="p-1.5 m-1.5" onClick={handlePasswordVisibility}>
                <Icon color={theme.extend.colors.primary[500]} width={16} height={16} name={isPasswordVisible ? 'ico-show' : 'ico-hide'} />
            </IconButton>
        ),
        [isPasswordVisible, handlePasswordVisibility]
    );

    return (
        <form className={formClassNames} onSubmit={handleSubmit(onSubmit)}>
            {isLoginPage && (
                <Text as="h1" variant="h1" className={styles.title}>
                    Bem-vindo ao {theme.metadata.name}
                </Text>
            )}

            <div className="w-full mb-4">
                <Controller name="username" control={control} render={({ field }) => <Input {...field} label="E-mail" error={formState.errors.username?.message} />} />
            </div>
            <div className="w-full mb-5">
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => <Input {...field} label="Senha" error={formState.errors.password?.message} type={isPasswordVisible ? 'text' : 'password'} right={Toggle} />}
                />
            </div>
            {isLoginPage && (
                <div className={styles.forgotPassword}>
                    <Link className="text-primary-500" to="/esqueceu-senha">
                        Esqueceu a senha?
                    </Link>
                </div>
            )}
            <Button disabled={isLoading} loading={isLoading} type="submit" size="medium" variant="contained" color="primary" fullWidth={true}>
                {submitButtonTitle}
            </Button>
        </form>
    );
};

const styles = {
    forgotPassword: 'flex justify-end mb-5',
    form: 'bg-white w-full rounded-t-2xl p-7 sm:rounded-b-2xl sm:p-20 xl:max-w-2xl mx-auto',
    title: 'mb-4 sm:text-center text-heading sm:mb-10'
};

export default LoginForm;
