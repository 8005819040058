import { useEffect, useState } from 'react';

import Select from 'components/core/form/select';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import _get from 'lodash/get';
import useGetClassificationOptions from 'services/queries/operationals/use-get-classifications-options';
import { ClassificationType } from 'types/models/classification';
import { Option } from 'types/general';
import { useParams } from 'react-router-dom';

type ClassificationsFieldsProps = {
    methods: UseFormReturn<any, any>;
    baseName?: string;
    index?: number;
};

const ClassificationFields = ({ baseName, methods, index }: ClassificationsFieldsProps) => {
    const { control, formState, watch, reset } = methods;

    const { providerId } = useParams();

    const [subcategoryFromClassification, setSubcategoryFromClassification] = useState<Option[] | []>([]);

    const { data } = useGetClassificationOptions(ClassificationType.ProviderServices);

    const getName = (name: string) => {
        const start = baseName ? `${baseName}.` : '';

        return index !== undefined ? `${start}${index}.${name}` : `${start}${name}`;
    };

    const getError = (name: string) => _get(formState.errors, `${getName(name)}.message`);

    const classification_id_watcher = watch(getName('classification_id'));

    useEffect(() => {
        if (classification_id_watcher?.value) {
            const findChildrenOptionsById: any = data?.find((item) => item.value === classification_id_watcher.value);

            setSubcategoryFromClassification(findChildrenOptionsById?.children_classifications);
        }
    }, [classification_id_watcher, data]);

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <Controller
                    name={getName('classification_id')}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Select
                                options={data}
                                {...field}
                                placeholder="Selecione uma classificação"
                                label="Classificação"
                                onChange={(option: any) => {
                                    if (providerId) {
                                        reset({ classification_id: option, subcategory_id: '' });
                                    }

                                    field.onChange(option);
                                }}
                            />
                        );
                    }}
                />
                <Controller
                    name={getName('subcategory_id')}
                    control={control}
                    render={({ field }) => {
                        const value = subcategoryFromClassification?.find((item) => item?.value === field?.value?.value) || field?.value?.value;

                        return (
                            <Select
                                placeholder="Selecione uma categoria"
                                {...field}
                                autoFocus={false}
                                error={getError('subcategory_id')}
                                label="Sub-categoria"
                                options={subcategoryFromClassification}
                                value={value || null}
                            />
                        );
                    }}
                />
            </div>
        </>
    );
};

export default ClassificationFields;
