import classNames from 'classnames';
import Card from 'components/core/card';
import React from 'react';

type Props = {
    title: string;
    className?: string;
};

const HomeMyTasksSkeleton = ({ title, className }: Props) => {
    const randomNumber = (min = 20, max = 60) => {
        return Math.random() * (max - min) + min;
    };

    return (
        <Card style={{ height: '100%' }} className={classNames('flex-1 flex flex-col py-6 px-4 pt-4', className)}>
            <div className="flex items-center mb-4">
                <p className="text-heading text-xl mr-auto font-medium">{title}</p>

                <div className="w-[252px] max-w-full rounded-2xl bg-gray-100 animate-pulse h-[46px]"></div>
            </div>

            <div className="flex flex-col overflow-y-auto">
                {[...new Array(5).keys()].map((i) => (
                    <div className="flex animate-pulse items-center mb-4 last:mb-0 cursor-pointer" key={`task${i}`}>
                        <span className="w-[4px] h-[45px] rounded-md mr-3 bg-gray-100"></span>
                        <div className="flex flex-col flex-1">
                            <span className="text-base-500 text-sm h-5 bg-gray-100 mb-1 rounded" style={{ width: `${randomNumber()}%` }}></span>
                            <p className="text-heading text-sm h-4 bg-gray-100 rounded" style={{ width: `${randomNumber()}%` }}></p>
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    );
};

export default HomeMyTasksSkeleton;
