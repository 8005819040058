import { Page } from 'types/models/page';
import { Rule } from 'types/permissions';

const useCreateNav = <T>(subpages: Page[], mapFn: (page: Page) => T) => {
    const arr = subpages
        .filter((subpage) => {
            const canAccess = subpage.rules.some((item) => item.key === Rule.List || item.key === Rule.Show);

            return canAccess;
        })
        .map(mapFn);

    return arr;
};

export default useCreateNav;
