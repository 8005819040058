import React from 'react';
import Text from 'components/core/text';
import { MeasurementStats } from 'types/models/provider';
import { formatMoney } from 'utils/money';
import { formatDate } from 'utils/date';

type NewMeasurementHeaderProps = {
    stats: MeasurementStats;
};

const NewMeasurementHeader = ({ stats }: NewMeasurementHeaderProps) => {
    return (
        <div className="bg-base-200 sticky left-0 -top-[1px] z-10 border-y border-y-base-300 flex w-full overflow-x-auto">
            <div className="border-r border-r-base-300 flex-[1.5]">
                <Text as="h6" variant="h6" className="text-heading text-center py-2 border-b border-b-base-300">
                    Previsto
                </Text>
                <div className="flex">
                    <div className="border-r border-r-base-300 flex-[2]">
                        <Text as="label" variant="body.medium.2xs" className="text-heading text-center py-2 border-b border-b-base-300 block">
                            Medição
                        </Text>
                        <div className="flex">
                            <div className="text-center border-r border-r-base-300 flex-1">
                                <Text as="span" variant="body.regular.xs" className="text-base-700 block p-2 border-b border-b-base-300">
                                    Valor
                                </Text>
                                <div className="flex gap-2 flex-wrap items-end justify-between px-6 py-3">
                                    <Text as="strong" variant="body.medium.2xs" className="text-secondary-500 leading-[1]">
                                        {formatMoney(stats.predicted.value || 0)}
                                    </Text>
                                    <Text as="strong" variant="body.medium.2xs" className="text-secondary-500 leading-[1]">
                                        {stats.predicted.percentage || 0}%
                                    </Text>
                                </div>
                            </div>
                            <div className="text-center">
                                <Text as="span" variant="body.regular.xs" className="text-base-700 block p-2 border-b border-b-base-300">
                                    Data
                                </Text>
                                <div className="flex items-end justify-between px-6 py-3">
                                    <Text as="strong" variant="body.medium.2xs" className="text-secondary-500 leading-[1]">
                                        {formatDate(stats.predicted.date)}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1">
                        <Text as="label" variant="body.medium.2xs" className="text-heading text-center py-2 border-b border-b-base-300 block">
                            Acumulado
                        </Text>
                        <div className="text-center">
                            <Text as="span" variant="body.regular.xs" className="text-base-700 block p-2 border-b border-b-base-300">
                                Valor
                            </Text>
                            <div className="flex gap-2 flex-wrap items-end justify-between px-6 py-3">
                                <Text as="strong" variant="body.medium.2xs" className="text-secondary-500 leading-[1]">
                                    {formatMoney(stats.predicted.accumulated.value || 0)}
                                </Text>
                                <Text as="strong" variant="body.medium.2xs" className="text-secondary-500 leading-[1]">
                                    {stats.predicted.accumulated.percentage || 0}%
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-[0.5]">
                <Text as="h6" variant="h6" className="text-heading text-center py-2 border-b border-b-base-300">
                    Realizado
                </Text>
                <Text as="label" variant="body.medium.2xs" className="text-heading text-center py-2 border-b border-b-base-300 block">
                    Acumulado
                </Text>
                <div className="text-center">
                    <Text as="span" variant="body.regular.xs" className="text-base-700 block p-2 border-b border-b-base-300">
                        Valor
                    </Text>
                    <div className="flex gap-2 flex-wrap items-end justify-between px-6 py-3">
                        <Text as="strong" variant="body.medium.2xs" className="text-secondary-500 leading-[1]">
                            {formatMoney(stats.realized.value || 0)}
                        </Text>
                        <Text as="strong" variant="body.medium.2xs" className="text-secondary-500 leading-[1]">
                            {stats.realized.percentage || 0}%
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewMeasurementHeader;
