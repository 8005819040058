import { useMutation } from 'react-query';
import api from 'services/api';
import { createMeasurementPeriodsKey } from './graphql';

type Payload = {
    provider: number;
    period: number;
};

type Response = {
    order: number;
    expectedDate: string;
    measurementDate: string;
    predictedPercentage: number;
    measurementPercentage: number;
    predictedValue: number;
    measurementValue: number;
};

const useCreateMeasurementPeriods = (projectProposalTypeId?: number, providerId?: number, period?: number) => {
    const fetcher = async (payload: Payload) => api.post<Response[]>(`/provider-financial-setups/${projectProposalTypeId}/measurements`, payload);

    return useMutation(createMeasurementPeriodsKey(providerId, period), fetcher);
};

export default useCreateMeasurementPeriods;
