import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { createOrUpdateProjectDocumentKey, getProjectDocumentsKey } from './graphql';

export type ProjectDocumentPayload = {
    classification: number;
    file: number;
    name: string;
    description?: string;
    observation?: string;
};

const useCreateOrUpdateProjectDocument = (projectId?: string, documentId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: ProjectDocumentPayload) => {
        const endpoint = documentId ? `/projects/${projectId}/documents/${documentId}` : `/projects/${projectId}/documents`;
        const method = documentId ? api.patch : api.post;

        return method(endpoint, payload);
    };

    return useMutation(createOrUpdateProjectDocumentKey(projectId, documentId), request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectDocumentsKey(projectId));

            navigate(-1);
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar o documento', 'danger');
        }
    });
};

export default useCreateOrUpdateProjectDocument;
