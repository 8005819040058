import { useQuery } from 'react-query';
import api from 'services/api';
import { DailyReport } from 'types/models/organization';
import { getFollowupsKey } from './graphql';

const useGetFollowups = (projectProposalTypeId?: string | number) => {
    const fetcher = async () => api.get<DailyReport[]>(`/project-types/${projectProposalTypeId}/daily-reports`).then(({ data }) => data);

    return useQuery(getFollowupsKey(projectProposalTypeId), fetcher);
};

export default useGetFollowups;
