import { City } from 'types/models/address';
import { WhereClause } from 'types/graphql';

export const getCityKey = (variables?: WhereClause<Partial<City>>) => ['city', variables];
export const getAllCitiesOptionsKey = (stateId?: number) => ['cities', 'all', 'options', stateId];

const queries = {
    getAll: `query GetCitiesOptionsByState ($state_id: Int!) {
        items: city(order_by: {name: asc}, where: {state_id: {_eq: $state_id}}) {
            value: id
            label: name
        }
    }`,
    getCity: `query GetCity ($where: city_bool_exp) {
        city(where: $where) {
            id
        }
    }`
};

export default queries;
