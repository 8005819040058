import { useQuery } from 'react-query';
import api from 'services/api';
import { getReportKey } from './keys';
import { Pagination } from 'types/pagination';
import { Project, ProjectDreResponse, ProjectProposalPayload, ProjectType } from 'types/models/project';
import { ReportResume } from 'types/models/timeline';
import { Modify } from 'types/general';

type ProjectReportResponseReport = Modify<
    Pick<ReportResume, 'eac' | 'etc'>,
    {
        saleMargin: number;
        eacMargin: number;
        saleValue: number;
        directCosts: number;
        eac: number;
        etc: number;
    }
> &
    Pick<ProjectDreResponse, 'resume'>['resume']['indirectCosts'];

type ProjectsReportResponse = Pagination<
    {
        project: Pick<Project, 'id' | 'customer' | 'code' | 'title'>;
        report: ProjectReportResponseReport;
        type: Pick<ProjectType, 'title'>;
    } & Pick<ProjectProposalPayload, 'investment'>
>;

const useGetProjectsReport = (page: number) => {
    const request = async () => {
        const { data } = await api.get<ProjectsReportResponse>(`project-types-isy?page=${page}`);

        return data;
    };

    return useQuery(getReportKey('projects', page), request);
};

export default useGetProjectsReport;
