// Dependencies
import useToast from 'hooks/toast/use-toast';

// Helpers
import { store } from 'store';
import { Creators as AuthActions } from 'store/auth';

const apiErrorHandling = async (error: any) => {
    const { showToast } = useToast();

    try {
        const state = store.getState();

        if (error?.response?.status === 401 && state.auth.token) {
            store.dispatch(AuthActions.logout());

            showToast('Sua sessão expirou. Faça login novamente.', 'danger');
        }

        throw new Error(error);
    } catch (ex) {
        return Promise.reject(error);
    }
};

export default apiErrorHandling;
