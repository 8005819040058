import { isObject } from 'lodash';
import UpdateCommercialBudgetVersion from './update';
import { CommercialBudgetCostEnum, CommercialBudgetGroupedCostType, CostItem, UpdateBudgetPayload } from 'types/models/commercial';
import { useCallback, useMemo, useState } from 'react';
import { formatDate, minutesToHours } from 'utils/date';
import { calculateValueByPercentage, convertToPercentage, getCommercialBudgetDataByProjectType, getDirectCosts, getExpensesCosts, getManagementCosts } from '../utils';
import useUpdateBudgetVersion from 'services/queries/commercial/use-update-budget-version';
import { useParams } from 'react-router-dom';
import useGetCommercialBudgetVersion from 'services/queries/commercial/use-get-commercial-budget-version';
import { getNumberValueOrZero } from 'utils/number';

const UpdateCommercialBudgetVersionPage = () => {
    const { budgetId, projectTypeId, budgetVersionId } = useParams();

    const contractValueState = useState(0);
    const [contractValue] = contractValueState;

    const { data: commercialBudgetVersionDetails, isLoading: isLoadingCommercialBudgetVersion } = useGetCommercialBudgetVersion(budgetId, budgetVersionId, projectTypeId);
    const { mutateAsync: updateBudgetVersion, isLoading: isSubmitting } = useUpdateBudgetVersion(budgetId, projectTypeId, budgetVersionId);

    const defaultValues = useMemo(() => {
        const commercialBudgetCosts = (commercialBudgetVersionDetails?.costs || []).reduce(
            (acc: any, curr) => {
                const commercialBudgetByType = acc[curr.type];

                if (!!commercialBudgetByType) {
                    if (!!curr.items.length && curr.type !== CommercialBudgetCostEnum.ExpensesOthers) {
                        const budgetsItems = (curr.items || []).map((item) => ({
                            hourMonth: minutesToHours(item.numberOfHours, 'number'),
                            hourValue: item.hourValue || 0,
                            licenseValue: item.licenseFee,
                            ...(Boolean(item.typeManagement) && { percentage: item.percentage }),
                            ...(!item.percentage && {
                                seniority: item.seniority,
                                service: { value: item.projectService?.id, label: item.projectService?.name },
                                type: curr.type
                            })
                        }));

                        commercialBudgetByType.push(...budgetsItems);
                    } else {
                        commercialBudgetByType.push({
                            service: { value: curr.projectService?.id, label: curr.projectService?.name },
                            classification: { value: curr.classification?.id, label: curr.classification?.name },
                            hourValue: curr.value || 0,
                            hourMonth: curr.quantity || 0,
                            percentage: curr.percentage || 0
                        });
                    }
                }

                return acc;
            },
            {
                [CommercialBudgetCostEnum.ExpensesThirdParties]: [],
                [CommercialBudgetCostEnum.ExpensesOthers]: [],
                [CommercialBudgetCostEnum.ProductionTeam]: [],
                [CommercialBudgetCostEnum.ManagementTeam]: [],
                [CommercialBudgetCostEnum.PostDeliveryCost]: [],
                [CommercialBudgetCostEnum.Contingency]: []
            }
        );

        if (!!commercialBudgetVersionDetails?.costs.length) {
            const GEOPname = getCommercialBudgetDataByProjectType(projectTypeId).geop.name;

            const values: UpdateBudgetPayload = {
                thirdPartyExpenses: commercialBudgetCosts[CommercialBudgetCostEnum.ExpensesThirdParties],
                otherExpenses: commercialBudgetCosts[CommercialBudgetCostEnum.ExpensesOthers],
                teamProductionExpenses: commercialBudgetCosts[CommercialBudgetCostEnum.ProductionTeam],
                postDeliveryCost: commercialBudgetCosts[CommercialBudgetCostEnum.PostDeliveryCost][0]?.percentage || 0,
                contingency: commercialBudgetCosts[CommercialBudgetCostEnum.Contingency][0]?.percentage || 0,
                deadline: commercialBudgetVersionDetails?.deadline,
                factor: commercialBudgetVersionDetails?.factor,
                date: commercialBudgetVersionDetails?.date,
                footage: commercialBudgetVersionDetails?.footage,
                tax: commercialBudgetVersionDetails?.percentageCostTax,
                commercialExpense: commercialBudgetVersionDetails?.percentageCommercialExpenses,
                financialExpense: commercialBudgetVersionDetails?.percentageFinancialExpenses,
                backoffice: commercialBudgetVersionDetails?.percentageCostAdministrative,
                manager: commercialBudgetCosts[CommercialBudgetCostEnum.ManagementTeam][1],
                coordinator: commercialBudgetCosts[CommercialBudgetCostEnum.ManagementTeam][2],
                selectedServices: commercialBudgetVersionDetails?.groupedCosts.map((item) => ({
                    actualValue: item.netValue,
                    quantity: item.footage,
                    totalValue: 0,
                    service: { value: item.projectService.id, label: item.projectService.name }
                })),
                ...(Boolean(GEOPname) && { [GEOPname]: getNumberValueOrZero(commercialBudgetCosts[CommercialBudgetCostEnum.ManagementTeam][0]?.percentage) })
            };

            return values;
        }
    }, [commercialBudgetVersionDetails, projectTypeId]);

    const getGroupedCosts = (reducedServices: any[], selectedServices: any[]) => {
        return reducedServices.map((item, index) => {
            const actualSelectedService = selectedServices[index];

            const discount = item.totalValue - (actualSelectedService?.actualValue || 0);
            const addition = (actualSelectedService?.actualValue || 0) - item.totalValue;

            return {
                type: CommercialBudgetGroupedCostType.Service,
                projectService: item.service?.value,
                grossValue: item.totalValue,
                netValue: actualSelectedService?.actualValue || 0,
                discount: discount < 0 ? 0 : discount,
                addition: addition < 0 ? 0 : addition,
                footage: actualSelectedService?.quantity || 0
            };
        });
    };

    const handleSubmit = useCallback(
        async (data: UpdateBudgetPayload) => {
            try {
                const { geop } = getCommercialBudgetDataByProjectType(projectTypeId);

                const thirdExpenses = data.thirdPartyExpenses;
                const otherExpenses = data.otherExpenses;
                const teamCostProductionExpenses = data.teamProductionExpenses;

                const postDeliveryCostValue = getDirectCosts(data, contractValue, false) * convertToPercentage(data.postDeliveryCost);
                const GEOPValue = getDirectCosts(data, contractValue, false, true) * convertToPercentage(data[geop.name]);
                const contingencyValue = calculateValueByPercentage(contractValue)(data.contingency);

                const services = [...thirdExpenses, ...otherExpenses, ...teamCostProductionExpenses].filter(isObject);

                const selectedServices = data.selectedServices || [];

                const formatedServices = services.map((item) => ({
                    ...item,
                    totalValue: !!item.hourMonth ? (item.hourMonth || 0) * (Number(item.hourValue) + (item.licenseValue || 0)) : item.totalValue
                }));

                const reducedServices = formatedServices.reduce((acc: any[], curr) => {
                    const currServiceValue = curr.service?.value;
                    const index = acc.findIndex((item) => item.service.value === currServiceValue);
                    const newArray = [...acc];

                    if (index !== -1) {
                        const updatedService = { ...acc[index], totalValue: acc[index].totalValue + curr.totalValue };
                        newArray[index] = updatedService;
                    } else {
                        newArray.push(curr);
                    }

                    return newArray;
                }, []);

                const groupedCosts = getGroupedCosts(reducedServices, selectedServices);

                const formattedThirdExpenses = getExpensesCosts(thirdExpenses, CommercialBudgetCostEnum.ExpensesThirdParties);
                const formattedOtherExpenses = getExpensesCosts(otherExpenses, CommercialBudgetCostEnum.ExpensesOthers);
                const formattedTeamCostProductionExpenses = getExpensesCosts(teamCostProductionExpenses, CommercialBudgetCostEnum.ProductionTeam);

                const managerValue = data.manager?.hourMonth * (data.manager?.hourValue + data.manager?.licenseValue);
                const coordinatorValue = data.coordinator?.hourMonth * (data.manager?.hourValue + data.manager?.licenseValue);

                const managementCosts = getManagementCosts(GEOPValue, coordinatorValue, managerValue, postDeliveryCostValue, contingencyValue, data, projectTypeId);

                const costs: CostItem[] = [
                    ...formattedThirdExpenses,
                    ...formattedOtherExpenses,
                    ...(!!teamCostProductionExpenses.length ? [...formattedTeamCostProductionExpenses] : []),
                    ...managementCosts
                ];

                const payload = {
                    footage: data.footage,
                    deadline: data.deadline,
                    factor: data.factor,
                    date: !!data.date ? formatDate(data.date, 'YYYY-MM-DD') : null,
                    percentageCostAdministrative: data.backoffice,
                    percentageCostTax: data.tax,
                    percentageCommercialExpenses: data.commercialExpense,
                    percentageFinancialExpenses: data.financialExpense,
                    costs,
                    groupedCosts
                };

                await updateBudgetVersion(payload);
            } catch (error) {
                console.log('handleSubmit', error);
            }
        },
        [contractValue, projectTypeId, updateBudgetVersion]
    );

    return (
        <UpdateCommercialBudgetVersion
            onSubmit={handleSubmit}
            contractValueState={contractValueState}
            isSubmitting={isSubmitting}
            isLoading={isLoadingCommercialBudgetVersion}
            defaultValues={defaultValues}
            version={commercialBudgetVersionDetails?.version}
        />
    );
};

export default UpdateCommercialBudgetVersionPage;
