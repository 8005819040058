import React from 'react';

// Dependencies
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

// Components
import Empty from 'components/empty';
import Spinner from 'components/core/spinner';

// Helpers
import useGetTerm from 'services/queries/projects/use-get-term';

const Term = () => {
    const ctx = useOutletContext();
    const { projectProposalTypeId, projectTypeTermId } = useParams();
    const term = useGetTerm(Number(projectProposalTypeId) || 0, projectTypeTermId);

    if (term.isLoading) {
        return <Spinner />;
    }

    if (Boolean(term.error) || !term.data) {
        return <Empty title="Termo não encontrado" />;
    }

    return <Outlet context={{ ...term.data, ctx }} />;
};

export default Term;
