import React, { useEffect, useMemo } from 'react';

// Dependencies
import { number, object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

// Components
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';

// Helpers
import theme from 'settings/theme';
import dictionary from 'utils/dictionary';
import { Profile } from 'types/models/profile';
import { UserPayload } from 'services/queries/users/use-create-user';
import { slugify } from 'utils/string';
import { Option } from 'types/general';

type CreateProps = {
    isLoading: boolean;
    isSubmitting: boolean;
    profiles?: { items: Pick<Profile, 'id' | 'name'>[] };
    title: string;
    onSubmit: (data: any) => void;
};

const schema = object({
    email: string().email(dictionary.validation.invalid.email).required(dictionary.validation.required),
    name: string().required(dictionary.validation.required),
    username: string().required(dictionary.validation.required),
    profile: number().required(dictionary.validation.required)
});

const CreateOrUpdateUser = ({ isLoading, isSubmitting, profiles, title, onSubmit }: CreateProps) => {
    const navigate = useNavigate();

    const handleClose = () => navigate(-1);

    const { control, handleSubmit, formState, watch, setValue } = useForm<UserPayload>({
        defaultValues: {
            name: '',
            email: '',
            username: ''
        },
        mode: 'onSubmit',
        resolver: yupResolver(schema)
    });

    const name = watch('name', '');

    useEffect(() => {
        setValue('username', slugify(name));
    }, [name, setValue]);

    const options = useMemo<Option[]>(() => profiles?.items.map((item) => ({ value: item.id, label: item.name })) || [], [profiles]);

    return (
        <Modal contentClassnames="w-[600px]" onClose={handleClose}>
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {title}
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                        <Controller control={control} name="name" render={({ field }) => <Input {...field} label="Nome" error={formState.errors.name?.message} />} />
                        <Controller control={control} name="username" render={({ field }) => <Input {...field} label="Nome de usuário" disabled={true} error={formState.errors.username?.message} />} />
                        <Controller control={control} name="email" render={({ field }) => <Input {...field} label="E-mail" error={formState.errors.email?.message} />} />
                        <Controller
                            control={control}
                            name="profile"
                            render={({ field }) => {
                                const value = options.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        options={options}
                                        label="Perfil de usuário"
                                        placeholder="Selecione uma opção"
                                        value={value}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateUser;
