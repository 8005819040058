import React, { useState } from 'react';
import Text from 'components/core/text';
import { Task } from 'types/models/task';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import TaskMeta from './task-meta';
import { useNavigate } from 'react-router-dom';
import useProjectTypeSlug from '../../hooks/use-project-type-slug';
import { DragDropContext, Draggable, Droppable, DroppableProvided, DropResult, ResponderProvided } from 'react-beautiful-dnd';

type TaskItemRecursiveProps = {
    identifier?: string;
    tasks: Partial<Task>[];
    timelineId?: number;
    projectId?: number;
    provided?: DroppableProvided;
    canEditTask: boolean;
    onDragEnd?: (result: DropResult, provided: ResponderProvided) => void;
    isProjectBlocked?: boolean;
};

const MAX_CHARS = 37;
const infoButton = { classes: { child: 'text-system-info-100' } };
const warningButton = { classes: { child: 'text-system-warning-100' } };
const secondaryButton = { classes: { child: 'text-secondary-100' } };

const TaskItemRecursive = ({ canEditTask, identifier, provided, projectId, tasks, timelineId, onDragEnd, isProjectBlocked }: TaskItemRecursiveProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { isManagement } = useProjectTypeSlug();
    const [openCodes, setOpenCodes] = useState<string[]>([]);

    const handleToggle = (code: string) => () => {
        const item = openCodes.find((item) => item === code);

        if (item) {
            return setOpenCodes((prev) => prev.filter((item) => item !== code));
        }

        return setOpenCodes((prev) => [...prev, code]);
    };

    const handleGoTo = (path: string) => (event: React.MouseEvent<any, MouseEvent>) => {
        event.stopPropagation();

        navigate(path);
    };

    const handleDragEnd = (result: DropResult, provided: ResponderProvided) => onDragEnd?.(result, provided);

    return (
        <div ref={provided?.innerRef} {...provided?.droppableProps}>
            {tasks.map((task, index) => {
                const hasChildren = Boolean(task.tasks?.length);
                const isOpen = openCodes.includes(task.code || '');

                return (
                    <Draggable isDragDisabled={isProjectBlocked} key={task.id} draggableId={task.id?.toString() || ''} index={index}>
                        {(providedDraggable) => (
                            <div
                                ref={providedDraggable.innerRef}
                                {...providedDraggable.draggableProps}
                                {...providedDraggable.dragHandleProps}
                                className="border border-base-300 rounded-[20px] mb-4 bg-base-100"
                                style={{
                                    ...providedDraggable.draggableProps.style,
                                    margin: `0 0 ${16}px 0`,
                                    userSelect: 'none'
                                }}>
                                <DragDropContext key={task.id} onDragEnd={handleDragEnd}>
                                    <Droppable isDropDisabled={isProjectBlocked} droppableId={`tasks-${task.id}`}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps} className="w-[100%] overflow-x-auto">
                                                {hasChildren ? (
                                                    <div className="w-full px-6 py-4 cursor-pointer overflow-auto" onClick={handleToggle(task.code || '')}>
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex flex-1 items-center">
                                                                <span className="flex items-center justify-center bg-base-200 text-heading rounded-[10px] min-w-[30px] px-2 py-[2px] mr-4">
                                                                    {!!identifier ? `${identifier}.${task.order}` : task.order}
                                                                </span>
                                                                <div className="flex flex-1 items-baseline mr-6">
                                                                    <Text as="h6" variant="h6" className="text-heading mr-1">
                                                                        {(task.title || '').length > MAX_CHARS ? `${task.title?.substring(0, MAX_CHARS)}...` : task.title}
                                                                    </Text>
                                                                    <Text as="span" variant="body.regular.2xs" className="text-base-500">
                                                                        ({task.tasks?.length})
                                                                    </Text>
                                                                    <Icon name={!isOpen ? 'ico-chevron-top' : 'ico-chevron-down'} color={palette.grey[500]} width={16} height={16} className="ml-3" />
                                                                </div>
                                                                <TaskMeta withProgress={isManagement} task={task} />
                                                                {task.late && Boolean(task.endDate) && (
                                                                    <Tooltip title="Esta tarefa está atrasada!" placement="top">
                                                                        <span className="ml-2 text-red-500 rounded bg-red-100 flex items-center justify-center min-w-[25px] h-[25px]">!</span>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                            <Tooltip placement="top" title="Ver tarefa">
                                                                <IconButton
                                                                    TouchRippleProps={infoButton}
                                                                    className="ml-2 hover:bg-primary-100 hover:bg-opacity-20"
                                                                    onClick={handleGoTo(`tarefa/${task.id}?timelineId=${timelineId}`)}>
                                                                    <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {!isManagement && !isProjectBlocked && (
                                                                <Tooltip placement="top" title="Adicionar tarefa">
                                                                    <IconButton
                                                                        TouchRippleProps={secondaryButton}
                                                                        className="hover:bg-secondary-100 hover:bg-opacity-20"
                                                                        onClick={handleGoTo(`tarefa/${task.id}/novo?projectId=${projectId}&timelineId=${timelineId}`)}>
                                                                        <Icon name="ico-plus" width={12} height={12} color={palette.secondary[500]} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            {canEditTask && !isProjectBlocked && (
                                                                <Tooltip placement="top" title="Editar tarefa">
                                                                    <IconButton
                                                                        TouchRippleProps={warningButton}
                                                                        className="hover:bg-system-warning-100 hover:bg-opacity-20"
                                                                        onClick={handleGoTo(`tarefa/${task.id}/editar?projectId=${projectId}&timelineId=${timelineId}`)}>
                                                                        <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="px-6 py-4 flex items-center w-full rounded-[20px] mb-2 bg-base-100 last:mb-0 hover:bg-base-200 hover:bg-opacity-50 cursor-pointer"
                                                        onClick={handleGoTo(`tarefa/${task.id}?timelineId=${timelineId}`)}>
                                                        <div className="flex flex-1 items-center">
                                                            <div className="flex flex-1 items-center">
                                                                <span className="flex items-center justify-center bg-base-200 text-heading rounded-[10px] min-w-[30px] px-2 py-[2px] mr-4">
                                                                    {!!identifier ? `${identifier}.${task.order}` : task.order}
                                                                </span>
                                                                <Text
                                                                    as={!!identifier ? 'p' : 'h6'}
                                                                    variant="body.regular.sm"
                                                                    className={!!identifier ? 'text-heading mr-4' : 'text-xs xl:text-lg font-medium text-heading mr-1'}>
                                                                    {task.title}
                                                                </Text>
                                                            </div>

                                                            <TaskMeta withCounts={true} withProgress={isManagement} task={task} />
                                                            {task.late && Boolean(task.endDate) && (
                                                                <Tooltip title="Esta tarefa está atrasada!" placement="top">
                                                                    <span className="ml-2 text-red-500 rounded bg-red-100 flex items-center justify-center min-w-[25px] min-h-[25px]">!</span>
                                                                </Tooltip>
                                                            )}
                                                            {canEditTask && !isProjectBlocked && (
                                                                <Tooltip placement="top" title="Editar tarefa">
                                                                    <IconButton
                                                                        TouchRippleProps={warningButton}
                                                                        className="hover:bg-system-warning-100 hover:bg-opacity-20 ml-2"
                                                                        onClick={handleGoTo(`tarefa/${task.id}/editar?projectId=${projectId}&timelineId=${timelineId}`)}>
                                                                        <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            {task.canReportProgress && !isProjectBlocked && (
                                                                <Tooltip placement="top" title="Informar progresso">
                                                                    <IconButton
                                                                        TouchRippleProps={warningButton}
                                                                        className="hover:bg-system-warning-100 hover:bg-opacity-20"
                                                                        onClick={handleGoTo(`tarefa/${task.id}/progresso?timelineId=${timelineId}`)}>
                                                                        <Icon name="ico-sort" color={palette.warning.main} width={18} height={18} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {Boolean(task.tasks?.length) && !isOpen && (
                                                    <div className="px-3 sm:px-6 mb-4">
                                                        <TaskItemRecursive
                                                            canEditTask={canEditTask}
                                                            identifier={!!identifier ? `${identifier}.${task.order}` : task.order?.toString()}
                                                            projectId={projectId}
                                                            tasks={task.tasks || []}
                                                            timelineId={timelineId}
                                                            onDragEnd={onDragEnd}
                                                            isProjectBlocked={isProjectBlocked}
                                                        />
                                                    </div>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        )}
                    </Draggable>
                );
            })}
            {provided?.placeholder}
        </div>
    );
};

export default TaskItemRecursive;
