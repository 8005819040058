import qs from 'qs';
import { useQuery } from 'react-query';
import api from 'services/api';
import { ClassificationType } from 'types/models/classification';
import { getPhaseOptionsByClassificationKey } from './graphql';

const useGetPhaseOptionsByClassification = (classificationFatherId: number, hasThirdPart: boolean, isComplementaryProject: boolean, projectServices?: number[]) => {
    const request = async () => {
        const params = {
            filter: {
                type: isComplementaryProject ? ClassificationType.ScopeComplementaryProjects : ClassificationType.Scope,
                classificationFather: classificationFatherId,
                ...(Boolean(projectServices) && hasThirdPart && { projectServices })
            }
        };

        const query = qs.stringify(params, { addQueryPrefix: true, encode: false, arrayFormat: 'brackets' });

        return api.get<any[]>(`/classifications${query}`).then(({ data }) => data);
    };

    return useQuery(getPhaseOptionsByClassificationKey(classificationFatherId, projectServices), request, { enabled: Boolean(classificationFatherId) });
};

export default useGetPhaseOptionsByClassification;
