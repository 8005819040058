import { useMemo } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { generateFileUrl } from 'utils/file';
import Avatar from '@mui/material/Avatar/Avatar';
import { CardHeader } from '@mui/material';
import hexToRgba from 'utils/hex-to-rgba';
import Icon from 'components/core/icon';
import { Option } from 'types/general';
import { useOutletContext } from 'react-router-dom';
import _get from 'lodash/get';
import useGetProviderProjectsAmount from 'services/queries/providers/use-get-provider-projects-amount';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';
import { getLabelNameByPersonContractType } from 'components/company/utils';
import { Provider } from 'types/models/provider';
import { Company } from 'types/models/company';
import { CamelCase } from 'types/utils';
import { PersonContractType } from 'types/models/person';
import { Classification } from 'types/models/classification';
import ProviderDetail from './detail';
import Text from 'components/core/text';
import SimpleTable from 'components/core/table/simple';
import Card from 'components/core/card';

type ProjectTabDetailsOutletProps = CamelCase<Partial<Company> & Partial<Provider>> & {
    personalDocument: string;
    type: PersonContractType;
    classifications: Classification[];
};

const headerTitleServicesType = [{ label: 'Classificação' }, { label: 'Classificação pai' }];
const headerTitleContacts = [{ label: 'Nome' }, { label: 'Email' }, { label: 'Telefone(s)' }];

const ProjectTabDetails = () => {
    const ctx = useOutletContext<ProjectTabDetailsOutletProps>();
    const { palette } = useTheme();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const { data: dataAmounts, isLoading: isAmountLoading } = useGetProviderProjectsAmount(ctx?.id);

    const amounts = [
        {
            label: 'Total de projetos',
            value: dataAmounts?.all,
            icon: 'ico-tasks-outline',
            variant: 'info'
        },
        {
            label: 'Total de projetos ativos',
            value: dataAmounts?.active,
            icon: 'ico-tasks-check',
            variant: 'success'
        }
    ];

    const details: Option<any>[] = [
        {
            label: 'Título',
            value: ctx?.title
        },
        ...(Boolean(ctx?.juridicName) ? [{ label: 'Nome jurídico', value: ctx.juridicName }] : []),
        ...(Boolean(ctx?.juridicFantasyName) ? [{ label: 'Nome fantasia', value: ctx.juridicFantasyName }] : []),
        ...(Boolean(ctx?.juridicDocument) ? [{ label: 'CNPJ', value: ctx.juridicDocument }] : []),
        ...(Boolean(ctx?.personalDocument) ? [{ label: 'CPF', value: ctx.personalDocument }] : []),
        ...(Boolean(ctx?.type) ? [{ label: 'Tipo', value: getLabelNameByPersonContractType(ctx.type) }] : []),
        {
            label: 'Tipo de negócio',
            value: ctx?.typeBusiness?.title
        },
        {
            label: 'Parceiro',
            value: ctx?.partner ? 'Sim' : 'Não'
        }
    ];

    const formatAddress = useMemo(() => {
        if (ctx?.address) {
            const { street, number, state, city, neighbourhood, zip } = ctx.address;

            return <Text as="p">{`${[street, number, neighbourhood, city?.name].join(', ')} - ${state?.initials}, ${zip}`}</Text>;
        }

        return;
    }, [ctx?.address]);

    const dataContacts = (ctx?.contacts || []).map((item, index) => ({
        id: index,
        contents: [
            { label: item.person?.name || item.person?.title || '-' },
            { label: item.person?.email || '-' },
            { label: item.person?.phones?.map(({ ddd = '00', number = '00000-0000' }) => `(${ddd}) ${number}`).join(', ') || '-' }
        ]
    }));

    const dataServicesType = (ctx?.classifications || []).map((item, index) => ({
        id: index,
        contents: [{ label: item.name || '-' }, { label: item.father?.name || '-' }]
    }));

    return (
        <>
            <div>
                {isSmallerThenTabletLandscape && <Breadcrumb className="mb-8" />}
                <div className="mb-7">
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: '#FFF', borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                {!!ctx?.image && <img src={generateFileUrl(ctx.image?.filename, ctx.image?.path)} />}
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title={ctx?.title}
                        subheader={ctx?.juridicName}
                        titleTypographyProps={{ fontSize: 18, color: palette.heading, fontWeight: 500 }}
                    />
                </div>

                <div className="grid gap-4 grid-cols-1 sm:grid-cols-3 mb-6">
                    {amounts.map(({ value, label, icon, variant }, index) => {
                        const variantColors = _get(palette, variant);

                        const backgroundColor = hexToRgba(variantColors[100], 0.3);
                        const iconColor = variantColors[800];

                        return (
                            <CardHeader
                                key={`${label}-${index}`}
                                avatar={
                                    <Avatar sx={{ bgcolor: backgroundColor, borderRadius: '14px !important', width: 48, height: 48 }}>
                                        <Icon name={icon} color={iconColor} />
                                    </Avatar>
                                }
                                classes={{ root: 'p-0' }}
                                title={label}
                                subheader={isAmountLoading ? <div className="animate-pulse bg-base-300 h-[20px] w-[25px]"></div> : value}
                                titleTypographyProps={{ color: palette.grey[500] }}
                                subheaderTypographyProps={{ color: palette.heading }}
                            />
                        );
                    })}
                </div>

                <Text as="p" className="uppercase mb-3 !text-base text-gray-600">
                    Detalhes
                </Text>
                <div className="grid grid-cols-1 sm:grid-cols-3 mb-10 gap-4">
                    {details.map(({ label, value }, index) => (
                        <ProviderDetail key={`provider_detail_with_label_${index}`} label={label} value={value} />
                    ))}
                </div>

                {Boolean(ctx?.address) && (
                    <>
                        <Text as="p" className="uppercase mb-3 text-gray-600">
                            Endereço
                        </Text>
                        <div className="mb-10 gap-4 text-sm" style={{ color: palette.heading }}>
                            {formatAddress}
                        </div>
                    </>
                )}
            </div>
            <div className="mt-5 flex flex-col gap-4">
                <Card>
                    <Text as="p" className="px-4 my-3 text-heading font-medium !text-base">
                        Contato(s)
                    </Text>
                    <SimpleTable columns={headerTitleContacts} items={dataContacts} />
                </Card>
                <Card>
                    <Text as="p" className="px-4 my-3 text-heading font-medium !text-base">
                        Tipos de serviços
                    </Text>
                    <SimpleTable columns={headerTitleServicesType} items={dataServicesType} />
                </Card>
            </div>
        </>
    );
};

export default ProjectTabDetails;
