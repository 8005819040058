// Helpers
import { User } from './user';
import { BaseModel } from './base';

export type Notification = {
    user_id: number;
    page_uri: string;
    is_read: boolean;
    type: NotificationType;
    text: string;
    entity: string;
    entity_id: number;
    subject: string;
    user: Partial<User>;
} & BaseModel;

export enum NotificationType {
    TapNew = 1,
    CisoManager
}
