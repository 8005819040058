import { useMemo } from 'react';

import Table from '@mui/material/Table/Table';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import IconButton from '@mui/material/IconButton/IconButton';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import { formatDate } from 'utils/date';
import AddButton from 'components/buttons/add';
import useTheme from '@mui/material/styles/useTheme';
import { TimelineResponse } from 'services/queries/projects/use-get-timelines';
import { ProjectTypeStatus } from 'types/models/project';
import { Link, useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import Badge from 'components/core/badge';
import { ColorVariant } from 'types/colors';
import Empty from 'components/empty';
import useProjectTypeSlug from '../../hooks/use-project-type-slug';
import { TimelineStatusCreation } from 'types/models/timeline';

type TimelineListProps = {
    withActions: boolean;
    withTitle: boolean;
    timelines?: TimelineResponse['timeline'];
    projectTypeStatus?: ProjectTypeStatus;
    className?: string;
};

const getVariant = (progress: number): { variant: ColorVariant; label: string } => {
    if (!progress) {
        return {
            variant: 'grey',
            label: 'Aguardando início'
        };
    }

    if (progress < 100) {
        return {
            variant: 'info',
            label: 'Em andamento'
        };
    }

    return {
        variant: 'success',
        label: 'Finalizado'
    };
};

const infoButton = { classes: { child: 'text-system-info-100' } };
const warningButton = { classes: { child: 'text-system-warning-100' } };
const dangerButton = { classes: { child: 'text-system-danger-100' } };

const TimelineList = ({ projectTypeStatus, timelines, withTitle, withActions, className }: TimelineListProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { isManagement } = useProjectTypeSlug();

    const isConstructionStarted = useMemo(() => projectTypeStatus === ProjectTypeStatus.Started, [projectTypeStatus]);

    const handleGoTo = (path: string) => () => navigate(path);

    const label = isManagement ? 'cronograma' : 'planejamento de tarefas';

    return (
        <>
            {withTitle && (
                <Text as="h6" variant="h6" className="text-heading mb-4">
                    {isManagement ? 'Cronogramas' : 'Planejamento de tarefas'}
                </Text>
            )}
            {Boolean(timelines?.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Fornecedor
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Data de início
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Data final
                                    </Text>
                                </TableCell>
                                {isConstructionStarted && (
                                    <>
                                        <TableCell>
                                            <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                                Progresso
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                                Status
                                            </Text>
                                        </TableCell>
                                    </>
                                )}
                                {withActions && (
                                    <TableCell className="w-[160px]">
                                        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                            Ações
                                        </Text>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {timelines?.map((item) => {
                                const progress = getVariant(item.progress_percentage || 0);

                                return (
                                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {item.provider?.title || '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {formatDate(item.start_date || '')}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {formatDate(item.end_date || '')}
                                            </Text>
                                        </TableCell>
                                        {isConstructionStarted && (
                                            <>
                                                <TableCell>
                                                    <div className="flex items-center">
                                                        <LinearProgress
                                                            color="primary"
                                                            value={item.progress_percentage}
                                                            variant="determinate"
                                                            classes={{ root: 'h-[6px] rounded-[3px] bg-base-300 flex-1', bar: 'rounded-[3px]' }}
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Badge variant={progress.variant}>{progress.label}</Badge>
                                                </TableCell>
                                            </>
                                        )}
                                        {withActions && (
                                            <TableCell>
                                                <div className="flex">
                                                    <Tooltip placement="top" title="Visualizar">
                                                        <IconButton TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20" onClick={handleGoTo(`${item.id}`)}>
                                                            <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Visualizar cronograma em gráfico">
                                                        <IconButton
                                                            TouchRippleProps={infoButton}
                                                            className="hover:bg-system-success-100 hover:bg-opacity-20"
                                                            onClick={handleGoTo(`${item.id}/cronograma`)}>
                                                            <Icon name="ico-chart" width={16} height={16} color={palette.success.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                        <span>
                                                            <IconButton
                                                                component={Link}
                                                                disabled={item.status_creation === TimelineStatusCreation.Finalized}
                                                                TouchRippleProps={warningButton}
                                                                className="hover:bg-system-warning-100 hover:bg-opacity-30 disabled:bg-base-100"
                                                                to={`${item.id}/editar`}>
                                                                <Icon
                                                                    name="ico-edit"
                                                                    width={16}
                                                                    height={16}
                                                                    color={item.status_creation === TimelineStatusCreation.Finalized ? palette.grey[400] : palette.warning.main}
                                                                />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                                        <span>
                                                            <IconButton
                                                                disabled={isConstructionStarted || item.status_creation === TimelineStatusCreation.Finalized}
                                                                TouchRippleProps={dangerButton}
                                                                className="hover:bg-system-danger-100 hover:bg-opacity-30 disabled:bg-base-100"
                                                                onClick={() => alert('Em desenvolvimento')}>
                                                                <Icon
                                                                    name="ico-trash"
                                                                    width={16}
                                                                    height={16}
                                                                    color={isConstructionStarted || item.status_creation === TimelineStatusCreation.Finalized ? palette.grey[400] : palette.error.main}
                                                                />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {withActions && (
                            <TableFooter>
                                <TableRow>
                                    <TableCell classes={{ root: 'py-2 border-none' }} colSpan={3}>
                                        <AddButton onClick={handleGoTo('novo')}>Novo {isManagement ? 'cronograma' : 'planejamento de tarefa'}</AddButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </TableContainer>
            ) : (
                <Empty className={className} title={`Nenhum ${label} cadastrado.`} buttonLabel={withActions ? `Criar ${label}` : undefined} onClick={withActions ? handleGoTo('novo') : undefined} />
            )}
        </>
    );
};

export default TimelineList;
