import React, { useCallback, useContext, useEffect } from 'react';
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'components/core/modal';
import { RequestContext } from 'contexts/request';
import RequestDetails from './details';
import { RequestSteps } from 'contexts/request/types';
import RequestFiles from './files';
import RequestAttentionPoints from './attention-points';
import { RequestTabCommonProps } from './types';
import { CamelCase } from 'types/utils';
import { ProjectTypeSolicitation } from 'types/models/project';

type SolicitationCreateOrUpdateProps = {
    solicitation?: CamelCase<ProjectTypeSolicitation>;
};

const SolicitationCreateOrUpdate = ({ solicitation }: SolicitationCreateOrUpdateProps) => {
    const navigate = useNavigate();
    const params = useParams<Omit<RequestTabCommonProps, 'solicitation'>>();

    const { state, changeStep, completeMultipleSteps, setContent } = useContext(RequestContext);

    useEffect(() => {
        if (solicitation) {
            const content = {
                subject: solicitation.subject,
                observations: solicitation.observations,
                description: solicitation.description,
                files: solicitation.files,
                classifications: solicitation.classifications?.map((item) => item.id) || [],
                attentionPoints: [...solicitation.criticalAttentionPoints, ...solicitation.relevantAttentionPoints],
                projectType: solicitation.projectType
            };

            setContent(content, false);
        }

        // eslint-disable-next-line
    }, [solicitation]);

    useEffect(() => {
        if (solicitation) {
            completeMultipleSteps([
                {
                    slug: RequestSteps.Details,
                    label: 'Detalhes'
                },
                {
                    slug: RequestSteps.Files,
                    label: 'Arquivos'
                },
                {
                    slug: RequestSteps.AttentionPoints,
                    label: 'Pontos de atenção'
                }
            ]);
        }
        // eslint-disable-next-line
    }, [solicitation]);

    const getComponent = (slug: RequestSteps) => {
        const items = new Map<RequestSteps, any>([
            [RequestSteps.Details, RequestDetails],
            [RequestSteps.Files, RequestFiles],
            [RequestSteps.AttentionPoints, RequestAttentionPoints]
        ]);

        const Component = items.get(slug);

        return <Component {...params} solicitation={state.content} /> ?? null;
    };

    const handleClose = () => navigate(-1);

    const handleChangeTab = useCallback((_: any, nextSlug: any) => changeStep(state.currentStep.slug, nextSlug), [state.currentStep.slug, changeStep]);

    return (
        <Modal contentClassnames="w-[800px]" closeOnClickOutside={false} onClose={handleClose}>
            <div className="pt-4">
                <Tabs classes={{ root: 'px-3 sm:px-4' }} indicatorColor="secondary" value={state.currentStep.slug} onChange={handleChangeTab}>
                    {state.steps.map((item, index) => {
                        const isDisabled = !state.completeds.find((step) => step.slug === item.slug);

                        return <Tab value={item.slug} disabled={isDisabled} key={`${item.slug}_${index}`} label={item.label.toUpperCase()} />;
                    })}
                </Tabs>
                {getComponent(state.currentStep.slug)}
            </div>
        </Modal>
    );
};

export default SolicitationCreateOrUpdate;
