import camelCase from 'lodash/camelCase';
import { Option } from 'types/general';
import dictionary from './dictionary';

export const convertEnumToOptions = <T>(enumValue: T, dictionaryKey?: string): Option[] => {
    const arr: Option[] = [];

    Object.keys(enumValue as any)
        .filter((key) => isNaN(+key))
        .forEach((key) => {
            arr.push({
                value: enumValue[key],
                label: Boolean(dictionaryKey) ? dictionary.content[dictionaryKey!][camelCase(key)] : dictionary.content[camelCase(key)]
            });
        });

    return arr;
};
