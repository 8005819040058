import React from 'react';

// Dependencies
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';

// Components
import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import AddButton from 'components/buttons/add';
import Input from 'components/core/form/input';

// Helpers
import hexToRgba from 'utils/hex-to-rgba';
import Select from 'components/core/form/select';
import selectStyles from 'components/core/form/select/styles';

type Props = {
    accessor?: string;
    index: number;
    className?: string;
} & UseFormReturn<any, any>;

export const defaultEmail = {
    type: 1,
    email: ''
};

const emailTypeOptions = [
    {
        label: 'Pessoal',
        value: 1
    },
    {
        label: 'Profissional',
        value: 2
    }
];

const Emails = ({ accessor = 'external', control, formState, index, className }: Props) => {
    const { palette } = useTheme();

    const { fields, append, remove } = useFieldArray({ name: `${accessor}.${index}.emails`, control });

    const handleAdd = () => append(defaultEmail);

    const handleDelete = (index: number) => () => remove(index);

    return (
        <div className={className}>
            <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                Email(s)
            </Text>
            {Boolean(fields.length) ? (
                fields.map((item, emailIndex) => {
                    return (
                        <div key={item.id} className="flex relative mb-2">
                            <div className="relative flex-1">
                                <div className="absolute right-[10px] top-[50%] -mt-[18px]">
                                    <IconButton
                                        size="large"
                                        type="button"
                                        color="error"
                                        sx={{ backgroundColor: palette.grey[100], '&:hover': { backgroundColor: hexToRgba(palette.error[100], 0.5) } }}
                                        onClick={handleDelete(emailIndex)}>
                                        <Icon name="ico-trash" width={12} height={12} color={palette.error[500]} />
                                    </IconButton>
                                </div>

                                <Controller
                                    name={`${accessor}.${index}.emails.${emailIndex}.email`}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="text"
                                            autoFocus={false}
                                            error={formState.errors?.[accessor]?.[index]?.phones?.[emailIndex]?.number?.message}
                                            inpuWrapperClasses="rounded-br-none rounded-tr-none"
                                        />
                                    )}
                                />
                            </div>

                            <Controller
                                name={`${accessor}.${index}.emails.${emailIndex}.type`}
                                control={control}
                                render={({ field }) => {
                                    const value = emailTypeOptions.find((item) => item.value === ~~field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={emailTypeOptions}
                                            placeholder="Selecione uma opção"
                                            error={formState.errors?.[accessor]?.[index]?.phones?.[emailIndex]?.type?.message}
                                            onChange={(option: any) => field.onChange(option.value)}
                                            parentClassName="sm:w-[200px]"
                                            selectStyles={{
                                                control: (base, data) => ({
                                                    ...selectStyles('medium', Boolean(formState.errors?.[accessor]?.[index]?.phones?.[emailIndex]?.type?.message)).control!(base, data),
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0,
                                                    borderLeft: 0
                                                })
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhum email adicionado até o momento" />
            )}
            <div className="py-2 -mt-2">
                <AddButton onClick={handleAdd}>Mais emails</AddButton>
            </div>
        </div>
    );
};

export default Emails;
