import React from 'react';

// Dependencies
import Table from '@mui/material/Table/Table';
import { useNavigate } from 'react-router-dom';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';

// Components
import Empty from 'components/empty';
import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';

// Helpers
import { Timeline } from 'types/models/timeline';
import ChecklistItem from './item';
import { Option } from 'types/general';

type ChecklistProps = {
    timeline?: Timeline;
    statuses: Option[];
};

const Checklist = ({ timeline, statuses }: ChecklistProps) => {
    const navigate = useNavigate();

    const handleBack = () => navigate(-1);

    if (!timeline) {
        return null;
    }

    return (
        <Modal
            contentClassnames="sm:min-w-[1000px]"
            closeOnClickOutside={false}
            headerLeft={
                <Text as="h3" variant="h4" className="text-heading">
                    Checklist de start de obra
                </Text>
            }
            onClose={handleBack}>
            {Boolean(timeline.tasks.length) ? (
                <div className="p-4">
                    <Table classes={{ root: 'relative' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell classes={{ root: 'sticky left-0 top-0 bg-base-200 z-10' }}>
                                    <Text as="span" className="text-primary-500">
                                        Item
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'sticky left-0 top-0 bg-base-200 z-10' }}>
                                    <Text as="span" className="text-primary-500">
                                        Nome
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'sticky left-0 top-0 bg-base-200 z-10' }}>
                                    <Text as="span" className="text-primary-500">
                                        Status
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'sticky left-0 top-0 bg-base-200 z-10' }}>
                                    <Text as="span" className="text-primary-500">
                                        Observação
                                    </Text>
                                </TableCell>
                                <TableCell classes={{ root: 'sticky left-0 top-0 bg-base-200 z-10' }}>
                                    <Text as="span" className="text-primary-500">
                                        Ações
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {timeline.tasks.map((task) => {
                                return <ChecklistItem statuses={statuses} task={task} key={task.id} />;
                            })}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div className="px-6">
                    <div className="border border-base-300 rounded-xl p-4 mb-4">
                        <Empty title="Nenhuma tarefa encontrada" />
                    </div>
                </div>
            )}
            <div className="flex items-center justify-between py-4 px-6">
                <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                    Fechar
                </Button>
            </div>
        </Modal>
    );
};

export default Checklist;
