import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { ProviderFinancialPaymentType } from 'types/models/provider';

export const getFinancialMeasurementByPk = (measurementId: number) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetFinancialMeasurementByPk',
                provider_financial_measurement_by_pk: {
                    __args: { id: measurementId },
                    id: true,
                    expected_date: true,
                    measurement_date: true,
                    measurement_percentage: true,
                    measurement_value: true,
                    order_measurement: true,
                    predicted_percentage: true,
                    predicted_value: true,
                    provider_financial_setup: {
                        timeline_budget: {
                            timeline: {
                                progress_percentage: true
                            }
                        }
                    },
                    provider_financial_measurement_has_payments: {
                        __args: {
                            where: {
                                provider_financial_payment: {
                                    type: { _eq: ProviderFinancialPaymentType.DirectBilling }
                                }
                            }
                        },
                        provider_financial_payment: {
                            date: true,
                            due_date: true,
                            forecast_date: true,
                            invoice_number: true,
                            issue_date: true,
                            observations: true,
                            percentage: true,
                            provider_document: true,
                            provider_name: true,
                            receipt_date: true,
                            sent_date: true,
                            value: true,
                            type: true
                        }
                    }
                }
            }
        },
        { pretty: true }
    );
};
