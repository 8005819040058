import useGetBranchesOptions from 'services/queries/branches/use-get-branches-options';
import Select from 'components/core/form/select';
import { Option } from 'types/general';
import useGetClassificationsOptions from 'services/queries/configs-classifications/use-get-classifications-options';
import { ClassificationType } from 'types/models/classification';
import { useEffect } from 'react';
import { yearsOptions } from '../utils';

type State = [number | undefined, React.Dispatch<React.SetStateAction<number | undefined>>];

type FiltersProps = {
    branchState: State;
    yearState: State;
    departmentState: State;
};

const Filters = ({ branchState, yearState, departmentState }: FiltersProps) => {
    const [branch, setBranch] = branchState;
    const [year, setYear] = yearState;
    const [department, setDepartment] = departmentState;

    const { data: branches = [], isLoading: isLoadingBranches, isSuccess: isSuccessBranch } = useGetBranchesOptions();
    const { data: departments = [], isLoading: isLoadingDepartments, isSuccess: isSuccessDepartments } = useGetClassificationsOptions(ClassificationType.Departament);

    const getValue = (options: Option[], value?: number) => options?.find((item) => item?.value === value);

    useEffect(() => {
        if (isSuccessBranch && branches?.[0]?.value) {
            setBranch(branches[0]?.value);
        }
    }, [isSuccessBranch, branches, setBranch]);

    useEffect(() => {
        if (isSuccessDepartments && departments?.[0]?.value) {
            setDepartment(departments?.[0]?.value);
        }
    }, [isSuccessDepartments, departments, setDepartment]);

    return (
        <div className="flex flex-wrap items-end gap-2">
            <Select
                isLoading={isLoadingBranches}
                value={getValue(branches, branch)}
                options={branches}
                label="Filial"
                placeholder="Selecione uma opção"
                onChange={(option: any) => setBranch(option.value)}
            />
            <Select
                isLoading={isLoadingDepartments}
                value={getValue(departments, department)}
                options={departments}
                label="Departamento"
                placeholder="Selecione uma opção"
                onChange={(option: any) => setDepartment(option.value)}
                parentClassName="min-w-[160px]"
            />
            <div className="w-[100px]">
                <Select value={getValue(yearsOptions, year)} options={yearsOptions} label="Ano" placeholder="Selecione" onChange={(option: any) => setYear(option.value)} />
            </div>
        </div>
    );
};

export default Filters;
