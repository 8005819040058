import { useState } from 'react';

import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { AttentionPoint } from 'types/models/report';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import { formatDate } from 'utils/date';
import Empty from 'components/empty';
import ExternalButton from 'components/buttons/external';
import { useParams } from 'react-router-dom';

type AttentionPointsProps = {
    data: AttentionPoint[];
};

const AttentionPointsTable = ({ data }: AttentionPointsProps) => {
    const [description, setDescription] = useState<string>();

    const { projectTypeSlug, projectProposalTypeId } = useParams();

    return (
        <>
            {Boolean(data.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Solicitação</TableCell>
                                <TableCell>Tarefa</TableCell>
                                <TableCell>Atribuída a</TableCell>
                                <TableCell>Data Fim</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => {
                                const solicitationUrl = `/app/operacional/${projectTypeSlug}/${projectProposalTypeId}/solicitacoes/${item.solicitation.id}`;
                                const taskUrl = `/app/operacional/${projectTypeSlug}/${projectProposalTypeId}/solicitacoes/${item.solicitation.id}/tarefas/${item.task.id}`;

                                return (
                                    <TableRow sx={{ '&:last-child td': { border: 0 } }} key={index}>
                                        <TableCell>
                                            <ExternalButton href={`${window.location.origin}${solicitationUrl}?tab=ATTENTION_POINTS`}>{item.solicitation?.subject}</ExternalButton>
                                        </TableCell>
                                        <TableCell>
                                            <ExternalButton href={window.location.origin + taskUrl}>{item.task.title}</ExternalButton>
                                        </TableCell>
                                        <TableCell>{item.task.person?.title}</TableCell>
                                        <TableCell>{formatDate(item.task.deadline)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Empty title="Nenhum ponto de atenção encontrado" className="p-4" />
            )}
            {Boolean(description) && (
                <Modal contentClassnames="py-5 px-7 w-[500px]" onClose={() => setDescription(undefined)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: description! }} />
                </Modal>
            )}
        </>
    );
};

export default AttentionPointsTable;
