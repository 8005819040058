import { useCallback, useMemo } from 'react';
import { HeaderText } from '..';
import { months } from 'utils/date';
import { getTotalWeeksInYear } from '../utils';

type Props = {
    year: number;
};

export const useCalendarHeaderTexts = ({ year }: Props) => {
    const getMonthsTexts = useCallback(({ years }: { years: number[] }) => {
        let result: HeaderText[] = [];

        years
            .map((year) =>
                months.map((month, index) => {
                    const totalWeeks = getTotalWeeksInYear({
                        year: Number(year),
                        month: index
                    });

                    return {
                        year,
                        title: month?.toUpperCase(),
                        monthNumber: index + 1,
                        totalWeeks
                    };
                })
            )
            .forEach((year) => {
                result = [...result, ...year];
            });

        return result;
    }, []);

    const headerTexts = useMemo<HeaderText[]>(() => {
        const years = [year];

        return getMonthsTexts({ years });
    }, [year, getMonthsTexts]);

    return headerTexts;
};
