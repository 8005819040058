import classNames from 'classnames';
import { DAY_HEIGHT } from '../../utils';

type PeopleMapPerMonthMonthsProps = {
    months: string[];
};

const Months = ({ months }: PeopleMapPerMonthMonthsProps) => {
    return (
        <div className={`flex divide-x divide-slate-200 border-b border-slate-200 font-medium text-[#6d7178]`}>
            {months.map((month) => {
                return (
                    <div
                        key={`month_${month}`}
                        style={{ height: DAY_HEIGHT + 10 }}
                        className={classNames('flex flex-col items-center justify-center text-xs text-center min-w-[130px] sm:min-w-[70px] flex-auto md:flex-1', month === 'Total' && 'font-bold')}>
                        {month}
                    </div>
                );
            })}
        </div>
    );
};

export default Months;
