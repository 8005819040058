import React, { useCallback } from 'react';
import Text from 'components/core/text';
import Dropzone from 'components/dropzone';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Empty from 'components/empty';
import { Accept } from 'react-dropzone';
import { FileApiModel } from 'types/models/file';
import classNames from 'classnames';
import FileRow from 'components/files-row';

type FilesProps = {
    label: string;
    hook: [Partial<File & FileApiModel>[], React.Dispatch<React.SetStateAction<Partial<File & FileApiModel>[]>>];
    accept?: Accept;
    className?: string;
    multiple?: boolean;
};

const Files = ({ accept, label, hook, className, multiple = true }: FilesProps) => {
    const [files, setFiles] = hook;

    const handleUploadFiles = (filesList: File[]) => setFiles([...files, ...filesList]);

    const handleRemoveFromList = useCallback(
        (originalFileName: string) => {
            const filtered = files.filter((item) => {
                return (item.originalName || item.path) !== originalFileName;
            });

            return setFiles(filtered);
        },
        [files, setFiles]
    );

    const handlePersistFile = (file: FileApiModel) => {
        setFiles((prev) => {
            return prev.map((item) => ({
                ...item,
                ...(item.path === file.originalName && { ...file, id: file.id })
            }));
        });
    };

    const containerClasses = classNames('mb-9', className);

    return (
        <div className={containerClasses}>
            <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                {label}
            </Text>
            <Dropzone accept={accept} multiple={multiple} onUploadFiles={handleUploadFiles} />
            {Boolean(files.length) && (
                <Table classes={{ root: 'mt-6' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Tipo
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Arquivo
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className="text-secondary-500">
                                    Status
                                </Text>
                            </TableCell>
                            <TableCell width={90}>
                                <Text as="span" className="text-secondary-500">
                                    Ações
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(files.length) ? (
                            files.map((file, index) => {
                                return <FileRow key={`${file.path}-${index}`} file={file} onPersistFile={handlePersistFile} onRemoveFromList={handleRemoveFromList} />;
                            })
                        ) : (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={4}>
                                    <Empty title="Nenhum arquivo adicionado até o momento." />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

export default Files;
