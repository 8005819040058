import React, { useState } from 'react';
import theme from 'settings/theme';
import Empty from 'components/empty';
import Text from 'components/core/text';
import { UseQueryResult } from 'react-query';
import Spinner from 'components/core/spinner';
import type { AttentionPoints as AttentionPointsType } from 'types/models/report';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import AttentionPointsTable from './table';

type TabValue = 'RELEVANT' | 'CRITICAL';

const AttentionPoints = ({ isLoading, isError, data: attentionPoints }: UseQueryResult<AttentionPointsType, unknown>) => {
    const [tab, setTab] = useState<TabValue>('RELEVANT');

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    return (
        <div className="border border-base-300 rounded-[14px] mb-4">
            <Text as="h6" variant="h6" className="p-4 text-heading">
                Pontos de atenção
            </Text>
            {isLoading ? (
                <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} className="min-h-[100px]" />
            ) : isError ? (
                <Empty title="Dados não encontrados" className="px-4" />
            ) : (
                <>
                    <Tabs classes={{ root: 'bg-base-100 z-[10]' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                        <Tab value="RELEVANT" label="Relevantes" />
                        <Tab value="CRITICAL" label="Críticos" />
                    </Tabs>
                    {tab === 'RELEVANT' && <AttentionPointsTable data={attentionPoints?.relevant || []} />}
                    {tab === 'CRITICAL' && <AttentionPointsTable data={attentionPoints?.critical || []} />}
                </>
            )}
        </div>
    );
};

export default AttentionPoints;
