import React, { useMemo } from 'react';

// Dependencies
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import ButtonBase from '@mui/material/ButtonBase/ButtonBase';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import TimeAgo from 'components/timeago';

// Helpers
import { Notification, NotificationType } from 'types/models/notification';
import useReadNotification from 'services/queries/notifications/use-read-notification';

type NotificationItemProps = {
    onCloseMenu: () => void;
} & Partial<Notification>;

const NotificationItem = ({ id, subject, text, created_at, page_uri, is_read, type, onCloseMenu }: NotificationItemProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();

    const buttonClasses = classNames('w-full px-3 py-2 bg-base-100 hover:bg-base-200 flex rounded-[14px] justify-[unset]', !is_read && 'bg-base-200');

    const { mutateAsync: readNotification } = useReadNotification(id!);

    const handleClick = async () => {
        try {
            await readNotification({ is_read: true });
        } catch (error) {
            console.log('error', error);
        } finally {
            const newUrl = page_uri?.includes('task') ? `/app/${page_uri.replace('task', 'tarefas')}` : `/app/${page_uri}`;

            navigate(!page_uri?.includes('app') ? newUrl : `/${page_uri!}`);
            onCloseMenu();
        }
    };

    const icon = useMemo(() => {
        const icons = new Map<NotificationType, any>([
            [NotificationType.TapNew, 'ico-travel'],
            [NotificationType.CisoManager, 'ico-travel']
        ]);

        return icons.get(type!) ?? null;
    }, [type]);

    return (
        <Tooltip
            title={
                <>
                    {subject}
                    <br />
                    {text}
                </>
            }
            placement="left">
            <ButtonBase className={buttonClasses} onClick={handleClick}>
                {icon && (
                    <div className="mr-4 bg-primary-100 bg-opacity-20 rounded-[14px] h-10 basis-10 shrink-0 flex items-center justify-center">
                        <Icon name={icon} width={16} height={16} color={palette.primary.main} />
                    </div>
                )}
                <div className="grow-[1] min-w-0">
                    <Text as="span" variant="body.regular.sm" className="text-heading block whitespace-nowrap text-ellipsis overflow-hidden">
                        {subject}
                    </Text>
                    <Text as="span" variant="body.regular.xs" className="text-base-500 block whitespace-nowrap text-ellipsis overflow-hidden italic">
                        {text}
                    </Text>
                    {created_at && (
                        <Text as="span" variant="body.regular.xs" className="text-base-400 block whitespace-nowrap text-ellipsis overflow-hidden mt-2">
                            <TimeAgo date={created_at} />
                        </Text>
                    )}
                </div>
                {!is_read && <div className="bg-secondary-500 h-[6px] rounded-full basis-[6px] shrink-0 ml-2" />}
            </ButtonBase>
        </Tooltip>
    );
};

export default NotificationItem;
