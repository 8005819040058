import { useEffect, useMemo, useState } from 'react';
import PageHeader from 'components/page/header';
import TasksColumn from './column';
import Button from 'components/core/button';
import Icon from 'components/core/icon';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import useGetTaskStatuses from 'services/queries/task-dashboard/use-get-task-statuses';
import useUpdateTaskDashboardStatus from 'services/queries/task-dashboard/use-update-task-dashboard-status';
import Spinner from 'components/core/spinner';
import { Outlet, useLocation } from 'react-router-dom';
import TasksFilter from './filter';

export type TaskFilters = {
    title: string;
    customer: string;
    initialPeriodStartDate: string;
    initialPeriodEndDate: string;
    finalPeriodStartDate: string;
    finalPeriodEndDate: string;
    project?: string;
};

const TasksPage = () => {
    const { isLoading, data: statuses } = useGetTaskStatuses();

    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const { mutate: updateTaskStatus, isLoading: isLoadingUpdate } = useUpdateTaskDashboardStatus();

    useEffect(() => {
        // removing the container spacing so that the calendar sidebar
        // can be have the height of the screen
        const [outletContainer]: any = document.getElementsByClassName('outlet') || [];

        if (outletContainer) {
            outletContainer.style = `
            height: 100%;
            flex: 1;
            `;
        }

        return () => {
            if (outletContainer) {
                outletContainer.removeAttribute('style');
            }
        };
    }, []);

    const handleDragEnd = (event: DropResult) => {
        const { source, destination, draggableId } = event;

        // dropped outside any list
        if (!destination) {
            return;
        }

        const payload = {
            source: {
                statusId: ~~source.droppableId,
                taskId: ~~draggableId,
                index: ~~source.index
            },
            destination: {
                statusId: ~~destination.droppableId,
                index: ~~destination.index
            }
        };

        updateTaskStatus(payload);
    };

    const { search } = useLocation();

    const filters = useMemo(() => {
        if (!search?.length) {
            return undefined;
        }

        return Object.fromEntries(new URLSearchParams(search)) as TaskFilters;
    }, [search]);

    return (
        <>
            {(isLoadingUpdate || isLoading) && <Spinner fixed={true} parentClasses="bg-base-200/[.5]" />}
            <div className="flex flex-col h-full overflow-hidden">
                <PageHeader
                    title="Tarefas"
                    className="mb-7"
                    right={
                        <Button
                            color="inherit"
                            variant="outlined"
                            type="button"
                            size="medium"
                            className="bg-white"
                            onClick={() => setIsFilterVisible(true)}
                            endIcon={<Icon name="ico-filter" width={17} height={17} color="currentColor" />}>
                            Filtrar
                        </Button>
                    }
                />
                <div className="flex flex-1 overflow-x-auto gap-[30px] column-custom-scroll ">
                    <DragDropContext onDragEnd={handleDragEnd}>
                        {statuses?.map((status) => (
                            <TasksColumn filters={filters} key={status.id} {...status} />
                        ))}
                    </DragDropContext>
                </div>
                {isFilterVisible && <TasksFilter onClose={() => setIsFilterVisible(false)} />}
            </div>
            <Outlet />
        </>
    );
};

export default TasksPage;
