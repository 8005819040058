import IconButton from '@mui/material/IconButton/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import Icon from 'components/core/icon';
import { generateLabelPercentageWithBadge } from 'pages/private/operationals/dre/utils';
import { formatMoney } from 'utils/money';
import { calculatePercentage, classesTableRowHover, commercialBudgetSelectStyles } from '../utils';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { generateTableClasses } from './update';
import Select from 'components/core/form/select';
import RemoveButton from 'components/buttons/remove';
import CurrencyInput from 'components/core/form/currency';
import useGetClassificationsOptions from 'services/queries/configs-classifications/use-get-classifications-options';
import { ClassificationType } from 'types/models/classification';

import { Option } from 'types/general';
import { NumberFormatValues } from 'react-number-format/types/types';
import { UpdateBudgetPayload } from 'types/models/commercial';

const INITIAL = {
    hourMonth: 0,
    hourValue: 0,
    licenseValue: 0,
    totalValue: 0
};

type OtherExpensesProps = {
    contractValue: number;
    projectServices: Option[];
    isLoadingProjectServices: boolean;
    isDisabled: boolean;
};

const OtherExpenses = ({ contractValue, projectServices, isLoadingProjectServices, isDisabled }: OtherExpensesProps) => {
    const { palette } = useTheme();

    const { data: classifications = [], isLoading: isLoadingClassifications } = useGetClassificationsOptions(ClassificationType.FinancialClassification);

    const { formState, control, getValues } = useFormContext<UpdateBudgetPayload>();

    const { fields, append, remove } = useFieldArray({ control, name: 'otherExpenses' });

    const handleAppend = () => append(INITIAL);

    const handleChangeSelect = (onChange: (...event: any[]) => void) => (option: any) => onChange(option);

    const updatedFields = getValues('otherExpenses') || [];
    const allRowsTotal = updatedFields.reduce((acc, { hourMonth = 0, hourValue = 0, licenseValue = 0 }) => {
        return (acc += hourMonth * (hourValue + licenseValue));
    }, 0);

    const percentageValue = calculatePercentage(contractValue, allRowsTotal);

    const handleChangeValue =
        (onChange: (...event: any[]) => void) =>
        ({ floatValue }: NumberFormatValues) =>
            onChange(floatValue);

    return (
        <>
            <TableRow classes={classesTableRowHover} className="border-t border-b relative group">
                <TableCell className={generateTableClasses(true)}>
                    <div className="flex items-center gap-2">
                        <span className="w-[150px]">Despesas Outros (DO)</span>
                        {!isDisabled && !fields.length && (
                            <Tooltip title="Classificações" placement="top">
                                <IconButton
                                    color="secondary"
                                    size="medium"
                                    sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}
                                    onClick={handleAppend}>
                                    <Icon name="ico-plus" width={12} height={12} color={palette.grey[100]} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </TableCell>
                <TableCell colSpan={4} className="!text-xs !text-base-500">
                    {formatMoney(allRowsTotal)}
                </TableCell>
                <TableCell className="!text-xs !text-base-500">{generateLabelPercentageWithBadge(parseFloat(percentageValue.toFixed(2)))}</TableCell>
            </TableRow>
            {fields.map((item, index) => {
                const hourValue = updatedFields[index].hourValue || 0;
                const hourMonth = updatedFields[index].hourMonth || 0;

                const totalRow = hourMonth * hourValue;

                return (
                    <TableRow key={item.id} className="group relative">
                        <TableCell className={generateTableClasses(false)}>
                            <div className="flex items-center gap-2">
                                <Controller
                                    name={`otherExpenses.${index}.classification`}
                                    control={control}
                                    render={({ field }) => {
                                        const value = classifications.find((item) => item.value === field.value?.value);

                                        return (
                                            <Select
                                                {...field}
                                                options={classifications}
                                                value={value}
                                                isLoading={isLoadingClassifications}
                                                className="min-w-[200px]"
                                                labelClassName="!text-xs"
                                                selectStyles={commercialBudgetSelectStyles('medium', !!formState.errors.otherExpenses?.[index].classification?.value?.message)}
                                                onChange={handleChangeSelect(field.onChange)}
                                                label="Classificação"
                                                isDisabled={isDisabled}
                                                error={formState.errors.otherExpenses?.[index].classification?.value?.message}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    name={`otherExpenses.${index}.service`}
                                    control={control}
                                    render={({ field }) => {
                                        const value = projectServices.find((item) => item.value === field?.value?.value);

                                        return (
                                            <Select
                                                {...field}
                                                options={projectServices}
                                                isLoading={isLoadingProjectServices}
                                                value={value}
                                                onChange={handleChangeSelect(field.onChange)}
                                                label="Serviço"
                                                parentClassName="flex-1"
                                                className="min-w-[200px] w-full"
                                                labelClassName="!text-xs"
                                                selectStyles={commercialBudgetSelectStyles('medium', !!formState.errors.otherExpenses?.[index]?.service?.value?.message)}
                                                isDisabled={isDisabled}
                                                error={formState.errors.otherExpenses?.[index]?.service?.value?.message}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(totalRow)}</TableCell>
                        <TableCell className="!text-xs !text-base-500">
                            <Controller
                                name={`otherExpenses.${index}.hourMonth`}
                                control={control}
                                render={({ field }) => (
                                    <CurrencyInput
                                        ref={field.ref}
                                        name={field.name}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        label="Quantidade"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        labelClassName="!text-xs"
                                        onValueChange={handleChangeValue(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors.otherExpenses?.[index]?.hourMonth?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500" colSpan={2}>
                            <Controller
                                name={`otherExpenses.${index}.hourValue`}
                                control={control}
                                render={({ field }) => (
                                    <CurrencyInput
                                        ref={field.ref}
                                        name={field.name}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        label="Valor"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        labelClassName="!text-xs"
                                        onValueChange={handleChangeValue(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors.otherExpenses?.[index]?.hourValue?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell>{!isDisabled && <RemoveButton className="right-5" onClick={remove.bind(this, index)} />}</TableCell>
                    </TableRow>
                );
            })}
            {!isDisabled && !!fields.length && (
                <TableRow>
                    <TableCell>
                        <Tooltip title="Classificações" placement="top">
                            <IconButton color="secondary" size="medium" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }} onClick={handleAppend}>
                                <Icon name="ico-plus" width={12} height={12} color={palette.grey[100]} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default OtherExpenses;
