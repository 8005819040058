import React, { useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/core/form/input';
import { Controller, useForm } from 'react-hook-form';
import useGetClassifications from 'services/queries/classifications/use-get-classifications';
import dictionary from 'utils/dictionary';
import { array, number, object, string } from 'yup';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { ClassificationType } from 'types/models/classification';
import Spinner from 'components/core/spinner';
import useTheme from '@mui/material/styles/useTheme';
import Select from 'components/core/form/select';
import Editor from 'components/core/form/editor';
import Button from 'components/core/button';
import Icon from 'components/core/icon';
import { ProjectTypeSolicitationPayload } from 'types/models/project';
import Text from 'components/core/text';
import { RequestContext } from 'contexts/request';
import useCreateOrUpdateSolicitation from 'services/queries/projects/use-create-or-update-solicitation';
import { RequestTabCommonProps } from './types';
import { useNavigate, useOutletContext } from 'react-router-dom';

type Payload = Omit<ProjectTypeSolicitationPayload, 'files' | 'attentionPoints'>;

const query = jsonToGraphQLQuery({
    query: {
        __name: 'GetClassificationsOptions',
        items: {
            __aliasFor: 'classification',
            __args: {
                where: {
                    type: { _eq: ClassificationType.Solicitation }
                },
                order_by: { name: new EnumType('asc') }
            },
            value: { __aliasFor: 'id' },
            label: { __aliasFor: 'name' }
        }
    }
});

const RequestDetails = ({ projectProposalTypeId, projectTypeSolicitationId }: RequestTabCommonProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { state, setContent } = useContext(RequestContext);
    const listQuery = useOutletContext<string>();

    const solicitation = state.content;

    const { data: classifications = [], isLoading } = useGetClassifications(query);
    const { mutateAsync: createOrUpdateSolicitation, isLoading: isSubmitting } = useCreateOrUpdateSolicitation<Payload>(listQuery, projectProposalTypeId, projectTypeSolicitationId);

    const schema = object({
        classifications: array(number()).min(1, dictionary.validation.required),
        description: string().required(dictionary.validation.required),
        subject: string().required(dictionary.validation.required),
        observations: string()
    });

    const { control, formState, handleSubmit, reset } = useForm<Payload>({
        mode: 'onSubmit',
        shouldFocusError: true,
        resolver: yupResolver(schema),
        defaultValues: {
            classifications: [],
            description: '',
            observations: '',
            subject: ''
        }
    });

    useEffect(() => {
        if (solicitation) {
            reset({
                classifications: solicitation.classifications,
                subject: solicitation.subject,
                observations: solicitation.observations,
                description: solicitation.description
            });
        }
    }, [solicitation, reset]);

    const submit = async (payload: Payload) => {
        try {
            const solicitation = await createOrUpdateSolicitation(payload);

            setContent(
                {
                    ...solicitation,
                    classifications: solicitation.classifications?.map((item) => item.id) || []
                },
                true
            );

            if (!projectTypeSolicitationId) {
                navigate(`../${solicitation.id}/editar`, { replace: true });
            }
        } catch (error) {
            console.log('submit', error);
        }
    };

    if (isLoading) {
        return (
            <div className="p-4">
                <Spinner color={palette.secondary[100]} fixed={false} size={20} />
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="px-3 pt-7 sm:p-[30px]">
                <Text as="h3" variant="h4" className="text-heading mb-5">
                    Detalhes
                </Text>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                    <Controller name="subject" control={control} render={({ field }) => <Input {...field} error={formState.errors.subject?.message} label="Assunto" />} />
                    <Controller
                        name="classifications"
                        control={control}
                        render={({ field }) => {
                            const value = classifications.filter((item) => field.value?.find((value) => value === item.value));

                            return (
                                <Select
                                    {...field}
                                    value={value}
                                    isMulti={true}
                                    options={classifications}
                                    label="Tipo"
                                    placeholder="Selecione uma opção"
                                    error={(formState as any).errors.classifications?.message}
                                    onChange={(option: any) => {
                                        const ids = option.map((item) => item.value);

                                        field.onChange(ids);
                                    }}
                                />
                            );
                        }}
                    />
                </div>
                <Controller name="description" control={control} render={({ field }) => <Editor {...field} label="Descrição" error={formState.errors.description?.message} parentClasses="mb-6" />} />
                <Controller name="observations" control={control} render={({ field }) => <Editor {...field} label="Observações" error={formState.errors.observations?.message} />} />
            </div>
            <div className="border-t py-2 pr-2 flex justify-end">
                <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    endIcon={<Icon name="ico-arrow-right" width={18} color={isSubmitting ? palette.grey[400] : palette.secondary[500]} />}
                    type="submit"
                    variant="text"
                    color={isSubmitting ? 'inherit' : 'secondary'}
                    className="min-w-[100px]">
                    {Boolean(projectTypeSolicitationId) ? 'Atualizar solicitação' : 'Criar solicitação'}
                </Button>
            </div>
        </form>
    );
};

export default RequestDetails;
