import Task from 'pages/private/operationals/task/view/task';
import useGetTask from 'services/queries/task/use-get-task';
import { useParams } from 'react-router-dom';

const TaskPage = () => {
    const { taskId } = useParams();

    const { isLoading, data, error } = useGetTask(taskId);

    return <Task timelineId={data?.timeline?.id?.toString() || null} task={data} isLoading={isLoading} hasError={Boolean(error)} />;
};

export default TaskPage;
