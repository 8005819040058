// Helpers;
import { File, FileApiModel } from './file';
import { Task } from './task';
import { BaseModel } from './base';
import { Address } from './address';
import { Customer, CustomerContact } from './customer';
import { Timeline } from './timeline';
import { CamelCase } from 'types/utils';
import { Person, Phone } from './person';
import { JobPosition } from './job-position';
import { Classification } from './classification';
import { MeasureOption } from 'services/queries/projects/use-get-project-measures';
import { ApprovalStatus, Frequency, Option, PaymentForm, Seniority, Shift, Status } from 'types/general';
import { Signpost, SignpostClass, SignpostDimension, SignpostPayload, SignpostType } from './signpost';
import { NumberFormatValues } from 'react-number-format';
import { Document } from './document';
import { Branch } from './branch';
import { ReportWithoutForecast } from 'services/queries/operationals/use-get-transactions-report';
import { CommercialBudgetResponse } from './commercial';
import { CommercialBudgetVersionsResponse } from 'services/queries/commercial/use-get-budget-versions';

export enum ProjectTypeEnum {
    Management = 1,
    BID,
    Projects,
    ComplementaryProjects
}

export enum ProjectDetachment {
    Enterprise = 1,
    Own
}

export enum ProjectTypeStatus {
    Canceled = -1,
    InCreation = 0,
    Waiting = 1,
    Started = 2,
    Done = 3,
    Stopped = 4,
    Processing = 5
}

export type ProjectPayload = {
    customer: number;
    code: string;
    customerContact: number;
    title: string;
    notes: string;
    footage: NumberFormatValues;
    status: Status;
    types: number[];
    address?: {
        neighbourhood: string;
        number: string;
        city: number;
        state: number;
        status: number;
        street: string;
        zip: string;
        compliment?: string;
    };
    documents: Array<{ file: number }>;
};

export type Project = {
    address: Array<{ address: Partial<Address> }>;
    id: number;
    code: string;
    title: string;
    status: Status;
    types: Array<{ type: ProjectType }>;
    customer: Partial<Customer>;
    customerContact: Partial<CustomerContact> | null;
    branch: Partial<Branch> | null;
    goals: string;
    manager_responsibility: string;
    notes: string | null;
    objectives: string;
    premises: string;
    proposal: Partial<ProjectProposal> | null;
    restrictions: string;
    risks: string;
    project_status: Partial<ProjectStatus>;
    project_management_informations: Partial<ProjectManagementInformation>[];
    project_signposts: Partial<ProjectSignpost>[];
    project_management_informations_aggregate?: Partial<{
        aggregate: {
            count: number;
        };
    }>;
    project_documents: Partial<ProjectDocument>[];
    documents: Partial<ProjectDocument>[];
    databook: any;
    footage: string | null;
    budgets?: Array<Pick<CommercialBudgetResponse, 'id' | 'budgetStatus'>>;
};

export type ProjectManagementInformation = {
    id: number;
    revision: number;
    approval_status: ApprovalStatus;
    created_at: string;
    updated_at: string;
    status: Status;
    project_id: number;
    signpost_class_id: number;
    management_start_date: string;
    management_end_date: string;
    duration: number;
    measures: Array<{ project_measure: Partial<ProjectMeasure> }>;
    project_management_information_has_measures: Array<{ project_measure: Partial<ProjectMeasure> }>;
    project: Partial<Project>;
    signpost_class: Partial<SignpostClass>;
    observations: string | null;
};

export type ProjectMeasure = {
    slug: string;
    title: string;
} & BaseModel;

export type ProjectStatus = {
    id: number;
    created_at: string;
    updated_at: string;
    status: Status;
    title: string;
    slug: ProjectStatusEnum;
    project_movements: any[];
    projects: Partial<Project>[];
};

export enum ProjectStatusEnum {
    PreTap = 'pre-tap',
    Tap = 'tap',
    Partial = 'partial',
    Stopped = 'stopped',
    Done = 'done',
    Canceled = 'canceled'
}

export type ProjectProposal = {
    id: number;
    project_id: number;
    created_at: string;
    updated_at: string;
    status: Status;
    file_id: number;
    proposal_types: Partial<ProjectProposalType>[];
    file: File;
    project: Partial<Project>;
};

export type ProjectFinancialSetup = {
    is_internal: boolean;
    budget_provider: number;
    number_of_execution_hours: number;
    value_of_execution_hours: number;
    service: Option;
};

export type ProjectProposalType = {
    file: File;
    file_id: number;
    progress: number;
    project_proposal_id: number;
    project_type_id: number;
    person_id: number;
    start_date: string;
    end_date: string;
    investment: number;
    phases: Partial<ProjectProposalTypePhase>[];
    resources: Partial<ProjectProposalTypeResource>[];
    project_type_person_resources: Partial<ProjectTypePersonResource>[];
    director: Partial<Person>;
    manager: Partial<Person>;
    project_proposal: Partial<ProjectProposal>;
    project_type: Partial<ProjectType>;
    project_methodology: Partial<ProjectMethodology>;
    budget_expense: number;
    budget_provider: number;
    percentage_cost_administrative: number;
    percentage_cost_tax: number;
    percentage_profit: number;
    number_of_management_hours: number;
    number_of_execution_hours: number;
    value_of_management_hours: number;
    value_of_execution_hours: number;
    number_of_predicted_management_hours?: number;
    number_of_predicted_execution_hours?: number;
    total_of_predicted_management_hours?: number;
    total_of_predicted_execution_hours?: number;
    predicted_progress: number;
    start_work_time: string;
    week_first_day_work: number;
    week_last_day_work: number;
    week_days: string;
    project_proposal_type_has_services: Array<{
        project_proposal_type: Partial<ProjectProposalType>;
        project_proposal_type_id: number;
        project_service: Partial<ProjectService>;
        project_service_id: number;
    }>;
    project_service_id: number;
    project_methodology_id: number;
    duration: number;
    deadline_type: ProjectDeadline;
    budget: number;
    has_phases: boolean;
    investment_type: PaymentForm;
    observations: string;
    value_of_entry_installment: number;
    has_entry_installment?: boolean;
    person_manager_id: number;
    resources_aggregate: {
        aggregate: {
            count: number;
        };
    };
    project_type_person_resources_aggregate: {
        aggregate: {
            count: number;
        };
    };
    managers: {
        aggregate: {
            count: number;
        };
    };
    residents: {
        aggregate: {
            count: number;
        };
    };
    timelines: Partial<Timeline>[];
    project_type_status: ProjectTypeStatus;
    financial_setup?: ProjectFinancialSetup[];
} & BaseModel;

export type ProjectDocument = {
    id: number;
    file: FileApiModel;
    classification: Classification;
    name: string;
    description: string;
    observation: string;
    type: number;
    project_id: number;
    project: Partial<Project>;
    document: Partial<Document>;
};

export type ProjectTypePersonResource = {
    project_proposal_type_id: number;
    person_id: number;
    project_management_information_id: number;
    seniority: Seniority;
    contract_start_date: string;
    contract_end_date: string;
    salary: number;
    project_id: number;
    type_management_information: ProjectTypeManagementInformation;
    person: Partial<Person>;
    project_management_information: Partial<ProjectManagementInformation>;
    project_proposal_type: Partial<ProjectProposalType>;
    services_id: number[];
    services: [{ classification: { id: number; name: string } }];
    provider: { id: number; name: string; title?: string };
    hour_value: number;
    predicted_time: number;
    accomplished_time: number;
    project_type_person_resource_periods: Partial<ProjectTypePersonResourcePeriods>[];
} & BaseModel;

export type ProjectTypePersonResourcePeriods = {
    contract_end_date: string;
    contract_start_date: string;
    percentage: number;
};

export type ProjectService = {
    id: number;
    title: string;
    type: ProjectTypeEnum;
    created_at: string;
    updated_at: string;
    status: Status;
    project_proposal_types: Partial<ProjectProposalType>[];
};

export type ProjectMethodology = {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
    status: Status;
    project_proposal_types: Partial<ProjectProposalType>[];
};

export type ProjectProposalTypeResource = {
    id: number;
    created_at: string;
    updated_at: string;
    status: Status;
    project_proposal_type_id: number;
    job_position_id: number;
    seniority: Seniority;
    quantity: number;
    job_position: Partial<JobPosition>;
    proposal_type: Partial<ProjectProposalType>;
    hour_value: number;
    shift: Shift;
    frequency: Frequency;
    observations: string;
};

export type ProjectProposalTypePhase = {
    id: number;
    created_at: string;
    updated_at: string;
    status: Status;
    project_proposal_type_id: number;
    payment_percentage: number;
    order_phase: number;
    duration: number;
    title: string;
    classification_id?: number;
    classification?: {
        classifications?: Partial<Classification>[];
    };
    proposal_type: Partial<ProjectProposalType>;
    project_proposal_type_phase_has_classifications: Array<{
        classification: Partial<Classification>;
    }>;
    financial_prevision: number;
    value: number;
};

export enum ProjectTypeSlug {
    Management = 'management',
    Projects = 'projects',
    ComplementaryProjects = 'complementary-projects',
    Bid = 'bid'
}

export type ProjectType = {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
    slug: ProjectTypeSlug;
    status: Status;
    project_proposal_types: Partial<ProjectProposalType>[];
};

export type ProjectPhasePayload = {
    duration: string;
    title: string;
    payment_percentage: number;
    status: number;
    order_phase: number;
};

type ProjectPhase = {
    classification?: number;
    classifications?: (number | undefined)[] | [];
    paymentPercentage?: number;
    order?: number;
    order_phase?: string;
    classification_id?: string;
    duration?: number;
    financialPrevision?: number;
    title?: string;
    hasThirdPart?: boolean;
    value?: number;
};

export type ProjectProposalPayload = {
    startDate: string;
    endDate: string;
    duration: string;
    observations: string;
    proposal: number;
    type: number;
    deadlineType: ProjectDeadline;
    investmentType: string;
    weekDays?: number[];
    investment: NumberFormatValues;
    budget: NumberFormatValues;
    budgetExpense: NumberFormatValues;
    budgetProvider: NumberFormatValues;
    percentageCostAdministrative?: NumberFormatValues;
    percentageCostTax?: NumberFormatValues;
    percentageProfit?: NumberFormatValues;
    numberOfManagementHours?: NumberFormatValues;
    numberOfExecutionHours?: NumberFormatValues;
    valueOfManagementHours?: NumberFormatValues;
    valueOfExecutionHours?: NumberFormatValues;
    valueOfEntryInstallment?: NumberFormatValues;
    hasPhases?: boolean;
    hasEntryInstallment?: boolean;
    methodology: number;
    director: number;
    services: number[];
    phases?: ProjectPhase[] | [];
    [ProjectTypeSlug.ComplementaryProjects]?: {
        phases?: ProjectPhase[] | [];
        services?: number[];
    };
    [ProjectTypeSlug.Projects]?: {
        services?: number[];
        phases?: ProjectPhase[] | [];
    };
    selectedPhases?: (number | undefined)[] | [];
    financialSetups?: ProjectFinancialSetup[];
    financialPrevision?: NumberFormatValues;
};

export type ProjectProposalResourcePayload = {
    job_position_id: number;
    project_proposal_type_id: number;
    quantity: number;
    seniority: Seniority;
    status: number;
    shift: Shift;
    frequency: Frequency;
    observations?: string;
};

export type ProjectProposalResourcesPayload = {
    resources: ProjectProposalResourcePayload[];
};

export type ProjectManagementInformationPayload = {
    revision: number;
    duration: string;
    management_start_date: string;
    management_end_date: string;
    project_id: number;
    signpost_class_id: number;
    status: Status;
    project_management_information_has_measures: MeasureOption[];
    isSignsSelected?: boolean;
    observations?: string;
};

export type ProjectSignpostPayload = {
    projectSignposts: SignpostPayload[];
};

export type ProjectSignpost = {
    id: number;
    created_at: string;
    updated_at: string;
    status: Status;
    project_id: number;
    signpost_id: number;
    quantity: number;
    type: SignpostType;
    dimension: SignpostDimension;
    project: Partial<Project>;
    signpost: Partial<Signpost>;
};

export enum ProjectDeadline {
    Period = 1,
    Duration
}

export type CreateProjectManagerPayload = {
    person_manager_id: number;
};

export type ProjectTypeResourcePayload = {
    resources: Array<{
        contract_start_date: string;
        contract_end_date: string;
        person_id: number;
        project_id: number;
        project_proposal_type_id: number;
        status: Status;
        type_management_information?: ProjectTypeManagementInformation;
        services_id?: number[];
        provider_id?: number;
        hour_value?: {
            floatValue?: number;
            formattedValue: string;
            value: string;
        };
    }>;
};

export enum ProjectTypeManagementInformation {
    Director = 1,
    Manager,
    Resident,
    Coordinator,
    Production
}

export type ProjectMeetingRecord = {
    project_id: number;
    project_proposal_type_id: number;
    person_id: number;
    title: string;
    date: string;
    project_meeting_record_has_files: Partial<ProjectMeetingRecordHasFile>[];
    files: File[];
    project_meeting_record_has_person_editors: Partial<ProjectMeetingRecordHasPersonEditor>[];
    editors: Partial<ProjectMeetingRecordHasPersonEditor>[];
    project_meeting_record_participants: Partial<ProjectMeetingRecordParticipant>[];
    participants: Partial<CamelCase<ProjectMeetingRecordParticipant>>[];
    project_meeting_record_subjects: Partial<ProjectMeetingRecordSubject>[];
    subjects: Partial<ProjectMeetingRecordSubject>[];
    person: Partial<Person>;
    project: Partial<Project>;
    projectProposalType?: Partial<ProjectProposalType>;
    project_proposal_type?: Partial<ProjectProposalType>;
    document?: Document;
} & BaseModel;

export type ProjectMeetingRecordHasFile = {
    project_meeting_record_id: number;
    file_id: number;
    file: File;
    project_meeting_record: any;
};

export type ProjectMeetingRecordHasPersonEditor = {
    project_meeting_record_id: number;
    person_id: number;
    person: Partial<Person>;
    project_meeting_record: Partial<ProjectMeetingRecord>;
};

export type ProjectMeetingRecordParticipant = {
    project_meeting_record_id: number;
    person_id: number;
    read: boolean;
    validation_required: boolean;
    name: string;
    email: string;
    phones: Phone[];
    person: Partial<Person>;
    project_meeting_record: Partial<ProjectMeetingRecord>;
    jobPosition?: string;
    company?: string;
} & BaseModel;

export type ProjectMeetingRecordSubject = {
    title: string;
    description: string;
    project_meeting_record_id: number;
    project_meeting_record: Partial<ProjectMeetingRecord>;
    tasks: Partial<Task>[];
} & BaseModel;

export type ProjectTypeDocument = {
    name: string;
    description: string;
    observation: string;
    project_proposal_type: Partial<ProjectProposalType>;
    project_proposal_type_id: number;
    classification: Partial<Classification>;
    classification_id: number;
    file: File;
    file_id: number;
    isTechnical?: boolean;
} & BaseModel;

export type ProjectTypeDocumentPayload = {
    file: number;
    name: string;
    classification: number;
    classification_holder?: number;
    description?: string;
    observation?: string;
};

export type ProjectTransactionOutcomeDocumentPayload = ProjectTypeDocumentPayload & {
    file: Partial<File>;
};

export type FieldType = 'date' | 'text';

export type TermType = {
    title: string;
    subtitle: string;
    initials: string;
    text: string;
    text_terms_of_acceptance: string;
    fields: {
        fields: Array<{
            name: string;
            type: FieldType;
            visible: boolean;
            title: string;
            value?: string;
        }>;
        signatures: Array<{
            title: string;
            order: number;
        }>;
    };
    with_caveat_fields: boolean;
    with_attachment: boolean;
    with_observations: boolean;
} & BaseModel;

export enum TermStatus {
    Waiting = 1,
    Created,
    Finalized
}

export type ProjectTypeTerm = {
    project_proposal_type: Partial<ProjectProposalType>;
    project_proposal_type_id: number;
    person_id: number;
    person: Partial<Person>;
    term_type: Partial<TermType>;
    term_type_id: number;
    type: Partial<TermType>;
    number: string;
    description: string;
    observations: string;
    caveatDescription: string;
    documents: Document[];
    termStatus: TermStatus;
    withCaveat: boolean;
    fields: TermType['fields']['fields'];
    mainFile: FileApiModel;
    originalFile: FileApiModel;
} & BaseModel;

export type ProjectTypeTermPayload = {
    type: number;
    caveatDescription: string;
    withCaveat: boolean;
    documents?: Array<{ file: number }>;
    observations?: string;
    fields?: TermType['fields']['fields'];
};

export type ProjectTypeSolicitation = {
    project_proposal_type: Partial<ProjectProposalType>;
    project_proposal_type_id: number;
    person_id: number;
    person: Partial<Person>;
    number: string;
    subject: string;
    description: string;
    observations: string;
    project_type_solicitation_has_classifications: Partial<ProjectTypeSolicitationHasClassifications>[];
    project_type_solicitation_has_files: Partial<ProjectTypeSolicitationHasFiles>[];
    classifications: Classification[];
    files: FileApiModel[];
    criticalAttentionPoints: AttentionPoint[];
    relevantAttentionPoints: AttentionPoint[];
    projectType: {
        id: number;
        project: Pick<Project, 'code' | 'id' | 'title'>;
    };
} & BaseModel;

export type ProjectTypeSolicitationHasClassifications = {
    classification_id: number;
    classification: Partial<Classification>;
    project_type_solicitation_id: number;
    project_type_solicitation: Partial<ProjectTypeSolicitation>;
};

export type ProjectTypeSolicitationHasFiles = {
    project_type_solicitation_id: number;
    project_type_solicitation: Partial<ProjectTypeSolicitation>;
    file_id: number;
    file: File;
};

export type ProjectTypeSolicitationPayload = {
    description: string;
    subject: string;
    observations: string;
    classifications: number[];
    files: number[];
    attentionPoints: AttentionPointPayload[];
};

export enum AttentionPointType {
    relevant = 1,
    critical
}

export type AttentionPointPayload = {
    backendId?: number;
    type: AttentionPointType;
    task: Pick<Task, 'title' | 'description' | 'deadline'> & { person?: number };
};

export type AttentionPoint = {
    id: number;
    task: Task;
    type: AttentionPointType;
    solicitation: ProjectTypeSolicitation;
};

type DRECosts = {
    sale: number;
    eac: number;
};

export type ValuePercentage = {
    value: number;
    percentage: number;
};

export type ProjectDreResponse = {
    resume: {
        total: number;
        indirectCosts: number;
        directCosts: DRECosts;
        margin: DRECosts;
    };
    directCosts: {
        resume: ReportWithoutForecast;
        expenses: Array<ReportWithoutForecast & { title: string }>;
        indirectCosts: {
            resume: ReportWithoutForecast;
            expenses: Array<ReportWithoutForecast & { title: string }>;
        };
        margin: {
            contract: ValuePercentage;
            eac: ValuePercentage;
            variation: number;
        };
    };
};

export type ProjectOutletContext = { projectDetails?: Partial<Project>; budgetVersions?: CommercialBudgetVersionsResponse['items'] };
