import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useGetCommercialBudgetVersions, { CommercialBudgetVersionsResponse } from 'services/queries/commercial/use-get-budget-versions';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Divider from '@mui/material/Divider/Divider';
import { projectTypeTitleByIdMap } from 'utils/project';
import SimpleTable from 'components/core/table/simple';
import { generateVersionsTableItems, versionsTableColumns } from '../utils';
import { CommercialBudgetOutletContext } from 'types/models/commercial';
import useTheme from '@mui/material/styles/useTheme';

const CommercialBudgetVersionsPage = () => {
    const { projectTypeId, budgetId, projectId } = useParams();
    const { palette } = useTheme();
    const { commercialBudget, setProjectTypeId } = useOutletContext<CommercialBudgetOutletContext>();

    const { data: versions = [], isLoading: isLoadingVersions } = useGetCommercialBudgetVersions(budgetId, projectTypeId, true);

    const navigate = useNavigate();

    const handleGoTo = (item: Partial<CommercialBudgetVersionsResponse['items'][0]>, type: 'update' | 'show' = 'update') => {
        const url = !!projectId
            ? `/app/contratos/${projectId}/fp/${item.budget_id}/versoes/${item.id}/${projectTypeId}/visualizar`
            : `/app/fp/${item.budget_id}/versoes/${item.id}/${projectTypeId}/${type === 'update' ? 'editar' : 'visualizar'}`;

        navigate(url);
    };

    return (
        <Modal
            closeOnClickOutside={false}
            onClose={navigate.bind(this, -1)}
            contentClassnames="w-[1200px]"
            headerLeft={
                <Text as="h4" variant="h4" className="text-heading">
                    {projectTypeTitleByIdMap[projectTypeId!] || '-'} - Versões
                </Text>
            }>
            <>
                <Divider />
                {isLoadingVersions ? (
                    <Spinner fixed={false} className="my-4" />
                ) : (
                    <SimpleTable
                        className="my-6"
                        items={generateVersionsTableItems(versions, palette, handleGoTo, setProjectTypeId, commercialBudget?.budgetStatus)}
                        columns={versionsTableColumns()}
                    />
                )}
            </>
        </Modal>
    );
};

export default CommercialBudgetVersionsPage;
