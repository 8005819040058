export type Modify<T, R> = Omit<T, keyof R> & R;

export type ReduxAction<T> = {
    type: string;
    payload: T;
};

export type ID = number | string;

export type Option<T = number> = {
    value: T;
    label: string;
};

export enum Status {
    Deleted = -1,
    Inactive,
    Active
}

export enum StatusLabel {
    Deleted = 'Apagado',
    Inactive = 'Inativo',
    Active = 'Ativo'
}

export enum Shift {
    Day = 1,
    Night
}

export enum Frequency {
    PartTime = 1,
    FullTime
}

export enum PaymentForm {
    Unique = 1,
    Monthly,
    LPU
}

export enum Seniority {
    Intern = 1,
    Junior,
    Intermediate,
    Senior
}

export enum ApprovalStatus {
    Pending = 1,
    Inactive,
    Active
}

export enum ProjectTransactions {
    Outcome = 1,
    Income
}
