import Card from 'components/core/card';
import Content from './content';
import Team from './team';
import { AgendaYear, People } from '../types';
import PageHeader from 'components/page/header';
import { useState } from 'react';
import Filters from './filters';
import Months from './months';
import { PeopleMapFilter } from 'services/queries/people-map/types';
import useGetMapOfHoursPerPersonAndYear from 'services/queries/people-map/use-get-map-of-hours-per-person-and-year';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import { months } from 'utils/date';

export type Response = {
    year: number;
    people: People<AgendaYear>[];
};

const monthsWithTotal = [...months, 'Total'];

const PeopleMapHHYear = () => {
    const title = `Mapa de HH por Ano`;

    const branchState = useState<number>();
    const departmentState = useState<number>();
    const yearState = useState<number | undefined>(new Date().getFullYear());
    const filtersState = useState<PeopleMapFilter[]>([]);

    const [filters, setFilters] = filtersState;

    const { data, isLoading } = useGetMapOfHoursPerPersonAndYear<Response>(filters);

    const { people = [] } = data || {};

    return (
        <>
            {isLoading && <Spinner parentClasses="bg-base-100/20 !z-50" />}

            <PageHeader title={title} className="mb-7" right={<Filters onSetFilters={setFilters} branchState={branchState} yearState={yearState} departmentState={departmentState} />} />

            {!isLoading && !!data?.people?.length ? (
                <Card>
                    <div className="flex max-h-[calc(100vh-220px)] overflow-y-auto">
                        <div className="bg-white h-max sticky left-0 z-[998]">
                            <Team items={people} />
                        </div>

                        <div className="flex-1 overflow-initial pb-2">
                            <div className="sticky top-0 bg-white z-50">
                                <Months months={monthsWithTotal} />
                            </div>
                            <Content months={monthsWithTotal} people={people} />
                        </div>
                    </div>
                </Card>
            ) : (
                <Empty title="Não foi executada consulta ou nenhum dado foi encontrado." />
            )}
        </>
    );
};

export default PeopleMapHHYear;
