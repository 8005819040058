import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import apiGraphQl from 'services/graphql';
import { convertToDateOrReturnUndefined } from 'utils/date';
import { getPersonContractKey } from './keys';

const useGetPersonContract = <T>(props: { contractId?: string; personId?: string; isDetails?: boolean }) => {
    const { contractId, personId, isDetails = false } = props || {};

    const navigate = useNavigate();
    const { showToast } = useToast();

    const fetcher = async () => {
        if (isDetails) {
            const { data } = await api.get(`/people/${personId}/contracts/${contractId}`);

            return data;
        }

        // Get data to fill the edit form
        const { contract } = await apiGraphQl<{ contract: any }>(
            `query GetPersonContractById($id: Int!) {
                contract: person_contract_by_pk(id: $id) {
                  finalDate: final_date
                  juridicDocument: juridic_document
                  juridicFantasyName: juridic_fantasy_name
                  juridicName: juridic_name
                  startDate: start_date
                  salary
                  position: position_id
                  seniority
                  shift
                  frequency
                  branch: branch_id
                  type
                  hourValue: hour_value
                  numberOfHours: number_of_hours
                  numberOfDays: number_of_days
                  benefits
                }
              }`,
            { id: contractId }
        );

        return {
            ...contract,
            startDate: convertToDateOrReturnUndefined(contract?.startDate),
            finalDate: convertToDateOrReturnUndefined(contract?.finalDate)
        };
    };

    return useQuery<T>(getPersonContractKey(contractId, isDetails), fetcher, {
        enabled: !!contractId,
        onError: () => {
            navigate(-1);
            showToast('Tivemos um problema ao obter os detalhes desse contrato. Tente novamente.', 'danger');
        }
    });
};

export default useGetPersonContract;
