import IconButton from '@mui/material/IconButton/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import Icon from 'components/core/icon';
import { generateLabelPercentageWithBadge } from 'pages/private/operationals/dre/utils';
import { formatMoney } from 'utils/money';
import { calculatePercentage, classesTableRowHover, commercialBudgetSelectStyles } from '../utils';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { generateTableClasses } from './update';
import { Option } from 'types/general';
import Select from 'components/core/form/select';
import RemoveButton from 'components/buttons/remove';
import CurrencyInput from 'components/core/form/currency';
import Input from 'components/core/form/input';
import { jobSeniorities } from 'utils/statics';
import { NumberFormatValues } from 'react-number-format/types/types';
import { UpdateBudgetPayload } from 'types/models/commercial';

type TeamProductionProps = {
    projectServices: Option[];
    isLoadingProjectServices: boolean;
    contractValue: number;
    isDisabled: boolean;
};

const INITIAL = {
    hourMonth: 0,
    hourValue: 0,
    licenseValue: 9,
    totalValue: 0,
    discount: 0,
    addition: 0
};

const TeamProduction = ({ projectServices, isLoadingProjectServices, contractValue, isDisabled }: TeamProductionProps) => {
    const { palette } = useTheme();

    const { formState, control, getValues } = useFormContext<UpdateBudgetPayload>();

    const { fields, append, remove } = useFieldArray({ control, name: 'teamProductionExpenses' });

    const handleAppend = () => append(INITIAL);

    const handleChangeSelect =
        (onChange: (...event: any[]) => void, isSeniority = false) =>
        (option: any) => {
            onChange(isSeniority ? option.value : option);
        };

    const handleChangeValue = (onChange: (...event: any[]) => void, type: 'anchor' | 'currency') => (value: React.ChangeEvent<HTMLInputElement> | NumberFormatValues) => {
        if ('target' in value && type === 'anchor') {
            return onChange(value.target.value);
        }

        if ('floatValue' in value && type === 'currency') {
            return onChange(value.floatValue);
        }
    };

    const updatedFields = getValues('teamProductionExpenses') || [];

    const allRowsTotal = updatedFields.reduce((acc, { hourMonth = 0, hourValue = 0, licenseValue = 0 }) => {
        return (acc += hourMonth * (hourValue + licenseValue));
    }, 0);

    const percentageValue = calculatePercentage(contractValue, allRowsTotal);

    return (
        <>
            <TableRow classes={classesTableRowHover} className="border-t border-b relative group">
                <TableCell className={generateTableClasses(true)}>
                    <div className="flex items-center gap-2">
                        <span className="w-[150px]">Custo Equipe Produção</span>
                        {!isDisabled && !fields.length && (
                            <Tooltip title="Serviços / Funções" placement="top">
                                <IconButton
                                    color="secondary"
                                    size="medium"
                                    sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}
                                    onClick={handleAppend}>
                                    <Icon name="ico-plus" width={12} height={12} color={palette.grey[100]} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </TableCell>
                <TableCell colSpan={4} className="!text-xs !text-base-500">
                    {formatMoney(allRowsTotal)}
                </TableCell>
                <TableCell className="!text-xs !text-base-500">{generateLabelPercentageWithBadge(parseFloat(percentageValue.toFixed(2)))}</TableCell>
            </TableRow>
            {fields.map((item, index) => {
                const hourLicenseValue = (updatedFields[index].hourValue || 0) + (updatedFields[index].licenseValue || 0);
                const hourMonth = updatedFields[index].hourMonth || 0;

                const totalRow = hourMonth * hourLicenseValue;

                return (
                    <TableRow key={item.id} className="group relative">
                        <TableCell className={generateTableClasses(false)}>
                            <div className="flex items-center gap-2">
                                <Controller
                                    name={`teamProductionExpenses.${index}.seniority`}
                                    control={control}
                                    render={({ field }) => {
                                        const value = jobSeniorities.find((item) => item.value === field.value);

                                        return (
                                            <Select
                                                {...field}
                                                value={value}
                                                options={jobSeniorities}
                                                onChange={handleChangeSelect(field.onChange, true)}
                                                label="Senioridade"
                                                parentClassName="flex-1"
                                                className="w-full min-w-[200px]"
                                                labelClassName="!text-xs"
                                                selectStyles={commercialBudgetSelectStyles('medium', !!formState.errors.teamProductionExpenses?.[index]?.seniority?.message)}
                                                isDisabled={isDisabled}
                                                error={formState.errors.teamProductionExpenses?.[index]?.seniority?.message}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    name={`teamProductionExpenses.${index}.service`}
                                    control={control}
                                    render={({ field }) => {
                                        const value = projectServices.find((item) => item.value === field.value?.value);

                                        return (
                                            <Select
                                                {...field}
                                                isLoading={isLoadingProjectServices}
                                                value={value}
                                                options={projectServices}
                                                onChange={handleChangeSelect(field.onChange)}
                                                label="Serviço"
                                                parentClassName="flex-1"
                                                className="min-w-[200px] w-full"
                                                labelClassName="!text-xs"
                                                selectStyles={commercialBudgetSelectStyles('medium', !!formState.errors.teamProductionExpenses?.[index]?.service?.value?.message)}
                                                isDisabled={isDisabled}
                                                error={formState.errors.teamProductionExpenses?.[index]?.service?.value?.message}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(totalRow)}</TableCell>
                        <TableCell className="!text-xs !text-base-500">
                            <Controller
                                name={`teamProductionExpenses.${index}.hourMonth`}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        value={field.value}
                                        type="number"
                                        inputMode="numeric"
                                        min={0}
                                        label="HH/mês"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        labelClassName="!text-xs"
                                        onChange={handleChangeValue(field.onChange, 'anchor')}
                                        disabled={isDisabled}
                                        error={formState.errors.teamProductionExpenses?.[index]?.hourMonth?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell>
                            <Controller
                                name={`teamProductionExpenses.${index}.hourValue`}
                                control={control}
                                render={({ field }) => (
                                    <CurrencyInput
                                        ref={field.ref}
                                        name={field.name}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        label="Valor Hora"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        labelClassName="!text-xs"
                                        onValueChange={handleChangeValue(field.onChange, 'currency')}
                                        disabled={isDisabled}
                                        error={formState.errors.teamProductionExpenses?.[index]?.hourValue?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell>
                            <Controller
                                name={`teamProductionExpenses.${index}.licenseValue`}
                                control={control}
                                render={({ field }) => (
                                    <CurrencyInput
                                        ref={field.ref}
                                        name={field.name}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        label="Licença"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        labelClassName="!text-xs"
                                        onValueChange={handleChangeValue(field.onChange, 'currency')}
                                        disabled={isDisabled}
                                        error={formState.errors.teamProductionExpenses?.[index]?.licenseValue?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell>{!isDisabled && <RemoveButton className="right-5" onClick={remove.bind(this, index)} />}</TableCell>
                    </TableRow>
                );
            })}
            {!isDisabled && !!fields.length && (
                <TableRow>
                    <TableCell>
                        <Tooltip title="Serviços / Funções" placement="top">
                            <IconButton color="secondary" size="medium" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }} onClick={handleAppend}>
                                <Icon name="ico-plus" width={12} height={12} color={palette.grey[100]} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default TeamProduction;
