import { Status } from 'types/general';

export const getProviderKey = (providerId?: string) => ['providers', providerId];
export const createOrUpdateProviderKey = (providerId?: string) => ['providers', 'create', providerId];
export const getAllProvidersOptionsKey = ['providers', 'all', 'options'];
export const getProviderProjectsAmountKey = (id?: number) => ['providers', 'projects', id];

export const getProviderFinancialSetupsKeys = (projectProposalTypeId?: number) => ['providers', 'financial', 'setups', projectProposalTypeId];

const queries = {
    createProvider: `mutation CreateProvider ($payload: provider_insert_input!) {
        item: insert_provider_one(object: $payload) {
            id
        }
    }`,
    getProvider: (fields: string) => `query GetProvider($providerId: Int!) {
        provider: provider_by_pk(id: $providerId) {
            ${fields}
        }
    }`,
    getAll: `query GetProvidersOptions {
        items: provider(order_by: {title: asc}, where: {status: {_eq: ${Status.Active}}}) {
            value: id
            label: title
        }
    }`,
    getProviderProjectsAmount: `query getProjectAmountsByProvider($providerId: Int!) {
        active: project_aggregate(where: {project_proposals: {proposal_types: {timelines: {provider_id: {_eq: $providerId}}}}, project_status: {id: {_neq: 3}}, status: {_eq: 1}}) {
          aggregate {
            count
          }
        }
        all: project_aggregate(where: {project_proposals: {proposal_types: {timelines: {provider_id: {_eq: $providerId}}}}, status: {_eq: 1}}) {
          aggregate {
            count
          }
        }
      }
      `
};

export default queries;
