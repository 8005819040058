import { useMemo } from 'react';
import Text from 'components/core/text';
import DatabookItems from '../components/databook-items';
import Spinner from 'components/core/spinner';
import { DatabookResponse } from 'services/queries/operationals/use-get-databooks';
import { DatabookFilesResponse } from '../types/types';

type DataBookProps = {
    data: {
        folders: DatabookResponse[];
        files: DatabookFilesResponse[];
    };
    isLoading: boolean;
    hasFiles: boolean;
};

type DataBookSeparator = {
    label: string;
    icon: string;
    data: DatabookResponse[] | DatabookFilesResponse[] | [];
    type: 'folder' | 'file';
};

const Databook = ({ data, isLoading, hasFiles }: DataBookProps) => {
    const arrDataSeparator: DataBookSeparator[] = useMemo(
        () => [
            {
                label: 'Pastas',
                icon: 'folder',
                type: 'folder',
                data: !hasFiles ? data.folders : []
            },
            {
                label: 'Arquivos',
                icon: 'file-text',
                type: 'file',
                data: data.files
            }
        ],
        [data, hasFiles]
    );

    return (
        <>
            {!isLoading ? (
                arrDataSeparator.map((itemArr, index) => {
                    if (!!itemArr?.data) {
                        return (
                            <div key={index} className="my-10">
                                <Text as="h3" className="text-heading text-[1.75rem] font-[500]">
                                    {itemArr.label}
                                </Text>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                                    {!!itemArr?.data?.length ? (
                                        itemArr.data.map((item, index) => (
                                            <div key={index}>
                                                <DatabookItems item={item} iconName={itemArr.icon} />
                                            </div>
                                        ))
                                    ) : (
                                        <Text className="mt-4 text-base-500 mr-1 italic">{itemArr.type === 'folder' ? 'Nenhuma pasta encontrada' : 'Nenhum arquivo encontrado'}</Text>
                                    )}
                                </div>
                            </div>
                        );
                    }
                    return;
                })
            ) : (
                <Spinner size={30} fixed={true} />
            )}
        </>
    );
};

export default Databook;
