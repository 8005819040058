import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { getAllKey } from '../crud/graphql';

type Payload = { file: number };

const useFinalizeTerm = (projectProposalTypeId?: number, projectTypeTermId?: string, params?: any) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Payload) => {
        const { data } = await api.patch<{ url: string }>(`/project-types/${projectProposalTypeId}/terms/${projectTypeTermId}/finalize`, payload);

        return data.url;
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('project_type_term', params));

            navigate(-1);

            showToast(`Termo ${projectProposalTypeId ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${projectProposalTypeId ? 'atualizar' : 'criar'} o termo`, 'danger');
        }
    });
};

export default useFinalizeTerm;
