import React, { useMemo } from 'react';

// Dependencies
import { Outlet, useOutletContext } from 'react-router-dom';

// Components
import Spinner from 'components/core/spinner';

// Helpers
import useGetTimelines from 'services/queries/projects/use-get-timelines';
import { ProjectTypeStatus } from 'types/models/project';
import TimelineList from './list';
import { OperationalDetailsPageContext } from '..';

type DocumentsProps = {
    projectProposalTypeIdProp?: number;
    withActions?: boolean;
    withTitle?: boolean;
    projectTypeStatusProp?: ProjectTypeStatus;
    className?: string;
};

const Timeline = ({ className, projectTypeStatusProp, projectProposalTypeIdProp, withTitle = false, withActions = false }: DocumentsProps) => {
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const projectProposalTypeId = useMemo(() => projectProposalTypeIdProp || ctx?.projectProposalType?.id, [projectProposalTypeIdProp, ctx?.projectProposalType]);
    const projectTypeStatus = useMemo(() => projectTypeStatusProp || ctx?.projectProposalType?.project_type_status, [projectTypeStatusProp, ctx?.projectProposalType]);

    const { data: timelines, isLoading } = useGetTimelines(projectProposalTypeId?.toString());

    if (isLoading) {
        return <Spinner fixed={false} parentClasses="h-[calc(100%-49px)]" />;
    }

    return (
        <>
            <TimelineList className={className} timelines={timelines} withActions={withActions} withTitle={withTitle} projectTypeStatus={projectTypeStatus} />
            <Outlet context={ctx} />
        </>
    );
};

export default Timeline;
