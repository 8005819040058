// Dependencies
import { useQuery } from 'react-query';

// Helpers
import api from 'services/api';
import { getProjectTypeTermKey } from './graphql';
import { ProjectTypeTerm } from 'types/models/project';

const useGetTerm = (projectProposalTypeId?: number, projectTypeTermId?: string) => {
    const fetcher = async () => api.get<Partial<ProjectTypeTerm>>(`/project-types/${projectProposalTypeId}/terms/${projectTypeTermId}`).then(({ data }) => data);

    return useQuery(getProjectTypeTermKey(projectTypeTermId), fetcher);
};

export default useGetTerm;
