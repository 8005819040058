import { CrudPageProps } from 'types/graphql';
import TableCell from '@mui/material/TableCell/TableCell';
import dictionary from 'utils/dictionary';
import Badge from 'components/core/badge';

const generateListPageProps = (personId?: number): CrudPageProps => {
    return {
        graphql: {
            table: 'bank_account',
            searchableField: 'bank.name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'bank.name',
                    label: dictionary.content.graphql['bank']
                },
                {
                    name: 'account',
                    label: dictionary.content.graphql['account'],
                    options: {
                        customBodyRender: (data, tableMeta) => {
                            const [, , , accountDigit] = tableMeta.rowData;
                            return <div>{accountDigit ? `${data}-${accountDigit}` : data}</div>;
                        }
                    }
                },
                {
                    name: 'account_digit',
                    label: dictionary.content.graphql['accountDigit'],
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    name: 'agency',
                    label: dictionary.content.graphql['agency'],
                    options: {
                        customBodyRender(data, tableMeta) {
                            const [, , , , , agencyDigit] = tableMeta.rowData;
                            return <div>{agencyDigit ? `${data}-${agencyDigit}` : data}</div>;
                        }
                    }
                },
                {
                    name: 'agency_digit',
                    label: dictionary.content.graphql['agencyDigit'],
                    options: {
                        display: 'excluded'
                    }
                },
                {
                    name: 'pix',
                    label: 'Pix'
                },
                {
                    name: 'main',
                    label: dictionary.content.graphql['mainBankAccount'],
                    options: {
                        customBodyRender(data) {
                            return <Badge variant={data ? 'success' : 'error'}>{data ? 'Sim' : 'Não'}</Badge>;
                        }
                    }
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ],
            where: {
                bank_account_person: {
                    person_id: {
                        _eq: personId
                    }
                }
            }
        },
        api: {
            useApiTodelete: true,
            endpoint: `/people/${personId}/bank-accounts`
        }
    };
};

export default generateListPageProps;
