import React, { createContext, useContext } from 'react';
import { GraphqlPaginationVariables } from 'types/graphql';

type State = {
    params?: GraphqlPaginationVariables<any>;
    onChangeParams(data: any): void;
    getParam(name: string): any;
    onChangeParam(data: { name: string; value: any }): void; // Util to update a single param
};

/**
 * This context will help us manage the state of the table's params
 */
const ListPageContext = createContext<State>({
    params: undefined,
    onChangeParams: () => {},
    getParam: () => {},
    onChangeParam: () => {}
});

export const useListPageContext = () => {
    return useContext(ListPageContext);
};

export const ListPageContextProvider = ({ children, ...rest }: { children: React.ReactNode } & State) => {
    return <ListPageContext.Provider value={rest}>{children}</ListPageContext.Provider>;
};
