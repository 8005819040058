// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Status } from 'types/general';
import apiGraphQl from 'services/graphql';
import queries, { getAllKey } from './graphql';
import { GraphqlPagination, GraphqlPaginationVariables, GraphqlQueryPayload } from 'types/graphql';
import _set from 'lodash/set';

const useGetAll = <T>(queryConfig: GraphqlQueryPayload, variables: GraphqlPaginationVariables<T>) => {
    const getAll = async () => {
        const query = {
            ...queryConfig,
            columns: queryConfig.columns?.filter((item) => item.name !== 'actions') // REMOVE ACTIONS COLUMN FROM THE QUERY PAYLOAD
        };

        const params = {
            limit: variables.limit,
            offset: variables.offset,
            orderBy: variables.orderBy,
            where: variables.where
        };

        const finalVariables = () => {
            // Set status column key and value
            _set(params, `where.${queryConfig?.statusPath ?? 'status'}`, { _neq: Status.Deleted });

            return params;
        };

        if (!!queryConfig.query) {
            return apiGraphQl<GraphqlPagination<T>>(queryConfig.query(params));
        }

        return apiGraphQl<GraphqlPagination<T>>(queries.getAll(query), finalVariables());
    };

    return useQuery(getAllKey(queryConfig.table, variables), getAll);
};

export default useGetAll;
