import { RouteObject } from 'react-router-dom';

import OperationalDetailsPage from 'pages/private/operationals/view';
import Checklist from 'pages/private/projects/checklist';
import ChecklistPage from 'pages/private/projects/checklist/view';
import ChecklistCreateOrUpdatePage from 'pages/private/projects/checklist/create-or-update';
import CreateOrUpdateProjectTypeResourcesPage from 'pages/private/projects/resource';
import CreateOrUpdateMeetingRecordPage from 'pages/private/projects/meeting-records/create-or-update';
import SetupSignpostPage from 'pages/private/projects/signpost';
import CreateOrUpdateCisoPage from 'pages/private/projects/ciso/create-or-update';
import MeetingRecordPage from 'pages/private/projects/meeting-records/view';
import CreateOrUpdateTasksPage from 'pages/private/projects/tasks';
import Document from 'pages/private/operationals/documents';
import DocumentsCreateOrUpdatePage from 'pages/private/operationals/documents/create-or-update';
import TermsCreateOrUpdatePage from 'pages/private/projects/terms/create-or-update';
import Term from 'pages/private/projects/terms';
import SolicitationCreateOrUpdatePage from 'pages/private/projects/requests/create-or-update';
import TimelineCreateOrUpdatePage from 'pages/private/projects/timeline/create-or-update';
import TaskProgressPage from 'pages/private/operationals/progress';
import TaskDetailsPage from 'pages/private/operationals/task/view';
import ConstructionDetails from 'pages/private/operationals/view/details';
import Scopes from 'pages/private/projects/view/components/type/scopes';
import Resources from 'pages/private/projects/view/components/type/resources';
import MeetingRecords from 'pages/private/projects/view/components/meeting-records';
import CisoContainer from 'pages/private/projects/view/components/cisos';
import Documents from 'pages/private/projects/view/components/type/documents';
import Terms from 'pages/private/projects/view/components/type/terms/terms';
import Requests from 'pages/private/projects/view/components/type/requests';
import Timeline from 'pages/private/operationals/view/timeline';
import TimelineDetails from 'pages/private/operationals/view/timeline/details';
import Followup from 'pages/private/operationals/view/followup/list';
import CreateOrUpdateFollowupPage from 'pages/private/operationals/view/followup/create-or-update';
import FinancialPage from 'pages/private/operationals/view/financial';
import CreateOrUpdateFinancialPage from 'pages/private/operationals/view/financial/create-or-update';
import FinancialViewPage from 'pages/private/operationals/view/financial/view';
import MeasurementPage from 'pages/private/operationals/measurement';
import CreateOrUpdateMeasurementPaymentPage from 'pages/private/operationals/payment/create-or-update';
import MeasurementPaymentPage from 'pages/private/operationals/payment/view';
import NewMeasurement from 'pages/private/operationals/measurement/new';
import ReportsPage from 'pages/private/operationals/reports';
import OperationalDashboard from 'pages/private/operationals/view/dashboard';
import DocumentViewPage from 'pages/private/operationals/documents/view';
import TermViewPage from 'pages/private/operationals/terms/view';
import RequestPage from 'pages/private/projects/requests/view';
import CisoViewPage from 'pages/private/projects/ciso/view';
import CreateOrUpdateTaskPage from 'pages/private/operationals/task/create-or-update';
import TaskPage from 'pages/private/operationals/task';
import UpdateAttentionPoint from 'pages/private/projects/requests/attention-points/update';
import OutcomesPage from 'pages/private/operationals/outcomes/list';
import OutcomePage from 'pages/private/operationals/outcomes/view';
import Page from 'pages/shared/page';
import { Rule } from 'types/permissions';
import OperationalsListPage from 'pages/private/operationals/list';
import CreateOrUpdateOutcomePage from 'pages/private/operationals/outcomes/create-or-update';
import ProjectDrePage from 'pages/private/operationals/dre';
import DetailsTabsModal from 'pages/private/operationals/view/followup/view/details-tabs-modal';
import TransactionOutcomeDocumentForm from 'pages/private/operationals/outcomes/documents/form';
import Schedule from 'pages/private/operationals/schedule';
import DatabookPage from 'pages/private/operationals/databook/view';

const taskRoute: RouteObject = {
    path: 'tarefa/:taskId',
    element: <Page Component={TaskPage} withModal={true} code="operacional-detalhes-cronogramas-tarefa" permissions={[Rule.Show]} />,
    children: [
        {
            index: true,
            element: <Page Component={TaskDetailsPage} withModal={true} code="operacional-detalhes-cronogramas-tarefa" permissions={[Rule.Show]} />
        },
        {
            path: 'novo',
            element: <Page Component={CreateOrUpdateTaskPage} withModal={true} code="operacional-detalhes-planejamento-de-tarefas-tarefa" permissions={[Rule.Create]} />
        },
        {
            path: 'editar',
            element: <Page Component={CreateOrUpdateTaskPage} withModal={true} code="operacional-detalhes-cronogramas-tarefa" permissions={[Rule.Update]} />
        },
        {
            path: 'progresso',
            element: <Page Component={TaskProgressPage} withModal={true} code="operacional-detalhes-cronogramas-tarefa-progresso" permissions={[Rule.Create, Rule.Update]} />
        }
    ]
};

const schedule: RouteObject = {
    path: 'cronograma',
    element: <Page Component={Schedule} code="operacional-detalhes-cronograma" permissions={[Rule.Show]} />,
    children: [taskRoute]
};

const operationals: RouteObject[] = [
    {
        path: 'operacional/:projectTypeSlug',
        element: <Page Component={OperationalsListPage} code="operacional" permissions={[Rule.List]} />
    },
    {
        path: 'operacional/:projectTypeSlug/:projectProposalTypeId',
        element: <Page Component={OperationalDetailsPage} code="operacional" permissions={[Rule.Show]} />,
        children: [
            {
                index: true,
                element: <Page Component={OperationalDashboard} code="operacional" permissions={[Rule.Show]} />
            },
            {
                path: 'detalhes',
                element: <Page Component={ConstructionDetails} code="operacional-detalhes-home" permissions={[Rule.Show]} />
            },
            {
                path: 'escopos',
                element: <Page Component={Scopes} code="operacional-detalhes-escopos" withTitle={true} permissions={[Rule.Show]} />
            },
            {
                path: 'recursos',
                element: <Page Component={Resources} code="operacional-detalhes-recursos" withActions={true} withTitle={true} permissions={[Rule.Show]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateProjectTypeResourcesPage} withModal={true} code="operacional-detalhes-recursos" permissions={[Rule.Create]} />
                    },
                    {
                        path: 'editar/:resourceId',
                        element: <Page Component={CreateOrUpdateProjectTypeResourcesPage} withModal={true} code="operacional-detalhes-recursos" permissions={[Rule.Update]} />
                    }
                ]
            },
            {
                path: 'atas-de-reuniao',
                element: <Page Component={MeetingRecords} code="operacional-detalhes-atas-de-reuniao" permissions={[Rule.Show]} elevation={0} withTitle={true} withActions={true} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateMeetingRecordPage} withModal={true} code="operacional-detalhes-atas-de-reuniao" permissions={[Rule.Create]} />
                    },
                    {
                        path: ':meetingRecordId',
                        element: <Page Component={MeetingRecordPage} withModal={true} code="operacional-detalhes-atas-de-reuniao" permissions={[Rule.Show]} />
                    },
                    {
                        path: ':meetingRecordId/editar',
                        element: <Page Component={CreateOrUpdateMeetingRecordPage} withModal={true} code="operacional-detalhes-atas-de-reuniao" permissions={[Rule.Update]} />
                    },
                    {
                        path: ':meetingRecordId/tarefas',
                        element: <Page Component={CreateOrUpdateTasksPage} withModal={true} code="operacional-detalhes-atas-de-reuniao-tarefas" permissions={[Rule.Create, Rule.Update]} />
                    }
                ]
            },
            {
                path: 'checklist',
                element: <Page Component={Checklist} withModal={true} code="operacional-detalhes-checklist" permissions={[Rule.Show]} />,
                children: [
                    {
                        path: ':checklistId',
                        element: <Page Component={ChecklistPage} withModal={true} code="operacional-detalhes-checklist" permissions={[Rule.Show]} />
                    },
                    {
                        path: 'novo',
                        element: <Page Component={ChecklistCreateOrUpdatePage} withModal={true} code="operacional-detalhes-checklist" permissions={[Rule.Create]} />
                    },
                    {
                        path: 'editar',
                        element: <Page Component={ChecklistCreateOrUpdatePage} withModal={true} code="operacional-detalhes-checklist" permissions={[Rule.Update]} />
                    }
                ]
            },
            {
                path: 'documentos',
                element: <Page Component={Documents} code="operacional-detalhes-documentos" permissions={[Rule.List]} withTitle={true} withActions={true} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={DocumentsCreateOrUpdatePage} withModal={true} code="operacional-detalhes-documentos" permissions={[Rule.Create]} />
                    },
                    {
                        path: ':projectTypeDocumentId',
                        element: <Page Component={Document} withModal={true} code="operacional-detalhes-documentos" permissions={[Rule.Show]} />,
                        children: [
                            {
                                index: true,
                                element: <Page Component={DocumentViewPage} withModal={true} code="operacional-detalhes-documentos" permissions={[Rule.Show]} />
                            },
                            {
                                path: 'editar',
                                element: <Page Component={DocumentsCreateOrUpdatePage} withModal={true} code="operacional-detalhes-documentos" permissions={[Rule.Update]} />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'termos',
                element: <Page Component={Terms} code="operacional-detalhes-termos" origin="operational" permissions={[Rule.List]} withTitle={true} withActions={true} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={TermsCreateOrUpdatePage} withModal={true} code="operacional-detalhes-termos" permissions={[Rule.Create]} />
                    },
                    {
                        path: ':projectTypeTermId',
                        element: <Page Component={Term} withModal={true} code="operacional-detalhes-termos" permissions={[Rule.Show]} />,
                        children: [
                            {
                                index: true,
                                element: <Page Component={TermViewPage} withModal={true} code="operacional-detalhes-termos" permissions={[Rule.Show]} />
                            },
                            {
                                path: 'editar',
                                element: <Page Component={TermsCreateOrUpdatePage} withModal={true} code="operacional-detalhes-termos" permissions={[Rule.Update]} />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'solicitacoes',
                element: <Page Component={Requests} code="operacional-detalhes-solicitacoes" permissions={[Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={SolicitationCreateOrUpdatePage} withModal={true} code="operacional-detalhes-solicitacoes" permissions={[Rule.Create]} />
                    },
                    {
                        path: ':projectTypeSolicitationId/editar',
                        element: <Page Component={SolicitationCreateOrUpdatePage} withModal={true} code="operacional-detalhes-solicitacoes" permissions={[Rule.Update]} />
                    },
                    {
                        path: ':projectTypeSolicitationId',
                        element: <Page Component={RequestPage} withModal={true} code="operacional-detalhes-solicitacoes" permissions={[Rule.Show]} />,
                        children: [
                            {
                                path: 'tarefas/:taskId',
                                element: <Page Component={TaskPage} withModal={true} code="operacional-detalhes-solicitacoes-tarefa" permissions={[Rule.Show]} />,
                                children: [
                                    {
                                        index: true,
                                        element: <Page Component={TaskDetailsPage} withModal={true} code="operacional-detalhes-solicitacoes-tarefa" permissions={[Rule.Show]} />
                                    }
                                ]
                            },
                            {
                                path: 'pontos-de-atencao/:attentionPointId/editar',
                                element: <Page Component={UpdateAttentionPoint} withModal={true} code="operacional-detalhes-solicitacoes-ponto-de-atencao" permissions={[Rule.Update]} />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'cisos',
                element: <Page Component={CisoContainer} code="operacional-detalhes-cisos" permissions={[Rule.List]} withTitle={true} withActions={true} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateCisoPage} withModal={true} code="operacional-detalhes-cisos" permissions={[Rule.Create]} />
                    },
                    {
                        path: ':cisoId',
                        element: <Page Component={CisoViewPage} withModal={true} code="operacional-detalhes-cisos" permissions={[Rule.Show]} withActions={true} />
                    },
                    {
                        path: ':cisoId/editar',
                        element: <Page Component={CreateOrUpdateCisoPage} withModal={true} code="operacional-detalhes-cisos" permissions={[Rule.Update]} />
                    },
                    {
                        path: 'placas/:signPostClassId/configurar',
                        element: <Page Component={SetupSignpostPage} withModal={true} code="operacional-detalhes-cisos-placas" permissions={[Rule.Create]} />
                    }
                ]
            },
            {
                path: 'acompanhamentos',
                element: <Page Component={Followup} code="operacional-detalhes-acompanhamentos" permissions={[Rule.List]} withActions={true} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateFollowupPage} withModal={true} code="operacional-detalhes-acompanhamentos" permissions={[Rule.Create]} />
                    },
                    {
                        path: ':dailyReportId',
                        element: <Page Component={DetailsTabsModal} withModal={true} code="operacional-detalhes-acompanhamentos" permissions={[Rule.Show]} />
                    },
                    {
                        path: ':dailyReportId/editar',
                        element: <Page Component={CreateOrUpdateFollowupPage} withModal={true} code="operacional-detalhes-acompanhamentos" permissions={[Rule.Update]} />
                    }
                ]
            },
            {
                path: 'planejamento-de-tarefas/:taskPlanningId',
                element: <Page Component={TimelineDetails} code="operacional-detalhes-planejamento-de-tarefas" permissions={[Rule.Show]} />,
                children: [
                    {
                        path: 'editar',
                        element: <Page Component={TimelineCreateOrUpdatePage} withModal={true} code="operacional-detalhes-planejamento-de-tarefas" permissions={[Rule.Update]} />
                    },
                    {
                        path: 'tarefa/:taskId',
                        element: <Page Component={TaskPage} withModal={true} code="operacional-detalhes-planejamento-de-tarefas-tarefa" permissions={[Rule.Show]} />,
                        children: [
                            {
                                index: true,
                                element: <Page Component={TaskDetailsPage} withModal={true} code="operacional-detalhes-planejamento-de-tarefas-tarefa" permissions={[Rule.Show]} />
                            },
                            {
                                path: 'novo',
                                element: <Page Component={CreateOrUpdateTaskPage} withModal={true} code="operacional-detalhes-planejamento-de-tarefas-tarefa" permissions={[Rule.Create]} />
                            },
                            {
                                path: 'editar',
                                element: <Page Component={CreateOrUpdateTaskPage} withModal={true} code="operacional-detalhes-planejamento-de-tarefas-tarefa" permissions={[Rule.Update]} />
                            },
                            {
                                path: 'progresso',
                                element: <Page Component={TaskProgressPage} withModal={true} code="operacional-detalhes-escopo-tarefa-progresso" permissions={[Rule.Create]} />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'cronogramas',
                element: <Page Component={Timeline} code="operacional-detalhes-cronogramas" permissions={[Rule.List]} withTitle={true} withActions={true} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={TimelineCreateOrUpdatePage} withModal={true} code="operacional-detalhes-cronogramas" permissions={[Rule.Create]} />
                    }
                ]
            },
            {
                path: 'cronogramas/:projectTypeTimelineId',
                element: <Page Component={TimelineDetails} code="operacional-detalhes-cronogramas" permissions={[Rule.Show]} />,
                children: [
                    taskRoute,
                    {
                        path: 'editar',
                        element: <Page Component={TimelineCreateOrUpdatePage} withModal={true} code="operacional-detalhes-cronogramas" permissions={[Rule.Update]} />
                    }
                ]
            },
            {
                path: 'cronogramas/:projectTypeTimelineId/cronograma',
                element: <Page Component={Schedule} code="operacional-detalhes-cronograma" permissions={[Rule.Show]} />,
                children: [taskRoute]
            },
            {
                path: 'financeiros',
                element: <Page Component={FinancialPage} code="operacional-detalhes-financeiros" permissions={[Rule.List]} withActions={true} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateFinancialPage} withModal={true} code="operacional-detalhes-financeiros" permissions={[Rule.Create]} />
                    },
                    {
                        path: ':providerFinancialSetupId/editar',
                        element: <Page Component={CreateOrUpdateFinancialPage} withModal={true} code="operacional-detalhes-financeiros" permissions={[Rule.Update]} />
                    }
                ]
            },
            {
                path: 'financeiros/:providerFinancialSetupId',
                element: <Page Component={FinancialViewPage} code="operacional-detalhes-financeiros" permissions={[Rule.Show]} />,
                children: [
                    {
                        path: 'medicoes/:measurementId',
                        children: [
                            {
                                index: true,
                                element: <Page Component={MeasurementPage} withModal={true} code="operacional-detalhes-financeiros-medicao" permissions={[Rule.Show]} />
                            },
                            {
                                path: 'novo',
                                element: <Page Component={NewMeasurement} withModal={true} code="operacional-detalhes-financeiros" permissions={[Rule.Create]} />
                            }
                        ]
                    },
                    {
                        path: 'pagamentos/novo',
                        element: <Page Component={CreateOrUpdateMeasurementPaymentPage} withModal={true} code="operacional-detalhes-financeiros-pagamento" permissions={[Rule.Create]} />
                    },
                    {
                        path: 'pagamentos/:paymentId',
                        element: <Page Component={MeasurementPaymentPage} withModal={true} code="operacional-detalhes-financeiros-pagamento" permissions={[Rule.Show]} />
                    }
                ]
            },
            {
                path: 'databook/*',
                element: <Page Component={DatabookPage} code="operacional-detalhes-databook" permissions={[Rule.List]} />
            },
            {
                path: 'relatorios',
                element: <Page Component={ReportsPage} code="operacional-detalhes-relatorios" permissions={[Rule.Show]} />
            },
            {
                path: 'despesas',
                element: <Page Component={OutcomesPage} code="operacional-detalhes-despesas" permissions={[Rule.List]} withActions={true} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={CreateOrUpdateOutcomePage} title="Nova despesa" withModal={true} code="operacional-detalhes-despesas" permissions={[Rule.Create]} />
                    },
                    {
                        path: 'editar/:outcomeId',
                        element: <Page Component={CreateOrUpdateOutcomePage} title="Atualizar despesa" withModal={true} code="operacional-detalhes-despesas" permissions={[Rule.Update]} />
                    },
                    {
                        path: ':outcomeId',
                        element: <Page Component={OutcomePage} withModal={true} code="operacional-detalhes-despesas" permissions={[Rule.Show]} />
                    },
                    {
                        path: ':outcomeId/documentos/novo',
                        element: <Page Component={TransactionOutcomeDocumentForm} withModal={true} code="operacional-detalhes-despesas" permissions={[Rule.Create]} />
                    },
                    {
                        path: ':outcomeId/documentos/:transactionOutcomeDocumentId/editar',
                        element: <Page Component={TransactionOutcomeDocumentForm} withModal={true} code="operacional-detalhes-despesas" permissions={[Rule.Update]} />
                    }
                ]
            },
            {
                path: 'dre',
                element: <Page Component={ProjectDrePage} code="operacional-detalhes-dre" permissions={[Rule.Show]} />
            },
            schedule
        ]
    }
];

export default operationals;
