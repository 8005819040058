import { PropsWithChildren, createContext, useContext } from 'react';
import { HeaderText } from '..';
import { OccupationMapResponse } from 'services/queries/occupation-map/use-get-occupation-map';

type ContextState = {
    headerTexts: HeaderText[];
    activePeople: number[];
    items: OccupationMapResponse;
};

const CalendarContext = createContext({} as ContextState);

export const CalendarContextProvider = ({ value, children }: PropsWithChildren<{ value: ContextState }>) => {
    return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};

export const useCalendarContext = () => {
    return useContext(CalendarContext);
};
