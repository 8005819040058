import React, { useState } from 'react';
import theme from 'settings/theme';
import Empty from 'components/empty';
import Text from 'components/core/text';
import { UseQueryResult } from 'react-query';
import Spinner from 'components/core/spinner';
import type { PhysicalProgress as PhysicalProgressType } from 'types/models/report';
import LineChart from '../line-chart';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import PhysicalProgressTable from './table';

const colors = ['#b3d3d3', theme.extend.colors.secondary[500]];

const PhysicalProgress = ({ isLoading, isError, data: physicalProgress }: UseQueryResult<PhysicalProgressType, unknown>) => {
    const [tab, setTab] = useState<number | string>('GENERAL');

    const handleChangeTab = (_: any, nextSlug: any) => setTab(nextSlug);

    return (
        <div className="border border-base-300 rounded-[14px] mb-4">
            <Text as="h6" variant="h6" className="p-4 text-heading">
                Avanço físico
            </Text>
            {isLoading ? (
                <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} className="min-h-[100px]" />
            ) : isError ? (
                <Empty title="Dados não encontrados" className="px-4" />
            ) : (
                <>
                    <Tabs variant="scrollable" classes={{ root: 'bg-base-100 z-[10]' }} indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                        <Tab value="GENERAL" label="Geral" />
                        <Tab value="DETAILS" label="Detalhes" />
                        {physicalProgress?.providers.map((item) => {
                            return <Tab key={item.provider.id} value={item.provider.id} label={item.provider.title} />;
                        })}
                    </Tabs>
                    {tab === 'DETAILS' && (
                        <div className="p-4">
                            <PhysicalProgressTable tableProgress={physicalProgress?.general.tableProgress} tableResult={physicalProgress?.general.tableResult} />
                        </div>
                    )}
                    {tab === 'GENERAL' && (
                        <div className="p-4">
                            <LineChart colors={colors} labels={physicalProgress?.general.graphic.labels || []} datasets={physicalProgress?.general.graphic.datasets || []} className="min-h-[350px]" />
                        </div>
                    )}
                    {physicalProgress?.providers.map((item) => {
                        return tab === item.provider.id ? (
                            <div className="p-4">
                                <LineChart colors={colors} labels={item.graphic.labels || []} datasets={item.graphic.datasets || []} className="min-h-[350px]" />
                            </div>
                        ) : null;
                    })}
                </>
            )}
        </div>
    );
};

export default PhysicalProgress;
