import { useMutation } from 'react-query';
import apiGraphQl from 'services/graphql';

const useDeletePageRules = () => {
    const request = async (pageId: number) => {
        return apiGraphQl(
            `mutation DeletePageRules($pageId: Int!) {
                item: delete_page_rule(where: {page_id: {_eq: $pageId}}) {
                    affected_rows
                }
            }`,
            { pageId }
        );
    };

    return useMutation(request);
};

export default useDeletePageRules;
