import { Fragment, useState } from 'react';

// Dependencies
import Divider from '@mui/material/Divider/Divider';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton/IconButton';

// Components
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Modal from 'components/core/modal';

// Helpers
import { Project } from 'types/models/project';
import ExternalButton from 'components/buttons/external';
import { generateProjectStatusBadge } from '../../utils';

const infoButton = { classes: { child: 'text-system-info-100' } };

const ProjectDetails = (props: Partial<Project>) => {
    const { palette } = useTheme();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleToggleModal = (value: boolean) => () => setIsModalOpen(value);

    return (
        <>
            <div className="px-6 py-5 relative">
                <div className="flex justify-between items-center">
                    <div className="flex-1 pr-2">
                        <Text variant="h5" as="h5" className="block !text-[22px] text-heading mb-1">
                            {props.title}
                        </Text>
                        {props.code && (
                            <Text variant="body.regular.xs" as="span" className="block text-base-400 italic">
                                Contrato #{props.code}
                            </Text>
                        )}
                    </div>
                    <div>{generateProjectStatusBadge(props.project_status?.slug)}</div>
                </div>
                {props.customer && (
                    <>
                        <Divider className="my-6" />
                        <div>
                            <div className="flex items-center flex-wrap justify-between mb-3">
                                <Text variant="body.regular.sm" as="span" className="text-secondary-500">
                                    Cliente
                                </Text>
                                <Text variant="body.regular.sm" as="span" className="text-base-500">
                                    {props.customer?.title}
                                </Text>
                            </div>
                            {Boolean(props.customer.contacts?.length) && (
                                <div className="flex items-baseline flex-wrap justify-between mb-3">
                                    <Text variant="body.regular.sm" as="span" className="text-secondary-500">
                                        Contato(s)
                                    </Text>
                                    <div className="flex flex-col items-end">
                                        {props.customer.contacts?.map((item, index) => (
                                            <Fragment key={index}>
                                                <ExternalButton onClick={() => null}>{item.person?.name}</ExternalButton>
                                                <Text variant="body.regular.sm" as="span" className="text-base-500 text-right mb-1">
                                                    {item?.person?.phones?.map((item) => `(${item.ddd}) ${item.number}`).join(', ')}
                                                </Text>
                                            </Fragment>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {Boolean(props.address?.length) && (
                    <div className="mb-5">
                        <Text variant="body.regular.sm" as="span" className="block text-secondary-500 mb-1">
                            Local do projeto
                        </Text>
                        <div>
                            {props.address?.map((item, index) => (
                                <Text key={index} variant="body.regular.sm" as="span" className="text-base-500 block mb-1 leading-5">
                                    {item.address.street}
                                    {', '}
                                    {item.address.number}
                                    {' - '}
                                    {item.address.compliment}
                                    {', '}
                                    {item.address.neighbourhood}
                                    {' - '}
                                    {item.address.city?.name}/{item.address.state?.initials}
                                    {', '}
                                    {item.address.zip}
                                </Text>
                            ))}
                        </div>
                    </div>
                )}
                <div className="flex justify-end">
                    {Boolean(props.notes) && (
                        <Tooltip placement="top" title="Observações" disableFocusListener={true}>
                            <IconButton TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20" onClick={handleToggleModal(true)}>
                                <Icon name="ico-info" width={16} height={16} color={palette.info.main} />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
            {isModalOpen && props.notes && (
                <Modal contentClassnames="py-5 px-7 w-[600px]" onClose={handleToggleModal(false)}>
                    <Text variant="h4" as="h4" className="text-heading mb-5">
                        Observações
                    </Text>
                    <div className="rich-content" dangerouslySetInnerHTML={{ __html: props.notes }} />
                </Modal>
            )}
        </>
    );
};

export default ProjectDetails;
