import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { ProjectTypeTerm, ProjectTypeTermPayload } from 'types/models/project';
import { createOrUpdateProjectTypeTermKey, getProjectTypeTermsKey } from './graphql';
import { Modify } from 'types/general';
import { getAllKey } from '../crud/graphql';

const useCreateOrUpdateTerm = (projectProposalTypeId?: number, projectTypeTermId?: string, ctx?: any) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: Partial<Modify<ProjectTypeTermPayload, { fields: Array<{ name: string; value: any }> }>>) => {
        const method = projectTypeTermId ? api.patch : api.post;
        const endpoint = projectTypeTermId ? `/project-types/${projectProposalTypeId}/terms/${projectTypeTermId}` : `/project-types/${projectProposalTypeId}/terms`;

        return method<Partial<ProjectTypeTerm>>(endpoint, payload);
    };

    return useMutation(createOrUpdateProjectTypeTermKey(projectProposalTypeId, projectTypeTermId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('project_type_term', ctx));
            queryClient.invalidateQueries(getProjectTypeTermsKey(projectProposalTypeId));

            navigate(-1);

            showToast(`Termo ${projectProposalTypeId ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${projectProposalTypeId ? 'atualizar' : 'criar'} o termo`, 'danger');
        }
    });
};

export default useCreateOrUpdateTerm;
