// Dependencies
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { City } from 'types/models/address';
import { WhereClause } from 'types/graphql';
import cities, { getCityKey } from './graphql';
import { removeFalsyValues } from 'utils/object';

const useGetCity = (key: keyof City, variables?: WhereClause<Partial<City>>) => {
    const getCity = () => apiGraphQl<any>(cities.getCity, variables).then((data) => data.city[0]);

    const isInvalid = isEmpty(removeFalsyValues(variables?.where[key]));

    return useQuery(getCityKey(variables), getCity, { enabled: !isInvalid });
};

export default useGetCity;
