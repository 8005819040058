import { useState } from 'react';
import Avatar from '@mui/material/Avatar/Avatar';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import Text from 'components/core/text';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import hexToRgba from 'utils/hex-to-rgba';
import { formatDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import TaskItemRecursive from './recursive';
import useGetTimeline from 'services/queries/projects/use-get-timeline';
import IconButton from '@mui/material/IconButton/IconButton';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { ColorVariant } from 'types/colors';
import { Link, Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import useReorderTasks from 'services/queries/timeline/use-reorder-tasks';
import { reorderArray } from 'utils/array';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import ConfirmModal from 'components/core/modal/confirm';
import useFinalizeTimelineCreation from 'services/queries/operationals/use-finalize-timeline-creation';
import { TimelineStatusCreation } from 'types/models/timeline';
import useProjectTypeSlug from '../../hooks/use-project-type-slug';
import TableHeaderDetails from './table-header-details';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import classNames from 'classnames';
import { ProjectTypeStatus } from 'types/models/project';
import { OperationalDetailsPageContext } from '..';

const warningButton = { classes: { child: 'text-system-warning-100' } };
const secondaryButton = { classes: { child: 'text-secondary-100' } };

type TimelineDetailsProps = {
    timelineIdProp?: number;
};

const getVariant = (progress: number): { variant: ColorVariant; label: string } => {
    if (!progress) {
        return {
            variant: 'grey',
            label: 'Aguardando início'
        };
    }

    if (progress < 100) {
        return {
            variant: 'info',
            label: 'Em andamento'
        };
    }

    return {
        variant: 'success',
        label: 'Finalizado'
    };
};

const getTasks = (id, array) => {
    for (const node of array) {
        if (node.id === id) {
            return node;
        }

        if (node.tasks) {
            const child = getTasks(id, node.tasks);
            if (child) return child;
        }
    }
};

const TimelineDetails = ({ timelineIdProp }: TimelineDetailsProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isManagement } = useProjectTypeSlug();
    const { projectProposalTypeId, projectTypeTimelineId, taskPlanningId } = useParams();

    const ctx = useOutletContext<OperationalDetailsPageContext>();

    const isProjectBlocked = ctx?.projectProposalType?.project_type_status === ProjectTypeStatus.Canceled || ctx?.projectProposalType?.project_type_status === ProjectTypeStatus.Done;

    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);

    const isManagementTimeline = pathname.includes('cronogramas') && isManagement;

    const timelineId = timelineIdProp || Number(projectTypeTimelineId || taskPlanningId || 0);

    const { mutateAsync: reorderTasks } = useReorderTasks(timelineId.toString());
    const { data: timeline, isLoading, error } = useGetTimeline(Number(projectProposalTypeId), timelineId.toString());
    const { mutateAsync: finalizeTimelineCreation, isLoading: isFinalizing } = useFinalizeTimelineCreation(Number(projectProposalTypeId), timelineId?.toString());

    const progress = getVariant(timeline?.progressPercentage || 0);

    const physicalProgressPercentage = timeline?.proposalType?.report?.progress || 0;
    const hourProgressPercentage = timeline?.proposalType?.report?.hourProgress || 0;
    const financialProgressPercentage = timeline?.proposalType?.report?.financialProgress || 0;

    const handleToggleModalConfirmation = (value: boolean) => () => setIsModalConfirmationOpen(value);

    const handleDragEnd = async (result: DropResult) => {
        try {
            const parentTaskId = Number(result.destination?.droppableId.split('-')[1]);

            const sourceIndex = result.source.index;
            const destinationIndex = result.destination?.index;

            // If parent task id not exists it means the tasks is present in root array
            const parentTask = parentTaskId ? getTasks(parentTaskId, timeline?.tasks || []) : timeline;

            const newArr = reorderArray(parentTask?.tasks || [], sourceIndex, destinationIndex).map((item, index) => ({ task: item.id, order: index + 1 }));

            if (Boolean(newArr.length)) {
                await reorderTasks(newArr as any);
            }
        } catch (error) {
            console.log('handleDragEnd', error);
        }
    };

    const hasReportResume = Boolean(timeline?.proposalType?.report?.resume);

    const scopeContainerDetailsClasses = classNames('grid grid-cols-1 gap-4 mb-8', hasReportResume ? 'grid grid-cols-1 gap-4 lg:grid-cols-4' : '');
    const iconsContainerDetailsClasses = classNames('mb-[15px]', hasReportResume ? 'flex flex-col gap-2 justify-between' : 'grid grid-cols-1 md:grid-cols-3 gap-4 mb-8');

    const handleBack = () => navigate(-1);

    const handleConfirmFinalizeTimeline = async () => {
        try {
            await finalizeTimelineCreation({ status: 'finalized' });

            handleToggleModalConfirmation(false)();
        } catch (error) {
            console.log('error', error);
        }
    };

    if (isLoading) {
        return (
            <div className="absolute left-2/4 top-2/4 -ml-4 -mt-4">
                <Spinner fixed={false} />
            </div>
        );
    }

    if (error) {
        return <Empty className="p-4" title={`${isManagementTimeline ? 'Cronograma' : 'Planejamento de tarefas'} não encontrado.`} />;
    }

    return (
        <>
            <div className="flex items-center justify-between mb-6">
                <Text as="h4" variant="h4" className="text-heading">
                    {timeline?.provider?.title || 'Planejamento de tarefas'}
                </Text>
                <div>
                    {!!timeline?.canEditTask && !isProjectBlocked && (
                        <Tooltip placement="left" title="Editar" disableFocusListener={true}>
                            <IconButton
                                component={Link}
                                TouchRippleProps={warningButton}
                                size="large"
                                className="mr-2 bg-system-warning-100 bg-opacity-30 hover:bg-system-warning-100 hover:bg-opacity-40"
                                to="editar">
                                <Icon name="ico-edit" width={12} height={12} color={palette.warning.main} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {timeline?.statusCreation !== TimelineStatusCreation.Finalized && !isProjectBlocked && (
                        <Tooltip placement="left" title="Finalizar criação" disableFocusListener={true}>
                            <IconButton
                                className="bg-secondary-100 bg-opacity-20 hover:bg-secondary-100 hover:bg-opacity-40 p-[9px]"
                                TouchRippleProps={secondaryButton}
                                color="default"
                                onClick={handleToggleModalConfirmation(true)}>
                                <Icon name="ico-check-square" color={palette.secondary[400]} width={16} height={16} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isManagementTimeline && (
                        <Tooltip placement="left" title="Fechar cronograma" disableFocusListener={true}>
                            <IconButton className="bg-base-200 ml-2" size="large" color="default" onClick={handleBack}>
                                <Icon name="ico-close" color={palette.heading} width={10} height={10} />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className={scopeContainerDetailsClasses}>
                {hasReportResume && (
                    <div className="col-span-3 mr-5">
                        <TableHeaderDetails report={timeline?.proposalType?.report} />
                    </div>
                )}
                <div className={iconsContainerDetailsClasses}>
                    {(Boolean(timeline?.startDate) || Boolean(timeline?.endDate)) && (
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                    <Icon name="ico-calendar" color={palette.info[500]} />
                                </Avatar>
                            }
                            classes={{ root: 'p-0', title: 'text-xs', subheader: 'text-xs' }}
                            title={`Período do ${isManagementTimeline ? 'cronograma' : 'Plan. de Tarefas'}`}
                            subheader={`${formatDate(timeline?.startDate)} - ${formatDate(timeline?.endDate)}`}
                            titleTypographyProps={{ color: palette.grey[500] }}
                            subheaderTypographyProps={{ color: palette.heading }}
                        />
                    )}
                    {Boolean(timeline?.budget) && (
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: hexToRgba(palette.success[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                    <Icon name="ico-dollar-sign" color={palette.success[500]} />
                                </Avatar>
                            }
                            classes={{ root: 'p-0', title: 'text-xs', subheader: 'text-xs' }}
                            title="Orçamento"
                            subheader={formatMoney(parseFloat(timeline?.budget?.value?.toString() || '') || 0)}
                            titleTypographyProps={{ color: palette.grey[500] }}
                            subheaderTypographyProps={{ color: palette.heading }}
                        />
                    )}
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.grey[200], 0.8), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-flag" width={14} height={16} color={palette.grey[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0', title: 'text-xs', subheader: 'text-xs' }}
                        title="Status"
                        subheader={progress.label}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette[progress.variant][500], fontStyle: 'italic' }}
                    />
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.secondary[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-discount" width={15} height={15} color={palette.secondary[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0', title: 'text-xs', subheader: 'text-xs' }}
                        title={`Progresso Físico (${formatCurrency(physicalProgressPercentage)}%)`}
                        subheader={
                            <LinearProgress
                                color="primary"
                                value={physicalProgressPercentage > 100 ? 100 : physicalProgressPercentage < 0 ? 0 : physicalProgressPercentage}
                                variant="determinate"
                                classes={{ root: 'h-[6px] rounded-[3px] bg-base-300 flex-1 mt-1', bar: 'rounded-[3px]' }}
                            />
                        }
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                    {!isManagementTimeline && (
                        <>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: hexToRgba(palette.secondary[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                        <Icon name="ico-discount" width={15} height={15} color={palette.secondary[500]} />
                                    </Avatar>
                                }
                                classes={{ root: 'p-0', title: 'text-xs', subheader: 'text-xs' }}
                                title={`Progresso HH (${formatCurrency(hourProgressPercentage)}%)`}
                                subheader={
                                    <LinearProgress
                                        color={hourProgressPercentage > 100 ? 'error' : 'primary'}
                                        value={hourProgressPercentage > 100 ? 100 : hourProgressPercentage < 0 ? 0 : hourProgressPercentage}
                                        variant="determinate"
                                        classes={{ root: 'h-[6px] rounded-[3px] bg-base-300 flex-1 mt-1', bar: 'rounded-[3px]' }}
                                    />
                                }
                                titleTypographyProps={{ color: palette.grey[500] }}
                                subheaderTypographyProps={{ color: palette.heading }}
                            />
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: hexToRgba(palette.secondary[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                        <Icon name="ico-discount" width={15} height={15} color={palette.secondary[500]} />
                                    </Avatar>
                                }
                                classes={{ root: 'p-0', title: 'text-xs', subheader: 'text-xs' }}
                                title={`Progresso Financeiro (${formatCurrency(financialProgressPercentage)}%)`}
                                subheader={
                                    <LinearProgress
                                        color={financialProgressPercentage > 100 ? 'error' : 'primary'}
                                        value={financialProgressPercentage > 100 ? 100 : financialProgressPercentage < 0 ? 0 : financialProgressPercentage}
                                        variant="determinate"
                                        classes={{ root: 'h-[6px] rounded-[3px] bg-base-300 flex-1 mt-1', bar: 'rounded-[3px]' }}
                                    />
                                }
                                titleTypographyProps={{ color: palette.grey[500] }}
                                subheaderTypographyProps={{ color: palette.heading }}
                            />
                        </>
                    )}
                </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable isDropDisabled={isProjectBlocked} droppableId="tasks">
                    {(provided) => (
                        <TaskItemRecursive
                            canEditTask={!!timeline?.canEditTask}
                            provided={provided}
                            projectId={timeline?.project?.id}
                            timelineId={timeline?.id}
                            tasks={timeline?.tasks || []}
                            onDragEnd={handleDragEnd}
                            isProjectBlocked={isProjectBlocked}
                        />
                    )}
                </Droppable>
            </DragDropContext>
            {isModalConfirmationOpen && (
                <ConfirmModal
                    color="secondary"
                    title="Finalizar criação"
                    description="Você tem certeza que deseja finalizar a criação deste cronograma?"
                    isLoading={isFinalizing}
                    onClose={handleToggleModalConfirmation(false)}
                    onConfirmAction={handleConfirmFinalizeTimeline}
                />
            )}
            <Outlet context={ctx} />
        </>
    );
};

export default TimelineDetails;
