// Dependencies
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { createDailyReportKey, getFollowupsKey } from './graphql';
import { useNavigate } from 'react-router-dom';
import { DailyReportPayload } from 'types/models/organization';

const useCreateOrUpdateDailyReport = (projectProposalTypeId?: number, dailyReportId?: number | undefined) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: DailyReportPayload) => {
        const endpoint = dailyReportId ? `/project-types/${projectProposalTypeId}/daily-reports/${dailyReportId}` : `/project-types/${projectProposalTypeId}/daily-reports`;

        const method = dailyReportId ? api.patch : api.post;

        return method(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(createDailyReportKey(projectProposalTypeId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getFollowupsKey(projectProposalTypeId));

            navigate(-1);

            showToast('Acompanhamento criado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar o acompanhamento', 'danger');
        }
    });
};

export default useCreateOrUpdateDailyReport;
