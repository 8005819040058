import { ProjectTypeEnum } from 'types/models/project';

export const projectTypesNames = ['BID', 'Gerenciamento', 'Projetos de Arquitetura', 'Projetos Complementares'];

export const projectTypeTitleMap = {
    gerenciamento: 'Gerenciamento',
    bid: 'BID',
    'projetos-de-arquitetura': 'Projetos de arquitetura',
    'projetos-complementares': 'Projetos complementares'
};

export const projectTypeTitleByIdMap = {
    [ProjectTypeEnum.BID]: projectTypeTitleMap.bid,
    [ProjectTypeEnum.ComplementaryProjects]: projectTypeTitleMap['projetos-complementares'],
    [ProjectTypeEnum.Projects]: projectTypeTitleMap['projetos-de-arquitetura'],
    [ProjectTypeEnum.Management]: projectTypeTitleMap.gerenciamento
};
