// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import useAuth from 'store/auth/use-auth';
import { WhereClause } from 'types/graphql';
import { Person } from 'types/models/person';
import { Pagination } from 'types/pagination';
import { Option, Status } from 'types/general';
import people, { getAllPeopleOptionsKey } from './graphql';

const useGetPeopleOptions = (whereClause?: WhereClause<Partial<Person>>, isEnabled = true) => {
    const { auth } = useAuth();
    const account_id = auth.credentials.account.id;

    const where = {
        ...whereClause?.where,
        account_id: { _eq: account_id },
        status: { _eq: Status.Active }
    };

    const getAllAccountsOptions = () => apiGraphQl<Pagination<Option>>(people.getAll, { where }).then((data) => data.items);

    return useQuery(getAllPeopleOptionsKey(account_id), getAllAccountsOptions, { enabled: isEnabled });
};

export default useGetPeopleOptions;
