import { useMemo } from 'react';

import { useOutletContext, useParams } from 'react-router-dom';

import { CrudPageProps, GraphqlPaginationVariables } from 'types/graphql';

import { CreateJobPositionPayload } from 'types/models/configurations-classifications';

import { createOrUpdateJobPositionMutation } from '../../utils/utils';
import CreateOrUpdateJobPosition from './create-or-update';
import useCreateOrUpdateJobPosition from 'services/queries/configs-classifications/use-create-update-job-position';
import useGetJobPositionById from 'services/queries/configs-classifications/use-get-job-position-by-id';

const CreateOrUpdateConfigJobPositionPage = ({ title }: CrudPageProps) => {
    const { configJobPositionId } = useParams();

    const params = useOutletContext<GraphqlPaginationVariables<CreateJobPositionPayload>>();

    const {
        mutateAsync: mutateJobPosition,
        isLoading: isSubmitting,
        error: errorCreateUpdate
    } = useCreateOrUpdateJobPosition(configJobPositionId, params, createOrUpdateJobPositionMutation(configJobPositionId));

    const { data: jobPositionDetails, isLoading: isLoadingJobPosition, error: errorGetClassificationId } = useGetJobPositionById(configJobPositionId);

    const error = useMemo(() => errorCreateUpdate || errorGetClassificationId, [errorCreateUpdate, errorGetClassificationId]);

    const isBuilding = useMemo(() => isSubmitting || isLoadingJobPosition, [isSubmitting, isLoadingJobPosition]);

    const handleSubmit = async (data: CreateJobPositionPayload) => {
        try {
            await mutateJobPosition(data);
        } catch (error) {
            console.log('error', error);
        }
    };

    return <CreateOrUpdateJobPosition isBuilding={isBuilding} isSubmitting={isSubmitting} onSubmit={handleSubmit} defaultValues={jobPositionDetails} error={error} title={title || ''} />;
};

export default CreateOrUpdateConfigJobPositionPage;
