import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { createFinancialSetupKey } from './graphql';
import { useNavigate } from 'react-router-dom';
import { ProviderFinancialSetupPayload, ProviderFinancialPaymentType } from 'types/models/provider';
import { getProviderFinancialSetupsKeys } from '../providers/graphql';

type Payload = {
    budget: ProviderFinancialSetupPayload['budget'];
    period: ProviderFinancialSetupPayload['period'];
    withTechnicalRetention: ProviderFinancialSetupPayload['withTechnicalRetention'];
    measurements: Array<{
        order: number;
        expectedDate: string;
        predictedPercentage: number;
        predictedValue: number;
    }>;
    payments?: Array<{
        type: ProviderFinancialPaymentType;
        value: number;
        percentage: number;
        date: string;
    }>;
};

const useCreateFinancialSetup = (projectProposalTypeId?: number) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const fetcher = async (payload: Payload) => api.post(`/provider-financial-setups/${projectProposalTypeId}`, payload);

    return useMutation(createFinancialSetupKey(projectProposalTypeId), fetcher, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProviderFinancialSetupsKeys(projectProposalTypeId));

            navigate(-1);

            showToast('Configuração financeira criada com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao criar a configuração', 'danger');
        }
    });
};

export default useCreateFinancialSetup;
