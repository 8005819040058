import ModalFilter from 'components/modal/filter';
import { useEffect, useMemo } from 'react';
import Select from 'components/core/form/select';
import DatePicker from 'components/core/datepicker';
import { Controller, useForm } from 'react-hook-form';
import omitBy from 'lodash/omitBy';
import { formatDate } from 'utils/date';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetOptions } from 'services/queries/options/use-get-options';

import operational from 'services/queries/operationals/graphql';
import { OperationalFormState } from 'types/models/operationals';
import useResponsive from 'hooks/responsive/use-responsive';
import qs from 'qs';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import { OperationalListQueryFilterParsed } from '../list';
import { projectTypeStatuses } from 'utils/statuses';

type OperationalFilterProps = {
    onClose(): void;
};

const OperationalFilter = ({ onClose }: OperationalFilterProps) => {
    const { isMobile } = useResponsive();
    const { search } = useLocation();
    const navigate = useNavigate();

    const { data: customers, isLoading: isLoadingCustomers } = useGetOptions(operational.getCustomerByProject);
    const { data: people, isLoading: isLoadingPeople } = useGetOptions(operational.getProjectTypePersonResource);

    const filtersQueryParsed: OperationalListQueryFilterParsed = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);

    const initialPeriodStartDate = useGetQueryParam('filter[initialPeriodStartDate]');
    const initialPeriodEndDate = useGetQueryParam('filter[initialPeriodEndDate]');
    const finalPeriodStartDate = useGetQueryParam('filter[finalPeriodStartDate]');
    const finalPeriodEndDate = useGetQueryParam('filter[finalPeriodEndDate]');

    const { control, formState, handleSubmit, reset } = useForm<OperationalFormState>({
        mode: 'onSubmit'
    });

    useEffect(() => {
        const paramsWithFormatedDate = {
            ...(!!initialPeriodStartDate && { initialPeriodStartDate: formatDate(initialPeriodStartDate, 'MM/DD/YYYY') }),
            ...(!!initialPeriodEndDate && { initialPeriodEndDate: formatDate(initialPeriodEndDate, 'MM/DD/YYYY') }),
            ...(!!finalPeriodStartDate && { finalPeriodStartDate: formatDate(finalPeriodStartDate, 'MM/DD/YYYY') }),
            ...(!!finalPeriodEndDate && { finalPeriodEndDate: formatDate(finalPeriodEndDate, 'MM/DD/YYYY') }),
            ...(!!filtersQueryParsed.filter?.status && { status: filtersQueryParsed.filter?.status }),
            ...(!!filtersQueryParsed.filter?.person && { person: filtersQueryParsed.filter?.person }),
            ...(!!filtersQueryParsed.filter?.customer && { customer: filtersQueryParsed.filter?.customer })
        };

        reset(paramsWithFormatedDate);
    }, [reset, finalPeriodEndDate, initialPeriodStartDate, initialPeriodEndDate, finalPeriodStartDate, filtersQueryParsed.filter]);

    const clear = () => {
        onClose();
        navigate('.');
    };

    const submit = (data: OperationalFormState) => {
        const newDataWithFormatedDate = {
            ...data,
            initialPeriodStartDate: formatDate(data.initialPeriodStartDate, 'YYYYMMDD'),
            initialPeriodEndDate: formatDate(data.initialPeriodEndDate, 'YYYYMMDD'),
            finalPeriodStartDate: formatDate(data.finalPeriodStartDate, 'YYYYMMDD'),
            finalPeriodEndDate: formatDate(data.finalPeriodEndDate, 'YYYYMMDD')
        };

        const result: any = omitBy(newDataWithFormatedDate, (v) => !v || v === '-');

        if (!Object.keys(result)) {
            return clear();
        }

        const filters = qs.stringify(
            { filter: result },
            {
                addQueryPrefix: true,
                encode: false,
                arrayFormat: 'brackets'
            }
        );

        if (isMobile) {
            onClose();
        }

        if (!Object.keys(filters).length) {
            return;
        }

        navigate(filters);
    };

    return (
        <ModalFilter onClose={onClose} onSubmit={handleSubmit(submit)} onClear={clear}>
            <div className="gap-4 flex flex-col">
                <Controller
                    name="customer"
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = customers?.items?.filter((item) => fieldValuesNumber?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={customers?.items || []}
                                label="Cliente(s)"
                                isMulti={true}
                                isClearable={true}
                                placeholder="Selecione uma opção"
                                error={(formState.errors as any).customer?.message}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isLoading={isLoadingCustomers}
                            />
                        );
                    }}
                />
                <div>
                    <label htmlFor="initialPeriod" className="text-base-500 mb-4 block">
                        Período Inicial
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                        <Controller
                            name="initialPeriodStartDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data inicial" error={formState.errors.initialPeriodStartDate?.message} {...field} />}
                        />
                        <Controller
                            name="initialPeriodEndDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data final" error={formState.errors.initialPeriodEndDate?.message} {...field} />}
                        />
                    </div>
                </div>
                <div className="">
                    <label htmlFor="finalPeriod" className="text-base-500 mb-4 block">
                        Período Final
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                        <Controller
                            name="finalPeriodStartDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data inicial" error={formState.errors.finalPeriodStartDate?.message} {...field} />}
                        />
                        <Controller
                            name="finalPeriodEndDate"
                            control={control}
                            render={({ field }) => <DatePicker placeholderText="Data final" error={formState.errors.finalPeriodEndDate?.message} {...field} />}
                        />
                    </div>
                </div>

                <Controller
                    name="person"
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = people?.items?.filter((item) => fieldValuesNumber?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={people?.items || []}
                                isMulti={true}
                                isClearable={true}
                                label="Gerente(s)"
                                placeholder="Selecione uma opção"
                                error={(formState.errors as any).person?.message}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                                isLoading={isLoadingPeople}
                            />
                        );
                    }}
                />

                <Controller
                    name="status"
                    control={control}
                    render={({ field }) => {
                        const fieldValuesNumber = field.value?.map((item) => Number(item));
                        const value = projectTypeStatuses.filter((item) => fieldValuesNumber?.includes(item.value));

                        return (
                            <Select
                                {...field}
                                value={value}
                                isMulti={true}
                                isClearable={true}
                                options={projectTypeStatuses}
                                label="Status"
                                placeholder="Selecione uma opção"
                                error={(formState.errors as any).status?.message}
                                onChange={(option: any) => {
                                    const ids = option.map((item) => item.value);

                                    field.onChange(ids);
                                }}
                            />
                        );
                    }}
                />
            </div>
        </ModalFilter>
    );
};

export default OperationalFilter;
