import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import apiGraphQl from 'services/graphql';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Profile } from 'types/models/profile';
import { getAllKey } from '../crud/graphql';
import queries, { createProfileKey } from './graphql';

const useCreateOrUpdateProfile = (params: GraphqlPaginationVariables<Profile>, profileId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: Pick<Profile, 'name' | 'slug' | 'status'>) => {
        return apiGraphQl<{ item: Pick<Profile, 'id'> }>(!!profileId ? queries.updateProfile : queries.createProfile, {
            payload,
            ...(!!profileId && { id: profileId })
        });
    };

    return useMutation(createProfileKey, request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getAllKey('profile', params));
        },
        onError: () => {
            showToast(`Ocorreu um erro ao tentar ${!!profileId ? 'atualizar' : 'criar'} o perfil`, 'danger');
        }
    });
};

export default useCreateOrUpdateProfile;
