import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { ProviderFinancialPaymentType } from 'types/models/provider';
import dictionary from 'utils/dictionary';
import { formatMoney } from 'utils/money';
import { array, bool, number, object, string } from 'yup';

export const getProviderFinancialSetupsQuery = (projectProposalTypeId?: number) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetProviderFinancialSetups',
                provider_financial_setup: {
                    __args: {
                        where: {
                            timeline_budget: {
                                timeline: {
                                    project_proposal_type_id: { _eq: projectProposalTypeId }
                                }
                            }
                        },
                        order_by: { id: new EnumType('asc') }
                    },
                    id: true,
                    period: true,
                    with_technical_retention: true,
                    timeline_budget: {
                        value: true,
                        provider: {
                            id: true,
                            title: true
                        }
                    },
                    provider_financial_measurements_aggregate: {
                        aggregate: { count: true }
                    },
                    provider_financial_payments: {
                        percentage: true,
                        value: true
                    }
                }
            }
        },
        { pretty: true }
    );
};

export const getTimelineBudgetQuery = (projectProposalTypeId?: number, providerId?: number) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetTimelineBudgetQuery',
            timeline: {
                __args: {
                    where: {
                        project_proposal_type_id: { _eq: projectProposalTypeId },
                        provider_id: { _eq: providerId }
                    },
                    order_by: { id: new EnumType('asc') }
                },
                budget: {
                    id: true,
                    value: true
                }
            }
        }
    });
};

export const financialSetupSchema = (
    budget: number,
    percentageAverage: (value: any, context: any) => boolean,
    handlePercentage: (value: any, context: any) => boolean,
    budgetAverage: (value: any, context: any) => boolean
) => {
    return object({
        withPaymentStart: number().oneOf([0, 1]),
        budget: number().required(dictionary.validation.required).positive('O valor precisa ser maior que zero'),
        measurements: array(
            object({
                order: number().required(dictionary.validation.required),
                expectedDate: string().required(dictionary.validation.required),
                predictedPercentage: number().required(dictionary.validation.required).min(0, dictionary.validation.number.min(0)).max(100, dictionary.validation.number.max(100)),
                predictedValue: object({
                    formattedValue: string(),
                    value: string(),
                    floatValue: number()
                })
            })
        ).when('period', (period, schema) =>
            Boolean(period)
                ? schema
                      .test('percentageAverage', 'A soma dos percentuais das medições não é igual à 100% do orçamento disponível', percentageAverage)
                      .test('budgetAverage', `A soma dos valores das medições não é igual ao valor do orçamento disponível: ${formatMoney(budget)}`, budgetAverage)
                : undefined
        ),
        payments: array(
            object({
                type: number().oneOf([ProviderFinancialPaymentType.Start, ProviderFinancialPaymentType.DirectBilling]),
                value: object({
                    formattedValue: string(),
                    value: string(),
                    floatValue: number()
                }),
                percentage: number().required(dictionary.validation.required).min(0, dictionary.validation.number.min(0)).max(100, dictionary.validation.number.max(100)),
                date: string().required(dictionary.validation.required)
            })
        )
            .when('withPaymentStart', (withPaymentStart, schema) => (withPaymentStart === 1 ? schema.required(dictionary.validation.required).min(1) : schema.notRequired()))
            .test('handlePercentage', handlePercentage),
        period: number().required(dictionary.validation.required).positive('O período precisa ser maior que zero'),
        withTechnicalRetention: bool().default(false)
    });
};
