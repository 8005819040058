// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import useToast from 'hooks/toast/use-toast';
import { createCustomerKey } from '../customers/graphql';
import api from 'services/api';

const useContactMutation = (props: { contactId?: string; endpoint: string; queryKey?: any }) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { contactId, endpoint, queryKey } = props || {};

    const isEdit = !!contactId;

    const request = async (payload: any) => {
        const apiService = isEdit ? api.patch : api.post;

        return apiService<any>(isEdit ? `${endpoint}/${contactId}` : endpoint, payload).then(({ data }) => data);
    };

    return useMutation(createCustomerKey(), request, {
        onSuccess: () => {
            if (queryKey) {
                queryClient.invalidateQueries(queryKey);
            }

            navigate(-1);

            showToast(`Contato ${isEdit ? 'atualizado' : 'criado'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isEdit ? 'atualizar' : 'criar'} o contato`, 'danger');
        }
    });
};

export default useContactMutation;
