import { useMutation } from 'react-query';
import api from 'services/api';

type Payload = {
    taskId: number;
    status: number;
};

const useChangeTaskStatus = () => {
    const request = async ({ status, taskId }: Payload) => api.put(`/tasks/${taskId}/status`, { status });

    return useMutation(request);
};

export default useChangeTaskStatus;
