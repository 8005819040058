// Helpers
import { Phone } from 'types/models/person';
import { FileApiModel } from 'types/models/file';
import { Modify, Option, Status } from 'types/general';

export type ReducerAction = {
    type: MeetingRecordActions;
    payload: any;
};

export type MeetingRecordActions = 'SET_STEP_COMPLETED' | 'SET_CURRENT_STEP' | 'SET_CONTENT' | 'TOGGLE_LOADER';

export type Step = {
    slug: MeetingSteps;
    label: string;
};

export type MeetingPayload = {
    dateFormatted: string;
    editors: Option[];
    title: string;
    project?: number;
    projectProposalType?: number;
};

export type ParticipantPayload = {
    internal: Array<{
        validationRequired: boolean;
        person: Option;
    }>;
    external: Array<{
        name: string;
        email: string;
        phones: Phone[];
        jobPosition?: string;
        company?: string;
        validationRequired: boolean;
    }>;
};

export type SubjectPayload = {
    title: string;
    description: string;
};

export type Content = {
    meeting: MeetingPayload;
    files: Array<Partial<File & FileApiModel>>;
    participants: ParticipantPayload;
    subjects: SubjectPayload[];
};

export type MeetingRecordState = {
    completeds: Step[];
    content: Content;
    currentStep: Step;
    isLoading: boolean;
    steps: Step[];
};

export type MeetingRecordPayload = {
    participants: any[];
    files: number[];
    subjects: SubjectPayload[];
    status: Status;
} & Modify<MeetingPayload, { editors: number[] }>;

export enum MeetingSteps {
    Meeting = 'MEETING',
    Files = 'FILES',
    Participants = 'PARTICIPANTS',
    Subjects = 'SUBJECTS',
    Publish = 'PUBLISH'
}

export type Steps = 'MEETING' | 'FILES' | 'PARTICIPANTS' | 'SUBJECTS' | 'PUBLISH';

export type MeetingRecordContextType = {
    state: MeetingRecordState;
    setCurrentStep: (slug: MeetingSteps) => void;
    changeStep: (currentStepSlug: MeetingSteps, nextStepSlug?: MeetingSteps, previousStepSlug?: MeetingSteps) => void;
    completeStep: (step: Step) => void;
    getStep: (slug: MeetingSteps) => void;
    setContent: (payload: any, jumpToNextStep?: boolean) => void;
};
