import { useCustomerDetails } from 'pages/private/clients/useCustomerDetails';
import React, { useMemo } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { generateFileUrl } from 'utils/file';
import Avatar from '@mui/material/Avatar/Avatar';
import { CardHeader } from '@mui/material';
import hexToRgba from 'utils/hex-to-rgba';
import Icon from 'components/core/icon';
import { Option } from 'types/general';
import Breadcrumb from 'components/core/breadcrumb';
import useResponsive from 'hooks/responsive/use-responsive';

const CustomerTabDetails = () => {
    const { title, juridic_name, juridic_document, juridic_fantasy_name, account, file, type_business, address } = useCustomerDetails();
    const { palette } = useTheme();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const amounts: Option[] = [
        {
            label: 'Total de contratos',
            value: 0
        },
        {
            label: 'Total de projetos ativos',
            value: 0
        }
    ];

    const details: Option<any>[] = [
        {
            label: 'Título',
            value: title
        },
        {
            label: 'Nome jurídico',
            value: juridic_name
        },
        {
            label: 'Nome fantasia',
            value: juridic_fantasy_name
        },
        {
            label: 'CNPJ',
            value: juridic_document
        },
        {
            label: 'Conta',
            value: account?.name
        },
        {
            label: 'Tipo de negócio',
            value: type_business?.title
        }
    ];

    const formattedAddresses = useMemo(
        () =>
            address
                ?.filter(({ address }) => !!address)
                ?.map(({ address }, index) => {
                    const { street, number, state, city, neighbourhood, zip } = address;

                    return <p key={`address_${index}`}>{`${[street, number, neighbourhood, city?.name].join(', ')} - ${state?.initials}, ${zip}`}</p>;
                }),
        [address]
    );

    return (
        <div>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-8" />}
            <div className="mb-7">
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#FFF', border: '1px solid #c4c4c4', borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                            {file && <img src={generateFileUrl(file?.filename, file?.path)} className="max-w-[90%]" />}
                        </Avatar>
                    }
                    classes={{ root: 'p-0' }}
                    title={title}
                    subheader={juridic_name}
                    titleTypographyProps={{ fontSize: 18, color: palette.heading, fontWeight: 500 }}
                />
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 mb-6">
                {amounts.map(({ value, label }, index) => (
                    <CardHeader
                        key={`${label}-${index}`}
                        avatar={
                            <Avatar sx={{ bgcolor: hexToRgba(palette.info[100], 0.2), borderRadius: '14px !important', width: 48, height: 48 }} aria-label="recipe">
                                <Icon name="ico-chart" color={palette.info[500]} />
                            </Avatar>
                        }
                        classes={{ root: 'p-0' }}
                        title={label}
                        subheader={value}
                        titleTypographyProps={{ color: palette.grey[500] }}
                        subheaderTypographyProps={{ color: palette.heading }}
                    />
                ))}
            </div>

            <p className="uppercase mb-3 text-gray-600">Detalhes</p>
            <div className="grid grid-cols-1 sm:grid-cols-3 mb-10 gap-4">
                {details.map(({ label, value }, index) => (
                    <div className="flex flex-col" key={`detail-${index}`}>
                        <p className="mb-1" style={{ color: palette.grey[500] }}>
                            {label}
                        </p>
                        <p className="text-sm" style={{ color: palette.heading }}>
                            {value || '-'}
                        </p>
                    </div>
                ))}
            </div>
            {Boolean(formattedAddresses?.length) && (
                <>
                    <p className="uppercase mb-3 text-gray-600">Endereço{!!formattedAddresses?.length && 's'}</p>
                    <div className="mb-10 gap-4 text-sm" style={{ color: palette.heading }}>
                        {formattedAddresses}
                    </div>
                </>
            )}
        </div>
    );
};

export default CustomerTabDetails;
