import Editor from 'components/core/form/editor';
import Select from 'components/core/form/select';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateAssetPayload } from 'types/models/assets';
import { assetsModalities } from 'utils/statics';
import CommonFields from '../commonFields';

const PeripheralsFields = () => {
    const { control, formState } = useFormContext<CreateAssetPayload>();

    return (
        <>
            <CommonFields />
            <Controller
                name="modality"
                control={control}
                render={({ field }) => {
                    const value = assetsModalities.find((item) => item.value === field.value);
                    return (
                        <Select
                            {...field}
                            value={value}
                            options={assetsModalities}
                            error={formState.errors?.modality?.message}
                            onChange={(option: any) => field?.onChange(option?.value)}
                            label="Modalidade"
                            placeholder="Selecione a modalidade"
                        />
                    );
                }}
            />
            <Controller
                name="observations"
                control={control}
                render={({ field }) => <Editor parentClasses="col-span-3" {...field} label="Observação" error={formState.errors?.observations?.message} placeholder="Observação" />}
            />
        </>
    );
};

export default PeripheralsFields;
