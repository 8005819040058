import api from 'services/api';
import { useQuery } from 'react-query';
import { getReportKey } from './keys';

const useGetResourcesReport = (page: number) => {
    const request = async () => {
        const { data } = await api.get<any>(`reports/project-types-resources?page=${page}`);

        return data;
    };

    return useQuery(getReportKey('resources', page), request);
};

export default useGetResourcesReport;

//TODO: type response - HIGOR
