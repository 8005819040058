import React, { useMemo, useState } from 'react';

// Dependencies
import Table from '@mui/material/Table/Table';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import IconButton from '@mui/material/IconButton/IconButton';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import { Link, Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';

// Components
import Empty from 'components/empty';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import Spinner from 'components/core/spinner';
import AddButton from 'components/buttons/add';

// Helpers
import { ProjectTypeDocument } from 'types/models/project';
import { OperationalDetailsPageContext } from 'pages/private/operationals/view';
import useGetProjectTypeDocuments from 'services/queries/projects/use-get-project-type-documents';
import ConfirmModal from 'components/core/modal/confirm';
import TechnicalDocument from 'pages/private/operationals/documents/technical-documents';
import { generateFileUrl } from 'utils/file';
import ExternalButton from 'components/buttons/external';
import useDelete from 'services/queries/crud/use-delete';
import { useQueryClient } from 'react-query';
import { getProjectTypeDocumentsKey } from 'services/queries/projects/graphql';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';

type DocumentsProps = {
    projectProposalTypeIdProp?: number;
    withActions?: boolean;
    withTitle?: boolean;
    className?: string;
    isTransactionOutcome?: boolean;
    outcomeId?: number;
};

const infoButton = { classes: { child: 'text-system-info-100' } };
const warningButton = { classes: { child: 'text-system-warning-100' } };
const dangerButton = { classes: { child: 'text-system-danger-100' } };

const queryFields = jsonToGraphQLQuery({
    id: true,
    title: true,
    file: {
        id: true,
        filename: true,
        path: true
    },
    classification: {
        name: true,
        classification: { name: true }
    }
});

const Documents = ({ projectProposalTypeIdProp, withActions, withTitle, className, isTransactionOutcome, outcomeId }: DocumentsProps) => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { isSmallerThenTabletLandscape } = useResponsive();
    const { pathname } = useLocation();

    const queryClient = useQueryClient();

    const [documentToDeleteId, setDocumentToDeleteId] = useState<number>();
    const [viewTechnicalDocumentId, setViewTechnicalDocumentId] = useState<number | null>(null);

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const projectProposalTypeId = useMemo(() => projectProposalTypeIdProp || ctx?.projectProposalType?.id, [projectProposalTypeIdProp, ctx?.projectProposalType]);

    const { data: documents, isLoading } = useGetProjectTypeDocuments(queryFields, projectProposalTypeId, isTransactionOutcome, outcomeId);

    const endpoint = useMemo(
        () => (isTransactionOutcome ? `/project-types/${projectProposalTypeId}/transactions/${outcomeId}/documents` : `/project-types/${projectProposalTypeId}/documents`),
        [isTransactionOutcome, outcomeId, projectProposalTypeId]
    );

    const { mutateAsync: deleteOutcomeTransactionDocument, isLoading: isDeletingDocumet } = useDelete({
        apiPayload: { endpoint, useApiTodelete: true }
    });

    const handleVisualizeTechnicalDocument = (documentItem: Partial<ProjectTypeDocument>) => {
        if (!documentItem.isTechnical) {
            return;
        }

        return setViewTechnicalDocumentId(documentItem?.id!);
    };

    const handleGoTo = (path: string) => () => navigate(path);

    const generateCategoryText = (document: Partial<ProjectTypeDocument>) => {
        return document.classification?.father?.name ? `${document.classification?.father?.name} > ${document.classification?.name}` : document.classification?.name;
    };

    const handleShowFile = (document: Partial<any>) => () => window.open(generateFileUrl(document.file?.filename || '', document.file?.path || ''), '_blank');

    const handleDelete = async () => {
        try {
            await deleteOutcomeTransactionDocument({ id: documentToDeleteId });
            queryClient.invalidateQueries(getProjectTypeDocumentsKey(projectProposalTypeId));
            setDocumentToDeleteId(undefined);
        } catch (error) {
            console.log('handleDeleteDocument', error);
        }
    };

    if (isLoading) {
        return <Spinner fixed={false} parentClasses="h-[calc(100%-49px)] !p-4" />;
    }

    return (
        <>
            {isSmallerThenTabletLandscape && pathname.includes('documentos') && <Breadcrumb className="mb-4" />}
            {withTitle && (
                <Text as="h6" variant="h6" className="text-heading mb-4">
                    Documentos
                </Text>
            )}
            {Boolean(documents?.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Título
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        {isTransactionOutcome ? 'Classificação' : 'Categoria'}
                                    </Text>
                                </TableCell>
                                {isTransactionOutcome && (
                                    <TableCell>
                                        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                            Arquivo
                                        </Text>
                                    </TableCell>
                                )}
                                {withActions && (
                                    <TableCell classes={{ root: 'w-[130px]' }}>
                                        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                            Ações
                                        </Text>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents?.map((item) => {
                                return (
                                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {item.name || '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {generateCategoryText(item)}
                                            </Text>
                                        </TableCell>
                                        {isTransactionOutcome && (
                                            <TableCell>
                                                <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                    <ExternalButton onClick={handleShowFile(item)}>Visualizar</ExternalButton>
                                                </Text>
                                            </TableCell>
                                        )}

                                        {withActions && (
                                            <TableCell>
                                                {!isTransactionOutcome && (
                                                    <Tooltip placement="top" title="Visualizar" disableFocusListener={true}>
                                                        <IconButton
                                                            component={Link}
                                                            to={!item.isTechnical ? item.id.toString() : ''}
                                                            TouchRippleProps={infoButton}
                                                            onClick={() => handleVisualizeTechnicalDocument(item)}
                                                            className="hover:bg-system-info-100 hover:bg-opacity-30">
                                                            <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {!item.isTechnical && (
                                                    <div className="flex">
                                                        <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                            <IconButton
                                                                component={Link}
                                                                to={isTransactionOutcome ? `documentos/${item.id}/editar` : `${item.id}/editar`}
                                                                TouchRippleProps={warningButton}
                                                                className="hover:bg-system-warning-100 hover:bg-opacity-30">
                                                                <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                                            <IconButton
                                                                TouchRippleProps={dangerButton}
                                                                className="hover:bg-system-danger-100 hover:bg-opacity-30"
                                                                onClick={() => setDocumentToDeleteId(item.id)}>
                                                                <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {withActions && (
                            <TableFooter>
                                <TableRow>
                                    <TableCell classes={{ root: 'py-2 border-none' }} colSpan={2}>
                                        <AddButton linkComponent={Link} to={isTransactionOutcome ? 'documentos/novo' : 'novo'}>
                                            Novo documento
                                        </AddButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </TableContainer>
            ) : (
                <Empty
                    title="Nenhum documento cadastrado."
                    className={className}
                    buttonLabel={withActions ? 'Enviar documento' : undefined}
                    onClick={withActions ? handleGoTo(isTransactionOutcome ? 'documentos/novo' : 'novo') : undefined}
                />
            )}
            {Boolean(documentToDeleteId) && (
                <ConfirmModal
                    title="Apagar documento"
                    description="Você tem certeza que deseja apagar este documento?"
                    onClose={() => setDocumentToDeleteId(undefined)}
                    isLoading={isDeletingDocumet}
                    onConfirmAction={handleDelete}
                />
            )}

            {viewTechnicalDocumentId && <TechnicalDocument setViewTechnicalDocumentId={setViewTechnicalDocumentId} documentId={viewTechnicalDocumentId.toString()} />}
            <Outlet />
        </>
    );
};

export default Documents;
