import classNames from 'classnames';
import Card from 'components/core/card';
import React from 'react';
import { ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
type Props = {
    className?: string;
    title: string;
};

const ProjectChartSkeleton = ({ className, title }: Props) => {
    const data: ChartData<'doughnut', number[], string> = {
        labels: [],
        datasets: [
            {
                data: [33, 33, 33],
                backgroundColor: ['rgb(243 244 246)', 'rgb(243 244 246)', 'rgb(243 244 246)'],
                borderWidth: 0,
                borderRadius: 30,
                spacing: 4,
                circumference: 252,
                rotation: 234
            }
        ]
    };

    return (
        <Card className={classNames('flex-1 flex flex-col py-6 px-4 pt-6', className)}>
            <p className="text-heading text-xl font-medium mb-0">{title}</p>

            <div className="w-[241px] m-auto relative flex items-center justify-center animate-pulse">
                <Doughnut
                    data={data}
                    options={{
                        cutout: 95
                    }}
                />
                <div className="absolute flex flex-col items-center mt-8">
                    <div className="h-[40px] w-[44px] bg-gray-100 mb-1"></div>
                    <div className="h-[20px] w-[56px] bg-gray-100"></div>
                </div>
            </div>

            <div className="grid grid-cols-3 gap-2 divide-x animate-pulse">
                {[...new Array(3).keys()].map((i) => (
                    <div key={`chart_legend_${i}`} className="flex flex-col items-center px-4">
                        <span className="w-[6px] h-[6px] rounded-[2px] mb-1 bg-gray-100"></span>
                        <p className="text-heading text-2xl font-medium w-[30px] h-[30px] mb-1 bg-gray-100"></p>
                        <p className="text-base-500 text-sm w-[58px] h-[18px] bg-gray-100"></p>
                    </div>
                ))}
            </div>
        </Card>
    );
};

export default ProjectChartSkeleton;
