import TableCell from '@mui/material/TableCell/TableCell';
import useTheme from '@mui/material/styles/useTheme';
import Badge from 'components/core/badge';
import useToast from 'hooks/toast/use-toast';
import ListPage from 'pages/private/crud/list';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllKey } from 'services/queries/crud/graphql';
import { getProjectTypeTermsKey } from 'services/queries/projects/graphql';
import useGetTermPrintUrl from 'services/queries/terms/use-get-term-print-url';
import { ColorVariant } from 'types/colors';
import { CrudPageProps } from 'types/graphql';
import { Page } from 'types/models/page';
import { TermStatus } from 'types/models/project';

type Colors = {
    [key: string | number]: ColorVariant;
};

type TermsOriginOperationalProps = {
    page?: Page;
};

const statusesLabel = {
    [TermStatus.Waiting]: 'Aguardando impressão',
    [TermStatus.Created]: 'Termo gerado',
    [TermStatus.Finalized]: 'Termo finalizado'
};

const statusesColors: Colors = {
    [TermStatus.Waiting]: 'grey',
    [TermStatus.Created]: 'info',
    [TermStatus.Finalized]: 'success'
};

const TermsOriginOperational = ({ page }: TermsOriginOperationalProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { projectProposalTypeId } = useParams();
    const { mutateAsync: getPrintUrl } = useGetTermPrintUrl();

    const { showToast } = useToast();

    const listConfig: CrudPageProps = useMemo(() => {
        const handlePrint = (projectTypeTermId: number) => async () => {
            try {
                showToast('Gerando termo para impressão...', 'warning');

                const url = await getPrintUrl({
                    projectProposalTypeId: projectProposalTypeId?.toString(),
                    projectTypeTermId: projectTypeTermId.toString()
                });

                queryClient.invalidateQueries(
                    getAllKey('project_type_term', {
                        limit: 10,
                        page: 1,
                        offset: 0,
                        where: {
                            project_proposal_type_id: { _eq: projectProposalTypeId }
                        },
                        orderBy: { number: 'desc' }
                    })
                );

                queryClient.invalidateQueries(getProjectTypeTermsKey(Number(projectProposalTypeId) || 0));

                window.open(url, '_blank');
            } catch (error) {
                showToast('Houve um erro ao imprimir o termo', 'danger');
                console.log('handlePrint', error);
            }
        };

        const handleGoTo = (path: string) => () => navigate(path);

        const createCustomActions = (item: any) => {
            const arr = [
                {
                    label: 'Abrir',
                    icon: { name: 'ico-show', color: palette.grey[500] },
                    onClick: handleGoTo(item?.id?.toString())
                }
            ];

            if (item.term_status !== TermStatus.Finalized) {
                arr.push(
                    {
                        label: 'Editar',
                        icon: { name: 'ico-edit', color: palette.grey[500] },
                        onClick: handleGoTo(`${item?.id}/editar`)
                    },
                    {
                        label: 'Imprimir',
                        icon: { name: 'ico-printer', color: palette.grey[500] },
                        onClick: () => handlePrint(item.id)()
                    }
                );
            }

            return arr;
        };

        return {
            customActions: createCustomActions,
            canShowDeleteButton: (item: any) => item.term_status !== TermStatus.Finalized,
            graphql: {
                table: 'project_type_term',
                searchableField: 'term_type.title',
                statusPath: 'status',
                orderBy: { number: 'desc' },
                columns: [
                    {
                        name: 'id',
                        options: { display: 'excluded' }
                    },
                    {
                        name: 'number',
                        label: 'Código'
                    },
                    {
                        name: 'term_type.title',
                        label: 'Tipo'
                    },
                    {
                        name: 'person.title',
                        label: 'Criador'
                    },
                    {
                        name: 'term_status',
                        label: 'Status',
                        options: {
                            customBodyRender: (value) => {
                                return Boolean(statusesLabel[value]) ? <Badge variant={statusesColors[value] || 'grey'}>{statusesLabel[value]}</Badge> : '-';
                            }
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                        }
                    }
                ],
                where: {
                    project_proposal_type_id: { _eq: projectProposalTypeId }
                }
            },
            api: {
                endpoint: `/project-types/${projectProposalTypeId}/terms`,
                useApiTodelete: true
            },
            title: 'Termos',
            withBorder: true
        };
        // eslint-disable-next-line
    }, [projectProposalTypeId, getPrintUrl, palette.grey, queryClient]);

    return <ListPage page={page} {...listConfig} />;
};

export default TermsOriginOperational;
