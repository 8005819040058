import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getPersonDetailsTabKey } from './graphql';
import { Status } from 'types/general';

type Aggregate = {
    aggregate: {
        count: number;
    };
};

type Response = {
    totalProjects: Aggregate;
    totalContracts: Aggregate;
    totalTasks: Aggregate;
    tasksByStatus: { id: number; title: string; slug: string; total: Aggregate }[];
    tasks?: { accomplishedTime: number }[];
};

const useGetPersonDetailsTab = (personId?: string) => {
    const { showToast } = useToast();

    const fetcher = async () => {
        const { totalProjects, totalContracts, totalTasks, tasksByStatus, tasks } = await apiGraphQl<Response>(
            `query getPersonDetailsTab($personId: Int!) {
                totalProjects: project_type_person_resource_aggregate(where: {person_id: {_eq: $personId}, project_proposal_type: {project_proposal: {project: {status: {_eq: 1}}}}, status: {_neq: -1}}) {
                  aggregate {
                    count
                  }
                }
                totalContracts: person_contract_aggregate(where: {person_id: {_eq: $personId}}) {
                  aggregate {
                    count
                  }
                }
                totalTasks: task_aggregate(where: {person_id: {_eq: $personId}}) {
                  aggregate {
                    count
                  }
                }
                tasks: task(where: {person_id: {_eq: $personId}, status: {_eq: ${Status.Active}}} ){
                    accomplishedTime: accomplished_time
                }
                tasksByStatus: task_status {
                  id
                  title
                  slug
                  total: tasks_aggregate(where: {person_id: {_eq: $personId}}) {
                    aggregate {
                      count
                    }
                  }
                }
              }`,
            { personId }
        );

        const getAggregate = (data?: any) => ~~data?.aggregate?.count;

        const colors = new Map([
            ['pending', '#ffdd70'],
            ['stopped', '#FD8A84'],
            ['done', '#40B05F'],
            ['processing', '#29b6f6'],
            ['partial', '#E8E9EB']
        ]);

        return {
            totalProjects: getAggregate(totalProjects),
            totalContracts: getAggregate(totalContracts),
            totalTasks: getAggregate(totalTasks),
            tasksByStatusData: {
                total: {
                    label: 'Tarefas',
                    value: getAggregate(totalTasks)
                },

                items: [
                    tasksByStatus.map(({ total, slug }) => ({
                        color: colors.get(slug) || '',
                        value: getAggregate(total)
                    }))
                ],
                legends: tasksByStatus.map(({ total, slug, title }) => ({
                    color: colors.get(slug) || '',
                    value: getAggregate(total),
                    label: title
                }))
            },
            tasks
        };
    };

    return useQuery(getPersonDetailsTabKey(personId), fetcher, {
        enabled: !!personId,
        onError: () => {
            showToast('Tivemos um problema ao obter os detalhes dessa pessoa. Tente novamente.', 'danger');
        }
    });
};

export default useGetPersonDetailsTab;
