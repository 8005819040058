import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';

export const getDashboardsKey = ['dashboards'];

export const getDashboardsQuery = jsonToGraphQLQuery(
    {
        query: {
            __name: 'GetDashboards',
            items: {
                __aliasFor: 'dashboard_template',
                __args: {
                    where: {
                        status: { _eq: Status.Active }
                    },
                    order_by: { name: new EnumType('asc') }
                },
                value: { __aliasFor: 'id' },
                label: { __aliasFor: 'name' }
            }
        }
    },
    {
        pretty: true
    }
);
