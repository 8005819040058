import useTheme from '@mui/material/styles/useTheme';
import Card from 'components/core/card';
import Icon from 'components/core/icon';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';

export type DRECardProps = {
    title: string;
    isLoading: boolean;
    data: any;
    icon?: string;
};

export const DREtitleClasses = 'text-base-500 block font-normal text-center sm:text-left !text-base mb-2';
export const DREvalueClasses = (isLess?: boolean) => `font-medium !text-2xl text-heading leading-none ${isLess ? 'text-[#FD7972]' : ''}`;

const SimpleCard = ({ isLoading, data, title, icon }: DRECardProps) => {
    const { palette } = useTheme();

    return (
        <Card className="py-4 px-4 shadow-lg rounded-2xl border border-base-200 min-h-[194px] sm:min-h-[130px]">
            <div className="flex flex-col sm:flex-row items-center flex-wrap justify-center sm:justify-between h-full gap-4">
                <div className="flex flex-col">
                    <Text className={DREtitleClasses}>{title}</Text>
                    {isLoading ? (
                        <div className="p-4">
                            <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                        </div>
                    ) : (
                        <Text as="strong" className={DREvalueClasses()}>
                            {data}
                        </Text>
                    )}
                </div>
                {!!icon && (
                    <div className="flex items-center justify-center rounded-[14px] h-[64px] w-[64px] bg-secondary-100 bg-opacity-40">
                        <Icon className="text-secondary-500 font-bold text-2xl" width={32} height={32} name={icon} />
                    </div>
                )}
            </div>
        </Card>
    );
};

export default SimpleCard;
