// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { Pagination } from 'types/pagination';
import personContractStatus, { getAllPersonContractStatusOptionsKey } from './graphql';

const useGetPersonContractStatusOptions = (enabled = false) => {
    const getPersonContractStatusOptions = () => apiGraphQl<Pagination<Option>>(personContractStatus.getAllOptions).then((data) => data.items);

    return useQuery(getAllPersonContractStatusOptionsKey, getPersonContractStatusOptions, { enabled });
};

export default useGetPersonContractStatusOptions;
