import React from 'react';
import { RouteObject } from 'react-router-dom';
import { CrudPageProps } from 'types/graphql';
import ListPage from 'pages/private/crud/list';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import Icon from 'components/core/icon';
import theme from 'settings/theme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import CreateOrUpdateMenuGroupPage from 'pages/private/menu/create-or-update';
import { Rule } from 'types/permissions';
import Page from 'pages/shared/page';

const [list]: CrudPageProps[] = [
    {
        graphql: {
            table: 'menu_group',
            searchableField: 'name',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'name',
                    label: dictionary.content.graphql['name']
                },
                {
                    name: 'icon',
                    label: dictionary.content.graphql['icon'],
                    options: {
                        customBodyRender: (value) => (
                            <Tooltip placement="right" title={value} disableFocusListener={true} className="float-left">
                                <span>
                                    <Icon name={value} color={theme.extend.colors.secondary[500]} />
                                </span>
                            </Tooltip>
                        )
                    }
                },
                {
                    name: 'order_menu',
                    label: dictionary.content.graphql['order_menu']
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ]
        },
        title: 'Menu'
    }
];

const menu: RouteObject[] = [
    {
        path: 'menu',
        element: <Page Component={ListPage} code="menu" permissions={[Rule.List]} {...list} />,
        children: [
            {
                path: 'novo',
                element: <Page Component={CreateOrUpdateMenuGroupPage} withModal={true} code="menu" permissions={[Rule.Create]} />
            },
            {
                path: 'editar/:menuGroupId',
                element: <Page Component={CreateOrUpdateMenuGroupPage} withModal={true} code="menu" permissions={[Rule.Update]} />
            }
        ]
    }
];

export default menu;
