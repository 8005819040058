// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import project, { getCisoKey } from './graphql';
import { ProjectManagementInformation } from 'types/models/project';

type Response = {
    project_management_information_by_pk: Partial<ProjectManagementInformation>;
};

const useGetCiso = (cisoId?: string) => {
    const fetcher = () => apiGraphQl<Response>(project.getCiso, { cisoId }).then((response) => response.project_management_information_by_pk);

    return useQuery(getCisoKey(cisoId), fetcher, { enabled: Boolean(cisoId) });
};

export default useGetCiso;
