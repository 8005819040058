import { useMutation } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import api from 'services/api';
import { UserResetPassword } from 'types/models/user';

const useResetUserPassword = () => {
    const { showToast } = useToast();

    const fetcher = async (payload: UserResetPassword) => {
        return api.patch(`users/password`, payload);
    };

    return useMutation(fetcher, {
        onSuccess: () => {
            showToast(`Senha alterada com sucesso`, 'success', { duration: 5000 });
        },
        onError: (error) => {
            console.error(error);
            showToast(`Ocorreu um erro ao renovar a sua senha em nosso sistema`, 'danger');
        }
    });
};

export default useResetUserPassword;
