import CreateProjectProposalContent from './proposal';
import { CreateProposalPageProps } from '../utils/types';
import { ProjectTypeEnum } from 'types/models/project';

const CreateProjectProposal = ({ defaultValues, isBuilding, isSubmitting, onSubmit, projectProposalId, projectTypeId, projectStatusSlug, projectDetails }: CreateProposalPageProps) => {
    return (
        <CreateProjectProposalContent
            title="Projetos de Arquitetura"
            projectType={ProjectTypeEnum.Projects}
            defaultValues={defaultValues}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            projectProposalId={projectProposalId}
            projectTypeId={projectTypeId}
            projectStatusSlug={projectStatusSlug}
            projectDetails={projectDetails}
        />
    );
};

export default CreateProjectProposal;
