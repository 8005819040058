// Helpers
import { Status } from 'types/general';

export const getAllJobPositionsOptionsKey = ['job-positions', 'all', 'options'];

const queries = {
    getAllOptions: `query GetJobPositionsOptions {
        items: job_position(order_by: {title: asc}, where: {status: {_eq: ${Status.Active}}}) {
            value: id
            label: title
        }
    }`
};

export default queries;
