import { useCallback, useEffect, useMemo, useState } from 'react';
import Spinner from 'components/core/spinner';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import useGetProjectProposalType from 'services/queries/projects/use-get-project-proposal-type';
import { Project, ProjectProposalType, ProjectStatusEnum, ProjectTypeStatus } from 'types/models/project';

import ConstructionSidebar from './sidebar';
import useGetProject from 'services/queries/projects/use-get-project';
import useGetChecklist from 'services/queries/projects/use-get-checklist';
import { Timeline } from 'types/models/timeline';
import useBreadcrumb from 'store/breadcrumb/use-breadcrumb';
import OperationalNavbar from './navbar';
import { ProjectTypeRouteSlug } from 'services/queries/operationals/use-get-operational';
import useGetTaskPlanning from 'services/queries/operationals/use-get-task-planning';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';
import useProjectTypeSlug from '../hooks/use-project-type-slug';
import useConfig from 'store/config/use-config';
import useResponsive from 'hooks/responsive/use-responsive';
import Icon from 'components/core/icon';
import { useTheme } from '@mui/material';
import UpdateProjectTypeStatus from './update-project-type-status';

export type OperationalDetailsPageContext = {
    checklist: Timeline;
    projectProposalType: Partial<ProjectProposalType>;
    project: Project;
};

type Params = { projectProposalTypeId: string; projectTypeSlug: ProjectTypeRouteSlug };

const OperationalDetailsPage = ({ page }: WithPagePermissionsProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isSmallerThenTabletLandscape } = useResponsive();

    const [updateProjectTypeStatus, setUpdateProjectTypeStatus] = useState<ProjectTypeStatus | null>(null);
    const [subpages] = useState(page?.subpages || []);
    const { config, setConfig } = useConfig();
    const { palette } = useTheme();

    const { isManagement } = useProjectTypeSlug();

    const isManagementTimeline = useMemo(() => pathname.includes('cronogramas') && isManagement, [pathname, isManagement]);
    const { projectProposalTypeId } = useParams<Params>();

    const { data: checklist } = useGetChecklist(isManagementTimeline, projectProposalTypeId);
    const { data: taskPlanning } = useGetTaskPlanning(Number(projectProposalTypeId || 0), !isManagementTimeline);
    const { data: projectProposalType, isLoading } = useGetProjectProposalType(Number(projectProposalTypeId || 0));
    const { data: project } = useGetProject(projectProposalType?.project_proposal?.project?.id);

    useBreadcrumb(project?.title);

    useEffect(() => {
        if (isSmallerThenTabletLandscape) {
            setConfig('operational', { isNavbarOpen: false, isSidebarOpen: false });
        }

        // eslint-disable-next-line
    }, [isSmallerThenTabletLandscape]);

    const tapReleased = useMemo(() => project?.project_status?.slug !== ProjectStatusEnum.PreTap, [project?.project_status?.slug]);

    const handleGoTo = useCallback((path: string) => () => navigate(path), [navigate]);

    const handleAddResource = useCallback(() => {
        if (!tapReleased) {
            return alert('O TAP deste projeto ainda não foi liberado.');
        }

        handleGoTo(`recursos/novo`)();
    }, [tapReleased, handleGoTo]);

    if (isLoading) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    return (
        <div className="bg-base-100 flex flex-col md:flex-row -ml-6 -mr-4 -my-6 md:h-[calc(100vh-70px)] divide-y md:divide-y-0 md:max-h-[calc(100vh-70px)] overflow-hidden md:relative">
            <OperationalNavbar subpages={subpages} taskPlanningId={taskPlanning?.id || 0} />
            {!config.operational.isSidebarOpen && isSmallerThenTabletLandscape && (
                <div className="relative">
                    <button
                        type="button"
                        className="z-50 w-9 h-9 bg-base-200 rounded-bl fixed flex items-center justify-center hover:bg-base-300 top-[70px] right-0 transition-colors"
                        onClick={() => setConfig('operational', { isSidebarOpen: true })}>
                        <Icon name={config.operational.isSidebarOpen ? 'ico-chevron-right' : 'ico-chevron-left'} color={palette.heading} width={16} height={16} />
                    </button>
                </div>
            )}
            <section className="flex-1 border-x border-x-base-300 overflow-y-auto relative p-6 pt-12">
                <Outlet context={{ checklist, projectProposalType, project }} />
            </section>
            <ConstructionSidebar
                checklist={checklist}
                director={projectProposalType?.director}
                project={project}
                resources={projectProposalType?.project_type_person_resources}
                projectTypeStatus={projectProposalType?.project_type_status}
                onAddResource={handleAddResource}
                projectProposalTypeId={Number(projectProposalTypeId)}
                taskPlanningId={taskPlanning?.id}
                taskPlanningStatusCreation={taskPlanning?.statusCreation}
                onSetUpdateProjectTypeStatus={setUpdateProjectTypeStatus}
            />
            {Boolean(updateProjectTypeStatus) && <UpdateProjectTypeStatus updateProjectTypeStatus={updateProjectTypeStatus!} onSetProjectTypeStatus={setUpdateProjectTypeStatus} />}
        </div>
    );
};

export default OperationalDetailsPage;
