import React from 'react';
import Modal from 'components/core/modal';
import Text from 'components/core/text';
import Button from 'components/core/button';

type ConfirmModalConfig = {
    title: string;
    description: string;
    isLoading: boolean;
    color?: 'error' | 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'inherit';
    onClose: () => void;
    onConfirmAction: () => void;
};

const ConfirmModal = ({ onConfirmAction, onClose, color = 'error', description, isLoading, title }: ConfirmModalConfig) => {
    return (
        <Modal contentClassnames="w-[500px]" closeOnClickOutside={false} onClose={onClose}>
            <div className="p-6">
                <Text as="h6" variant="h5" className="text-heading mb-3">
                    {title}
                </Text>
                <Text as="p" variant="body.regular.sm" className="text-base-700 mb-6">
                    {description}
                </Text>
                <div className="flex items-center justify-end">
                    <Button disabled={isLoading} loading={isLoading} type="submit" variant="contained" color={color} className="min-w-[100px] mr-4" onClick={onConfirmAction}>
                        Enviar
                    </Button>
                    <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={onClose}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
