import React, { useCallback, useContext, useEffect } from 'react';

// Dependencies
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Components
import Files from '../components/files';
import Modal from 'components/core/modal';
import Meeting from '../components/meeting';
import Publish from '../components/publish';
import Subjects from '../components/subjects';
import Participants from '../components/participants';

// Helpers
import { formatDate } from 'utils/date';
import { ProjectMeetingRecord } from 'types/models/project';
import { MeetingSteps } from 'contexts/meeting-record/types';
import { MeetingRecordContext } from 'contexts/meeting-record';

type CreateOrUpdateMeetingRecordProps = {
    projectProposalTypeId?: number;
} & UseQueryResult<ProjectMeetingRecord, unknown>;

const CreateOrUpdateMeetingRecord = (props: CreateOrUpdateMeetingRecordProps) => {
    const navigate = useNavigate();
    const { state, changeStep, setContent } = useContext(MeetingRecordContext);

    const setContentWithIncomingData = () => {
        const meeting = {
            dateFormatted: formatDate(props?.data?.date, 'YYYY-MM-DD[T]HH:mm'),
            editors: props?.data?.editors.map((item) => ({ value: (item as any).id, label: (item as any).name })),
            title: props?.data?.title,
            project: props?.data?.project.id,
            projectProposalType: props?.data?.projectProposalType?.id
        };

        const participants = {
            internal: props?.data?.participants
                .filter((item) => item.person)
                .map((item) => ({
                    validationRequired: item.validationRequired,
                    person: { value: item.person?.id, label: item.person?.name }
                })),
            external: props.data?.participants.filter((item) => item.email)
        };

        setContent(
            {
                meeting,
                subjects: props?.data?.subjects,
                files: props?.data?.files,
                participants
            },
            false
        );
    };

    useEffect(() => {
        if (props?.data) {
            setContentWithIncomingData();
        }
        // eslint-disable-next-line
    }, [props?.data]);

    const getComponent = (slug: MeetingSteps) => {
        const items = new Map<MeetingSteps, any>([
            [MeetingSteps.Meeting, Meeting],
            [MeetingSteps.Subjects, Subjects],
            [MeetingSteps.Participants, Participants],
            [MeetingSteps.Files, Files],
            [MeetingSteps.Publish, Publish]
        ]);

        const Component = items.get(slug);

        return <Component /> ?? null;
    };

    const handleClose = () => navigate(-1);

    const handleChangeTab = useCallback((_: any, nextSlug: any) => changeStep(state.currentStep.slug, nextSlug), [state.currentStep.slug, changeStep]);

    return (
        <Modal contentClassnames="w-[1200px]" closeOnClickOutside={false} onClose={handleClose}>
            <div className="pt-4">
                <Tabs variant="scrollable" classes={{ root: 'px-4' }} indicatorColor="secondary" value={state.currentStep.slug} onChange={handleChangeTab}>
                    {state.steps.map((item, index) => {
                        const isDisabled = !state.completeds.find((step) => step.slug === item.slug) && item.slug !== state.currentStep.slug;

                        return <Tab value={item.slug} disabled={isDisabled} key={`${item.slug}_${index}`} label={item.label.toUpperCase()} />;
                    })}
                </Tabs>
                {getComponent(state.currentStep.slug)}
            </div>
        </Modal>
    );
};

export default CreateOrUpdateMeetingRecord;
