import React, { useMemo, useState } from 'react';

// Dependencies
import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import { useParams } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import Accordion from '@mui/material/Accordion/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';

// Components
import Terms from '../terms/terms';
import Scopes from '../scopes';
import Documents from '../documents';
import Empty from 'components/empty';
import Resources from '../resources';
import Card from 'components/core/card';
import CisoContainer from '../../cisos';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import ProjectTypeDetails from '../details';
import Spinner from 'components/core/spinner';
import MeetingRecords from '../../meeting-records';

// Helpers
import { ProjectTypeAccordion, ProjectTypeProps } from '../types';
import { ProjectManagementInformation, ProjectProposalType } from 'types/models/project';
import { Timeline as TimelineModel } from 'types/models/timeline';
import Timeline from 'pages/private/operationals/view/timeline';

type ManagementContentProps = {
    activeAccordion: ProjectTypeProps['activeAccordion'];
    isLoading: boolean;
    onCreateProposalType: () => void;
    onToggleAccordion: ProjectTypeProps['onToggleAccordion'];
    projectProposalType?: Partial<ProjectProposalType>;
    managementInformations?: Partial<ProjectManagementInformation>[];
    checklist?: TimelineModel;
};

const ManagementContent = ({ activeAccordion, checklist, projectProposalType, managementInformations, isLoading, onCreateProposalType, onToggleAccordion }: ManagementContentProps) => {
    const { palette } = useTheme();
    const { projectId } = useParams();

    const [tab, setTab] = useState(0);

    const hasCisos = useMemo(() => Boolean(managementInformations?.length), [managementInformations]);

    const handleChangeTab = (_, value: number) => setTab(value);

    const isOpen = activeAccordion === ProjectTypeAccordion.Management;

    const handleAccordion = (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => onToggleAccordion(ProjectTypeAccordion.Management, expanded);

    return (
        <Card elevation={21} className="mb-4">
            <Accordion expanded={isOpen} TransitionProps={{ unmountOnExit: true }} onChange={handleAccordion}>
                <AccordionSummary
                    classes={{ content: 'm-0', root: 'px-6 py-4', expanded: '!m-0' }}
                    expandIcon={<Icon name="ico-chevron-down" width={14} color={isOpen ? palette.heading : palette.grey[400]} />}>
                    <Text as="h5" variant="h5" className="text-heading !text-base md:!text-lg xl:!text-xl relative">
                        Gerenciamento
                    </Text>
                </AccordionSummary>
                {isLoading ? (
                    <div className="pb-6">
                        <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                    </div>
                ) : (
                    <AccordionDetails classes={{ root: 'p-0' }}>
                        {projectProposalType ? (
                            <>
                                <Tabs variant="scrollable" indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                                    <Tab label="Detalhes" />
                                    <Tab label="Escopos" />
                                    <Tab label="Recursos" />
                                    <Tab label="Atas de reunião" />
                                    <Tab disabled={!hasCisos} label="CISO" />
                                    <Tab label="Documentos" />
                                    <Tab label="Termos" />
                                    <Tab label="Cronogramas" />
                                </Tabs>
                                {tab === 0 && <ProjectTypeDetails checklist={checklist} type="management" projectProposalType={projectProposalType} />}
                                {tab === 1 && <Scopes projectProposalTypeProp={projectProposalType} className="p-4" />}
                                {tab === 2 && <Resources resourcesProp={projectProposalType.project_type_person_resources} className="p-4" />}
                                {tab === 3 && <MeetingRecords projectIdProp={Number(projectId)} elevation={0} projectProposalTypeIdProp={projectProposalType?.id} />}
                                {tab === 4 && <CisoContainer projectProposalTypeId={projectProposalType.id?.toString()!} projectIdProp={Number(projectId) || 0} />}
                                {tab === 5 && <Documents projectProposalTypeIdProp={projectProposalType?.id} className="p-4" />}
                                {tab === 6 && <Terms projectProposalTypeIdProp={projectProposalType?.id} origin="project" className="p-4" />}
                                {tab === 7 && <Timeline projectProposalTypeIdProp={projectProposalType?.id} className="p-4" />}
                            </>
                        ) : (
                            <Empty title="Proposta não encontrada." buttonLabel="Configurar proposta" className="px-6 pb-6" onClick={onCreateProposalType} />
                        )}
                    </AccordionDetails>
                )}
            </Accordion>
        </Card>
    );
};

export default ManagementContent;
