import RecursiveManagement from './management/recursive-management';
import Button from 'components/core/button';
import { formatDate } from 'utils/date';
import RecursiveProject from './project/recursive-project';
import Text from 'components/core/text';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetPeopleByProjectOptions from 'services/queries/people/use-get-people-options-by-project';
import { useMemo } from 'react';
import useProjectTypeSlug from 'pages/private/operationals/hooks/use-project-type-slug';
import { CamelCase } from 'types/utils';
import { ProjectProposalType } from 'types/models/project';
import { formatMoney } from 'utils/money';

type TimelineFormProps = {
    isSubmitting: boolean;
    isSendButtonDisabled: boolean;
    projectId?: number;
    proposalType?: Partial<CamelCase<ProjectProposalType>>;
    endDate: string;
    startDate: string;
    numberOfManagementHours: () => any;
    numberOfExecutionHours: () => any;
    valueOfExecutionHours?: () => any;
    valueOfManagementHours?: () => any;
};

const MAX_LEVELS = 4;

const TimelineForm = ({
    isSendButtonDisabled,
    isSubmitting,
    projectId,
    proposalType,
    endDate,
    startDate,
    numberOfExecutionHours,
    numberOfManagementHours,
    valueOfExecutionHours,
    valueOfManagementHours
}: TimelineFormProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isManagement } = useProjectTypeSlug();

    const { data: people = [] } = useGetPeopleByProjectOptions(projectId);

    const isManagementSchedule = useMemo(() => pathname.includes('cronogramas') && isManagement, [pathname, isManagement]);
    const isManagementTimelineTaskPlanning = isManagement && pathname.includes('planejamento-de-tarefas');

    const handleClose = () => navigate(-1);

    const proposalExecutionValueTotal = useMemo(
        () => proposalType?.valueOfExecutionHours! * proposalType?.numberOfExecutionHours! || 0,
        [proposalType?.valueOfExecutionHours, proposalType?.numberOfExecutionHours]
    );

    const proposalManagementValueTotal = useMemo(
        () => proposalType?.valueOfManagementHours! * proposalType?.numberOfManagementHours! || 0,
        [proposalType?.numberOfManagementHours, proposalType?.valueOfManagementHours]
    );

    return (
        <>
            <div className="border rounded-xl py-4 px-2  sm:p-4 border-base-300 flex-1 flex sm:flex-row flex-col items-baseline mb-6 sticky left-0 top-4 bg-base-100 z-50">
                <Text as="h6" variant="h6" className="text-heading">
                    Consolidado
                </Text>
                {isManagementSchedule ? (
                    <>
                        <div className="ml-1 sm:ml-4">
                            <Text as="span" variant="body.medium.sm" className="text-secondary-500 mr-2">
                                Data de início (previsão):
                            </Text>
                            <Text as="span" variant="body.regular.sm" className="text-base-700">
                                {formatDate(startDate)}
                            </Text>
                        </div>
                        <div className="ml-1 sm:ml-4">
                            <Text as="span" variant="body.medium.sm" className="text-secondary-500 mr-2">
                                Data final (previsão):
                            </Text>
                            <Text as="span" variant="body.regular.sm" className="text-base-700">
                                {formatDate(endDate)}
                            </Text>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="ml-1 sm:ml-4">
                            <Text as="span" variant="body.medium.sm" className="text-secondary-500 mr-2">
                                Execução:
                            </Text>
                            <Text as="span" variant="body.regular.sm" className="text-base-700">
                                <span className={valueOfExecutionHours?.()! > proposalExecutionValueTotal ? 'text-system-danger-500' : ''}>{formatMoney(valueOfExecutionHours?.() || 0)} </span>{' '}
                                <span className={numberOfExecutionHours() > (proposalType?.numberOfExecutionHours || 0) ? 'text-system-danger-500' : ''}>({numberOfExecutionHours() || 0}h)</span> /{' '}
                                {formatMoney(proposalExecutionValueTotal || 0)} ({proposalType?.numberOfExecutionHours || 0}h)
                            </Text>
                        </div>
                        <div className="ml-1 sm:ml-4">
                            <Text as="span" variant="body.medium.sm" className="text-secondary-500 mr-2">
                                Gestão:
                            </Text>
                            <Text as="span" variant="body.regular.sm" className="text-base-700">
                                <span className={valueOfManagementHours?.()! > proposalManagementValueTotal ? 'text-system-danger-500' : ''}>{formatMoney(valueOfManagementHours?.()! || 0)}</span>{' '}
                                <span className={numberOfManagementHours() > (proposalType?.numberOfManagementHours || 0) ? 'text-system-danger-500' : ''}>({numberOfManagementHours?.() || 0}h)</span>{' '}
                                / {formatMoney(proposalManagementValueTotal || 0)} ({proposalType?.numberOfManagementHours || 0}h)
                            </Text>
                        </div>
                    </>
                )}
            </div>
            <div>
                <Text as="h4" variant="h6" className="text-heading mb-3">
                    Serviços/Atividades
                </Text>
                {isManagementTimelineTaskPlanning || isManagementSchedule ? <RecursiveManagement levels={MAX_LEVELS} /> : <RecursiveProject people={people} levels={MAX_LEVELS} />}
            </div>
            <div className="flex items-center mt-6">
                <Button disabled={isSendButtonDisabled} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                    Enviar
                </Button>
                <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                    Cancelar
                </Button>
            </div>
        </>
    );
};

export default TimelineForm;
