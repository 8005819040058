import { MigrationManifest } from 'redux-persist';

const migrations: MigrationManifest = {
    1: (state: any) => state,
    2: (state) => state,
    3: ({ _persist }: any) => ({ _persist }),
    4: ({ _persist }: any) => ({ _persist })
};

export default migrations;
