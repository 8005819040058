import React from 'react';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Icon from 'components/core/icon';
import Text from 'components/core/text';
import { Task } from 'types/models/task';
import classNames from 'classnames';
import Empty from 'components/empty';
import TaskLightbox from './lightbox';

type TaskFilesProps = {
    files: Task['files'];
    className?: string;
    emptyClassName?: string;
    itemsPerRow?: number;
};

const TaskFiles = ({ files, className, emptyClassName, itemsPerRow = 7 }: TaskFilesProps) => {
    const { palette } = useTheme();

    const containerClasses = classNames('p-6', className);

    const images = files
        .filter((file) => file.file.mimeType.includes('image'))
        .map((image) => ({
            url: image.file.url,
            urlThumb: image.file.urlThumb
        }));

    const nonImages = files.filter((file) => !file.file.mimeType.includes('image'));

    const lightboxClasses = classNames('grid gap-4 mb-4', `grid-cols-${itemsPerRow}`);

    return (
        <div className={containerClasses}>
            <Text as="label" className="block text-heading mb-4">
                Imagens
            </Text>
            {Boolean(images.length) ? (
                <div className={lightboxClasses}>
                    <TaskLightbox items={images} />
                </div>
            ) : (
                <Empty title="Nenhuma imagem encontrada" className="mb-6" />
            )}
            <Text as="label" className="block text-heading mb-3">
                Arquivos
            </Text>
            {Boolean(nonImages.length) ? (
                nonImages.map((item) => {
                    const icon = item.file.mimeType.includes('image') ? 'ico-image' : 'ico-text';

                    return (
                        <div key={item.id} className="border border-base-300 rounded-2xl px-5 py-3 flex items-center mb-2 last:mb-0">
                            <div className="flex items-center flex-1 mr-5 overflow-hidden">
                                <Icon name={icon} color={palette.grey[500]} className="mr-5" />
                                <Text as="span" variant="body.regular.xs" className="text-heading block mb-[2px] text-ellipsis">
                                    {item.file.originalName}
                                </Text>
                            </div>
                            <div className="flex items-center">
                                <Tooltip placement="top" title="Baixar arquivo" disableFocusListener={true}>
                                    <IconButton href={item.file.url} target="_blank" download={true} color="secondary" sx={{ '&:hover': { backgroundColor: palette.grey[200] } }}>
                                        <Icon name="ico-download" color={palette.grey[700]} width={16} height={16} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    );
                })
            ) : (
                <Empty title="Nenhum arquivo encontrado" className={`mb-6 ${emptyClassName}`} />
            )}
        </div>
    );
};

export default TaskFiles;
