import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Icon from 'components/core/icon';
import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { BudgetStatus, CommercialBudgetCostEnum, CommercialBudgetManagementTeamEnum, Employee, Service, UpdateBudgetPayload } from 'types/models/commercial';
import { array, number, object, string } from 'yup';
import dictionary from 'utils/dictionary';
import { ProjectTypeEnum } from 'types/models/project';
import dayjs from 'dayjs';
import { CommercialBudgetVersionsResponse } from 'services/queries/commercial/use-get-budget-versions';
import Badge from 'components/core/badge';
import selectStyles from 'components/core/form/select/styles';
import { hourToMinutes } from 'utils/date';
import { Status } from 'types/general';

export const budgetProjectTypesColumns = [
    {
        label: 'Tipo de projeto',
        className: 'pl-6'
    },
    {
        label: 'Versão'
    },
    {
        label: 'Valor contrato'
    },
    {
        label: 'Custos Indiretos'
    },
    {
        label: 'Custo direto'
    },
    {
        label: 'Margem'
    },
    {
        label: 'Status'
    },
    {
        label: 'Ações'
    }
];

export const generatePercentage = (value = 0, withSuffix = true) => {
    if (withSuffix) {
        const percentage = value.toLocaleString('pt-BR', { maximumFractionDigits: 2 });
        return percentage + '%';
    }

    return Number(((value || 0) * PERCENTAGE_VALUE).toFixed(2));
};

type GenerateProjectTypesItemsParams = {
    budgetVersions?: CommercialBudgetVersionsResponse['items'];
    palette: any;
    budgetStatus?: BudgetStatus;
    onSetProjectTypeId: (value: number) => void;
    onSetProjectTypeToDeleteId: (value: number) => void;
    onSetProjectProposalTypeId: (value: number) => void;
};

export const generateProjectTypesItems = ({
    budgetVersions = [],
    palette,
    budgetStatus,
    onSetProjectTypeId,
    onSetProjectTypeToDeleteId,
    onSetProjectProposalTypeId
}: GenerateProjectTypesItemsParams) => {
    const budgetVersionsItems = budgetVersions.map((item, index) => {
        const canShowAction = budgetStatus === BudgetStatus.Started && item.status === Status.Active;

        const handleDeleteProjectProposalType = () => {
            onSetProjectTypeToDeleteId(item.project_type.id);
            onSetProjectProposalTypeId(item.id);
        };

        return {
            id: `budget_version_${index}`,
            contents: [
                {
                    label: item.project_type?.title,
                    className: 'pl-6',
                    textClassName: 'text-sm'
                },
                {
                    label: item.version || '-'
                },
                {
                    label: formatMoney(item.contract_value)
                },
                {
                    label: formatMoney(item.indirect_costs)
                },
                {
                    label: formatMoney(item.direct_costs)
                },
                {
                    label: generatePercentage(item.percentage_margin)
                },
                {
                    label: <Badge variant={item.status === Status.Active ? 'success' : 'error'}>{item.status === Status.Active ? 'Ativo' : 'Cancelado'}</Badge>
                },
                {
                    label: (
                        <div className="flex items-center gap-2">
                            <Tooltip placement="top" title="Visualizar versão ativa" disableFocusListener={true}>
                                <IconButton
                                    component={Link}
                                    to={`versoes/${item.id}/${item.project_type?.id}/visualizar`}
                                    className="hover:bg-system-info-100 hover:bg-opacity-30"
                                    color="secondary"
                                    size="small">
                                    <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                </IconButton>
                            </Tooltip>
                            {canShowAction && (
                                <Tooltip placement="top" title="Editar versão ativa" disableFocusListener={true}>
                                    <IconButton component={Link} to={`versoes/${item.id}/${item.project_type?.id}/editar`} className="hover:bg-system-warning-100 hover:bg-opacity-30">
                                        <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip placement="top" title="Ver versões" disableFocusListener={true}>
                                <IconButton component={Link} to={`versoes/${item.id}/${item.project_type?.id}/versionamentos`} className="hover:bg-system-grey-100 hover:bg-opacity-30">
                                    <Icon name="ico-version" width={16} height={16} color={palette.grey.main} />
                                </IconButton>
                            </Tooltip>
                            {canShowAction && (
                                <Tooltip placement="top" title="Gerar nova versão" disableFocusListener={true}>
                                    <IconButton onClick={onSetProjectTypeId?.bind(this, item.project_type?.id)} className="hover:bg-system-success-100 hover:bg-opacity-30">
                                        <Icon name="ico-plus" width={16} height={16} color={palette.success.main} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {canShowAction && (
                                <Tooltip placement="top" title="Cancelar versão ativa" disableFocusListener={true}>
                                    <IconButton onClick={handleDeleteProjectProposalType} className="hover:bg-system-danger-100 hover:bg-opacity-30">
                                        <Icon name="ico-close-circle" width={16} height={16} color={palette.error.main} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    )
                }
            ]
        };
    });

    return budgetVersionsItems;
};

export const classesTableRowHover = { root: 'hover:bg-base-200 hover:bg-opacity-50' };

export const convertToPercentage = (value = 0) => value / PERCENTAGE_VALUE;

export const calculatePercentage = (contractValue: number, totalValue: number) => {
    const safeContractValue = isFinite(contractValue) ? contractValue : 0;
    const safeTotalValue = isFinite(totalValue) ? totalValue : 1;

    return safeTotalValue !== 0 ? (safeTotalValue / safeContractValue) * PERCENTAGE_VALUE : 0;
};

export const PERCENTAGE_VALUE = 100;

export const calculateValueByPercentage =
    (contractValue = 0) =>
    (value = 0) =>
        (contractValue * value) / PERCENTAGE_VALUE;

export const getManagementTeamTotal = (values: UpdateBudgetPayload, projectTypeId?: string) => {
    const getValues = (coordinatorOrManager: Employee) => (coordinatorOrManager?.hourMonth || 0) * ((coordinatorOrManager?.hourValue || 0) + (coordinatorOrManager?.licenseValue || 0));

    const { geop } = getCommercialBudgetDataByProjectType(projectTypeId);

    const coordinatorTotal = getValues(values.coordinator) || 0;
    const managerTotal = getValues(values.manager) || 0;

    const teamProductionExpensesTotal = calculateDirectCosts(values, 'teamProductionExpenses');
    const otherExpensesTotal = calculateDirectCosts(values, 'otherExpenses');
    const thirdPartyExpensesTotal = calculateDirectCosts(values, 'thirdPartyExpenses');

    const totalExpensesValue = teamProductionExpensesTotal + otherExpensesTotal + thirdPartyExpensesTotal;
    const GEOPTotal = totalExpensesValue * convertToPercentage(values[geop.name] || 0);
    const managementTeamCostTotal = coordinatorTotal + managerTotal + GEOPTotal;

    return managementTeamCostTotal;
};

export const getIndirectCosts = (values: UpdateBudgetPayload, contractValue = 0) => {
    const { backoffice, commercialExpense, financialExpense, tax } = values;

    const getValueByPercentage = calculateValueByPercentage(contractValue);

    const indirectCostsValue = getValueByPercentage(backoffice) + getValueByPercentage(commercialExpense) + getValueByPercentage(financialExpense) + getValueByPercentage(tax);

    return indirectCostsValue;
};

type ServicesCategories = keyof Pick<UpdateBudgetPayload, 'teamProductionExpenses' | 'thirdPartyExpenses' | 'otherExpenses'>;

const calculateDirectCosts = (expenses: UpdateBudgetPayload, category: ServicesCategories): number => {
    const categoryExpenses = expenses[category] || [];

    const total = categoryExpenses.reduce((acc, curr) => {
        const hourLicenseValue = (curr.hourValue || 0) + (curr.licenseValue || 0);
        const hourMonthValue = curr.hourMonth || 0;

        const value = hourMonthValue * hourLicenseValue;
        return acc + value;
    }, 0);

    return total;
};

export const getDirectCosts = (values: UpdateBudgetPayload, contractValue = 0, withPostDeliveryCost = true, withOnlyExpenses = false, projectTypeId?: string) => {
    const getValueByPercentage = calculateValueByPercentage(contractValue);

    const teamProductionExpensesTotal = calculateDirectCosts(values, 'teamProductionExpenses');
    const otherExpensesTotal = calculateDirectCosts(values, 'otherExpenses');
    const thirdPartyExpensesTotal = calculateDirectCosts(values, 'thirdPartyExpenses');
    const managementTeamCostTotal = getManagementTeamTotal(values, projectTypeId);
    const contigencyTotal = getValueByPercentage(values.contingency);

    const total = teamProductionExpensesTotal + otherExpensesTotal + thirdPartyExpensesTotal + managementTeamCostTotal;
    const postDeliveryCostTotal = total * convertToPercentage(values.postDeliveryCost);

    if (withPostDeliveryCost) {
        return total + postDeliveryCostTotal + contigencyTotal;
    }

    if (withOnlyExpenses) {
        return teamProductionExpensesTotal + otherExpensesTotal + thirdPartyExpensesTotal;
    }

    return total;
};

export const INITIAL_COMMERCIAL_BUDGET_VERSION = {
    thirdPartyExpenses: [],
    otherExpenses: [],
    teamProductionExpenses: [],
    manager: {
        licenseValue: 9,
        hourMonth: 0,
        hourValue: 0
    },
    coordinator: {
        licenseValue: 9,
        hourMonth: 0,
        hourValue: 0
    },
    selectedServices: [],
    contingency: 0,
    postDeliveryCost: 0,
    backoffice: 20,
    tax: 14.25,
    commercialExpense: 0,
    financialExpense: 0,
    GEOPArchitecture: 5,
    GEOPInstallations: 5,
    GEOPBid: 5,
    GEOPManagement: 5,
    factor: 2.65,
    footage: 0,
    deadline: 1,
    date: dayjs().add(1, 'month').toString()
};

const validateExpenses = (thirdPartyExpenses = [], otherExpenses = [], teamProductionExpenses = []) => {
    return !!thirdPartyExpenses.length || !!otherExpenses.length || !!teamProductionExpenses.length;
};

const optionSchema = object({
    label: string().required(dictionary.validation.required),
    value: number().required(dictionary.validation.required)
});

const commercialBudgetCommonSchemas = object({
    service: optionSchema,
    hourMonth: number().required(dictionary.validation.required),
    hourValue: number().required(dictionary.validation.required)
});

export const commercialBudgetVersionSchema = (projectTypeId?: string) => {
    const { required } = dictionary.validation;

    return object({
        thirdPartyExpenses:
            projectTypeId === String(ProjectTypeEnum.Management) ? array(object({ classification: optionSchema }).concat(commercialBudgetCommonSchemas)) : array(commercialBudgetCommonSchemas),
        otherExpenses: array(
            object({
                classification: optionSchema
            }).concat(commercialBudgetCommonSchemas)
        ),
        teamProductionExpenses: array(
            object({
                seniority: number().required(required),
                licenseValue: number().required(required)
            }).concat(commercialBudgetCommonSchemas)
        ),
        backoffice: number().required(required),
        tax: number().required(required),
        commercialExpense: number().required(required),
        financialExpense: number().required(required),
        footage: number().when(['thirdPartyExpenses', 'otherExpenses', 'teamProductionExpenses'], {
            is: validateExpenses,
            then: number().required(required),
            otherwise: number().notRequired()
        }),
        deadline: number().when(['thirdPartyExpenses', 'otherExpenses', 'teamProductionExpenses'], {
            is: validateExpenses,
            then: number().required(required),
            otherwise: number().notRequired()
        }),
        factor: number().required(required),
        date: string().when(['thirdPartyExpenses', 'otherExpenses', 'teamProductionExpenses'], {
            is: validateExpenses,
            then: string().required(required),
            otherwise: string().notRequired()
        }),
        postDeliveryCost: number().required(required),
        contingency: number().required(required),
        selectedServices: array(
            object({
                quantity: number().required(required).default(0),
                actualValue: number().required(required).default(0)
            })
        )
            .test('selectedServices', required, (_, context) => {
                const { thirdPartyExpenses, otherExpenses, teamProductionExpenses } = context.parent;

                return validateExpenses(thirdPartyExpenses, otherExpenses, teamProductionExpenses);
            })
            .required(required),
        manager: object({
            hourMonth: number().required(required),
            hourValue: number().required(required),
            licenseValue: number().required(required)
        }),
        coordinator: object({
            hourMonth: number().required(required),
            hourValue: number().required(required),
            licenseValue: number().required(required)
        }),
        ...(projectTypeId === String(ProjectTypeEnum.Projects) && { GEOPArchitecture: number().required(required) }),
        ...(projectTypeId === String(ProjectTypeEnum.ComplementaryProjects) && { GEOPInstallations: number().required(required) }),
        ...(projectTypeId === String(ProjectTypeEnum.BID) && { GEOPBid: number().required(required) })
    });
};

type BudgetByProjectTypeResponse = {
    [key: number]: {
        projectServiceId: ProjectTypeEnum;
        geop: {
            label: string;
            name: keyof Pick<UpdateBudgetPayload, 'GEOPArchitecture' | 'GEOPInstallations' | 'GEOPBid' | 'GEOPManagement'>;
        };
    };
};

export const getCommercialBudgetDataByProjectType = (projectType?: string): BudgetByProjectTypeResponse[ProjectTypeEnum] => {
    const budgetByProjectType: BudgetByProjectTypeResponse = {
        [ProjectTypeEnum.Projects]: {
            projectServiceId: ProjectTypeEnum.Projects,
            geop: {
                label: 'GEOP Arquitetura',
                name: 'GEOPArchitecture' as const
            }
        },
        [ProjectTypeEnum.ComplementaryProjects]: {
            projectServiceId: ProjectTypeEnum.ComplementaryProjects,
            geop: {
                label: 'GEOP Instalações',
                name: 'GEOPInstallations' as const
            }
        },
        [ProjectTypeEnum.BID]: {
            projectServiceId: ProjectTypeEnum.BID,
            geop: {
                label: 'GEOP BID',
                name: 'GEOPBid' as const
            }
        },
        [ProjectTypeEnum.Management]: {
            projectServiceId: ProjectTypeEnum.Management,
            geop: {
                label: 'GEOP Gerenciamento',
                name: 'GEOPManagement' as const
            }
        }
    };

    return budgetByProjectType[projectType || ProjectTypeEnum.Projects];
};

export const commercialBudgetFPSchema = (isContractPage = false) =>
    object({
        types: array(number().required(dictionary.validation.required)).required(dictionary.validation.required),
        code: string().required(dictionary.validation.required),
        ...(!isContractPage && {
            customer: number().required(dictionary.validation.required),
            title: string().required(dictionary.validation.required)
        })
    });

export const versionsTableColumns = (isContractPage = false) => [
    {
        label: 'Versão',
        className: 'pl-6'
    },
    ...(isContractPage ? [{ label: 'Programa' }] : []),
    {
        label: 'Valor do contrato',
        className: 'min-w-[160px]'
    },
    {
        label: 'Custo Indiretos'
    },
    {
        label: 'Custos diretos'
    },
    {
        label: 'Margem (%)',
        className: 'min-w-[140px]'
    },
    {
        label: 'Margem / Mês',
        className: 'min-w-[140px]'
    },
    {
        label: 'Venda / M ²',
        className: 'min-w-[140px]'
    },
    {
        label: 'Ativo'
    },
    {
        label: 'Ações'
    }
];

export const generateVersionsTableItems = (
    versions: CommercialBudgetVersionsResponse['items'] = [],
    palette?: any,
    onGoTo?: (item: Partial<CommercialBudgetVersionsResponse['items'][0]>, type: 'update' | 'show') => void,
    onSetProjectTypeId?: (value: number) => void,
    budgetStatus?: BudgetStatus,
    isContractPage = false
) => {
    return versions?.map((item, index) => {
        const marginByMonthValue = (item.margin || 0) / (item.deadline || 0);
        const saleValue = marginByMonthValue / (item.footage || 0);

        return {
            id: `version_table_${index}`,
            contents: [
                {
                    label: item.version,
                    className: 'pl-6'
                },
                ...(isContractPage
                    ? [
                          {
                              label: <Badge variant="info">{item?.project_type.title}</Badge>
                          }
                      ]
                    : []),
                {
                    label: formatMoney(item.contract_value)
                },
                {
                    label: formatMoney(item.indirect_costs)
                },
                {
                    label: formatMoney(item.direct_costs)
                },
                {
                    label: `${formatMoney(item.margin)} (${generatePercentage(item.percentage_margin)})`
                },
                {
                    label: formatMoney(isFinite(marginByMonthValue) ? marginByMonthValue : 0)
                },
                {
                    label: formatMoney(isFinite(saleValue) ? saleValue : 0)
                },
                {
                    label: <Badge variant={item.active ? 'success' : 'error'}>{item.active ? 'Sim' : 'Não'}</Badge>
                },
                {
                    label: (
                        <div className="flex items-center gap-2">
                            <Tooltip placement="top" title="Visualizar versão" disableFocusListener={true}>
                                <IconButton className="hover:bg-system-info-100 hover:bg-opacity-30" color="secondary" size="small" onClick={onGoTo?.bind(this, item, 'show')}>
                                    <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                </IconButton>
                            </Tooltip>
                            {isContractPage && (
                                <Tooltip placement="top" title="Ver versões" disableFocusListener={true}>
                                    <IconButton
                                        component={Link}
                                        to={`${item.budget_id}/versoes/${item.id}/${item.project_type?.id}/versionamentos`}
                                        className="hover:bg-system-grey-100 hover:bg-opacity-30">
                                        <Icon name="ico-version" width={16} height={16} color={palette.grey.main} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {item.active && budgetStatus === BudgetStatus.Started && !isContractPage && (
                                <>
                                    <Tooltip placement="top" title="Editar versão ativa" disableFocusListener={true}>
                                        <IconButton className="hover:bg-system-warning-100 hover:bg-opacity-30" color="secondary" size="small" onClick={onGoTo?.bind(this, item, 'update')}>
                                            <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip placement="top" title="Gerar nova versão" disableFocusListener={true}>
                                        <IconButton onClick={onSetProjectTypeId?.bind(this, item.project_type?.id)} className="hover:bg-system-success-100 hover:bg-opacity-30">
                                            <Icon name="ico-plus" width={16} height={16} color={palette.success.main} />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </div>
                    )
                }
            ]
        };
    });
};

export const commercialBudgetSelectStyles = (size: 'medium' | 'small' = 'medium', hasError: boolean) => ({
    singleValue: (base: any, props: any) => {
        const baseStyles = selectStyles(size, hasError).singleValue?.(base, props);
        return {
            ...baseStyles,
            fontSize: 12
        };
    }
});

export const budgetStatusMap = {
    [BudgetStatus.Started]: {
        variant: 'info',
        label: 'Iniciado'
    },
    [BudgetStatus.GeneratedContract]: {
        variant: 'success',
        label: 'Gerado'
    },
    [BudgetStatus.NotConverted]: {
        variant: 'error',
        label: 'Não gerado'
    }
};

export const getManagementCosts = (GEOPValue = 0, coordinatorValue = 0, managerValue = 0, postDeliveryCostValue = 0, contingencyValue = 0, data: UpdateBudgetPayload, projectTypeId?: string) => {
    const { geop } = getCommercialBudgetDataByProjectType(projectTypeId);

    return [
        {
            type: CommercialBudgetCostEnum.ManagementTeam,
            value: GEOPValue + coordinatorValue + managerValue,
            items: [
                {
                    typeManagement: CommercialBudgetManagementTeamEnum.General,
                    value: GEOPValue,
                    percentage: data[geop.name],
                    manager: true
                },
                {
                    typeManagement: CommercialBudgetManagementTeamEnum.Manager,
                    value: managerValue,
                    hourValue: data.manager.hourValue,
                    numberOfHours: hourToMinutes(data.manager.hourMonth),
                    licenseFee: data.manager.licenseValue,
                    manager: true
                },
                {
                    typeManagement: CommercialBudgetManagementTeamEnum.Coordinator,
                    value: coordinatorValue,
                    hourValue: data.manager.hourValue,
                    numberOfHours: hourToMinutes(data.coordinator.hourMonth),
                    licenseFee: data.coordinator.licenseValue
                }
            ]
        },
        {
            type: CommercialBudgetCostEnum.PostDeliveryCost,
            value: postDeliveryCostValue,
            percentage: data.postDeliveryCost
        },
        {
            type: CommercialBudgetCostEnum.Contingency,
            value: contingencyValue,
            percentage: data.contingency
        }
    ];
};

export const getExpensesCosts = (expense: Service[] = [], type: CommercialBudgetCostEnum) => {
    if (type !== CommercialBudgetCostEnum.ProductionTeam) {
        return expense.map((item) => ({
            type,
            projectService: item.service?.value,
            classification: item.classification?.value,
            value: item.hourValue || 0,
            quantity: item.hourMonth
        }));
    }

    const teamCostExpenseTotal = expense.reduce((acc, { hourMonth = 0, hourValue = 0, licenseValue = 0 }) => (acc += hourMonth * (hourValue + licenseValue)), 0);

    return [
        {
            type,
            value: teamCostExpenseTotal,
            items: expense.map((item) => ({
                projectService: item.service?.value,
                seniority: item.seniority,
                value: item.totalValue,
                hourValue: item.hourValue,
                numberOfHours: hourToMinutes(item.hourMonth),
                licenseFee: item.licenseValue
            }))
        }
    ];
};
