// Dependencies
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

// Helpers
import useToast from 'hooks/toast/use-toast';
import { createOrUpdateProviderKey } from './graphql';
import api from 'services/api';
import { useShowApiErrors } from 'hooks/error/show-api-errors';

type CreateOrUpdateProviderResponse = {
    id: number;
};

const useCreateOrUpdateProvider = (providerId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const { showToastErrors } = useShowApiErrors();

    const fetcher = async (payload: any) => {
        const apiService = !!providerId ? api.patch : api.post;
        const endpoint = !!providerId ? `/providers/${providerId}` : `/providers`;

        return apiService<CreateOrUpdateProviderResponse>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(createOrUpdateProviderKey(providerId), fetcher, {
        onSuccess: ({ id }) => {
            navigate(`../${id}`);

            showToast('Fornecedor salvo com sucesso', 'success');
        },
        onError: (error: any) => {
            const defaultMessage = 'Ocorreu um erro ao salvar o fornecedor';
            showToastErrors(error, defaultMessage);
        }
    });
};

export default useCreateOrUpdateProvider;
