import React from 'react';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { TableProgress, TableResult } from 'types/models/report';
import { formatDate } from 'utils/date';

type PhysicalProgressTableProps = {
    tableProgress?: TableProgress[];
    tableResult?: TableResult;
};

const PhysicalProgressTable = ({ tableProgress = [], tableResult }: PhysicalProgressTableProps) => {
    return (
        <>
            {Boolean(tableProgress.length) && (
                <TableContainer>
                    <Table className="mb-6">
                        <TableHead>
                            <TableRow className="uppercase bg-base-300">
                                <TableCell align="center" colSpan={5}>
                                    Avanço físico
                                </TableCell>
                            </TableRow>
                            <TableRow className="bg-base-200">
                                <TableCell>Semanal</TableCell>
                                <TableCell>% Físico Planejado</TableCell>
                                <TableCell>% Acumulo Planejado</TableCell>
                                <TableCell>% Físico Realizado</TableCell>
                                <TableCell>% Acumulo Realizado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableProgress.map((item, index) => {
                                return (
                                    <TableRow key={index} sx={{ '&:last-child td': { border: 0 } }}>
                                        <TableCell>
                                            {formatDate(item.startDate)} à {formatDate(item.endDate)}
                                        </TableCell>
                                        <TableCell>{item.plannedPhysical}</TableCell>
                                        <TableCell>{item.plannedAccumulated}</TableCell>
                                        <TableCell>{item.realizedPhysical}</TableCell>
                                        <TableCell>{item.accumulatedPhysical}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {Boolean(tableResult) && (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={2} className="uppercase bg-base-300">
                                    Resultado Alcançado Físico
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" colSpan={2} className="bg-base-200">
                                    (%) percentual
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td': { border: 0 } }}>
                                <TableCell>Acumulado previsto</TableCell>
                                <TableCell>{tableResult?.expectedAccumulated ? `${tableResult?.expectedAccumulated}%` : '-'}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td': { border: 0 } }}>
                                <TableCell>Acumulado real</TableCell>
                                <TableCell>{tableResult?.realAccumulated ? `${tableResult?.realAccumulated}%` : '-'}</TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td': { border: 0 } }}>
                                <TableCell>Desvio</TableCell>
                                <TableCell>{tableResult?.deviation ? `${tableResult?.deviation}%` : '-'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default PhysicalProgressTable;
