import { useQuery } from 'react-query';
import { getTermTypesOptionsKey } from './keys';
import apiGraphQl from 'services/graphql';
import { Option, Status } from 'types/general';
import { Pagination } from 'types/pagination';

const query = `query GetStatesOptions {
    items: term_type(order_by: {title: asc}, where: {status: {_eq: ${Status.Active}}}) {
        value: id
        label: title
    }
}`;

const useGetTermOptions = () => {
    const request = async () => apiGraphQl<Pagination<Option>>(query).then(({ items }) => items);

    return useQuery(getTermTypesOptionsKey, request);
};

export default useGetTermOptions;
