import { useMutation, useQueryClient } from 'react-query';

import api from 'services/api';
import { getAllKey } from '../crud/graphql';
import { getPeopleKey } from '../people/graphql';
import { createPersonContractStatusOptionsKey } from './graphql';

const usePersonContractStatusMutation = (props: { contractId?: number; personId?: number; onSuccess?(): void; onError?(): void }) => {
    const { contractId, personId, onError, onSuccess } = props || {};

    const queryClient = useQueryClient();

    const request = async (payload: any) => api.post<any>(`/people/${personId}/contracts/${contractId}/status`, payload).then(({ data }) => data);

    return useMutation(createPersonContractStatusOptionsKey, request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getPeopleKey(String(personId!)));

            queryClient.invalidateQueries(
                getAllKey('person_contract', {
                    limit: 10,
                    page: 1,
                    offset: 0,
                    where: {
                        person_id: {
                            _eq: ~~(personId || 0)
                        },
                        status: {
                            _neq: -1
                        }
                    }
                })
            );

            onSuccess?.();
        },
        onError
    });
};

export default usePersonContractStatusMutation;
