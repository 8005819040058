import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { genders } from 'utils/statics';
import Phones from 'pages/private/projects/meeting-records/components/participants/phones';
import Text from 'components/core/text';
import hexToRgba from 'utils/hex-to-rgba';
import theme from 'settings/theme';
import Switch from 'components/core/switch';
import { getNameOrErrorFieldArray } from 'utils/fields-array';
import { useCallback } from 'react';

type ContactsFieldsProps = {
    methods: UseFormReturn<any, any>;
    baseName?: string;
    index?: number;
    withUser?: boolean;
};

const ContactsFields = ({ baseName, methods, index, withUser = true }: ContactsFieldsProps) => {
    const { control, formState, watch } = methods;

    const getNameOrError = useCallback((name: string) => getNameOrErrorFieldArray(formState, baseName, index)(name), [baseName, formState, index]);

    const hasUser = !!watch(getNameOrError('user').name);

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                <Controller name={getNameOrError('name').name} control={control} render={({ field }) => <Input {...field} autoFocus={true} error={getNameOrError('name').error} label="Nome" />} />
                <Controller name={getNameOrError('title').name} control={control} render={({ field }) => <Input {...field} autoFocus={false} error={getNameOrError('title').error} label="Título" />} />
                <Controller
                    name={getNameOrError('gender').name}
                    control={control}
                    render={({ field }) => {
                        const value = genders.find((item) => item.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={genders}
                                label="Gênero"
                                placeholder="Selecione uma opção"
                                error={getNameOrError('gender').error}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
                <Controller
                    name={getNameOrError('email').name}
                    control={control}
                    render={({ field }) => <Input {...field} type="email" autoFocus={false} error={getNameOrError('email').error} label="E-mail" />}
                />
            </div>
            <Phones className="mb-4" accessor={baseName || 'phones'} index={index || 0} {...methods} />
            {withUser && (
                <div className="-mx-2 sm:-mx-4 px-7 py-5 mb-5" style={{ backgroundColor: hexToRgba(theme.extend.colors.secondary[100], 0.2) }}>
                    <div className="flex justify-between items-center">
                        <Text variant="body.medium.sm" as="span" className="text-secondary-700">
                            Esta pessoa será usuária do sistema?
                        </Text>
                        <Controller
                            name={getNameOrError('user').name}
                            control={control}
                            render={({ field }) => <Switch value={!!field?.value} onChange={(value) => field.onChange(value ? { username: '' } : null)} />}
                        />
                    </div>
                    {hasUser && (
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
                            <Controller
                                name={getNameOrError('user.username').name}
                                control={control}
                                render={({ field }) => <Input {...field} autoFocus={true} label="Nome de usuário" placeholder="Nome de usuário" error={getNameOrError('user.usernam.errore').error} />}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default ContactsFields;
