// Dependencies
import { useSearchParams } from 'react-router-dom';

const useGetQueryParam = (param: string) => {
    const [searchParams] = useSearchParams();

    return searchParams.get(param);
};

export default useGetQueryParam;
