import SimpleTable from 'components/core/table/simple';
import { budgetProjectTypesColumns, generateProjectTypesItems } from '../utils';
import useTheme from '@mui/material/styles/useTheme';
import { CommercialBudgetVersionsResponse } from 'services/queries/commercial/use-get-budget-versions';
import { BudgetStatus } from 'types/models/commercial';

type BudgetVersionsTableProps = {
    budgetVersions?: CommercialBudgetVersionsResponse['items'];
    budgetStatus?: BudgetStatus;
    onSetProjectTypeId: React.Dispatch<React.SetStateAction<number | null>>;
    onSetProjectTypeToDeleteId: React.Dispatch<React.SetStateAction<number | null>>;
    onSetProjectProposalTypeId: React.Dispatch<React.SetStateAction<number | null>>;
};

const BudgetVersionTable = ({ budgetVersions, budgetStatus, onSetProjectTypeId, onSetProjectTypeToDeleteId, onSetProjectProposalTypeId }: BudgetVersionsTableProps) => {
    const { palette } = useTheme();

    const budgetVersionsItems = generateProjectTypesItems({
        budgetVersions,
        palette,
        budgetStatus,
        onSetProjectTypeId,
        onSetProjectTypeToDeleteId,
        onSetProjectProposalTypeId
    });

    return <SimpleTable className="mt-6" columns={budgetProjectTypesColumns} items={budgetVersionsItems} />;
};

export default BudgetVersionTable;
