import React from 'react';

// Dependencies
import classNames from 'classnames';
import toast, { Toast as ToastType } from 'react-hot-toast';

// Components
import Icon from 'components/core/icon';

// Helpers
import theme from 'settings/theme';

type ToastOptions = Partial<Pick<ToastType, 'id' | 'duration' | 'ariaProps' | 'className' | 'style' | 'iconTheme'>>;

const colors = {
    danger: ['border-system-danger-300', 'ico-close-circle'],
    warning: ['border-system-warning-300', 'ico-alert'],
    success: ['border-system-success-300', 'ico-check']
};

export const genId = (() => {
    let count = 0;
    return () => {
        return (++count).toString();
    };
})();

const useToast = () => {
    const showToast = (message: string, variant: 'danger' | 'success' | 'warning' = 'danger', options?: ToastOptions) => {
        const id = genId();
        const [color, icon] = colors[variant];

        return toast(message, {
            ...options,
            id,
            className: classNames(styles.toast, color),
            icon: <Icon color={theme.extend.colors.system[variant][500]} name={icon} className={styles.icon} />,
            duration: 5000
        });
    };

    return {
        showToast
    };
};

const styles = {
    icon: 'mr-3',
    toast: 'bg-base-100 flex rounded-lg p-4 border-r-4 toast text-base-500 text-base text-sm'
};

export default useToast;
