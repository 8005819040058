import { jsonToGraphQLQuery } from 'json-to-graphql-query';

export const getTermTypeQuery = (termTypeId?: number) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetFinancialMeasurementByPk',
                term_type_by_pk: {
                    __args: { id: termTypeId },
                    id: true,
                    fields: true,
                    with_attachment: true,
                    with_observations: true,
                    with_caveat_fields: true
                }
            }
        },
        { pretty: true }
    );
};
