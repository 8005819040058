import { useQuery } from 'react-query';
import api from 'services/api';
import { Task } from 'types/models/task';
import { getTaskKey } from './graphql';

const useGetTask = (taskId?: string) => {
    const fetcher = async () => api.get<Task>(`/tasks/${taskId}`).then(({ data }) => data);

    return useQuery(getTaskKey(taskId), fetcher);
};

export default useGetTask;
