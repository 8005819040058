import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { ClassificationType } from 'types/models/classification';
import { TimelineStatusCreation, TimelineType } from 'types/models/timeline';

export const getDailyReportsQuery = (projectProposalTypeId?: number) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetDailyReports',
            daily_report: {
                __args: {
                    where: {
                        project_proposal_type_id: { _eq: projectProposalTypeId }
                    },
                    order_by: { order_daily_report: new EnumType('desc') }
                },
                order_daily_report: true
            }
        }
    });
};

export const createClassificationQuery = (type: ClassificationType) => {
    return jsonToGraphQLQuery({
        query: {
            __name: 'GetClassificationsOptions',
            items: {
                __aliasFor: 'classification',
                __args: {
                    where: {
                        type: { _eq: type },
                        status: { _eq: Status.Active }
                    },
                    order_by: { name: new EnumType('asc') }
                },
                value: { __aliasFor: 'id' },
                label: { __aliasFor: 'name' }
            }
        }
    });
};

export const providerQuery = (projectProposalTypeId?: string | number) => {
    return jsonToGraphQLQuery(
        {
            query: {
                __name: 'GetTimelinesProviderOptions',
                project_proposal_type_by_pk: {
                    __args: {
                        id: Number(projectProposalTypeId || 0)
                    },
                    timelines: {
                        __args: {
                            where: {
                                type: { _eq: TimelineType.Timeline },
                                status_creation: { _eq: TimelineStatusCreation.Finalized }
                            }
                        },
                        provider: {
                            value: { __aliasFor: 'id' },
                            label: { __aliasFor: 'title' }
                        }
                    }
                }
            }
        },
        { pretty: true }
    );
};
