import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import { getTaskKey } from './graphql';

const useDeleteTaskComment = (commentId: number, taskId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async () => api.delete(`/tasks/${taskId}/comments/${commentId}`);

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getTaskKey(taskId));
            showToast('Comentário apagado com sucesso', 'success');
        },
        onError: () => {
            showToast('Ocorreu um erro ao apagar o comentário', 'danger');
        }
    });
};

export default useDeleteTaskComment;
