import PageHeader from 'components/page/header';
import Calendar from './calendar';
import { Outlet } from 'react-router-dom';
import Filters from './filters';
import { useState } from 'react';
import { useGetOccupationMap } from 'services/queries/occupation-map/use-get-occupation-map';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import { CrudPageProps } from 'types/graphql';

const OccupationMap = ({ title }: CrudPageProps) => {
    const branchState = useState<number>();
    const departmentState = useState<number>();
    const yearState = useState<number | undefined>(new Date().getFullYear());

    const [branch] = branchState;
    const [year] = yearState;
    const [classification] = departmentState;

    const filter = {
        branch,
        year,
        classification
    };

    const { data, isLoading, isSuccess } = useGetOccupationMap({
        filter,
        enabled: !Object.values(filter).some((value) => !value)
    });

    const isEmpty = isSuccess && !data?.length;

    return (
        <>
            {isLoading && <Spinner fixed={true} />}

            <div className="flex flex-col h-full w-full max-w-full absolute top-0 left-0">
                <PageHeader title={title} className="p-7" right={<Filters branchState={branchState} yearState={yearState} departmentState={departmentState} />} />
                <div className="bg-base-100 h-full flex-1 w-full max-h-full overflow-hidden ">
                    {isEmpty ? <Empty title="Não foi executada consulta ou nenhum dado foi encontrado." /> : data ? <Calendar year={year!} data={data} /> : null}
                </div>
                <Outlet />
                <style dangerouslySetInnerHTML={{ __html: '.outlet {position: relative; overflow: hidden}' }}></style>
            </div>
        </>
    );
};

export default OccupationMap;
