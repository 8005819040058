import React, { useCallback } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import { Option } from 'types/general';
import Text from 'components/core/text';
import RemoveButton from 'components/buttons/remove';
import Dropzone from 'components/dropzone';
import ErrorMessage from 'components/error/message';
import { generateFileUrl } from 'utils/file';
import { getNameOrErrorFieldArray } from 'utils/fields-array';

type DocumentsFieldsProps = {
    methods: UseFormReturn<any, any>;
    classifications: Option[];
    index?: number;
    baseName?: string;
};

const DocumentsFields = ({ methods, classifications = [], index, baseName }: DocumentsFieldsProps) => {
    const { control, formState, setValue } = methods;

    const getNameOrError = useCallback((name: string) => getNameOrErrorFieldArray(formState, baseName, index)(name), [baseName, formState, index]);

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <Controller name={getNameOrError('name').name} control={control} render={({ field }) => <Input {...field} autoFocus={true} error={getNameOrError('name').error} label="Nome*" />} />

                <Controller
                    name={getNameOrError('classification').name}
                    control={control}
                    render={({ field }) => {
                        const value = classifications.find((item) => (item as any)?.value === field.value);

                        return (
                            <Select
                                {...field}
                                value={value}
                                options={classifications}
                                label="Classificação*"
                                placeholder="Selecione um"
                                error={getNameOrError('classification').error}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                        );
                    }}
                />
            </div>
            <div className="mb-6 relative">
                <Text as="label" variant="body.regular.sm" className="block mb-1.5 text-base-500">
                    Arquivo*
                </Text>
                <Controller
                    name={getNameOrError('file').name}
                    control={control}
                    render={({ field }) => {
                        const handleChangeFile = (filesList: File[]) => {
                            const [file] = filesList || [];

                            field.onChange(file);
                        };

                        const handleClickRemove = () => {
                            setValue(getNameOrError('file').name, '');
                        };

                        const { value } = field;

                        if (!!value) {
                            const link = value?.id ? generateFileUrl(value?.filename, value?.path) : URL.createObjectURL(value);

                            return (
                                <div className="flex items-center h-[120px] border border-base-300 rounded-[14px] px-4 py-3 relative">
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <div className="flex flex-col">
                                            <span className="font-medium text-heading mb-1">{value?.original_name ?? value?.name}</span>
                                            <span className="italic text-xs">Clique para visualizar o arquivo</span>
                                        </div>
                                    </a>

                                    <RemoveButton onClick={handleClickRemove} className="!opacity-100 !right-4" />
                                </div>
                            );
                        }

                        return <Dropzone multiple={false} onUploadFiles={handleChangeFile} />;
                    }}
                />

                <ErrorMessage visible={Boolean(formState.errors?.file)}>Selecione um arquivo</ErrorMessage>
            </div>
        </>
    );
};

export default DocumentsFields;
