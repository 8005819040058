// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import useAuth from 'store/auth/use-auth';
import notification, { getAllNotificationsKey } from './graphql';
import { Notification as NotificationModel } from 'types/models/notification';

const useGetNotifications = () => {
    const { auth } = useAuth();

    const getJobPositions = () => apiGraphQl<{ notifications: Partial<NotificationModel>[] }>(notification.getAll, { user_id: auth.credentials.user.id }).then((data) => data.notifications);

    return useQuery(getAllNotificationsKey, getJobPositions, {
        refetchInterval: 20000,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: true
    });
};

export default useGetNotifications;
