import { useMemo } from 'react';
import difference from 'lodash/difference';
import Text from 'components/core/text';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableBody from '@mui/material/TableBody/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead/TableHead';
import TableFooter from '@mui/material/TableFooter/TableFooter';
import AddButton from 'components/buttons/add';
import { Link, Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import Empty from 'components/empty';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import Icon from 'components/core/icon';
import useTheme from '@mui/material/styles/useTheme';
import useGetProviderFinancialSetups from 'services/queries/providers/use-get-provider-financial-setups';
import { getProviderFinancialSetupsQuery } from './utils';
import { OperationalDetailsPageContext } from '..';
import Spinner from 'components/core/spinner';
import { pluralize } from 'utils/string';
import Badge from 'components/core/badge';
import { formatMoney } from 'utils/money';
import useGetTimelines from 'services/queries/projects/use-get-timelines';
import TableContainer from '@mui/material/TableContainer';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';

type FinancialPageProps = {
    withActions?: boolean;
};

const infoButton = { classes: { child: 'text-system-info-100' } };
const warningButton = { classes: { child: 'text-system-warning-100' } };
const dangerButton = { classes: { child: 'text-system-danger-100' } };

const FinancialPage = ({ withActions }: FinancialPageProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const handleGoTo = (path: string) => () => navigate(path);
    const { isSmallerThenTabletLandscape } = useResponsive();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const { data: timelines } = useGetTimelines(ctx?.projectProposalType?.id?.toString());
    const { data: setups, isLoading, isError } = useGetProviderFinancialSetups(getProviderFinancialSetupsQuery(ctx?.projectProposalType?.id), ctx?.projectProposalType?.id);

    const timelinesWithoutSetupIds = useMemo(() => {
        const timelinesIds = (timelines || [])?.map((item) => item.provider?.id);
        const setupsTimelinesIds = (setups || [])?.map((item) => item.timeline_budget?.provider?.id);

        return difference(timelinesIds, setupsTimelinesIds);
    }, [timelines, setups]);

    if (isLoading) {
        return (
            <div className="absolute left-2/4 top-2/4 -ml-4 -mt-4">
                <Spinner fixed={false} />
            </div>
        );
    }

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <Text as="h6" variant="h6" className="text-heading mb-4">
                Controle de custos
            </Text>
            {!Boolean(timelines?.length) ? (
                <Empty title="Para criar uma configuração financeira é necessário ter ao menos um cronograma criado." />
            ) : isError || !setups ? (
                <Empty title="Configurações financeiras não encontradas" />
            ) : Boolean(setups.length) ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Fornecedor
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Período
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Retenção técnica
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Orçamento
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Entrada
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                        Medições
                                    </Text>
                                </TableCell>
                                {withActions && (
                                    <TableCell>
                                        <Text variant="body.medium.sm" as="span" className="text-secondary-500">
                                            Ações
                                        </Text>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {setups.map((item, index) => {
                                return (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {item.timeline_budget?.provider?.title || '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {pluralize(item.period || 0, 'dias', 'dia')}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Badge variant="info">{item.with_technical_retention ? 'Sim' : 'Não'}</Badge>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {formatMoney(item.timeline_budget?.value || 0)}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {Boolean(item.provider_financial_payments?.[0])
                                                    ? `${item.provider_financial_payments?.[0]?.percentage}% (${formatMoney(item.provider_financial_payments?.[0]?.value)})`
                                                    : '-'}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text as="span" variant="body.regular.sm" className="text-base-500">
                                                {item.provider_financial_measurements_aggregate?.aggregate?.count || 0}
                                            </Text>
                                        </TableCell>
                                        {withActions && (
                                            <TableCell>
                                                <div className="flex">
                                                    <Tooltip placement="top" title="Visualizar">
                                                        <IconButton TouchRippleProps={infoButton} className="hover:bg-primary-100 hover:bg-opacity-20" onClick={handleGoTo(`${item.id}`)}>
                                                            <Icon name="ico-show" width={16} height={16} color={palette.info.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Editar" disableFocusListener={true}>
                                                        <IconButton
                                                            TouchRippleProps={warningButton}
                                                            className="hover:bg-system-warning-100 hover:bg-opacity-30"
                                                            onClick={handleGoTo(`${item.id}/editar`)}>
                                                            <Icon name="ico-edit" width={16} height={16} color={palette.warning.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Apagar" disableFocusListener={true}>
                                                        <IconButton
                                                            TouchRippleProps={dangerButton}
                                                            className="hover:bg-system-danger-100 hover:bg-opacity-30"
                                                            onClick={() => alert('Em desenvolvimento')}>
                                                            <Icon name="ico-trash" width={16} height={16} color={palette.error.main} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {Boolean(timelinesWithoutSetupIds.length) && (
                            <TableFooter>
                                <TableRow>
                                    <TableCell classes={{ root: 'py-2 border-none' }} colSpan={6}>
                                        <AddButton linkComponent={Link} to="novo">
                                            Nova configuração financeira
                                        </AddButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </TableContainer>
            ) : (
                <Empty
                    title="Nenhuma configuração encontrada."
                    buttonLabel={Boolean(timelinesWithoutSetupIds.length) ? 'Criar configuração' : undefined}
                    onClick={Boolean(timelinesWithoutSetupIds.length) ? handleGoTo('novo') : undefined}
                />
            )}
            <Outlet context={{ ...ctx, timelinesWithoutSetupIds }} />
        </>
    );
};

export default FinancialPage;
