import Text from 'components/core/text';
import { classesTableRowHover } from '../utils';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import CurrencyInput from 'components/core/form/currency';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { formatMoney } from 'utils/money';
import { NumberFormatValues } from 'react-number-format';
import { memo, useEffect, useMemo } from 'react';
import { Service, UpdateBudgetPayload } from 'types/models/commercial';
import ResumeTable from './resume-table';
import isEqual from 'lodash/isEqual';

type UpdateServicesTablesProps = {
    services: Service[];
    contractValue: number;
    onSetContractValue: React.Dispatch<React.SetStateAction<number>>;
    isDisabled: boolean;
};

const UpdateServicesTables = ({ services, contractValue, isDisabled, onSetContractValue }: UpdateServicesTablesProps) => {
    const { control, formState, watch, getValues } = useFormContext<UpdateBudgetPayload>();

    const factorValue = watch('factor');
    const { replace } = useFieldArray({ name: 'selectedServices', control });

    const selectedServices = getValues('selectedServices');

    const formatedSelectedServices = useMemo(
        () =>
            services.map((item) => ({
                ...item,
                totalValue: !!item.hourMonth ? (item.hourMonth || 0) * (Number(item.hourValue) + (item.licenseValue || 0)) : item.totalValue
            })),
        [services]
    );

    const reducedServices = useMemo(
        () =>
            formatedSelectedServices.reduce((acc: any[], curr) => {
                const currServiceValue = curr.service?.value;
                const index = acc.findIndex((item) => item.service.value === currServiceValue);
                const newArray = [...acc];

                if (index !== -1) {
                    const updatedService = { ...acc[index], totalValue: (acc[index].totalValue || 0) + (curr.totalValue || 0) };
                    newArray[index] = updatedService;
                } else {
                    newArray.push(curr);
                }

                return newArray;
            }, []),
        [formatedSelectedServices]
    );

    const handleChangeManipulateValue = (onChange: (...event: any[]) => void) => (values: NumberFormatValues) => {
        onChange(values.floatValue);
    };

    useEffect(() => {
        if (!!selectedServices.length) {
            const resumeContractValue = selectedServices.reduce((acc, curr) => acc + (curr.actualValue || 0), 0);

            const totalValue = services.reduce((acc, curr) => {
                const hourLicenseValue = (curr.hourValue || 0) + (curr.licenseValue || 0);
                const hourMonth = curr.hourMonth || 0;
                const value = hourMonth ? hourMonth * hourLicenseValue : curr.totalValue || 0;

                return acc + value;
            }, 0);

            onSetContractValue(resumeContractValue || totalValue);
        }
    }, [selectedServices, onSetContractValue, services]);

    useEffect(() => {
        const formattedSelectedServices = reducedServices.map((item) => {
            const indexServiceById = selectedServices.findIndex((selectedServiceItem) => selectedServiceItem.service?.value === item.service?.value);

            return {
                ...item,
                ...(indexServiceById !== -1 && {
                    actualValue: selectedServices?.[indexServiceById]?.actualValue || 0,
                    quantity: selectedServices?.[indexServiceById]?.quantity || 0,
                    service: selectedServices?.[indexServiceById]?.service
                })
            };
        });

        if (!isEqual(selectedServices, formattedSelectedServices)) {
            replace(formattedSelectedServices);
        }
    }, [selectedServices, reducedServices, replace]);

    return (
        <>
            <Text as="h5" className="text-heading font-medium !text-xl px-6 mt-12 mb-4">
                Resumo
            </Text>
            <TableContainer>
                <ResumeTable contractValue={contractValue} isDisabled={isDisabled} />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="text-secondary-500 pl-6 w-[400px]">ESCOPO</TableCell>
                            <TableCell className="text-secondary-500 w-[215px]">QUANTIDADE / ÁREA</TableCell>
                            <TableCell className="text-secondary-500 w-[215px]">VALOR CONTRATO</TableCell>
                            <TableCell className="text-secondary-500 w-[250px]">VALOR UNITÁRIO</TableCell>
                            <TableCell className="text-secondary-500">VALOR CALCULADO</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '& td:first-of-type': { paddingLeft: '24px' } }}>
                        {selectedServices.map((item, index) => {
                            const selectedServiceIndexByService = selectedServices.findIndex((selectedServiceItem) => selectedServiceItem.service?.value === item.service?.value);
                            const hasSelectedServiceIndexByService = selectedServiceIndexByService !== -1;

                            const actualValue = selectedServices[selectedServiceIndexByService]?.actualValue;
                            const quantityValue = selectedServices[selectedServiceIndexByService]?.quantity;

                            const calculatedUnitValue = hasSelectedServiceIndexByService ? (actualValue || 0) / (quantityValue || 0) : 0;

                            const unitValue = isFinite(calculatedUnitValue) ? calculatedUnitValue : 0;
                            const calculatedValue = (item.totalValue || 0) * (factorValue || 1);

                            return (
                                <TableRow classes={classesTableRowHover} key={`table_row_services_${index}_${item.service?.value}`}>
                                    <TableCell className="!text-xs !text-base-500">{item?.service?.label}</TableCell>
                                    <TableCell className="!text-xs !text-base-500">
                                        <Controller
                                            name={`selectedServices.${index}.quantity`}
                                            render={({ field }) => (
                                                <CurrencyInput
                                                    ref={field.ref}
                                                    name={field.name}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={field.value || 0}
                                                    placeholder="EX: 00,00"
                                                    parentClassName="w-[130px]"
                                                    className="w-full !text-xs"
                                                    onValueChange={handleChangeManipulateValue(field.onChange)}
                                                    disabled={isDisabled}
                                                    error={formState.errors.selectedServices?.[index]?.quantity?.message}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell className="!text-xs !text-base-500">
                                        <Controller
                                            name={`selectedServices.${index}.actualValue`}
                                            control={control}
                                            render={({ field }) => (
                                                <CurrencyInput
                                                    ref={field.ref}
                                                    name={field.name}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={field.value || 0}
                                                    placeholder="EX: 00,00"
                                                    parentClassName="w-[130px]"
                                                    className="w-full !text-xs"
                                                    onValueChange={handleChangeManipulateValue(field.onChange)}
                                                    disabled={isDisabled}
                                                    error={formState.errors.selectedServices?.[index]?.actualValue?.message}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell className="!text-xs !text-base-500">{formatMoney(unitValue)}</TableCell>
                                    <TableCell className="!text-xs !text-base-500">{formatMoney(calculatedValue)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default memo(UpdateServicesTables);
