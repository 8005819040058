// Dependencies
import { createActions, createReducer } from 'reduxsauce';

// Helpers
import api from 'services/api';
import { ReduxAction } from 'types/general';
import { AuthActionsType, AuthState, AuthActions } from './types';

export const { Creators, Types } = createActions<Record<AuthActionsType, AuthActionsType>, AuthActions>({
    setAuth: ['payload'],
    logout: []
});

const INITIAL_STATE: AuthState = {
    isAuthenticated: false,
    token: '',
    credentials: {
        user: {
            email: '',
            id: 0,
            name: '',
            profile: {
                id: 0,
                name: '',
                created_at: '',
                slug: '',
                status: -1,
                updated_at: '',
                profile_page_rules: [],
                dashboard_template_id: 0,
                dashboardTemplate: {
                    key: '',
                    name: ''
                }
            },
            created_at: '',
            is_super_admin: false,
            status: -1,
            updated_at: '',
            username: ''
        },
        account: {
            created_at: '',
            id: 0,
            name: '',
            slug: '',
            status: -1,
            updated_at: ''
        }
    }
};

const setAuth = (state = INITIAL_STATE, action: ReduxAction<AuthState>): AuthState => ({
    ...state,
    ...action.payload,
    isAuthenticated: true
});

const logout = (state = INITIAL_STATE): AuthState => {
    delete api.defaults.headers.common.Authorization;

    return { ...state, ...INITIAL_STATE };
};

export default createReducer<AuthState>(INITIAL_STATE, {
    [Types.SET_AUTH]: setAuth,
    [Types.LOGOUT]: logout
});
