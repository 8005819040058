import RemoveButton from 'components/buttons/remove';
import DatePicker from 'components/core/datepicker';
import CurrencyInput from 'components/core/form/currency';
import Text from 'components/core/text';
import { Controller, useFormContext } from 'react-hook-form';
import { ManagementResourcePayload } from 'types/models/resource';

type ManagementResourceProps = {
    resourceIndex: number;
    periodIndex: number;
    onDeletePeriod: (index: number) => void;
};

const MAX_PERCENTAGE_ALLOWED = 100;

const ManagementResourcePeriods = ({ periodIndex, resourceIndex, onDeletePeriod }: ManagementResourceProps) => {
    const { control, formState, setValue, watch } = useFormContext<ManagementResourcePayload>();

    const handleDeleteResource = () => onDeletePeriod(periodIndex);

    const generateFieldPath = (field: keyof ManagementResourcePayload['resources'][0]['periods'][0]) => `resources.${resourceIndex}.periods.${periodIndex}.${field}` as any;

    const managementStartDate = watch(generateFieldPath('contractStartDate')) as string;

    return (
        <div className="group mb-5 border border-base-300 p-4 rounded-[14px] relative last:mb-0">
            {Boolean(periodIndex) && <RemoveButton onClick={handleDeleteResource} />}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <Controller
                    control={control}
                    name={generateFieldPath('contractStartDate')}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            label="Início do contrato"
                            placeholderText="Selecione uma data"
                            error={formState.errors.resources?.[resourceIndex]?.periods?.[periodIndex]?.contractStartDate?.message}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={generateFieldPath('contractEndDate')}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            minDate={new Date(managementStartDate)}
                            label="Final do contrato"
                            placeholderText="Selecione uma data"
                            error={formState.errors.resources?.[resourceIndex]?.periods?.[periodIndex]?.contractEndDate?.message}
                        />
                    )}
                />
                <div>
                    <Controller
                        control={control}
                        name={generateFieldPath('percentage')}
                        render={({ field }) => {
                            return (
                                <CurrencyInput
                                    allowNegative={false}
                                    isAllowed={({ floatValue = 0 }) => floatValue >= 0 && floatValue <= MAX_PERCENTAGE_ALLOWED}
                                    value={field.value}
                                    onValueChange={(values) => setValue(generateFieldPath('percentage'), values.floatValue)}
                                    autoComplete="off"
                                    label="Percentual"
                                    placeholder="Ex: 10"
                                    error={formState.errors.resources?.[resourceIndex]?.periods?.[periodIndex]?.percentage?.message}
                                    rightClasses="bg-base-200 px-4"
                                    right={
                                        <Text as="span" variant="body.regular.xs" className="text-heading">
                                            %
                                        </Text>
                                    }
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ManagementResourcePeriods;
