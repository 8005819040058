import { string, object, boolean } from 'yup';
import dictionary from 'utils/dictionary';

const { required } = dictionary.validation;

const personBankAccountSchema = object({
    bank: object().typeError('Apenas objeto'),
    agency: string().required(required),
    agency_digit: string().nullable(true),
    account: string().required(required),
    account_digit: string().nullable(true),
    pix: string().required(required),
    main: boolean().default(false)
});

export default personBankAccountSchema;
