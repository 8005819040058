import { useCalendarContext } from '../../context';
import { WEEK_WIDTH, getMonthWidth, getTotalWeeksInYear } from '../../utils';
import WeekDays from './week-days';
import { useIsActiveMonth } from '../../hooks/use-is-active-month';
import { useEffect } from 'react';

const CalendarHeaderMonths = () => {
    const { headerTexts } = useCalendarContext();

    return (
        <>
            {headerTexts.map(({ year, title: month, monthNumber = 1 }, index) => {
                return <Item key={[month, year, index].join('-')} year={year} month={month} monthNumber={monthNumber} />;
            })}
        </>
    );
};

const Item = ({ year, month, monthNumber }: { year: number; month: string; monthNumber: number }) => {
    const isActive = useIsActiveMonth({ year, month });

    useEffect(() => {
        if (isActive) {
            document.getElementById('active-month')?.scrollIntoView({
                behavior: 'smooth',
                inline: 'center'
            });
        }
    }, [isActive]);

    const totalWeeks = getTotalWeeksInYear({
        year,
        month: monthNumber
    });

    const width = getMonthWidth({ year, month: monthNumber });

    const classes = 'flex flex-col items-center justify-center shrink border-b border-slate-200 bg-white';

    return (
        <div
            {...(isActive && {
                id: 'active-month'
            })}
            className={classes}
            style={{ width, maxWidth: WEEK_WIDTH * 6, minWidth: WEEK_WIDTH * 4, flex: '1 0 auto', height: 75 }}>
            <p className="text-base-500 text-xs">{year}</p>
            <p className="text-heading font-medium">{month}</p>
            <WeekDays totalWeeks={totalWeeks} uniqKey={[year, month, totalWeeks].join('-')} />
        </div>
    );
};

export default CalendarHeaderMonths;
