import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { ProjectTypeSolicitation } from 'types/models/project';
import { createOrUpdateProjectTypeSolicitationKey, getProjectTypeSolicitationsKey } from './graphql';

const useCreateOrUpdateSolicitation = <T>(listQuery: string, projectProposalTypeId?: string, projectTypeSolicitationId?: string) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const request = async (payload: T) => {
        const method = projectTypeSolicitationId ? api.patch : api.post;
        const endpoint = projectTypeSolicitationId ? `/project-types/${projectProposalTypeId}/solicitations/${projectTypeSolicitationId}` : `/project-types/${projectProposalTypeId}/solicitations`;

        return method<Partial<ProjectTypeSolicitation>>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(createOrUpdateProjectTypeSolicitationKey(projectProposalTypeId, projectTypeSolicitationId), request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectTypeSolicitationsKey(listQuery));

            showToast(`Solicitação ${projectProposalTypeId ? 'atualizada' : 'criada'} com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${projectProposalTypeId ? 'atualizar' : 'criar'} a solicitação`, 'danger');
        }
    });
};

export default useCreateOrUpdateSolicitation;
