// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { GraphqlByPk } from 'types/graphql';
import { ProjectType } from 'types/models/project';
import project, { getProjectTypeKey } from './graphql';

const useGetProjectType = (projectTypeId?: string) => {
    const getProjectType = async () => {
        return apiGraphQl<GraphqlByPk<ProjectType>>(project.getProjectType, { projectTypeId }).then((data) => data.project_type_by_pk);
    };

    return useQuery(getProjectTypeKey(projectTypeId), getProjectType);
};

export default useGetProjectType;
