import { useMutation } from 'react-query';

import useToast from 'hooks/toast/use-toast';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';

const useResetPassword = (token?: string) => {
    const { showToast } = useToast();
    const navigate = useNavigate();

    const fetcher = async (payload: { password: string; code: string; confirmPassword: string }) => {
        return api.patch(`/forgot-password/${token}`, payload);
    };

    return useMutation(fetcher, {
        onSuccess: () => {
            showToast(`Senha renovada com sucesso`, 'success', { duration: 5000 });
            navigate('/');
        },
        onError: (error) => {
            console.log(error);
            showToast(`Ocorreu um erro renovar sua senha em nosso sistema`, 'danger');
        }
    });
};

export default useResetPassword;
