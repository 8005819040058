import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import useTheme from '@mui/material/styles/useTheme';
import DatePicker from 'components/core/datepicker';
import Input from 'components/core/form/input';
import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Empty from 'components/empty';
import { Controller, useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Task } from 'types/models/task';
import dictionary from 'utils/dictionary';
import { date, number, object, string } from 'yup';
import useGetPeopleByProjectOptions from 'services/queries/people/use-get-people-options-by-project';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import Select from 'components/core/form/select';
import Editor from 'components/core/form/editor';
import Button from 'components/core/button';
import useCreateOrUpdateTask from 'services/queries/task/use-create-or-update-task';
import { timeToHours } from 'utils/date';
import { TimelineStatusCreation } from 'types/models/timeline';
import CurrencyInput from 'components/core/form/currency';
import useGetProjectProposalTypes from 'services/queries/projects/use-get-project-proposal-type';

type Payload = {
    startDate: string;
    endDate: string;
    predictedTime?: number;
    personOrProvider: {
        label: string;
        value: number;
        type: 'person' | 'provider';
    };
    title: string;
    description?: string;
};

const HOUR_IN_MINUTES = 60;

const schema = object({
    title: string().required(dictionary.validation.required),
    startDate: date().nullable(true).notRequired(),
    endDate: date().nullable(true).notRequired(),
    predictedTime: number(),
    personOrProvider: object({
        label: string(),
        value: number(),
        type: string().oneOf(['person', 'provider'])
    }),
    description: string()
});

const CreateOrUpdateTaskPage = () => {
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { pathname } = useLocation();

    const { projectProposalTypeId } = useParams();
    const projectId = useGetQueryParam('projectId');
    const timelineId = useGetQueryParam('timelineId');

    const isUpdate = pathname.includes('editar');

    const ctx = useOutletContext<UseQueryResult<Task>>();

    const { data: people = [] } = useGetPeopleByProjectOptions(Number(projectId));
    const { data: projectProposalType } = useGetProjectProposalTypes(Number(projectProposalTypeId));

    const { mutateAsync: createOrUpdateTask, isLoading: isSubmitting } = useCreateOrUpdateTask(isUpdate, ctx?.data?.id.toString(), timelineId || '');

    const providers = useMemo(() => {
        const arr = projectProposalType?.project_type_person_resources
            ?.filter((item) => Boolean(item.provider))
            .map((item) => ({
                value: item.provider?.id,
                label: item.provider?.title,
                type: 'provider'
            }));

        return arr || [];
    }, [projectProposalType]);

    const personProviderOptions = useMemo(() => {
        const peopleGroup = {
            label: 'Pessoas',
            type: 'person',
            options: people.map((item) => ({
                value: item.value,
                label: item.label,
                type: 'person'
            }))
        };

        const providerGroup = {
            label: 'Fornecedores',
            type: 'provider',
            options: providers
        };

        return [peopleGroup, providerGroup];
    }, [people, providers]);

    const { data: task, isLoading, error } = ctx;

    const scopeIsFinalized = useMemo(() => task?.timeline.statusCreation === TimelineStatusCreation.Finalized, [task]);

    const { control, formState, handleSubmit, reset, setValue } = useForm<Payload>({
        mode: 'onSubmit',
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (scopeIsFinalized) {
            setValue('predictedTime', timeToHours('0'));
        }
    }, [scopeIsFinalized, setValue]);

    const hasChildren = useMemo(() => Boolean(ctx?.data?.tasks?.length), [ctx]);

    useEffect(() => {
        if (ctx?.data && isUpdate) {
            reset({
                title: ctx?.data?.title || '',
                description: ctx?.data?.description || '',
                ...(!hasChildren && {
                    endDate: ctx?.data?.endDate,
                    startDate: ctx?.data?.startDate,
                    // person: ctx?.data?.person?.id,
                    predictedTime: timeToHours(ctx?.data?.predictedTime || ''),
                    ...(Boolean(ctx.data.provider) && {
                        personOrProvider: {
                            label: ctx.data.provider.title,
                            value: ctx.data.provider.id,
                            type: 'provider'
                        }
                    }),
                    ...(Boolean(ctx.data.person) && {
                        personOrProvider: {
                            label: ctx.data.person.title,
                            value: ctx.data.person.id,
                            type: 'person'
                        }
                    })
                })
            });
        }
    }, [hasChildren, ctx, reset, isUpdate]);

    const handleBack = () => navigate(-1);

    const submit = async (data: Payload) => {
        try {
            const isCreate = hasChildren && !isUpdate;

            const payload = {
                description: data.description,
                endDate: data.endDate,
                predictedTime: (data.predictedTime || 0) * HOUR_IN_MINUTES,
                startDate: data.startDate,
                title: data.title,
                ...(data.personOrProvider.type === 'person'
                    ? {
                          [data.personOrProvider.type]: data.personOrProvider.value,
                          provider: null
                      }
                    : {
                          [data.personOrProvider.type]: data.personOrProvider.value,
                          person: null
                      }),
                ...(isCreate && {
                    task: ctx?.data?.id,
                    order: (ctx?.data?.tasks?.length || 0) + 1
                })
            };

            await createOrUpdateTask(payload);
        } catch (error) {
            console.log('CreateOrUpdateTaskPage -> submit', error);
        }
    };

    const formatGroupLabel = (data: any) => data.label;

    return (
        <Modal
            closeOnClickOutside={false}
            contentClassnames="w-[700px]"
            headerLeft={
                task?.title ? (
                    <Text as="h3" variant="h4" className="text-heading">
                        {isUpdate ? task?.title : 'Nova tarefa'}
                    </Text>
                ) : undefined
            }
            onClose={handleBack}>
            {isLoading ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : Boolean(error) ? (
                <Empty title="Tarefa não encontrada" className="p-5" />
            ) : (
                <form className="px-3 sm:px-6 pb-6" onSubmit={handleSubmit(submit)}>
                    <Controller
                        control={control}
                        name="title"
                        render={({ field }) => <Input {...field} autoComplete="nope" type="text" label="Título" error={formState.errors.title?.message} parentClassName="mb-6" />}
                    />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                        <Controller
                            control={control}
                            name="startDate"
                            render={({ field }) => <DatePicker {...field} label="Data inicial" placeholderText="Selecione uma data" error={formState.errors.startDate?.message} />}
                        />
                        <Controller
                            control={control}
                            name="endDate"
                            render={({ field }) => <DatePicker {...field} label="Data final" placeholderText="Selecione uma data" error={formState.errors.endDate?.message} />}
                        />
                    </div>
                    {hasChildren && isUpdate ? null : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                            <Controller
                                name="personOrProvider"
                                control={control}
                                render={({ field }) => {
                                    const value = personProviderOptions?.find((item) => (item as any).type === field?.value?.type)?.options?.find((item) => item.value === field?.value?.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={personProviderOptions}
                                            label="Pessoa/Fornecedor"
                                            placeholder="Selecione uma opção"
                                            error={(formState as any).errors.personOrProvider?.message}
                                            formatGroupLabel={formatGroupLabel}
                                            isClearable={true}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="predictedTime"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <CurrencyInput
                                            disabled={scopeIsFinalized}
                                            value={field.value}
                                            onValueChange={(values) => setValue('predictedTime', values.floatValue)}
                                            autoComplete="nope"
                                            label="Tempo previsto"
                                            placeholder="Ex: 10"
                                            error={formState.errors.predictedTime?.message}
                                            rightClasses="bg-base-200 px-4"
                                            right={
                                                <Text as="span" variant="body.regular.xs" className="text-heading">
                                                    /horas
                                                </Text>
                                            }
                                        />
                                    );
                                }}
                            />
                        </div>
                    )}
                    <Controller
                        control={control}
                        name="description"
                        render={({ field }) => {
                            return <Editor {...field} error={formState.errors?.description?.message} label="Descrição" parentClasses="mb-6" />;
                        }}
                    />
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleBack}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateTaskPage;
