// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { getTimelineProvidersKey } from './graphql';

type Response = {
    project_proposal_type_by_pk: {
        timelines: Array<{ provider: Option }>;
    };
};

const useGetTimelineProviders = (query: string, projectProposalTypeId?: number) => {
    const fetcher = async () => apiGraphQl<Response>(query).then(({ project_proposal_type_by_pk }) => project_proposal_type_by_pk.timelines.map((item) => item.provider));

    return useQuery(getTimelineProvidersKey(projectProposalTypeId), fetcher, { enabled: Boolean(projectProposalTypeId) });
};

export default useGetTimelineProviders;
