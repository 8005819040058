import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Modal from 'components/core/modal';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Select from 'components/core/form/select';

import theme from 'settings/theme';

import { AssetsFurnitureClassifications, AssetsTechnologyClassifications, CreateAssetPayload } from 'types/models/assets';

import { useShowApiErrors } from 'hooks/error/show-api-errors';

import useGetClassificationOptions from 'services/queries/operationals/use-get-classifications-options';
import { ClassificationType } from 'types/models/classification';
import FurnitureFields from './fields/furniture';
import { FormProvider } from 'react-hook-form';
import MachineryFields from './fields/technology/machinery';
import PeripheralsFields from './fields/technology/peripherals';
import EquipmentFields from './fields/technology/equipment';
import TelephonyFields from './fields/technology/telephony';
import { isEmpty } from 'lodash';
import { administrativeAssets } from 'settings/routes/entities/utils/administrative';
import { assetSchema } from './utils';

type AssetProps = {
    title: string;
    isBuilding: boolean;
    isSubmitting: boolean;
    onSubmit: (data: CreateAssetPayload) => void;
    defaultValues?: any;
    error?: any;
};

const INITIAL_FORM_STATE = {
    name: '',
    serial_number: '',
    account_id: 1,
    label: '',
    quantity: 0,
    value: 0,
    insurance_value: 0,
    brand: '',
    model: '',
    observations: '',
    code: '',
    phone: ''
};

const CreateOrUpdateAsset = ({ isBuilding, isSubmitting, onSubmit, defaultValues, error, title }: AssetProps) => {
    const navigate = useNavigate();
    const { classificationTypeId } = useParams();

    const methods = useForm<CreateAssetPayload>({
        mode: 'onSubmit',
        shouldUnregister: true,
        resolver: yupResolver(assetSchema),
        defaultValues: INITIAL_FORM_STATE as any
    });

    const { formState, control, handleSubmit, reset, setError, watch } = methods;
    const { showFormErrors } = useShowApiErrors();

    const titleByClassificationType = `${title} ${administrativeAssets[classificationTypeId!]?.title}`;

    const { data: optionsClassifications } = useGetClassificationOptions(+classificationTypeId!);

    useEffect(() => {
        if (!!error) {
            showFormErrors({ error, setError });
        }
    }, [showFormErrors, error, setError]);

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleClose = () => navigate(-1);

    const classification = watch('classification_id');

    const renderForm = (classificationId: number) => {
        const items = new Map<ClassificationType, Map<AssetsFurnitureClassifications | AssetsTechnologyClassifications, () => JSX.Element>>([
            [
                ClassificationType.AssetFurniture,
                new Map([
                    [AssetsFurnitureClassifications.HomeAppliances, FurnitureFields],
                    [AssetsFurnitureClassifications.Furnitures, FurnitureFields]
                ])
            ],
            [
                ClassificationType.AssetTechnology,
                new Map([
                    [AssetsTechnologyClassifications.Machinery, MachineryFields],
                    [AssetsTechnologyClassifications.Peripherals, PeripheralsFields],
                    [AssetsTechnologyClassifications.Equipment, EquipmentFields],
                    [AssetsTechnologyClassifications.Telephony, TelephonyFields]
                ])
            ]
        ]);

        const categoryMap = items.get(+classificationTypeId!);
        const Component = categoryMap?.get(classificationId) || null;

        return !!Component ? (
            <FormProvider {...methods}>
                <Component />
            </FormProvider>
        ) : null;
    };

    return (
        <Modal onClose={handleClose} contentClassnames="w-[800px]">
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={theme.extend.colors.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5 text-xl">
                        {titleByClassificationType || '-'}
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 gap-4 mb-4">
                        <Controller
                            name="classification_id"
                            control={control}
                            render={({ field }) => {
                                const value = optionsClassifications?.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={optionsClassifications}
                                        label="Classificação"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.classification_id?.message}
                                        onChange={(option: any) => {
                                            field.onChange(option.value);
                                            reset({ classification_id: option.value });
                                        }}
                                    />
                                );
                            }}
                        />
                        {renderForm(classification!)}
                    </div>
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateOrUpdateAsset;
