import IconButton from '@mui/material/IconButton/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import useTheme from '@mui/material/styles/useTheme';
import Icon from 'components/core/icon';
import { generateLabelPercentageWithBadge } from 'pages/private/operationals/dre/utils';
import { formatMoney } from 'utils/money';
import { calculatePercentage, classesTableRowHover, commercialBudgetSelectStyles } from '../utils';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { generateTableClasses } from './update';
import { Option } from 'types/general';
import Select from 'components/core/form/select';
import RemoveButton from 'components/buttons/remove';
import CurrencyInput from 'components/core/form/currency';
import useGetClassificationsOptions from 'services/queries/configs-classifications/use-get-classifications-options';
import { ClassificationType } from 'types/models/classification';
import { useParams } from 'react-router-dom';
import { ProjectTypeEnum } from 'types/models/project';
import { NumberFormatValues } from 'react-number-format/types/types';
import { UpdateBudgetPayload } from 'types/models/commercial';

type ThirdPartyProps = {
    projectServices: Option[];
    isLoadingProjectServices: boolean;
    contractValue: number;
    isDisabled: boolean;
};

const INITIAL = {
    hourMonth: 0,
    hourValue: 0,
    licenseValue: 0,
    totalValue: 0,
    discount: 0,
    addition: 0
};

const ThirdParty = ({ projectServices, isLoadingProjectServices, contractValue, isDisabled }: ThirdPartyProps) => {
    const { palette } = useTheme();
    const { projectTypeId } = useParams();

    const { data: classifications = [], isLoading: isLoadingClassifications } = useGetClassificationsOptions(ClassificationType.BudgetManagement);
    const { formState, control, getValues } = useFormContext<UpdateBudgetPayload>();

    const { fields, append, remove, update } = useFieldArray({ control, name: 'thirdPartyExpenses' });

    const handleAppend = () => append(INITIAL);

    const updatedFields = getValues('thirdPartyExpenses') || [];

    const allRowsTotal = updatedFields.reduce((acc, { hourMonth = 0, hourValue = 0, licenseValue = 0 }) => {
        return (acc += hourMonth * (hourValue + licenseValue));
    }, 0);

    const percentageValue = calculatePercentage(contractValue, allRowsTotal);

    const handleChangeSelect =
        (onChange: (...event: any[]) => void, ix: number, isClassification = false) =>
        (option: any) => {
            onChange(option);

            const field = fields[ix];

            update(ix, {
                ...field,
                ...(isClassification && { classification: option }),
                ...(!isClassification && { service: option })
            });
        };

    const handleChangeValue = (onChange: (...event: any[]) => void) => (value: NumberFormatValues) => {
        return onChange(value.floatValue);
    };

    return (
        <>
            <TableRow classes={classesTableRowHover} className="border-t border-b relative group">
                <TableCell className={generateTableClasses(true)}>
                    <div className="flex items-center gap-2">
                        <span className="w-[150px]">Despesas Terceiros (DT)</span>
                        {!isDisabled && !fields.length && (
                            <Tooltip title="Adicionar serviços" placement="top">
                                <IconButton
                                    color="secondary"
                                    size="medium"
                                    sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }}
                                    onClick={handleAppend}>
                                    <Icon name="ico-plus" width={12} height={12} color={palette.grey[100]} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </TableCell>
                <TableCell colSpan={4} className="!text-xs !text-base-500">
                    {formatMoney(allRowsTotal)}
                </TableCell>
                <TableCell className="!text-xs !text-base-500">{generateLabelPercentageWithBadge(parseFloat(percentageValue.toFixed(2)))}</TableCell>
            </TableRow>
            {fields.map((item, index) => {
                const hourValue = updatedFields[index].hourValue || 0;
                const hourMonth = updatedFields[index].hourMonth || 0;

                const totalRow = hourMonth * hourValue;

                return (
                    <TableRow key={item.id} className="group relative">
                        <TableCell className={generateTableClasses(false)}>
                            <div className="flex items-center gap-2">
                                {projectTypeId === String(ProjectTypeEnum.Management) && (
                                    <Controller
                                        name={`thirdPartyExpenses.${index}.classification`}
                                        control={control}
                                        render={({ field }) => {
                                            const value = classifications.find((item) => item.value === field.value?.value);

                                            return (
                                                <Select
                                                    {...field}
                                                    value={value}
                                                    isLoading={isLoadingClassifications}
                                                    labelClassName="!text-xs"
                                                    className="min-w-[200px] !text-xs"
                                                    options={classifications}
                                                    onChange={handleChangeSelect(field.onChange, index, true)}
                                                    label="Classificação"
                                                    isDisabled={isDisabled}
                                                    selectStyles={commercialBudgetSelectStyles('medium', !!formState.errors.thirdPartyExpenses?.[index].classification?.value?.message)}
                                                    error={formState.errors.thirdPartyExpenses?.[index].classification?.value?.message}
                                                />
                                            );
                                        }}
                                    />
                                )}
                                <Controller
                                    name={`thirdPartyExpenses.${index}.service`}
                                    control={control}
                                    render={({ field }) => {
                                        const value = projectServices.find((item) => item.value === field?.value?.value);

                                        return (
                                            <Select
                                                {...field}
                                                isLoading={isLoadingProjectServices}
                                                value={value}
                                                options={projectServices}
                                                onChange={handleChangeSelect(field.onChange, index)}
                                                label="Serviço"
                                                parentClassName="flex-1"
                                                className="min-w-[200px] w-full !text-xs"
                                                labelClassName="!text-xs"
                                                selectStyles={commercialBudgetSelectStyles('medium', !!formState.errors.thirdPartyExpenses?.[index]?.service?.value?.message)}
                                                isDisabled={isDisabled}
                                                error={formState.errors.thirdPartyExpenses?.[index]?.service?.value?.message}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500">{formatMoney(totalRow)}</TableCell>
                        <TableCell className="!text-xs !text-base-500">
                            <Controller
                                name={`thirdPartyExpenses.${index}.hourMonth`}
                                control={control}
                                render={({ field }) => (
                                    <CurrencyInput
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        label="Quantidade"
                                        parentClassName="w-[130px]"
                                        labelClassName="!text-xs"
                                        className="w-full !text-xs"
                                        onValueChange={handleChangeValue(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors.thirdPartyExpenses?.[index].hourMonth?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell className="!text-xs !text-base-500" colSpan={2}>
                            <Controller
                                name={`thirdPartyExpenses.${index}.hourValue`}
                                control={control}
                                render={({ field }) => (
                                    <CurrencyInput
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        value={field.value}
                                        placeholder="EX: 00,00"
                                        label="Valor"
                                        parentClassName="w-[130px]"
                                        className="w-full !text-xs"
                                        labelClassName="!text-xs"
                                        onValueChange={handleChangeValue(field.onChange)}
                                        disabled={isDisabled}
                                        error={formState.errors.thirdPartyExpenses?.[index].hourValue?.message}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell>{!isDisabled && <RemoveButton className="right-5" onClick={remove.bind(this, index)} />}</TableCell>
                    </TableRow>
                );
            })}
            {!isDisabled && !!fields.length && (
                <TableRow>
                    <TableCell>
                        <Tooltip title="Adicionar serviços" placement="top">
                            <IconButton color="secondary" size="medium" sx={{ backgroundColor: palette.secondary.main, '&:hover': { backgroundColor: palette.secondary.dark } }} onClick={handleAppend}>
                                <Icon name="ico-plus" width={12} height={12} color={palette.grey[100]} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default ThirdParty;
