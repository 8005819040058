import Tab from '@mui/material/Tab/Tab';
import Tabs from '@mui/material/Tabs/Tabs';
import Card from 'components/core/card';
import { useMemo, useState } from 'react';
import { CommercialBudgetVersionsResponse } from 'services/queries/commercial/use-get-budget-versions';
import { Project, ProjectProposalType } from 'types/models/project';
import ProjectProposal from '../proposal';
import ProjectFPs from './fp';
import ProjectDocuments from '../documents';
import MeetingRecords from '../meeting-records';

type ProjectFinancialProps = {
    project: Partial<Project>;
    budgetVersions: CommercialBudgetVersionsResponse['items'];
    projectProposalTypes: Partial<ProjectProposalType>[];
};

const ProjectFinancial = ({ project, budgetVersions, projectProposalTypes = [] }: ProjectFinancialProps) => {
    const [tab, setTab] = useState(0);

    const types = useMemo(() => {
        if (!!budgetVersions.length) {
            return projectProposalTypes.map((item) => ({
                project_type_id: item.project_type?.id,
                project_proposal_id: project.proposal?.id || 0,
                project_type_slug: item?.project_type?.slug
            }));
        }

        return (project?.types || []).map((item) => ({
            project_type_id: item.type.id,
            project_proposal_id: project.proposal?.id || 0,
            project_type_slug: item.type.slug
        }));
    }, [projectProposalTypes, project, budgetVersions]);

    const handleChangeTab = (_, value: number) => setTab(value);

    return (
        <Card className="mb-4 sm:min-h-[281px]">
            <Tabs variant="scrollable" indicatorColor="secondary" value={tab} onChange={handleChangeTab}>
                <Tab label="Propostas" />
                <Tab label="FPs" />
                <Tab label="Documentos" />
                <Tab label="Atas de reunião" />
            </Tabs>
            {tab === 0 && <ProjectProposal projectStatus={project.project_status} projectProposalId={project.proposal?.id} types={types || []} budgetVersions={budgetVersions} />}
            {tab === 1 && <ProjectFPs project={project} />}
            {tab === 2 && <ProjectDocuments withActions={true} />}
            {tab === 3 && <MeetingRecords elevation={0} projectIdProp={project.id} withTitle={false} withActions={true} />}
        </Card>
    );
};

export default ProjectFinancial;
