import useToast from 'hooks/toast/use-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import apiGraphQl from 'services/graphql';
import { getPersonDocumentKey } from './graphql';

const useGetPersonDocument = (props: { documentId?: string }) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const { documentId } = props || {};

    const request = async () => {
        return apiGraphQl<any>(
            `query getPersonDocumentByPk($documentId: Int!) {
                item: person_document_by_pk(id: $documentId) {
                  id
                  document {
                    name
                    description
                    observation
                    classification: classification_id
                    file {
                      id
                      path
                      filename
                      original_name
                    }
                  }
                }
              }
          `,
            { documentId }
        ).then(({ item }) => item?.document);
    };

    return useQuery(getPersonDocumentKey(documentId), request, {
        enabled: !!documentId,
        onError: () => {
            navigate(-1);
            showToast('Tivemos um problema ao obter os detalhes desse documento. Tente novamente.', 'danger');
        }
    });
};

export default useGetPersonDocument;
