export const maskToCurrency = ({ nextState }) => {
    const { value } = nextState || {};
    let amountFormatted = value?.replace?.(/\D/g, '');

    amountFormatted = amountFormatted?.replace?.(/^0+/g, '');

    if (amountFormatted?.length === 2) {
        return {
            ...nextState,
            value: amountFormatted,
            selection: {
                start: amountFormatted.length + 3,
                end: amountFormatted.length + 3
            }
        };
    }

    const amountFormattedWithComma = amountFormatted?.replace?.(/(?=\d{2})(\d{2})$/, '.$1');
    const amountFormattedWithDot = amountFormattedWithComma?.replace?.(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    if (amountFormattedWithDot) {
        return {
            ...nextState,
            value: amountFormattedWithDot,
            selection: {
                start: amountFormattedWithDot.length + 3,
                end: amountFormattedWithDot.length + 3
            }
        };
    }

    return nextState;
};

export const currencyToFloat = (currency = '') => parseFloat(currency.replace(/[^0-9.-]+/g, ''));

export const formatMoney = (number = 0) => number?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

export const convertIntToFloat = (value: number) => (Math.round(value * 100) / 100).toFixed(2);

/**
 * This function will format the number to 1k (thousand), 1M (million), or 1B (billion)
 * @param num number
 * @returns string
 */
export const formatNumber = (num = 0) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return Number(num).toLocaleString('pt-BR');
};
