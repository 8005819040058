import { useQuery } from 'react-query';
import apiGraphQl from 'services/graphql';
import { getTasksByStatusKey, taskDashboardQueries } from './graphql';
import useAuth from 'store/auth/use-auth';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { TaskDashboardItem } from 'types/models/task-dashboard';
import { generateFileUrl } from 'utils/file';
import { TaskFilters } from 'pages/private/tasks';
import { Status } from 'types/general';

type Response = {
    items: {
        tasks: { task: TaskDashboardItem }[];
    }[];
};

type Data = { statusId: number; filters?: TaskFilters };

const useGetTasksByStatus = (data: Data) => {
    const { filters, statusId } = data || {};

    const { auth } = useAuth();

    const personId = auth?.credentials?.user?.person?.id;

    const fetcher = (): Promise<TaskDashboardItem[]> => {
        const taskFilters: any = {
            project_proposal_type: {
                status: { _eq: Status.Active }
            },
            status: { _eq: Status.Active }
        };

        /**
         *  What an ugly code!
         *  TODO: MAKE THINGS BETTER HERE
         */
        if (!!Object.keys(filters || {})?.length) {
            const { title, customer, initialPeriodStartDate, initialPeriodEndDate, finalPeriodStartDate, finalPeriodEndDate, project } = filters || {};

            if (customer) {
                _set(taskFilters, 'project_proposal_type.project_proposal.project.customer_id._eq', customer);
            }

            if (title) {
                _set(taskFilters, 'title._ilike', `%${title}%`);
            }

            if (project) {
                _set(taskFilters, 'project_proposal_type.project_proposal.project_id._in', project?.split(','));
            }

            Object.entries({ initialPeriodStartDate, finalPeriodStartDate }).forEach(([key, value]) => {
                if (!value) return;

                _set(taskFilters, `start_date.${key === 'initialPeriodStartDate' ? '_gte' : '_lte'}`, value);
            });

            Object.entries({ initialPeriodEndDate, finalPeriodEndDate }).forEach(([key, value]) => {
                if (!value) return;

                _set(taskFilters, `end_date.${[key === 'initialPeriodEndDate' ? '_gte' : '_lte']}`, value);
            });
        }

        return apiGraphQl<Response>(taskDashboardQueries.getTasksByStatus, {
            ...(taskFilters && { taskWhere: { task: taskFilters } }),
            personId,
            statusId
        }).then(({ items }) => {
            return _get(items, '[0].tasks', []).map(({ task }) => {
                if (task?.person) {
                    task.person.profileImage = generateFileUrl(task?.person?.file?.filename, task?.person?.file?.path);
                }

                task.comments = task?.comments?.aggregate?.count;
                task.files = task?.files?.aggregate?.count;

                return task as TaskDashboardItem;
            });
        });
    };

    return useQuery(getTasksByStatusKey(statusId, filters), fetcher, { enabled: !!personId && !!statusId });
};

export default useGetTasksByStatus;
