import React from 'react';
import InstitutionalLayout from '../layout/institutional';

const TermsOfUsePage = () => {
    return (
        <InstitutionalLayout title="Termos de Uso">
            <section className="text-gray-500 text-xl">
                <div className="rich-content">
                    <div>
                        <p>
                            <b>POR FAVOR, LEIA COM ATENÇÃO ESSES TERMOS DE USO ANTES DE ACESSAR A NOSSA PLATAFORMA OU FAZER USO DE NOSSOS SERVIÇOS.</b>
                        </p>
                        <p>
                            Reservamo-nos o direito de alterar ou modificar estes Termos de Uso ao nosso exclusivo critério, a qualquer momento. Qualquer alteração ou modificação a estes Termos de Uso
                            entrará em vigor imediatamente após a publicação em nosso website. Você é responsável por analisar periodicamente a mais atualizada versão destes Termos de Uso.
                        </p>
                        <p>
                            Nossa obtenção e uso de informações associadas aos Serviços é disciplinada também por nossa Política de Privacidade constante do item 3 abaixo (“Política de Privacidade”).
                            Os Termos de Uso são válidos para todos os Usuários de nossa Plataforma. O uso continuado dos nossos Serviços importa na aceitação de quaisquer alterações ou modificações
                            feitas nos Termos de Uso, sendo certo que estes Termos de Uso imediatamente encerram, substituem e superam todos os acordos, termos de uso e acertos anteriores entre você e
                            o Milidata.
                        </p>
                        <p>
                            O Milidata ou qualquer de seus afiliados poderá fornecer a uma gerenciadora de reclamações ou seguradoras quaisquer informações necessárias (inclusive suas informações de
                            contato) se houver qualquer reclamação e tais informações forem necessárias para solucionar a reclamação, litígio ou conflito.
                        </p>
                        <p>Os Termos de Uso são válidos para todos os Usuários de nossa Plataforma.</p>
                        <p>
                            <b>1. TERMOS DEFINIDOS</b>
                        </p>
                        <p>1.1. Definições. As expressões sublinhadas abaixo terão o significado que lhes é atribuído em cada respectivo item:</p>
                        <p>&nbsp;</p>
                        <p>“Milidata” plataforma desenvolvida pela Blank Comunicação Digital LTDA inscrita no CNPJ 19.222.699/0001-62.</p>
                        <p>
                            “Professor” significa qualquer pessoa, com pelo menos 18 (treze) anos de idade, residente em qualquer parte do mundo, que tenha sido cadastrado&nbsp; em nossa Plataforma e
                            atenda as condições impostas nas disposições destes Termos de Uso.
                        </p>
                        <p>“Aceitação” significa a obtenção de seu consentimento em relação aos Termos de Uso da Plataforma Milidata e obtenção a quaisquer materiais relacionados a marca Milidata.</p>
                        <p>
                            <span>“Conteúdo” terá o significado que lhe é atribuído pelo item 4.5.</span>
                        </p>
                        <p>“Contrato” terá o significado que lhe é atribuído pelo item 2.2.</p>
                        <p>“Dados” significa Informações Públicas e Dados Pessoais, em conjunto.</p>
                        <p>“Dados Pessoais” significa qualquer outra informação e/ou parte de Suas Informações que não sejam Informações Públicas.</p>
                        <p>
                            <span>
                                “Informação Pública” significa o nome, afiliação a companhias e localidade, bem como qualquer parte de Suas Informações que, por meio do uso da Plataforma ou de outra
                                maneira, seja enviada ou disponibilizada para a inclusão em Áreas Públicas da Plataforma.
                            </span>
                        </p>
                        <p>“Licença Limitada” terá o significado que lhe é atribuído pelo item 4.1.</p>
                        <p>“Operador” significa qualquer pessoa natural ou jurídica a qual compete a realização do tratamento de Dados e Suas Informações em nome do Milidata.</p>
                        <p>“Plataforma” terá o significado que lhe é atribuído pelo item 7.1.</p>
                        <p>
                            <span>“Serviços” terá o significado que lhe é atribuído pelo item 2.1.</span>
                        </p>
                        <p>
                            <span>“Usuário” significa qualquer pessoa que faça uso das Áreas Privadas da Plataforma e que não tenha sido registrado como “Professor”.</span>
                        </p>
                        <p>
                            <span>Interpretação. As seguintes regras de interpretação deverão ser aplicadas a estes Termos de Uso:</span>
                        </p>
                        <p>(i) as expressões definidas no item 1.1, grafadas no singular, incluirão o plural, e vice-versa;</p>
                        <p>(ii) as referências aos itens e parágrafos constituem referências aos itens e parágrafos destes Termos de Uso, salvo especificação em contrário;</p>
                        <p>(iii) o termo “incluir” ou “incluindo” deverá ser interpretado sempre como sendo seguido da expressão “mas sem se limitar” ou “mas sem limitação”.</p>
                        <p>
                            <b>2. ACEITAÇÃO AOS TERMOS DE USO</b>
                        </p>
                        <p>
                            2.1. Você somente estará autorizado a utilizar os Serviços do Milidata se concordar com todas regras e condições estabelecidas nestes Termos de Uso, que será o contrato
                            entre o Milidata e você, e que regulará toda a nossa relação obrigacional e jurídica (“Contrato”).
                        </p>
                        <p>
                            2.2. Caso não concorde com estes Termos de Uso, você não estará autorizado a acessar ou utilizar os Serviços oferecidos em nossa Plataforma. A utilização dos Serviços do
                            Milidata está expressamente condicionada ao seu consentimento às regras deste Contrato.
                        </p>
                        <p>
                            <b>3. POLÍTICA DE PRIVACIDADE: TRATAMENTO DE DADOS, INFORMAÇÕES PÚBLICAS E DADOS PESSOAIS</b>
                        </p>
                        <p>
                            3.1. Finalidade. A finalidade do tratamento dos seus Dados pelo Milidata é a de fornecer, personalizar e aprimorar nossa Plataforma, viabilizando um satisfatório
                            funcionamento dos Serviços e da Plataforma, bem como notificar os usuários sobre as atualizações disponíveis, ofertas, promoções e campanhas de publicidade de nossos
                            Serviços e de parceiros selecionados e, ainda, para aproximar alunos no âmbito de estudo de cada membro. Caso haja alteração na finalidade a qual você consentiu
                            inicialmente para o tratamento de seus Dados, uma nova Aceitação será requerida. Você somente poderá continuar a fazer uso da Plataforma Milidata caso concorde com a nova
                            finalidade estabelecida.
                        </p>
                        <p>
                            3.2. Você é responsável por manter seguras Suas Informações e atualizá-las com frequência. O Milidata age como um canal passivo para a distribuição e publicação de suas
                            Informações Públicas. NÃO TEMOS QUALQUER RESPONSABILIDADE RELACIONADA AO CONTEÚDO DAS POSTAGENS DOS USUÁRIOS E ALUNOS EM NOSSA PLATAFORMA, DECORRENTE DE LEIS DE PROPRIEDADE
                            INTELECTUAL, LEIS TRABALHISTAS, LEIS DE PRIVACIDADE E QUAISQUER OUTRAS LEIS APLICÁVEIS.
                        </p>
                        <p>
                            3.3. Tratamento de Dados. Você consente de forma livre, informada e inequívoca que o Milidata ou qualquer de seus afiliados poderá fornecer seus Dados e Suas Informações a
                            um ou mais Operadores, a fim de viabilizar o tratamento de Dados e Suas Informações pelo Milidata, inclusive no exterior, desde que os Operadores respeitem a privacidade e
                            a proteção de Dados em nível condizente com aquele oferecido pela legislação brasileira e por estes Termos de Uso, e ofereçam condições adequadas de segurança no
                            armazenamento e processamento dos Dados.
                        </p>
                        <p>
                            3.4. Parceiros Comerciais. Os Dados Pessoais poderão ser compartilhados com parceiros comerciais selecionados para o oferecimento de serviços ou produtos relacionados,
                            observando sempre a condição de que estes respeitem os termos destes Termos de Uso. Não compartilharemos os seus Dados Pessoais para qualquer finalidade que esteja em
                            desacordo com a legislação vigente ou com estes Termos de Uso. POR MEIO DA UTILIZAÇÃO DE NOSSOS SERVIÇOS E ACESSO A NOSSA PLATAFORMA VOCÊ CONCORDA EXPRESSAMENTE COM O
                            COMPARTILHAMENTO DOS SEUS DADOS PESSOAIS COM NOSSOS PARCEIROS COMERCIAIS.
                        </p>
                        <p>
                            3.5 Requerimentos. Quaisquer requerimentos relacionados ao tratamento de seus Dados e Suas Informações, incluindo, mas não se limitando, a exclusão dos Dados e Suas
                            Informações de nossa Plataforma, deverão ser encaminhados diretamente ao Milidata.
                        </p>
                        <p>
                            3.5.1. Os Dados Pessoais coletados, incluindo, mas não se limitando a, dados geográficos, demográficos e de perfil de consumo, poderão ser utilizadas para permitir a melhor
                            customização de sua experiência na Plataforma.
                        </p>
                        <p>
                            3.6 Restrições. Ao considerar o uso de nossa Plataforma e de nossos Serviços, você concorda que as Suas Informações: (a) não devem ser fraudulentas; (b) não devem infringir
                            nenhum direito autoral de terceiros, patente, marca registrada, direito de distribuição ou outro direito de propriedade intelectual, de publicação ou privacidade; (c) não
                            devem violar nenhuma lei, estatuto, ordenação ou regulamento; (d) não devem ser difamatórias, caluniosas, ameaçadoras ou abusivas; (e) não devem ser obscenas ou conter
                            pornografia, pornografia infantil, ou fotos de pessoas despidas; (f) não devem conter vírus, cavalos de tróia, worms, time bombs, cancelbots, easter eggs ou outras rotinas
                            de programação que possam danificar, interferir, interceptar ou desapropriar qualquer sistema, dado ou informação pessoal; (g) não devem nos responsabilizar ou fazer com
                            que percamos (total ou parcialmente) o serviço do nosso Provedor de Internet ou outros fornecedores; e/ou (h) não devem criar links direta ou indiretamente a qualquer
                            material que você não tenha direito de incluir ou linkar.
                        </p>
                        <p>
                            3.7 Obrigações. Por meio destes Termos de Uso você concorda que: (a) para registro e manutenção do seu cadastro em nosso site, deverá nos informar um endereço válido de
                            e-mail, devendo sempre atualizá-lo em caso de mudança; (b) é vedada a utilização do seu perfil de Professor, da Newsletter do Milidata, de postagens de comentários em
                            blogs, de uploads de fotos ou quaisquer porções do portal de internet reservadas apenas a uso dos Usuários, para fins comerciais, vendas de bens e serviços, ou promoção de
                            uma companhia, bem ou serviço não relacionado ao tópico ou espírito do Milidata; e (c) será exclusivamente responsável pelas informações que Postar nas áreas publicamente
                            acessíveis da Plataforma, independentemente de sua intenção, ou não, de fazê-lo.
                        </p>
                        <p>3.8 Privacidade. Todos os Dados Pessoais relacionadas ao uso dos Serviços ou ao uso da Plataforma estão sujeitas à Política de Privacidade aqui estipulada.</p>
                        <p>
                            3.9 Licença. Nós não clamamos propriedade de Suas Informações. Nós usaremos os seus Dados Pessoais apenas de acordo com a nossa Política de Privacidade. Entretanto, para
                            nos autorizar a usar suas Informações Públicas e para nos assegurar de que não violamos nenhum direito que você possa ter sobre suas Informações Públicas, ao concordar com
                            estes Termos de Uso, VOCÊ GARANTE AO Milidata O DIREITO NÃO-EXCLUSIVO, UNIVERSAL E SEM PAGAMENTO DE ROYALTIES DE UTILIZAR NO BRASIL E/OU NO EXTERIOR E DE EXERCER,
                            COMERCIALIZAR E EXPLORAR TODOS OS DIREITOS DE CÓPIA, PUBLICIDADE, E DIREITOS DE BASE DE DADOS QUE VOCÊ POSSUA SOBRE SUAS INFORMAÇÕES PÚBLICAS, ATRAVÉS DE QUALQUER MEIO
                            CONHECIDO OU QUE VENHA A SER CRIADO FUTURAMENTE.
                        </p>
                        <p>
                            3.10 Acessibilidade das Informações Públicas. As suas Informações Públicas podem ser acessíveis e publicadas por programas de publicação automática e por ferramentas de
                            busca, ferramentas de metabusca, crawlers, metacrawlers e outros similares.
                        </p>
                        <p>
                            3.11 Consentimento de Divulgação. Você entende e concorda que o Milidata pode divulgar seus Dados caso: (a) se verifique legítimo interesse do Milidata; (b) lhe seja
                            requerido por lei ou por acreditar de boa-fé que essa divulgação é razoável e necessária para: (i) cooperar com um procedimento judicial, uma ordem judicial ou processo
                            legal sobre nós ou nosso portal de internet; (ii) reforçar os Termos de Uso; (iii) replicar a respeito da infração do direito de terceiros por suas informações; e/ou (iv)
                            proteger os direitos, propriedades ou a segurança pessoal do Milidata, seus empregados, Usuários e público.
                        </p>
                        <p>
                            3.12 Consentimento de Divulgação. O Milidata reserva-se ao direito, a seu exclusivo critério, de suspender ou excluir o seu uso e cadastro de nossa Plataforma e de nossos
                            Serviços, bem como de recusar todo e qualquer uso corrente ou futuro de todas ou algumas partes de nossa Plataforma e/ou Serviços.
                        </p>
                        <p>
                            3.13 Senha e Segurança. Ao completar o seu processo de registro, você receberá uma senha que habilitará seu acesso à Plataforma do Milidata. Você concorda em manter a
                            confidencialidade da sua senha e é inteiramente responsável por qualquer dano resultante da falta de confidencialidade e de todas as atividades que ocorrerem através do uso
                            de sua senha. Você concorda em notificar o Milidata imediatamente de qualquer utilização não autorizada de sua senha ou qualquer quebra de segurança referente ao seus
                            cadastro e acesso para que possamos tomar as medidas cabíveis e evitar o vazamento de seus dados. O Milidata NÃO SERÁ RESPONSABILIZADO POR QUALQUER PERDA OU DANO QUE OCORRA
                            A PARTIR DO DESCUMPRIMENTO DO DISPOSTO NESTE ITEM.
                        </p>
                        <p>
                            3.14 Os Usuários e Professores que nos fornecerem seus números de telefone poderão ser contatados pelo Milidata via telefone para serem informados a respeito de novos
                            produtos e serviços ou eventos próximos. SE VOCÊ NÃO DESEJA RECEBER ESMilidata LIGAÇÕES, POR FAVOR NOS AVISE ATRAVÉS DE NOSSA CENTRAL DE ATENDIMENTO.
                        </p>
                        <p>3.15 Crianças.</p>
                        <p>
                            A Plataforma não se destina a menores de 13 anos de idade. O Milidata não coleta ou solicita Dados Pessoais de visitantes abaixo dessa faixa etária intencionalmente, exceto
                            quando permitido pela respectiva legislação. QUALQUER PESSOA QUE NOS PRESTE SEUS DADOS PESSOAIS DECLARA QUE TEM 13 ANOS DE IDADE OU MAIS.
                        </p>
                        <p>3.16 Exclusão de Conta e Dados Pessoais.</p>
                        <p>
                            Determinadas informações são necessárias para fornecer-lhe os Serviços. Nesse sentido, só excluímos tais informações após excluirmos a sua conta. Ao excluir sua conta, ela
                            será excluída permanentemente da Plataforma dentro de um prazo de [30 (trinta)] dias a partir da solicitação, ressalvadas as hipóteses de guarda obrigatória de registros
                            previstas em lei. Informações de pagamento poderão ser mantidas em nossa base de dados para futuras referencias contábeis.
                        </p>
                        <p>3.17 Segurança da Informação.</p>
                        <p>
                            O Milidata&nbsp;utiliza medidas de segurança comercialmente razoáveis para proteger as informações contidas em nossos servidores e banco de dados. O Milidata&nbsp;não
                            oferece nenhuma garantia de infalibilidade dos Serviços. A Plataforma e seus respectivos conteúdos são oferecidos “na forma em que estão”. Quando recebemos e transferimos
                            certos tipos de informações confidenciais, incluindo, mas não se limitando a, informações financeiras e dados de cartão de crédito, redirecionamos os visitantes a
                            servidores seguros, notificando-os através de uma janela pop-up em nosso aplicativo ou website. Sempre que solicitamos um número de cartão de crédito, esse número será
                            transmitido no formato criptografado padrão da indústria, SSL (Secure Sockets Layer, camada de soquetes de segurança). Ao completar o seu processo de registro, você criará
                            uma senha que habilitará seu acesso à Plataforma do Milidata. Você concorda em manter a confidencialidade da sua senha e é inteiramente responsável por qualquer dano
                            resultante da falta de confidencialidade e de todas as atividades que ocorrerem através do uso de sua senha. Você concorda em notificar o Milidata&nbsp;imediatamente de
                            qualquer utilização não autorizada de sua senha ou qualquer quebra de segurança referente ao seus cadastro e acesso para que possamos tomar as medidas cabíveis e evitar o
                            vazamento de seus dados. O Milidata&nbsp;NÃO SERÁ RESPONSABILIZADO POR QUALQUER PERDA OU DANO QUE OCORRA A PARTIR DO DESCUMPRIMENTO DO DISPOSTO NESTE ITEM.
                        </p>
                        <p>3.18 Política de Cookies.</p>
                        <p>
                            O Milidata&nbsp;usa cookies, tags e outras tecnologias similares para melhorar sua experiência em nossa Plataforma e para fornecer-lhe informações adequadas às suas
                            necessidades.
                        </p>
                        <p>
                            3.18.1 Os cookies são utilizados para armazenar as preferências do Usuário e do Aluno em seu computador, smartphone, celular ou tablet e oferecer um serviço personalizado.
                            Servem para reconhecer, acompanhar seus hábitos de navegação e alertar os visitantes sobre novas áreas que podem ser de seu interesse cada vez que retornarem ao nosso
                            aplicativo.
                        </p>
                        <p>
                            3.18.2 Durante a sua navegação em nossa Plataforma, é possível que cookies anônimos ou tecnologias similares sejam instalados no seu computador, smartphone, celular ou
                            tablet.
                        </p>
                        <p>
                            3.18.3 Você poderá recusar os cookies caso não tenha interesse em que trabalhemos para a melhora da sua experiência. Por favor, consulte as instruções de seu navegador para
                            saber mais sobre cookies e sobre como desabilitá-los.
                        </p>
                        <p>4. CONDIÇÕES GERAIS DE USO DOS SERVIÇOS</p>
                        <p>
                            4.1 Ao aceitar estes Termos de Uso, você tem o direito não exclusivo, intransferível, não sub-licenciável e limitado de entrar, acessar e usar os Serviços, unicamente para
                            uso pessoal e não comercial (“Licença Limitada”).
                        </p>
                        <p>4.2 Todos os direitos não previstos expressamente nestes Termos de Uso estão reservados ao Milidata.</p>
                        <p>
                            4.3 Você concorda que os Serviços são para o seu uso pessoal e não comercial e que ninguém além de você usará os Serviços. Você não tem direitos de cópia ou reprodução no
                            todo ou em parte, de qualquer dos Serviços de propriedade do Milidata.
                        </p>
                        <p>
                            4.4 Além da Licença Limitada estabelecida nestes Termos de Uso, você não tem qualquer outro direito, título ou propriedade sobre os Serviços. VOCÊ ENTENDE E RECONHECE QUE,
                            EM QUAISQUER CIRCUNSTÂNCIAS, OS SEUS DIREITOS COM RELAÇÃO AO SERVIÇOS SERÃO LIMITADOS PELOS DIREITOS AUTORAIS E/OU LEIS DE PROPRIEDADE INTELECTUAL APLICÁVEIS E AINDA POR
                            ESTES TERMOS DE USO.
                        </p>
                        <p>
                            4.5 Você é o único responsável pela obtenção, pagamento e manutenção de todos os serviços de telefonia, acesso à internet, plano de dados, tarifas e/ou outras taxas,
                            mensalidades e custos associados ao seu acesso e uso dos Serviços, bem como pelo software, hardware de computador e outros equipamentos necessários para o uso e acesso aos
                            Serviços, não cabendo ao Milidata a responsabilidade pelo uso, aquisição ou reparação dos serviços e produtos acima referidos.
                        </p>
                        <p>
                            4.6 Não obstante outras disposições contidas nestes Termos de Uso, os Serviços estão disponíveis para seu uso por um período que começa a partir do registro de seus dados
                            junto com o pagamento correspondente ao plano escolhido, incluindo as suas renovações.
                        </p>
                        <p>
                            4.7 Você não deve tentar nem apoiar as tentativas de terceiros para driblar e/ou reverter a engenharia, tampouco decodificar, decompilar, desmontar ou fraudar ou interferir
                            de qualquer forma com aspectos dos Serviços. Você não deve distribuir, intercambiar, modificar, vender ou revender ou retransmitir a qualquer pessoa qualquer parte dos
                            Serviços, incluindo, mas não se limitando, a qualquer texto, imagem ou áudio, para qualquer finalidade empresarial comercial ou pública. Você concorda em não copiar,
                            vender, distribuir ou transferir o Conteúdo e/ou Serviços Milidata.
                        </p>
                        <p>
                            <b>5. USO DA PLATAFORMA</b>
                        </p>
                        <p>5.1 Responsabilidade e Controle.</p>
                        <p>
                            Você é inteiramente responsável por Suas Informações que faça upload, poste, distribua, envie por e-mail ou de alguma outra forma torne disponível via nossa Plataforma. Nós
                            não controlamos suas Informações Públicas ou as Informações Públicas de outros Professores ou postadas por eles, e não garantimos a precisão, integridade ou a qualidade de
                            Suas Informações ou das Informações postadas por ou sobre outros Professores, nem endossamos nenhuma opinião expressada por você ou por outros Professores. VOCÊ ENTENDE QUE
                            USANDO NOSSA PLATAFORMA, VOCÊ PODE SER EXPOSTO A INFORMAÇÕES OFENSIVAS, INDECENTES OU COM AS QUAIS NÃO CONCORDE. Nós não temos nenhuma obrigação de monitorar, nem tomamos
                            qualquer responsabilidade pelas Suas Informações, pelas Informações Públicas ou informações a respeito de quaisquer assuntos ou postadas por outros Usuários. Você concorda
                            que sob nenhuma circunstância o Milidata, seus diretores, sócios ou funcionários poderão ser responsabilizados por quaisquer informações, incluindo, mas não se limitando a
                            erros ou omissões nas Suas Informações ou nas Informações Públicas postadas por ou sobre outros Alunos, por perdas e danos de qualquer tipo, ocorridas como resultado do uso
                            das Suas Informações ou de quaisquer Informações Públicas de ou sobre outros Alunos que venham a ser postadas, enviadas por e-mail, transmitidas ou disponibilizadas de
                            qualquer outra maneira conectada à nossa Plataforma, ou por qualquer falha em corrigir ou remover quaisquer dessas informações.
                        </p>
                        <p>
                            5.2 Condições para Suspensão ou Término dos Serviços Os seguintes tipos de ação são vedados no portal de internet e na Plataforma Milidata e são passíveis de suspensão e/ou
                            término imediato do seu status de Professor:
                        </p>
                        <p>(a) Uso de nossa Plataforma para:</p>
                        <p>(i) ameaçar ou intimidar outra pessoa de qualquer forma, incluindo a restrição ou inibição do uso de nossa Plataforma;</p>
                        <p>
                            (ii) personificar qualquer pessoa (incluindo a equipe do Milidata ou outros Alunos), ou atestar falsamente afiliação ou representação de qualquer pessoa ou companhia,
                            através do uso de endereços de e-mail similares, apelidos, ou a criação de contas falsas ou qualquer outro método ou procedimento;
                        </p>
                        <p>(iii) disfarçar a origem de quaisquer Informações Públicas que sejam transmitidas a terceiros;</p>
                        <p>(iv) perseguir ou perturbar outrem; e/ou (v) coletar ou armazenar dados pessoais de outros usuários;</p>
                        <p>(b) Postar quaisquer Informações Públicas ou outro material:</p>
                        <p>
                            (i) que seja ilegal, ofensivo, racista, preconceituoso, ameaçador, abusivo, perturbador, difamatório, intimidador, vulgar, obsceno, profano, acusatório, que invada a
                            privacidade de outrem (inclusive a postagem de e-mails privados ou informações de contato de outros usuários), odioso, racial, eticamente ou de qualquer outra forma
                            contestável, incluindo quaisquer Informações Públicas ou outro material que possa ser considerado um discurso de ódio;
                        </p>
                        <p>(ii) que seja obsceno, pornográfico ou adulto por natureza;</p>
                        <p>(iii) que você não tenha o direito de disponibilizar por qualquer lei ou por contrato;</p>
                        <p>
                            (iv) que infrinja qualquer patente, marca registrada, segredo comercial, direitos autorais ou quaisquer outros direitos de propriedade intelectual ou direitos de terceiros;
                        </p>
                        <p>
                            (v) que seja qualquer tipo de propaganda ou material promocional não solicitado, ou qualquer outra forma de solicitação (incluindo, mas não se limitando a “spam”, “junk
                            mail”, e correntes de e-mail); ou
                        </p>
                        <p>(vi) que seja de qualquer outra forma inapropriado ou postado de má fé;</p>
                        <p>
                            (c) Encorajar outrem a violar os Termos de Uso ou se recusar a seguir instruções da equipe do Milidata; e (d) Violar (intencional ou não intencionalmente) os Termos de Uso,
                            ou de qualquer lei, ordenação, estatuto ou regulamento local, estadual, nacional ou internacional aplicável.
                        </p>
                        <p>
                            5.2.1. Mesmo que nós proibamos o conteúdo e as condutas listadas no item 5.2 acima, você entende e concorda que você poderá vir a ser exposto a tais condutas e conteúdos e
                            que usa a Plataforma por sua próprio conta e risco. Para os propósitos destes Termos de Uso, “postar” inclui o upload, a postagem, distribuição, envio de e-mails,
                            publicação, transmissão ou disponibilização de qualquer outra forma.
                        </p>
                        <p>
                            5.2.2. Sem se limitar ao disposto acima, o Milidata tem o direito de remover quaisquer Informações Públicas ou outro material que viole esses Termos de Uso ou seja de outra
                            maneira questionável.
                        </p>
                        <p>
                            5.3 Não-Interferência com a Plataforma. Você concorda que não irá: (a) fazer upload, postar, publicar, distribuir, enviar por e-mail ou transmitir de qualquer outra forma
                            rotinas de programação, arquivos ou programas com a intenção de interromper, destruir ou limitar as funcionalidades de qualquer software ou hardware ou equipamento de
                            telecomunicação; (b) interferir com ou perturbar nossa Plataforma ou com as redes conectadas ao nosso portal de internet através do uso de nossa Plataforma, ou desobedecer
                            quaisquer requerimentos, procedimentos, políticas ou regulamentos das redes conectadas ao nosso portal de internet, ou de alguma outra maneira interferir com a nossa
                            Plataforma em qualquer sentido, incluindo através do uso de JavaScript, ou outros códigos; (c) agir de qualquer maneira que imponha uma carga excessivamente pesada, que
                            seja desproporcional ou não razoável, em nossa infraestrutura; e/ou (d) copiar, reproduzir, alterar, modificar ou exibir publicamente qualquer informação que esteja
                            disponível em nosso portal de internet (exceto as Suas Informações), ou criar trabalhos derivados do nosso portal de internet (exceto Suas Informações), com o entendimento
                            de que tais ações constituiriam infração de direitos autorais ou outro tipo de violação à propriedade intelectual e/ou industrial do Milidata ou de quaisquer terceiros,
                            exceto se autorizado por escrito e com antecedência pelo Milidata ou pelo terceiro.
                        </p>
                        <p>
                            5.4 Práticas Gerais de Uso da Plataforma. Você declara saber e entender que nós podemos estabelecer práticas e limites gerais no que diz respeito ao uso de nossa
                            Plataforma. VOCÊ ENTENDE QUE NÓS NOS RESERVAMOS O DIREITO DE SUSPENDER PROFESSORES QUE ESTEJAM INATIVOS POR UM PERÍODO EXTENSO DE TEMPO. Você entende que nós nos reservamos
                            o direito de mudar essas práticas e limites gerais a qualquer momento, a nosso critério, com ou sem aviso.
                        </p>
                        <p>
                            5.5 Atualizações da Plataforma. É facultado ao Milidata&nbsp;proceder a adequações em sua Plataforma, visando o acompanhamento das evoluções tecnológicas relacionadas ao
                            Serviço prestado e a garantia da sua qualidade, sendo que nessa hipótese você será comunicado das referidas evoluções.
                        </p>
                        <p>
                            5.6 O Milidata&nbsp;não se responsabiliza por qualquer tipo de dano, prejuízo ou qualquer outro problema que seja decorrente do uso, inabilidade de uso ou defeito dos
                            programas de computador ou sistemas de tecnologia do Usuário/Professor.
                        </p>
                        <p>
                            5.7 Embora busquemos, em todo o tempo, oferecer uma boa qualidade dos nossos Serviços, o Milidata&nbsp;não garante, sob nenhuma hipótese, que os sistemas de conexão com os
                            serviços (via telefônica, via cabo ou qualquer outro) estejam livres de possíveis falhas ou interrupções, não se responsabilizando pela qualidade da rede utilizada para
                            acesso ao serviço, vez que esta é mantida por terceiros, que não o Milidata, e, portanto, foge do seu controle, diligência e responsabilidade.
                        </p>
                        <p>
                            5.8 Você declara estar ciente que pequenos defeitos de programação (bugs) são comuns a sistemas de tecnologia, isentando, assim, o Milidata, de qualquer responsabilidade
                            por danos decorrentes de tais bugs usuais, eventualmente existentes para a utilização dos Serviços, limitando‐se a responsabilidade do Milidata&nbsp;à correção das
                            intercorrências eventualmente identificadas.
                        </p>
                    </div>
                    <div>
                        <p>
                            <b>6. COMUNICADOS DA EQUIPE Milidata</b>
                        </p>
                        <p>
                            6.1 Você entende e concorda que nós podemos enviar certos comunicados, como anúncios de serviços do Milidata e newsletters, bem como ofertas de bens e serviços relevantes e
                            que beneficiem você ou qualquer grupo de Professores unidos por afinidade que você possa vir a participar, e que esses comunicados são considerados parte de nossa
                            Plataforma.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <b>7. LINKS</b>
                        </p>
                        <p>
                            7.1. Nós podemos providenciar, ou terceiros podem providenciar e publicar, links para outros portais de internets ou recursos. Por não termos controle sobre tais portais de
                            internets ou recursos, você entende e concorda que nós não somos responsáveis pela disponibilidade de tais portais de internet e recursos, e nós não endossamos e/ou nos
                            responsabilizamos nem somos passíveis de ser responsabilizados por qualquer conteúdo, publicidade, produto, ou outro material disponível neste ou através desse portal de
                            internet ou recurso.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <b>8. GARANTIAS E RESPONSABILIDADES</b>
                        </p>
                        <p>
                            8.1. Isenção de Garantias. Nossa Plataforma é oferecida a você “como é” e “como está disponível”. NÓS NOS ISENTAMOS DE GARANTIAS E CONDIÇÕES DE QUALQUER TIPO, SEJAM
                            EXPRESMilidata, IMPLÍCITAS OU INSTITUÍDAS, INCLUINDO, MAS NÃO SE LIMITANDO A GARANTIAS RELACIONADAS À CONFIABILIDADE, CONVENIÊNCIA E PERFORMANCE DE NOSSA PLATAFORMA. NÓS
                            NOS ISENTAMOS AINDA DE QUAISQUER GARANTIAS SOBRE INFORMAÇÕES OU CONSELHOS OBTIDOS ATRAVÉS DE NOSSA PLATAFORMA. ISENTAMO-NOS DE QUAISQUER GARANTIAS DE TERCEIROS POR SERVIÇOS
                            OU BENS RECEBIDOS ATRAVÉS OU ANUNCIADOS EM NOSSA PLATAFORMA OU RECEBIDOS ATRAVÉS DE LINKS EXIBIDOS EM NOSSA PLATAFORMA, BEM COMO POR QUALQUER INFORMAÇÃO OU CONSELHO
                            RECEBIDO ATRAVÉS DE QUAISQUER LINKS EXIBIDOS EM NOSSA PLATAFORMA. ALÉM DISSO, NENHUM CONSELHO OU INFORMAÇÃO, SEJA ORAL OU ESCRITA, OBTIDA DE VOCÊ POR NÓS, DEVE CRIAR NENHUM
                            TIPO DE GARANTIA.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            8. Suposição de Risco. Você entende e concorda que o download ou obtenção de qualquer outra forma de acesso ao nosso material ou dados que não seja através do uso de nossa
                            Plataforma oficial será feito ao seu próprio critério e risco, e que você será o único responsável por quaisquer danos ao seu computador ou perda de dados que resulte do
                            download deste material ou dados.
                        </p>
                        <p>
                            8.3. Limitação de Responsabilidade. Você concorda que, em nenhuma circunstância, o Milidata poderá ser responsabilizado por qualquer dano direto, indireto, incidental,
                            especial, consequencial ou punitivo, incluindo mas não se limitando a perdas e danos, lucros cessantes, perda de uma chance, outras perdas e danos intangíveis (ainda que o
                            Milidata&nbsp;tenha sido alertado sobre a possibilidade de ocorrência de tais danos), relacionado ao uso dos Serviços ou de nossa Plataforma, nem com relação à incapacidade
                            e/ou impossibilidade de usá-los (incluindo hipóteses de negligência). Você concorda também que em nenhuma circunstância o Milidata&nbsp;poderá ser responsabilizado por
                            qualquer dano direto, indireto, incidental, especial, consequencial ou punitivo, incluindo mas não se limitando a perdas e danos, lucros cessantes, perda de uma chance,
                            outras perdas e danos intangíveis, decorrentes de qualquer ação e/ou omissão resultante de dolo, imprudência, negligência e/ou imperícia que sejam praticadas pelo Operador,
                            bem como por quaisquer condutas adotadas pelo Operador que não estejam em observância às orientações passadas ao Operador pelo Milidata.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <b>9. PROPRIEDADE INTELECTUAL</b>
                        </p>
                        <p>
                            9.1. Direitos autorais. O Conteúdo publicado no Portal Milidata&nbsp;e outros trabalhos de autoria encontrados na Plataforma como parte dos Serviços estão protegidos pelas
                            leis e tratados de direitos autorais nacionais e internacionais, assim como outras leis e tratados de propriedade intelectual. O Conteúdo é licenciado, não vendido. Você
                            não pode fazer cópias não autorizadas nem usar nenhum Conteúdo, exceto conforme especificado aqui e de acordo com as leis aplicáveis. Todos os direitos autorais do Conteúdo
                            e dos Serviços (incluindo, mas não se limitando a imagens, fotografias, animações, vídeos, áudio, música, texto, layout e “look and feel” incorporados nos Serviços) são de
                            propriedade do Milidata. Você concorda em cumprir com todas as leis de proteção dos direitos autorais relacionadas ao uso dos Serviços e do Conteúdo. O
                            Milidata&nbsp;reserva-se o direito de tomar as medidas que julgar apropriadas, a seu exclusivo critério, a fim de proteger os direitos autorais do Conteúdo e dos Serviços,
                            além das condições previstas nestes Termos de Uso.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            9.2. Não é permitido aos Usuários e/ou Professores tentar reconfigurar, desmontar ou fazer engenharia reversa no portal de internet, nos Serviços e/ou no Conteúdo do
                            Milidata.
                        </p>
                        <p>
                            9.3. É vedado o download, a gravação, filmagem e/ou qualquer outra forma de armazenamento dos Serviços, independentemente da finalidade, exceto aqueles disponibilizados
                            pelo Milidata&nbsp;com esta opção, enquanto esta opção estiver disponível. Os Serviços são disponibilizados para uso online através de streaming.
                        </p>
                        <p>
                            9.4. Marcas. Você não está autorizado a utilizar nenhuma marca e/ou sinais distintivos encontrados no portal de internet, no Conteúdo e/ou nos Serviços do Milidata. Você
                            não pode copiar, exibir ou usar nenhuma das marcas comerciais sem consentimento prévio por escrito do seu proprietário. Qualquer uso não autorizado poderá violar as leis de
                            propriedade industrial, leis de privacidade, de propriedade intelectual e ainda estatutos civis e/ou criminais.
                        </p>
                        <p>
                            9.5. Todos os direitos e licenças não concedidos expressamente nestes Termos de Uso são reservados aos proprietários dos Conteúdos e/ou Serviços. Estes Termos de Uso não
                            concedem quaisquer licenças implícitas.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <b>10. DISPOSIÇÕES GERAIS</b>
                        </p>
                        <p>
                            10.1. Independência das Disposições. Toda disposição contida nos presentes Termos de Uso deverá ser interpretada de tal forma a permitir sua validade e eficácia nos termos
                            da Lei aplicável. Caso qualquer disposição constante dos presentes Termos de Uso seja declarada inválida, nula, anulável ou ineficaz nos termos das Leis aplicáveis, tal
                            disposição será considerada inválida, nula, anulável ou ineficaz na exata medida de sua proibição ou invalidade, sem que os termos remanescentes de tal disposição ou os
                            demais dispositivos contidos nos presentes Termos de Uso sejam afetados.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            10.2. Tolerância. A tolerância ou o não exercício de quaisquer direitos assegurados nestes Termos de Uso ou na lei em geral não importará em novação ou em renúncia a
                            qualquer desses direitos.
                        </p>
                        <p>10.3. Legislação Aplicável. Estes Termos de Uso serão regidos pelas Leis da República Federativa do Brasil.</p>
                        <p>
                            10.4. Foro. Fica estabelecido que todas as disputas e litígios decorrentes direta ou indiretamente dos direitos e obrigações previstos nestes Termos de Uso serão resolvidas
                            perante o foro central da Comarca da Capital do Estado do Rio de Janeiro, com a exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.
                        </p>
                    </div>
                </div>
            </section>
        </InstitutionalLayout>
    );
};

export default TermsOfUsePage;
