import React from 'react';
import useGetQueryParam from 'hooks/router/use-get-query-param';
import { useOutletContext } from 'react-router-dom';
import Task from './task';
import { Task as TaskModel } from 'types/models/task';
import { UseQueryResult } from 'react-query';

const TaskDetailsPage = () => {
    const timelineId = useGetQueryParam('timelineId');
    const ctx = useOutletContext<UseQueryResult<TaskModel>>();

    if (!ctx) {
        return null;
    }

    const { data: task, isLoading, error } = ctx;

    return <Task timelineId={timelineId} task={task} isLoading={isLoading} hasError={Boolean(error)} />;
};

export default TaskDetailsPage;
