// Dependencies
import { useParams } from 'react-router-dom';
import { useQueries, UseQueryOptions } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { ProjectProposalType } from 'types/models/project';
import project, { getProjectProposalSensitiveDataKey } from './graphql';

type Response = Partial<ProjectProposalType>;

type Type = {
    project_type_id: number | undefined;
    project_proposal_id: number;
};

const useGetProjectProposalSensitiveData = (projectProposalTypeIds: Type[]) => {
    const { projectId } = useParams();

    const getProjectProposalSensitiveData = (type: Type) => () => apiGraphQl<Response>(project.getProjectProposalSensitiveData, type).then((data: any) => data.project_proposal_type[0]);

    const requests: UseQueryOptions<any, any, Response>[] = projectProposalTypeIds.map((item) => ({
        queryKey: getProjectProposalSensitiveDataKey(item as any, Number(projectId)),
        queryFn: getProjectProposalSensitiveData(item),
        enabled: !!item
    }));

    return useQueries(requests);
};

export default useGetProjectProposalSensitiveData;
