import { useEffect, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import Text from 'components/core/text';
import Address from 'components/address';
import Modal from 'components/core/modal';
import Contacts from 'components/contacts';
import Button from 'components/core/button';
import Spinner from 'components/core/spinner';
import Input from 'components/core/form/input';
import Select from 'components/core/form/select';
import Dropzone from 'components/dropzone';
import RemoveButton from 'components/buttons/remove';

import { contractTypesOptionsWithoutIntern, companyFormSchema } from '../utils';
import { Option } from 'types/general';
import { CompanyLocalPayload } from 'types/models/company';
import { generateFileUrl } from 'utils/file';
import ClassificationsServiceType from 'pages/private/providers/details/tabs/services-types/components/classifications';

import { PersonContractType } from 'types/models/person';
import classNames from 'classnames';

type CompanyFormProps = {
    businessTypes: Option[];
    isBuilding: boolean;
    isSubmitting: boolean;
    title: string;
    onSubmit: (data: CompanyLocalPayload) => void;
    defaultValues?: any;
    showImage?: boolean;
    contactsWithUser?: boolean;
    showPartner?: boolean;
};

const CompanyForm = ({ businessTypes, isBuilding, isSubmitting, title, onSubmit, defaultValues, showImage = false, contactsWithUser = true, showPartner = false }: CompanyFormProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isProvider = pathname.includes('fornecedores');

    const methods = useForm<any>({
        mode: 'onSubmit',
        resolver: yupResolver(companyFormSchema({ id: defaultValues?.id, contactsWithUser })),
        defaultValues: {
            juridic_document: '',
            personal_document: '',
            juridic_fantasy_name: '',
            juridic_name: '',
            title: '',
            type: PersonContractType.Juridic,
            partner: false,
            ...defaultValues
        }
    });

    const { control, formState, handleSubmit, reset, setValue, watch } = methods;

    const personContractType = watch('type');

    useEffect(() => {
        if (!!defaultValues?.id) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    const handleClose = () => navigate(-1);

    const showContactsOrClassifications = useMemo(() => !defaultValues?.id, [defaultValues.id]);

    return (
        <Modal contentClassnames="w-[1200px]" onClose={handleClose}>
            {isBuilding ? (
                <div className="p-4">
                    <Spinner color={palette.secondary[100]} fixed={false} size={20} />
                </div>
            ) : (
                <form className="py-5 px-3 sm:px-7" onSubmit={handleSubmit(onSubmit)}>
                    <Text as="h3" variant="h4" className="text-heading mb-5">
                        {title}
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-4">
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <Input {...field} autoFocus={false} error={formState.errors.title?.message} label="Título" parentClassName={classNames(isProvider ? 'sm:col-span-3' : '')} />
                            )}
                        />
                        {isProvider && (
                            <Controller
                                name="type"
                                control={control}
                                render={({ field }) => {
                                    const fieldValue = +field?.value || PersonContractType.Juridic;
                                    const value = contractTypesOptionsWithoutIntern.find((item) => +item.value === fieldValue);
                                    return (
                                        <Select
                                            {...field}
                                            autoFocus={false}
                                            value={value}
                                            error={formState.errors.type?.message}
                                            label="Tipo"
                                            placeholder="Selecione um tipo"
                                            options={contractTypesOptionsWithoutIntern}
                                            onChange={(option: any) => {
                                                field.onChange(option?.value);
                                                setValue('juridic_name', '');
                                                setValue('juridic_document', '');
                                                setValue('juridic_fantasy_name', '');
                                                setValue('personal_document', '');
                                            }}
                                            parentClassName="sm:col-span-1"
                                        />
                                    );
                                }}
                            />
                        )}

                        {(personContractType === PersonContractType.Juridic || !isProvider) && (
                            <>
                                <Controller
                                    name="juridic_name"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            parentClassName={classNames(isProvider ? 'sm:col-span-2' : '')}
                                            autoFocus={false}
                                            error={formState.errors.juridic_name?.message}
                                            label="Nome jurídico"
                                        />
                                    )}
                                />
                                <Controller
                                    name="juridic_fantasy_name"
                                    control={control}
                                    render={({ field }) => <Input {...field} autoFocus={false} error={formState.errors.juridic_fantasy_name?.message} label="Nome fantasia" />}
                                />
                                <Controller
                                    name="juridic_document"
                                    control={control}
                                    render={({ field }) => <Input {...field} autoComplete="nope" mask="99.999.999/9999-99" label="CNPJ" error={formState.errors.juridic_document?.message} />}
                                />
                            </>
                        )}
                        {personContractType === PersonContractType.Personal && isProvider && (
                            <Controller
                                name="personal_document"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Input {...field} value={field.value} autoComplete="nope" mask="999.999.999-99" autoFocus={false} error={formState.errors.personal_document?.message} label="CPF" />
                                )}
                            />
                        )}
                        <Controller
                            name="type_business_id"
                            control={control}
                            render={({ field }) => {
                                const value = businessTypes.find((item) => item.value === field.value);

                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        options={businessTypes}
                                        label="Tipo de negócio"
                                        placeholder="Selecione uma opção"
                                        error={formState.errors.type_business_id?.message}
                                        onChange={(option: any) => field.onChange(option.value)}
                                    />
                                );
                            }}
                        />

                        {showPartner && (
                            <Controller
                                name="partner"
                                control={control}
                                render={({ field }) => {
                                    const options = [
                                        {
                                            value: true,
                                            label: 'Sim'
                                        },
                                        {
                                            value: false,
                                            label: 'Não'
                                        }
                                    ];

                                    const value = options.find((item) => item.value === field.value);

                                    return (
                                        <Select
                                            {...field}
                                            value={value}
                                            options={options}
                                            label="Esse fornecedor é parceiro?"
                                            placeholder="Selecione uma opção"
                                            error={formState.errors.partner?.message}
                                            onChange={(option: any) => field.onChange(option.value)}
                                        />
                                    );
                                }}
                            />
                        )}
                    </div>

                    {showImage && (
                        <div className="border border-base-300 rounded-[14px] py-4 px-2 sm:p-4 mb-4 relative">
                            <Text as="label" variant="body.regular.sm" className="block mb-4 text-base-500">
                                Logo
                            </Text>
                            <Controller
                                name="file"
                                control={control}
                                render={({ field }) => {
                                    const handleChangeFile = (filesList: File[]) => {
                                        const [file] = filesList || [];

                                        field.onChange(file);
                                    };

                                    const handleClickRemove = () => {
                                        setValue('file', '');
                                    };

                                    const { value } = field;

                                    if (!!value) {
                                        const currentImage = !!value?.id ? generateFileUrl(value?.filename, value.path) : URL.createObjectURL(field?.value);

                                        return (
                                            <div className="flex items-center">
                                                <a className="flex items-center" href={currentImage} rel="noreferrer" target="_blank">
                                                    <div className="w-12 h-12 bg-center bg-cover rounded-[14px] mr-4" style={{ backgroundImage: `url(${currentImage})` }}></div>
                                                    <div>
                                                        <span>{value?.original_name ?? value?.name}</span>
                                                    </div>
                                                </a>

                                                <RemoveButton onClick={handleClickRemove} className="opacity-100 right-[18px]" />
                                            </div>
                                        );
                                    }

                                    return (
                                        <Dropzone
                                            multiple={false}
                                            onUploadFiles={handleChangeFile}
                                            accept={{
                                                'image/*': ['.jpeg', '.png']
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    )}

                    <div className="border border-base-300 rounded-[14px] py-4 px-2 sm:p-4 mb-4">
                        <Text as="h5" variant="h6" className="text-heading mb-3">
                            Endereço
                        </Text>
                        <Address {...methods} className="mb-0" />
                    </div>
                    {showContactsOrClassifications && <Contacts withUser={contactsWithUser} {...methods} />}
                    {isProvider && showContactsOrClassifications && <ClassificationsServiceType {...methods} />}
                    <div className="flex items-center">
                        <Button disabled={isSubmitting} loading={isSubmitting} type="submit" variant="contained" color="secondary" className="min-w-[100px] mr-4">
                            Enviar
                        </Button>
                        <Button color="inherit" className="min-w-[100px]" variant="outlined" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CompanyForm;
