import React, { useMemo } from 'react';
import Spinner from 'components/core/spinner';
import Empty from 'components/empty';
import FollowupList from './followup';
import { Outlet, useOutletContext } from 'react-router-dom';
import { OperationalDetailsPageContext } from '../..';
import { ProjectTypeStatus } from 'types/models/project';
import useGetFollowups from 'services/queries/operationals/use-get-followups';

type FollowupProps = {
    withActions?: boolean;
};

const Followup = ({ withActions }: FollowupProps) => {
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext> | undefined>();

    const { data: followups, isLoading, error } = useGetFollowups(ctx?.projectProposalType?.id);

    const isConstructionStarted = useMemo(() => ctx?.projectProposalType?.project_type_status === ProjectTypeStatus.Started, [ctx?.projectProposalType?.project_type_status]);

    if (isLoading) {
        return (
            <div className="absolute left-2/4 top-2/4 -ml-4 -mt-4">
                <Spinner fixed={false} />
            </div>
        );
    }

    if (Boolean(error)) {
        return <Empty className="p-4" title="Acompanhamentos não encontrados" />;
    }

    return (
        <>
            <FollowupList followups={followups || []} isConstructionStarted={isConstructionStarted} withActions={withActions} />
            <Outlet context={ctx} />
        </>
    );
};

export default Followup;
