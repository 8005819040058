import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Status } from 'types/general';
import { ClassificationType } from 'types/models/classification';

export const classificationsQuery = (isTransactionOutcomeDocument?: boolean) =>
    jsonToGraphQLQuery({
        query: {
            __name: 'GetClassificationsOptions',
            items: {
                __aliasFor: 'classification',
                __args: {
                    where: {
                        type: { _eq: isTransactionOutcomeDocument ? ClassificationType.TransactionDocument : ClassificationType.PersonDocument },
                        status: { _eq: Status.Active }
                    },
                    order_by: { name: new EnumType('asc') }
                },
                value: { __aliasFor: 'id' },
                label: { __aliasFor: 'name' }
            }
        }
    });
