import React, { forwardRef, useMemo } from 'react';

// Dependencies
import classNames from 'classnames';
import ReactDatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';

// Components
import Icon from '../icon';
import Input from '../form/input';
import DatePickerHeader from './header';

// Helpers
import theme from 'settings/theme';
import pt from 'date-fns/locale/pt-BR';

// Assets
import 'react-datepicker/dist/react-datepicker.css';
import { Modify } from 'types/general';

registerLocale('pt-BR', pt);

type DatePickerProps = {
    label?: string;
    className?: string;
    inpuWrapperClasses?: string;
    error?: string;
    parentClasses?: string;
    mask?: string;
} & Modify<ReactDatePickerProps, { selectsRange?: boolean }>;

const DatePicker = forwardRef<ReactDatePicker, DatePickerProps>(({ error, label, className, inpuWrapperClasses, parentClasses, mask = '99/99/9999', withPortal = true, ...props }, ref) => {
    const containerClasses = classNames('relative', parentClasses);

    const value = useMemo(() => {
        if (!Boolean(props.value)) {
            return null;
        }

        if ((props.value as any) instanceof Date) {
            return props.value as any;
        }

        return new Date(props.value as string);
    }, [props.value]);

    return (
        <div className={containerClasses}>
            <ReactDatePicker
                {...props}
                customInput={
                    <Input
                        label={label}
                        name={props.name}
                        value={props.value}
                        onChange={props.onChange as any}
                        className={className}
                        inpuWrapperClasses={inpuWrapperClasses}
                        error={error}
                        autoComplete="nope"
                        mask={mask}
                        right={<Icon name="ico-chevron-down" width={16} height={9.4} color={theme.extend.colors.base[400]} className="mr-4" />}
                        rightClasses={props.disabled ? 'bg-base-200' : 'bg-base-100'}
                    />
                }
                renderCustomHeader={DatePickerHeader}
                ref={ref}
                autoComplete="off"
                dateFormat="P"
                locale="pt-BR"
                selected={value}
                withPortal={withPortal}
                value={value}
            />
        </div>
    );
});

export default DatePicker;
