// Dependencies
import { useQuery } from 'react-query';

// Helpers
import { Option } from 'types/general';
import apiGraphQl from 'services/graphql';
import { GraphqlPagination } from 'types/graphql';
import project, { getSubjectsByMeetingRecordKey } from './graphql';

const useGetSubjectsByMeetingRecord = (meetingRecordId?: number) => {
    const fetcher = () => apiGraphQl<GraphqlPagination<Option>>(project.getSubjectByMeetingRecord, { meetingRecordId }).then((data) => data.items);

    return useQuery(getSubjectsByMeetingRecordKey(meetingRecordId), fetcher, { enabled: Boolean(meetingRecordId) });
};

export default useGetSubjectsByMeetingRecord;
