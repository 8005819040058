import { Status } from 'types/general';
import dictionary from 'utils/dictionary';
import { array, number, object, string } from 'yup';

export const projectProposalResourceSchema = (proposalTypeId: number) =>
    object({
        resources: array(
            object({
                job_position_id: number().required(dictionary.validation.required),
                project_proposal_type_id: number()
                    .required(dictionary.validation.required)
                    .default(proposalTypeId || 0),
                quantity: number().min(1, dictionary.validation.number.min(1)).required(dictionary.validation.required),
                seniority: number().required(dictionary.validation.required),
                shift: number().required(dictionary.validation.required),
                frequency: number().required(dictionary.validation.required),
                status: number().required(dictionary.validation.required).default(Status.Active),
                observations: string()
            })
        ).min(1)
    });
