import useToast from 'hooks/toast/use-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { Task } from 'types/models/task';
import { getTimelineKey } from '../projects/graphql';

const useCreateOrUpdateTask = (isUpdate: boolean, taskId?: string, timelineId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const queryClient = useQueryClient();

    const request = async (payload) => {
        const method = isUpdate ? api.patch : api.post;
        const endpoint = isUpdate ? `/tasks/${taskId}` : '/tasks';

        return method<Task>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(request, {
        onSuccess: () => {
            queryClient.invalidateQueries(getTimelineKey(timelineId));

            showToast(`Tarefa ${isUpdate ? 'atualizada' : 'criada'} com sucesso`, 'success');

            navigate(-1);
        },
        onError: () => {
            showToast(`Ocorreu um erro ao ${isUpdate ? 'atualizar' : 'criar'} a tarefa`, 'danger');
        }
    });
};

export default useCreateOrUpdateTask;
