// Dependencies
import { useQuery } from 'react-query';

// Helpers
import apiGraphQl from 'services/graphql';
import { ProjectProposalType, ProjectType } from 'types/models/project';
import project, { getProjectProposalTypesKey } from './graphql';

type Response = {
    items: Partial<ProjectProposalType & ProjectType>[];
};

const useGetProjectProposalTypes = (proposalId?: number, projectId?: number, isWithBudgetVersions = false) => {
    const fetcher = async () => {
        const { items } = await apiGraphQl<Response>(isWithBudgetVersions ? project.getProjectProposalTypes(proposalId) : project.getProjectProposalTypesWithoutVersions(proposalId, projectId));

        return items;
    };

    return useQuery(getProjectProposalTypesKey(proposalId, projectId), fetcher, { enabled: Boolean(proposalId) });
};

export default useGetProjectProposalTypes;
