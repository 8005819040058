export const excludePaths = [
    '/app/operacional',
    '/app/bid',
    '/app/projetos',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/atas-de-reuniao/:meetingRecordId',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/ciso/:cisoId',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/ciso/:cisoId/editar',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/documentos/:documentId',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/documentos/:documentId/editar',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/termos/:termId',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/termos/:termId/editar',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/solicitacoes/:requestId',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/solicitacoes/:requestId/editar',
    '/app/operacional/:projectTypeSlug/:projectProposalTypeId/acompanhamentos/:dailyReportId',
    '/app/contratos/:projectId/proposta',
    '/app/contratos/:projectId/proposta/:projectProposalId',
    '/app/contratos/:projectId/proposta/:projectProposalId/tipo',
    '/app/contratos/:projectId/proposta/:projectProposalId/tipo/:projectTypeId',
    '/app/operacional/:projectTypeSlug/:projectProposalId/databook/*'
];
