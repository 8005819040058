import { useMutation, useQueryClient } from 'react-query';
import api from 'services/api';
import useToast from 'hooks/toast/use-toast';
import { getTimelineKey } from '../projects/graphql';
import { TaskProgressPayload } from 'pages/private/operationals/progress/progress';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTaskKey } from '../task/graphql';

const useCreateOrUpdateTaskProgress = (timelineId?: string | null, taskId?: string, taskProgressId?: string | null) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();

    const request = async (payload: TaskProgressPayload) => {
        const endpoint = !!taskProgressId ? `/tasks/${taskId}/progress/${taskProgressId}` : `/tasks/${taskId}/progress`;
        const method = !!taskProgressId ? api.patch : api.post;

        return method(endpoint, payload);
    };

    return useMutation(request, {
        onSuccess: () => {
            const isDashboardTasksPage = pathname.includes('/app/tarefas');

            if (!isDashboardTasksPage) {
                queryClient.invalidateQueries(getTimelineKey(timelineId));
                queryClient.invalidateQueries(getTaskKey(taskId));
            }

            navigate(-1);

            showToast(`Progresso ${!!taskProgressId ? 'atualizado' : 'informado'} com sucesso`, 'success');
        },
        onError: (error: any) => {
            showToast(error?.response?.data?.title || `Ocorreu um erro ao ${!!taskProgressId ? 'atualizar' : 'informar'} o progresso`, 'danger');
        }
    });
};

export default useCreateOrUpdateTaskProgress;
