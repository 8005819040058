import Empty from 'components/empty';
import { Operational } from 'types/models/operationals';
import { Pagination } from 'types/pagination';
import OperationalsListTable from './table';
import OperationalsListCards from './cards';
import { OperationalOrderByValue } from './quick-filter';
import { ItemsPerPage } from 'types/graphql';

type ConstructionsListProps = {
    data?: Pagination<Operational>;
    listMode: OperationalsListMode;
    page: number;
    orderBy?: OperationalOrderByValue;
    totalPerPage?: ItemsPerPage;
    onChangePage(page: number): void;
    onChangeSort?: React.Dispatch<React.SetStateAction<OperationalOrderByValue | undefined>>;
    onChangeRowsPerPage?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export type OperationalsListMode = 'cards' | 'table';

const OperationalsList = ({ data, listMode, page, orderBy, totalPerPage, onChangePage, onChangeSort, onChangeRowsPerPage }: ConstructionsListProps) => {
    const List = () => {
        if (!listMode) {
            return null;
        }

        const modes = {
            cards: OperationalsListCards,
            table: OperationalsListTable
        };

        const Component = modes[listMode];

        return <Component totalPerPage={totalPerPage} orderBy={orderBy} data={data} page={page} onChangeSort={onChangeSort} onChangePage={onChangePage} onChangeRowsPerPage={onChangeRowsPerPage} />;
    };

    if (!data?.items?.length) {
        return <Empty title="Nenhum registro encontrado" />;
    }

    return <List />;
};

export default OperationalsList;
