import { useEffect } from 'react';
import Text from 'components/core/text';
import { Outlet, useParams } from 'react-router-dom';
import CardsInfo from './components/cards-info';
import useGetprojectDreData from 'services/queries/operationals/use-get-project-dre-details';
import useResponsive from 'hooks/responsive/use-responsive';
import Breadcrumb from 'components/core/breadcrumb';
import SimpleTable from 'components/core/table/simple';
import { formatMoney } from 'utils/money';
import useConfig from 'store/config/use-config';
import Spinner from 'components/core/spinner';
import { DREHeadColumns, generateLabelPercentageWithBadge } from './utils';

export const spaceBorderTableClasses = '!border-r-[40px] !border-r-transparent';
export const bolderTextClasses = 'font-semibold';

const ProjectDrePage = () => {
    const { projectProposalTypeId } = useParams();
    const { isSmallerThenTabletLandscape } = useResponsive();
    const { setConfig } = useConfig();

    const { data: projectDreData, isLoading: isLoadingDataDre } = useGetprojectDreData(projectProposalTypeId as string);

    useEffect(() => {
        setConfig('operational', { isNavbarOpen: false, isSidebarOpen: false });

        // eslint-disable-next-line
    }, []);

    const tableDataExpenses = (projectDreData?.directCosts.expenses || []).map((item, index) => {
        return {
            id: `expenses_${index + 1}`,
            contents: [
                { label: item.title, className: spaceBorderTableClasses },
                { label: formatMoney(item.contract) },
                { label: formatMoney(item.planned) },
                { label: formatMoney(item.realized) },
                { label: formatMoney(item.budget) },
                { label: formatMoney(item.etc) },
                { label: formatMoney(item.eac) },
                { label: generateLabelPercentageWithBadge(item.variation) }
            ]
        };
    });

    const tableDataIndirectCosts = (projectDreData?.directCosts.indirectCosts.expenses || []).map((item, index) => {
        return {
            id: `indirects_costs_${index}`,
            contents: [
                { label: item.title, className: spaceBorderTableClasses },
                { label: formatMoney(item.contract), size: 7 }
            ]
        };
    });

    const tableDataItems = [
        {
            id: `direct_costs_static`,
            contents: [
                {
                    label: 'CUSTOS DIRETOS',
                    textClassName: bolderTextClasses,
                    className: spaceBorderTableClasses
                },
                {
                    label: formatMoney(projectDreData?.directCosts.resume.contract),
                    textClassName: bolderTextClasses
                },
                {
                    label: formatMoney(projectDreData?.directCosts.resume.planned),
                    textClassName: bolderTextClasses
                },
                {
                    label: formatMoney(projectDreData?.directCosts.resume.realized),
                    textClassName: bolderTextClasses
                },
                {
                    label: formatMoney(projectDreData?.directCosts.resume.budget),
                    textClassName: bolderTextClasses
                },
                {
                    label: formatMoney(projectDreData?.directCosts.resume.etc),
                    textClassName: bolderTextClasses
                },
                {
                    label: formatMoney(projectDreData?.directCosts.resume.eac),
                    textClassName: bolderTextClasses
                },
                {
                    label: generateLabelPercentageWithBadge(projectDreData?.directCosts.resume.variation),
                    textClassName: bolderTextClasses
                }
            ]
        },
        ...tableDataExpenses,
        {
            id: `indirect_costs_static`,
            contents: [
                { label: 'CUSTOS INDIRETOS (BDI)', className: spaceBorderTableClasses, textClassName: bolderTextClasses },
                { label: formatMoney(projectDreData?.directCosts.indirectCosts.resume.contract), size: 7, textClassName: bolderTextClasses }
            ]
        },
        ...tableDataIndirectCosts,
        {
            id: `margins_static`,
            contents: [
                { label: 'MARGEM', textClassName: bolderTextClasses, className: spaceBorderTableClasses },
                {
                    label: `${formatMoney(projectDreData?.directCosts.margin.contract.value)} (${projectDreData?.directCosts.margin.contract.percentage || 0}%)`,
                    size: 5,
                    textClassName: bolderTextClasses
                },
                {
                    label: `${formatMoney(projectDreData?.directCosts.margin?.eac?.value)} (${projectDreData?.directCosts.margin.eac.percentage || 0}%)`,
                    textClassName: bolderTextClasses
                },
                { label: generateLabelPercentageWithBadge(projectDreData?.directCosts.margin.variation) }
            ]
        }
    ];

    return (
        <>
            {isSmallerThenTabletLandscape && <Breadcrumb className="mb-4" />}
            <CardsInfo data={projectDreData} isLoading={isLoadingDataDre} />
            <Text as="h6" variant="h6" className="text-heading mb-4">
                Despesas Planejadas
            </Text>
            {isLoadingDataDre ? <Spinner fixed={true} /> : <SimpleTable columns={DREHeadColumns} items={tableDataItems} />}
            <Outlet />
        </>
    );
};

export default ProjectDrePage;
