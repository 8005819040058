import { useQuery } from 'react-query';
import api from 'services/api';
import { ProjectTypeRouteSlug, getOperationalApiSlugByRoute } from './use-get-operational';

export const useGetOperationalOrderBy = ({ projectTypeSlug }: Props) => {
    const fetcher = () => api.get<OperationalOrderBy[]>(`/project-types/${getOperationalApiSlugByRoute(projectTypeSlug)}?listType=order-by`).then(({ data }) => data);

    return useQuery(getKey(projectTypeSlug), fetcher);
};

const getKey = (projectTypeSlug?: ProjectTypeRouteSlug) => ['project-types', 'order-by', projectTypeSlug];

type Props = {
    projectTypeSlug?: ProjectTypeRouteSlug;
};

export type OperationalOrderBy = {
    title: string;
    field: string;
    direction: string;
};
