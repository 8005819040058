import { useOutletContext } from 'react-router-dom';

// Components
import Spinner from 'components/core/spinner';

// Helpers
import useGetDocument from 'services/queries/projects/use-get-document';
import DetailsDocument from '../components/details';
import { Dispatch, SetStateAction } from 'react';
import { OperationalDetailsPageContext } from '../../view';

type TechnicalDocumentProps = {
    documentId: string;
    setViewTechnicalDocumentId?: Dispatch<SetStateAction<number | null>>;
};
const TechnicalDocument = ({ documentId, setViewTechnicalDocumentId }: TechnicalDocumentProps) => {
    const ctx = useOutletContext<Partial<OperationalDetailsPageContext>>();

    const isTechnical = true;

    const { data: document, isLoading: isLoadingDocument } = useGetDocument(ctx?.project?.id.toString(), documentId, isTechnical);

    if (isLoadingDocument) {
        return <Spinner />;
    }

    return <DetailsDocument setViewTechnicalDocumentId={setViewTechnicalDocumentId} isTechnical={isTechnical} document={document} />;
};

export default TechnicalDocument;
