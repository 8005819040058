// Helpers
import { CRUDDetails, GraphqlPaginationVariables, GraphqlQueryPayload } from 'types/graphql';

export const getAllKey = (table: string, variables?: GraphqlPaginationVariables<any>) => ['crud', 'all', table, variables].filter(Boolean);

export const getDetailsKey = (table?: string, id?: string | null) => ['crud', 'details', table, id];

export const createKey = (config: any) => ['crud', 'create', config];

export const generateQueryObject = (term: string) => {
    const keys = term.split('.');

    const [, ...rest] = keys;

    return keys.join(' {\n') + '\n' + (rest || []).map(() => `}`).join('\n');
};

export const generateGetAllQuery = ({ columns, table }: GraphqlQueryPayload) => {
    const arr =
        columns?.map((item) => {
            if (item.name.includes('.')) {
                return generateQueryObject(item.name);
            }

            return item.name;
        }) || [];

    const queryName = `Get${table.charAt(0).toUpperCase()}${table.slice(1)}s`;

    return `query ${queryName} ($orderBy: [${table}_order_by!], $where: ${table}_bool_exp, $limit: Int, $offset: Int) {
        items: ${table} (where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
            ${arr.join('\n')}
        }
        pagination: ${table}_aggregate (where: $where) {
            aggregate {
              count
            }
        }
    }`;
};

export const generateGetDetailsQuery = ({ fields, table }: CRUDDetails) => {
    const arr =
        fields?.map((name) => {
            if (name.includes('.')) {
                return generateQueryObject(name);
            }

            return name;
        }) || [];

    const queryName = `Get${table?.charAt(0).toUpperCase()}${table?.slice(1)}Details`;

    return `query ${queryName}($id: Int!) {
        item: ${table}_by_pk(id: $id) {
            ${arr.join('\n')}
        }
      }`;
};

export const generateDeleteQuery = (table: string, withSlug = false) => {
    let params = `
        status: -1
    `;

    if (withSlug) {
        params += `\n slug: $slug`;
    }

    return `mutation deleteItem($id: Int!, $slug: String) {
        item: update_${table}_by_pk(pk_columns: {id: $id}, _set: {${params}}) {
          id
          status
        }
    }`;
};

const crudQueries = {
    getAll: generateGetAllQuery,
    delete: generateDeleteQuery,
    getDetails: generateGetDetailsQuery
};

export default crudQueries;
