import React, { useMemo, useState } from 'react';
import intersectionBy from 'lodash/intersectionBy';
import { useOutletContext } from 'react-router-dom';
import useCreateFinancialSetup from 'services/queries/operationals/use-create-financial-setup';
import useGetTimelineProviders from 'services/queries/operationals/use-get-providers';
import useGetTimeline from 'services/queries/timeline/use-get-timeline';
import { ProviderFinancialSetupPayload } from 'types/models/provider';
import { formatDate } from 'utils/date';
import { OperationalDetailsPageContext } from '../..';
import { providerQuery } from '../../followup/create-or-update/utils';
import { getTimelineBudgetQuery } from '../utils';
import CreateOrUpdateFinancial from './create-or-update';

const CreateOrUpdateFinancialPage = () => {
    const providerHook = useState<number>();

    const ctx = useOutletContext<Partial<OperationalDetailsPageContext & { timelinesWithoutSetupIds: number[] }> | undefined>();

    const providers = useGetTimelineProviders(providerQuery(ctx?.projectProposalType?.id), ctx?.projectProposalType?.id);

    const { data: timeline } = useGetTimeline(getTimelineBudgetQuery(ctx?.projectProposalType?.id, providerHook[0]), providerHook[0]);

    const { mutateAsync: createFinancialSetup, isLoading: isSubmitting } = useCreateFinancialSetup(ctx?.projectProposalType?.id);

    const isBuilding = useMemo(() => providers.isLoading, [providers.isLoading]);

    const providersAvailable = useMemo(() => {
        const arr = (ctx?.timelinesWithoutSetupIds || [])?.map((item) => ({ value: item }));

        return intersectionBy(providers.data || [], arr, 'value');
    }, [ctx?.timelinesWithoutSetupIds, providers.data]);

    const handleSubmit = async (data: ProviderFinancialSetupPayload) => {
        try {
            delete data['withPaymentStart'];

            const payload = {
                budget: data.budget,
                period: data.period,
                withTechnicalRetention: data.withTechnicalRetention,
                ...(Boolean(data.payments.length) && {
                    payments: data.payments.map((item) => ({
                        ...item,
                        value: item.value?.floatValue || 0,
                        date: formatDate(item.date, 'DD/MM/YYYY')
                    }))
                }),
                measurements: data.measurements.map((item) => ({
                    ...item,
                    expectedDate: formatDate(item.expectedDate, 'DD/MM/YYYY'),
                    predictedValue: item.predictedValue?.floatValue || 0
                }))
            };

            await createFinancialSetup(payload);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <CreateOrUpdateFinancial
            budgetId={timeline?.budget?.id || 0}
            budget={timeline?.budget?.value || 0}
            providers={providersAvailable || []}
            isBuilding={isBuilding}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            providerHook={providerHook}
            projectProposalTypeId={ctx?.projectProposalType?.id}
        />
    );
};

export default CreateOrUpdateFinancialPage;
