import React from 'react';
import SolicitationCreateOrUpdate from './requests';
import { RequestProvider } from 'contexts/request';
import useGetSolicitation from 'services/queries/projects/use-get-solicitation';
import { useParams } from 'react-router-dom';

const SolicitationCreateOrUpdatePage = () => {
    const { projectProposalTypeId, projectTypeSolicitationId } = useParams();

    const { data } = useGetSolicitation(projectProposalTypeId, projectTypeSolicitationId);

    return (
        <RequestProvider>
            <SolicitationCreateOrUpdate solicitation={data} />
        </RequestProvider>
    );
};

export default SolicitationCreateOrUpdatePage;
