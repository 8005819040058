import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import api from 'services/api';
import { createPersonBankAccountKey } from './graphql';
import { getAllKey } from '../crud/graphql';
import useToast from 'hooks/toast/use-toast';
import { GraphqlPaginationVariables } from 'types/graphql';
import { Person, PersonBankAccountPayload } from 'types/models/person';

type Props = {
    personId?: string;
    bankAccountId?: string;
    params: GraphqlPaginationVariables<Person>;
};
const useCreateOrUpdatePersonBankAccountMutation = ({ personId, bankAccountId, params }: Props) => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    const queryClient = useQueryClient();

    const isEdit = !!bankAccountId;

    const fetcher = async (payload: PersonBankAccountPayload) => {
        const apiService = isEdit ? api.patch : api.post;
        const endpoint = isEdit ? `/people/${personId}/bank-accounts/${bankAccountId}` : `/people/${personId}/bank-accounts`;

        return apiService<any>(endpoint, payload).then(({ data }) => data);
    };

    return useMutation(createPersonBankAccountKey(personId, bankAccountId), fetcher, {
        onSuccess: () => {
            navigate(-1);

            queryClient.invalidateQueries(getAllKey('person', params));
            showToast(`Conta bancária ${isEdit ? 'atualizada' : 'criada'} com sucesso`, 'success');
        }
    });
};

export default useCreateOrUpdatePersonBankAccountMutation;
