import ListPage from 'pages/private/crud/list';
import React from 'react';
import dictionary from 'utils/dictionary';
import TableCell from '@mui/material/TableCell/TableCell';
import { CrudPageProps } from 'types/graphql';
import { useCustomerDetails } from 'pages/private/clients/useCustomerDetails';
import Spinner from 'components/core/spinner';
import { WithPagePermissionsProps } from 'hocs/with-page-permissions';

const CustomerDetailsContacts = ({ page }: WithPagePermissionsProps) => {
    const { id } = useCustomerDetails();

    if (!id) {
        return (
            <div className="relative w-full h-full">
                <Spinner />
            </div>
        );
    }

    const listConfig: CrudPageProps = {
        graphql: {
            table: 'customer_contact',
            searchableField: 'person.name',
            statusPath: 'person.status',
            columns: [
                {
                    name: 'id',
                    options: { display: 'excluded' }
                },
                {
                    name: 'person.title',
                    label: dictionary.content.graphql.title
                },
                {
                    name: 'person.name',
                    label: dictionary.content.graphql.name
                },
                {
                    name: 'person.phones',
                    label: dictionary.content.graphql.phones,
                    options: {
                        customBodyRender: (value) => {
                            return value?.map((item) => `(${item.ddd}) ${item.number}`).join(', ') || '-';
                        }
                    }
                },
                {
                    name: 'person.email',
                    label: dictionary.content.graphql.email
                },
                {
                    name: 'actions',
                    label: 'Ações',
                    options: {
                        customHeadRender: () => <TableCell key="actions" style={{ width: 80 }} />
                    }
                }
            ],
            where: {
                customer_id: {
                    _eq: id
                }
            }
        },
        api: {
            endpoint: `/customers/${id}/contacts`,
            useApiTodelete: true
        },
        title: 'Contatos',
        withBorder: true
    };

    return <ListPage page={page} {...listConfig} />;
};

export default CustomerDetailsContacts;
