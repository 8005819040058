import { FocusEvent, forwardRef, InputHTMLAttributes, LegacyRef, memo, useRef } from 'react';

// Components
import Text from 'components/core/text';
import ErrorMessage from 'components/error/message';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

// Helpers
import classNames from 'classnames';

export type InputProps = Partial<{
    label: string;
    error: string;
    parentClassName: string;
    left: JSX.Element;
    leftClasses: string;
    right: JSX.Element;
    rightClasses: string;
    inpuWrapperClasses: string;
    errorMessageClasses: string;
    showErrorMessage: boolean;
    labelClassName?: string;
}> &
    InputHTMLAttributes<HTMLInputElement> &
    Partial<InputMaskProps>;

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ label, className, error, errorMessageClasses, inpuWrapperClasses, left, leftClasses, mask, parentClassName, right, rightClasses, showErrorMessage = true, labelClassName, ...props }, ref) => {
        const inputWrapperRef = useRef<HTMLDivElement>(null);
        const parentClasses = classNames('w-full', parentClassName);
        const leftElementClasses = classNames(center, leftClasses);
        const rightElementClasses = classNames(center, rightClasses);
        const labelClasses = classNames(labelText, error && 'text-system-danger-500', labelClassName);
        const inputClasses = classNames(input, error && 'text-system-danger-500', className);
        const inputWrapperClasses = classNames(inputWrapper, inpuWrapperClasses, error ? 'border-system-danger-500 hover:border-system-danger-600' : 'border-base-300 hover:border-base-400');

        const handleFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
            const border = error ? 'border-system-danger-500' : 'border-primary-200';

            inputWrapperRef.current?.classList.add(border);

            props.onFocus && props.onFocus(event);
        };

        const handleBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
            const border = error ? 'border-system-danger-500' : 'border-primary-200';

            inputWrapperRef.current?.classList.remove(border);

            props.onBlur && props.onBlur(event);
        };

        return (
            <div className={parentClasses}>
                {label && (
                    <Text as="label" variant="body.regular.sm" className={labelClasses}>
                        {label}
                    </Text>
                )}
                <div ref={inputWrapperRef} className={inputWrapperClasses}>
                    {left && <div className={leftElementClasses}>{left}</div>}
                    {mask ? (
                        <InputMask {...props} ref={ref as LegacyRef<InputMask>} mask={mask} className={inputClasses} onBlur={handleBlur} onFocus={handleFocus} />
                    ) : (
                        <input {...props} ref={ref} className={inputClasses} onBlur={handleBlur} onFocus={handleFocus} />
                    )}
                    {right && <div className={rightElementClasses}>{right}</div>}
                </div>
                <ErrorMessage className={errorMessageClasses} visible={Boolean(error) && showErrorMessage}>
                    {error}
                </ErrorMessage>
            </div>
        );
    }
);

const { center, input, inputWrapper, labelText } = {
    center: 'flex items-center',
    input: 'text-sm text-heading w-full px-4 py-3 disabled:bg-base-200',
    inputWrapper: 'transition flex w-full border rounded-[14px] overflow-hidden h-12',
    labelText: 'block mb-1.5 text-base-500'
};

export default memo(Input);
