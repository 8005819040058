// Dependencies
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Helpers
import { Modify } from 'types/general';
import apiGraphQl from 'services/graphql';
import useToast from 'hooks/toast/use-toast';
import { ProjectProposalResourcePayload } from 'types/models/project';
import project, { createProjectProposalKey, getProjectKey, getProjectProposalTypeKey } from './graphql';

export type CustomProjectProposalResourcesPayload = Modify<ProjectProposalResourcePayload, { quantity: number }>;

type CreateOrUpdateProjectProposalResourcesResponse = {
    item:
        | {
              returning: Array<{
                  proposal_type: {
                      id: number;
                      project_proposal_id: number;
                      project_type_id: number;
                  };
              }>;
          } & { proposal_type: { id: number } };
};

const useCreateOrUpdateProjectProposalResources = (projectId?: number, resourceId?: string) => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const isEdit = Boolean(resourceId);

    const CreateOrUpdateProjectProposalResources = async (payload: CustomProjectProposalResourcesPayload[] | CustomProjectProposalResourcesPayload) => {
        const graphqlVariables = isEdit ? { payload, id: +resourceId! } : { payload };

        return apiGraphQl<CreateOrUpdateProjectProposalResourcesResponse>(isEdit ? project.updateProjectProposalTypeResource : project.createProposalResources, graphqlVariables);
    };

    return useMutation(createProjectProposalKey, CreateOrUpdateProjectProposalResources, {
        onSuccess: ({ item }) => {
            queryClient.invalidateQueries(getProjectKey(projectId));

            const returned = Boolean(item?.returning) ? item.returning[0] : item;

            queryClient.invalidateQueries(getProjectProposalTypeKey(returned.proposal_type.id));

            navigate(-1);

            showToast(`${isEdit ? 'Recurso adicionado' : 'Recursos adicionados'} à proposta com sucesso`, 'success');
        },
        onError: () => {
            showToast(`Ocorreu um erro ao adicionar ${isEdit ? 'o recurso' : 'os recursos'} à proposta`, 'danger');
        }
    });
};

export default useCreateOrUpdateProjectProposalResources;
