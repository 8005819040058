import Icon from 'components/core/icon';
import Avatar from 'components/core/avatar';
import classNames from 'classnames';
import { formatDate } from 'utils/date';
import { TaskDashboardItem } from 'types/models/task-dashboard';
import { useNavigate } from 'react-router-dom';

type ColumnCardProps = { isDragging: boolean; statusId: number; task: TaskDashboardItem };

const ColumnCard = ({ isDragging, task }: ColumnCardProps) => {
    const navigate = useNavigate();

    const container = classNames('bg-white border border-base-300 rounded-2xl px-4 py-4 mb-4 flex flex-col', isDragging && 'shadow-md');

    const { title, person, created_at, comments = 0, files = 0, id } = task || {};

    const handleClick = () => navigate(`/app/tarefas/${id}`);

    return (
        <div className={container} onClick={handleClick}>
            <div className="flex items-center mb-1">
                <div className="flex items-center gap-2 ">
                    <Icon name="ico-calendar" width={18} height={18} className="text-base-500" />
                    <span className="text-heading font-normal text-sm uppercase">{formatDate(created_at, 'MMM DD')}</span>
                </div>
            </div>
            <p className="text-heading text-base font-medium mb-2">{title}</p>
            {!!task?.project_proposal_type?.project_proposal?.project?.title && (
                <p className="text-base-700 text-xs font-normal mb-1">- Projeto: {task?.project_proposal_type?.project_proposal?.project?.title}</p>
            )}
            {!!task?.task_father && <p className="text-base-700 text-xs font-normal mb-2">- Tarefa filha de: {task?.task_father.title}</p>}
            <div className="flex items-center">
                <div className="flex items-center flex-1 gap-5">
                    {[
                        { icon: 'ico-attachment', value: files },
                        { icon: 'ico-chat', value: comments }
                    ].map(({ icon, value }, index) => (
                        <div key={`info_${index}`} className="flex items-center">
                            <Icon name={icon} width={16} height={16} className="text-base-500 mr-2" />
                            <span className="text-heading font-normal text-sm">{value}</span>
                        </div>
                    ))}
                </div>

                <div className="flex items-center gap-2">
                    <Avatar firstLetter={person?.name?.charAt(0) || ''} size="micro" src={person?.profileImage} />
                </div>
            </div>
        </div>
    );
};

export default ColumnCard;
